/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import {
    getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import EditItem from "./tptedititem";
import CSVFileValidator from 'csv-file-validator'
import UploadPODReceit from "./uploaditem";
import ViewAttachments from "./viewallattachment";
import ViewIdentityAttachments from "./viewidentityattachment";
import ExportPDF from "./exportpdf"
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");

export default class CourtCasesTrack extends Component {
    state = {
        loadshow: 'show-n',
        overly: 'show-n',
        sliderTranslate: '',
        showdata: this.displayData,
        modules: AllModules,
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        show1: false,
        basicTitle1: '',
        basicType1: "default",
        contentString: "testing",
        mwidth: "col-xl-12 col-lg-12",
        dept_code: '',
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
            resizable: true
        },
        rowData: [],
        frameworkComponents: {
            edititem: EditItem
        },
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: false,
        rowSelection: "single",
        context: { componentParent: this },
        rowClassRules: {
            "boxbgblue": "data.to_be_approved == 1"
        },
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        detailCellRendererParams: {},
        loadingCellRenderer: "customLoadingCellRenderer",
        loadingCellRendererParams: { loadingMessage: "One moment please..." },
        rowModelType: "serverSide",
        paginationPageSize: 200,
        cacheBlockSize: 50,
        maxBlocksInCache: 2,
        pagetitle: "Court Cases Tracking",
        sliderRouteTranslate: '',
        file: '',
        csvcontent: [],
        accidentDate: "",
        endDate: "",
        defaultsdate: "",
        defaultedate: "",
        headerHeight: 50,
        showform: "show-n",
        sliderForceDestination: "",
        shwdata: "show-n",
        sliderCourtData: "",
        sliderBulk: "",
        transporter_code: "",
        truck_no: '',
        accidentPlace: '',
        accidentComments: '',
        court_complex: '',
        court_filing_number: '',
        fir_number: '',
        courtHearingDate: '',
        driverName: '',
        driverNumber: '',
        username: "",
        userid: "",
        email: "",
        status : {},
        filteredData: [],
        truckList: [],
        rowid: "",
        carriers: [],
        groups: [],
        group: [],
        adaniCheck: 0,
        pdfRowData: [],
        pdfColumns: [],
        addressColumn: 300,
        tptGrouptrucks: [],
        advocate_name: '',
        advocate_number: '',
        court_case_number: '',
        rowId : ''
    }


    componentDidMount() {
        var transporter_code = localStorage.getItem("transportercode");
        var username = localStorage.getItem("username")
        var userid = localStorage.getItem("userid");
        var email = localStorage.getItem("email");
        this.setState({
            transporter_code: transporter_code,
            username: username,
            userid: userid,
            email: email
        })

        var parameters = {
            transporter_code: transporter_code
        }
        var vehicle_group = localStorage.getItem("vehicle_group");

        if (vehicle_group != "") {
            var tparms = {
                transporter_code: transporter_code,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("records ", records)
                    this.setState({
                        tptGrouptrucks: records
                    })
                })
        }
        redirectURL.post("/dashboard/tpttrucks", parameters)
            .then((response) => {
                var records = response.data.records.map(e => ({ 'label': e.truck_no, 'value': e.truck_no }))
                this.setState({
                    truckList: records,
                    // rowData:records
                })
            })

        this.onLoadData(parameters)
    }

    onLoadData = params => {
        this.setState({
            rowData: [],
            // loadshow: "show-m"
        });
        redirectURL.post("/dashboard/getTrackOfCourtCases", params).then(async resp => {
            let gridData = resp.data.records
            this.setState({
                rowData: gridData
            })

        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onClickHideAll = () => {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: '',
            sliderTranslatesidebar: '',
            sliderForceCloseTranslate: '',
            sliderRakeTranslate: '',
            sliderBulkRakeTranslate: "",
            bulkslide: '',
            sliderForceDestination: '',
            sliderBulkForceClose: "",
            sliderCourtData: "",
            sliderBulk: "",
            rowid: "",
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onAddCourtCase = () => {
        this.setState({
            sliderCourtData: "slider-translate-30p",
            overly: 'show-m',
        })
    }

    formRegister = param => {
        param.preventDefault()
        const { truck_no, accidentDate, accidentPlace, accidentComments, court_complex, court_filing_number, fir_number, courtHearingDate, driverName, driverNumber, court_case_number, advocate_name, advocate_number,status,rowId } = this.state
        const formdata = {
           data : {'truck_no': truck_no.value
                    ,accidentDate, accidentPlace, 
                    accidentComments, court_complex, 
                    court_filing_number, fir_number, 
                    courtHearingDate, driverName, driverNumber,
                    court_case_number, advocate_name, advocate_number,
                    'status' : status.value,
                },
            'rowId':rowId            
        }
        // console.log('formdata', formdata)
        redirectURL.post("/dashboard/postTrackOfCourtCases", formdata).then(async resp => {
            if (resp.data.message == 'success') {
                this.setState({
                    show : true,
                    basicType : 'success',
                    basicTitle : 'Successfully registered',
                    truck_no : '',
                    accidentDate : '',
                    accidentPlace : '',
                    accidentComments: '',
                    court_complex : '',
                    court_filing_number : '',
                    fir_number : '',
                    courtHearingDate : '',
                    driverName : '',
                    driverNumber : '',
                    court_case_number : '',
                    advocate_name : '',
                    advocate_number : '',
                    status : {},
                    rowId : '',
                    sliderCourtData : '',
                    overly : 'show-n'
                })
                this.onLoadData()
            } else {
                this.setState({
                    show : true,
                    basicType : 'warning',
                    basicTitle : 'Some Thing went wrong'
                })
            }
        })
    }
    // edit button functionality
    updateRowHandler = rowNode =>{
        // console.log('rowNode', rowNode)
        let gridRowData = rowNode.data
        this.setState({
            sliderCourtData: "slider-translate-30p",
            overly: 'show-m',
            truck_no : {label : gridRowData.truck_no , value : gridRowData.truck_no},
            accidentDate : gridRowData.accidentDate,
            accidentPlace : gridRowData.accidentPlace,
            accidentComments: gridRowData.accidentComments,
            court_complex : gridRowData.court_complex,
            court_filing_number : gridRowData.court_filing_number,
            fir_number : gridRowData.fir_number,
            courtHearingDate : gridRowData.courtHearingDate,
            driverName : gridRowData.driverName,
            driverNumber : gridRowData.driverNumber,
            court_case_number : gridRowData.court_case_number,
            advocate_name : gridRowData.advocate_name,
            advocate_number : gridRowData.advocate_number,
            status : {label : gridRowData.status, value : gridRowData.status},
            rowId : rowNode.value
        })

    }

    onChangeTruckNo = truck_no => {
        this.setState({ truck_no })
    }

    handleraccidentDateTime = date => {
        var d = new Date(date._d);
        let month = (d.getMonth() + 1) < 10 ? `0${(d.getMonth() + 1)}` : (d.getMonth() + 1)
        var accident_date = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())

        this.setState({
            accidentDate: accident_date
        })
    }

    handlercourtHearingDateTime = date => {
        var d = new Date(date._d);
        let month = (d.getMonth() + 1) < 10 ? `0${(d.getMonth() + 1)}` : (d.getMonth() + 1)
        var courtHearingDate = d.getFullYear() + "-" + month + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        this.setState({
            courtHearingDate
        })
    }

    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }

 

    render() {

        const columnwithDefs = [
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                pinned: "left",
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    return {
                        component: "edititem"
                    }
                }
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                width: 140,
            },
            {
                headerName: 'Accident Date',
                field: "accidentDate",
                width: 140,
                valueGetter: (params) => {
                    return getHyphenYYYYMMDD(params.data.accidentDate)
                },
            },
            {
                headerName: 'Accident Place',
                field: "accidentPlace",
                width: 140,
            },
            {
                headerName: 'Accident Commnets',
                field: "accidentComments",
                width: 140,
            },
            {
                headerName: 'Court Name',
                field: "court_complex",
                width: 140,
            },
            {
                headerName: 'Court Filing Number',
                field: "court_filing_number",
                width: 140,
            },
            {
                headerName: 'Fir Number',
                field: 'fir_number',
                width: 140
            },
            {
                headerName: 'Court Case Number',
                field: 'court_case_number',
                width: 140
            },
            {
                headerName: 'Court Hearing Date',
                field: "courtHearingDate",
                width: 140,
                valueGetter: (params) => {
                    return getHyphenYYYYMMDD(params.data.courtHearingDate)
                },
            },
            {
                headerName: 'Advocate Name',
                field: 'advocate_name',
                width: 140,
            },
            {
                headerName: 'Advocate Number',
                field: 'advocate_number',
                width: 140,
            },
            {
                headerName: 'Driver Name',
                field: "driverName",
                width: 140,
            },
            {
                headerName: 'Driver Number',
                field: "driverNumber",
                width: 140,
            },
            {
                headerName: 'status',
                field: "status",
                width: 140,
            },
        ]
        
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>Court Cases </span>
                                    <button
                                        type="button"
                                        onClick={this.onAddCourtCase}
                                        className="btn btn-warning float-right">
                                        Add Case
                                    </button>
                                </h5>
                            </div>

                            <div className="card-body">
                                <div className="row">
                                    <div className={"row col-xl-12 col-lg-12 "}>
                                        <div className={"col-xl-12 col-lg-12 "}>
                                            <div id="myGrid" style={{ height: "680px", width: "100%" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    headerHeight={this.state.headerHeight}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                // statusBar={this.state.statusBar}
                                                // sideBar={this.state.sideBar}
                                                // overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                // detailCellRendererParams={this.state.detailCellRendererParams}
                                                // masterDetail={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"dataLoadpage " + (this.state.loadshow)}>
                                </div>
                                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                                </div>
                                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>

                                <div className={"slide-r " + (this.state.sliderCourtData)} style={{ overflow: "auto" }}>
                                    <div className="slide-r-title">
                                        <h4>
                                            Register a Court Case
                                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
                                        </h4>
                                    </div>
                                    <div className="slide-r-body" style={{ position: "relative" }}>
                                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                            <form method="POST" id="defectForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRegister}>
                                                <div className="form-group mt-20p">
                                                    <label className="">Truck No <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <Select placeholder="Select"
                                                        value={this.state.truck_no}
                                                        onChange={this.onChangeTruckNo}
                                                        options={this.state.truckList}
                                                        required />
                                                </div>
                                                <div className="form-group mt-20p" style={{ width: '406px' }}>
                                                    <label>Accident Date <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <Datetime value={this.state.accidentDate}
                                                        disableCloseOnClickOutside={false}
                                                        closeOnSelect={true}
                                                        inputProps={{ placeholder: 'accidentDate', name: 'accidentDate', autoComplete: 'off', readOnly: true }}
                                                        dateFormat="YYYY-MM-DD"
                                                        name="accidentDate"
                                                        onChange={this.handleraccidentDateTime} />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Acident Place <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="accidentPlace" value={this.state.accidentPlace} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Accident Comments <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <textarea name="accidentComments" value={this.state.accidentComments} onChange={this.changeHandler} rows="4" className="form-control" ></textarea>
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Court Name <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="court_complex" value={this.state.court_complex} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Case Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="court_case_number" value={this.state.court_case_number} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Court Filling Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="court_filing_number" value={this.state.court_filing_number} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Fir Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="fir_number" value={this.state.fir_number} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Advocate Name<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="advocate_name" value={this.state.advocate_name} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Advocate Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="advocate_number" value={this.state.advocate_number} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className=" form-group mt-20p" style={{ width: '406px' }}>
                                                    <label>Court Hearing Date </label>
                                                    <Datetime value={this.state.courtHearingDate}
                                                        disableCloseOnClickOutside={false}
                                                        closeOnSelect={true}
                                                        inputProps={{ placeholder: 'courtHearingDate', name: 'courtHearingDate', autoComplete: 'off', readOnly: true }}
                                                        style={{ width: '314px' }}
                                                        dateFormat="YYYY-MM-DD"
                                                        name="courtHearingDate"
                                                        onChange={this.handlercourtHearingDateTime} />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Driver Name <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="driverName" value={this.state.driverName} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Driver Number<span style={{ color: "#FF0000" }}>*</span></label>
                                                    <input type="text" name="driverNumber" value={this.state.driverNumber} onChange={this.changeHandler} className="form-control" required />
                                                </div>
                                                <div className="form-group mt-20p">
                                                    <label className="">Status <span style={{ color: "#FF0000" }}>*</span></label>
                                                    <Select placeholder="Select"
                                                        value={this.state.status}
                                                        onChange={(e)=> this.setState({status : e}) }
                                                        options={ [
                                                                {'value': 'new', 'label' :'New'},
                                                                {'value': 'in_progress', 'label' :'In Progress'},
                                                                {'value': 'closed', 'label' :'Closed'}
                                                            ]}
                                                        required />
                                                </div>
                                                <div className="col-xl-12 col-lg-12 form-group">
                                                    <button type="submit" className="btn btn-success">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}