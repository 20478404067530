/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component, useContext } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import $ from 'jquery';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { parseMarker } from '@fullcalendar/react';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');

export default class TruckStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: 'multiple',
            context: { componentParent: this },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            frameworkComponents: {
                updateItem: UpdateItem,
                deleteItem: DeleteItem
                //statusChange:StatusChange
            },
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk: "",
            file: "",
            csvcontent: [],
            statusList: [],
            citiesList:[],
            msiltrips: [],
            msiltripitems: [],
            fromdate:[],
            todate:[],
            allrowData:[]
        };
        // this.updateRowHandler = this.updateRowHandler.bind(this);
        // this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
    }
    getCurrentDepartmentCode(pathToMatch = null) {
        let department = null;
        let departmentName = null;
        switch (pathToMatch) {
            case "/tptoemmaster":
                department = 'SNDG';
                departmentName = " ";
                break;

            default:
                console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
        }
        this.setState({
            currentDepartmentTitle: departmentName
        });
        return department;
    }
    async componentDidMount() {
        loadDateTimeScript()
        if (googleAnalytics.page.enableGA) {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({ eventAction: eventAction })
        }
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        // get department code to filter records by department
        let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
        var fromdate = moment.parseZone().subtract(6, 'days').format("YYYY-MM-DD");
        var todate = moment.parseZone().format("YYYY-MM-DD");
        await this.setState({
            departmentCode: currentDepartmentCode,
            fromdate : fromdate,
            todate : todate
        });
        /*Consignments List*/
        //this.getOnTripData()
        await this.onLoadData();
        
    };

getOnTripData = () => {
    this.onLoadData();
}

onLoadData = () => {
    var loginType = "";
    if (localStorage.getItem("is_hero") != undefined && localStorage.getItem("is_hero") == 1) {
        loginType = "hero"
    }
    else{
        loginType = "msil"
    }
    var tptCode = localStorage.getItem('transportercode');
    var vehicle_group = localStorage.getItem("vehicle_group");
    var tptrucks = []
    if(vehicle_group != "")
    {
        var tparms = {
            transporter_code:tptCode,
            region:vehicle_group
        }
        redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
        .then((response) => {
            var records = response.data.records;
            // console.log("records ", records)
            tptrucks = records;
            this.setState({
                tpttrucks:records
            })
        })
    }
    var fromdate = this.state.fromdate;
    var todate = this.state.todate;
    var dateList = getDaysBetweenDates(fromdate,todate);
    console.log(dateList,"dateList");
    var params = {
        transporter_code: localStorage.getItem("transportercode"),
        dateList: dateList,
        loginType: loginType,
    }
    redirectURL.post('/dashboard/getTruckStatus', params).then(async(response) => {
        var records = response.data;
        console.log("records ", records)
        redirectURL.post('/dashboard/gethistorictruckdetailsbystatus', params).then(async(response1) => {
            var records1 = response1.data;
            // console.log(records1,"records1")
            // let statusDropDown = records1.dropList
            // let citiesDropDown = records1.citiesList
            let truckStatusData = records1.griddata
            var tpttrucks = tptrucks;
            // console.log("citiesDropDown ",citiesDropDown)
			var trucksarr = []
			if(tpttrucks.length > 0)
			{
				truckStatusData.filter(f =>
					tpttrucks.filter((d )=> {
						if(d.truck_no == f.truck_no){									
							trucksarr.push(f)
						}
					})
				);
			}
			else
			{
				trucksarr = truckStatusData
			}
            console.log("truckStatusData", trucksarr)
            // citiesDropDown.sort(function(a, b){
            //     if(a < b) { return -1; }
            //     if(a > b) { return 1; }
            //     return 0;
            // })
            await this.setState({
                rowData: trucksarr,
                dateList: dateList,
                // statusList: statusDropDown,
                // citiesList: citiesDropDown,
                loadshow: "show-n",
                overly: "show-n"
            });
            await this.historicGrid();
        })
    })
    .catch(function (error) {
        console.log(error);
    });

}
filterData = async () => {
    var fromDate = $("#fromDate").val();
    var toDate = $("#toDate").val();
    await this.setState({
        fromdate : fromDate,
        todate : toDate,
        loadshow : "show-m",
        overly : "show-m",
    });
    await this.onLoadData();
}
historicGrid = async () => {
    var rowData = this.state.rowData;
    var dateList = this.state.dateList;
    var columns = []
    columns.push({
        headerName:"Truck No",
        field:"truck_no",
        width:120,
        editable:false
    })
    dateList.map((trans,index) => {
        // console.log("trans ", trans)
        columns.push({
            headerName:trans,
            field:"truck_status_"+index,
            width:120,
            editable:false
        })
    })
    // console.log("Columns ", columns)
    this.setState({
        columnwithDefs:columns
    })
    var rowdefs = []
    // await rowData.map((ste) =>{
    //     var rows = {}
    //     rows.truck_no=ste.truck_no;   
    //     dateList.map((t) => {  
    //         if(ste.status_date == t)
    //         {
    //             rows["truck_status"] = ste.truck_status
    //         }                   
    //     })
    //     rowdefs.push(rows)
    // })        
    var groupTrucks = groupBy(rowData, rdata => rdata.truck_no);
    groupTrucks.forEach((trans1, transkey) =>{       
        var rows = {}
        rows.truck_no=transkey;
        dateList.map((t,index) => {  
            console.log(trans1,"trans1")
            var truckInfo = trans1.filter((tf) => tf.status_date == t);  
            if(truckInfo.length > 0)
            {
                rows["truck_status_"+index] = truckInfo[0].truck_status
            }
            else
            {
                rows["truck_status_"+index] = "";
            }                
        })
        // trans1.map((t) => {  
        //     if(t.status_date == t)
        //     {
        //         rows["truck_status"] = t.truck_status
        //     }
        // })
        rowdefs.push(rows)
    })
    this.setState({
        allrowData:rowdefs,
        overly:'show-n',
        load:'show-n',
    })
}
logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
        } else {
            console.log("GA disabled...");
        }
    } catch (error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}

onCloseUserManualModal = () => {
    this.setState({ usermanualmodal: false });
};

onClickShowUserManaul = () => {
    this.setState({ usermanualmodal: true });
};

onGridReady = params => {
    this.gridApi = params.api;
    //console.log("Grid is: ", this.gridOptionsWrapper);
    this.gridColumnApi = params.columnApi;

};
onGridState = () => {
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();

    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);



}


/*Alert Popups*/
closeAlert = () => {
    this.setState({
        show: false
    });
}


onAddRow() {
    // var newItem = 
    // {
    //     "truck_no":"",
    //     "truck_type":"",
    //     "transporter_code":"",
    //     "dept_code":""
    // };
    // console.log("newItem",newItem);
    // var res = this.gridApi.updateRowData({ add: [newItem] });
    this.setState({
        sliderRso: 'slider-translate',
        overly: 'show-m',
        oem_code: "",
        oem_name: "",
        industry_type: ""
    })

}


onClickHideAll() {
    this.setState({
        loadshow: 'show-n',
        overly: 'show-n',
        sliderRso: "",
        sliderUpdate: "",
        sliderBulk: ""
    });

}

onChangeTruckType(trucktype) {
    this.setState(
        { trucktype },
        () => console.log(`Option selected:`, this.state.trucktype)
    );


    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}

changeHandler = (event) => {
    //console.log("Select", event.target.value);
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
}


onChangeTransporter(transporter) {
    this.setState(
        { transporter },
        () => console.log(`Option selected:`, this.state.transporter)
    );


    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}

onChangeDepartment(deptcode) {
    this.setState(
        { deptcode },
        () => console.log(`Option selected:`, this.state.deptcode)
    );


    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}

onClickShowBulk() {
    this.setState({
        overly: "show-m",
        sliderBulk: 'slider-translate',
    })
}

render() {
    const modalStyles = {
        width: '1300px !important',
    }
    var transporters = []
    var trans = this.state.transporters;
    if (trans.length > 0) {
        trans.map((item) => {
            transporters.push(item.value)
        })
    }
    const { usermanualmodal } = this.state;
    var hideChilds = true;
    if (this.state.departmentCode == "LOG-PRT") {
        hideChilds = false
    }
    var todaydate = moment.parseZone().format("DD-MM-YYYY")
    var yesterdaydate =  moment.parseZone().subtract(1, 'days').format("DD-MM-YYYY")
    var daybeforeyesterdaydate =  moment.parseZone().subtract(2, 'days').format("DD-MM-YYYY")
    var fourthday =  moment.parseZone().subtract(3, 'days').format("DD-MM-YYYY")
    var thirdday =  moment.parseZone().subtract(4, 'days').format("DD-MM-YYYY")
    var secondday =  moment.parseZone().subtract(5, 'days').format("DD-MM-YYYY")
    var firstday =  moment.parseZone().subtract(6, 'days').format("DD-MM-YYYY")

    const columnwithDefs = [
        {
            headerName: "Truck no",
            field: "truck_no",
            width: 120,
            editable: false,
        },
        {
            headerName: firstday,
            field: "firstday_status",
            width: 150,
            editable: false,
        },
        {
            headerName: secondday,
            field: "secondday_status",
            width: 150,
            editable: false,
        },
        {
            headerName: thirdday,
            field: "thirdday_status",
            width: 150,
            editable: false,
        },
        {
            headerName: fourthday,
            field: "fourthday_status",
            width: 150,
            editable: false,
        },
        {
            headerName: daybeforeyesterdaydate,
            field: "fifthday_status",
            width: 150,
            editable: false,
        },
        {
            headerName: yesterdaydate,
            field: "yesterday_status",
            width: 150,
            editable: false,
        },
        {
            headerName: todaydate,
            field: "current_status",
            width: 150,
            editable: true,
            filter: "agSetColumnFilter",
        },
        // {
        //     headerName: "Location",
        //     field: "city",
        //     width: 150,
        //     editable: false,
        //     // cellEditor: 'agSelectCellEditor',
        //     // cellEditorParams: {
        //     //     values: this.state.citiesList,
        //     // },
        // },
    ];
    return (
        <div className="container-fluid">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Historic Truck Status </span>
                                {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button>
                                       <button type="button" onClick={this.onClickShowBulk.bind(this)} className="btn btn-danger float-right">Bulk Upload</button> */}
                            </h5>
                        </div>
                        <div class="row" style={{marginLeft:"1em"}}>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>From Date</label>
								<input type="text" name="date" id="fromDate" autoComplete='off' className="datetimepicker_date form-control" value={this.state.fromdate}/>
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>To Date</label>
								<input type="text" name="date" id="toDate" autoComplete='off' className="datetimepicker_date form-control" value={this.state.todate}/>
							</div>
							<div class="col-xl-2 col-lg-2 mr-0 form-group">
								<label>&nbsp;</label>
								<br />
								<button type="submit" onClick={this.filterData.bind(this)} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
						</div>
                        <div className="card-body">
                            <div id="myGrid" style={{ height: "450px", width: "100%" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.allrowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    frameworkComponents={this.state.frameworkComponents}
                                    stopEditingWhenGridLosesFocus={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    suppressRowClickSelection={true}
                                    onCellClicked={this.onCellRowClicked}
                                // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                //editType={this.state.editType}
                                // floatingFilter={true}
                                //  paginationAutoPageSize={true}
                                //updateRowHandler={this.updateRowHandler}
                                //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"slide-r " + (this.state.sliderTranslate)} >

                <div className="slide-r-title">
                    <h4>
                        Sidebars
                    </h4>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>

                    <div className="col-xl-12 col-lg-12">
                        <div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

                    </div>
                </div>
            </div>


            <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
                <div className="slide-r-title">
                    <h4>
                        Add OEM Item
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                </div>
            </div>



            <div className={"slide-r " + (this.state.sliderUpdate)} style={{ overflow: "auto" }}>
                <div className="slide-r-title">
                    <h4>
                        Update OEM Item
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                </div>
               
            </div>


            <div className={"slide-r " + (this.state.sliderBulk)} style={{ overflow: "auto" }}>
                <div className="slide-r-title">
                    <h4>
                        Bulk Upload
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                </div>
                
            </div>


            <div className={"dataLoadpage " + (this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


        </div>

    );
}
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getDaysBetweenDates (startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    console.log(startDate,"startDate")
    console.log(endDate,"endDate")
    var date = new Date(startDate.getTime());
    var dates = [];
    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    console.log(dates,"dates")
    var dateList = [];
    dates.map(function(dt){        
        dateList.push(moment.parseZone(dt).format("YYYY-MM-DD"));
    });
    return dateList;
};
$(document).on("change", "#toDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromDate").val();
    var cargoDate=$("#toDate").val();
	// var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#toDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#fromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromDate").val();
    var cargoDate=$("#toDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
	var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#fromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}