/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ExportPDF from './exportpdf';

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import {
    getDDMMYYYY, getDDMMMYYYY, getHyphenDDMMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import UploadPODReceit from "./uploaditem";
import ViewAttachments from "./viewallattachment";
import ViewIdentityAttachments from "./viewidentityattachment";
import EditItem from "./tptedititem";
import DeleteItem from "./deleteitem";
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class TPTDriverData extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            rowData: [],
            frameworkComponents: {
                uploaddoc: UploadPODReceit,
                viewattachments: ViewAttachments,
                viewidentityattachments: ViewIdentityAttachments,
                edititem: EditItem,
                deleteitem: DeleteItem
            },
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            rowClassRules: {
                "boxbgblue": "data.to_be_approved == 1"
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
			  },
            detailCellRendererParams: {},
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 200,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "RSO POD",
            sliderRouteTranslate: '',
            file: '',
            csvcontent: [],
            startDate: "",
            endDate: "",
            defaultsdate: "",
            defaultedate: "",
            headerHeight: 80,
            showform: "show-n",
            sliderForceDestination: "",
            truck_no: "",
            address_proof_no: "",
            address_proof: { value: "", label: "Select" },
            identity_proof_no: "",
            identity_proof: { value: "", label: "Select" },
            uploads: [],
            files: [],
            identityfiles: [],
            shwdata: "show-n",
            sliderDefectData: "",
            sliderBulk: "",
            transporter_code: "",
            username: "",
            userid: "",
            email: "",
            filteredData: [],
            showTrucksList: "show-n",
            trucks: [],
            rowid: "",
            driver_name: "",
            driver_mobile_no: "",
            driver_mobile_no_1: "",
            driver_address: "",
            driver_code: "",
            vehicle_no: "",
            license_no: "",
            driver_father_name: "",
            expiry_date: "",
            pdfRowData: [],
            pdfColumns: [],
            addressColumn: "",
            vehicle_no: { value: "", label: "Select" },
            vehiclenos: [],
            guarantor_name:"",
            guarantor_mobile_no:"",

        };
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickShowUploadModal = this.onClickShowUploadModal.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
        this.onClickIdentityDownload = this.onClickIdentityDownload.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
    }

    componentDidMount() {
        loadDateTimeScript()
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate = moment().endOf('month').format('YYYY-MM-DD');
        var transporter_code = localStorage.getItem("transportercode")
        var username = localStorage.getItem("username")
        var userid = localStorage.getItem("userid");
        var email = localStorage.getItem("email");
        // console.log("transporter_code ", transporter_code)
        this.setState({
            startDate: sdate,
            endDate: edate,
            defaultsdate: sdate,
            defaultedate: edate,
            transporter_code: transporter_code,
            username: username,
            userid: userid,
            email: email
        })
        var mindate = moment.parseZone().format('YYYY-MM-DD');
        var minactdate = moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
        // console.log("path ", this.props.match.path)
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });

        var parameters = {
            transporter_code: transporter_code
        }

        this.onLoadData(parameters)

    }

    onLoadData = (parameters) => {
        this.setState({
            rowData: [],
            loadshow: "show-m"
        });
        // console.log("parameters ", parameters)
        redirectURL.post("/dashboard/tptdrivers", parameters)
            .then(async (response) => {
                var records = response.data.records;
                var vehicles = []
                var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
                var pdfRowData = [];
                // console.log("summaryData", records)
                records.map(function (t) {
                    vehicles.push(t.vehicle_no);
                    pdfRowData.push({
                        driver_code: t.driver_code,
                        driver_name: t.driver_name,
                        driver_father_name: t.driver_father_name,
                        vehicle_no: t.vehicle_no,
                        license_no: t.license_no,
                        expiry_date: t.expiry_date,
                        driver_address: t.driver_address,
                        driver_mobile_no: t.driver_mobile_no,
                        driver_mobile_no_1: t.driver_mobile_no_1,
                        guarantor_name:t.guarantor_name,
                        guarantor_mobile_no:t.guarantor_mobile_no,
                        address_proof: t.address_proof,
                        address_proof_no: t.address_proof_no,
                        address_proof_attachment: t.address_proof,
                        identity_proof: t.identity_proof,
                        identity_proof_no: t.identity_proof_no,
                        identity_proof_attachment: t.identity_proof,

                    });
                });

                var pdfColumns = [
                    { header: "Driver Code", binding: 'driver_code' },
                    { header: 'Driver Name', binding: 'driver_name' },
                    { header: 'Father Name', binding: 'driver_father_name' },
                    { header: 'Vehicle No', binding: 'vehicle_no' },
                    { header: 'License No', binding: 'license_no' },
                    { header: 'Expiry Date', binding: 'expiry_date' },
                    { header: 'Driver Address', binding: 'driver_address' },
                    { header: 'Driver Mobile Number', binding: 'driver_mobile_no' },
                    { header: 'Alternate Mobile No', binding: 'driver_mobile_no_1' },
                    { header: 'Guarantor Name', binding: 'guarantor_name' },
                    { header: 'Guarantor Mobile No', binding: 'guarantor_mobile_no' },
                    { header: 'Address Proof', binding: 'address_proof' },
                    { header: 'Address Proof No', binding: 'address_proof_no' },
                    { header: 'Proof Attachment', binding: 'address_proof' },
                    { header: 'Identity Proof', binding: 'identity_proof' },
                    { header: 'Identity Proof No', binding: 'identity_proof_no' },
                    { header: 'Identity Proof Attachment', binding: 'identity_proof_attachment' },
                ]

                this.setState({
                    pdfRowData,
                    pdfColumns,
                    rowData: records,
                    loadshow: "show-n",
                    overly: "show-n"
                });

                if (records.length > 0) {
                    var quer = {
                        trucks: vehicles,
                        transporter_code: parameters.transporter_code
                    }
                }
                else {
                    var quer = {
                        trucks: [],
                        transporter_code: parameters.transporter_code
                    }
                }
                redirectURL.post("/dashboard/tptrucksdata", quer)
                    .then(async (response) => {
                        var records = response.data.records;
                        var vehiclenos = []
                        vehiclenos.push({
                            value: "",
                            label: "Select"
                        })
                        if (records.length > 0) {

                            records.map((item) => {
                                vehiclenos.push({
                                    value: item.truck_no,
                                    label: item.truck_no
                                })
                            })
                        }
                        this.setState({
                            vehiclenos: vehiclenos
                        })
                    })

            })
    }


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    /*Alert Popups*/
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

    /*Alert Popups*/
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: '',
            sliderTranslatesidebar: '',
            sliderForceCloseTranslate: '',
            sliderRakeTranslate: '',
            sliderBulkRakeTranslate: "",
            bulkslide: '',
            sliderForceDestination: '',
            sliderBulkForceClose: "",
            sliderDefectData: "",
            sliderBulk: "",
            rowid: "",
        });

    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }


    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;


    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.gridApi.deselectAll();
        this.setState({ open: false });
    };

    onAddRow() {
        // var newItem = 
        // {
        //     sr_no:"",
        //     defect_type:"",
        //     defect_location:"",
        //     defect_remarks:""
        // };
        //console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
            sliderDefectData: "slider-translate",
            overly: 'show-m',
            driver_code: "",
            driver_name: "",
            vehicle_no: "",
            license_no: "",
            driver_father_name: "",
            driver_mobile_no: "",
            driver_mobile_no_1: "",
            guarantor_name:"",
            guarantor_mobile_no:"",
            expiry_date: "",
            driver_address: "",
            address_proof_no: "",
            identity_proof_no: "",
            address_proof: { value: "", label: "Select" },
            identity_proof: { value: "", label: "Select" }
        })
    }

    selectvins() {
        let items = [];
        var vins = this.state.vins;
        if (vins.length > 0) {
            vins.map((item) => {
                items.push({ "value": item.vehicle_id, "label": item.vehicle_id })
            })
        }
        return items;
    }

    onChangeVINs(vinno) {
        this.setState(
            { vinno },
            () => console.log(`Option selected:`, this.state.vinno)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeCarrier(carrier) {
        this.setState(
            { carrier },
            () => console.log(`carrier selected:`, this.state.carrier)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeGroup(group) {
        this.setState(
            { group },
            () => console.log(`group selected:`, this.state.group)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }


    onClickShowUploadModal(params) {
        this.setState({
            open: true
        })
    }
    onCloseModal = () => {
        this.setState({ open: false, rejectopen: false });
    };

    changeFileHandler = async (e) => {
        // console.log(e.target.files)
        var files = Array.from(e.target.files);
        this.setState({
            files: files,
            open: false
        })
    }

    changeIdentityFileHandler = async (e) => {
        // console.log(e.target.files)
        var files = Array.from(e.target.files);
        this.setState({
            identityfiles: files,
            open: false
        })
    }

    formRegister(event) {
        event.preventDefault();
        var truck_no = $("#inputTruck").val();


        if (this.state.address_proof.value != "") {
            var address_proof = this.state.address_proof.value
        }
        else {
            var address_proof = "";
        }
        if (this.state.identity_proof.value != "") {
            var identity_proof = this.state.identity_proof.value
        }
        else {
            var identity_proof = "";
        }

        if (this.state.identity_proof_no != "") {
            var identity_proof_no = this.state.identity_proof_no
        }
        else {
            var identity_proof_no = ""
        }
        if (this.state.address_proof_no != "") {
            var address_proof_no = this.state.address_proof_no
        }
        else {
            var address_proof_no = ""
        }

        // console.log("group arr ", group)
        if (this.state.rowid == "") {
            // if(this.state.driver_name != "" && this.state.driver_mobile_no != "" && this.state.driver_mobile_no_1 != "" 
            // && this.state.driver_address != "" && this.state.address_proof_file != "" && this.state.identity_proof_file != "" 
            // && identity_proof != "" && address_proof != ""  && address_proof_no != "" && identity_proof_no != "")
            // {

            if (this.state.driver_name != "" && this.state.driver_mobile_no != "" ) {

                var formdata = new FormData()
                var ftype = "";
                var fname = "";
                for (var x = 0; x < this.state.files.length; x++) {
                    // console.log("this.state.files[x] ", this.state.files[x])
                    ftype = this.state.files[x].type
                    fname = this.state.files[x].name


                    formdata.append('file', this.state.files[x])
                }
                var identityfile = "";

                for (var x1 = 0; x1 < this.state.identityfiles.length; x1++) {
                    // console.log("this.state.identityfiles[x] ", this.state.identityfiles[x1])
                    ftype = this.state.identityfiles[x1].type
                    fname = this.state.identityfiles[x1].name

                    identityfile = this.state.identityfiles[x1]
                    formdata.append('identityfile', this.state.identityfiles[x1])
                }
                // console.log("identityfile ", identityfile)
                var transportercode = this.state.transporter_code;
                var username = this.state.username;
                var userid = this.state.userid;
                var email = this.state.email;
                var expiry_date = $("#expiry_date").val();

                formdata.append("driver_code", this.state.driver_code)
                formdata.append("driver_name", this.state.driver_name)
                formdata.append("vehicle_no", this.state.vehicle_no.value)
                formdata.append("license_no", this.state.license_no)
                formdata.append("driver_father_name", this.state.driver_father_name)
                formdata.append("expiry_date", expiry_date)
                formdata.append("driver_mobile_no", this.state.driver_mobile_no)
                formdata.append("driver_mobile_no_1", this.state.driver_mobile_no_1)
                formdata.append("driver_address", this.state.driver_address)
                formdata.append("guarantor_name",this.state.guarantor_name)
                formdata.append("guarantor_mobile_no",this.state.guarantor_mobile_no)
                formdata.append("address_proof", address_proof)
                formdata.append("identity_proof", identity_proof)
                formdata.append("address_proof_no", address_proof_no)
                formdata.append("identity_proof_no", identity_proof_no)
                formdata.append("transporter_code", transportercode)
                formdata.append("username", username)
                formdata.append("userid", userid)
                formdata.append("email", email)
                // console.log("formdata ", formdata)
                if (fname != "") {
                    var ft = fname.split(".")
                    var extens = ft[parseInt(ft.length) - 1];
                    // console.log("extension ", extens)
                    if (extens == "pdf") {
                        redirectURL.post("/dashboard/savetptdriver", formdata, {
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded"
                                // "Content-Type": "multipart/form-data"
                            }
                        })
                            .then((response) => {
                                // console.log("resj ", response.data)
                                if (response.data.status == "success") {
                                    this.setState({
                                        show: true,
                                        basicType: "success",
                                        basicTitle: "Successfully register data",
                                        driver_code: "",
                                        driver_name: "",
                                        vehicle_no: "",
                                        license_no: "",
                                        driver_father_name: "",
                                        driver_mobile_no: "",
                                        driver_mobile_no_1: "",
                                        expiry_date: "",
                                        driver_address: "",
                                        address_proof_no: "",
                                        identity_proof_no: "",
                                        guarantor_name:"",
                                        guarantor_mobile_no:"",
                                        address_proof: { value: "", label: "Select" },
                                        identity_proof: { value: "", label: "Select" },
                                        file: [],
                                        files: [],
                                        sliderDefectData: "",
                                        overly: 'show-n',
                                    })

                                    var parameters = {
                                        transporter_code: this.state.transporter_code
                                    }
                                    this.onLoadData(parameters)
                                }
                                else {
                                    this.setState({
                                        show1: true,
                                        basicType1: "danger",
                                        basicTitle1: "Failed to register data"
                                    })
                                }
                            })
                    }
                    else {
                        this.setState({
                            show1: true,
                            basicType1: "danger",
                            basicTitle1: "File formats allowed only pdf"
                        })
                    }
                }
                else {

                    redirectURL.post("/dashboard/savetptdriver", formdata, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                            // "Content-Type": "multipart/form-data"
                        }
                    })
                        .then((response) => {
                            // console.log("resj ", response.data)
                            if (response.data.status == "success") {
                                this.setState({
                                    show: true,
                                    basicType: "success",
                                    basicTitle: "Successfully register data",
                                    driver_code: "",
                                    driver_name: "",
                                    vehicle_no: "",
                                    license_no: "",
                                    driver_father_name: "",
                                    driver_mobile_no: "",
                                    driver_mobile_no_1: "",
                                    expiry_date: "",
                                    driver_address: "",
                                    address_proof_no: "",
                                    identity_proof_no: "",
                                    guarantor_name:"",
                                    guarantor_mobile_no:"",
                                    address_proof: { value: "", label: "Select" },
                                    identity_proof: { value: "", label: "Select" },
                                    file: [],
                                    files: [],
                                    sliderDefectData: "",
                                    overly: 'show-n',
                                })

                                var parameters = {
                                    transporter_code: this.state.transporter_code
                                }
                                this.onLoadData(parameters)
                            }
                            else {
                                this.setState({
                                    show1: true,
                                    basicType1: "danger",
                                    basicTitle1: "Failed to register data"
                                })
                            }
                        })

                }


            }
            else {
                this.setState({
                    show1: true,
                    basicType1: "danger",
                    basicTitle1: "All * fields are mandatory"
                })
            }
        }
        else {
            if (this.state.driver_mobile_no != "" && this.state.driver_name.value != "") {
                var formdata = new FormData()
                var ftype = "";
                var fname = "";
                for (var x = 0; x < this.state.files.length; x++) {
                    console.log("this.state.files[x] ", this.state.files[x])
                    ftype = this.state.files[x].type
                    fname = this.state.files[x].name


                    formdata.append('file', this.state.files[x])
                }
                var identityfile = "";

                for (var x1 = 0; x1 < this.state.identityfiles.length; x1++) {
                    // console.log("this.state.identityfiles[x] ", this.state.identityfiles[x1])
                    ftype = this.state.identityfiles[x1].type
                    fname = this.state.identityfiles[x1].name

                    identityfile = this.state.identityfiles[x1]
                    formdata.append('identityfile', this.state.identityfiles[x1])
                }
                // console.log("identityfile ", identityfile)
                var transportercode = this.state.transporter_code;
                var username = this.state.username;
                var userid = this.state.userid;
                var email = this.state.email;

                var expiry_date = $("#expiry_date").val();

                formdata.append("driver_code", this.state.driver_code)
                formdata.append("driver_name", this.state.driver_name)
                formdata.append("vehicle_no", this.state.vehicle_no.value)
                formdata.append("license_no", this.state.license_no)
                formdata.append("driver_father_name", this.state.driver_father_name)
                formdata.append("expiry_date", expiry_date)
                formdata.append("driver_mobile_no", this.state.driver_mobile_no)
                formdata.append("driver_mobile_no_1", this.state.driver_mobile_no_1)
                formdata.append("driver_address", this.state.driver_address)
                formdata.append("guarantor_name", this.state.guarantor_name)
                formdata.append("guarantor_mobile_no", this.state.guarantor_mobile_no)
                formdata.append("address_proof", address_proof)
                formdata.append("identity_proof", identity_proof)
                formdata.append("address_proof_no", address_proof_no)
                formdata.append("identity_proof_no", identity_proof_no)
                formdata.append("transporter_code", transportercode)
                formdata.append("username", username)
                formdata.append("userid", userid)
                formdata.append("email", email)
                formdata.append("rowid", this.state.rowid)
                // console.log("formdata ", formdata)
                var ft = fname.split(".")
                var extens = ft[parseInt(ft.length) - 1];
                // console.log("extension ", extens)
                // if(extens == "pdf")
                // {
                redirectURL.post("/dashboard/updatetptdriver", formdata, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                        // "Content-Type": "multipart/form-data"
                    }
                })
                    .then((response) => {
                        // console.log("resj ", response.data)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicType: "success",
                                basicTitle: "Successfully update data",
                                driver_name: "",
                                driver_mobile_no: "",
                                driver_mobile_no_1: "",
                                driver_address: "",
                                address_proof_no: "",
                                identity_proof_no: "",
                                guarantor_mobile_no:"",
                                guarantor_name:"",
                                address_proof: { value: "", label: "Select" },
                                identity_proof: { value: "", label: "Select" },
                                file: [],
                                files: [],
                                sliderDefectData: "",
                                overly: 'show-n',
                            })

                            var parameters = {
                                transporter_code: this.state.transporter_code
                            }
                            this.onLoadData(parameters)
                        }
                        else {
                            this.setState({
                                show1: true,
                                basicType1: "danger",
                                basicTitle1: "Failed to update data"
                            })
                        }
                    })
                // }
                // else
                // {
                //     this.setState({
                //         show1:true,
                //         basicType1:"danger",
                //         basicTitle1:"File formats allowed only pdf"
                //     })
                // }
            }
            else {
                this.setState({
                    show1: true,
                    basicType1: "danger",
                    basicTitle1: "All * fields are mandatory"
                })

            }

        }

    }

    onClickDownload(params) {
        console.log("params ", params.data)
        var parameters = {
            rowid: params.data._id,
            prooftype: "address"
        }
        redirectURL.post("/dashboard/downloaddriverfile", parameters)
            .then((response) => {
                console.log("resj ", response.data)
                var filename = params.data.address_proof_file;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })


    }

    onClickIdentityDownload(params) {
        // console.log("params ",params.data)

        var parameters = {
            rowid: params.data._id,
            prooftype: "identity"
        }
        redirectURL.post("/dashboard/downloaddriverfile", parameters)
            .then((response) => {
                console.log("resj ", response.data)
                var filename = params.data.identity_proof_file;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })


    }
    onChangeTruck = vehicle_no => {
        this.setState({ vehicle_no })
    }
    onChangeAddress = address_proof => {
        this.setState({
            address_proof: address_proof
        })
    }
    onChangeIdentity = identity_proof => {
        this.setState({
            identity_proof: identity_proof
        })
    }

    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var trucks = this.state.trucks;
        var alltrucks = []
        var alltrucks = trucks.filter(f =>
            !this.state.rowData.some(d => d.truck_no == f.truck_no)
        );
        var dataset = trucks
        dataset = dataset.slice(0, 100);
        this.setState({
            filteredData: dataset,
            showTrucksList: "show-m",
        })
    }

    handlerForm = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var trucks = this.state.trucks;
        var alltrucks = []
        var alltrucks = trucks.filter(f =>
            !this.state.rowData.some(d => d.truck_no == f.truck_no)
        );
        var dataset = trucks
        var filteredResult = dataset.filter(function (e) {
            if (e != "" && e != null) {
                return e.truck_no.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }

        });
        filteredResult = filteredResult.slice(0, 100);
        this.setState({
            filteredData: filteredResult,
            showTrucksList: "show-m",
        })
    }


    updateRowHandler(rownode) {
        // console.log("rownode ", rownode.data)

        if (rownode.data.address_proof == "aadhar card") {
            var aproof = "Aadhar Card"
        }
        else if (rownode.data.address_proof == "electricity bill") {
            var aproof = "Electricity Bill"
        }
        else {
            var aproof = "";
        }


        if (rownode.data.identity_proof == "voter card") {
            var iproof = "Voter Card"
        }
        else if (rownode.data.identity_proof == "pan card") {
            var iproof = "Pan Card"
        }
        else if (rownode.data.identity_proof == "driving license") {
            var iproof = "Driving License"
        }
        else {
            var iproof = "";
        }

        this.setState({
            rowid: rownode.data._id,
            driver_name: rownode.data.driver_name,
            guarantor_name:rownode.data.guarantor_name,
            guarantor_mobile_no:rownode.data.guarantor_mobile_no,
            driver_mobile_no: rownode.data.driver_mobile_no,
            driver_mobile_no_1: rownode.data.driver_mobile_no_1,
            driver_address: rownode.data.driver_address,
            address_proof: { value: rownode.data.address_proof, label: aproof },
            address_proof_no: rownode.data.address_proof_no,
            identity_proof: { value: rownode.data.identity_proof, label: iproof },
            identity_proof_no: rownode.data.identity_proof_no,
            driver_code: rownode.data.driver_code,
            vehicle_no: { value: rownode.data.vehicle_no, label: rownode.data.vehicle_no },
            license_no: rownode.data.license_no,
            driver_father_name: rownode.data.driver_father_name,
            expiry_date: moment.parseZone(rownode.data.expiry_date).format("DD-MM-YYYY HH:mm:ss"),
            sliderDefectData: "slider-translate",
            overly: 'show-m',
        })
    }
    onClickShowBulk() {
        this.setState({
            sliderBulk: "slider-translate",
            overly: 'show-m'
        })
    }

    changeBulkFileHandler = async (e) => {
        var dt = '';

        const config = {
            headers: [

                {
                    name: 'driver_code',
                    inputName: 'driver_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_name',
                    inputName: 'driver_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_father_name',
                    inputName: 'driver_father_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'vehicle_no',
                    inputName: 'vehicle_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'license_no',
                    inputName: 'license_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'expiry_date',
                    inputName: 'expiry_date',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_mobile_no',
                    inputName: 'driver_mobile_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_mobile_no_1',
                    inputName: 'driver_mobile_no_1',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_address',
                    inputName: 'driver_address',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'guarantor_name',
                    inputName: 'guarantor_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'guarantor_mobile_no',
                    inputName: 'guarantor_mobile_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'address_proof',
                    inputName: 'address_proof',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'address_proof_no',
                    inputName: 'address_proof_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'identity_proof',
                    inputName: 'identity_proof',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'identity_proof_no',
                    inputName: 'identity_proof_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData1) => {
                this.setState({
                    csvcontent: csvData1.data
                });
                console.log(csvData1.data);
            })
            .catch(err => { })
        // console.log("e.target.files[0]",e.target.result);

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file: e.target.files[0]
        });

    }
    formBulkRegister(event) {
        event.preventDefault()
        if (this.state.csvcontent.length > 0) {
            var params = {
                csvcontent: this.state.csvcontent,
                transporter_name: this.state.username,
                transporter_code: this.state.transporter_code,
                username: this.state.username,
                userid: this.state.userid,
                email: this.state.email
            }
            console.log("params ", params)
            redirectURL.post("/dashboard/tptbulkdrivers", params)
                .then((response) => {
                    if (response.data.status == "success") {
                        this.setState({
                            csvcontent: [],
                            show: true,
                            basicTitle: "Successfully store data",
                            basicType: "success",
                            sliderBulk: "",
                            overly: 'show-n'
                        })
                    }
                    else if (response.data.status == "exists") {
                        this.setState({
                            csvcontent: [],
                            show: true,
                            basicTitle: "Already Trucks exists",
                            basicType: "danger"
                        })
                    }
                    else {
                        if (response.data.status == "notvalid") {
                            this.setState({
                                csvcontent: [],
                                show: true,
                                basicTitle: "Invalid data uploaded",
                                basicType: "danger"
                            })
                        }
                        else {
                            this.setState({
                                csvcontent: [],
                                show: true,
                                basicTitle: "Failed to registered",
                                basicType: "danger"
                            })
                        }

                    }
                })
        }
        else {
            this.setState({
                csvcontent: [],
                show: true,
                basicTitle: "Data not exists in uploaded file",
                basicType: "danger"
            })
        }


    }
    selectCarriers() {
        var items = []
        if (this.state.carriers.length > 0) {
            this.state.carriers.map((item) => {
                items.push({
                    value: item.carrier_code,
                    label: item.carrier_title
                })
            })
        }
        return items;
    }
    selectGroups() {
        var items = []
        if (this.state.groups.length > 0) {
            this.state.groups.map((item) => {
                items.push({
                    value: item.group_code,
                    label: item.group_title
                })
            })
        }
        return items;
    }
    deleteRowHandler(cell) {
        console.log("cell ", cell.data)
    }
    render() {
        // console.log("rowid ", this.state.rowid)
        var rr = this.state.rowid
        if (rr.trim() == "") {
            var rshw = "show-m";
            var isreq = true
        }
        else {
            var rshw = "show-n";
            var isreq = false
        }
        const modalStyles = {
            width: '300px !important',
        }
        const { open } = this.state;
        var columnwithDefs = [
            {
                headerName: "Vehicle No",
                field: "vehicle_no",
                width: 140,
            },
            {
                headerName: "Driver Name",
                field: "driver_name",
                width: 140,
            },
            {
                headerName: "Father Name",
                field: "driver_father_name",
                width: 140,
            },
            {
                headerName: "Driver Mobile No",
                field: "driver_mobile_no",
                width: 140,
            },
            {
                headerName: "Driver Code",
                field: "driver_code",
                width: 140,
            },
            {
                headerName: "License No",
                field: "license_no",
                width: 140,
            },
            {
                headerName: "Expiry Date",
                field: "expiry_date",
                width: 140,
                valueGetter: params => {
                    if (params.data.expiry_date == '1970-01-01T00:00:00.000Z') {
                        return ''
                    } else {
                        return getHyphenDDMMMYYYYHHMM(params.data.expiry_date);
                    }
                }
            },
            {
                headerName: "Guarantor Name",
                field: "guarantor_name",
                width: 140,
            },
            {
                headerName: "Guarantor Moblie Number",
                field: "guarantor_mobile_no",
                width: 140,
            },
            {
                headerName: "Driver Address",
                field: "driver_address",
                width: 140,
            },
            {
                headerName: "Alternate Mobile No",
                field: "driver_mobile_no_1",
                width: 140,
            },

            {
                headerName: "Address Proof",
                field: "address_proof",
                width: 250,
                resizable: true,
                editable: false
            },

            {
                headerName: "Address Proof No",
                field: "address_proof_no",
                width: 250,
                resizable: true,
                editable: false
            },
            {
                headerName: "Proof Attachment",
                field: "address_proof",
                width: 250,
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    return {
                        component: "viewattachments"
                    }
                }

            },
            {
                headerName: "Identity Proof",
                field: "identity_proof",
                width: 250,
                resizable: true,
                editable: false
            },

            {
                headerName: "Identity Proof No",
                field: "identity_proof_no",
                width: 250,
                resizable: true,
                editable: false
            },
            {
                headerName: "Identity Proof Attachment",
                field: "identity_proof",
                width: 250,
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    return {
                        component: "viewidentityattachments"
                    }
                }

            },
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                pinned: "left",
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    return {
                        component: "edititem"
                    }
                }

            },
            // {
            // 	headerName:"",
            // 	field:"_id",
            //     width:90,
            //     pinned:"left",
            //     resizable:true,
            //     editable:false,
            //     cellRendererSelector:function(params){
            //         return {
            //             component:"deleteitem"
            //         }
            //     }

            // },

        ]
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e.truck_no}</li>)
        })
        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">

                    <div className="col-xl-12 col-lg-12">

                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>Driver Details  </span>

                                    <button
                                        type="button"
                                        onClick={this.onClickShowBulk.bind(this)}
                                        className="btn btn-danger float-right">
                                        Bulk Upload
                                    </button>
                                    <button
                                        type="button"
                                        onClick={this.onAddRow.bind(this)}
                                        className="btn btn-warning float-right">
                                        Add Driver
                                    </button>
                                    {(this.state.pdfRowData.length > 0) ?
                                        <ExportPDF rowData={this.state.pdfRowData} pagetitle="RSO POD" pdfColumns={this.state.pdfColumns}
                                        // addressColumn={this.state.addressColumn}
                                        />
                                        : ""}
                                    <span className="layoutbtns pull-right">
                                        <a className="btn btn-info mr-2" href="/tptlogin/driverlogs">
                                            Driver Logs
                                        </a>
                                    </span>
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className={"row col-xl-12 col-lg-12 "}>

                                        <div className={"col-xl-12 col-lg-12 "}>
                                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    headerHeight={this.state.headerHeight}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    masterDetail={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>




                <div className={"slide-r " + (this.state.sliderDefectData)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Driver Details
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" id="defectForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRegister.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">Driver Code <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="driver_code" value={this.state.driver_code} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Driver Name <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="driver_name" value={this.state.driver_name} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Vehicle No </label>
                                    {/* <input type="text" name="vehicle_no" value={this.state.vehicle_no} onChange={this.changeHandler.bind(this)}  className="form-control" required  /> */}
                                    <Select placeholder="Select"
                                        value={this.state.vehicle_no}
                                        onChange={this.onChangeTruck.bind(this)}
                                        options={this.state.vehiclenos}
                                        />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Father Name <span style={{ color: "#FF0000" }}></span></label>
                                    <input type="text" name="driver_father_name" value={this.state.driver_father_name} onChange={this.changeHandler.bind(this)} className="form-control" />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Mobile Number <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="driver_mobile_no" value={this.state.driver_mobile_no} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Alternate Mobile Number <span style={{ color: "#FF0000" }}></span></label>
                                    <input type="text" name="driver_mobile_no_1" value={this.state.driver_mobile_no_1} onChange={this.changeHandler.bind(this)} className="form-control" />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Guarantor Name <span style={{ color: "#FF0000" }}></span></label>
                                    <input type="text" name="guarantor_name" value={this.state.guarantor_name} onChange={this.changeHandler.bind(this)} className="form-control" />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Guarantor Mobile Number <span style={{ color: "#FF0000" }}></span></label>
                                    <input type="text" name="guarantor_mobile_no" value={this.state.guarantor_mobile_no} onChange={this.changeHandler.bind(this)} className="form-control" />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Address <span style={{ color: "#FF0000" }}></span></label>
                                    <textarea name="driver_address" value={this.state.driver_address} onChange={this.changeHandler.bind(this)} rows="4" className="form-control" ></textarea>
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Address Proof <span style={{ color: "#FF0000" }}></span></label>
                                    <Select placeholder="Select"
                                        value={this.state.address_proof}
                                        onChange={this.onChangeAddress.bind(this)}
                                        options={[
                                            { "value": "", "label": "Select" },
                                            { "value": "aadhar card", "label": "Aadhar Card" },
                                            { "value": "electricity bill", "label": "Electricity Bill" }
                                        ]}
                                        required />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Address Proof No <span style={{ color: "#FF0000" }}></span></label>
                                    <input
                                        type="text"
                                        placeholder="Ex: 0000000000000000"
                                        id="address_proof_no"
                                        name="address_proof_no"
                                        onChange={this.changeHandler.bind(this)}
                                        className="form-control"
                                        value={this.state.address_proof_no}
                                    />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Upload Address File <span style={{ color: "#FF0000" }}></span></label>
                                    <input
                                        type="file"
                                        id="address_proof_file"
                                        name="address_proof_file"
                                        onChange={this.changeFileHandler.bind(this)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Identity Proof <span style={{ color: "#FF0000" }}></span></label>
                                    <Select placeholder="Select"
                                        value={this.state.identity_proof}
                                        onChange={this.onChangeIdentity.bind(this)}
                                        options={[
                                            { "value": "", "label": "Select" },
                                            { "value": "voter card", "label": "Voter Card" },
                                            { "value": "pan card", "label": "Pan Card" },
                                            { "value": "driving license", "label": "Driving License" }
                                        ]}
                                        required />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Identity Proof No <span style={{ color: "#FF0000" }}></span></label>
                                    <input
                                        type="text"
                                        placeholder="Ex: 0000000000000000"
                                        id="identity_proof_no"
                                        name="identity_proof_no"
                                        value={this.state.identity_proof_no}
                                        onChange={this.changeHandler.bind(this)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Upload Identity File <span style={{ color: "#FF0000" }}></span></label>
                                    <input
                                        type="file"
                                        id="identity_proof_file"
                                        name="identity_proof_file"
                                        onChange={this.changeIdentityFileHandler.bind(this)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">License No</label>
                                    <input type="text" name="license_no" value={this.state.license_no} onChange={this.changeHandler.bind(this)} className="form-control"  />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Expiry Date</label>
                                    <input type="text" name="expiry_date" id="expiry_date" value={this.state.expiry_date} className="datetimepicker_mask form-control"  />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>

                            <div className="col-xl-12 col-lg-12 form-group">
                                Note: File formats allowed : pdf
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"slide-r " + (this.state.sliderBulk)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Bulk Register
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" id="bulkForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkRegister.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input
                                        type="file"
                                        id="bulk"
                                        name="bulk"
                                        onChange={this.changeBulkFileHandler.bind(this)}
                                        className="form-control" required={isreq} />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                <div className="col-xl-12 col-lg-12">
                                    <a
                                        href={require("../../assets/json/sample_bulk_tpt_driver.csv")}
                                        target="_blank"
                                        className="btn btn-danger">Download Sample File</a>

                                </div>
                                <div className="col-xl-12 col-lg-12 f12 mt-20" style={{ color: "#FF0000" }}>
                                    Note: Expiry Date should be "YYYY-MM-DD"
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>

        );
    }
}


window.onload = function () {
    $("#expiry_date").datetimepicker({
        mask: '9999-19-39',
        format: 'Y-m-d',
        timepicker: false
    });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};
document.addEventListener('turbolinks:before-render', () => {
    loadDateTimeScript()
});

function loadDateTimeScript() {

    $('#expiry_date').datetimepicker({
        mask: '9999-19-39',
        format: 'Y-m-d',
        timepicker: false
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        console.log(date1Number, date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $("#trucks-dropdown").removeClass("show-m");
        $("#trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($("#trucks-dropdown").attr("class").includes("show-m") || $("#trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $("#trucks-dropdown").removeClass("show-m");
                    $("#trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })


})

