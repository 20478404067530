/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "@grapecity/wijmo.styles/wijmo.css";
import * as pdf from "@grapecity/wijmo.pdf";
import * as wijmo from "@grapecity/wijmo";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';


export default class ExportPDF extends Component {

    constructor(props) {
        super(props);
        // this.colWidth = 130;
        this.rowHeight = 25;
        this.state =
        {
            rowData: [],
            pdfColumns: [],
            adaniCheck: 0,
            addressColumn: "",
            colWidth: null

        };
    }

    componentDidMount() {
        // console.log(this.props,"cnsole")
        var rowData = this.props.rowData;
        var pdfColumns = this.props.pdfColumns;
        var addressColumnWidth = 130
        // console.log("pdfrowData", rowData)
        if (addressColumnWidth == "undefined" || addressColumnWidth == "" || addressColumnWidth == null) {
            this.setState({
                colWidth: 130
            })
        } else {
            this.setState({
                colWidth: addressColumnWidth
            })
        }
        this.setState({
            rowData: rowData,
            pdfColumns: pdfColumns
        })
        // console.log("rowDataTest: => ", rowData)
    }

    componentWillReceiveProps(newprops) {
        // console.log("receivedProps",newprops.rowData)
        var rowData = newprops.rowData;
        var pdfColumns = newprops.pdfColumns;
        var addressColumnWidth = newprops.addressColumnWidth
        this.setState({
            rowData: rowData,
            pdfColumns: pdfColumns
        })
    }

    exportPDF() {
        let doc = new pdf.PdfDocument({
            header: {
                declarative: {
                    text: this.props.pagetitle,
                    font: new pdf.PdfFont('times', 12),
                    brush: '#bfc1c2'
                }
            },
            footer: { declarative: { text: '\t&[Page] of &[Pages]' } },
            lineGap: 2,
            pageSettings: {
                margins: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                },
                size: "A2"
            },
            pageAdded: function (s, e) {
                let image = require('../../assets/icons/logo.png');
                let enmovilImage = require('../../assets/icons/enmovil.png');
                //
                s.drawImage(image, null, null, {
                    width: 150,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Center
                });
                s.drawImage(enmovilImage, 1000, 0, {
                    width: 100,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Right
                });
            },
            ended: (sender, args) => pdf.saveBlob(args.blob, 'Document.pdf')
        });

        doc.footer.drawText("Report generated on enmovil.io by Enmovil Solutions", null, null, {
            align: pdf.PdfTextHorizontalAlign.Right
        });
        var consignees = this.state.rowData;
        // console.log("cCheck=:",this.state.rowData)
        // console.log(consignees,"consignees")

        consignees.map(function (c) {

            try {
                if (c.lat != undefined && c.lat != '') {
                    c.lat = c.lat.toFixed(6);
                }
                else {
                    c.lat = "";
                }
            }
            catch (e) {
                c.lat = "";
            }

            try {
                if (c.lng != undefined && c.lng != '') {
                    c.lng = c.lng.toFixed(6);
                }
                else {
                    c.lng = "";
                }
            }
            catch (e) {
                c.lng = "";
            }

            // if(c.stime != ""){
            //     c.stime = getHyphenDDMMMYYYYHHMM(c.stime);
            // }
            // else{
            //     c.stime = "";
            // }
            if (c.time_from_prev_point != '' && c.time_from_prev_point != undefined) {
                c.time_from_prev_point = ConvertSeconds(c.time_from_prev_point);
            }
            else {
                c.time_from_prev_point = "";
            }
            if (c.cumm_distance != '' && c.cumm_distance != undefined) {
                c.cumm_distance = convertMeters(c.cumm_distance);
            }
            else {
                c.cumm_distance = "";
            }

        });
        if (consignees.length > 60) {
            var totalPages = (consignees.length / 60).toString();
            var lastRemainder = parseInt(totalPages.split(".")[1])
            totalPages = parseInt(totalPages.split(".")[0])
            //console.log(totalPages,"totalPages")
            //console.log(lastRemainder,"lastRemainder")
            var endPage = 60
            var startPage = 0
            while (consignees.length !== 0) {
                let sen = consignees.splice(0, 60)
                this.$_drawEmployee(doc, sen);
            }
            // for (var i = 0; i < totalPages; i++) {
            //     //console.log(startPage, endPage);
            //     var sen = consignees.slice(startPage, endPage);
            //     this.$_drawEmployee(doc, sen);

            //     if ((i + 1) == totalPages) {
            //         var sen1 = consignees.slice(endPage + 1, lastRemainder);
            //         this.$_drawEmployee(doc, sen1);
            //     }
            //     else {

            //         startPage = endPage + 1;
            //         endPage = endPage + 75;
            //     }

            // }
        }
        else {
            this.$_drawEmployee(doc, consignees);
        }

        // consignees.forEach((employee, i, arr) => {
        //     this.$_drawEmployee(doc, employee);
        //     if (i < arr.length - 1) {
        //         doc.addPage();
        //     }
        // });

        doc.end();
    }
    //
    $_drawEmployee(doc, consignees) {
        //let tot = employee.expenses.totals;
        let expenses = consignees;
        // console.log(expenses, "expenses")

        if (expenses.length > 0) {
            let columns = this.state.pdfColumns, bold = new pdf.PdfFont('times', 20, 'normal', 'bold'),
                colWidth = this.state.colWidth, rowHeight = this.rowHeight;

            let y = doc.y;

            doc.moveDown(2);

            // * draw table *
            doc.saveState();

            y = 0;
            let scale = doc.width / (columns.length * colWidth), docY = doc.y;

            if (scale > 1) {
                scale = 1;
            }

            doc.scale(scale, scale, new wijmo.Point(0, docY));
            doc.translate(0, docY);

            // header
            this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');

            y += rowHeight;

            // body

            expenses.map((item, index) => {
                //this.$_checkLineAvailable(doc);  
                this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format  ,bold = new pdf.PdfFont('times', 16, 'normal'));
                y += rowHeight;
            });

            doc.addPage(doc.currentPageSettings);
            doc.footer.drawText("Report generated on enmovil.io by Enmovil Solutions", null, null, {
                align: pdf.PdfTextHorizontalAlign.Right
            });

        }
    }
    //
    $_checkLineAvailable(doc) {
        if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
            doc.addPage();
        }
    }
    //
    $_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
        let colWidth = this.state.colWidth, rowHeight = this.rowHeight;
        //
        values.forEach((v, idx) => {
            let x = idx * colWidth;
            //
            doc.paths
                .rect(x, y, colWidth, rowHeight)
                //.fill(brush || '#eaeaea')
                .stroke();
            //
            let value = valueGetter != null ? valueGetter(v) : v || '';
            let format = formatGetter != null ? formatGetter(v) : '';
            //
            if (value !== 'Total') {
                value = wijmo.changeType(value, wijmo.DataType.String, format);
            }
            //
            doc.drawText(value, x + 2, y + 2, {
                font: font,
                height: rowHeight,
                width: colWidth
            });
        });
        doc.moveDown(5);
    }

    render() {
        return (
            <button className="btn btn-danger" style={{ float: "right", marginRight: "10px" }} onClick={this.exportPDF.bind(this)}>Export To PDF</button>

        )
    }
}

function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = parseInt(hours);
    var minutes = (num - (rhours * 3600)) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function ConvertMinutes(num) {
    var d = Math.floor(num / 1440); // 60*24
    var h = Math.floor((num - (d * 1440)) / 60);
    var m = Math.round(num % 60);
    var dayText = d > 1 ? " Days " : " Day ";
    return d + dayText + h + " Hrs " + m + " Mins"
}

function ConvertSeconds(totalSeconds) {
    // var days = Math.floor(num/86400);
    // var hours = Math.floor(num/3600)
    // var minutes = Math.floor(num/60);
    var minutes = Math.round((totalSeconds % 3600) / 60);
    var hours = Math.round((totalSeconds % 86400) / 3600);
    var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
    // let d = (new Date(t0)) - (new Date(t1));
    // let weekdays     = Math.floor(d/1000/60/60/24/7);
    // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
    // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
    // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
    var dayText = days > 1 ? " Days " : " Day ";
    return days + dayText + hours + " Hrs " + minutes + " Mins"
}

function ConvertHHMMSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}
function convertMeters(metersValue) {
    return (metersValue / 1000).toFixed(2);
}