import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import Constant from "../common/googlemapkey";

var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
export default class TptVicinityScreen extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Trucks within Fuel Stations",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },

			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,

			plant_code:'',
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
            fuel_station_options:[],
            fuel_station_data:[],
            fuelstagingtrucks:[],
			fuelstagingtrucks_copy:[],
            selectFuelStationName:"All",
            markersList:[],
		};
		this.popmarker=this.popmarker.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
			var tptCode = localStorage.getItem('transportercode');
			var vehicle_group = localStorage.getItem("vehicle_group");
			var tptrucks = []
			if(vehicle_group != "")
			{
				var tparms = {
					transporter_code:tptCode,
					region:vehicle_group
				}
				redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
				.then((response) => {
				var records = response.data.records;
					// console.log("records ", records)
					tptrucks = records;
					this.setState({
						tpttrucks:records
					})
				})
			}
            redirectURL.post("/consignments/truckswithinfuelstations").then((response)=>{
				var fuelstagingtrucks = response.data.fuelstagingtrucks
                console.log(response.data,"fuel data")
                var fuel_station_options = [{label:"All", value: "All"}];
                response.data.trucksfuelstn.map((e)=>{
                    console.log(e, "each fuel station record")
                    fuel_station_options.push({label:e.fuel_station_name, value: e.fuel_station_name})
                })
				var tpttrucks = tptrucks;
				console.log("tpttrucks ",tpttrucks)
				var trucksarr = []
				if(tpttrucks.length > 0)
				{
					fuelstagingtrucks.filter(f =>
						tpttrucks.filter((d )=> {
							if(d.truck_no == f.truck_no){									
								trucksarr.push(f)
							}
						})
					);
				}
				else
				{
					trucksarr = fuelstagingtrucks
				}
                this.setState({
                    fuel_station_options : fuel_station_options,
                    fuel_station_data : response.data.trucksfuelstn,
                    rowData : trucksarr,
					fuelstagingtrucks_copy: trucksarr
                })
                this.renderMapDefault();
            })

    }
	renderMapDefault = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMapDefault");
		window.initMapDefault = this.initMapDefault
 	}
	initMapDefault = () => {

		var lt=28.4519751;
		var ln=77.0310713;

    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },
            disableDefaultUI: true,
            zoomControl: true*/
		});
        var bounds = new window.google.maps.LatLngBounds();
        var plant = {
            url: require("../../assets/icons/gas-pump.png"), // url
            scaledSize: new window.google.maps.Size(32,32), // scaled size
            origin: new window.google.maps.Point(0,0), // origin
            anchor: new window.google.maps.Point(0, 0) // anchor
          };
        // console.log(coords);
        var markersList = this.state.markersList
        this.state.fuel_station_data.map((e)=>{
            bounds.extend(new window.google.maps.LatLng(e.latitude,e.longitude));
            var marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(e.latitude,e.longitude),
                map: map,
                icon: plant,
                title : e.fuel_station_name
            });
            markersList.push(marker)
            var contentarr = [];
            contentarr.push({"key":"Station Name", "value":e.fuel_station_name})
            contentarr.push({"key":"Address", "value":e.fuel_station_address})


            var contentString = infoBox(marker.icon, "", contentarr,'')

            var infowindow = new window.google.maps.InfoWindow({
                content: contentString
            });

            marker.setAnimation(window.google.maps.Animation.DROP)
            marker.addListener('click', function() {
                infowindow.open(map, marker);
            });
        })
        this.setState({markersList:markersList})
        map.fitBounds(bounds)

	}

	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	  }

formHandler = (event) =>{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}

		// this.setState({
		// 	loadshow:'show-m',
		// });
		// console.log(this.state.markersList)
        // console.log(this.state.selectFuelStationName,"station name")
        this.state.markersList.map((e)=>{
            if(this.state.selectFuelStationName == e.title || this.state.selectFuelStationName == "All"){
				if(this.state.selectFuelStationName != "All")
				{
					console.log(this.state.fuelstagingtrucks_copy, "fuelstagingcopy")
					var filteredData = this.state.fuelstagingtrucks_copy.filter((e) => {
						return e.fuel_station_name == this.state.selectFuelStationName
					})
					console.log(filteredData, this.state.selectFuelStationName, "filtered")
					this.setState({
						fuelstagingtrucks: filteredData
					})
				}
				else
				{
					this.setState({
						fuelstagingtrucks: this.state.fuelstagingtrucks_copy
					})
				}
				
                e.setVisible(true)
            }
            else{
                e.setVisible(false)
            }
        })
    }
	onClickHideAll(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",

		})
	}
	popmarker(e)
	{
		console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		//var image =  require('../../assets/icons/truckblue.png');
		var image =  require('../../assets/icons/truck_na_24.png');
		/*if(data.overspeeding_exception == 1)
		{
			var image =  require('../../assets/icons/truck_overspeed.png');
		}

		if(data.nightdriving_exception == 1)
		{
			var image =  require('../../assets/icons/truck-end.png');
		}*/

		var image =  require('../../assets/icons/truck_na_24.png');
		if(data.status == 1)
		{
			var truckText =  "Inside Plant";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_inside_24.png');
		}
		else if(data.status == 2)
		{
			var truckText =  "Going to Destination";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_going_24.png');
		}
		else if(data.status == 3 || data.status == 4)
		{
			var truckText =  "At Destination Location";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_atdealer_24.png');
		}
		else if(data.status == 5)
		{
			var truckText =  "Left Destination Location";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_leftdealer_24.png');
		}
		else if(data.status == 6)
		{
			var truckText =  "Return to Plant";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_return_24.png');
		}
		else if(data.status == 7)
		{
			var truckText =  "Empty Truck Outside Plant";
			var color =  "#333333";var image =  require('../../assets/icons/truck_na_24.png');
			var image =  require('../../assets/icons/truck_empty_24.png');
		}
		else{
			var truckText =  "N/A";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_na_24.png');
		}
		if(this.state.adaniCheck == 1)
		{
			if(data.speed > 20)
			{
				var image =  require('../../assets/icons/shuttle_red.png');
			}
			else
			{
				var image =  require('../../assets/icons/shuttle_green.png');
			}
		}
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});


		var contentarr = []
		var headerTitle = truckText
		if(this.state.adaniCheck == 1)
		{
			contentarr.push({"key":"Shuttle No", "value":data.truck_no});
		}
		else
		{
			contentarr.push({"key":"Truck No", "value":data.truck_no});
		}
		contentarr.push({"key":"Transporter Name", "value":data.transporter_name});
		contentarr.push({"key":"Distance from geofence", "value":data.distance_from_coordinates});
		//contentarr.push({"key":"LSP Name", "value":data.lsp_name});
		contentarr.push({"key":"Address", "value":data.area+", "+data.state});

		var contentString = infoBox(image, headerTitle, contentarr, '')
		/*
		var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Department:" + data.dept_code+"<br />Address:"+data.address;
		var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+truckText+"</td></tr><tr><th>Distance from Geofence: </th><td style='text-align: right'>"+data.distancel
		contentString = contentString + " (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
		contentString = contentString + data.transporter_name + "</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name+"</td></tr><tr><th>Department:</th>";
		contentString = contentString + "<td style='text-align: right'>"+data.dept_code+"</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
			*/
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});

		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});

		marker.setMap(map);


	}
   onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};


	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		
		var columnDefs= [
			{
			  headerName: "Truck No",
			  field: "truck_no",
			  width: 140,


			},
		 	// {
		    //   headerName: "Transporter Name",
		    //   field: "transporter_name",
		    //   width: 200,

		    // },
			{
				headerName: "Fuel Station City",
				field: "fuel_station_city",
				width: 140,
			},
			{
				headerName: "Fuel Station Name",
				field: "fuel_station_name",
				width: 200,

			},
			{
				headerName: "Fuel Station Address",
				field: "fuel_station_address",
				width: 200,

			},
			{
				headerName: "Distance from Location (KM)",
				field: "distance_from_coordinates",
				width: 200,
			},
			// {
			// 	headerName: "Last Known City/Town",
			// 	field: "area",
			// 	width: 150,
			// },
			// {
			// 	headerName: "Last Known State",
			// 	field: "state",
			// 	width: 150,
			// },
			{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 150,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
				}

			},
			{
				headerName: "Speed",
				field: "speed",
				width: 140,
			},
			{
				headerName: "Travel Status",
				field: "travel_status",
				width: 140,
			},

		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}

        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>

								</h5>
				   			</div>
				   			<div className="card-body row">
							   <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
								   <div className={"col-xl-12 col-lg-12 "+this.state.showStateTrucksMsg}>
								   	{/* <p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p> */}
								   </div>

	                    			<div className="row">
                                        <div className="col-xl-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Select Fuel Station : </label>
                                                <Select
                                                    placeholder="All"
                                                    closeMenuOnSelect={true}
                                                    isMulti={false}
                                                    // onChange={this.getRailConsginments.bind(this)}
                                                    className={"border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={this.state.fuel_station_options}
                                                    onChange={(e)=>{this.setState({selectFuelStationName:e.value})}}
                                                />
                                            </div>
                                        </div>

										<div className="form-group col-xl-2 col-lg-2">
											<button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
										</div>
									</div>


								</form>
								<div className="row col-xl-12 col-lg-12">

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>


									<div className="col-xl-6 col-md-6 col-lg-6" id="map_canvas" style={dStyles}></div>

                                    <div className="col-xl-6 col-lg-6">

                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                               
                                                floatingFilter={false}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>

								</div>

                            </div>
				   		</div>
				   	</div>
				 </div>


				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
            </div>
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{

	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);

}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }