import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import CountUp from 'react-countup';
import Select from 'react-select';
import axios from 'axios';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault,
    getDDMMYYYYHHMM,
    getHyphenDDMMMYYYY
} from '../common/utils';


import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class CronJobs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            // rowSelection: "single" ,
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            frameworkComponents: {},
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: { value: "", label: "" },
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            oems: [],
            dealer_code: "",
            dealer_name: "",
            dealer_address: "",
            latitude: "",
            longitude: "",
            city: "",
            state: "",
            region: "",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            latLngArray: [],
            total_cron_jobs:[],
            failed_cron_jobs:[],
            pageTitle:"Cron Jobs",
            loadshow: 'show-n',
            overly:"show-n"
        };

    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }


    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }





    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    /*Alert Popups*/
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        await window.location.reload();
    }




    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRso: "",
            sliderUpdate: "",
            bulkslide: "",
            sliderBulk: ""
        });

    }

    onClickCounter(params) {
		if (params == "total_cron_jobs"){
			this.gridApi.setRowData(this.state.total_cron_jobs);
			this.gridApi.onFilterChanged();
		}
		if (params == "failed_cron_jobs"){
			this.gridApi.setRowData(this.state.failed_cron_jobs);
			this.gridApi.onFilterChanged();
		}
		
	}

    render() {
        var columnWithDefs = [
            {
                headerName: "Cron Job Name",
                field: "cron_job_name",
                width: 220,

            },
            {
                headerName: "Purpose",
                field: "purpose",
                width: 220,

            },
            {
                headerName: "Frequency",
                field: "frequency",
                width: 220,

            },
            {
                headerName: "Last Updated On",
                field: "last_updated_on",
                width: 220,
                valueGetter: function (params) {
                    if (params.data.last_updated_on != undefined && params.data.last_updated_on != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.last_updated_on)
                    }
                    else {
                        return "";
                    }
                },

            },
            {
                headerName: "Impact Statement",
                field: "impact_statement",
                width: 220,

            },

        ]

        return (
            <div className="">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="row col-xl-12">

                            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                                <div className="card">
                                    <div className="card-body" id="cronJobsCounterCardBody">
                                        <div className="crm-numbers pb-0">
                                            <div className="row">
                                                <div className="col " style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "total_cron_jobs")}>
                                                    <span className="f13">
                                                        <i className="icofont icofont-ui-browser f22 txt-success"></i>
                                                        <br /> Total Cron Jobs
                                                    </span>
                                                    <h4 className="txt-success-medium f50">
                                                        <span className="counter">
                                                            <CountUp end={(this.state.total_cron_jobs != undefined) ? this.state.total_cron_jobs.length : 0} />
                                                        </span>
                                                    </h4>

                                                </div>

                                                <div className="col " style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "failed_cron_jobs")}>
                                                    <span className="f13">
                                                        <i className="icofont icofont-ui-browser f24 txt-danger"></i>
                                                        <br /> Failed Cron Jobs
                                                    </span>
                                                    <h4 className="txt-danger-medium f50">
                                                        <span className="counter">
                                                            <CountUp end={(this.state.failed_cron_jobs != undefined) ? this.state.failed_cron_jobs.length : 0} />
                                                        </span>
                                                    </h4>

                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header ">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pageTitle} </span>
                                </h5>
                            </div>
                            <div className="card-body pt-10px" >
                                <div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnWithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        // detailCellRendererParams={this.state.detailCellRendererParams}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}

                                        enableCellChangeFlash={false}
                                        //suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        // rowSelection={this.state.rowSelection}
                                        // onRowSelected={this.onRowSelection.bind(this)}
                                        suppressRowClickSelection={true}
                                        // suppressCellSelection= {true}
                                        // overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                        excelStyles={this.state.excelStyles}
                                    />

                                    {/* 
										<div className={"dataLoadpage " +(this.state.loadshow)}>
										</div>
										<div className={"dataLoadpageimg " +(this.state.loadshow)}>
											<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
										</div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


            </div>

        );


    }
}


function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;

    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh * 3600));
    var mm = Math.floor(hrr / 60)
    if (mm < 10) {
        var mins = "0" + mm;
    }
    else {
        var mins = mm;
    }

    if (hh < 10) {
        var hrs = "0" + hh;
    }
    else {
        var hrs = hh;
    }
    return hrs + ":" + mins;
}

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map11.get(key);
        if (!collection) {
            map11.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map11;
}