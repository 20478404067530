import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
// import axios from 'axios';
// import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import Map from './mapLocationComponent';
// import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';

export default class TruckSidebarComponent extends Component {
    state = {
        modules: AllModules,
        truckData: this.props.truckInfo,
        consignmentlists: this.props.consignments,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        rowData: null,
        animateRows: true,
    };

    handleClick = (e) => {
        e.stopPropagation();
        this.props.context.onCloseUploadDiv();
    };

    render() {

        const lat = this.props.currenttrucklocation[0].latitude
        const lng = this.props.currenttrucklocation[0].longitude

        return (
            <div className="col-xl-12 xol-lg-12">
                <div className="row">
                    <div className="slide-r-title col-xl-12 col-lg-12" style={{ padding: "0px" }}>
                        <h4>
                            Truck No #{this.props.truckNo}
                        </h4>
                    </div>
                    <div className="slide-r-body col-xl-12 col-lg-12" style={{ position: "relative" }}>
                        <div className="col-xl-12 col-lg-12">
                            <Accordion preExpanded='info'>
                            <AccordionItem uuid='info'>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Current Location:
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel key={"map"}>
                                        <Map width={"92%"} coordinates={this.props.currenttrucklocation} marginLeft={"15px"} />
                                    </AccordionItemPanel>                               
                            </AccordionItem>
                            </Accordion>
                        </div>
                        <div className="col-xl-12 col-lg-12 mt-4">
                            <a href={`https://maps.google.com/?q=${lat},${lng}`} target="_blank" rel="noopener noreferrer" marginLeft={"15px"} style={{color: '#000000'}}> Go To Google Map</a>
                        </div>
                        <div className="col-xl-12 col-lg-12 mt-30p">
                            <button onClick={this.handleClick} className="btn btn-default">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}












