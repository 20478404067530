/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import CountUp from 'react-countup';

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYY, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import ChallansColumnChart from './challansColumnChart';
import ChallansStateWiseChart from './challansStateWiseChart';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
//import { thirdchartdatagrid } from '../../../../tpt_node/routes/controller/consignmentController';
//import { getConsignmentsNoGpsLifeTimeCounter } from '../../../../tpt_node/models/consignmentModel';

var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var doOnce = true;
var rad = function (x) {
    return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;
var consigners = [];
var monthName = {
    1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun",
    7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec"
}

var monthNumbers = {
    "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04", "May": "05", "Jun": "06",
    "Jul": "07", "Aug": "08", "Sep": "09", "Oct": "10", "Nov": "11", "Dec": "12"
}
export default class TptVicinityScreen extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            pageTitle: "Vehicle Challans",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            radius: '15',
            rlatitude: '',
            rlongitude: '',
            latitude: '',
            longitude: '',
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            rowData: [],
            originalrowData: [],
            selectedTabLat: '',
            selectedTabLng: '',
            deftab: 'all',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,

            plant_code: '',
            showPlant: 'show-n',
            showAddr: 'show-n',
            request_type: "1",
            view_type: '',
            tabplants: [],
            clickabletab: '',
            plantlist: [],
            stateFormData: "",
            sliderStateEmailsTranslate: "",
            overly: "show-n",
            showStateTrucksMsg: "show-n",
            states_list: [],
            selectedState: [],
            showStates: "show-n",
            showRadius: "show-m",
            usermanualmodal: false,
            tpttrucks: [],
            adaniCheck: 0,
            fuel_station_options: [],
            fuel_station_data: [],
            fuelstagingtrucks: [],
            selectFuelStationName: "All",
            markersList: [],
            fasttagtrucks: [],
            fasttagtnx: [],
            VehicleNumber: [{ "label": "All", value: "All" }],
            btnClicked: [],
            CellRendererFunc: [],
            detailCellRendererParams: {},
            fasttagtruckstnx: [],
            fast_tag_transc: [],
            TransactionStatus: "All",
            fast_tag_screen: [],
            fasttagtruckstnxcopy: [],
            vehicle_truck_no: [],
            vehchallan: [],
            vehchallancopy: [],
            summaryview: "btn-danger",
            detailview: "",
            summaryview_sh: "show-m",
            detailview_sh: "show-n",
            totalTrucks: 0,
            totalTrucksAmount: 0,
            pendingTrucks: 0,
            pendingTrucksAmount: 0,
            paidTrucks: 0,
            paidTrucksAmount: 0,
            challanSeriesData: [],
            challanCategories: [],
            availableYears: [],
            selected_year: [],
            selectedStatus: { "label": "Pending", value: "Pending" },
            selectedMonthYear: [{ "label": "All", value: "All" }],
            monthYearOptions: [],
            uniquedate: [],
            challansChartTitle: "Pending & Paid Challans",
            topchallanSeriesData: [],
            topchallanCategories: [],
            monthchallanSeriesData: [],
            monthchallanCategories: [],
            startDate: "",
            endDate: "",
            startDate1: "",
            endDate1: "",
            challan_image : '',
        };
        this.onLoadChallancharts = this.onLoadChallancharts.bind(this);
        this.onLoadMonthChallancharts = this.onLoadMonthChallancharts.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    onClickBtnData(btn_type, e) {
        //    console.log(btn_type,e)
        if (btn_type == 1) {
            this.setState({
                detailview: "btn-danger",
                summaryview: "btn-default",
                summaryview_sh: "show-n",
                detailview_sh: "show-m"
            })
            var currentTime = new Date();
        }
        else {
            this.setState({
                summaryview: "btn-danger",
                detailview: "btn-default",
                detailview_sh: "show-n",
                summaryview_sh: "show-m"
            })
            //    if(this.state.startDate1 != "" && this.state.startDate1 !="NaN-NaN-NaN" && this.state.endDate1 != "" && this.state.endDate1 !="NaN-NaN-NaN")
            //    {
            //        var sdate = this.state.startDate1;
            //        var edate = this.state.endDate1;
            //    }
            //    else
            //    {
            //        var sdate = "";
            //        var edate = "";
            //    }
            //    if(localStorage.getItem("transportercode") != undefined)
            //     {
            //         var transporter = JSON.parse(localStorage.getItem("transportercode"))
            //     }
            //     else{
            //         var transporter = "";
            //     }
            //     if(Array.isArray(transporter))
            //     {
            //     var transporter_code = transporter[0]
            //     }
            //     else{
            //         var transporter_code = transporter[0]
            //     }
            //    var params = {
            //        truck_no : ["All"],
            //        // selected_months : [moment.parseZone().format("MM-YYYY")],
            //        startdate:sdate,
            //        enddate:edate,
            //        status : "All",
            //        transporter_code:transporter_code 
            //    }
            // this.onLoadData1(params);
            //    this.formatChallanData(this.state.vehchallancopy, [{"label": (currentTime.getFullYear()).toString(), value: (currentTime.getFullYear()).toString()}])
            //     this.formatStateWiseData()
        }
    }
    componentDidMount() {
        var sdate = moment.parseZone().subtract(30, "days").format("YYYY-MM-DD")
        var edate = moment.parseZone().format("YYYY-MM-DD");

        this.setState({
            startDate: sdate,
            endDate: edate,
            startDate1: sdate,
            endDate1: edate
        })
        if (localStorage.getItem("transportercode") != undefined) {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else {
            var transporter = "";
        }
        if (Array.isArray(transporter)) {
            var transporter_code = transporter[0]
        }
        else {
            var transporter_code = transporter[0]
        }

        var params1 = {
            truck_no: ["All"],
            // selected_months : [moment.parseZone().format("MM-YYYY")],
            startdate: sdate,
            enddate: edate,
            status: "Pending",
            transporter_code: transporter_code
        }
        // console.log("params1 ", params1)
        this.onLoadDetailData(params1);

        var params = {
            truck_no: ["All"],
            // selected_months : [moment.parseZone().format("MM-YYYY")],
            startdate: sdate,
            enddate: edate,
            status: "All",
            transporter_code: transporter_code
        }
        this.onLoadData(params);

        var qu = {
            transporter_code: JSON.stringify([params.transporter_code])
        }
        redirectURL.post("/dashboard/tpttrucks", qu).then((response) => {
            var records = response.data.records;
            var vehicle_truck_no = []
            // console.log("records", records)
            if (records.length > 0) {
                records.map((e) => {
                    vehicle_truck_no.push({ value: e.truck_no, label: e.truck_no })
                })
            }

            this.setState({
                vehicle_truck_no: vehicle_truck_no
            })
        })

    }
    formatValue(value) {
        return value.toLocaleString('hi-IN');
    }
    onLoadData = (params) => {
        var s1 = moment.parseZone(new Date(params.startdate + " 00:00:00")).format("MMM-YYYY");
        var s2 = moment.parseZone(new Date(params.enddate + " 00:00:00")).format("MMM-YYYY");
        var y1 = moment.parseZone(new Date(params.startdate + " 00:00:00")).format("YYYY");
        var y2 = moment.parseZone(new Date(params.enddate + " 00:00:00")).format("YYYY");
        var arr = []
        var tptCode = localStorage.getItem('transportercode');
        var vehicle_group = localStorage.getItem("vehicle_group");
        var tptrucks = []
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("records ", records)
                    tptrucks = records;
                    this.setState({
                        tpttrucks: records
                    })
                })
        }
        redirectURL.post("/consignments/challansScreen", params).then((response) => {
            // console.log(response.data,"fasttag data")
            var vehchallan = response.data.vehchallan
            var vehicle_truck_no = [{ label: "All", value: "All" }];
            var totalTrucks = 0;
            var totalTrucksAmount = 0;
            var pendingTrucks = 0;
            var pendingTrucksAmount = 0;
            var paidTrucks = 0;
            var paidTrucksAmount = 0;
            var availableYears = [];
            var currentTime = new Date();
            var year = currentTime.getFullYear();
            var month = currentTime.getMonth() + 1
            month = (month < 10) ? "0" + month : month
            var selectedMonthYear = [
                { "label": s1, "value": s1 },
                { "label": s2, "value": s2 }
            ]

            var monthYearOptions = []
            var tpttrucks = tptrucks;
            // console.log("tpttrucks ",tpttrucks)
            var trucksarr = []
            if (tpttrucks.length > 0) {
                vehchallan.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f.truck_no) {
                            trucksarr.push(f)
                        }
                    })
                );
            }
            else {
                trucksarr = vehchallan
            }
            response.data.uniquedate.map((e) => {
                // console.log(e,"data")
                var transactionDateTime = moment.parseZone(e).format('YYYY-MM')
                transactionDateTime = monthName[parseInt(transactionDateTime.split("-")[1])] + "-" + transactionDateTime.split("-")[0]
                monthYearOptions.push(transactionDateTime)
            })

            trucksarr.map((e) => {
                var challan_year = getHyphenDDMMMYYYY(e.challan_date_time).split("-")[2]
                availableYears.push(challan_year)
                //if(challan_year == currentTime.getFullYear().toString())
                // {
                totalTrucksAmount = totalTrucksAmount + (e.challan_status == "Pending" ? parseInt(e.fine_imposed) : parseInt(e.received_amount) );
                if (e.challan_status == "Pending") {
                    pendingTrucksAmount = pendingTrucksAmount + parseInt(e.fine_imposed)
                }
                else {
                    paidTrucksAmount = paidTrucksAmount + parseInt(e.received_amount)
                }
                if (e.challan_status == "Pending") {
                    pendingTrucks = pendingTrucks + 1
                }
                else {
                    paidTrucks = paidTrucks + 1
                }
                //}

                // var temp1 = vehicle_truck_no.filter((t)=>{
                //     return t.value == e.truck_no
                //  })
                //  if(temp1.length == 0){

                //     vehicle_truck_no.push({label:e.truck_no, value: e.truck_no}) 
                // } 

            })

            // console.log(pendingTrucks,"truck_nos")
            // console.log(monthYearOptions,"monthYearOptionsCopy")
            var monthYearOptionsCopy = [{ "label": "All", "value": "All" }];
            [...new Set(monthYearOptions)].map((e) => {
                monthYearOptionsCopy.push({ "label": e, value: e })
            })

            availableYears = [...new Set(availableYears)];
            availableYears = availableYears.sort((a, b) => a - b)
            var availableYearOptions = [];
            availableYears.map((e) => {
                availableYearOptions.push({ label: e.toString(), value: e.toString() })
            })
            this.setState({
                // vehicle_truck_no : vehicle_truck_no,
                vehchallan: trucksarr,
                vehchallancopy: trucksarr,
                totalTrucksAmount: totalTrucksAmount,
                pendingTrucks: pendingTrucks,
                pendingTrucksAmount: pendingTrucksAmount,
                paidTrucks: paidTrucks,
                paidTrucksAmount: paidTrucksAmount,
                totalTrucks: trucksarr.length,
                monthYearOptions: monthYearOptionsCopy,
                selectedMonthYear: selectedMonthYear,
                availableYears: availableYearOptions,
                selected_year: [{ "label": y1, value: y1 }, { "label": y2, value: y2 }]
            });
            // console.log( trucksarr,"challan")
            // var currentYear = (currentTime.getFullYear()).toString() +"-"+(currentTime.getMonth() + 1 > 9)?currentTime.getMonth() + 1:"0"+(currentTime.getMonth() + 1)
            this.formatChallanData(trucksarr, [{ "label": y1, value: y1 }, { "label": y2, value: y2 }])
            this.formatStateWiseData()
            var yr = [{ "label": y1, value: y1 }, { "label": y2, value: y2 }];
            this.onLoadChallancharts(params, yr);
            this.onLoadMonthChallancharts(params, yr);
        })
    }
    onLoadData1 = (params) => {
        var s1 = moment.parseZone(new Date(params.startdate + " 00:00:00")).format("MMM-YYYY");
        var s2 = moment.parseZone(new Date(params.enddate + " 00:00:00")).format("MMM-YYYY");
        var y1 = moment.parseZone(new Date(params.startdate + " 00:00:00")).format("YYYY");
        var y2 = moment.parseZone(new Date(params.enddate + " 00:00:00")).format("YYYY");
        var arr = []
        var tptCode = localStorage.getItem('transportercode');
        var vehicle_group = localStorage.getItem("vehicle_group");
        var tptrucks = []
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("records ", records)
                    tptrucks = records;
                    this.setState({
                        tpttrucks: records
                    })
                })
        }
        redirectURL.post("/consignments/challansScreen", params).then((response) => {
            var vehchallan = response.data.vehchallan
            // console.log(response.data,"fasttag data")
            var vehicle_truck_no = [{ label: "All", value: "All" }];
            var totalTrucks = 0;
            var totalTrucksAmount = 0;
            var pendingTrucks = 0;
            var pendingTrucksAmount = 0;
            var paidTrucks = 0;
            var paidTrucksAmount = 0;
            var availableYears = [];
            var currentTime = new Date();
            var year = currentTime.getFullYear();
            var month = currentTime.getMonth() + 1
            month = (month < 10) ? "0" + month : month
            var selectedMonthYear = [
                { "label": s1, "value": s1 },
                { "label": s2, "value": s2 }
            ]

            var monthYearOptions = []
            var tpttrucks = tptrucks;
            // console.log("tpttrucks ", tpttrucks)
            var trucksarr = []
            if (tpttrucks.length > 0) {
                vehchallan.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f.truck_no) {
                            trucksarr.push(f)
                        }
                    })
                );
            }
            else {
                trucksarr = vehchallan
            }
            response.data.uniquedate.map((e) => {
                // console.log(e,"data")
                var transactionDateTime = moment.parseZone(e).format('YYYY-MM')
                transactionDateTime = monthName[parseInt(transactionDateTime.split("-")[1])] + "-" + transactionDateTime.split("-")[0]
                monthYearOptions.push(transactionDateTime)
            })

            trucksarr.map((e) => {
                var challan_year = getHyphenDDMMMYYYY(e.challan_date_time).split("-")[2]
                availableYears.push(challan_year)
                //if(challan_year == currentTime.getFullYear().toString())
                //{
                totalTrucksAmount = totalTrucksAmount +  (e.challan_status == "Pending" ? parseInt(e.fine_imposed) : parseInt(e.received_amount) );

                if (e.challan_status == "Pending") {
                    pendingTrucksAmount = pendingTrucksAmount + parseInt(e.fine_imposed)
                }
                else {
                    paidTrucksAmount = paidTrucksAmount + parseInt(e.received_amount)
                }
                if (e.challan_status == "Pending") {
                    pendingTrucks = pendingTrucks + 1
                }
                else {
                    paidTrucks = paidTrucks + 1
                }
                //}

                // var temp1 = vehicle_truck_no.filter((t)=>{
                //     return t.value == e.truck_no
                //  })
                //  if(temp1.length == 0){

                //     vehicle_truck_no.push({label:e.truck_no, value: e.truck_no}) 
                // } 

            })
            // redirectURL.post("/dashboard/tpttrucks",params).then((response)=>{
            //     response.data.truck_no.map((e)=>{
            //         var vehicle_truck_no = e.truck_no
            //         vehicle_truck_no.push(vehicle_truck_no)
            //     })
            // })


            // console.log(monthYearOptions,"monthYearOptionsCopy")
            var monthYearOptionsCopy = [{ "label": "All", "value": "All" }];
            [...new Set(monthYearOptions)].map((e) => {
                monthYearOptionsCopy.push({ "label": e, value: e })
            })

            availableYears = [...new Set(availableYears)];
            availableYears = availableYears.sort((a, b) => a - b)
            var availableYearOptions = [];
            availableYears.map((e) => {
                availableYearOptions.push({ label: e.toString(), value: e.toString() })
            })
            this.setState({
                // vehicle_truck_no : vehicle_truck_no,
                vehchallan: trucksarr,
                vehchallancopy: trucksarr,
                totalTrucksAmount: totalTrucksAmount,
                pendingTrucks: pendingTrucks,
                pendingTrucksAmount: pendingTrucksAmount,
                paidTrucks: paidTrucks,
                paidTrucksAmount: paidTrucksAmount,
                totalTrucks: trucksarr.length,
                monthYearOptions: monthYearOptionsCopy,
                selectedMonthYear: selectedMonthYear,
                availableYears: availableYearOptions,
                selected_year: [{ "label": y1, value: y1 }, { "label": y2, value: y2 }]
            });
            // var currentYear = (currentTime.getFullYear()).toString() +"-"+(currentTime.getMonth() + 1 > 9)?currentTime.getMonth() + 1:"0"+(currentTime.getMonth() + 1)
            this.formatChallanData(trucksarr, [{ "label": y1, value: y1 }, { "label": y2, value: y2 }])
            this.formatStateWiseData()
            var yr = [{ "label": y1, value: y1 }, { "label": y2, value: y2 }];
            this.onLoadChallancharts(params, yr);
            this.onLoadMonthChallancharts(params, yr);
        })
    }

    onLoadDetailData = (params) =>{
        // console.log("Step1")
        var s1 = moment.parseZone(new Date(params.startdate + " 00:00:00")).format("MMM-YYYY");
        var s2 = moment.parseZone(new Date(params.enddate + " 00:00:00")).format("MMM-YYYY");
        var y1 = moment.parseZone(new Date(params.startdate + " 00:00:00")).format("YYYY");
        var y2 = moment.parseZone(new Date(params.enddate + " 00:00:00")).format("YYYY");
        var arr = []
        var tptCode = localStorage.getItem('transportercode');
        var vehicle_group = localStorage.getItem("vehicle_group");
        var tptrucks = []
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("records ", records)
                    tptrucks = records;
                    this.setState({
                        tpttrucks: records
                    })
                })
        }

        redirectURL.post("/consignments/challansScreen", params).then((response) => {
            console.log(response.data,"fasttag data")
            var vehchallan = response.data.vehchallan
            var vehicle_truck_no = [{ label: "All", value: "All" }];
            var totalTrucks = 0;
            var totalTrucksAmount = 0;
            var pendingTrucks = 0;
            var pendingTrucksAmount = 0;
            var paidTrucks = 0;
            var paidTrucksAmount = 0;
            var availableYears = [];
            var currentTime = new Date();
            var year = currentTime.getFullYear();
            var month = currentTime.getMonth() + 1
            month = (month < 10) ? "0" + month : month
            var selectedMonthYear = [
                { "label": s1, "value": s1 },
                { "label": s2, "value": s2 }
            ]
            var tpttrucks = tptrucks;
            // console.log("tpttrucks ",tpttrucks)
            var trucksarr = []
            if (tpttrucks.length > 0) {
                vehchallan.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f.truck_no) {
                            trucksarr.push(f)
                        }
                    })
                );
            }
            else {
                trucksarr = vehchallan
            }
            var monthYearOptions = []
            response.data.uniquedate.map((e) => {
                // console.log(e,"data")
                var transactionDateTime = moment.parseZone(e).format('YYYY-MM')
                transactionDateTime = monthName[parseInt(transactionDateTime.split("-")[1])] + "-" + transactionDateTime.split("-")[0]
                monthYearOptions.push(transactionDateTime)
            })
            // console.log("response.data.vehchallan ",trucksarr)
            trucksarr.map((e) => {
                var challan_year = getHyphenDDMMMYYYY(e.challan_date_time).split("-")[2]
                availableYears.push(challan_year)
                //if(challan_year == currentTime.getFullYear().toString())
                // {
                totalTrucksAmount = totalTrucksAmount +  (e.challan_status == "Pending" ? parseInt(e.fine_imposed) : parseInt(e.received_amount) );
                if (e.challan_status == "Pending") {
                    pendingTrucksAmount = pendingTrucksAmount + parseInt(e.fine_imposed)
                }
                else {
                    paidTrucksAmount = paidTrucksAmount + parseInt(e.received_amount)
                }
                if (e.challan_status == "Pending") {
                    pendingTrucks = pendingTrucks + 1
                }
                else {
                    paidTrucks = paidTrucks + 1
                }
                //}

                // var temp1 = vehicle_truck_no.filter((t)=>{
                //     return t.value == e.truck_no
                //  })
                //  if(temp1.length == 0){

                //     vehicle_truck_no.push({label:e.truck_no, value: e.truck_no}) 
                // } 

            })

            // console.log("response.data.vehchallan2 ",trucksarr)
            // console.log(pendingTrucks,"truck_nos")
            // console.log(monthYearOptions,"monthYearOptionsCopy")
            var monthYearOptionsCopy = [{ "label": "All", "value": "All" }];
            [...new Set(monthYearOptions)].map((e) => {
                monthYearOptionsCopy.push({ "label": e, value: e })
            })

            availableYears = [...new Set(availableYears)];
            availableYears = availableYears.sort((a, b) => a - b)
            var availableYearOptions = [];
            availableYears.map((e) => {
                availableYearOptions.push({ label: e.toString(), value: e.toString() })
            })

            // console.log("response.data.vehchallan3 ",trucksarr)
            this.setState({
                // vehicle_truck_no : vehicle_truck_no,
                rowData: trucksarr,

            });
            // console.log( trucksarr,"challan")
            // var currentYear = (currentTime.getFullYear()).toString() +"-"+(currentTime.getMonth() + 1 > 9)?currentTime.getMonth() + 1:"0"+(currentTime.getMonth() + 1)
            this.formatChallanData(trucksarr, [{ "label": y1, value: y1 }, { "label": y2, value: y2 }])
            this.formatStateWiseData()
            var yr = [{ "label": y1, value: y1 }, { "label": y2, value: y2 }];
            this.onLoadChallancharts(params, yr);
            this.onLoadMonthChallancharts(params, yr);
        })
    }
    onLoadDetailData1 = (params) => {
        var s1 = moment.parseZone(new Date(params.startdate + " 00:00:00")).format("MMM-YYYY");
        var s2 = moment.parseZone(new Date(params.enddate + " 00:00:00")).format("MMM-YYYY");
        var y1 = moment.parseZone(new Date(params.startdate + " 00:00:00")).format("YYYY");
        var y2 = moment.parseZone(new Date(params.enddate + " 00:00:00")).format("YYYY");
        var arr = []
        redirectURL.post("/consignments/challansScreen", params).then((response) => {
            // console.log(response.data,"fasttag data")
            var vehicle_truck_no = [{ label: "All", value: "All" }];
            var totalTrucks = 0;
            var totalTrucksAmount = 0;
            var pendingTrucks = 0;
            var pendingTrucksAmount = 0;
            var paidTrucks = 0;
            var paidTrucksAmount = 0;
            var availableYears = [];
            var currentTime = new Date();
            var year = currentTime.getFullYear();
            var month = currentTime.getMonth() + 1
            month = (month < 10) ? "0" + month : month
            var selectedMonthYear = [
                { "label": s1, "value": s1 },
                { "label": s2, "value": s2 }
            ]

            var monthYearOptions = []
            response.data.uniquedate.map((e) => {
                // console.log(e,"data")
                var transactionDateTime = moment.parseZone(e).format('YYYY-MM')
                transactionDateTime = monthName[parseInt(transactionDateTime.split("-")[1])] + "-" + transactionDateTime.split("-")[0]
                monthYearOptions.push(transactionDateTime)
            })

            response.data.vehchallan.map((e) => {
                var challan_year = getHyphenDDMMMYYYY(e.challan_date_time).split("-")[2]
                availableYears.push(challan_year)
                //if(challan_year == currentTime.getFullYear().toString())
                //{
                totalTrucksAmount = totalTrucksAmount + (e.challan_status == "Pending" ? parseInt(e.fine_imposed) : parseInt(e.received_amount) );
                if (e.challan_status == "Pending") {
                    pendingTrucksAmount = pendingTrucksAmount + parseInt(e.fine_imposed)
                }
                else {
                    paidTrucksAmount = paidTrucksAmount + parseInt(e.received_amount)
                }
                if (e.challan_status == "Pending") {
                    pendingTrucks = pendingTrucks + 1
                }
                else {
                    paidTrucks = paidTrucks + 1
                }
                //}

                // var temp1 = vehicle_truck_no.filter((t)=>{
                //     return t.value == e.truck_no
                //  })
                //  if(temp1.length == 0){

                //     vehicle_truck_no.push({label:e.truck_no, value: e.truck_no}) 
                // } 

            })
            // redirectURL.post("/dashboard/tpttrucks",params).then((response)=>{
            //     response.data.truck_no.map((e)=>{
            //         var vehicle_truck_no = e.truck_no
            //         vehicle_truck_no.push(vehicle_truck_no)
            //     })
            // })


            // console.log(monthYearOptions,"monthYearOptionsCopy")
            var monthYearOptionsCopy = [{ "label": "All", "value": "All" }];
            [...new Set(monthYearOptions)].map((e) => {
                monthYearOptionsCopy.push({ "label": e, value: e })
            })

            availableYears = [...new Set(availableYears)];
            availableYears = availableYears.sort((a, b) => a - b)
            var availableYearOptions = [];
            availableYears.map((e) => {
                availableYearOptions.push({ label: e.toString(), value: e.toString() })
            })
            this.setState({
                // vehicle_truck_no : vehicle_truck_no,
                vehchallan: response.data.vehchallan,
                vehchallancopy: response.data.vehchallan,

            });
            console.log("vehchallancopy", response.data.vehchallan)
            // var currentYear = (currentTime.getFullYear()).toString() +"-"+(currentTime.getMonth() + 1 > 9)?currentTime.getMonth() + 1:"0"+(currentTime.getMonth() + 1)
            this.formatChallanData(response.data.vehchallan, [{ "label": y1, value: y1 }, { "label": y2, value: y2 }])
            this.formatStateWiseData()
            var yr = [{ "label": y1, value: y1 }, { "label": y2, value: y2 }];
            this.onLoadChallancharts(params, yr);
            this.onLoadMonthChallancharts(params, yr);
        })
    }
    onLoadChallancharts(parameters, year) {
        // if(parameters.selected_months.indexOf("All") >= 0)
        // {
        //     var monthYearOptions = this.state.monthYearOptions;
        //     var years = []
        //     var year = moment.parseZone().format("YYYY");
        //     for(var m =2017;m<year;m++)
        //     {
        //         years.push(m.toString())
        //     }
        // }
        // else
        // {
        // console.log("selected_year ", this.state.selected_year)
        // var years = []
        // if(this.state.selected_year.length > 0)
        // {
        //     this.state.selected_year.map((item) => {
        //         years.push(item.value.toString())
        //     })
        // }
        // else
        // {
        //     var year = moment.parseZone().format("YYYY");
        //     years.push(year.toString())
        // }
        // console.log("years ", years)
        // parameters.years = years;
        let newArray = [];
        // Declare an empty object
        let totuniqueObject = {};
        var totobjTitle;
        // Loop for the array elements
        for (let i in year) {

            // Extract the title
            totobjTitle = year[i]['value'];

            // Use the title as the index
            totuniqueObject[totobjTitle] = year[i];
        }

        // Loop to push unique object into array
        for (var a in totuniqueObject) {
            newArray.push(totuniqueObject[a]);
        }
        var years = []
        if (newArray.length > 0) {
            newArray.map((item) => {
                years.push(item.value)
            })
        }
        parameters.years = years;
        if (localStorage.getItem("transportercode") != undefined) {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else {
            var transporter = "";
        }
        if (Array.isArray(transporter)) {
            parameters.transporter_code = transporter[0];
        }
        else {
            parameters.transporter_code = transporter;
        }
        // console.log("parameters here ", parameters)
        redirectURL.post("/dashboard/topchallans", parameters)
            .then((response) => {
                // console.log("response data ", response.data)
                var records = response.data;
                var seriesList = []
                var categories = []
                if (records.length > 0) {
                    records.map((item) => {

                        seriesList.push({ name: item.truck_no, y: item.amount, "title": "INR " + item.amount })
                        categories.push(item.truck_no);

                    })
                    seriesList.sort(function (a, b) {
                        return b.y - a.y;
                    });
                    // console.log({"name": "Month & Year", data: seriesList}, "check")
                    this.setState({
                        topchallanSeriesData: { "name": "Top Challa Offender", data: seriesList },
                        topchallanCategories: categories,
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                }

            })
    }

    onLoadMonthChallancharts(parameters, year) {
        // if(parameters.selected_months.indexOf("All") >= 0)
        // {
        //     var monthYearOptions = this.state.monthYearOptions;
        //     var years = []
        //     var year = moment.parseZone().format("YYYY");
        //     for(var m =2017;m<year;m++)
        //     {
        //         years.push(m.toString())
        //     }
        // }
        // else
        // {
        //     var years = parameters.selected_months;
        // }

        // parameters.years = years;
        // console.log("this.state.selected_year ", this.state.selected_year)
        // var years = [];
        // console.log(this.state.selected_year.length,"this.state.selected_year.length")
        //     if(this.state.selected_year.length > 0)
        //     {
        //         this.state.selected_year.map((item) => {
        //             years.push(item.value.toString())
        //         })
        //     }
        //     else
        //     {
        //         var year = moment.parseZone().format("YYYY");
        //         years.push(year.toString())
        //     }
        //     console.log("years ", years)

        let newArray = [];
        // Declare an empty object
        let totuniqueObject = {};
        var totobjTitle;
        // Loop for the array elements
        for (let i in year) {

            // Extract the title
            totobjTitle = year[i]['value'];

            // Use the title as the index
            totuniqueObject[totobjTitle] = year[i];
        }

        // Loop to push unique object into array
        for (var a in totuniqueObject) {
            newArray.push(totuniqueObject[a]);
        }

        var years = []
        if (newArray.length > 0) {
            newArray.map((item) => {
                years.push(item.value)
            })
        }
        parameters.years = years;

        if (localStorage.getItem("transportercode") != undefined) {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else {
            var transporter = "";
        }
        if (Array.isArray(transporter)) {
            parameters.transporter_code = transporter[0];
        }
        else {
            parameters.transporter_code = transporter;
        }
        if (this.state.startDate != "" && this.state.startDate != "NaN-NaN-NaN" && this.state.endDate != "" && this.state.endDate != "NaN-NaN-NaN") {
            var sdate = this.state.startDate;
            var edate = this.state.endDate;
        }
        else {
            var sdate = "";
            var edate = "";
        }
        parameters.startdate = sdate;
        parameters.enddate = edate;
        // console.log(" month parameters ", parameters)
        redirectURL.post("/dashboard/monthlychallans", parameters)
            .then((response) => {
                // console.log("response data ", response.data)
                var records = response.data;
                var seriesList = []
                var categories = []
                if (records.length > 0) {
                    records.map((item) => {

                        seriesList.push({ name: item.month_year, y: item.amount_in_rs, "title": "INR " + item.amount_in_rs })
                        categories.push(item.month_year);

                    })
                    seriesList.sort(function (a, b) {
                        return b.y - a.y;
                    });
                    // console.log({"name": "Month & Year", data: seriesList}, "check")
                    this.setState({
                        monthchallanSeriesData: { "name": "Month", data: seriesList },
                        monthchallanCategories: categories,
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                }

            })
    }

    formatStateWiseData() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })

        var rowdata = this.state.vehchallan;
        var categories = [];
        var seriesList = [];

        var truckChallans = groupBy(rowdata, rdata => rdata.state_code);
        var pendingList = [];
        var paidList = [];
        truckChallans.forEach((monthData, monthKey) => {

            seriesList.push({ name: monthKey, y: monthData.length, "title": monthData.length })


        })
        seriesList.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return b.y - a.y;
        });
        seriesList.map(function (sl) {
            categories.push(sl.name)
        });
        // console.log( seriesList, categories,"check")
        this.setState({
            stateWiseSeriesData: { "name": "State", data: seriesList },
            stateWiseCategories: categories,
            loadshow: "show-n",
            overly: "show-n"
        })


    }

    formatChallanData(challanTrucks, yr) {
        // console.log(year, "Passed year")
        if (yr == null || yr == undefined) {
            var yer = moment.parseZone().format("YYYY");
            var year = [{ "value": yer.toString(), "label": yer.toString() }];
        }
        else {
            var year = yr;
        }
        let newArray = [];
        // Declare an empty object
        let totuniqueObject = {};
        var totobjTitle;
        // Loop for the array elements
        for (let i in year) {

            // Extract the title
            totobjTitle = year[i]['value'];

            // Use the title as the index
            totuniqueObject[totobjTitle] = year[i];
        }

        // Loop to push unique object into array
        for (var a in totuniqueObject) {
            newArray.push(totuniqueObject[a]);
        }

        this.setState({
            selected_year: year
        })
        var challanCategories = [];
        var challanTrucks = challanTrucks.map((e) => {
            var challan_month_year = moment.parseZone(e.challan_date).format('YYYY-MM')
            e.challan_month_year = challan_month_year
            return e;
        })
        // console.log(challanTrucks, "challanTRucks")
        challanTrucks.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.challan_date) - new Date(b.challan_date);
        });
        // console.log(challanTrucks, "sort4ed Data")
        var truckChallans = groupBy(challanTrucks, rdata => rdata.challan_month_year);
        var pendingList = [];
        var paidList = [];
        var years = [];

        newArray.map((e) => {
            years.push(e.value)
        })

        truckChallans.forEach((monthData, monthKey) => {
            var monthYear = monthKey.split("-")[0];
            // console.log(years,monthKey,"yearsmonthkey")
            if (years.includes(monthYear)) {
                var pendingData = monthData.filter(e => e.challan_status == "Pending");
                var paidData = monthData.filter(e => e.challan_status != "Pending");
                pendingList.push({ "name": "Pending Challan", "title": pendingData.length, y: pendingData.length });
                paidList.push({ "name": "Paid Challan", "title": paidData.length, y: paidData.length });
                challanCategories.push(monthKey)
            }
        })
        var challanSeriesData = [{
            name: "Pending Challans",
            data: pendingList
        },
        {
            name: "Paid Challans",
            data: paidList
        }];
        challanCategories = formatYearMonth(challanCategories)
        // console.log(challanSeriesData);
        // console.log(challanCategories,"categories")
        var totalTrucks = 0;
        var totalTrucksAmount = 0;
        var pendingTrucks = 0;
        var pendingTrucksAmount = 0;
        var paidTrucks = 0;
        var paidTrucksAmount = 0;
        var filtered_challans = this.state.vehchallancopy.filter((e) => {
            return years.includes(moment.parseZone(e.challan_date).format('YYYY'))
        })
        filtered_challans.map((e) => {
            // var challan_month_year = moment.parseZone(e.challan_date).format('YYYY')
            // if(challan_month_year == "year")
            // {
            totalTrucksAmount = totalTrucksAmount + (e.challan_status == "Pending" ? parseInt(e.fine_imposed) : parseInt(e.received_amount) );
            if (e.challan_status == "Pending") {
                pendingTrucksAmount = pendingTrucksAmount + parseInt(e.fine_imposed)
            }
            else {
                paidTrucksAmount = paidTrucksAmount + parseInt(e.received_amount)
            }
            if (e.challan_status == "Pending") {
                pendingTrucks = pendingTrucks + 1
            }
            else {
                paidTrucks = paidTrucks + 1
            }
            // }

        })

        var chartTitle = "Pending & Paid Challans" + " -" + " (" + years.join(",") + ")"
        this.setState({
            challanCategories: challanCategories,
            challanSeriesData: challanSeriesData,
            totalTrucksAmount: totalTrucksAmount,
            // pendingTrucks : pendingTrucks,
            // pendingTrucksAmount : pendingTrucksAmount,
            // paidTrucks : paidTrucks,
            // paidTrucksAmount : paidTrucksAmount,
            // totalTrucks : filtered_challans.length,
            challansChartTitle: chartTitle,

        })

        var params = {};
        this.onLoadChallancharts(params, newArray);
        this.onLoadMonthChallancharts(params, newArray);
    }

    toggleBounce() {
        if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
        } else {
            marker.setAnimation(window.google.maps.Animation.BOUNCE);
        }

    }

    formHandler = async (event) => {
        event.preventDefault();
        // console.log(this.state.VehicleNumber, this.state.selectedStatus,"data fasttagscreen");

        var selectedMonthYear = [];
        if (this.state.selectedMonthYear != null && this.state.selectedMonthYear != "") {
            // console.log(this.state.selectedMonthYear,"selectedMonthYear")
            this.state.selectedMonthYear.map((e) => {
                if (e.value == "All") {
                    selectedMonthYear.push(e.value)
                }
                else {
                    var monthNo = monthNumbers[e.value.split("-")[0]]
                    selectedMonthYear.push(monthNo + "-" + e.value.split("-")[1])
                }
            })
        }
        else {
            selectedMonthYear.push("All")
        }

        var VehicleNumber = [];
        if (this.state.VehicleNumber != null && this.state.VehicleNumber != "") {
            this.state.VehicleNumber.map((e) => {

                VehicleNumber.push(e.value)
            })
        }
        else {
            VehicleNumber.push("All")
        }
        var status = this.state.selectedStatus
        if (this.state.startDate != "" && this.state.startDate != "NaN-NaN-NaN" && this.state.endDate != "" && this.state.endDate != "NaN-NaN-NaN") {
            var sdate = this.state.startDate;
            var edate = this.state.endDate;
        }
        else {
            var sdate = "";
            var edate = "";
        }
        if (localStorage.getItem("transportercode") != undefined) {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else {
            var transporter = "";
        }
        if (Array.isArray(transporter)) {
            var transporter_code = transporter[0]
        }
        else {
            var transporter_code = transporter[0]
        }
        var tptCode = localStorage.getItem('transportercode');
        var vehicle_group = localStorage.getItem("vehicle_group");
        var tptrucks = []
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("records ", records)
                    tptrucks = records;
                    this.setState({
                        tpttrucks: records
                    })
                })
        }
        var params = {
            truck_no: VehicleNumber,
            // selected_months: selectedMonthYear,
            status: this.state.selectedStatus.value,
            startdate: sdate,
            enddate: edate,
            transporter_code: transporter_code
        }
        redirectURL.post("/consignments/challansScreen", params).then((response) => {
            var vehchallan = response.data.vehchallan
            // console.log('vehicle', vehchallan)
            var tpttrucks = tptrucks;
            var trucksarr = []
            if (tpttrucks.length > 0) {
                vehchallan.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f.truck_no) {
                            trucksarr.push(f)
                        }
                    })
                );
            }
            else {
                trucksarr = vehchallan
            }
            this.setState({
                rowData: trucksarr
            })
        })

    }
    onClickHideAll = () =>{
        $("#email_ids").val("");
        this.setState({
            overly: "show-n",
            sliderStateEmailsTranslate: "",
            sliderRso: "",
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;

    };


    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + d.getDate();
        this.setState({
            startDate: startdate
        })
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + d.getDate();
        this.setState({
            endDate: startdate
        })
    }

    handlerStartDateTime1 = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + d.getDate();
        this.setState({
            startDate1: startdate
        })
    }

    handlerEndDateTime1 = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + d.getDate();
        this.setState({
            endDate1: startdate
        })
    }
    onClickFilterData() {
        if (this.state.startDate1 != "" && this.state.startDate1 != "NaN-NaN-NaN" && this.state.endDate1 != "" && this.state.endDate1 != "NaN-NaN-NaN") {
            var sdate = this.state.startDate1;
            var edate = this.state.endDate1;
        }
        else {
            var sdate = "";
            var edate = "";
        }
        if (localStorage.getItem("transportercode") != undefined) {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else {
            var transporter = "";
        }
        if (Array.isArray(transporter)) {
            var transporter_code = transporter[0]
        }
        else {
            var transporter_code = transporter[0]
        }
        var params = {
            truck_no: ["All"],
            // selected_months : [moment.parseZone().format("MM-YYYY")],
            startdate: sdate,
            enddate: edate,
            status: "All",
            transporter_code: transporter_code
        }
        this.onLoadData1(params);
    }

    popmarker = item => {
        if (item.colDef.field == 'challen_print_link') {
            try {
                var params = {
                    challan_no: item.data.challan_no
                }
                redirectURL.post('/dashboard/getImageForChallan', params).then(response => {
                    let challan_image = response.data[0].challan_image
                    if (challan_image != undefined) {
                        challan_image = challan_image.toString('base64')
                        if (challan_image != undefined && challan_image !== '') {
                            this.setState({
                                challan_image,
                                sliderRso: "slider-translate-50p",
                                sliderType: 1,
                                sliderTitle: "Challan Image"
                            });
                        }
                    }

                })

            } catch (e) {
                this.setState({
                    show: true,
                    basicTitle: 'No Details Found',
                    basicType: 'info'
                })
            }
           
        } else if (item.colDef.field == "reciept_link") {
            var params = {
                challan_no: item.data.challan_no
            }
            try {
                redirectURL.post('/dashboard/getImageForReceipt', params).then(response => {
                    let receipt_image = response.data[0].receipt_image
                    if (receipt_image != undefined) {
                        receipt_image = receipt_image.toString('base64')
                        if (receipt_image !== undefined && receipt_image !== '') {
                            this.setState({
                                challan_image: receipt_image,
                                sliderRso: "slider-translate-50p",
                                sliderType: 1,
                                sliderTitle: "Reciept Image"
                            });
                        }
                    }

                })
            } catch (e) {
                this.setState({
                    show: true,
                    basicTitle: 'No details Found',
                    basicType: 'info'
                })
            }
           
        }
    }

    render() {

        var columnDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 140,


            },
            {
                headerName: "Engine Number",
                field: "engine_no",
                width: 200,

            },
            {
                headerName: "Challan Number",
                field: "challan_no",
                width: 150,

            },
            {
                headerName: "Challan Date",
                field: "challan_date_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.challan_date_time)
                }

            },
            {
                headerName: "Reason",
                field: "reason",
                width: 200,

            },
            {
                headerName: "State",
                field: "state_code",
                width: 120,

            },
            {
                headerName: "Amount (in INR)",
                field: "received_amount",
                width: 140,
                valueGetter: params =>{
                    if(params.data.challan_status=='Pending'){
                        return parseInt(params.data.fine_imposed)
                    }else{
                        return parseInt(params.data.received_amount)
                    }
                }
            },
            {
                headerName: "Payment Source",
                field: "payment_source",
                width: 120,
            },
            {
                headerName: "Status",
                field: "challan_status",
                width: 150,
            },
            {
                headerName: "Transaction Id",
                field: "transaction_id",
                width: 150,
            },
            {
                headerName: "Violator Name",
                field: "name_of_violator",
                width: 150,
            },
            {
                headerName: "DL or RC Number",
                field: "dl_no",
                width: 150,
            },
            {
                headerName: "Challan Print Link ",
                field: "challen_print_link",
                width: 100,
                // cellRenderer: (params)=>{
                //     var link = params.data.challen_print_link;
                //     return "<a href="+link+" target='_blank'><i class='fa fa-print'></i></a>"
                // }
            },
            {
                headerName: "Reciept Link",
                field: "reciept_link",
                width: 150,
                // cellRenderer: (params)=>{
                //     var link = params.data.reciept_link;
                //     return "<a href="+link+" target='_blank'><i class='fa fa-file'></i></a>"
                // }
            },
        ];

        const dStyles = {
            width: '90%',
            marginLeft: '0px',
            paddingLeft: '0px',
            height: '500px'
        }     

        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}

                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <span>
                            <button className={"float-left btn " + this.state.summaryview}
                                onClick={this.onClickBtnData.bind(this, 0)}>
                                Summary View
                            </button>
                            <button className={"float-left btn " + this.state.detailview}
                                onClick={this.onClickBtnData.bind(this, 1)}>
                                Detailed View
                            </button>
                        </span>
                    </div>
                    <div className="col-xl-12 col-lg-12">

                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>

                                </h5>
                            </div>
                            <div className="card-body row">

                                <div className="row col-xl-12 col-lg-12 col-sm-12">

                                    <div className={"dataLoadpage " + (this.state.loadshow)}>
                                    </div>
                                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                                    </div>
                                    {/* Detailed View code Starts */}
                                    <div className={"col-xl-12 col-lg-12 " + (this.state.detailview_sh)}>
                                        <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
                                            <div className="row">
                                                <div className="col-xl-2 col-lg-2">
                                                    <div className="form-group">
                                                        <label>From Date: </label>
                                                        {/* <Select
                                                            value={this.state.selectedMonthYear}
                                                            placeholder="All"
                                                            closeMenuOnSelect={true}
                                                            isMulti={true}
                                                            // onChange={this.getRailConsginments.bind(this)}
                                                            className={"border-radius-0"}
                                                            style={{borderRadius:"0px"}}
                                                            options={this.state.monthYearOptions}
                                                            onChange={(e)=>{this.setState({selectedMonthYear: e})}}
                                                        /> */}

                                                        <Datetime
                                                            inputProps={{ placeholder: 'yyyy-mm-dd', name: 'startDate', id: 'startDate', autoComplete: 'off', class: 'fc-input form-control' }}
                                                            dateFormat="YYYY-MM-DD"
                                                            timeFormat={false}
                                                            value={this.state.startDate}
                                                            onChange={this.handlerStartDateTime.bind(this)} />

                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2">
                                                    <div className="form-group">
                                                        <label>To Date: </label>

                                                        <Datetime
                                                            inputProps={{ placeholder: 'yyyy-mm-dd', name: 'endDate', id: 'endDate', autoComplete: 'off', class: 'fc-input form-control' }}
                                                            dateFormat="YYYY-MM-DD"
                                                            timeFormat={false}
                                                            value={this.state.endDate}
                                                            onChange={this.handlerEndDateTime.bind(this)} />

                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-3">
                                                    <div className="form-group">
                                                        <label>Truck No: </label>
                                                        <Select
                                                            //placeholder="All"
                                                            closeMenuOnSelect={true}
                                                            isMulti={true}
                                                            // onChange={this.getRailConsginments.bind(this)}
                                                            className={"border-radius-0"}
                                                            style={{ borderRadius: "0px" }}
                                                            options={this.state.vehicle_truck_no}
                                                            value={this.state.VehicleNumber}
                                                            onChange={(e) => { this.setState({ VehicleNumber: e }) }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-xl-3 col-lg-3">
                                                    <div className="form-group">
                                                        <label>Status: </label>
                                                        <Select
                                                            value={this.state.selectedStatus}
                                                            // placeholder="All"
                                                            closeMenuOnSelect={true}
                                                            isMulti={false}
                                                            // onChange={this.getRailConsginments.bind(this)}
                                                            className={"border-radius-0"}
                                                            style={{ borderRadius: "0px" }}
                                                            options={[{ label: "ALL", value: "ALL" }, { "label": "Pending", value: "Pending" },
                                                            { "label": "Paid", value: "PAID" }, { "label": "Disposed", value: "Disposed" }
                                                                , { "label": "UnPaid", value: "UNPAID" }]}
                                                            onChange={(e) => { this.setState({ selectedStatus: e }) }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-xl-2 col-lg-2">
                                                    <button type="submit" style={{ marginTop: "30px" }} className={"btn btn-success "}>Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                        <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                            {/* {console.log("here ", this.state.rowData)} */}
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                masterDetail={true}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                // enableRangeSelection={false}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                floatingFilter={false}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>

                                    <div className={"col-xl-12 col-lg-12 col-md-12 " + (this.state.summaryview_sh)}>
                                        <div className='row'>
                                            {/* <div className="col-xl-3 col-lg-3">
                                                    <div className="form-group">
                                                        <label>Year: </label>
                                                        <Select
                                                            value={this.state.selected_year}
                                                            //placeholder="All"
                                                            closeMenuOnSelect={true}
                                                            isMulti={true}
                                                            // onChange={this.getRailConsginments.bind(this)}
                                                            className={"border-radius-0"}
                                                            style={{borderRadius:"0px"}}
                                                            options={this.state.availableYears}
                                                            onChange={(e)=>{this.setState({selected_year: e});this.formatChallanData(this.state.vehchallan, e)}}
                                                        />
                                                    </div>
                                                </div>  */}
                                            <div className="col-xl-3 col-lg-3">
                                                <div className="form-group">
                                                    <label>From Date: </label>
                                                    {/* <Select
                                                            value={this.state.selectedMonthYear}
                                                            placeholder="All"
                                                            closeMenuOnSelect={true}
                                                            isMulti={true}
                                                            // onChange={this.getRailConsginments.bind(this)}
                                                            className={"border-radius-0"}
                                                            style={{borderRadius:"0px"}}
                                                            options={this.state.monthYearOptions}
                                                            onChange={(e)=>{this.setState({selectedMonthYear: e})}}
                                                        /> */}

                                                    <Datetime
                                                        inputProps={{ placeholder: 'yyyy-mm-dd', name: 'startDate1', id: 'startDate1', autoComplete: 'off', class: 'fc-input form-control' }}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        value={this.state.startDate1}
                                                        onChange={this.handlerStartDateTime1.bind(this)} />

                                                </div>
                                            </div>
                                            <div className="col-xl-3 col-lg-3">
                                                <div className="form-group">
                                                    <label>To Date: </label>

                                                    <Datetime
                                                        inputProps={{ placeholder: 'yyyy-mm-dd', name: 'endDate1', id: 'endDate1', autoComplete: 'off', class: 'fc-input form-control' }}
                                                        dateFormat="YYYY-MM-DD"
                                                        timeFormat={false}
                                                        value={this.state.endDate1}
                                                        onChange={this.handlerEndDateTime1.bind(this)} />

                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-2">
                                                <button className="btn btn-success mt-28p" onClick={this.onClickFilterData.bind(this)}>Get Data</button>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col col-xl-12 col-lg-12 col-md-12 cpointer p-5px">
                                                <div className="card n-p-0">
                                                    <div className="row card-body p-10px">

                                                        <div className="col col-xl-4 col-lg-4 cpointer" style={{ textAlign: "center" }}>
                                                            <span className="f12"><br /> Total Challans  </span>
                                                            <h4 className="txt-primary f40"><span className="counter">
                                                                <CountUp end={(this.state.totalTrucks) ? this.state.totalTrucks : 0} />
                                                            </span></h4>
                                                            <div className=" f15">
                                                                <div className="counter txt-success-medium">
                                                                    Total Amount (in INR) : &nbsp;
                                                                    <CountUp end={(this.state.totalTrucksAmount != '') ? this.state.totalTrucksAmount : 0} formattingFn={this.formatValue.bind(this)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col col-xl-4 col-lg-4 cpointer" style={{ textAlign: "center" }}>
                                                            <span className="f15"><br />  Pending Challans</span>
                                                            <h4 className="txt-secondary f40"><span className="counter">
                                                                <CountUp end={(this.state.pendingTrucks) ? this.state.pendingTrucks : 0} />
                                                            </span></h4>
                                                            <div className=" f15">
                                                                <div className="counter txt-success-medium">
                                                                    Total Pending Amount (in INR) : &nbsp;
                                                                    <CountUp end={(this.state.pendingTrucksAmount != '') ? this.state.pendingTrucksAmount : 0} formattingFn={this.formatValue.bind(this)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col col-xl-4 col-lg-4" style={{ textAlign: "center" }} >
                                                            <span className="f15"><br />  Paid Challans </span>
                                                            <h4 className="txt-pink-medium f40"><span className="counter"><CountUp end={(this.state.paidTrucks) ? this.state.paidTrucks : 0} /></span></h4>

                                                            <div className=" f15">
                                                                <div className="counter txt-success-medium">
                                                                    Total Paid Amount (in INR) : &nbsp;
                                                                    <CountUp end={(this.state.paidTrucksAmount != '') ? this.state.paidTrucksAmount : 0} formattingFn={this.formatValue.bind(this)} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-xl-12">
                                                <div className='col-xl-6 col-lg-6 col-md-6'>

                                                    <ChallansColumnChart
                                                        title={this.state.challansChartTitle}
                                                        subtitle={""}
                                                        context={this}
                                                        series={this.state.challanSeriesData}
                                                        categories={this.state.challanCategories}
                                                        yaxistitle={"Total Challans"}
                                                    />
                                                </div>
                                                <div className='col-xl-6 col-lg-6 col-md-6'>

                                                    <ChallansStateWiseChart
                                                        title={"State Wise challans"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={this.state.stateWiseSeriesData}
                                                        categories={this.state.stateWiseCategories}
                                                        yaxistitle={"Total Challans"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row col-xl-12 mt-40p">
                                                <div className='col-xl-6 col-lg-6 col-md-6'>

                                                    <ChallansStateWiseChart
                                                        title={"Top Challan Offenders Amount (INR)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={this.state.topchallanSeriesData}
                                                        categories={this.state.topchallanCategories}
                                                        yaxistitle={"Total Amount"}
                                                    />
                                                </div>

                                                <div className='col-xl-6 col-lg-6 col-md-6'>

                                                    <ChallansStateWiseChart
                                                        title={"Monthly Challan (INR)"}
                                                        subtitle={""}
                                                        context={this}
                                                        series={this.state.monthchallanSeriesData}
                                                        categories={this.state.monthchallanCategories}
                                                        yaxistitle={"Total Amount"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*  */}

                                    <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
                                        <div className="slide-r-title">
                                            <h4>
                                                {this.state.sliderTitle}
                                                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>
                                            </h4>
                                        </div>
                                        <div className="slide-r-body" style={{ position: "relative" }}>
                                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                                                {(this.state.sliderType == 1) ?
                                                    <div className="row col-sm-12">
                                                        {/* challan_image */}
                                                        <div className="col-sm-4" style={{ marginBottom: "2%" }}>        
                                                            <img alt="receipt" src={"data:application/octet-stream;base64," + this.state.challan_image.replace(/ /g, "+")} />
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {(this.state.sliderType == 2) ?
                                                    <div className="row col-sm-12">
                                                        {(this.state.gate_out_images_view.map(function (g, index) {
                                                            return <div className="col-sm-4" style={{ marginBottom: "2%" }}>
                                                                {(index == 0) ?
                                                                    <img alt="receipt" src={"data:application/octet-stream;base64," + g.replace(/ /g, "+")} />
                                                                    :
                                                                    <img alt="receipt" src={"data:application/octet-stream;base64," + g.replace(/ /g, "+").replace("+", "")} />
                                                                }
                                                            </div>
                                                        }))}
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>

                                    </div>

                                    {/*  */}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                {/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
            </div>
        );
    }
}


function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

    var len = geoFencingArea.getPath().getLength();
    console.log(len);
    var htmlStr = "";
    var coordinates = [];
    for (var i = 0; i < len; i++) {
        // var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
        // coordinates.push()
        var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
        var spliting = strlatlng.split(",");
        var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
        coordinates.push(latlngd);
        // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
        //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
        //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
    }
    // console.log("htmlStr", coordinates);

}
function initOMS() {
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
        //markersWontMove: true,
        //keepSpiderfied: true,
        //nearbyDistance: 10,
        //circleFootSeparation: 60,
        //legWeight: 1.5
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        basicFormatEvents: true
    });
}

function groupBy(list, keyGetter) {

    const map = new Map();

    list.forEach((item) => {

        const key = keyGetter(item);

        const collection = map.get(key);

        if (!collection) {

            map.set(key, [item]);

        } else {

            collection.push(item);

        }

    });

    return map;

}

function formatYearMonth(list) {
    var monthName = {
        1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun",
        7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec"
    }
    list = list.map((e) => {
        var year = e.split("-")[0];
        var month = e.split("-")[1];
        return monthName[parseInt(month)] + " " + year
    })
    return list
}