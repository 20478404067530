/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
// import UpdateButton from "../layouts/updatelmstates";
// import UpdateTransitButton from "../layouts/updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import ShowTPTGpsMap from "./showtptgpsmap";
// import DrawMap from '../common/drawmap';
// import ExportPDF from './exportpdf';
import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class TptDriverLogs extends Component {
    state = {
        pageTitle: "Transporter GPS Overspeed",
        eventGridAction: "gridAction",
        eventCounterAction: "counterAction",
        eventFormAction: "formAction",
        showform: 'show-m',
        loadshow: 'show-n',
        showrefresh: 'show-n',
        sliderTranslate: '',
        showdata: this.displayData,
        modules: AllModules,
        radius: '15',
        rlatitude: '',
        rlongitude: '',
        latitude: '',
        longitude: '',
        defaultradius: 0,
        coordinates: [],
        alert: null,
        show: false,
        basicTitle: '',
        basicType: "default",
        contentString: "testing",
        mwidth: "col-xl-12 col-lg-12",
        dept_code: '',
        defaultColDef: {
            sortable: true,
            filter: true,
            //editable: true,
            resizable: true
        },
        truckList: [],
        originalrowData: [],
        selectedTabLat: '',
        selectedTabLng: '',
        cluster_centroids: [],
        deftab: 'all',
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: false,
        rowSelection: "single",
        context: { componentParent: this },
        frameworkComponents: {
            // showtptgpsmap:ShowTPTGpsMap,
            // UpdateButton:UpdateButton,
            // UpdateTransitButton:UpdateTransitButton,
        },
        loadingCellRenderer: "customLoadingCellRenderer",
        loadingCellRendererParams: { loadingMessage: "One moment please..." },
        rowModelType: "serverSide",
        paginationPageSize: 50,
        cacheBlockSize: 50,
        maxBlocksInCache: 2,

        showPlant: 'show-n',
        showAddr: 'show-n',
        request_type: '',
        view_type: '',
        tabplants: [],
        clickabletab: '',
        plantlist: [],
        stateFormData: "",
        sliderStateEmailsTranslate: "",
        overly: "show-n",
        rakeslist: [],
        loadingpoints: [],
        destinationpoints: [],
        userType: "",
        pinnedRegionBottomRowData: [],
        pinnedTransitBottomRowData: [],
        stockData: [],
        totalStock: 0,
        totalSales: 0,
        totalDispatches: 0,
        railShare: 0,
        totalFySales: 0,
        totalFyDispatches: 0,
        railFyShare: 0,
        driverName: '',
        transitStock: 0,
        unloadingUpdatedDate: "",
        intransitUpdatedDate: "",
        screenView: "",
        rowData: [],
        driverNamesList: [],
        totalInstalled: [],
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        filteredData: [],
        truckNoList: [],
        showTrucksList: "show-n",
        startDate: '',
        endDate: '',
        truckNos: [],
        mapinfo: [],
        sliderRouteTranslate: '',
        tpttrucks: [],
        maptruck_no: "",
        routedetails: '',
        adaniCheck: 0,
        pdfRowData: [],
        pdfColumns: [],
        addressColumn: 300,
    };

    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });


    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount = async () => {
        var tptCode = localStorage.getItem('transportercode');
        if (tptCode.includes("T293") == true) {
            var adaniCheck = 1;
        }
        else {
            adaniCheck = 0;
        }
        this.setState({
            adaniCheck: adaniCheck
        });
        var vehicle_group = localStorage.getItem("vehicle_group");

        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if (is_enmovil_transporter == 1) {
            loadDateTimeScript();
            var edate = moment.parseZone().format('DD-MM-YYYY')+" 23:59";
            var sdate = moment.parseZone().subtract(15, 'days').format('DD-MM-YYYY')+" 00:00";
            
            $("#fromDate").val(sdate);
            $("#toDate").val(edate);
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m'
            });
            console.log(edate,"edate")
            console.log(sdate,"sdate")
            this.logPageView(); // Log page view to GA
            var screenView = "";

            let params = {                
                fromDate: sdate,
                toDate:edate,
                driverName : null
            }
            this.getDriverLogs(params)

        }

        // this.getUserGridState()
    }

    getDriverLogs = async (query) => {
        console.log(query, 'driverName')
        let d_name = []
        if (query) {
            if (query.driverName !== null) {
                if (query.driverName.length !== 0) {
                    query.driverName.forEach(e => {
                        d_name.push(e.value)
                    })
                }
            }
        }
        var transporter_code = [];
        if (localStorage.getItem('transportercode') != undefined) {
            if (localStorage.getItem("transportercode") != "all") {
                transporter_code = JSON.parse(localStorage.getItem("transportercode"));
            }
            else {
                transporter_code = localStorage.getItem("transportercode");
            }
            //var transporter_code = tptCode[0];
        }
        let qry = {
            transporter_code: transporter_code,
            // fromDate:sdate,
            // toDate:edate
    
        }
        if (query) {
            if (query.fromDate !== '' && query.fromDate !== undefined && query.toDate !== '' && query.toDate !== undefined) {
                qry.fromDate = query.fromDate
                qry.toDate = query.toDate
            }
        }
        if (d_name.length !== 0) {
            qry.driver_name = JSON.stringify(d_name)
        }
        console.log("qry", qry)
        await redirectURL.post("/dashboard/getDriversLogs", qry).then(async (response) => {
            let data = response.data
            console.log("response", data)
            if (data.message == "success") {
                this.setState({
                    'driverNamesList': data.driverNamesList,
                    'rowData': data.driverLogs,
                    loadshow: 'show-n',
                    overly: 'show-n'
                })
            }

        })
    }
    // getUserGridState(){
    // 	redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
    // 	.then( async (resp) => {
    // 		console.log("resp ", resp)
    // 		this.setState({
    // 			usergridstate:resp.data
    // 		});
    // 		this.restoreGridStates();
    // 	})
    // }
    onClickHideAll() {
        this.setState({
            overly: "show-n",
            showTrucksList: "show-n",
            sliderRouteTranslate: '',
        })
    }
    hideList() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            showTrucksList: "show-n",
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    // onShowTPTGPSMapRoute(rownode){
    //     var node = rownode.data;
    //     console.log("Node ", node)
    //     this.setState({
    //         loadshow:"show-m",
    //         overly:"show-m"
    //     })
    //     if (googleAnalytics.page.enableGA) {
    //         let eventOptions = {
    // 			"category": this.state.pagetitle,
    // 			"action": this.state.eventGridAction,
    // 			"label": googleAnalytics.page.action.viewRoute,
    // 		}
    // 		googleAnalytics.logEvent(eventOptions);
    //     }
    //     if(node.recent_instance != undefined && node.recent_instance != "")
    //     {
    //         var toDate = moment.parseZone(node.recent_instance).format('YYYY-MM-DD HH:mm:ss');
    //     }
    //     else{
    //         var toDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
    //     }
    // 	var parameters = {
    // 		truck_no:node.truck_no,
    // 		from_date:moment.parseZone(node.first_instance).format('YYYY-MM-DD HH:mm:ss'),
    // 		to_date:toDate,
    //     }
    //     redirectURL.post("/consignments/showgpseodmap", parameters)
    //     .then((response) => {
    //         console.log("response ", response.data)
    //         this.setState({
    //             mapinfo:response.data,
    //             sliderRouteTranslate:"slider-translate-60p",
    //             loadshow:'show-n',
    //             overly:'show-m',
    //             maptruck_no:node.truck_no,
    //             routedetails:response.data

    //     });
    //     })

    // }

    onClickFilterDriverlogReport = () => {
        const { driverName } = this.state
        const fromDate = $("#fromDate").val();
        const toDate = $("#toDate").val();
        let params = {
            driverName,
            fromDate,
            toDate
        }
        this.getDriverLogs(params)
        // console.log('')

    }

    onChangeDepartment(driverName) {
        this.setState(
            { driverName },
            () => console.log(`truckNos Option selected:`, this.state.truckNos)
        );
    }

    // onClickSaveGridState(){
    // 	if (googleAnalytics.page.enableGA) {
    // 		let pageTitle = this.state.pagetitle;
    // 		let eventOptions = {
    // 			"category": pageTitle,
    // 			"action": this.state.eventGridAction,
    // 			"label": "Save Grid Layout",
    // 		}
    // 		googleAnalytics.logEvent(eventOptions);
    // 	}
    // 	window.colState = this.gridColumnApi.getColumnState();
    // 	window.groupState = this.gridColumnApi.getColumnGroupState();
    // 	window.sortState = this.gridApi.getSortModel();
    // 	window.filterState = this.gridApi.getFilterModel();

    // 	let screenpage = 'Night Driving';



    // 	let reqparams = {
    // 		gridcolumns:window.colState,
    // 		gridgroup:window.groupState,
    // 		gridcolsort:window.sortState,
    // 		gridcolfilter:window.filterState,
    // 		userId:localStorage.getItem("userid"),
    // 		screenurl:window.location.pathname,
    // 		screentitle:screenpage
    // 	}
    // 	//console.log("reqparams ", reqparams)
    // 	redirectURL.post("/consignments/saveGridStates", reqparams)
    // 	.then((response) => {
    // 		//console.log("State response ",response.data)
    // 		this.setState({
    // 			show:true,
    // 			basicTitle:"Successfully saved grid layout",
    // 			basicType:"success",
    // 			screenurl:window.location.pathname,
    // 			screentitle:screenpage
    // 		})
    // 	})
    // 	.catch(function(e){
    // 		console.log("Error ", e)
    // 	})
    // }

    // restoreGridStates()
    // {
    // 	///console.log("this.state.usergridstate ", this.state.usergridstate)
    // 	try{
    // 		if(this.state.usergridstate.length > 0)
    // 		{
    // 			var windowstates = this.state.usergridstate;
    // 			console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
    // 			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
    // 			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
    // 			this.gridApi.setSortModel(windowstates[0].gridcolsort);
    // 			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

    // 		}
    // 	}
    // 	catch(e){}
    // }


    // resetState = () => {
    // 	if (googleAnalytics.page.enableGA) {
    // 		let pageTitle = this.state.pagetitle;
    // 		let eventOptions = {
    // 			"category": pageTitle,
    // 			"action": this.state.eventGridAction,
    // 			"label": "Reset Grid Layout",
    // 		}
    // 		googleAnalytics.logEvent(eventOptions);
    // 	}
    // 	this.gridColumnApi.resetColumnState();
    // 	this.gridColumnApi.resetColumnGroupState();
    // 	this.gridApi.setSortModel(null);
    // 	this.gridApi.setFilterModel(null);
    // 	//console.log('column state reset');
    // 	var reqparams = {
    // 		userId:localStorage.getItem("userid"),
    // 		screenurl:window.location.pathname,
    // 		screentitle:this.state.screenpage
    // 	}

    // 	redirectURL.post("/consignments/removeUserGridState", reqparams)
    // 	.then((response) => {
    // 		this.setState({
    // 			show:true,
    // 			basicTitle:"Successfully reset default grid layout",
    // 			basicType:"success"
    // 		})
    // 	})

    // };


    render() {
        var adaniCheck = this.state.adaniCheck;
        var tkName = "Truck No";
        if (adaniCheck == 1) {
            tkName = "Shuttle No";
        }
        var columnWithDefs = [
            // {
            //     headerName:"",
            //     field:"",
            //     width:50,
            //     cellRendererSelector:function(params){
            //         return {
            //             component:"showtptgpsmap"
            //         }
            //     }
            // },
            {
                headerName: "Driver Name",
                field: "driver_name",
                width: 150,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Vehicle Number',
                field: "vehicle_no",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            // {
            //     headerName: 'Driver Number',
            //     field: "driver_mobile_no",
            //     width: 120,
            //     filter: true,
            //     resizable: true,
            //     sortable: true,
            // },
            {
                headerName: 'Message',
                field: "message",
                width: 120,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Modified On',
                field: "created_on",
                width: 180,
                filter: true,
                resizable: true,
                sortable: true,
                valueGetter: function (params) {
                    if (params.data.created_on != undefined && params.data.created_on != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.created_on)
                    }
                    else {
                        return "N/A";
                    }
                }
            },


        ];
        // var listData = [] 
        // this.state.filteredData.map(function(e){
        // listData.push(<li className="dropdown-truck-no">{e}</li>)
        // })
        return (

            <div className={(this.state.screenView == "Mobile") ? "" : "container-fluid"} style={(this.state.screenView == "Mobile") ? { marginTop: "2em" } : {}}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="card">
                    <div className="card-header">
                        <h5>
                            <i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                            Driver Logs
                            {/* <div className="col-xl-12 col-lg-12">
                                    
                                    <span className="layoutbtns pull-right">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                </div> */}
                            <span className="layoutbtns pull-right">
                                <a className="btn btn-info mr-2" href="/tptlogin/managedrivers">
                                    Manage Drivers
                                </a>
                            </span>
                        </h5>
                    </div>

                    <div className={(this.state.screenView == "Mobile") ? "card-body" : "card-body col-xl-12 col-lg-12"}>
                        {/* <div className={"trucks-dropdown "+this.state.showTrucksList} style={{top:"94px"}}>
							<ul>
								{listData}
							</ul>
						</div> */}
                        <div class="row">
                            <div class={" col-xl-2 col-lg-2 form-group"}>
                                <label>Driver Names</label>
                                <Select
                                    placeholder={'Driver Name'}
                                    closeMenuOnSelect={false}
                                    isMulti={true}
                                    onChange={this.onChangeDepartment.bind(this)}
                                    className={"border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.driverNamesList} />
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <label>From Date</label>
                                <input type="text" name="date" id="fromDate" className="datetimepicker_date form-control" />
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <label>To Date</label>
                                <input type="text" name="date" id="toDate" className="datetimepicker_date form-control" />
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <button type="submit" onClick={this.onClickFilterDriverlogReport.bind(this)} style={{ marginTop: "29px" }} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>

                        </div>
                        <div id="myGrid" style={{ width: "100%", height: "350px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnWithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={50}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this },
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>

        );
    }
}


function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y H:i',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

$(document).on("change", "#toDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#fromDate").val();
    var cargoDate = $("#toDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if (startTime > endTime) {
        $("#toDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);

    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;

    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh * 3600));
    var mm = Math.floor(hrr / 60)
    if (mm < 10) {
        var mins = "0" + mm;
    }
    else {
        var mins = mm;
    }

    if (hh < 10) {
        var hrs = "0" + hh;
    }
    else {
        var hrs = hh;
    }
    return hrs + ":" + mins;
}

$(document).on("change", "#fromDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#fromDate").val();
    var cargoDate = $("#toDate").val();
    var bookingDate = bookingDate.split("-")[2] + "-" + bookingDate.split("-")[1] + "-" + bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2] + "-" + cargoDate.split("-")[1] + "-" + cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if (startTime > endTime) {
        $("#fromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
