import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import CountUp from 'react-countup';
import ChallansColumnChart from './challansColumnChart'

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
//import { getConsignmentsNoGpsLifeTimeCounter } from '../../../../tpt_node/models/consignmentModel';

var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment')
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
var monthName = {
    1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun",
    7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec"
}

var monthNumbers = {
    "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04", "May": "05", "Jun": "06",
    "Jul": "07", "Aug": "08", "Sep": "09", "Oct": "10", "Nov": "11", "Dec": "12"
}
export default class TptVicinityScreen extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Fastag Transactions",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-m',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },

			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,

			plant_code:'',
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-m",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
            fuel_station_options:[],
            fuel_station_data:[],
            fuelstagingtrucks:[],
            markersList:[],
            fasttagtrucks:[],
            fasttagtnx:[],
            VehicleNumber: [{"label": "All", value: "All"}],
            btnClicked:[],
            CellRendererFunc:[],
            detailCellRendererParams:{},
            fasttagtruckstnx:[],
            fast_tag_transc:[],
            TransactionStatus:"All",
            fast_tag_screen:[],
            fasttagtruckstnxcopy:[],
            totalTransactions:0,
            transactionAmount:0,
            monthYearOptions: [],
            selectedMonthYear: [{"label": "All", value: "All"}],
            seriesData: [],
            categories: [],
            yearOptions: [],
            selectedYear: {"label": "All", value: "All"},
            uniquedatetime:[],
            plazaSeriesData : [],
            plazaCategories : [],
            startDate:"",
            endDate:"",
            summary:"show-m",
            detailed:"show-n",
            summarycl:"btn-danger",
            detailedcl:"",
			totalwallet:0,
		};
		//this.popmarker=this.popmarker.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        var sdate = moment.parseZone().subtract(5,"days").format("YYYY-MM-DD")
        var edate = moment.parseZone().format("YYYY-MM-DD");

        this.setState({
            startDate:sdate,
            endDate:edate
        })
        var currentTime = new Date();
        var year = currentTime.getFullYear();
        var month = currentTime.getMonth() + 1
        month = (month < 10)?"0"+month:month
        var selectedMonthYear = {"label": monthName[parseInt(month)]+"-"+year, "value": monthName[parseInt(month)]+"-"+year}
        // var transportercode = JSON.parse(localStorage.getItem("transportercode"));
        // var transporter_code=transportercode;
        if(localStorage.getItem("transportercode") != undefined)
        {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else{
            var transporter = "";
        }
        if(Array.isArray(transporter))
        {
            var transporter_code = transporter[0]
        }
        else{
            var transporter_code = transporter
        }


		var tptCode = localStorage.getItem('transportercode');
        var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			   var records = response.data.records;
				// console.log("records ", records)
                tptrucks = records;
				this.setState({
					tpttrucks:records
				})
			})
		}


        var params = {
            "truck_no": ["All"],
            // "selected_months": [month+"-"+year],
            "startdate":sdate,
            "enddate":edate,
            // "status": "All",
            "transporter_code":transporter_code
        }
        var yearOptions = [];
        redirectURL.post("/consignments/fasttagTrucksTranscScreen", params).then((response)=>{
            // console.log(response.data,"fasttag data")
            var fast_tag_transc = [{label:"All", value: "All"}];
            var fast_tag_screen = [{label:"All", value: "All"}];
            var conters = response.data.fastagbalance;
            // console.log(conters,"conters")
            var fasttagtruckstnx = response.data.fasttagtruckstnx;
            console.log("fasttagtruckstnx",fasttagtruckstnx)
            var tpttrucks = tptrucks;
            console.log("tpttrucks ",tpttrucks)
			var trucksarr = []
			if(tpttrucks.length > 0)
			{
				fasttagtruckstnx.filter(f =>
					tpttrucks.filter((d )=> {
						if(d.truck_no == f.truck_no){									
							trucksarr.push(f)
						}
					})
				);
			}
			else
			{
				trucksarr = fasttagtruckstnx
			}
            console.log("trucksarr ", trucksarr)
            var totalwallet=0;
            if(conters.length > 0)
            {
                totalwallet=conters[0].CUGWalletBalance;
            }
            var totalTransactions = 0;
            var transactionAmount = 0;
            var monthYearOptions = []
            // response.data.uniquedatetime.map((e)=>{
            //     // console.log(e,"data")
            //     var transactionDateTime = moment.parseZone(e).format('YYYY-MM')
            //     transactionDateTime = monthName[parseInt(transactionDateTime.split("-")[1])]+"-"+transactionDateTime.split("-")[0]
            //     monthYearOptions.push(transactionDateTime)
            // })
            trucksarr.map((e)=>{
                // yearOptions.push(moment.parseZone(e.TransactionDateTime).format('YYYY'))
                transactionAmount+= parseFloat(e.TransactionAmount)
                // console.log(e, "fasttagtruckstnx")
                var temp1 = fast_tag_transc.filter((t)=>{
                    return t.value == e.VehicleNumber
                })
                if(temp1.length == 0){
                    fast_tag_transc.push({label:e.VehicleNumber, value: e.VehicleNumber}) 
                }   
                
                var temp = fast_tag_screen.filter((t)=>{
                   return t.value == e.TransactionStatus
                })
                if(temp.length == 0){
                    fast_tag_screen.push({label:e.TransactionStatus, value: e.TransactionStatus})  
                }              
            })
            // console.log(monthYearOptions,"monthYearOptionsCopy")
            var monthYearOptionsCopy = [{"label": "All", "value": "All"}];
            [...new Set(monthYearOptions)].map((e)=>{
                monthYearOptionsCopy.push({"label": e, value: e})
            })
            // var yearOptionsCopy = [{"label": "All", "value": "All"}];
            // [...new Set(yearOptions)].map((e)=>{
            //     yearOptionsCopy.push({"label": e, "value": e})
            // })
            // console.log(transactionAmount,"transactionAmount")
            this.setState({
                fast_tag_transc : fast_tag_transc,
                fast_tag_screen : fast_tag_screen,
                rowData : trucksarr,
                fasttagtruckstnxcopy :trucksarr,
                totalTransactions : trucksarr.length,
                transactionAmount: transactionAmount,
                monthYearOptions: monthYearOptionsCopy,
                loadshow: "show-n",
                overly: "show-n",
                selectedMonthYear: [selectedMonthYear],
                totalwallet:totalwallet,
                yearOptions: [{"label": "All", "value": "All"},
                                {"label": "2021", "value": "2021"},
                                {"label": "2022", "value": "2022"}]
            })
            this.formatChartData("All")
            this.formatPlazaData()
        })
        

    }
    formatValue(value){
        return value.toLocaleString('hi-IN');
    }

    formatPlazaData()
    {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        
            // var rowdata = this.state.fasttagtruckstnx;
            // var categories = [];
            // var seriesList = [];
            
            // var truckChallans = groupBy(rowdata, rdata => rdata.PlazaName);
            // var pendingList = [];
            // var paidList = [];
            // truckChallans.forEach((monthData, monthKey) =>{
               
            //         var amount = 0;
            //         monthData.map((e)=>{amount += parseFloat(e.TransactionAmount)})
            //         seriesList.push({name: monthKey,y: amount,"title":formatNumber(amount)})
            //         categories.push(monthKey)
                
            // })
            // seriesList.sort(function(a,b){
            //     // Turn your strings into dates, and then subtract them
            //     // to get a value that is either negative, positive, or zero.
            //     return b.y - a.y;
            // });
            // // console.log({"name": "Month & Year", data: seriesList}, "check")
            // this.setState({
            //     plazaSeriesData: {"name": "Plaza", data: seriesList},
            //     plazaCategories: categories,
            //     loadshow: "show-n",
            //     overly: "show-n"
            // })
        
            
        if(localStorage.getItem("transportercode") != undefined)
        {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else{
            var transporter = "";
        }
        if(Array.isArray(transporter))
        {
            var parameters = {
                transporter_code : transporter[0]
            }
        }
        else{
            var parameters = {
                transporter_code : transporter
            }
        }
        redirectURL.post("/consignments/plazafastag", parameters)
        .then((response)=>{
            // console.log("response ", response.data)
            var records = response.data;
            var seriesList = []
            var categories=[]
            if(records.length > 0)
            {
                records.map((item) => {
                    categories.push(item.PlazaName);
                    seriesList.push({"name":item.PlazaName,y: item.TransactionAmount,"title":formatNumber(item.TransactionAmount)})
                })
            }
            this.setState({
                plazaSeriesData: {"name": "Plaza", data: seriesList},
                plazaCategories: categories,
                loadshow: "show-n",
                overly: "show-n"
            })
            
        })
        
    }

    formatChartData(year)
    {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var params = {
            "truck_no": ["All"],
            "selected_months": ["All"],
            "status": "All"
        }
        var yearOptions = [];
        if(localStorage.getItem("transportercode") != undefined)
		{
			var transporter = JSON.parse(localStorage.getItem("transportercode"))
		}
		else{
			var transporter = "";
		}
		if(Array.isArray(transporter))
		{
			var parameters = {
				transporter_code : transporter[0]
			}
		}
		else{
			var parameters = {
				transporter_code : transporter
			}
        }
        redirectURL.post("/consignments/monthlyfastag", parameters)
        .then((response)=>{
            // console.log("response ", response.data)
            var records = response.data;
            var seriesList = []
            var categories=[]
            if(records.length > 0)
            {
                records.map((item) => {
                    categories.push(item.month_year);
                    seriesList.push({"name":item.month_year,y: item.TransactionAmount,"title":formatNumber(item.TransactionAmount)})
                })
            }
            this.setState({
                seriesData: {"name": "Month & Year", data: seriesList},
                categories: categories,
                loadshow: "show-n",
                overly: "show-n"
            })
        })
        
        // redirectURL.post("/consignments/fasttagTrucksTranscScreen", params).then((response)=>{
        //     var rowdata = response.data.fasttagtruckstnx;
        //     var categories = [];
        //     var seriesList = [];
        //     rowdata = rowdata.map((e)=>{
        //         var challan_month_year = moment.parseZone(e.TransactionDateTime).format('YYYY-MM')
        //         e.challan_month_year = challan_month_year
        //         return e;
        //     })
        //     // console.log(rowdata, "rowdata", year)
        //     rowdata.sort(function(a,b){
        //         // Turn your strings into dates, and then subtract them
        //         // to get a value that is either negative, positive, or zero.
        //         return new Date(a.TransactionDateTime) - new Date(b.TransactionDateTime);
        //     });
        //     var truckChallans = groupBy(rowdata, rdata => rdata.challan_month_year);
        //     var pendingList = [];
        //     var paidList = [];
        //     truckChallans.forEach((monthData, monthKey) =>{
        //         var monthYear = monthKey.split("-")[0];
        //         if(monthYear == year || year == "All"){
        //             var amount = 0;
        //             monthData.map((e)=>{amount += parseFloat(e.TransactionAmount)})
        //             seriesList.push({"name":monthKey,y: amount,"title":formatNumber(amount)})
        //             categories.push(monthKey)
        //         }
        //     })
        //     // console.log({"name": "Month & Year", data: seriesList}, "check")
            // this.setState({
            //     seriesData: {"name": "Month & Year", data: seriesList},
            //     categories: formatYearMonth(categories),
            //     loadshow: "show-n",
            //     overly: "show-n"
            // })
        // })
        
    }

formHandler = async (event) =>{
		event.preventDefault();

        var vehicleNumber = [];
        if(this.state.VehicleNumber != null && this.state.VehicleNumber != "")
        {
            this.state.VehicleNumber.map((e)=>{
                vehicleNumber.push(e.value)
            })
        }
        else{
            // this.state.VehicleNumber.map((e)=>{
                vehicleNumber.push("All")
            // })
        }

        var selectedMonthYear = [];
        if(this.state.selectedMonthYear != null && this.state.selectedMonthYear != "")
        {
            // console.log(this.state.selectedMonthYear,"selectedMonthYear")
            this.state.selectedMonthYear.map((e)=>{
                if(e.value == "All")
                {
                    selectedMonthYear.push(e.value)
                }
                else
                {
                    var monthNo = monthNumbers[e.value.split("-")[0]]
                    selectedMonthYear.push(monthNo+"-"+e.value.split("-")[1])
                }
            })
        }
        else{
            selectedMonthYear.push("All")
        }
        if(localStorage.getItem("transportercode") != undefined)
        {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else{
            var transporter = "";
        }
        if(Array.isArray(transporter))
        {
           var transporter_code = transporter[0]
        }
        else{
            var transporter_code = transporter[0]
        }
        if(this.state.startDate != "" && this.state.startDate !="NaN-NaN-NaN" && this.state.endDate != "" && this.state.endDate !="NaN-NaN-NaN")
        {
            var sdate = this.state.startDate;
            var edate = this.state.endDate;
        }
        else
        {
            var sdate = "";
            var edate = "";
        }
        var params = {
            truck_no: vehicleNumber,
            // selected_months: selectedMonthYear,
            startdate:sdate,
            enddate:edate,
            status: this.state.TransactionStatus,
            transporter_code:transporter_code
        }
        // console.log(params,"params")
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        redirectURL.post("/consignments/fasttagTrucksTranscScreen", params).then((response)=>{
            var fasttagdata = response.data.fasttagtruckstnx
            // console.log(fasttagdata,"fasttagdata")
            var transactionAmount = 0;
            response.data.fasttagtruckstnx.map((e)=>{
                transactionAmount+= parseFloat(e.TransactionAmount)
            })
            var conters = response.data.fastagbalance;
            var totalwallet=0;
            if(conters.length > 0)
            {
                totalwallet=conters[0].CUGWalletBalance;
            }
            this.setState({
                fasttagtruckstnx : response.data.fasttagtruckstnx,
                "transactionAmount": transactionAmount,
                totalTransactions: response.data.fasttagtruckstnx.length,
                loadshow: "show-n",
                overly: "show-n",
                totalwallet:totalwallet,
                rowData:fasttagdata
            })
        })
		
    }
	onClickHideAll(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",

		})
	}
    
   onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};


	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
    };
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1<10?"0"+(d.getMonth() +1):(d.getMonth() +1))+"-"+d.getDate();
    	this.setState({
            startDate:startdate
        })
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1<10?"0"+(d.getMonth() +1):(d.getMonth() +1))+"-"+d.getDate();
    	this.setState({
            endDate:startdate
        })
    }
    onClickTab(params)
    {
        if(params == "1"){
            this.setState({
                summary:"show-m",
                detailed:"show-n",
                summarycl:"btn-danger",
                detailedcl:""
            })
        }
        if(params == "2"){
            this.setState({
                summary:"show-n",
                detailed:"show-m",
                summarycl:"",
                detailedcl:"btn-danger"
            })
        }
    }
    render(){
		
		var columnDefs= [
			{
                headerName: "Vehicle No",
                field: "VehicleNumber",
                width: 140,
  
  
              },
            //   {
            //       headerName: "StartTransactionDate",
            //       field: "StartTransactionDate",
            //       width: 200,
            //       valueGetter:function(params){
            //         return getHyphenDDMMMYYYYHHMM(params.data.StartTransactionDate)}
    
            //   },
            //    {
            //     headerName: "EndTransactionDate",
            //     field: "EndTransactionDate",
            //     width: 150,
            //     valueGetter:function(params){
            //         return getHyphenDDMMMYYYYHHMM(params.data.EndTransactionDate)}
  
            //   },
              
            {
               headerName: "Lane Code",
               field: "LaneCode", 
               width: 150,
            
              },
              {
                  headerName: "Plaza Code",
                  field: "PlazaCode",
                  width: 120,
  
              },
              {
                  headerName: "Plaza Name",
                  field: "PlazaName",
                  width: 120,
              },
              {
                headerName: "Processing Date Time",
                field: "ProcessingDateTime",
                width: 120,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.ProcessingDateTime)}
            },
              {
                  headerName: "Transaction Amount (in INR)",
                  field: "TransactionAmount",
                  width: 150,
              },
              {
                  headerName: "Transaction Date Time",
                  field: "TransactionDateTime",
                  width: 150,
                  valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.TransactionDateTime)}
              },
              {
                headerName: "Transaction Id",
                field: "TransactionId",
                width: 150,
            },
            {
                headerName: "Transaction Reference No",
                field: "TransactionReferenceNumber",
                width: 150,
            },
            {
                headerName: "Transaction Status",
                field: "TransactionStatus",
                width: 150,
            },
			
		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}

        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
                    <div className={"col-xl-12 col-lg-12 "+this.state.showStateTrucksMsg}>
                    {/* <p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p> */}
                    </div>

                    <div className="row">
                        <div className="col-xl-2 col-lg-2">
                            <div className="form-group">
                                <label>From Date: </label>
                                {/* <Select
                                    value={this.state.selectedMonthYear}
                                    //placeholder="All"
                                    closeMenuOnSelect={true}
                                    isMulti={true}
                                    // onChange={this.getRailConsginments.bind(this)}
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    options={this.state.monthYearOptions}
                                    onChange={(e)=>{this.setState({selectedMonthYear: e})}}
                                /> */}
                            
                                <Datetime 
                                inputProps={{ placeholder: 'yyyy-mm-dd',name:'startDate', id:'startDate',  autoComplete:'off', class:'fc-input form-control' }} 
                                dateFormat="YYYY-MM-DD" 
                                timeFormat={false} 
                                value={this.state.startDate}
                                onChange={this.handlerStartDateTime.bind(this)} />
							
                            </div>
                        </div> 
                        <div className="col-xl-2 col-lg-2">
                            <div className="form-group">
                                <label>To Date: </label>
                                {/* <Select
                                    value={this.state.selectedMonthYear}
                                    //placeholder="All"
                                    closeMenuOnSelect={true}
                                    isMulti={true}
                                    // onChange={this.getRailConsginments.bind(this)}
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    options={this.state.monthYearOptions}
                                    onChange={(e)=>{this.setState({selectedMonthYear: e})}}
                                /> */}
                            
                                <Datetime 
                                inputProps={{ placeholder: 'yyyy-mm-dd',name:'endDate', id:'endDate',  autoComplete:'off', class:'fc-input form-control' }} 
                                dateFormat="YYYY-MM-DD" 
                                timeFormat={false} 
                                value={this.state.endDate}
                                onChange={this.handlerEndDateTime.bind(this)} />
							
                            </div>
                        </div> 
                        <div className="col-xl-3 col-lg-3">
                            <div className="form-group">
                                <label>Truck No: </label>
                                <Select
                                    //placeholder="All"
                                    value={this.state.VehicleNumber}
                                    closeMenuOnSelect={true}
                                    isMulti={true}
                                    // onChange={this.getRailConsginments.bind(this)}
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    options={this.state.fast_tag_transc}
                                    onChange={(e)=>{this.setState({VehicleNumber:e})}}
                                />
                            </div>
                        </div> 

                        {/* <div className="col-xl-3 col-lg-3">
                            <div className="form-group">
                                <label>Status </label>
                                <Select
                                    placeholder="All"
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    // onChange={this.getRailConsginments.bind(this)}
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    options={this.state.fast_tag_screen}
                                    onChange={(e)=>{this.setState({TransactionStatus:e.value})}}
                                />
                            </div>
                        </div>  */}

                        <div className="form-group col-xl-2 col-lg-2">
                            <button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
                        </div>
                    </div> 
                    </form>
            		<div className="col-xl-12 col-lg-12 n-p-0">
                        
                        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                            <div className="card">
                                <div className="card-body" id="dashboardCounterCardBody">
                                    <div className="crm-numbers pb-0">
                                        <div className="row">

                                            <div className="col cpointer" style = {{textAlign:"center"}}>
                                                <span className="f15 txt-primary"><br /> Total Transactions  </span>
                                                <h4 className="txt-primary f40"><span className="counter">
                                                    <CountUp end= {(this.state.totalTransactions)?this.state.totalTransactions:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col cpointer" style = {{textAlign:"center"}}>
                                                <span className="f15 txt-danger"><br />  Transactions Amount Balance (in INR)</span>
                                                <h4 className="txt-danger f40"><span className="counter">
                                                    <CountUp end= {(this.state.transactionAmount)?this.state.transactionAmount:0}
                                                     formattingFn={this.formatValue.bind(this)}/>
                                                    </span></h4>
                                            </div> 
                                            
                                            <div className="col cpointer" style = {{textAlign:"center"}}>
                                                <span className="f15 txt-warning"><br />  Total Wallet Balance (in INR)</span>
                                                <h4 className="txt-warning f40"><span className="counter">
                                                    <CountUp end= {(this.state.totalwallet)?this.state.totalwallet:0}
                                                     formattingFn={this.formatValue.bind(this)}/>
                                                    </span></h4>
                                            </div> 
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

		            	<div className="card">
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>

								</h5>
				   			</div>
                            <div className='col-xl-12 col-lg-12 col-md-12 mb-40p'>
                                <button className = {"float-left btn " + this.state.summarycl} 
                                onClick={this.onClickTab.bind(this,1)}> 
                                    Summary View
                                </button>
                                <button className = {"float-left btn " + this.state.detailedcl} 
                                onClick={this.onClickTab.bind(this,2)}>
                                    Detailed View
                                </button>
                            </div>
                               <div className={'col-xl-12 col-lg-12 col-md-12 '+(this.state.summary)}>
                                {/* <div className="form-group col-xl-3 col-lg-3 col-md-3">
                                    <label>Year: </label>
                                    <Select
                                        value={this.state.selectedYear}
                                        //placeholder="All"
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                        // onChange={this.getRailConsginments.bind(this)}
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        options={this.state.yearOptions}
                                        onChange={(e)=>{this.setState({selectedYear: e}); 
                                                        this.formatChartData(e.value)}}
                                    />
                                </div> */}
                                <div className="row">
                                <div className="row col-xl-6 col-lg-6 col-sm-6">
                                    <ChallansColumnChart 
                                    title={"Monthly Transactions (in INR)"}
                                    subtitle={""}
                                    context={this}
                                    series={this.state.seriesData}
                                    categories={this.state.categories}
                                    yaxistitle={"Total Transactions Amount (in INR)"}
                                    />
                                </div>
                                <div className="row col-xl-6 col-lg-6 col-sm-6">
                                    <ChallansColumnChart 
                                    title={"Top Spends - Plaza wise (INR)"}
                                    subtitle={""}
                                    context={this}
                                    series={this.state.plazaSeriesData}
                                    categories={this.state.plazaCategories}
                                    yaxistitle={"Total Amount (in INR)"}
                                    />
                                </div>
                                
                                </div>
                            </div>
				   			<div className={"card-body row "+(this.state.detailed)}>
							
								<div className="row col-xl-12 col-lg-12 col-sm-12">

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

                                    <div className="col-xl-12 col-lg-12 ">

                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                masterDetail = {true}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                // enableRangeSelection={false}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                floatingFilter={false}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>
                                    

								</div>

                            </div>
				   		</div>
				   	</div>
				 </div>


				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
            </div>
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{

	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);

}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }

  function groupBy(list, keyGetter) {

    const map = new Map();

    list.forEach((item) => {

         const key = keyGetter(item);

         const collection = map.get(key);

         if (!collection) {

             map.set(key, [item]);

         } else {

             collection.push(item);

         }

    });

    return map;

}

function formatYearMonth(list){
    var monthName = {
        1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun",
        7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec"
    }
    list = list.map((e)=>{
        var year = e.split("-")[0];
        var month = e.split("-")[1];
        return monthName[parseInt(month)]+" "+year
    })
    return list
}
function formatNumber (num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}