/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import {
    getDDMMYYYY, getDDMMMYYYY, getHyphenDDMMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import ShowTPTGpsMap from "./showtptgpsmap";
import ShowTPTGpsBreaks from "./showtptgpsbreaks";
import RoutemapAction from "./gpsroutemaplegs";
import ExportPDF from "./exportpdf"
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class TicketManagementForStoppages extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-n',
            overly: "show-n",
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            rowData: [],
            pdfRowData: [],
            pdfColumns: [],
            frameworkComponents: {
                showtptgpsmap: ShowTPTGpsMap,
                showtptgpsbreaks: ShowTPTGpsBreaks
            },
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            rowClassRules: {
                "boxbgblue": "data.to_be_approved == 1"
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 200,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "GPS EOD Analysis",
            sliderRouteTranslate: '',
            startDate: "",
            endDate: "",
            defaultStartDate: "",
            defaultEndDate: "",
            routeinfo: "",
            breaktruck: "",
            uniquetrucks: [],
            showTrucksList: "show-n",
            truck_no_list: [],
            tottalDistance: [],
            pinnedBottomRowData: [],
            pageType: 1,
            showDpk: { display: "none" },
            showMpk: { display: "none" },
            pageTitle: "Stoppages Data",
            tpttrucks: [],
            transporterTrucks: [],
            adaniCheck: 0,
        };


    }
    async componentDidMount() {
        var tptCode = localStorage.getItem('transportercode');
        if (tptCode.includes("T293") == true) {
            var adaniCheck = 1;
        }
        else {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck: adaniCheck
        });
        var vehicle_group = localStorage.getItem("vehicle_group");
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            }
            await redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    this.setState({
                        tpttrucks: records
                    })
                })
        }
        var deviceparams = {
            transporter_code: localStorage.getItem("transportercode")
        }
        await redirectURL.post("/dashboard/devices", deviceparams).then((response1) => {
            var transporterTrucks = [];
            var trunkslist = []
            var tptCode = JSON.parse(localStorage.getItem("transportercode"));

            var tpttrucks = this.state.tpttrucks;
            var tptunqrecords = []
            if (tpttrucks.length > 0) {
                response1.data.devices.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f.truck_no) {
                            transporterTrucks.push(f)
                            trunkslist.push(f.truck_no)
                        }
                    })
                );
            }
            else {
                response1.data.devices.map(function (e) {
                    if (tptCode.indexOf(e.transporter_code) > -1) {
                        transporterTrucks.push(e)
                        trunkslist.push(e.truck_no)
                    }
                })
            }
            this.setState({
                truckNoList: trunkslist,
            })
        })
        loadDateTimeScript()
        var sdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
        var edate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD');
        $("#tmfromdate").val(sdate);
        $("#tmtodate").val(edate);
        var parameters = {
            truck_no : this.state.truck_no_list,
            startDate: sdate + " 05:00:00",
            endDate: edate + " 22:59:59",
            transporter_code: JSON.parse(localStorage.getItem("transportercode"))
        }

        this.onLoadData(parameters)
    }
    onClickFilterData = () => {
        var sdate = $("#tmfromdate").val();
        var edate = $("#tmtodate").val();

        var parameters = {
            truck_no : this.state.truck_no_list,
            startDate: sdate+" 05:00:00",
            endDate: edate+" 22:59:59",
            transporter_code: JSON.parse(localStorage.getItem("transportercode"))
        }
        this.onLoadData(parameters)
    }

    onLoadData(parameters) {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        redirectURL.post("/dashboard/getTicketMangementDataForStoppages", parameters).then(response => {
            console.log(response.data , "records_1729")
            var records = response.data 
            if(records.length> 0){
                this.setState({
                    rowData:records,
                    loadshow: "show-n",
                    overly: "show-n",
                })
            }else{
                this.setState({
                    rowData:[],
                    loadshow: "show-n",
                    overly: "show-n",
                })
            }
        })
    }

    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        if(dataset !== undefined && dataset !== "undefined"){
            dataset = dataset.slice(0, 100);
            var tpttrucks = this.state.tpttrucks;
            var tptunqrecords = []
            if (tpttrucks.length > 0) {
                dataset.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f) {
                            tptunqrecords.push(f)
                        }
                    })
                );
            }
            else {
                tptunqrecords = dataset
            }
            this.setState({
                truck_no_list: tptunqrecords,
                showTrucksList: "show-m",
            })
        }
    }
    handlerForm = (event) => {
        //console.log("Select", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if (tpttrucks.length > 0) {
            dataset.filter(f =>
                tpttrucks.filter((d) => {
                    if (d.truck_no == f) {
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else {
            tptunqrecords = dataset
        }
        if(tptunqrecords !== undefined && tptunqrecords !== "undefined"){
            var filteredResult = tptunqrecords.filter(function (e) {
                if (e != "" && e != null) {
                    return e.toString().toLowerCase().includes($("#inputTruckForStoppages").val().toLocaleLowerCase());
                }
    
            });
            filteredResult = filteredResult.slice(0, 100);
            this.setState({
                truck_no_list: filteredResult,
                showTrucksList: "show-m",
            })
        }
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });

    }

    render() {
        var listData = []
        this.state.truck_no_list.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        
        var columnwithDefs = [

            {
                headerName: 'Truck No',
                field: "truck_no",
                width: 120,
            },
            {
                headerName: "Break Address",
                field: "break_address",
                width: 300,
            },
            {
                headerName: "Break Start",
                field: "break_start",
                width: 180,
                valueGetter: function (params) {
                    try {
                        if (params.data.break_start != "" && params.data.break_start != undefined) {
                            return getDDMMMYYYYHHMMDefault(params.data.break_start);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Break End",
                field: "break_end",
                width: 180,
                valueGetter: function (params) {
                    try {
                        if (params.data.break_end != "" && params.data.break_end != undefined) {
                            return getDDMMMYYYYHHMMDefault(params.data.break_end);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
           
            {
                headerName: "Break Duration",
                field: "break_time_seconds",
                width: 180,
                valueGetter: function (params) {
                    try {
                        return secondsToDhms(parseInt(params.data.break_time_seconds));
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            
           

        ]
        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">

                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-truck-alt cus-i"></i> <span>{this.state.pageTitle}  </span>
                                </h5>
                            </div>
                            <div className="card-body row">
                                <div className="row col-xl-12 col-lg-12">
                                    <div style={{ top: "65px" }} className={"trucks-dropdown " + this.state.showTrucksList}>
                                        <ul>
                                            {listData}
                                        </ul>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>{(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}</label>
                                        <input
                                            type="text"
                                            name="truck_no"
                                            id="inputTruckForStoppages"
                                            placeholder={(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}
                                            autoComplete="off"
                                            className="form-control"
                                            onFocus={this.initalList.bind(this)}
                                            onChange={this.handlerForm.bind(this)} />
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>From Date</label>

                                        <input
                                            type="text"
                                            id="tmfromdate"
                                            name="tmfromdate"
                                            className="form-control stoppages_filters_date"
                                        />
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>To Date</label>

                                        <input
                                            type="text"
                                            id="tmtodate"
                                            name="tmtodate"
                                            className="form-control stoppages_filters_date"
                                        />
                                    </div>


                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button type="button" className="btn btn-success" style={{ marginTop: "29px" }} onClick={this.onClickFilterData}>Submit</button>
                                    </div>

                                </div>
                                <div className={"row col-xl-12 col-lg-12 "}>

                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            masterDetail={true}
                                            pinnedBottomRowData={this.state.tottalDistance}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                getRowStyle: function (params) {
                                                    if (params.node.rowPinned) {
                                                        return { 'font-weight': 'bold', 'font-size': '16px' };
                                                    }
                                                },
                                            }}
                                        />

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>

        );
    }
}


function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.stoppages_filters_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#tmtodate", function(e) {
    e.preventDefault();
    var bookingDate=$("#tmfromdate").val();
    var cargoDate=$("#tmtodate").val();

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);

    var startTime = startDate.getTime();
    var endTime = endDate.getTime();

    if(startTime > endTime)
    {
        $("#tmtodate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#tmfromdate", function(e) {
    e.preventDefault();
    var bookingDate=$("#tmfromdate").val();
    var cargoDate=$("#tmtodate").val();

	var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);

    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    if(startTime > endTime)
    {
        $("#tmfromdate").val("");
        alert("From Date should be less than equal to To Date");
    }
})


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}

function secondsToString(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function minutesToDHM(n) {
    var num = n;
    var d = Math.floor(num / (60 * 24));
    var h = Math.floor((num % (60 * 24)) / 60);
    var m = Math.floor((num % (60 * 24)) % 60);

    var dDisplay = d >= 0 ? d + (d === 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h === 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m === 1 ? " Mins " : " Mins ") : "";
    return dDisplay + hDisplay + mDisplay
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    // console.log(date.length);
    // console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
function arrayMin(arr) {
    return arr.reduce(function (p, v) {
        return (p < v ? p : v);
    });
}

function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}
$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruckForStoppages").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruckForStoppages");
        if (!container.is(e.target)) {
            try {
                if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})
