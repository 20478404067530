import React, { Component, Fragment } from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Constant from "../common/googlemapkey";
// import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from './dates'; 
var infoBox = require('../common/google-infowindow');
var moment = require('moment');
var redirectURL = require('../redirectURL');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;

var coordinates=[];
var viewMarkersArr=[];
var coords_copy = [];
var markersArray;
var map;

class MobileMap extends Component {
	constructor(props){
		super(props);
		this.state = {
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			  
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

          },
          mapinfo:[]	
		};
		
	}
	componentDidMount(){
        // var truckno = this.props.match.params.truckno;
        // console.log("truckno ",truckno)
        // var parameters={
        //     truck_no:truckno
        // }
        // redirectURL.post("/common/truckmapdata",parameters)
        // .then((response) => {
        //     console.log("andriod map ",response.data)
        //     var mapdata = []
        //     if(response.data.records.length > 0)
        //     {
        //         mapdata = [response.data.records[0].last_known_location];
        //     }
        //     this.setState({
        //         mapinfo:mapdata
        //     })
            this.renderMap()
        // })
	}
	componentWillReceiveProps(){
		this.renderMap()
	}
    renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key=" + Constant.GOOGLE_KEY + "&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

    plotMultiColorPolyline(arr2,map,bounds)
    {
        if(arr2.length)
        {
            var data_type_list = [...new Set(arr2.map(item => item.type))];
            if(data_type_list.includes("transshipment"))
            {
                var data_type = "transshipment"
            }
            if(data_type_list.includes("rake"))
            {
                var data_type = "rake"
            }
            this.setState({
                data_type : data_type
            })
        }
        
        if(this.props.stepcoords == undefined)
        {
            if(arr2.length <= 1000)
            {
                var icon_cnt = 60;
                var icon_divisible = 60;
            }
            else
            {
                var icon_cnt = 500;
                var icon_divisible = 500;
            }
        }
        else
        {
            var icon_cnt = 1;
            var icon_divisible = 1;
        }
        //console.log(arr2, "coords")
        
        for(var i=0;i< arr2.length-1;i++)
        {
            var locationLatLng = [];
            locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
            if(icon_cnt % icon_divisible == 0)
            {
                var lineicon = [{
                            icon: {
                                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                    strokeColor:'#FF0000',
                                    fillColor:'#FF0000',
                                    fillOpacity:1,
                                    strokeWeight: 2
                            },
                            repeat:'100px',
                            path:[]
                        }];
            }else{lineicon=[]}
            icon_cnt = icon_cnt + 1
            var consignments_missing_route_line = new window.google.maps.Polyline({
                map:map,
                path : locationLatLng,
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: lineicon
            });
            if(arr2[i].msgtype == "G")
            {
                consignments_missing_route_line.setOptions({
                    strokeColor : "#452a68",
                    strokeWeight: 5.5,
                })
            }
            
            if(arr2[i].set == undefined && arr2[i].msgtype != "G")
            {
                consignments_missing_route_line.setOptions({
                    strokeColor : "#157254"
                })
            }
            else if(arr2[i].set == 1 && arr2[i].msgtype != "G")
            {
                consignments_missing_route_line.setOptions({
                    strokeColor : "#157254"
                })
            }
            else if(arr2[i].set == 2 && arr2[i].msgtype != "G")
            {
                consignments_missing_route_line.setOptions({
                    strokeColor : "#0000ff",
                    strokeWeight: 5.5,
                })
            }
            else if(arr2[i].set == 3 && arr2[i].msgtype != "G")
            {
                consignments_missing_route_line.setOptions({
                    strokeColor : "#980000",
                    strokeWeight: 5.5,
                })
            }
            bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
        }
    }
    plotBoundaryLines(boundaryarr){
        //console.log("boundaries ",boundaryarr)
        if(boundaryarr.length > 0)
        {
            
            boundaryarr.map((item) => {
                if(item.length>0)
                {
                    var line=new window.google.maps.Polyline(
                        {
                            map:map,
                            strokeColor: '#0ABB50',
                            strokeOpacity: 1.0,
                            strokeWeight: 2.5
                        });
                    for(var i =0; i<item.length;i++)
                    {
                        var locationLatLng = [];
                        locationLatLng.push(new window.google.maps.LatLng(item[i].lat,item[i].lng));
                        var consignments_missing_route_line = new window.google.maps.Polyline({
                            map:map,
                            path : locationLatLng,
                            strokeColor: '#0ABB50',
                            strokeOpacity: 1.0,
                            strokeWeight: 2.5,
                            fillColor: '#1D9567',
                            fillOpacity: 0.8
                            //icons: lineicon
                        });
                        
                        var path=line.getPath().getArray();								
                        let latLng=new window.google.maps.LatLng(item[i].lat,item[i].lng);
                        path.push(latLng);
                        line.setPath(path);
                    }
    
    
                    // var geoFencingArea = new window.google.maps.Polygon({
                    // 	//map:map,
                    //   paths: item,
                    //   editable: true,
                    //   strokeColor: '#1D9567',
                    //   strokeOpacity: 0.70,
                    //   strokeWeight: 0,
                    //   fillColor: '#1D9567',
                    //   fillOpacity: 0.35,
                    //   draggable:true
                      
                    // });
                    // geoFencingArea.setMap(map);
                    // //console.log("test ",geoFencingArea.getPath());
                    // //window.google.maps.event.addListener(geoFencingArea, "dragend", getPolygonCoords);
          
                    // window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at',getPolygonCoords);
                    // window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);
                    
    
    
                    
                }
            })
        }
    }
    
	initMap = () => {
		var currentwindow;
		var dealer='';
		var lt, ln;
		var arr=[];
		
        var jsondata = this.props.mapinfo;
        // console.log("jsondata ",jsondata)
        var locations = [];
        if(this.props.mapFor == 'truckgps')
        {
            var jsondata = this.props.mapinfo;
            var locations = jsondata.breaks;
            arr = jsondata.coords;
            console.log("arr here ", arr)
            // coords_copy = arr;
        }
        else
        {
            arr = jsondata;
        }
        console.log("arr ", arr)
        try{
            if(arr.length > 0 && arr != undefined)
            {
                lt = arr[0].lat;
                ln = arr[0].lng;
            }
            else
            {
                lt = "";
                ln = "";
             }
        }
        catch(e){

        }
       
    	
         var mapOptions = {
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels:true,
			mapTypeControlOptions: {
				mapTypeIds: ['hybrid', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt,ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
    	        
		var map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt,ln));
			// Create our info window content
		var currentinfowindow = null;
		var line=new window.google.maps.Polyline(
		{
			map:map,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2.5,
			icons: [{
					icon: {
							path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							strokeColor:'#ff8c52',
							fillColor:'#ff8c52',
							fillOpacity:1,
							strokeWeight: 2
					},
					repeat:'100px',
					path:[]
				}]
		});
        
        try{
            if(arr.length > 0)
            {

                var arr1=[];
                //console.log("Total ",ratio);
                if(this.state.timelinesmarkers.length > 0)
                {
                    arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
                        return (index % ratio == 0);
                    } );
                }
                
                var arr2=[];
                var darr=[];
                if(arr.length > 0)
                {
                    darr = arr.filter(function (value, index, arr) {
                        //console.log("Vale ",value)
                        return (value.dist_from_prev_point > 0);
                    } );
                    if(darr.length < 2000)
                    {
                        var ratio = 1;
                    }
                    else{
                        var ratio = 20;
                    }
                    //console.log("darr.length ", darr.length)
                    arr2 = darr.filter(function (value, index, darr) {
                        return (index % ratio == 0);
                    } );
                    

                    //var arr1 = this.state.timelinesmarkers;
                }
                
                
                for (let i = 0; i < arr.length; i++) {
                    
                    var path=line.getPath().getArray();
                            
                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
                    path.push(latLng);
                    line.setPath(path);
                        
                    //Change line color based on map type
                    window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
                        if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
                        {
                            var line=new window.google.maps.Polyline(
                            {
                                map:map,
                                strokeColor: '#FFFFFF',
                                strokeOpacity: 1.0,
                                strokeWeight: 2.5,
                                icons: [{
                                        icon: {
                                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                strokeColor:'#ff8c52',
                                                fillColor:'#ff8c52',
                                                fillOpacity:1,
                                                strokeWeight: 2
                                                },
                                        repeat:'100px',
                                        path:[]
                                    }]
                                });                
                        }
                        else
                        {
                            var line=new window.google.maps.Polyline(
                            {
                                map:map,
                                strokeColor: '#157254',
                                strokeOpacity: 1.0,
                                strokeWeight: 2.5,
                                icons: [{
                                        icon: {
                                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                strokeColor:'#ff8c52',
                                                fillColor:'#ff8c52',
                                                fillOpacity:1,
                                                strokeWeight: 2
                                                },
                                        repeat:'100px',
                                        path:[]
                                    }]
                                });

                        }

                        for (i = 0; i < arr.length; i++) {
                                var path=line.getPath().getArray();
                                let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
                                path.push(latLng);
                                line.setPath(path);
                                        //map.setCenter(latLng);
                            }
                                        
                        var x = map.getZoom();
                        var c = map.getCenter();
                        window.google.maps.event.trigger(map, 'resize');
                        map.setZoom(x);
                        map.setCenter(c);
                    } );
                    bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
                    
                }

                var infowindow = new window.google.maps.InfoWindow();
                var marker, l;
                if(locations.length > 0)
                {
                    for (l = 0; l < locations.length; l++) {
                        marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
                        icon:require('../assets/icons/cf.png'),
                        map: map,
                        });

                        window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
                            return function() {
                                //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
                            
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            // var header = "Break"
                            // contentarr.push({"key":"Break time", "value":locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")"})
                            
                            // var contentString = infoBox(marker.icon, header, contentarr,'')
                            var contentString="";
                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);
                            //console.log(marker.position.toJSON());
                            console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                            }
                        })(marker, l));
                    }
                }
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
                    icon:require('../../assets/icons/track_start.png'),
                    map: map,	
                });
            
                
            }
        }
		catch(e){

        }

		map.fitBounds(bounds)
	}


    render () {
        const modalStyles  = {
            width:'500px !important',
        }
        console.log(this.props)
        return (
           
			<div className="col-xl-12 col-md-12 n-p-0">
				<div id="map" className="" style={{width:'100%',height:"100vh"}}></div>		
			</div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


export default MobileMap;