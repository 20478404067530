import React, { Component } from "react";
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance
import './ErrorMessage.css'; // Import CSS file for styling


class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {successLogin:0,loginMessage:""};
    }

    async componentDidMount() {
        const token = this.props.match.params.token;
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login ", response);

            if (response.status === 200) {
                if (response.data.auth) {
                    if (response.data.otpAuthentication === 1) {
                        this.setState({
                            otpAuthentication: response.data.otpAuthentication,
                            otpMessage: "OTP has been sent to your Email",
                            userData: response.data,
                            successLogin: 0,
                        });
                    } else {
                        this.setState({
                            "successLogin" : 1,
                        })
                        await redirectURL.post("/manage/saveuserlogged", {
                            token: response.data.token,
                            userId: response.data.userid
                        });
                        
                        //	console.log("In  LoginForm response.data.user.dept_code = ", response.data.user.dept_code);
						localStorage.setItem('token', response.data.token);
											
						localStorage.setItem('userid', response.data.userid);
						let deptCode = JSON.stringify(response.data.user.dept_code);
						//console.log("In  LoginForm, response.data = ", response.data);
						localStorage.setItem('dept_code', deptCode);
						localStorage.setItem('is_admin', response.data.user.is_admin);
						localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
						localStorage.setItem('is_global', response.data.user.is_global);
						localStorage.setItem('firstname', response.data.user.firstname);
						localStorage.setItem('username', response.data.user.username);
						localStorage.setItem('lastname', response.data.user.lastname);
						localStorage.setItem('email', response.data.user.email);
						localStorage.setItem('user_type', response.data.user.user_type);
						localStorage.setItem('is_oem', response.data.user.ie_oem);
						localStorage.setItem('is_hero', response.data.user.is_hero);
						localStorage.setItem('oem', response.data.user.oem);
						//console.log("response.data.menus ", response.data.menus);
						localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
						//console.log("response.data.usermenucodes ", response.data.usermenucodes);
						localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
						localStorage.setItem('transportercode', JSON.stringify(response.data.user.transporter_code));
						
						localStorage.setItem('roles', response.data.user.roles);
						localStorage.setItem('is_enmovil_transporter', response.data.user.is_enmovil_transporter);
						localStorage.setItem('is_sub_user', response.data.user.is_sub_user);
						try{
							if(response.data.user.vehicle_group != undefined)
							{
								localStorage.setItem('vehicle_group', response.data.user.vehicle_group);
							}
							else{
								localStorage.setItem('vehicle_group', "");
							}
						}
						catch(e){}
						try{
							if(response.data.user.is_stockyard != undefined)
							{
								localStorage.setItem('is_stockyard', response.data.user.is_stockyard);
							}
							else{
								localStorage.setItem('is_stockyard', "");
							}
						}
						catch(e){}
						
						//console.log('users roles', typeof response.data.user.roles);
						sessionStorage.setItem('ref', 1);                       
                        localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
                        localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                        localStorage.setItem('roles', response.data.user.roles);
                        var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                        var m = md(JSON.stringify(response.data.user))
                        localStorage.setItem('m', m);
                        this.setState({
                            username: '',
                            email: '',
                            password: '',
                            loginMessage: response.data.msg
                        });
                        redirectURL.post('/menus/getmenus', {},{
                            headers:{
                                'Content-Type':'application/json'
                            }
                        })
                        .then( response => {
                            //console.log("Menus ",response);
                            if (response.statusText == 'OK') {
                                //console.log("In  SIDEBAR LoginForm MENUS response.data = ", response.data);
                                let menuText = JSON.stringify(response.data);
                                localStorage.setItem('menuText', menuText);
                
                            } else {
                                this.setState({
                                    username:'',
                                    email:'',
                                    password:'',
                                    loginMessage:response.data.msg
                                });
                            }
                            let MENUITEMS = getMenuLocalStorage();
                
                            //console.log("IN Sidebar componentWillMount, From DB MENUITEMS menuList ", MENUITEMS);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                    }
                } else {
                    this.setState({
                        username: '',
                        email: '',
                        password: '',
                        loginMessage: response.data.msg
                    });
                }
            } else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }

    getUserDashboardUrl(pathToMatch = null) {
		let dashboardUrl = "/tptlogin/gpsintegrationstatus";
		let currentDepartmentCode = null;
		let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

		//console.log("IN render, departmentCode ", departmentCode);
		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0]; 
		} else {
			currentDepartmentCode = departmentCode; 
		}

		var userroles = localStorage.getItem("roles");
		//console.log("IN render, currentDepartmentCode ", currentDepartmentCode);
		//console.log("localStorage roles ", localStorage.getItem("roles"))
		if(localStorage.getItem("is_enmovil_transporter") == 1)
		{
			if(localStorage.getItem("transportercode").indexOf("T243") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					dashboardUrl="/tptlogin/tafetrips";
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if (userroles =="tpt_billing_screen") {
				dashboardUrl = "/tptlogin/billingsummary"
			}
			else if(localStorage.getItem("transportercode").indexOf("T322") >= 0 || localStorage.getItem("transportercode").indexOf("T321") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					dashboardUrl="/tptlogin/tafetrips";
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("T215") >= 0 )
			{
				console.log(localStorage.getItem("transportercode"),"localStorage.getItem(transportercode)")
				if(localStorage.getItem("email") == "fiattrips@entrac.in")
				{
					console.log(localStorage.getItem("email"),"localStorage.getItem(email)")
					dashboardUrl="/tptlogin/fiattrips";
				}
				else
				{
					console.log("step2")
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("313039") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					dashboardUrl="/tptlogin/mahindratrips";
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("T305") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					if(localStorage.getItem("is_stockyard") == 1)
					{
						dashboardUrl="/tptlogin/stockyardmanagement";
					}
					else if(userroles.indexOf("tpt_subuser_challan") >= 0)
					{
						dashboardUrl="/tptlogin/challansScreen";
					}
					else{
						if(localStorage.getItem("email") == "ivc.ashokleyland@entrac.in")
						{
							dashboardUrl="/tptlogin/ashokleylandtrips";						
						}
						else if(localStorage.getItem("email") == "ivc.jawa@entrac.in" || localStorage.getItem("email") == "rajkamalsingh@ivclogistics.com")
						{
							dashboardUrl="/tptlogin/jawatrips";
						}
						else if(localStorage.getItem("email") == "ivc.tafe@entrac.in" || localStorage.getItem("email") == "kuldeepsharma@ivclogistics.com")
						{
							dashboardUrl="/tptlogin/tafetrips";
						}
					}
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("T329") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					if(localStorage.getItem("email") == "hciltrips@entrac.in")
					{
						dashboardUrl="/tptlogin/hciltrips";
					}
					else if(localStorage.getItem('email') == 'arpl_test@enmovil.in'){
						 dashboardUrl = "/tptlogin/testTripData"
					}
					else
					{
						dashboardUrl="/tptlogin/gpsintegrationstatus";
					}
				}
				else
				{
					dashboardUrl="/tptlogin/gpsintegrationstatus";
				}
			}
			else if(localStorage.getItem("transportercode").indexOf("302178") >= 0 || localStorage.getItem("transportercode").indexOf("313356") >= 0)
			{
				if(localStorage.getItem("is_sub_user") == 1)
				{
					dashboardUrl="/tptlogin/olatrips";
				}
			}
			else if (userroles.indexOf("tpt_subuser_railsummary") >= 0)
			{
				dashboardUrl="/tptlogin/tptrailsummary";
			}
			else if(localStorage.getItem("email").indexOf("billing@enmovil.in") >= 0){
				dashboardUrl="/tptlogin/billingsummary";
			}
			else if (userroles.indexOf("JWS") >= 0 || localStorage.getItem("email").indexOf("msil_rake@enmovil.in") >= 0)
			{
				dashboardUrl="/tptlogin/gpsintegrationstatus";
			}
			else if(localStorage.getItem("email").indexOf('amops5@orientalcarriers.in') >=0){
				dashboardUrl="/tptlogin/stockyardmanagement";
			}
			else
			{
				dashboardUrl="/tptlogin/gpsintegrationstatus";
			}
		}
		else
		{
			dashboardUrl="/tptlogin/gpsintegrationstatus";
		}
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}

	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
    render() {
		if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
		{
			var successLogin = this.state.successLogin;
			if(successLogin == 1)
			{
				let dashboardUrl = this.getUserDashboardUrl();
				console.log(dashboardUrl,"dashboardUrl1143");
				//let dashboardUrl = this.state.landingpage;
				// return <Redirect to="dashboard/snddefault" push={true} />

				return <Redirect to={dashboardUrl} push={true} />
			}
		}
        return (
            <div>
                {/* Loader ends */}
                {(this.state.loginMessage)?
                <div className="error-container">
                    {/* SVG for error icon */}
                    <svg
                    className="error-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                        fill="red"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12h2v8h-2zm0 10h2v2h-2z"
                    />
                    </svg>
                    {/* Error message */}
                    <p className="error-message">{this.state.loginMessage}</p>
                </div>
                :
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                }
            </div>
        );
    }
}
function getMenuLocalStorage() {
    let menuText = localStorage.getItem('menuText');
    let MENUITEMS = null;
    //   console.log("deptCodes = ", deptCodes);
    //   console.log("Type of deptCodes = ", typeof deptCodes);
    try {
        MENUITEMS = JSON.parse(menuText);
    } catch (error) {
        console.log('Error while Parsing menuText', error);
    }
}
export default EnmUserLogin;