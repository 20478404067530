
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const UpdateTPTbatchvin = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickUpdateItem(props);
    };

    return (
        <div>
          	<button onClick={handleOverspeedClick} className={"custom-btn f12 btn btn-success "} title="Update" >Update</button>
          
        </div>
    );
};

export default UpdateTPTbatchvin;
