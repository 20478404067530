/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ExportPDF from "./exportpdf"
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import ForceCloseSideBar from "../layouts/forceclosuresidebarcomponent";
import TptMSILTripsCounters from './tptmsiltripscounters';
import CSVFileValidator from 'csv-file-validator';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
var containerslist=[];
export default class TPTConsignments extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode : "",
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderCommentTranslate : "",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			sliderSegmentTranslate : "",
			mapinfo:'',
			commentsRowData:[],
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			from_date: moment.parseZone(new Date()).subtract(1, "month").format("YYYY-MM-DD"),
			to_date: moment.parseZone(new Date()).format("YYYY-MM-DD"),
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				pivot : true,
				enableValue: true,
    			enableRowGroup: true,
      	    },
      	    rowData: [],
			maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
				consignmentActions:ConsignmentActions,
				consignmentforceclose: Consignmentforceclose
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype:{"value":'all', "label":'All'},
			tripvalue:'',	
			consigner:[{"value":'all', "label":'All'}],
			originalData:[],
			isCounterDisplay:1,
			screenpage:'',
			pagetitle:'Other OEMs',
			countersjson:'',
			originalcountersjson:'',
			consigneecoords:'',
			consigner_coordinates:'',
			containerslist:[],
			forceclosedata:'',
			reached_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			reachedhh:'',
			reachedmm:'',
			reachedss:'00',
			left_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			lefthh:'',
			leftmm:'',
			leftss:'00',
			invoice_time:'',
			invoice_no:'',
			invoicehh:'',
			invoicemm:'',
			segementConsignmentCode : "",
			counter_screen : "",
			invoicess:'00',
			reasonforceclose:'',
			sliderTranslatesidebar:'',
			consignment_details:'',
			startDatetime:'',
			endDatetime:'',
			tolls : [],
			excelStyles : [
				{
					id : "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function(params) {
				if(typeof params.data.dct_comment != 'undefined'){
					//console.log("pars ", params.data.dct_comment.length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if(typeof params.data.vin_list != 'undefined'){
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.vin_list.toString().length/ 50))
					);
				}
				else if(typeof params.data.force_closure_reason != 'undefined'){
					//console.log("pars ", params.data.dct_comment.length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else{
					return 30;
				}
			  },
			  defaultsdate:'',
			  defaultedate:'',
			  movementtype:{"value":"all","label":"All"},
			  usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  showumncr:0,
			  showummove:0,
			  usermanualncrmodal:false,
			  usermanualmovemodal:false,
			  slideuploadeway:"",
			  cancelled_data: [],
			  pod_received_data: [],
              trip_closed_by_pod: [],
			  transporterTrucks:[],
			  tpttrucks:[],
			  adaniCheck:0,
			  addressColumn: 300,
			  pdfRowData: [],
			  pdfColumns: [],
			  tripDate:"",
			  bulkslide:"",
			  selectedTruck:{"label":"",value:""},
			//   markbtn:"Mark Fiat Trip",
			  sliderForceCloseTranslate: "",
              sliderBulkForceClose:"",
			  sliderBulkUpload: "",
			  bulkClosureData:[],
			  cities: [],
			  drivers: [],
			  oems: [],
			  oem: { "value": "", "label": "OEM" },
			  dealers: [],
			  plantlocations: [],
			  dealer: { "value": "", label: "Dealer" },
			  city: { "value": "", label: "City" },
			  transittype: { "value": "Road", "label": "Road" },
			  driver: { "value": "", "label": "Driver" },
			  filteredDataTrip: [],
			  plants: [],
			  showTrucksListTrip: "show-n",
			  counters_data: [],
			  allRowData:[],
			  msilCounters: {
				"transit_delay": [],
				"nightdriving_exception": [],
				"no_gps_data": [],
				"overspeeding_exception": [],
				"loading_delay_exception": [],
				"enroute_exception": [],
				"is_critical": [],
				"probable_accident": []
			},
			uniqueOverSpeedTruckList: [],
			uniqueNightDrivingTruckList: [],
			uniqueLoadingExceptionTruckList: [],
			uniqueEnrouteStoppagesTruckList: [],
			sliderReportTranslate: "",
			sliderTitle : "",
			reportRowData : []
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
	}

	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
		var tptCode = localStorage.getItem("transportercode");
		if (localStorage.getItem("transportercode") != undefined) {
			var transporter = JSON.parse(localStorage.getItem("transportercode"))
		}
		else {
			transporter = "";
		}
		if (Array.isArray(transporter)) {
			var transporter_code = transporter[0];
		}
		else {
			transporter_code = transporter[0]
		}
		this.setState({
			tcode: transporter_code,
		})
		if(this.props.pageLoad == "10")
		{
			loadDateTimeScript();
			// console.log("tafe trips");
			if(tptCode.includes("T293") == true)
			{
				var adaniCheck = 1;
			}
			else
			{
				var adaniCheck = 0;
			}
			this.setState({
				adaniCheck : adaniCheck
			});
			redirectURL.post('/dashboard/oemmasterdata')
				.then((response) => {
					var records = response.data.records;
					//   console.log("records ", records)
					this.setState({
						oems: records
					});
				})
				.catch(function (error) {
					console.log(error);
				});
				var transporter_code = localStorage.getItem("transportercode")
				var prms = {
					transporter_code: transporter_code,
					oem_code: this.state.oem.value
				}
				redirectURL.post("/dashboard/oemplantdealersdata", prms)
					.then((response) => {
						console.log("respone oem ", response.data)
						var dealers = response.data.dealers;
						var plants = response.data.plants;
						this.setState({
							dealers: dealers,
							plantlocations: plants
						})
					})
				let tpCode = localStorage.getItem("transportercode")
				var parameters = {
					transporter_code: tpCode
				}
				redirectURL.post('/dashboard/tptdrivers', parameters)
				.then((response) => {
					var records = response.data.records;
					//   console.log("records ", records)
					this.setState({
						drivers: records
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			this.onLoadGetConsignmentsList();

		}
		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:"/otheroemsscreen"})
        .then( async (resp) => {
			
            await this.setState({
                usergridstate:resp.data
            });
            await this.restoreGridStates();
        })
	};
	
	componentWillReceiveProps(newprops) {
		console.log(newprops,"this.props----MapView-Componentwill")
		if(newprops.pageLoad == "10" && this.state.rowData.length == 0)
		{
			// redirectURL.post('/dashboard/oemmasterdata')
			// 	.then((response) => {
			// 		var records = response.data.records;
			// 		//   console.log("records ", records)
			// 		this.setState({
			// 			oems: records
			// 		});
			// 	})
			// 	.catch(function (error) {
			// 		console.log(error);
			// 	});
			this.onLoadGetConsignmentsList();
		}
	}

	onLoadGetConsignmentsList()
	{
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		})
		// console.log(parameters);
		// console.log("urlpath ", urlpath)
		var deviceparams = {
			transporter_code:localStorage.getItem("transportercode"),
			from_date : this.state.from_date,
			to_date : this.state.to_date
		}
		redirectURL.post("/dashboard/otheroemconsignmentsdata", deviceparams)
		  .then(async (response) => {
            var records = response.data;
            console.log("recordsdata", records);
			let countersdata = []
			if (records.length > 0) {
				records.map((item) => {
					// console.log(item,"item trucks")				
					var elasptm = "";
					var elspa = ''
					if (item.last_moved_time != "" && item.last_moved_time != undefined) {
						var td1 = moment.parseZone(new Date()).format("YYYY-MM-DD")
						var tday1 = moment.parseZone(new Date(td1 + " 00:00:00")).format("YYYY-MM-DD HH:mm:ss")
						// console.log("tday1 ", tday1)
						var e1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
						// console.log("current date is: ", e1)
						var e2 = moment.parseZone(item.last_moved_time).format("YYYY-MM-DD HH:mm:ss")
						// console.log("last moved date is: ", e2)
						var ems = moment(e1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
						// console.log("Difference : ", ems)

						var ed = moment.duration(ems);
						// console.log("Duration : ", ed)
						var es = Math.floor(ed.asSeconds());
						// console.log("Seconds ",es, item.truck_no)
						var esmins = Math.floor(ed.asMinutes());
						// console.log("MInutes ",esmins, item.truck_no)

						var todayems = moment(tday1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
						// console.log("Today Difference : ", todayems)

						var todayed = moment.duration(todayems);
						var todaysec = Math.floor(todayed.asSeconds());
						var todaymin = Math.floor(todayed.asMinutes());

						// console.log("todayhrs ", todaysec)
						if (todaysec > 0) {
							// elspa = 0
							elspa = todaysec
						}
						else {
							// elspa = todaysec
							elspa = 0
						}
						if (es > 0) {
							elasptm = es
						}
						else {
							elasptm = 0
						}
					}
					else {
						elasptm = ""
					}
					// console.log("elasptm ", elasptm)
					item.elaped_time_from_last_moved_seconds = elasptm
					if (item.status == 2 || item.status == "2" || item.status == '3' || item.status == 3 || item.status == 4) {
						if (localStorage.getItem("transportercode").includes("T293")) {
							if (item.status == 2 || item.status == "2" || item.status == "In Transit") {
								var plant_location_name = "Gujarat";
								if (["Manesar", "Gurgaon", "Haryana"].includes(item.plant_location_name)) {
									plant_location_name = "Haryana"
								}
								if (plant_location_name == localStorage.getItem("plant_location")) {
									countersdata.push(item);
								}
							}
						}
						countersdata.push(item);
					}


				})
			}

			var msilCounters = {};			
			var transitDelayData = countersdata.filter(e =>{
				if(![undefined,null,""].includes(e.revised_trip_end) && ![undefined,null,""].includes(e.expected_trip_end)){
					var revised_trip_end = moment(e.revised_trip_end)
					var expected_trip_end = moment(e.expected_trip_end)
					if(revised_trip_end.diff(expected_trip_end,"hours")>0){
						return e
					}
				}
			})
			msilCounters['transit_delay'] = transitDelayData;
			var noGpsData = countersdata.filter(e => e.no_gps_data == "1")
			msilCounters['no_gps_data'] = noGpsData;

			let uniqueOverSpeedTruckList = [] 
			var overSpeeding = countersdata.filter(e => {
				if(e.overspeeding_exception == "1"){
					if(uniqueOverSpeedTruckList.includes(e.truck_no)){

					}else{
						uniqueOverSpeedTruckList.push(e.truck_no)
						return e
					}					
				}})
			msilCounters['overspeeding_exception'] = overSpeeding;
			let uniqueNightDrivingTruckList = [] 
			// var nightDriving = countersdata.filter(e => e.nightdriving_exception == "1")
			var nightDriving = countersdata.filter(e => {
				if(e.nightdriving_exception == "1"){
					if(uniqueNightDrivingTruckList.includes(e.truck_no)){

					}else{
						uniqueNightDrivingTruckList.push(e.truck_no)
						return e
					}					
				}})
			msilCounters['nightdriving_exception'] = nightDriving;
			let uniqueLoadingExceptionTruckList = []
			// var loadingDelay = countersdata.filter(e => e.loading_delay_exception == "1")
			var loadingDelay = countersdata.filter(e => {
				if(e.loading_delay_exception == "1"){
					if(uniqueLoadingExceptionTruckList.includes(e.truck_no)){

					}else{
						uniqueLoadingExceptionTruckList.push(e.truck_no)
						return e
					}					
				}})
			
			msilCounters['loading_delay_exception'] = loadingDelay;
			let uniqueEnrouteStoppagesTruckList = []

			// var stoppages = countersdata.filter(e => e.enroute_exception == "1")
			var stoppages = countersdata.filter(e => {
				if(e.enroute_exception == "1"){
					if(uniqueEnrouteStoppagesTruckList.includes(e.truck_no)){

					}else{
						uniqueEnrouteStoppagesTruckList.push(e.truck_no)
						return e
					}					
				}})
			msilCounters['enroute_exception'] = stoppages;
			var isCritical = countersdata.filter(e => e.is_critical == "1")
			msilCounters['is_critical'] = isCritical;
			var probableAccident = countersdata.filter(e => e.probable_accident == "1")
			msilCounters['probable_accident'] = probableAccident;

			// console.log('uniqueOverSpeedTruckList',uniqueOverSpeedTruckList)
			this.setState({
				counters_data: msilCounters,
				rowData : countersdata,
				allRowData : countersdata,
				loadshow:'show-n',
				overly:'show-n',
				uniqueOverSpeedTruckList,
				uniqueNightDrivingTruckList,
				uniqueLoadingExceptionTruckList,
				uniqueEnrouteStoppagesTruckList,
			});
		});
		// redirectURL.post("/dashboard/tpttrucks", deviceparams).then((response1)=>{
		// 	var trucks = response1.data.records;
		// 	var transporterTrucks = [];
		// 	trucks.map(function(tr){
		// 		transporterTrucks.push({
		// 			"label" : tr.truck_no,
		// 			"value" : tr.truck_no,
		// 		})
		// 	});
		// 	this.setState({
		// 		transporterTrucks : transporterTrucks
		// 	});		   
		// });
	}

	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow:'show-m'
		});
		var reqData = {
            consignment_code:e.consignment_code
        }
        //console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("records", records);
		
			this.setState({
				uploadDivWidth:'30%',
				sliderTranslate:"slider-translate-50p",
				showDiv:'show-m',
				timelinedata:records,
				loadshow:'show-n',
				overly:'show-m'
			});
		})
		.catch(function (error) {
		console.log(error);
		});
		
	}
	
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n',
			slideuploadeway:""
		});
	}
    
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		
	};	

	onGridState = () =>{
		//console.log(this.gridApi);
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);		   
	}

	
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkslide:"",
			selectedTruck:{"label":"",value:""},
            sliderBulkForceClose: '',
			sliderBulkUpload:'',
			sliderRso: "",
		});
		
	}

	showGoogleRouteAlert = () => {
        this.setState({
			show: true,
			basicTitle:"No google routes found",
			basicType:"danger"
        });
	}

	closeAlert = () => {
        this.setState({
            show: false
        });
	}
	onClickCounterShowDataTEST(params){
		console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
	}

    
	onSelectTrip= triptype =>{
		this.setState(
			{ triptype },
			() => console.log(`Trip Option selected:`, this.state.triptype)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	      () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			() => console.log(`Movement Option selected:`, this.state.movementtype)
		);
		
	}
	selectConsignerOptionsItems(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		if(this.state.deptcode == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-TNP')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
	}
	
	selectMovementTypeOptionsItems(){
        let items = [];     
		items.push({value:"all",label:"All"});
		items.push({value:true,label:"Local Movement"});
		items.push({value:false,label:"Non Local Movement"});
		return items;
	}


	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		if (this.state.to_date != "" && startdate > this.state.to_date) {
			this.setState({
				show: true,
				basicTitle: 'From Invoice Date should be less than To Invoice Date',
				basicType: "danger",
				loadshow: 'show-n',
				startDate: "",
			})
		}
		else {
			this.setState({
				from_date: startdate
			});
		}
	}
	
	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var edate = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1)) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		if (this.state.from_date != "" && edate < this.state.from_date) {
			this.setState({
				show: true,
				basicTitle: 'To Invoice Date should be greater than From Invoice Date',
				basicType: "danger",
				loadshow: 'show-n',
				endDate: "",
			})
		}
		else {
			this.setState({
				to_date: edate
			});
		}
	}

	submitOEMFilterData = (event) => {
		event.preventDefault();
        this.setState({
            overly:"show-m",
            loadshow:"show-m"
        })
		var fromdate = this.state.from_date
		var todate = this.state.to_date
		if (!["",undefined].includes(fromdate) && !["",undefined].includes(todate)) {
			this.onLoadGetConsignmentsList();
		}
	}

	onRowSelection(e){
		var bulkClosureData = this.state.bulkClosureData;
		if(e.colDef.field == "tpt_delivery_date")
		{
			bulkClosureData = bulkClosureData.filter(function(elem){
				return elem.trip_no != e.data.trip_no; 
			});
			if(e.data.tpt_delivery_date !=undefined && e.data.tpt_delivery_date !="")
			{
				var tptDelDate = e.data.tpt_delivery_date.replaceAll("/","-");
				tptDelDate = tptDelDate.split(" ")[0];
				bulkClosureData.push({
					consignment_code : e.data.trip_no,
					truck_no : e.data.truck_no,
					tpt_deliver_date : tptDelDate,
				})
			}
		}
		this.setState({
			bulkClosureData : bulkClosureData
		});
		// console.log(bulkClosureData,"bulkClosureData")
	}
	onClickShowMapView = async()=>{
		//this.gridApi.deselectAll();
		if(this.state.containerslist.length > 0)
		{
			var reqparms = {
				truck_no:this.state.containerslist,
				dept_code:this.state.deptcode
			}
		}
		else{
			var reqparms = {
				truck_no:[],
				dept_code:this.state.deptcode
			}
		}
		
		await redirectURL.post("/consignments/mapview",reqparms)
		.then((response) => {
			//console.log("Res ",response.data.message)
			var records = response.data.message;
			
			 var truck = JSON.parse(records.truck_info);
			 if(truck.length > 0)
			 {
				 if( truck.length > 1)
				{
					var consinees = this.state.plants;
				}
				else{
					var consinees = records.consignee_coordinates;
				}
			 }
			 else{
				var consinees = [];
			}
			 console.log("truckinfo,",truck)
			 //console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
			this.setState({
				open:true,
				maprowData:truck,
				rownode:truck,
				maptruckno:records.truck_no,
				consigneecoords:consinees,
				consigner_coordinates:records.consigner_coordinates
			});
			
			
			//containerslist=[];
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
	 };
	 changeSegmentHandler=(e)=>{
		//  alert(e.consignment_code);
		this.setState({
			sliderSegmentTranslate : "slider-translate-60p",
			segementConsignmentCode : e.consignment_code,
			overly : "show-m"
		})
	}

	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	 };
	
	 onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ openforceclose: false });
	 };

	 

	 
	 onClickForceClose = (params) =>{
		 //console.log("Pareas ", params)
		 this.setState({
			 forceclosedata:params.data,
			 forceCloseRowNode : params,
			 sliderForceCloseTranslate:"slider-translate-40p",
			 overly:'show-m',
			 reasonforceclose:""
		 })
	 }
	 handleInput(val) {
		this.setState({ reasonforceclose: val[1],selectedReason : val[0] });
		// console.log(val)
	  }
	 changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    	}
	
    onCloseUserManualModal = () => {
        this.setState({ usermanualncrmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualncrmodal: true });
    };

    onCloseUserManualMoveModal = () => {
        this.setState({ usermanualmovemodal: false });
    };

    onClickShowUserMoveManaul = () => {
        this.setState({ usermanualmovemodal: true });
	};
	
	onShowUploadEWay()
	{
		this.setState({
			slideuploadeway:"slider-translate",
			overly:'show-m'
		})
	}

	onClickBulkClose = () => {
		var csvcontentBulk = this.state.bulkClosureData;
		if(csvcontentBulk.length > 0)
		{
			redirectURL.post('/consignments/uploadotheroembulkforceclosetrips', csvcontentBulk).then(resp => {
				// console.log("respon", resp)
				if (resp.data.message == "success") {
					this.setState({
						show: true,
						basicType: "success",
						basicTitle: "Success",
					})
					window.location.reload();
				} else {
					this.setState({
						show: true,
						basicType: "warning",
						basicTitle: resp.data.message,
					})
				}
			})
		} else {
			this.setState({
				show: true,
				basicType: "warning",
				basicTitle: "Please Enter TPT Delivery Date to continue",
				file1: "",
			})
		}
	}
    
	resetUpload = () => {
		this.setState({
			slideuploadeway:'',
			overly:'show-n',
			file:'',
			bulkslide:'',
		});
	}
	
    onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		console.log("IN onShowRouteDiv, /Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});

		var gateouttime = moment.parseZone(e.trip_start_date).format('YYYY-MM-DD HH:mm:ss');
		var end_date = moment.parseZone(new Date()).format('YYYY-MM-DD HH:mm:ss');
		
		var reqData = {
            truck_no:e.truck_no,
			gate_out_time:gateouttime,
			seconddate:end_date,
			screen:"consignment"
        }
    console.log("reqData", reqData);
		redirectURL.post('/consignments/maproutesForTafe',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			try{
				if(records.status == "failure" || records.coords.length == 0)
				{
                    //console.log("records", records.coords);
                    if(records.status != undefined)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
					
				}
				else{
					if(records != '')
					{
						var sdate = e.gate_out_time;
						if(e.invoice_time)
						{
							console.log("came into invoice time", e.invoice_time)
							//console.log("Step1", e.invoice_time)
							var sdate = e.invoice_time;
						}
						else{
							//console.log("Step2")
							if(e.gate_in_time)
							{
								console.log("came into gate in time", e.gate_in_time)
								//console.log("Step3")
								var sdate = e.gate_in_time;
							}
							else{
								//console.log("Step4")
								if(e.gate_out_time){
									//console.log("Step5")
									console.log("came into gate out time", e.gate_out_time)
									var sdate = e.gate_out_time;
								}
							}
						}


						var edate ='';
						if(e.status <= 4)
						{
							//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
							//var edatesecond = new Date(ms);
							end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
							edate = getHyphenDDMMYYYYHHMMSS(end_date);
						}
						else if(e.status == 5)
						{
							if (e.left_dealer_marked)
							{
								edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
							}
							else{
								end_date = moment.parseZone().format('DD-MM-YYYY HH:mm:ss')
								// edate = getHyphenDDMMYYYYHHMMSS(end_date);
							}
						}
						//console.log("End Date ", edate)
						this.setState({
							sliderRouteTranslate:"slider-translate-60p",
							showDiv:'show-m',
							mapinfo:records,
							dealer:e.consignee_code,
							consignment_code:"Truck No : "+e.truck_no,
							maptruckno:e.truck_no,
							routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": end_date},
							loadshow:'show-n',
							sidebarSubHeader:"Consignment Information",
							overly:'show-m',
							rownode:e,
							leg_no:0,
							tolls : records.tolls
						});
						this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
						this.renderMap();

					}
				}
			}
			catch(e){
				// this.setState({
				// 	show: true,
				// 	basicTitle:'No Route data available',
				// 	basicType:"danger",
				// 	loadshow:'show-n'
				// });
			}
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n',
			slideuploadeway:""
		});
	}
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState(); 
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		console.log("grid col",this.gridColumnApi.getColumnState())
		let screenpage = 'Other OEMs';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:"/otheroemsscreen",
			screentitle:screenpage
		}
		console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		let screenpage = 'Other OEMs';
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:"/otheroemsscreen",
			screentitle:screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	setTripDate = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        // console.log(value);
        console.log(value)
        this.setState({tripDate:value});
    }

	onChangeTruckItem(selectedTruck){
		this.setState(
			{ selectedTruck },
			() => console.log(`Truck Option selected:`, this.state.selectedTruck)
		);    
	}
	onAddRow() {
		var tptCode = localStorage.getItem('transportercode');
		var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if (vehicle_group != "") {
			var tparms = {
				transporter_code: tptCode,
				region: vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
				.then((response) => {
					var records = response.data.records;
					// console.log("records ", records)
					tptrucks = records;
					this.setState({
						tpttrucks: records
					})
				})
		}
		var params = {
			transporter_code: localStorage.getItem("transportercode")
		}
		redirectURL.post("/dashboard/tpttrucks", params)
			.then((response) => {
				var records = response.data.records;
				var tpttrucks = tptrucks;
				console.log("tpttrucks ", tpttrucks)
				var trucksarr = []
				if (tpttrucks.length > 0) {
					records.filter(f =>
						tpttrucks.filter((d) => {
							if (d.truck_no == f.truck_no) {
								trucksarr.push(f)
							}
						})
					);
				}
				else {
					trucksarr = records
				}
				console.log(trucksarr, "trucksarr")
				var trucks = []
				if (trucksarr.length > 0) {
					trucksarr.map((item) => {
						trucks.push(item.truck_no);
					})
				}
				console.log(trucks, "trucks")
				this.setState({
					alltrucks: trucks
				})
			})
		redirectURL.post("/dashboard/tripsdealers")
			.then((response) => {
				// console.log("respone oem ", response.data)
				var dealers = response.data.records;
				// console.log("dealers ", dealers)
				let newArray = [];
				// Declare an empty object
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements
				for (let i in dealers) {

					// Extract the title
					totobjTitle = dealers[i]['city'];

					// Use the title as the index
					totuniqueObject[totobjTitle] = dealers[i];
				}

				// Loop to push unique object into array
				for (var a in totuniqueObject) {
					newArray.push(totuniqueObject[a]);
				}
				this.setState({
					cities: newArray
				})
			})
		// var newItem = 
		// {
		//     "truck_no":"",
		//     "truck_type":"",
		//     "transporter_code":"",
		//     "dept_code":""
		// };
		// console.log("newItem",newItem);
		// var res = this.gridApi.updateRowData({ add: [newItem] });
		this.setState({
			sliderRso: 'slider-translate',
			selectedRowNode: "",
			overly: 'show-m',
			plant: { "value": "", "label": "Plant Location" },
			dealer: { "value": "", "label": "Dealer" },
			oem: { "value": "", "label": "OEM" },
			driver: { "value": "", "label": "Driver" },
			truck_no: "",
			trip_no: "",
			vin_no: "",
			invoice_date: "",
			gate_in_time: "",
			gate_out_time: ""
		})
		$("#invoice_date").val("__-__-____ __:__");
		$("#gate_in_time").val("__-__-____ __:__");
		$("#gate_out_time").val("__-__-____ __:__");
		$("#invoice_date").val("");
		$("#gate_in_time").val("");
		$("#gate_out_time").val("");

	}
	onClickNewAddRow(event) {
		event.preventDefault();

		var invoice_date = $("#invoice_date").val();
		var gate_in_time = $("#gate_in_time").val();
		var gate_out_time = $("#gate_out_time").val();
		var eway_bill_date = $("#eway_bill_date").val();
		var quantity = $("#quantity").val();
		var fleet_amount = $("#fleet_amount").val();
		var distance_in_km = $("#distance_in_km").val();
		var expected_eta = $("#expected_eta").val();
		var truck_no = $("#inputTrucktrip").val();
		if (localStorage.getItem("is_hero") == 1 && localStorage.getItem("is_hero") != undefined) {
			var accessby = "HERO"
		}
		else {
			var accessby = "Maruti Suzuki"
		}
		if (this.state.oem.value != "" && this.state.plant.value != "" && this.state.city.value != ""
			&& invoice_date != "" && gate_out_time != ""
			&& invoice_date != "__-__-____ __:__" && gate_out_time != "__-__-____ __:__") {
			var params = {
				oem_code: this.state.oem.value,
				oem_name: this.state.oem.label,
				plant_location_code: this.state.plant.value,
				plant_location_name: this.state.plant.label,
				dealer_code: this.state.dealer.value,
				dealer_name: this.state.dealer.label,
				city: this.state.city.value,
				truck_no: truck_no,
				trip_no: this.state.trip_no,
				vin_no: this.state.vin_no,
				driver_name: this.state.driver.value,
				invoice_date: invoice_date,
				gate_in_time: gate_in_time,
				gate_out_time: gate_out_time,
				eway_bill_date: eway_bill_date,
				quantity: quantity,
				fleet_amount: fleet_amount,
				distance_in_km: distance_in_km,
				expected_eta: expected_eta,
				accessby: accessby,
				transporter_code: localStorage.getItem("transportercode")
			}
			if (this.state.selectedRowNode != "") {
				params.row_id = this.state.selectedRowNode._id
			}
			// console.log("params ", params)
			redirectURL.post("/dashboard/savetpttrips", params, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).then((response) => {
						//console.log("update response is : ",response.data);
						//console.log("Update Response ",response.data.result)
						if (response.data.status == "success") {
							this.setState({
								show: true,
								basicTitle: 'Successfully added data',
								basicType: "success",
								sliderRso: "",
								selectedRowNode: "",
								overly: "show-n",
								loadshow: "show-n"
							});
							// this.onLoadData();
							var consignData = {}
							var tptcode = localStorage.getItem("transportercode");
							if (localStorage.getItem("transportercode") != undefined) {
								var transporter = JSON.parse(localStorage.getItem("transportercode"))
							}
							else {
								var transporter = "";
							}
							if (Array.isArray(transporter)) {
								var transporter_code = transporter[0]
							}
							else {
								var transporter_code = transporter[0]
							}


							var urlpath = '/consignments/tptmsiltrips';
							if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
								var sDate = this.state.defaultsdate;
							}
							else {
								var sDate = this.state.startDate;
							}
							if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
								var eDate = this.state.defaultedate;
							}
							else {
								var eDate = this.state.endDate;
							}
							var consignData = {
								tpt_code: tptcode,
								transporter_code: this.state.localtransporter,
								startDate: sDate,
								endDate: eDate,
								consigner: this.state.consigner
							}

							this.onLoadGetConsignmentsList(urlpath, consignData);
						}
						else if (response.data.status == "gateout") {
							this.setState({
								show: true,
								basicTitle: 'Gate out time should be greater than gate in time and invoice date.',
								basicType: "danger",
							});
						}
						else {
							this.setState({
								show: true,
								basicTitle: 'Something went wrong',
								basicType: "danger",
							});
						}

					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}
		else {
			this.setState({
				show: true,
				basicTitle: 'All * fields should not be empty',
				basicType: "danger",
			});
		}

	}
	onChangeSourceItem(plant) {
		this.setState(
			{ plant },
			// () => console.log(`Option selected:`, this.state.plant)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeDealers(dealer) {
		this.setState(
			{ dealer },
			// () => console.log(`Option selected:`, this.state.dealer)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangeCity(city) {
		this.setState(
			{ city },
			// () => console.log(`Option selected:`, this.state.city)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeDriver(driver) {
		this.setState(
			{ driver },
			// () => console.log(`Option selected:`, this.state.driver)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangeOEM(oem) {
		this.setState(
			{ oem },
			() => {
				var transporter_code = localStorage.getItem("transportercode")
				var prms = {
					transporter_code: transporter_code,
					oem_code: this.state.oem.value
				}
				redirectURL.post("/dashboard/oemplantdealersdata", prms)
					.then((response) => {
						// console.log("respone oem ", response.data)
						var dealers = response.data.dealers;
						var plants = response.data.plants;
						this.setState({
							dealers: dealers,
							plantlocations: plants
						})
					})
			}
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	selectsources() {
		let items = [];
		try {
			if (this.state.plantlocations.length > 0) {
				this.state.plantlocations.map((item) => {
					items.push({ "value": item.plant_location_code, "label": item.plant_location_name })
				})
			}
		}
		catch (e) {

		}
		return items;
	}
	selectdealers() {
		let items = [];
		try {
			if (this.state.dealers.length > 0) {
				this.state.dealers.map((item) => {
					items.push({ "value": item.dealer_code, "label": item.dealer_name })
				})
			}
		}
		catch (e) {

		}
		return items;

	}
	selectcity() {
		let items = [];
		var citieslist = [];
		if (this.state.cities.length > 0) {
			citieslist = [...new Set(this.state.cities.map(obj => obj.city))];
		}
		try {
			if (citieslist.length > 0) {
				citieslist.map((item) => {
					if (item != '') {

						items.push({ "value": item, "label": item })

					}
				})
			}
		}
		catch (e) {

		}
		return items;
	}
	selectoem() {
		let items = [];
		try {
			if (this.state.oems.length > 0) {
				this.state.oems.map((item) => {
					items.push({ "value": item.oem_code, "label": item.oem_name })
				})
			}
		}
		catch (e) {

		}
		items.push({ value: "empty", label: "Empty" })
		return items;
	}

	selectdrivers() {
		let items = [];
		try {
			if (this.state.drivers.length > 0) {
				this.state.drivers.map((item) => {
					items.push({ "value": item.driver_name, "label": item.driver_name })
				})
			}
		}
		catch (e) {

		}
		return items;
	}

	onChangeTransitType(transittype) {
		this.setState(
			{ transittype },
			// () => console.log(`Option selected:`, this.state.transittype)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	initalListTrip = (event) => {
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.alltrucks;
		var tptunqrecords = dataset

		tptunqrecords = tptunqrecords.slice(0, 100);
		this.setState({
			filteredData: tptunqrecords,
			showTrucksList: "show-m",
		})
		console.log(tptunqrecords, "tptunqrecords")
	}
	handlerFormTrip = (event) => {
		//console.log("Select", event.target.value);
		// let name = event.target.name;
		// let value = event.target.value;
		// this.setState({[name]:value});
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.alltrucks
		var tptunqrecords = dataset

		var filteredResult = tptunqrecords.filter(function (e) {
			if (e != "" && e != null) {
				return e.toString().toLowerCase().includes($("#inputTrucktrip").val().toLocaleLowerCase());
			}

		});
		// console.log("filteredResult ", filteredResult)
		filteredResult = filteredResult.slice(0, 100);
		this.setState({
			filteredDataTrip: filteredResult,
			showTrucksListTrip: "show-m",
		})
	}
	formForceCloseHandler(event) {
		event.preventDefault()

		//  if(this.state.deptcode == 'SNDG')
		//  {
		// 	 var invoice_time = moment.parseZone(this.state.invoice_time).format("YYYY-MM-DD")+" "+this.state.invoicehh+":"+this.state.invoicemm+":"+this.state.invoicess;
		//  }
		//  else{
		// 	var invoice_time=""
		//  }
		var consignemnt = this.state.forceCloseRowNode.data;
		var consignment_code = consignemnt.trip_no;
		var consignee_code = consignemnt.consignee_code;

		var truck_no = consignemnt.truck_no;
		var dept_code = this.state.deptcode;
		var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var userid = localStorage.getItem('userid');
		var reasonforceclose = this.state.reasonforceclose;
		var others_Reason = this.state.selectedReason;
		//  var reached_dealer_time =  moment.parseZone(new Date(this.state.reached_dealer_time)).format("YYYY-MM-DD")+" "+this.state.reachedhh+":"+this.state.reachedmm+":"+this.state.reachedss;
		//  var left_dealer = moment.parseZone(new Date(this.state.left_dealer_time)).format("YYYY-MM-DD")+" "+this.state.lefthh+":"+this.state.leftmm+":"+this.state.leftss;

		var tpt_deliver_date = document.getElementById("tpt_deliver_date").value;
		if (tpt_deliver_date != '') {
			var rtime = tpt_deliver_date.split(" ");
			var splitrtime = rtime[0].split("-");
			if (splitrtime[0].length == 4) {
				var reachdealertime = splitrtime[0] + "-" + splitrtime[1] + "-" + splitrtime[2] + " " + rtime[1];

			}
			else {
				var reachdealertime = splitrtime[2] + "-" + splitrtime[1] + "-" + splitrtime[0] + " " + rtime[1];

			}
			//console.log("reached_dealer_time ", reachdealertime)
		}
		else {
			var reachdealertime = "";
		}
		// 	var left_dealer = document.getElementById("left_dealer_time").value;  
		// 	if(left_dealer != '')
		// 	{
		// 		var ltime = left_dealer.split(" ");
		// 		var splitltime = ltime[0].split("-");
		// 		var leftdealertime = splitltime[2]+"-"+splitltime[1]+"-"+splitltime[0]+" "+ltime[1]+":00";
		// 		//console.log("leftdealertime ", leftdealertime)
		// 	}   
		// 	else
		// 	{
		// 		var leftdealertime=""
		// 	}    

		if (this.state.deptcode == "SNDG") {
			//console.log(consignemnt.invoice_time);
			var invoice_time = getHyphenYYYYMMDDHHMMSS(consignemnt.invoice_time);
			var reqdata = {
				dept_code: dept_code,
				consignment_code: consignment_code,
				//consignee_code:consignee_code,
				truck_no: truck_no,
				//force_closure_time:force_closure_time,
				userid: userid,
				tpt_deliver_date: reachdealertime,
				reasonforceclose:reasonforceclose,
				others_Reason : others_Reason,
				screen: ""
				// reached_dealer_time:reachdealertime,
				//invoice_time:invoice_time,
				// left_dealer:leftdealertime
			}
		}
		else {
			var reqdata = {
				dept_code: dept_code,
				consignment_code: consignment_code,
				//consignee_code:"",
				truck_no: truck_no,
				//force_closure_time:force_closure_time,
				userid: userid,
				screen: "",
				reasonforceclose:reasonforceclose,
				others_Reason : others_Reason,
				tpt_deliver_date: reachdealertime,
				//invoice_time:"",
				// left_dealer:leftdealertime
			}
		}
		 var flag = 0;
		 if(others_Reason == "" && reasonforceclose == "Others"){
			 flag = 1;
		 }
		 if(flag == 0 && reasonforceclose == "Others")
		 {
		  //    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
			 if(others_Reason.replace(/\s/g,"").length == 0)
			 {
			  flag = 1;
			 }
		 }
		console.log("reqdata ",reqdata)
		if (reachdealertime != "" && reachdealertime != "__-__-____ __:__") {
			redirectURL.post("/consignments/otheroemtripclose", reqdata)
				.then((response) => {
					//console.log("Resposne updateConsignmentForceClose ", response)
					// console.log(response);
					if (response.data.message == 'Success') {
						// document.getElementById("reached_dealer_time").value='';
						// document.getElementById("left_dealer_time").value=''; 
						this.setState({
							show: true,
							basicTitle: "Success",
							basicType: "success",
							loadshow: 'show-n',
							overly: 'show-n',
							left_dealer_time: '',
							reached_dealer_time: '',
							reachedhh: '',
							reachedmm: '',
							reachedss: '',
							lefthh: '',
							leftmm: '',
							leftss: '',
							reasonforceclose: "",
							openforceclose: false,
							sliderForceCloseTranslate: '',
							sliderTranslatesidebar: "",
						});
						document.getElementById("tpt_deliver_date").value = "";
						window.location.reload();
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Error",
							basicType: "danger",
							loadshow: 'show-n',
							overly: 'show-n',
							left_dealer_time: '',
							sliderTranslatesidebar: "",
							reached_dealer_time: '',
							reachedhh: '',
							reachedmm: '',
							reachedss: '',
							lefthh: '',
							leftmm: '',
							leftss: '',
							reasonforceclose: '',
							openforceclose: false,
							sliderForceCloseTranslate: ''
						});
					}

				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: 'Reason for closue should not be empty',
				basicType: "danger",
			});
		}
		event.target.reset();
	}

	onClickBulkUpload = () => {
		this.setState({
			overly: 'show-m',
			sliderBulkForceClose: 'slider-translate-50p'
		})
	}
	onClickBulkUploadOEM = () => {
		this.setState({
			overly: 'show-m',
			sliderBulkUpload: 'slider-translate-50p'
		})
	}

    changeFileHandlerFc = async (e) => {
		const config = {
			headers: [
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'tpt_deliver_date',
					inputName: 'tpt_deliver_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},

			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData2) => {
				this.setState({
					csvcontentBulk: csvData2.data
				});
				console.log(csvData2.data, "csvData");
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)

				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			file1: e.target.files[0]
		});
	}
	changeFileHandlerOEM = async (e) => {
		const config = {
			headers: [
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'totaldays',
					inputName: 'totaldays',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'invoice_date',
					inputName: 'invoice_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'oem_name',
					inputName: 'oem_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'city',
					inputName: 'city',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'gate_in_time',
					inputName: 'gate_in_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'gate_out_time',
					inputName: 'gate_out_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'distance_in_km',
					inputName: 'distance_in_km',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'reached_dealer_reported',
					inputName: 'reached_dealer_reported',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'driver_name',
					inputName: 'driver_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'driver_mobile',
					inputName: 'driver_mobile',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},

			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData2) => {
				this.setState({
					csvcontentBulk: csvData2.data
				});
				console.log(csvData2.data, "csvData");
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)

				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			file1: e.target.files[0]
		});
	}
    onSubmitBulkUploadForceClosure = (e) => {
		e.preventDefault()
		var csvcontentBulk  = this.state.csvcontentBulk
		console.log("csvcontentBulk",csvcontentBulk)

		if (csvcontentBulk.length != 0) {
			redirectURL.post('/consignments/uploadotheroembulkforceclosetrips', csvcontentBulk).then(resp => {
				// console.log("respon", resp)
				if (resp.data.message == "success") {
					this.setState({
						show: true,
						basicType: "success",
						basicTitle: "Success",
						file1: "",

					})
                    window.location.reload();
				} else {
					this.setState({
						show: true,
						basicType: "warning",
						basicTitle: resp.data.message,
						file1: "",
					})
				}
			})
		}

	}
	onSubmitBulkUpload = (e) => {
		e.preventDefault()
		var csvcontentBulk  = this.state.csvcontentBulk
		console.log("csvcontentBulk",csvcontentBulk)
		var params={
			csvcontentBulk:csvcontentBulk,
			transporter_code:localStorage.getItem("transportercode"),
		}

		if (csvcontentBulk.length != 0) {
			redirectURL.post('/consignments/uploadotheroembulkupload', params).then(response => {
				// console.log("respon", resp)
				if (response.data.status == "success") {
					this.setState({
						show: true,
						basicTitle: 'Successfully added items',
						basicType: "success",
						loadshow: "show-n",
						overly: "show-n",
						sliderBulkUpload: "",
						file: "",

					});
					this.onLoadGetConsignmentsList()
				}else {
					this.setState({
						show: true,
						basicType: "warning",
						basicTitle: response.data.message,
						file1: "",
					})
				}
			})
		}

	}
	onClickCounterShowData(val) {
		// console.log("Val ", val)
		if (val == 0) {
			this.gridApi.setRowData(this.state.allRowData)
		}
		else {
			// console.log(this.state.counters_data, "msil")
			this.gridApi.setRowData(this.state.counters_data[val])
		}
	}

	onCloseSliderDiv = () => {
        this.setState({
            sliderReportTranslate: "",
            showDiv: "show-n",
            overly: "show-n",
            loadshow: "show-n",
        });
    };

	// onClickSaveGridState() {
	// 	if (googleAnalytics.page.enableGA) {
	// 		let pageTitle = this.state.pagetitle;
	// 		let eventOptions = {
	// 			"category": pageTitle,
	// 			"action": this.state.eventGridAction,
	// 			"label": "Save Grid Layout",
	// 		}
	// 		googleAnalytics.logEvent(eventOptions);
	// 	}
	// 	window.colState = this.gridColumnApi.getColumnState();
	// 	window.groupState = this.gridColumnApi.getColumnGroupState();
	// 	window.sortState = this.gridApi.getSortModel();
	// 	window.filterState = this.gridApi.getFilterModel();

	// 	let screenpage = 'MSIL Trips';



	// 	let reqparams = {
	// 		gridcolumns: window.colState,
	// 		gridgroup: window.groupState,
	// 		gridcolsort: window.sortState,
	// 		gridcolfilter: window.filterState,
	// 		userId: localStorage.getItem("userid"),
	// 		screenurl: window.location.pathname,
	// 		screentitle: screenpage
	// 	}
	// 	//console.log("reqparams ", reqparams)
	// 	redirectURL.post("/consignments/saveGridStates", reqparams)
	// 		.then((response) => {
	// 			//console.log("State response ",response.data)
	// 			this.setState({
	// 				show: true,
	// 				basicTitle: "Successfully saved grid layout",
	// 				basicType: "success",
	// 				screenurl: window.location.pathname,
	// 				screentitle: screenpage
	// 			})
	// 		})
	// 		.catch(function (e) {
	// 			console.log("Error ", e)
	// 		})
	// }
	onCellValueChanged = async (params) => {
		const updatedStatus = params.data.status;
		const formData = {
			id: params.data._id, // Assuming each row has a unique identifier
			status: JSON.stringify(updatedStatus)
		};
	
		// console.log(formData, "formData");
	
		try {
			const response = await redirectURL.post("/dashboard/editstatusforArlLogin", formData);
			// console.log(response.data, 'response data');
	
			if (response.data.message === "success") {
				this.setState({
					show: true,
					basicType: "success",
					basicTitle: "Status has been updated successfully",
				});
				// console.log("Status has been updated successfully");
			} else {
				console.log("Failed to update status");
			}
		} catch (error) {
			console.error("Error updating status:", error);
		}
	}
	

	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		const { open,tcode } = this.state;
		const { openforceclose } = this.state;
		var maptransporter='';
		try{
			if(this.state.rownode.transporter)
			{
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter=this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch(e){
			console.log(e)
		}
		var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
		var listDatatrip = []
		this.state.filteredDataTrip.map(function (e) {
			listDatatrip.push(<li className="tptdropdown-truck-no">{e}</li>)
		})
		var tStatusShowOrHide = true
		if (tcode == "T329") {
			tStatusShowOrHide = false
		}
		var editforARL = false
		if(localStorage.getItem("email").includes("nitish.baweja@arlpl.com") || localStorage.getItem("transportercode").includes("T329")){
			// console.log("Print it is working")
			var editforARL = true
		}
		const columnwithDefs = 
		[
            // {
			// 	headerName: "Actions",
			// 	field: "_id",
			// 	colId: "_id",
			// 	width: 40,
			// 	pinned: 'left',
			// 	filter: false,resizable: true,
			// 	headerCheckboxSelection: true,
			// 	//headerCheckboxSelectionFilteredOnly: true,
			// 	checkboxSelection: true
				
			// },    
			{
                headerName: tkName,
                field: "truck_no",
                width: 140,
                // pinned : "left",
                filter: "agSetColumnFilter",
                resizable: true
            },

            {
                headerName: "Consignment Code",
                field: "trip_no",
                //colId : "consignment_code",
                width: 120,
                // pinned: 'left',
                editable: false,
                filter: "agSetColumnFilter", resizable: true,
                //cellRenderer : "agGroupCellRenderer"
            },
			{
				headerName: "Status",
				field: "status",
				width: 120,
				filter: "agSetColumnFilter",
				resizable: true,
				editable: function (params) {
					const transporterCode = localStorage.getItem("transportercode");
					return ["T329", "T290", "GARL"].some(code => transporterCode.includes(code));
				},
				cellEditor: 'agSelectCellEditor',
				cellEditorParams: {
					values: [
						"Inside Plant",
						"In Transit",
						"Near Destination",
						"Reached Destination",
						"Left Destination",
						"Force Closed"
					],
				},
				valueGetter: function (params) {
					const statusMap = {
						1: "Inside Plant",
						2: "In Transit",
						3: "Near Destination",
						4: "Reached Destination",
						5: "Left Destination",
						6: "Force Closed"
					};
					return statusMap[params.data.status] || "";
				},
				valueSetter: function (params) {
					const statusMapReverse = {
						"Inside Plant": 1,
						"In Transit": 2,
						"Near Destination": 3,
						"Reached Destination": 4,
						"Left Destination": 5,
						"Force Closed": 6
					};
					params.data.status = statusMapReverse[params.newValue];
					return true;
				},
				onCellValueChanged:this.onCellValueChanged,
				
			}
			,
			{
				headerName: "Original ETA",
				field: "expected_trip_end",
				id: "expected_trip_end",
				width: 160,
				//pinned: 'left',              
				resizable: true,
				valueGetter: function (params) {
					if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null) {
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
					}
					else {
						return "";
					}


				},
				comparator: dateComparator,

			},
			{
				headerName: "Revised ETA",
				field: "revised_trip_end",
				width: 160,
				//pinned: 'left',              
				resizable: true,
				// cellClass : "dateFormat",
				valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
					}
					else {
						return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
					}

				},
				comparator: dateComparator,

				cellClass: function (params) {
					let hourDifference = 0;
					if (params.data.revised_trip_end && params.data.expected_trip_end) {
						let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
						let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
						hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}

					if (hourDifference >= 1) {
						return 'bgColorDangerMedium'
					}

					if (hourDifference <= -1) {
						return 'bgColorSuccessMedium'
					}
					else {
						return ''
					}
				}
			},
			// {
			// 	headerName: "TPT Delivery Date",
			// 	field: "tpt_delivery_date",
			// 	width: 110,
            //     editable: true,
            //     cellEditor: DateEditor,
			// 	valueGetter: function(params){
			// 		if(params.data.tpt_delivery_date !=undefined && params.data.tpt_delivery_date !="")
			// 		{
			// 			return getHyphenDDMMYYYY(params.data.tpt_delivery_date);
			// 		}
			// 	}
			// 	// valueSetter:(params)=>{
			// 	// 	console.log(params)
			// 	// 		if(params.oldValue != params.newValue)
			// 	// 		{
			// 	// 			var data = {
			// 	// 				truck_no:params.data.truck_no,
			// 	// 				tpt_delivery_date:params.newValue,
			// 	// 			}
			// 	// 			params.data.tpt_delivery_date = params.newValue;
			// 	// 			redirectURL.post("/consignments/forceclosetrucks", data,{
			// 	// 			headers:{
			// 	// 				'Content-Type': 'application/json'
			// 	// 				}
			// 	// 				}).then((response)=>{
			// 	// 			})
			// 	// 		}
			// 	// }
			// },
            {
                headerName: "Transit Delay(Days)",
                field: "totaldays",
                width: 200,
                filter: "agSetColumnFilter",
                cellRenderer: '', resizable: true,
                valueGetter: function (params) {
					
					
						var expected = params.data.expected_trip_end;
					if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
						var revised = params.data.expected_trip_end;
					}
					else {
						var revised = params.data.revised_trip_end;
					}
					var s = moment.parseZone(expected).format("x");
					var ed = moment.parseZone(revised).format("x");
					var diff = (ed - s)
					var days = secondsToDays(diff)
					var totaldays = Math.round(days / 1000);
					if (totaldays > 0) {
						return totaldays;
					}
					else {
						return 0;
					}
                }
            },
			{
				headerName: "Idle Time Remarks",
				field: "idle_time_remarks",
				width: 110,
				editable:true,
				hide: (localStorage.getItem("transportercode").includes("T305")|| localStorage.getItem("transportercode").includes("T329"))?false:true,	
				valueSetter:(params)=>{
				console.log(params)
					if(params.oldValue != params.newValue)
					{
						var data = {
							truck_no:params.data.truck_no,
							idle_time_remarks:params.newValue,
						}
						params.data.idle_time_remarks = params.newValue;
						redirectURL.post("/dashboard/updateidletimeremarks", data,{
						headers:{
							'Content-Type': 'application/json'
							}
							}).then((response)=>{
						})
					}
				}
	
	
			},
            {
                headerName: "Invoice Date",
                field: "invoice_date",
                width: 150,
                //filter: "agDateColumnFilter",
                cellRenderer: '', resizable: true,
                comparator: dateComparator,

                valueGetter: function (params) {
                    if (params.data.invoice_date != '' && params.data.invoice_date != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                    }
                    else {
                        return "NA";
                    }
                },
            },
			{
				headerName: "Address",
				field: "address",
				width: 200,
				valueGetter: function (params) {
					return params.data.address;
				}
				// colId:"device_address",
			},
			{
				headerName: "Travel Status",
				field: "elaped_time_from_last_moved_seconds",
				width: 100,
				hide: tStatusShowOrHide,
				// colId:"elaped_time_from_last_moved_seconds",
				valueGetter: function (params) {
					try {
						// console.log("params.data.travel_sttaus ", params.data.travel_sttaus)
						// if(params.data.travel_sttaus != "" && params.data.travel_sttaus != undefined){
						// 	return "Inactive";
						// }else{							
						if (params.data.last_known_speed > 0) {
							return "Travelling";
						}
						else {
							var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
							return "Idle (" + mre + ")";
						}
						// }
					}
					catch (e) {
						return "";
					}
				},
				cellClass: function (params) {
					try {
						if (params.data.travel_sttaus == "Inactive") {
							return ["maroon", "fbold"];
						}
						else {
							if (params.data.last_known_speed > 0) {
								return ["greenfont", "fbold"];
							}
							else {
								return ["redfont", "fbold"];
							}
						}

					}
					catch (e) {
						return "";
					}
				}
			},
			{
				headerName: "Last Packet Time",
				field: "last_active_on",
				width: 130,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.last_active_on)
				},

				//filter: "agDateColumnFilter",
				// cellRenderer:'',resizable: true	          
			},
            {
                headerName: "Consigner Name",
                field: "oem_name",
                width: 150,
                filter: "agSetColumnFilter",
				resizable: true,
				valueGetter : param =>{
					if(param.data.consigner_name !== '' && param.data.consigner_name !== undefined){
						return param.data.consigner_name
					}else{
						return param.data.oem_name
						
					}
				}

            },
            // {
            //     headerName: "Consignee Code",
            //     field: "dealer_code",
            //     width: 150,
            //     filter: "agSetColumnFilter",
            //     cellRenderer: '', resizable: true
            // },
            // {
            //     headerName: "Consignee Name",
            //     field: "dealer_name",
            //     width: 150,
            //     filter: "agSetColumnFilter",
            //     cellRenderer: '', resizable: true
            // },
            {
                headerName: "Consignee City",
                field: "city",
                width: 150,
                filter: "agSetColumnFilter",
                resizable: true
            },


            {
                headerName: "Gate in Time",
                field: "gate_in_time",
                width: 180,
                //filter: "agDateColumnFilter",
                //cellRenderer:'dateFormater',
                comparator: dateComparator,

                valueGetter: function (params) {
                    if (params.data.gate_in_time != "2000-01-01T00:00:00.000Z" && params.data.gate_in_time != "1970-01-01T00:00:00.000Z" && params.data.gate_in_time != "") {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                    }
                    else {
                        return "";
                    }

                },
                resizable: true
            },
            {
                headerName: "Gate out Time",
                field: "gate_out_time",
                width: 180,
                //filter: "agDateColumnFilter",
                //cellRenderer:'dateFormater',
                // sortable:true,
                comparator: dateComparator,

                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                },
                resizable: true
            },
            {
                headerName: "Expected Distance (KM)",
                field: "distance_in_km",
                width: 150,
                filter: "agSetColumnFilter",
                resizable: true,
				editable:editforARL,
				valueSetter:(param)=>{
					// console.log("test",param.data)
					if(param.oldValue != param.newValue){
						var formData ={
							"distance_in_km":param.newValue,
							"trip_no":param.data.trip_no,
						}
						this.setState({
							overly:"show-m",
							loadshow:"show-m"
						})
						redirectURL.post("/dashboard/editexpecteddistanceforARL",formData).then((response)=>{
							if(response.data.message == "Expected Trip End Updated !!"){
								this.setState({
									basicTitle:response.data.message,
									basicType:"success",
									show:true,
									overly:"show-n",
									loadshow:"show-n"
								})
								this.onLoadGetConsignmentsList()
							}else{
								this.setState({
									basicTitle:response.data.message,
									basicType:"warning",
									show:true,
									overly:"show-n",
									loadshow:"show-n"
								})
							}
						})
					}else{
						this.setState({
							basicTitle:"Please Enter New Value",
							basicType:"warning",
							show:true
						})
					}
				}
            },
			{
				headerName: "Reached Time",
				field: "reached_dealer_reported",
				width: 120,
				filter:true,
				resizable: true,
				valueGetter: function (params) {
                    if (params.data.reached_dealer_reported != '' && params.data.reached_dealer_reported != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.reached_dealer_reported);
                    }
                    else {
                        return "NA";
                    }
                },


			},
			{
				headerName: "Driver Name",
				field: "driver_name",
				width: 120,
				filter:true,
				resizable: true,


			},
			{
				headerName: "Driver Mobile No",
				field: "driver_mobile",
				width: 140,
				filter:true,
				resizable: true,


			},
            {
				headerName: "Trip Close",
				field: "",
				width: 80,
				cellRendererSelector: function (params) {
					var rendComponent = {
						component: "consignmentforceclose"
					};
					return rendComponent;
				},
				resizable: true,


			},  
			
		];
		
		// }
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	}
		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				
				 <div className="row">
					<div className="card-body pb-0">
						<div className="row">
							<div className="col-2">
								<label>From Invoice Date </label>
								<Datetime value={this.state.from_date}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{ placeholder: 'From Date', name: 'startDate', autoComplete: 'off', readOnly: true }}
									dateFormat="YYYY-MM-DD"
									timeFormat={false}
									name="fromDate"
									onChange={this.handlerStartDateTime} />
							</div>
							<div className="col-2">
								<label>To Invoice Date </label>
								<Datetime
									value={this.state.to_date}
									disableCloseOnClickOutside={false}
									closeOnSelect={true}
									inputProps={{ placeholder: 'To Date', name: 'toDate', autoComplete: 'off', readOnly: true }}
									dateFormat="YYYY-MM-DD"
									timeFormat={false}
									name="toDate"
									onChange={this.handlerEndDateTime} />
							</div>

							<div className="form-group col-xl-3 col-lg-3"   >
								<button className="btn btn-success mt-30p" type="submit" onClick={this.submitOEMFilterData}>Submit</button>
							</div>
						</div>
					</div>
				 {(this.state.msilCounters != undefined) ?
						<TptMSILTripsCounters
							context={this}
							total_rows={this.state.allRowData}
							counters={this.state.counters_data}
							onClickCounterShowData={this.onClickCounterShowData}
						/>
						: ""}
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
									
									<button className="float-right btn custom-btn white btn-danger"
											style={{ marginRight: "10px", fontSize: "14px", marginLeft : '2px' }} onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
									</button>
									<button style={{ marginRight: "10px", fontSize: "14px", marginLeft : '2px' }} className="float-right btn custom-btn white btn-info"  onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
									</button>
									<button type="button" onClick={this.onAddRow.bind(this)} style={{ marginRight: "10px", fontSize: "14px", marginLeft : '2px' }} className="btn btn-warning float-right">Add New Trip</button>
                                    <button type="button" onClick={this.onClickBulkUpload} style={{ marginRight: "10px", fontSize: "14px", marginLeft : '2px' }} className="btn btn-info float-right">Bulk Force Closure</button>
                                    <button type="button" onClick={this.onClickBulkUploadOEM} style={{ marginRight: "10px", fontSize: "14px", marginLeft : '2px' }} className="btn btn-primary float-right">Bulk Upload</button>
									
									{/* <button type="button" onClick={this.onClickBulkClose} style={{ marginRight: "10px", fontSize: "14px", marginLeft : '2px' }} className="btn btn-danger float-right">Trip Close Submit</button> */}
									
                                    {/* <button type="button" onClick={this.onClickBulkUpload} style={{ marginRight: "10px", fontSize: "14px", marginLeft : '2px' }} className="btn btn-danger float-right">Historic Trip Data</button> */}
									{/* <span className="layoutbtns pull-right">
										<button className="float-right custom-btn white btn-danger"
                                         style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px",cursor: "pointer"}} onClick={this.onClickMarkTafe.bind(this)}>
											{this.state.markbtn}
										</button>
                                    </span> */}
									{/* <span className="layoutbtns pull-right">
                                         <button className="float-right custom-btn white btn-danger"
                                         style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                             <i className="icofont icofont-save"></i> Save Grid Layout
                                         </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.resetState.bind(this)}>
                                             <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span> */}
									{/* {(this.state.pdfRowData.length > 0) ?
										<ExportPDF  rowData={this.state.pdfRowData} pdfColumns={this.state.pdfColumns} 
										addressColumn={this.state.addressColumn}/>  
										: "" } */}
								</h5>
				   			</div>				   			
		            		<div className="card-body pt-10px" >
								
                                
                                
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									//floatingFilter={true}
									
									enableCellChangeFlash={false}
									//suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
												    
									// rowSelection={this.state.rowSelection}
									// onRowSelected={this.onRowSelection.bind(this)}
									suppressRowClickSelection={true}
    								// suppressCellSelection= {true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									excelStyles= {this.state.excelStyles}
									onCellClicked={this.onCellClicked}
									onCellEditingStopped={this.onRowSelection.bind(this)}
		          		          />
									
									{/* 
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				
				
                
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap context={this} rownode={this.state.rownode} mapFor={"consignment"} tolls={this.state.tolls} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} adaniCheck={this.state.adaniCheck}/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">{(this.state.adaniCheck == 1) ? "Shuttle"  : "Truck"} No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.mapinfo.route_details.start_time != '')?getHyphenDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.mapinfo.route_details.end_time != '')?getHyphenDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance/1000)} Kms</div>
											</div>
											
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
				<div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Add New Trip
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select OEM *</label>
									<Select
										placeholder={"Select OEM"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeOEM.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										value={this.state.oem}
										options={this.selectoem()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Plant Location *</label>
									<Select
										placeholder={"Select Plant Location"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeSourceItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										value={this.state.plant}
										options={this.selectsources()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Dealer </label>
									<Select
										placeholder={"Select Dealer"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeDealers.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										value={this.state.dealer}
										options={this.selectdealers()} />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select city * </label>
									<Select
										placeholder={"Select City"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeCity.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										value={this.state.city}
										options={this.selectcity()} />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<div className={"trucks-dropdown " + this.state.showTrucksListTrip} style={{ top: "65px" }}>
										<ul>
											{listDatatrip}
										</ul>
									</div>
									<label className="col-xl-12 col-lg-12">Truck No *</label>
									{/*<input 
									type="text" 
									name="truck_no"
									value={this.state.truck_no}
									className="form-control" 
                                    // onChange={this.changeNumberAccept.bind(this)}
                                    onChange = {this.changeHandler.bind(this)}
                                    // onKeyPress={onlyNumberKey(this.value)}
                                    /> 
                                    */}
									<input
										type="text"
										name="truck_no"
										id="inputTrucktrip"
										placeholder={(this.state.adaniCheck == 1) ? "Shuttle" : "Truck No"}
										autoComplete="off"
										style={{ marginLeft: "15px" }}
										className="form-control"
										onFocus={this.initalListTrip.bind(this)}
										onChange={this.handlerFormTrip.bind(this)} />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Trip No *</label>
									<input
										type="text"
										name="trip_no"
										value={this.state.trip_no}
										className="form-control"
										onChange={this.changeHandler.bind(this)} />


								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Vin No *</label>
								<input 
									type="text" 
									name="vin_no"
									value={this.state.vin_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Driver *</label>
									<Select
										placeholder={"Select Driver"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeDriver.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										options={this.selectdrivers()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Quantity</label>
									<input
										type="text"
										name="quantity"
										id="quantity"
										value={this.state.quantity}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Freight Amount</label>
									<input
										type="text"
										name="fleet_amount"
										id="fleet_amount"
										value={this.state.fleet_amount}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Expected Distance (KM)</label>
									<input
										type="text"
										name="distance_in_km"
										id="distance_in_km"
										value={this.state.distance_in_km}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Expected ETA</label>
									<input
										type="text"
										name="expected_eta"
										id="expected_eta"
										value={this.state.expected_eta}
										autoComplete="off"
										className="expected_eta datetimepicker_mask form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Invoice Date *</label>
									<input
										type="text"
										name="invoice_date"
										id="invoice_date"
										value={this.state.invoice_date}
										className="invoice_date datetimepicker_mask form-control"
										autoComplete={"off"}
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Gate In Time</label>
									<input
										type="text"
										name="gate_in_time"
										id="gate_in_time"
										value={this.state.gate_in_time}
										autoComplete={"off"}
										className="gate_in_time datetimepicker_mask form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Gate Out Time *</label>
									<input
										type="text"
										name="gate_out_time"
										id="gate_out_time"
										value={this.state.gate_out_time}
										autoComplete={"off"}
										className="gate_out_time datetimepicker_mask form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Eway Bill date</label>
									<input
										type="text"
										name="eway_bill_date"
										id="eway_bill_date"
										value={this.state.selected_eway_bill_date}
										autoComplete={"off"}
										className="gate_out_time datetimepicker_mask form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>


						</div>
					</div>
				</div>
				<div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
					<h3 className="subH">{this.state.markbtn}</h3>
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadTafeTrip}>
							
								<div className="form-group">
									<label className="">Truck</label>
									<Select 
									placeholder={"Select Truck"}
									closeMenuOnSelect={true}
									onChange={this.onChangeTruckItem.bind(this)} 
									className={"border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.selectedTruck}
									options={this.state.transporterTrucks} />  
								</div>
								
								<div className="form-group mt-20p">
									<label>Trip Start Date</label>
									<input type="text" placeholder="" name="trip_start_date" id="trip_start_date" className="datetimepicker_mask form-control" required />  
								</div>
								
								<div className="form-group mt-20p">
									<label>Trip No's</label>
									<input type="text" name="trip_no" id="trip_no" placeholder="" className="form-control" required />
									<p style={{color:"red"}}>*Enter multiple trip numbers with comma seperated</p>
								</div>
								
								<div className="form-group mt-20p">
									<label>Dealer Code</label>
									<input type="text" name="dealer_code" id="dealer_code" placeholder="" className="form-control" required />
								</div>
								
								<div className="form-group mt-20p">
									<label>Dealer Name</label>
									<input type="text" name="dealer_name" id="dealer_name" placeholder="" className="form-control" required />
								</div>

								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>                   
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderForceCloseTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Trip Closure
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>

							<div className="col-xl-12 col-lg-12 row form-group" style={{marginLeft:"1em"}}>
									<label className="col-xl-12 col-lg-12">Delivery Date:</label>
									<input
										type="text"
										name="tpt_deliver_date"
										id="tpt_deliver_date"
										autoComplete={"off"}
										className="tpt_deliver_date datetimepicker_mask form-control" />
								</div>
																
								<ForceCloseSideBar context={this} handleInput = {this.handleInput} deptcode={this.state.deptcode}/>

								{/* <div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div> */}
							</form>
						</div>
					</div>
				</div>

				{/* slider div */}
				{/* <div
					className={"slide-r " + this.state.sliderReportTranslate}
					style={{ overflow: "auto" }}
				>
					<div className="slide-r-title">
						<h4>
							{this.state.sliderTitle}
							<span
								className="float-right closebtn"
								style={{ marginRight: "25px" }}
								onClick={this.onCloseSliderDiv}
							>
								X
							</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
							<div
								id="myGrid"
								style={{
									height: "600px",
									width: "100%"
								}}
								className="ag-theme-balham"
							>
								<AgGridReact
									modules={this.state.modules}
									columnDefs={this.state.sliderColumns1}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.reportRowData}
									paginationPageSize={this.state.paginationPageSize}
									detailCellRendererParams={this.state.detailCellRendererParams}									
									// onCellClicked={this.getZoneLegData}
									// rowHeight={this.state.rowHeight}
									// onGridReady={this.onGridReady}
									// onGridState={this.onGridState}
									pagination={true}
									masterDetail={true}
									enableCharts={true}
									enableRangeSelection={true}
									gridOptions={{
										context: { componentParent: this }
									}}
									suppressRowClickSelection={true}
								/>
							</div>
						</div>
					</div>
				</div> */}
				{/* slider div end */}

                <div className={"slide-r " + (this.state.sliderBulkForceClose)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Bulk Upload Force Close
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUploadForceClosure}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Upload File *</label>
									<input
										type="file"
										name="uploadfile"
										className="form-control"
										onChange={this.changeFileHandlerFc} />
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
								{/* <div className="col-xl-12 col-lg-12 form-group">
								Date format should be in yyyy:mm:dd hh:mm:ss
							</div> */}
								<div className="col-xl-12 col-lg-12 form-group">
									<a href={require("../../assets/json/bulk_force_close_sample_file.csv")} target="_blank" className="btn btn-warning">Download Template</a>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderBulkUpload)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Bulk Upload
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Upload File *</label>
									<input
										type="file"
										name="uploadfile"
										className="form-control"
										onChange={this.changeFileHandlerOEM} />
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
								{/* <div className="col-xl-12 col-lg-12 form-group">
								Date format should be in yyyy:mm:dd hh:mm:ss
							</div> */}
								<div className="col-xl-12 col-lg-12 form-group">
									<a href={require("../../assets/json/sample_template_OEM_bulk_upload.csv")} target="_blank" className="btn btn-warning">Download Template</a>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>              	
		);
	}
}



function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
	

	return diffHrs + " hour(s) and " + (diffMins/60) + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function onSetCritical(params, status){
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status,
		token:localStorage.getItem("token"),
		userId:localStorage.getItem("userid")
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}

function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag(){
	var rs = document.getElementById("invalid_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		onShow:false
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y H:i',
		timepicker:true,
		onShow:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToHM(seconds) {
	seconds = Number(seconds);
	// var d = Math.floor(seconds / (3600*24));
	// var h = Math.floor(seconds % (3600*24) / 3600);
	// var m = Math.floor(seconds % 3600 / 60);
	// var s = Math.floor(seconds % 60);

	// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	// return dDisplay + hDisplay + mDisplay;

	var hh = Math.floor((seconds / 3600));
	var hrr = Math.floor(seconds - (hh * 3600));
	var mm = Math.floor(hrr / 60)
	if (mm < 10) {
		var mins = "0" + mm;
	}
	else {
		var mins = mm;
	}

	if (hh < 10) {
		var hrs = "0" + hh;
	}
	else {
		var hrs = hh;
	}
	return hrs + ":" + mins;
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
		timepicker:false,
        dateFormat: "dd-mm-yy",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map11;
}