import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import CountUp from 'react-countup';
import ChallansColumnChart from './challansColumnChart'

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
import CreatableSelect from 'react-select/creatable';
//import { getConsignmentsNoGpsLifeTimeCounter } from '../../../../tpt_node/models/consignmentModel';

var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment')
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
var monthName = {
    1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun",
    7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec"
}

var monthNumbers = {
    "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04", "May": "05", "Jun": "06",
    "Jul": "07", "Aug": "08", "Sep": "09", "Oct": "10", "Nov": "11", "Dec": "12"
}
export default class TptVicinityScreen extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Challan Manual Transactions",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-m',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },

			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,

			plant_code:'',
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
            fuel_station_options:[],
            fuel_station_data:[],
            fuelstagingtrucks:[],
            markersList:[],
            fasttagtrucks:[],
            fasttagtnx:[],
            VehicleNumber: [],
            btnClicked:[],
            CellRendererFunc:[],
            detailCellRendererParams:{},
            fasttagtruckstnx:[],
            fast_tag_transc:[],
            TransactionStatus:"All",
            fast_tag_screen:[],
            fasttagtruckstnxcopy:[],
            totalTransactions:0,
            transactionAmount:0,
            monthYearOptions: [],
            selectedMonthYear: [{"label": "All", value: "All"}],
            seriesData: [],
            categories: [],
            yearOptions: [],
            selectedYear: {"label": "All", value: "All"},
            uniquedatetime:[],
            plazaSeriesData : [],
            plazaCategories : [],
            startDate:"",
            endDate:"",
            summary:"show-m",
            detailed:"show-n",
            summarycl:"btn-danger",
            detailedcl:"",
			totalwallet:0,
            challan_no:"",
            challan_date:"",
            amount_in_inr:"",
            dl_number:"",
            engine_no:"",
            payment_source:"",
            challan_state:"",
            challan_status:"",
            transaction_id:"",
            violater_name:"",
            truck_no:"",
            vehicle_truck_no:[],
            selectedStatus:[],
            vehicle_Number:[],
            VehicleValueNumber:[],
            stateValueCode:[],
            paymentValueSource:[],
            isnewtruck : false,
		};
		//this.popmarker=this.popmarker.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        loadDateTimeScript();
            if(localStorage.getItem("transportercode") != undefined)
            {
                var transporter = JSON.parse(localStorage.getItem("transportercode"))
            }
            else{
                var transporter = "";
            }
            if(Array.isArray(transporter))
            {
                var transporter_code = transporter[0]
            }
            else{
                var transporter_code = transporter
            }
            var params = {
                // "selected_months": [month+"-"+year],
                // "startdate":sdate,
                // "enddate":edate,
                // "status": "All",
                transporter_code:transporter_code
            }
        redirectURL.post("/consignments/challanData",params).then((response)=>{
            var vehchallan = response.data.vehchallan
            var uniquechallanpayment = response.data.uniquechallanpayment
            var uniquechallanstate = response.data.uniquechallanstate
            var uniquechallanstatus = response.data.uniquechallanstatus
            // console.log(response.data.vehchallan,"vehicle challan")
            // console.log(response.data.uniquechallanpayment,"vehicle payment")
            // console.log(response.data.uniquechallanstate,"vehicle state")
            
            var VehicleNumber =[{"label":"","value":""}];

            if(vehchallan !=null && vehchallan !=undefined)
            {
                vehchallan.map((e)=>{
                
                    VehicleNumber.push({"label":e,"value":e})
                })
            }
        var paymentSource = [{"label":"","value":""}];    
            if(uniquechallanpayment!=null && uniquechallanpayment != undefined)
            {
                uniquechallanpayment.map((e)=>{
                
                    paymentSource.push({"label":e,"value":e})
                })
            }

        // var paymentStatus = [{ "label": "", "value": "" }];
        var paymentStatus = []
            if (uniquechallanstatus != null && uniquechallanstatus != undefined) {
                uniquechallanstatus.map((e) => {
                    paymentStatus.push({ "label": e, "value": e })
                })
            }
        var stateCode = [{"label":"","value":""}];    
            if(uniquechallanstate !=null && uniquechallanstate != undefined)
            {
                uniquechallanstate.map((e)=>{
                
                    stateCode.push({"label":e,"value":e})
                })
            }
            this.setState({
                VehicleNumber:VehicleNumber,
                paymentSource:paymentSource,
                paymentStatus : paymentStatus,
                stateCode:stateCode,
            })
        })
       
    }
    onClickNewAddRow(event)
	{
        event.preventDefault();
		
        var challan_no = $("#challan_no").val();
        var challan_date= $("#challan_date").val();
        var amount_in_inr = $("#amount_in_inr").val();
        var VehicleValueNumber = this.state.VehicleValueNumber.value;
		var dl_number = $("#dl_number").val();
		var engine_no = $("#engine_no").val();
        var paymentValueSource = this.state.paymentValueSource.value
        var stateValueCode = this.state.stateValueCode.value
        var selectedStatus = this.state.selectedStatus.value
        var transaction_id = $("#transaction_id").val();
        var violater_name = $("#violater_name").val();
		if(localStorage.getItem("is_hero") == 1 && localStorage.getItem("is_hero") != undefined)
		{
			var accessby = "HERO"
		}
		else{
			var accessby = "Maruti Suzuki"
		}
        
        // console.log(params,"params")
       if(challan_no !="" && challan_no != undefined && challan_date !="" && challan_date != undefined && amount_in_inr !="" && amount_in_inr != undefined
        && engine_no !="" && engine_no != undefined && 
        VehicleValueNumber !="" && VehicleValueNumber != undefined && stateValueCode != ""&& stateValueCode != undefined && selectedStatus != ""&& selectedStatus != undefined
        && transaction_id != "" && transaction_id != undefined && violater_name != ""&& violater_name != undefined)
        {
            if(challan_no.toString().length >= 4 && challan_no.toString().length <= 30 && VehicleValueNumber.toString().length >= 4 && VehicleValueNumber.toString().length <= 30
               && dl_number.toString().length >= 4 && dl_number.toString().length <= 30 && engine_no.toString().length >= 4 && engine_no.toString().length <= 30
               && transaction_id.toString().length >= 4 && transaction_id.toString().length <= 30 && violater_name.toString().length >= 4 && violater_name.toString().length <= 30)
            {
                var params = {
                    challan_no:challan_no,
                    challan_date:challan_date,
                    amount_in_inr:amount_in_inr,
                    dl_number:dl_number,
                    engine_no:engine_no,
                    payment_source:paymentValueSource,
                    transaction_id:transaction_id,
                    truck_no:VehicleValueNumber,
                    state:stateValueCode,
                    status:selectedStatus,
                    violater_name:violater_name,
                    transporter_code:localStorage.getItem("transportercode"),
                    isnewtruck : this.state.isnewtruck
                }
                
                redirectURL.post("/dashboard/savechallanmanualtrips", params,{
                    headers:{
                        'Content-Type': 'application/json'
                    }
                }).
                then(
                    (response)=>{
                        //console.log("update response is : ",response.data);
                        //console.log("Update Response ",response.data.result)
                        if(response.data.status == "success")
                        {
                            this.setState({
                                show: true,
                                basicTitle:'Successfully added data',
                                basicType:"success",
                                sliderRso:"",
                                selectedRowNode: "",
                                challan_no:"",
                                amount_in_inr:"",
                                dl_number:"",
                                engine_no:"",
                                paymentValueSource:"",
                                transaction_id:"",
                                VehicleValueNumber:"",
                                stateValueCode:"",
                                selectedStatus:"",
                                violater_name:"",
                                challan_date:"",
                                isnewtruck : false,
                            }); 
                        }
                        else{
                            this.setState({
                                show: true,
                                basicTitle:'Something went wrong',
                                basicType:"danger",
                                VehicleValueNumber: "",
                                isnewtruck : false,

                            });
                        }
                        
                    }
                )
                .catch(function(error){
                    console.log(error);
                });
            }
            else{
                this.setState({
                    show: true,
                    basicTitle:'Enter min 4 and max 30 Characters',
                    basicType:"warning",
                });
            }
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }

    }

    formHandler = async (event) =>{
		event.preventDefault();
        // console.log(this.state.VehicleNumber, this.state.selectedStatus,"data fasttagscreen");
        
        var selectedMonthYear = [];
        if(this.state.selectedMonthYear != null && this.state.selectedMonthYear != "")
        {
            // console.log(this.state.selectedMonthYear,"selectedMonthYear")
            this.state.selectedMonthYear.map((e)=>{
                if(e.value == "All")
                {
                    selectedMonthYear.push(e.value)
                }
                else
                {
                    var monthNo = monthNumbers[e.value.split("-")[0]]
                    selectedMonthYear.push(monthNo+"-"+e.value.split("-")[1])
                }
            })
        }
        else{
            selectedMonthYear.push("All")
        }

        var vehicleNumber = [];
        if(this.state.vehicle_Number!=null && this.state.vehicle_Number != "")
        {
            this.state.vehicle_Number.map((e)=>{
               
                vehicleNumber.push(e.value)
            })
        }
		else if(vehicleNumber.length==0)
        {
            vehicleNumber.push("All")
        }
        if(this.state.startDate != "" && this.state.startDate !="NaN-NaN-NaN" && this.state.endDate != "" && this.state.endDate !="NaN-NaN-NaN")
        {
            var sdate = this.state.startDate;
            var edate = this.state.endDate;
        }
        else
        {
            var sdate = "";
            var edate = "";
        }
        if(localStorage.getItem("transportercode") != undefined)
        {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else{
            var transporter = "";
        }
        if(Array.isArray(transporter))
        {
        var transporter_code = transporter[0]
        }
        else{
            var transporter_code = transporter[0]
        }
        var params = {
            truck_no: vehicleNumber,
            startdate:sdate,
            enddate:edate,
            // manual_entry:1,
            transporter_code: transporter_code
        }
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        redirectURL.post("/consignments/challansScreenFilter",params).then((response)=>{
            this.setState({
                rowData : response.data.vehchallan,
                loadshow: "show-n",
                overly: "show-n"
            })
        })
        
    }
	onClickHideAll(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",

		})
	}
    
   onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};

    handleChange = (newValue) => {
        this.setState({ VehicleValueNumber: newValue });
    };

    handleCreate = (inputValue) => {
        console.log("check_new_option")
        const { VehicleNumber } = this.state;
        const newOption = { value: inputValue, label: inputValue };

        // Add the new truck number to the collection
        this.setState({
            VehicleNumber: [...VehicleNumber, newOption],
            VehicleValueNumber: newOption,
        });

        this.setState({
            isnewtruck : true
        })
        
    };

    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        if(name == "challan_no" || name == "VehicleNumber" || name == "dl_number" || name == "engine_no" || name == "transaction_id" || name == "violater_name")
        {
            if(value !="")
            {
                const regex = /^[-\w\s]+$/;
                var valLength = value.toString().length;
                if(valLength <= 30)
                {
                    this.setState({[name]:value});
                }
            }
            else
            {
                this.setState({[name]:value});
            }
        }
        else if(name == "amount_in_inr")
        {
            if(value !="" && value !=undefined)
            {
                if(value > 0)
                {
                    this.setState({[name]:value.replace(/\D/g,'')});
                }
            }
            else
            {
                this.setState({[name]:value.replace(/\D/g,'')});
            }
        }
        else
        {
            this.setState({[name]:value});
    	}
    }

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
    };
    handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1<10?"0"+(d.getMonth() +1):(d.getMonth() +1))+"-"+d.getDate();
    	this.setState({
            startDate:startdate
        })
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1<10?"0"+(d.getMonth() +1):(d.getMonth() +1))+"-"+d.getDate();
    	this.setState({
            endDate:startdate
        })
    }
    selectPlazaName(){
		let items = [];    
		
			if(this.state.plazaNames.length > 0)
			{
				this.state.plazaNames.map((e) =>{
					items.push({"value":e.PlazaCode,"label":e.PlazaName})
				})
			}	
            console.log(items,"items")
		return items;
	}
    selectPlazaCode(){
		let items = [];    
		
			if(this.state.plazaNames.length > 0)
			{
				this.state.plazaNames.map((e) =>{
					items.push({"value":e.PlazaCode,"label":e.PlazaCode})
				})
			}	
            console.log(items,"items")
		return items;
	}
    
    onClickTab(params)
    {
        if(params == "1"){
            this.setState({
                summary:"show-m",
                detailed:"show-n",
                summarycl:"btn-danger",
                detailedcl:""
            })
        }
        if(params == "2"){
            this.setState({
                summary:"show-n",
                detailed:"show-m",
                summarycl:"",
                detailedcl:"btn-danger",
                loadshow:"show-m"
            })
            var sdate = moment.parseZone().subtract(3,"days").format("YYYY-MM-DD")
            var edate = moment.parseZone().format("YYYY-MM-DD");
            var selectedMonthYear = [];
            if(this.state.selectedMonthYear != null && this.state.selectedMonthYear != "")
            {
                // console.log(this.state.selectedMonthYear,"selectedMonthYear")
                this.state.selectedMonthYear.map((e)=>{
                    if(e.value == "All")
                    {
                        selectedMonthYear.push(e.value)
                    }
                    else
                    {
                        var monthNo = monthNumbers[e.value.split("-")[0]]
                        selectedMonthYear.push(monthNo+"-"+e.value.split("-")[1])
                    }
                })
            }
            else{
                selectedMonthYear.push("All")
            }

            var vehicle_Number = [];
            if(this.state.vehicle_Number!=null && this.state.vehicle_Number != "")
            {
                this.state.vehicle_Number.map((e)=>{
                
                    vehicle_Number.push(e.value)
                })
            }
            else 
            {
                vehicle_Number.push("All")
            }
            var status = this.state.selectedStatus
            if(localStorage.getItem("transportercode") != undefined)
            {
                var transporter = JSON.parse(localStorage.getItem("transportercode"))
            }
            else{
                var transporter = "";
            }
            if(Array.isArray(transporter))
            {
            var transporter_code = transporter[0]
            }
            else{
                var transporter_code = transporter[0]
            }
            var params = {
                truck_no: vehicle_Number,
                startdate:sdate,
                enddate:edate,
                manual_entry:1,
            }      
            var currentTime = new Date();
            var year = currentTime.getFullYear();
            var month = currentTime.getMonth() + 1
            month = (month < 10)?"0"+month:month
            var selectedMonthYear = {"label": monthName[parseInt(month)]+"-"+year, "value": monthName[parseInt(month)]+"-"+year}
            // var transportercode = JSON.parse(localStorage.getItem("transportercode"));
            // var transporter_code=transportercode;
            if(localStorage.getItem("transportercode") != undefined)
            {
                var transporter = JSON.parse(localStorage.getItem("transportercode"))
            }
            else{
                var transporter = "";
            }
            if(Array.isArray(transporter))
            {
                var transporter_code = transporter[0]
            }
            else{
                var transporter_code = transporter
            }
            var params = {
                "truck_no": ["All"],
                // "selected_months": [month+"-"+year],
                "startdate":sdate,
                "enddate":edate,
                // "status": "All",
                "transporter_code":transporter_code
            }
            var yearOptions = [];
            redirectURL.post("/consignments/challansScreenData",params).then((response)=>{
                var challanData = response.data.vehchallan
                var uniquetrucknos = response.data.uniquetrucknos
                // var records = response.data.records;
                var vehicle_truck_no = []
                // console.log("records123", challanData)
                if(uniquetrucknos.length > 0)
                {
                    uniquetrucknos.map((e)=>{
                        vehicle_truck_no.push({value:e,label:e})
                    })
                }
                this.setState({
                    vehicle_truck_no:vehicle_truck_no,
                    rowData:challanData,
                    startDate:sdate,
                    endDate:edate,
                    loadshow:"show-n"
                })
            })
        }
    }
    render(){
		
		var columnDefs= [
			{
                headerName: "Truck No",
                field: "truck_no",
                width: 140,
  
  
              },
              {
                  headerName: "Engine Number",
                  field: "engine_no",
                  width: 200,
    
              },
               {
                headerName: "Challan Number",
                field: "challan_no",
                width: 150,
  
              },
              {
               headerName: "Challan Date",
               field: "challan_date_time", 
               width: 150,
               valueGetter:function(params){
                return getHyphenDDMMMYYYYHHMM(params.data.challan_date_time)}
            
              },
              {
                  headerName: "State",
                  field: "state_code",
                  width: 120,
  
              },
              {
                  headerName: "Amount (in INR)",
                  field: "received_amount",
                  width: 140,
              },
              {
                headerName: "Payment Source",
                field: "payment_source",
                width: 120,
            },
              {
                  headerName: "Status",
                  field: "challan_status",
                  width: 150,
              },
              {
                  headerName: "Transaction Id",
                  field: "transaction_id",
                  width: 150,
              },
              {
                headerName: "Violator Name",
                field: "name_of_violator",
                width: 150,
            },
            {
                headerName: "DL or RC Number",
                field: "dl_no",
                width: 150,
            },
			
		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}

        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                    <div className={"col-xl-12 col-lg-12 "+this.state.showStateTrucksMsg}>
                    {/* <p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p> */}
                    </div>
            		<div className="col-xl-12 col-lg-12 n-p-0">
                        
                        <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        </div>

		            	<div className="card">
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>

								</h5>
				   			</div>
                            <div className='col-xl-12 col-lg-12 col-md-12 mb-20p mt-20p'>
                                <button className = {"float-left btn " + this.state.summarycl} 
                                onClick={this.onClickTab.bind(this,1)}> 
                                    Enter Details
                                </button>
                                <button className = {"float-left btn " + this.state.detailedcl} 
                                onClick={this.onClickTab.bind(this,2)}>
                                   History
                                </button>
                            </div>
                               <div className={'col-xl-5 col-lg-5 col-md-5 '+(this.state.summary)}>
                                {/* <div className="form-group col-xl-3 col-lg-3 col-md-3">
                                    <label>Year: </label>
                                    <Select
                                        value={this.state.selectedYear}
                                        //placeholder="All"
                                        closeMenuOnSelect={true}
                                        isMulti={false}
                                        // onChange={this.getRailConsginments.bind(this)}
                                        className={"border-radius-0"}
                                        style={{borderRadius:"0px"}}
                                        options={this.state.yearOptions}
                                        onChange={(e)=>{this.setState({selectedYear: e}); 
                                                        this.formatChartData(e.value)}}
                                    />
                                </div> */}
                                 <form  method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Challan No*</label>
                                        {/* <Select 
                                            placeholder={"Select Plaza Name"}
                                            closeMenuOnSelect={true}
                                            // isMulti={true}
                                            onChange={(e)=>{this.setState({PlazaName:e})}} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            value={this.state.PlazaName}
                                            options={this.selectPlazaName()} />  */}
                                        <div className="col-xl-12 col-lg-12">
                                            <input
                                                type="text"
                                                name="challan_no"
                                                id="challan_no"
                                                autoComplete="off"
                                                value={this.state.challan_no}
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                            />
                                        </div>
                                           
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Amount in INR*</label>
                                        <div className="col-xl-12 col-lg-12">
                                            <input
                                                type="text"
                                                name="amount_in_inr"
                                                id="amount_in_inr"
                                                autoComplete="off"
                                                value={this.state.amount_in_inr}
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Truck No*</label>
                                        {/* <Select
                                            //placeholder="All"
                                            closeMenuOnSelect={true}
                                            //isMulti={true}
                                            // onChange={this.getRailConsginments.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.VehicleNumber}
                                            value={this.state.VehicleValueNumber}
                                            onChange={(e)=>{this.setState({VehicleValueNumber:e})}}
                                        />  */}
                                        <CreatableSelect
                                            isClearable
                                            closeMenuOnSelect={true}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.VehicleNumber}
                                            value={this.state.VehicleValueNumber}
                                            onChange={this.handleChange}
                                            onCreateOption={this.handleCreate}
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">DL or RC Number</label>
                                        <div className="col-xl-12 col-lg-12">
                                            <input
                                                type="text"
                                                name="dl_number"
                                                id="dl_number"
                                                autoComplete="off"
                                                value={this.state.dl_number}
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Engine No*</label>
                                        {/* <Select 
                                            placeholder={"Select Plaza Code"}
                                            closeMenuOnSelect={true}
                                            // isMulti={true}
                                            onChange={(e)=>{this.setState({PlazaCode:e})}} 
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            value={this.state.plaza_code}
                                            options={this.selectPlazaCode()} />    */}
                                        <div className="col-xl-12 col-lg-12">
                                            <input
                                                type="text"
                                                name="engine_no"
                                                id="engine_no"
                                                autoComplete="off"
                                                value={this.state.engine_no}
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                            />
                                        </div>
                                            
                                    </div>
                                    {/* <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Payment Source</label>
                                        <Select
                                            //placeholder="All"
                                            closeMenuOnSelect={true}
                                            //isMulti={true}
                                            // onChange={this.getRailConsginments.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.paymentSource}
                                            value={this.state.paymentValueSource}
                                            onChange={(e)=>{this.setState({paymentValueSource:e})}}
                                        />     
                                    </div> */}

                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">State*</label>
                                        <Select
                                            //placeholder="All"
                                            closeMenuOnSelect={true}
                                            //isMulti={true}
                                            // onChange={this.getRailConsginments.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.stateCode}
                                            value={this.state.stateValueCode}
                                            onChange={(e)=>{this.setState({stateValueCode:e})}}
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Status*</label>
                                        <Select
                                            value= {this.state.selectedStatus}
                                            // placeholder="All"
                                            closeMenuOnSelect={true}
                                            //isMulti={false}
                                            // onChange={this.getRailConsginments.bind(this)}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.paymentStatus}
                                            onChange={(e)=>{this.setState({selectedStatus:e})}}
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Transaction Id*</label>
                                        <div className="col-xl-12 col-lg-12">
                                            <input
                                                type="text"
                                                name="transaction_id"
                                                id="transaction_id"
                                                autoComplete="off"
                                                value={this.state.transaction_id}
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                            />
                                        </div>
                                         
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Violater Name*</label>
                                        <div className="col-xl-12 col-lg-12">
                                            <input
                                                type="text"
                                                name="violater_name"
                                                id="violater_name"
                                                autoComplete="off"
                                                value={this.state.violater_name}
                                                className="form-control"
                                                onChange={this.changeHandler.bind(this)}
                                            />
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Challan Date*</label>
                                        <div className="col-xl-12 col-lg-12">
                                            <input
                                                type="text"
                                                name="challan_date"
                                                id="challan_date"
                                                autoComplete="off"
                                                //value={this.state.challan_date}
                                                className="challan_date datetimepicker_mask form-control"
                                                onChange={this.changeHandler.bind(this)}
                                            />
                                        </div>
                                        
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                 </form>    
                            </div>
                            {this.state.detailedcl =="btn-danger" && 
				   			<div className={"card-body row "+(this.state.detailed)}>
								<div className="row col-xl-12 col-lg-12 col-sm-12">
                                    
                                <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
                                    <div className="row">
                                        <div className="col-xl-2 col-lg-2">
                                            <div className="form-group">
                                                <label>From Date: </label>
                                                {/* <Select
                                                    value={this.state.selectedMonthYear}
                                                    placeholder="All"
                                                    closeMenuOnSelect={true}
                                                    isMulti={true}
                                                    // onChange={this.getRailConsginments.bind(this)}
                                                    className={"border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={this.state.monthYearOptions}
                                                    onChange={(e)=>{this.setState({selectedMonthYear: e})}}
                                                /> */}
                        
                                                <Datetime 
                                                inputProps={{ placeholder: 'yyyy-mm-dd',name:'startDate', id:'startDate',  autoComplete:'off', class:'fc-input form-control' }} 
                                                dateFormat="YYYY-MM-DD" 
                                                timeFormat={false} 
                                                value={this.state.startDate}
                                                onChange={this.handlerStartDateTime.bind(this)} />
                                            
                                            </div>
                                        </div> 
                                        <div className="col-xl-2 col-lg-2">
                                            <div className="form-group">
                                                <label>To Date: </label>
                                            
                                                <Datetime 
                                                inputProps={{ placeholder: 'yyyy-mm-dd',name:'endDate', id:'endDate',  autoComplete:'off', class:'fc-input form-control' }} 
                                                dateFormat="YYYY-MM-DD" 
                                                timeFormat={false} 
                                                value={this.state.endDate}
                                                onChange={this.handlerEndDateTime.bind(this)} />
                                            
                                            </div>
                                        </div> 
                                        <div className="col-xl-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Truck No: </label>
                                                <Select
                                                    //placeholder="All"
                                                    closeMenuOnSelect={true}
                                                    isMulti={true}
                                                    // onChange={this.getRailConsginments.bind(this)}
                                                    className={"border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={this.state.vehicle_truck_no}
                                                    value={this.state.vehicle_Number}
                                                    onChange={(e)=>{this.setState({vehicle_Number:e})}}
                                                />
                                            </div>
                                        </div> 
{/* 
                                        <div className="col-xl-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Status: </label>
                                                <Select
                                                    value= {this.state.selectedStatus}
                                                    // placeholder="All"
                                                    closeMenuOnSelect={true}
                                                    isMulti={false}
                                                    // onChange={this.getRailConsginments.bind(this)}
                                                    className={"border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={[{label: "ALL", value: "ALL"},{"label": "Pending", value: "Pending"},
                                                            {"label": "Cash", value: "Cash"},{"label": "Disposed", value: "Disposed"}]}
                                                    onChange={(e)=>{this.setState({selectedStatus:e})}}
                                                />
                                            </div>
                                        </div>  */}

                                        <div className="form-group col-xl-2 col-lg-2">
                                            <button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
                                        </div>
                                    </div> 
                                </form>

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

                                    <div className="col-xl-12 col-lg-12 ">

                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                masterDetail = {true}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={false}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                floatingFilter={false}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>
                                    

								</div>

                            </div>
                            }
				   		</div>
				   	</div>
				 </div>


				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
            </div>
        );
    }
}

$(document).on("change", "#toDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromDate").val();
    var cargoDate=$("#toDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#toDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#fromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromDate").val();
    var cargoDate=$("#toDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#fromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{

	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);

}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }

  function groupBy(list, keyGetter) {

    const map = new Map();

    list.forEach((item) => {

         const key = keyGetter(item);

         const collection = map.get(key);

         if (!collection) {

             map.set(key, [item]);

         } else {

             collection.push(item);

         }

    });

    return map;

}

function formatYearMonth(list){
    var monthName = {
        1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun",
        7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec"
    }
    list = list.map((e)=>{
        var year = e.split("-")[0];
        var month = e.split("-")[1];
        return monthName[parseInt(month)]+" "+year
    })
    return list
}
function formatNumber (num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}
function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		onShow:false
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
		onShow:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}