
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const EditActions = (props) => {
    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     props.context.componentParent.onCloseRouteDiv(e);
    //     props.context.componentParent.onCloseUploadDiv(e);
    //     props.context.componentParent.onShowTimelineDiv(props.data);
    // };
    const handleClick = (e) => {
        e.stopPropagation(); 
        props.context.componentParent.onShowEditAction(props.data);
    };

    return (
        <div className="edit-icon-component">

            <button onClick={handleClick} className="custom-btn label label-success"><i class="fa fa-edit"></i></button>

        </div>
    );
};

export default EditActions;
