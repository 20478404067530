/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "../layouts/updatelmstates";
import UpdateTransitButton from "../layouts/updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ShowTPTGpsMap from "./showtptgpsmap";
import DrawMap from '../common/drawmap';
import ExportPDF from './exportpdf';
import Constant from "../common/googlemapkey";
import NextBillionMaps from '../common/nbmaps';

import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class TptHarshBreaking extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Harsh Breaking Report",
			screenpage : '',
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				showtptgpsmap:ShowTPTGpsMap,
                UpdateButton:UpdateButton,
                UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:'',
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            rowData : [],
            rakeslist : [],
            loadingpoints:[],
            destinationpoints:[],
            userType:"",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            stockData:[],
            totalStock:0,
            totalSales:0,
            totalDispatches:0,
            railShare:0,
            totalFySales:0,
            totalFyDispatches:0,
            railFyShare:0,
            pendingForDispatchStock:0,
            transitStock:0,
            unloadingUpdatedDate:"",
            intransitUpdatedDate:"",
            screenView: "",
            rowData: [],
            todayInstalled: [],
            totalInstalled: [],
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			filteredData: [],
			truckNoList:[],
			showTrucksList : "show-n",
			startDate:'',
			endDate:'',
			truckNos:[],
			mapinfo:[],
			sliderRouteTranslate:'',
			tpttrucks:[],
			adaniCheck:0,
			pdfRowData: [],
			pdfColumns: [],
			addressColumn: 300,
			node:{},
			harsh_breaking:""
		};
		this.onShowTPTGPSMapRoute = this.onShowTPTGPSMapRoute.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount = async () =>{
       
		var tptCode = localStorage.getItem('transportercode');
		if(tptCode.includes("T293") == true)
        {
            var adaniCheck = 1;
        }
        else
        {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck : adaniCheck
        });
		var vehicle_group = localStorage.getItem("vehicle_group");
		var tpttrucks = []
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			    var recordsarr = response.data.records;
                var records =[] 
                if(recordsarr.length > 0)
                {
                    recordsarr.map((item) => {
                        if(parseInt(item.alert_id) == 14 && parseInt(item.speed) < 10 )
                        {

                        }
                        else
                        {                            
                            records.push(item)
                        }
                        
                    })
                }
				tpttrucks = records
				this.setState({
					tpttrucks:records
				})
			})
		}
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if(is_enmovil_transporter == 1)
        {
			loadDateTimeScript();
			var edate = moment.parseZone().format('YYYY-MM-DD HH:mm');
			var sdate = moment.parseZone().subtract(5, 'days').format('YYYY-MM-DD HH:mm')
			var enddate = moment.parseZone().format('YYYY-MM-DD HH:mm');
			var startdate = moment.parseZone().subtract(5, 'days').format('YYYY-MM-DD HH:mm')
			$("#hbfromDate").val(sdate);
			$("#hbtoDate").val(edate);
			this.setState({
				loadshow:'show-m',
				overly:'show-m'
			});
            this.logPageView(); // Log page view to GA
            var screenView = "";
            var transporter_code = [];
            if(localStorage.getItem('transportercode') !=undefined && localStorage.getItem('transportercode') !="undefined")
            {
				if(localStorage.getItem("transportercode") != "all")
				{
					var transporter_code = JSON.parse(localStorage.getItem("transportercode"));
            	}
				else{
					var transporter_code = localStorage.getItem("transportercode");
            	}
                //var transporter_code = tptCode[0];
			}
			var query = {
                transporter_code : transporter_code,
                startDate:startdate,
                endDate:enddate
			}
			// console.log(localStorage.getItem("plant_location"),"plant_location")
			if(localStorage.getItem('transportercode') !=undefined && localStorage.getItem('transportercode') !="undefined")
            {
				if(localStorage.getItem("transportercode") != "all")
				{
					if(JSON.parse(localStorage.getItem("transportercode")).includes("T293"))
					{
						query.plant_location = localStorage.getItem("plant_location") ;
					}
				}
			}
			
            await redirectURL.post("/dashboard/harshbreakingdata",query).then(async (response)=>{
				
				var trucks = response.data.trucks;				
				var rowData = response.data.response;
				// console.log("rowData",rowData)
			if(trucks !== undefined && trucks !== "undefined"){	
				if(trucks.length > 0)
				{
					var trucksarr = []
					var truckNosList = [];
					truckNosList.push({
						value:"Alllist",
						label:"ALL"
					  })
					//var tpttrucks = this.state.tpttrucks;
					if(JSON.parse(localStorage.getItem("transportercode")).includes("T293") && localStorage.getItem("plant_location") != "" &&
					localStorage.getItem("plant_location") !== undefined)
					{
						var lsParams = {
							transporter_code : JSON.parse(localStorage.getItem("transportercode")),
							plant_location : localStorage.getItem("plant_location")
						}
						await redirectURL.post("/consignments/getDistinctTrucks",lsParams).then(async (response1) => {
							var tpttrucks = response1.data.recordsdata;
							//console.log(tpttrucks,"tpttrucks---");
							//console.log(recs,"recs---");
						if(tpttrucks !== undefined && tpttrucks!== "undefined")
						{	
							if(tpttrucks.length > 0)
							{
								await trucks.filter(f =>
									tpttrucks.filter((d )=> {
										if(d.truck_no === f){									
											// trucksarr.push(f)
											truckNosList.push({
												label:f,
												value:f,
											});
										}
									})
								);
							}
							else
							{
								// trucksarr = trucks
								trucks.map((item) => {
									truckNosList.push({
										label:item,
										value:item,
									});
								})
							}
							if(tpttrucks.length > 0)
							{
								trucksarr = await rowData.filter(function(n) { return tpttrucks.indexOf(n.truck_no) !== -1;});
								// console.log(trucksarr,"trucksarr")
								var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
								var pdfRowData = [];
								trucksarr.map(t => {
									(								
										pdfRowData.push({								
										name: t.truck_no,									
										transporter_code: (t.transporter_code),
										timestamp: getHyphenDDMMMYYYYHHMM(t.timestamp),
										speed: t.speed,
										// alert_id: (t.alert_id),
										alert_address: t.alert_address,
										alert_id: (t.alert_id == 13 ? "Harsh Breaking" : t.alert_id == 14 ? "Acceleration" : "")
									}))
								})
								var pdfColumns = [
									{ header: name, binding: 'name'},                
									{ header: 'Transporter Code', binding: 'transporter_code'},
									{ header: 'Start Time', binding: 'timestamp'},
									{ header: 'Speed (km/h)', binding: 'speed'},
									{ header: 'Alert Type', binding: 'alert_id',  
									valueGetter:function(params){
										try{
											if(params.data.alert_id == 13)
											{
												return "Harsh Breaking";
											}
											else if(params.data.alert_id == 14)
											{
												return "Accelerations";
											}
											else
											{
												return "";
											}
										}
										catch(e){
											return "";
										}
									}},
									{ header: 'Address', binding: 'alert_address'},							
								]
								this.setState({
									pdfRowData,
									pdfColumns,
									rowData: trucksarr,
									truckNoList: truckNosList,
									loadshow:'show-n',
									overly:'show-n'
								})
							}
						}	
							else
							{
								trucksarr = rowData
							}
						})
					}
					else
					{
					
						if(tpttrucks.length > 0)
						{
							
							await trucks.filter(f =>
								tpttrucks.filter((d )=> {
									if(d.truck_no == f){									
										// trucksarr.push(f)
										truckNosList.push({
											label:f,
											value:f,
										});
									}
								})
							);
						}
						else
						{
							// trucksarr = trucks
							trucks.map((item) => {
								truckNosList.push({
									label:item,
									value:item,
								});
							})
						}
						if(tpttrucks.length > 0)
						{
							rowData.filter(f =>
								tpttrucks.filter((d )=> {
									if(d.truck_no == f.truck_no){									
										trucksarr.push(f)
									}
								})
							);
						}
						else
						{
							trucksarr = rowData
						}
						// console.log(trucksarr,"trucksarr")
						var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
						var pdfRowData = [];
						
						trucksarr.map(t => {
							(								
								pdfRowData.push({								
								name: t.truck_no,									
								transporter_code: (t.transporter_code),
								timestamp: getHyphenDDMMMYYYYHHMM(t.timestamp),
								speed: t.speed,
								// alert_id: (t.alert_id),
								alert_address: t.alert_address,
								alert_id: (t.alert_id == 13 ? "Harsh Breaking" : t.alert_id == 14 ? "Acceleration" : "")
							}))
						})
						var pdfColumns = [
							{ header: name, binding: 'name'},                
							{ header: 'Transporter Code', binding: 'transporter_code'},
							{ header: 'Start Time', binding: 'timestamp'},
							{ header: 'Speed (km/h)', binding: 'speed'},
							{ header: 'Alert Type', binding: 'alert_id',  
							valueGetter:function(params){
								try{
									if(params.data.alert_id == 13)
									{
										return "Harsh Breaking";
									}
									else if(params.data.alert_id == 14)
									{
										return "Accelerations";
									}
									else
									{
										return "";
									}
								}
								catch(e){
									return "";
								}
							}},
							{ header: 'Address', binding: 'alert_address'},							
						]
						this.setState({
							pdfRowData,
							pdfColumns,
							rowData: trucksarr,
							truckNoList: truckNosList,
							loadshow:'show-n',
							overly:'show-n'
						})
					
					}
				}
			}	
				else
				{
					this.setState({
						rowData: [],
						truckNoList: [],
						loadshow:'show-n',
						overly:'show-n'
					})
				}
            })
        }
        else
        {
            window.location.href="/";
        }
		this.getUserGridState()
    }
	getUserGridState(){
		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname , screentitle:"Harsh Breaking Report"})
		.then( async (resp) => {
			// console.log("resp ", resp)
			this.setState({
				usergridstate:resp.data
			});
			this.restoreGridStates();
		})
	}
	onClickHideAll(){
		this.setState({
			overly : "show-n",
			showTrucksList : "show-n",
			sliderRouteTranslate:'',
		})
	}
	hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	
    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };
    onShowTPTGPSMapRoute(rownode){
        var node = rownode.data;
        console.log("Node ", node)
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
	// 	const toDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
	// 	var parameters = {
	// 		truck_no:node.truck_no,
	// 		from_date:moment.parseZone(node.timestamp).format('YYYY-MM-DD HH:mm:ss'),
	// 		to_date:toDate,
    //     }
    //     redirectURL.post("/consignments/showgpseodmap", parameters)
    //     .then((response) => {
    //         console.log("response ", response.data)
    //         this.setState({
    //             mapinfo:response.data,
    //             sliderRouteTranslate:"slider-translate-60p",
    //             loadshow:'show-n',
    //             overly:'show-m',

    //     });
    //     })
		  this.setState({
			overly:"show-n",
			loadshow:"show-n",
			sliderRouteTranslate : "slider-translate-60p",
			node:node,
			harsh_breaking:node

		})
		  this.renderMap()
        
	}
	renderMap = () => {

        loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap1
    }
    initMap1 = () => {
        //console.log("arr ",arr)
       
		var lt = 28.4519751;
		var ln = 77.0310713;
		var map;
       
        try {
            map = new window.google.maps.Map(document.getElementById('map'),  {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels: true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(28.4519751, 77.0310713),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            });
        } catch (e) { 
			console.log(e);
			alert("error")
		}

        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(lt, ln));
        // Create our info window content
		var marker = new window.google.maps.Marker({
			position: new window.google.maps.LatLng(this.state.node.latitude, this.state.node.longitude),
			// icon: {
			// 	path: window.google.maps.SymbolPath.CIRCLE,
			// 	strokeColor: '#157254',
			// 	fillColor: '#157254',
			// 	fillOpacity: 1,
			// 	strokeWeight: 5,
			// 	scale: 1,
			// },
			map: map,
			// content: arr2[a]
		});
		bounds.extend(new window.google.maps.LatLng(this.state.node.latitude, this.state.node.longitude));

		// 	//console.log("Arr ",arr1[a])
		var infowindow = new window.google.maps.InfoWindow();
		window.google.maps.event.addListener(marker, 'mouseover', ( (marker) =>{
			return  () => {
				//console.log("Arr ",marker)
				var contentarr = []
				var headerName = ""
				//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
				if(this.state.node.alert_id == 13)
				{
					headerName = "Harsh Breaking";
				}
				else if(this.state.node.alert_id == 14)
				{
					headerName = "Accelerations";
				}
				contentarr.push({ "key": "Truck No ", "value": this.state.node.truck_no })
				contentarr.push({ "key": "TimeStamp ", "value": getDDMMYYYYHHMMSS(this.state.node.timestamp) })
				contentarr.push({ "key": "Transporter Code ", "value": this.state.node.transporter_code })

				var contentString = infoBox("", headerName , contentarr, '')


				infowindow.setContent(contentString);
				// currentwindow = infowindow;
				infowindow.open(map, marker);
				//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
			}
		})(marker));
		window.google.maps.event.addListener(marker, 'mouseout', function () {
			infowindow.close();
		});

		
        map.fitBounds(bounds)

    }

	onClickFilterOverspeedReport = () => {
		var truckNos = this.state.truckNos;
		var truck_nos = [];
		if(this.state.truckNos !="" && this.state.truckNos != undefined){
			if(truckNos.length > 0)
			{
				truckNos.map(function(e){
					truck_nos.push(e.value)
				});
			}
		}
			
			if(truck_nos.length == 0)
			{
                truck_nos = JSON.stringify(["Alllist"]);
            }
		
		var fromDate = $("#hbfromDate").val();
		var toDate = $("#hbtoDate").val();
		if(fromDate !='' && toDate !='')
		{
			var transporter_code = [];
			if(localStorage.getItem('transportercode') !=undefined)
            {
				if(localStorage.getItem("transportercode") != "all")
				{
					var transporter_code = JSON.parse(localStorage.getItem("transportercode"));
            	}
				else{
					var transporter_code = localStorage.getItem("transportercode");
            	}
                //var transporter_code = tptCode[0];
			}
			var reqparams = {
				transporter_code: transporter_code,
				truck_no : truck_nos,
				startDate : fromDate,
				endDate : toDate,
			}
			// console.log("reqparams ", reqparams)
			this.setState({
				loadshow:'show-m',
				overly:'show-m',
			})
			console.log(reqparams , "1729")
			redirectURL.post("dashboard/harshbreakingfilter",reqparams)    
			.then(async(response) => {
				var records = response.data.response;
				var tpttrucks = this.state.tpttrucks;
				var trucksarr = []
				if(JSON.parse(localStorage.getItem("transportercode")).includes("T293") && localStorage.getItem("plant_location") != "" &&
                localStorage.getItem("plant_location") != undefined)
                {
                    var lsParams = {
                        transporter_code : JSON.parse(localStorage.getItem("transportercode")),
                        plant_location : localStorage.getItem("plant_location")
                    }
                    await redirectURL.post("/consignments/getDistinctTrucks",lsParams).then(async (response1) => {
                        var tpttrucks = response1.data.recordsdata;
                        //console.log(tpttrucks,"tpttrucks---");
                        //console.log(recs,"recs---");
                        
						if(tpttrucks.length > 0)
						{
							trucksarr = await records.filter(function(n) { return tpttrucks.indexOf(n.truck_no) !== -1;});

						}
						else
						{
							trucksarr = records
						}
                    })
                }
				else
				{	
					if(tpttrucks.length > 0)
					{
						records.filter(f =>
							tpttrucks.filter((d )=> {
								if(d.truck_no == f.truck_no){									
									trucksarr.push(f)
								}
							})
						);
					}
					else
					{
						trucksarr = records
					}
				}

				var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
						var pdfRowData = [];
						trucksarr.map(t => {
													
								pdfRowData.push({								
								name: t.truck_no,
								first_instance: getHyphenDDMMMYYYYHHMM(t.first_instance),
								speed: t.speed,
								recent_instance: getHyphenDDMMMYYYYHHMM(t.recent_instance),
								first_instance_address: t.first_instance_address,								
							})
						})
						// console.log("pdfRowDat", trucksarr)
						var pdfColumns = [
							{ header: name, binding: 'name'},                
							{ header: 'Start Time', binding: 'first_instance'},
							{ header: 'Speed (km/h)', binding: 'speed'},
							{ header: 'End Time', binding: 'recent_instance'},
							{ header: 'Address', binding: 'first_instance_address'},							
						]

				this.setState({
					pdfRowData,
					pdfColumns,
					loadshow:'show-n',
					overly:'show-n',
					rowData:trucksarr
				})
			})
			.catch(function(e){
				console.log("Error ",e)
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:'Please select dates to continue',
				basicType:"danger",
				loadshow:'show-n',
			})
		}
		
	}

	onChangeDepartment(truckNos){
		this.setState(
			{ truckNos },
			() => console.log(`truckNos Option selected:`, this.state.truckNos)
		);
	}
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		console.log(window.colState , "harshbreaking_columns")
		let screenpage = 'Harsh Breaking Report';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};


    render(){
        var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
        
		var columnWithDefs= [
			{
                headerName:"",
                field:"",
                width:50,
                cellRendererSelector:function(params){
                    return {
                        component:"showtptgpsmap"
                    }
                }
            },
            {
				headerName: tkName,
				field: "truck_no",
                width: 160,
                filter: true,
                resizable: true,
                sortable:true,
			},
            {
				headerName: "Transporter Code",
				field: "transporter_code",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
			},
            {
				headerName: "Start Time",
				field: "timestamp",
                width: 160,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter: function(params){
                    if(params.data.timestamp !=undefined && params.data.timestamp !='')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
					else
					{
						return "N/A";
					}
                }
            },
            {
				headerName: "Speed (km/h)",
				field: "speed",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
			},
            
            {
				headerName: "Alert Type",
				field: "alert_id",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter:function(params){
                    try{
                        if(params.data.alert_id == 13)
                        {
                            return "Harsh Breaking";
                        }
                        else if(params.data.alert_id == 14)
                        {
                            return "Accelerations";
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
			},
            
			{
				headerName: "Address",
				field: "alert_address",
                width: 240,
                filter: true,
                resizable: true,
                sortable:true
            },
			// {
			// 	headerName:"Current Geofence Name",
			// 	field:"current_geofence_name",
			// 	width:240,
			// 	filter: true,
            //     resizable: true,
            //     sortable:true
			// },
		];
		if(localStorage.getItem("transportercode").indexOf("T305") >= 0)
		{
			// console.log('columnWithDefs',columnWithDefs)
			columnWithDefs.push(
				{
					headerName:"Carrier Title",
					field:"carrier_title",
					width:240,
					filter: true,
					resizable: true,
					sortable:true
				},
				{
					headerName:"Group Title",
					field:"group_title",
					width:240,
					filter: true,
					resizable: true,
					sortable:true
				}
			)
		}
		var listData = [] 
        this.state.filteredData.map(function(e){
        listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="card">
                    <div className="card-header">
                        <h5>
							<i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                        	Safety Reports - Harsh Braking/Acceleration
							<div className="col-xl-12 col-lg-12">
                                    
                                    <span className="layoutbtns pull-right">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                </div>
                        </h5>
                    </div>
					
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>
						<div className={"trucks-dropdown "+this.state.showTrucksList} style={{top:"94px"}}>
							<ul>
								{listData}
							</ul>
						</div>
						<div class="row">
							<div class={" col-xl-2 col-lg-2 form-group"}>
								<label>{(this.state.adaniCheck == 1) ?	"Shuttles"  : "Trucks"} </label>
								<Select 
								placeholder={(this.state.adaniCheck == 1) ?	"Shuttles"  : "Trucks"}
								closeMenuOnSelect={false}
								isMulti={true}
								onChange={this.onChangeDepartment.bind(this)}
								className={"border-radius-0"}
								autoComplete="off"
								style={{borderRadius:"0px"}}
								options={this.state.truckNoList} />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>From Date</label>
								<input type="text" name="date" autoComplete="off" id="hbfromDate" className="hb_date form-control" />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>To Date</label>
								<input type="text" name="date" autoComplete="off" id="hbtoDate" className="hb_date form-control" />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>&nbsp;</label>
								<br />
								<button type="submit" onClick={this.onClickFilterOverspeedReport.bind(this)} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>&nbsp;</label>
								<br />
								{(this.state.pdfRowData.length > 0) ?
									<ExportPDF  rowData={this.state.pdfRowData} pageTitle="HarshBreaking Report" pdfColumns={this.state.pdfColumns} 
									addressColumn={this.state.addressColumn}/>  
								: "" }							</div>
							
						</div>
                        <div id="myGrid" style={{width:"100%",height:"350px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnWithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={50}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this },
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						{/* <div className="slide-r-body" style={{height:"100%"}}>
							
							<NextBillionMaps 
								harsh_breaking = {this.state.harsh_breaking}
							/>

                        </div> */}
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
							<div id="map" className="" style={{ width: '100%', height: "70vh" }}></div>
							</div>
                        </div>
                    </div>
                :""}
				
            </div>
              
        );
    }
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	// console.log(date.length);
	// console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.hb_date').datetimepicker({
		format:'Y-m-d H:i',
		timepicker:true
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#hbtoDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#hbfromDate").val();
    var cargoDate=$("#hbtoDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#hbtoDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#hbfromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#hbfromDate").val();
    var cargoDate=$("#hbtoDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#hbfromDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};