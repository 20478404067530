/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
//import { getConsignmentsNoGpsLifeTimeCounter } from '../../../../tpt_node/models/consignmentModel';

import CountUp from 'react-countup';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
export default class TptVicinityScreen extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Fastag Trucks",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },

			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,

			plant_code:'',
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
            fuel_station_options:[],
            fuel_station_data:[],
            fuelstagingtrucks:[],
            selectFuelStationName:"All",
            markersList:[],
            fasttagtrucks:[],
            fasttagtnx:[],
            VehicleNumber:[],
            btnClicked:[],
            CellRendererFunc:[],
			detailCellRendererParams:{},
			totalwallet:0,
			totaltagsassign:0,
			totalfleet:0
		};
		this.popmarker=this.popmarker.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
		this.setState({loadshow:"show-m"})
		var tptCode = localStorage.getItem('transportercode');		
		var vehicle_group = localStorage.getItem("vehicle_group");
		let vehicleGroupList = []
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
		
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			   var records = response.data.records;
				vehicleGroupList = records
				this.setState({
					tpttrucks:records
				})
			})
		}
		if(localStorage.getItem("transportercode") != undefined)
        {
            var transporter = JSON.parse(localStorage.getItem("transportercode"))
        }
        else{
            var transporter = "";
        }
        if(Array.isArray(transporter))
        {
            var params = {
                transporter_code : transporter
            }
        }
        else{
            var params = {
                transporter_code : [transporter]
            }
        }
		// if(vehicle_group !== "") params.region = vehicle_group
            redirectURL.post("/consignments/fasttagTrucksScreen",params).then((response)=>{
				var conters = response.data.fastagbalance;
				var totalwallet=0;
				var totaltagsassign=0;
				var totalfleet=0;
				if(conters.length > 0)
				{
					totalwallet=conters[0].CUGWalletBalance;
					totaltagsassign=conters[0].TotalTagsAssigned;
					totalfleet=conters[0].FleetSize;
				}
				let rowData = []
				let fastTagTxnFilter = []
				let respData = response.data.fasttagtrucks;
				// for fasttagtnx it is last 3 days data
				let fasttagtnx = response.data.fasttagtxn;
				if(this.state.tpttrucks.length !== 0){
					respData.filter(f => this.state.tpttrucks.filter(d =>{
						if(d.truck_no == f.VehicleNumber){									
							rowData.push(f)
						}
					}))
				}else{
					rowData = respData;
				}

                this.setState({
                    fasttagtrucks:rowData,
					totalwallet:totalwallet,
					totaltagsassign:totaltagsassign,
					totalfleet:totalfleet,
					loadshow:"show-n"

                })
            })

    }
	formatValue(value){
        return value.toLocaleString('hi-IN');
    }

	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	  }

formHandler = (event) =>{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}

        this.state.markersList.map((e)=>{
            if(this.state.selectFuelStationName == e.title || this.state.selectFuelStationName == "All"){
                e.setVisible(true)
            }
            else{
                e.setVisible(false)
            }
        })
    }
	onClickHideAll(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",

		})
	}
	async popmarker(e)
	{
        
        
        if(e.colDef.field == "Transactionbutton")
        {
			var VehicleNumber = e.data.VehicleNumber
			var tptCode = localStorage.getItem("transportercode")
			var params = {
				VehicleNumber:VehicleNumber,
			}
			var fasttagtnxdata = []
			await redirectURL.post("consignments/getfasttagtnxdata", params).then((response) => {
				console.log(response.data)
				fasttagtnxdata = response.data
			})

        await this.setState({
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName: "Vehicle No",
                            field: "VehicleNumber",
                            width: 140,
              
              
                          },
                          {
                              headerName: "StartTransactionDate",
                              field: "ProcessingDateTime",
                              width: 200,
                              valueGetter:function(params){
                                return getHyphenDDMMMYYYYHHMM(params.data.ProcessingDateTime)}
                
                          },
                           {
                            headerName: "EndTransactionDate",
                            field: "TransactionDateTime",
                            width: 150,
                            valueGetter:function(params){
                                return getHyphenDDMMMYYYYHHMM(params.data.TransactionDateTime)}
              
                          },
                          {
                           headerName: "LaneCode",
                           field: "LaneCode", 
                           width: 150,
                        
                          },
                          {
                          	headerName: "PlazaCode",
                          	field: "PlazaCode",
                          	width: 200,
              
                          },
                          {
                          	headerName: "PlazaName",
                          	field: "PlazaName",
                          	width: 120,
                          },
                          {
                            headerName: "Processing DateTime",
                            field: "ProcessingDateTime",
                            width: 120,
                            valueGetter:function(params){
                                return getHyphenDDMMMYYYYHHMM(params.data.ProcessingDateTime)}
                        },
                          {
                          	headerName: "Transaction Amount",
                          	field: "TransactionAmount",
                          	width: 150,
                          },
                          {
                          	headerName: "Transaction DateTime",
                          	field: "TransactionDateTime",
                          	width: 150,
                              valueGetter:function(params){
                                return getHyphenDDMMMYYYYHHMM(params.data.TransactionDateTime)}
                          },
                          {
                            headerName: "Transaction Id",
                            field: "TransactionId",
                            width: 150,
                        },
                        {
                            headerName: "Transaction Reference No",
                            field: "TransactionReferenceNumber",
                            width: 150,
                        },
                        {
                            headerName: "Transaction Status",
                            field: "TransactionStatus",
                            width: 150,
                        },
                        
                          
                    ],
                    overlayNoRowsTemplate: 'No rows to show',
                    refreshStrategy: 'everything',
                },
                getDetailRowData:async  (param) =>{
                    param.successCallback(fasttagtnxdata);
                },
                masterDetail: true
            }
        });
            e.node.setExpanded(!e.node.expanded);

        }
	}
    
   onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};


	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		
		var columnDefs= [
			{
			  headerName: "Vehicle Number",
			  field: "VehicleNumber",
			  width: 140,


			},
            {
                headerName: "Tag AccountNumber",
                field: "TagAccountNumber",
                width: 200,
  
            },
		 	{
		      headerName: "Toll Balance",
		      field: "TollBalance",
		      width: 150,

		    },
            {
             headerName: "",
             field: "Transactionbutton",
             width: 150,
             cellRenderer: (params)=> {
                return '<button type="button" class="btn btn-primary" style = "padding:0px">View Transaction</button>';
            }

            },
			
		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}

        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
			<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
	            {this.state.alert}
            	<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
						<div className="card">
							<div className="card-body" id="dashboardCounterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">

										<div className="col cpointer" style = {{textAlign:"center"}}>
											<span className="f15 txt-primary"><br /> Total Fleet Size  </span>
											<h4 className="txt-primary f40"><span className="counter">
												<CountUp end= {(this.state.totalfleet)?this.state.totalfleet:0}/>
												</span></h4>
										</div>
										<div className="col cpointer" style = {{textAlign:"center"}}>
											<span className="f15 txt-success"><br /> Total Tags Assigned  </span>
											<h4 className=" txt-success f40"><span className="counter">
												<CountUp end= {(this.state.totaltagsassign)?this.state.totaltagsassign:0}/>
												</span></h4>
										</div>
										<div className="col cpointer" style = {{textAlign:"center"}}>
											<span className="f15 txt-danger"><br />  Total Wallet Balance (in INR)</span>
											<h4 className="txt-danger f40"><span className="counter">
												<CountUp end= {(this.state.totalwallet)?this.state.totalwallet:0}
												formattingFn={this.formatValue.bind(this)}/>
												</span></h4>
										</div> 
									</div>
								</div>

							</div>
						</div>
					</div>
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>

								</h5>
				   			</div>
				   			<div className="card-body row">
							   <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
								   <div className={"col-xl-12 col-lg-12 "+this.state.showStateTrucksMsg}>
								   	{/* <p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p> */}
								   </div>

	                    			<div className="row">
                                        {/* <div className="col-xl-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Select Fuel Station : </label>
                                                <Select
                                                    placeholder="All"
                                                    closeMenuOnSelect={true}
                                                    isMulti={false}
                                                    // onChange={this.getRailConsginments.bind(this)}
                                                    className={"border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={this.state.fuel_station_options}
                                                    onChange={(e)=>{this.setState({selectFuelStationName:e.value})}}
                                                />
                                            </div>
                                        </div> */}

										{/* <div className="form-group col-xl-2 col-lg-2">
											<button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
										</div> */}
									</div>


								</form>
								<div className="row col-xl-12 col-lg-12 col-sm-12">

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

                                    <div className="col-xl-12 col-lg-12 ">

                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                masterDetail = {true}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.fasttagtrucks}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                // enableRangeSelection={false}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                floatingFilter={false}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>

								</div>

                            </div>
				   		</div>
				   	</div>
				 </div>


				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
            </div>
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{

	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);

}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }