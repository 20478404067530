import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules, AllEnterpriseModules} from '@ag-grid-enterprise/all-modules';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMMYYYY, getHyphenDDMMYYYY } from '../common/utils';
// import ShowTruckConsignments from "./showtruckconsignments.js"
import RevokSaveBilling from "./checkboxtruckconsignments"
import Showuptimelegs from "./showgpsbillinguptimelegs";
import Modal from 'react-responsive-modal';
import BillingLogs from './tptgpsbillinglogs';
import CSVFileValidator from 'csv-file-validator';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class TPTGPSBilling extends Component{
        constructor(props){
        super(props);
        this.state = {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:"",
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                // showtruckconsignments:ShowTruckConsignments,
                RevokSaveBilling:RevokSaveBilling,
                Showuptimelegs:Showuptimelegs
			},
            headerHeight:60,
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            dept_code:"",
            pageTitle:"",
            month:[],
            year:[],
            startDate:'',
            endDate:'',
            billingtrucks:[],
            pagerest:0,
            approveby:"",
            approvedon:"",
            hidebuttons:0,
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false,
            reason:'',
            approveenreason:'',
            revertreason:'',
            approveitemreason:'',
            disapprovereason:'',
            dispproveitem:"",
            approveitem:"",
            billingRowData:"",
            screen:'',
            msilapprover:'',
            revertapprover:'',
            acbtntrucks:"btn-danger",
            acbtntrips:"btn-default",
            columnstrucks:1,
            columnstrips:0,
            truckslist:[],
            rowTripData:[],
            rowTruckData:[],
            rowmsilData:[],
            notrowData:[],
            activetab:"btn-success",
            activeNtab:"btn-default",
            atab:"msil",
            tottalDistance:0,
            billmonthtext:"",
            sliderUptime:"",
            uptimeRowData:[],
            uptimetruckno:"",
            billstart:"",
            billend:"",
            uptimecolumnsDefs:[],
            pinnedRegionBottomRowData:[],
        }
        this.onLoadBillingData = this.onLoadBillingData.bind(this);
        this.onClickCheckboxItem = this.onClickCheckboxItem.bind(this);
        this.onPaginationChanged = this.onPaginationChanged.bind(this);
        this.resetPaginationSelection = this.resetPaginationSelection.bind(this);
        this.revertSavedItem = this.revertSavedItem.bind(this);
        this.approveSavedItem = this.approveSavedItem.bind(this);
        this.onClickShowUpTimeLegs = this.onClickShowUpTimeLegs.bind(this);
        this.onClickDonwloadUpTimeLegs = this.onClickDonwloadUpTimeLegs.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount(){
        var prevmonth = moment.parseZone().subtract(1, 'months').format("YYYY-MM")
        var currentmonth = moment.parseZone().format("YYYY-MM")
        var prevmm = moment.parseZone().subtract(1, 'months').format("MM")
        // var currentmm = moment.parseZone().format("MM")
        // var currentyy = moment.parseZone().format("YYYY")
    //    console.log("prevmonth ",prevmonth)
    //    console.log("currentmonth ",currentmonth)
    //    var startDate = prevmonth+"-"+16
    //    var endDate = currentmonth+"-"+15
        var monthc = moment.parseZone().format("MM")
        // var currentyy = moment.parseZone().format("YYYY")

        var mnth = parseInt(this.props.match.params.month)
        if(mnth < 10)
        {
            monthc = "0"+mnth;
        }
        else
        {
            monthc = mnth;
        }
        var currentmm = monthc
        var currentyy = this.props.match.params.year
        var mopts = ['January', 'February', 'March', "April", 'May', 'June', 'July', 'August', 
         'September', 'October', 'November', 'December'] 
       this.setState({
            // startDate:startDate,
            // endDate:endDate,
            year:currentyy,
            month:currentmm,
            billmonthtext:mopts[parseInt(currentmm)-1]
        })
        var transporter_code = localStorage.getItem("transportercode");
        var parameters = {
            // startDate:startDate,
            // endDate:endDate,
            year:currentyy,
            month:currentmm,
            transporter_code:JSON.parse(transporter_code)
        }
        
        this.onLoadBillingData(parameters)
    }

    onLoadBillingData(parameters){
        this.setState({
            loadshow:"show-m"
        })
        redirectURL.post("/consignments/tptgpsbillingtrucks",parameters)
        .then((response) => {
            // console.log("response ", response.data.devices)
            var devices = response.data.devices
            console.log("devices ", devices)
            var msilrecs = []
            var nonmsilrecs = []
            var tper = 0
            var avguptime = 0
            var billstart="";
            var billend="";
            if(devices.length > 0)
            {
                devices.map((item) => {
                    if(item.is_part_msil_fleet == "Yes")
                    {
                        msilrecs.push(item);
                    }
                    else{
                        nonmsilrecs.push(item);
                    }
                    
                    tper = parseFloat(tper)+parseFloat(item.uptime_percentage)
                })
                billstart = devices[0].billing_start
                billend = devices[0].billing_end
            }
            avguptime = tper/(devices.length)
            var pinnedBottomRowData = {}
            pinnedBottomRowData['truck_no'] = "";
            pinnedBottomRowData['transporter_code'] = "";
            pinnedBottomRowData['transporter_name'] = "";
            pinnedBottomRowData['device_registered_on'] = "";
            pinnedBottomRowData['billing_start'] = "";
            pinnedBottomRowData['billing_end'] = "";
            pinnedBottomRowData['billing_days'] = "";
            pinnedBottomRowData['uptime_days'] = "Total (%)";
            pinnedBottomRowData['uptime_percentage'] = avguptime;
            pinnedBottomRowData['is_part_msil_fleet'] = "total";
            pinnedBottomRowData['tpt_billing_status'] = "total";
            if(this.state.atab == "msil")
            {
                this.setState({
                    rowData:msilrecs,
                    tottalDistance:[pinnedBottomRowData],
                    rowmsilData:msilrecs,
                    nonmsilrowData:nonmsilrecs,
                    loadshow:"show-n",
                    billstart:billstart,
                    billend:billend
                })
            }
            else{
                this.setState({
                    rowData:nonmsilrecs,
                    tottalDistance:[pinnedBottomRowData],
                    rowmsilData:msilrecs,
                    nonmsilrowData:nonmsilrecs,
                    loadshow:"show-n"
                })
            }
           
            
        })
    }
    
    
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           

           
        
        //
    }
    
    
    onGridReady1 = params => {
		this.gridApi1 = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
        
        this.gridApi1.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi1.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	

	onGridState1 = () =>{
		 console.log(this.gridApi1);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState1 = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState1 = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns1 = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi1.getFilterModel();
           this.gridApi1.setFilterModel(window.filterState);
           

           
        
        //
    }

    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderUptime:""
        });
        
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    changeArrayHandler = month => {
        this.setState(
            { month }
        );
        
    }
    monthoptions(){
        var months = [
           // {value:'all',label:'All'},
            {value:'01',label:'Jan'},
            {value:'02',label:'Feb'},
            {value:'03',label:'Mar'},
            {value:'04',label:'Apr'},
            {value:'05',label:'May'},
            {value:'06',label:'Jun'},
            {value:'07',label:'Jul'},
            {value:'08',label:'Aug'},
            {value:'09',label:'Sep'},
            {value:'10',label:'Oct'},
            {value:'11',label:'Nov'},
            {value:'12',label:'Dec'}
        ]

        return months;
    }
    
    changeYearArrayHandler = year => {
        this.setState(
            { year }
        );
        
    }
    yearoptions(){
        var years = []
        var cyear = moment.parseZone().format("YYYY")
        var defaultyear = 2020
        //console.log("cyear ", cyear)
        for(var i=defaultyear;i<=cyear;i++)
        {
            //console.log(i)
            years.push({value:i,label:i})
        }    
       // console.log("years ", years)
        return years;
    }

    approveAllTrucks(){
        this.setState({
            open:true
        })
    }
    approveAllTrucksAction(){
        var selecteditems = []
        this.gridApi.forEachLeafNode( (node) => {
            //console.log("NODE ALL ", node)
            selecteditems.push(node.data)
        })

        var cnf = window.confirm("Are you sure you want to approve? Data cannot be modified once approved.")
        //console.log("cnf ", cnf)

        var transporter_code = localStorage.getItem("transportercode");
        if(cnf == true)
        {
            if(this.state.reason != "" && this.state.reason.trim() != "")
            {
                var params = {
                    selecteditems:selecteditems,
                    month:this.state.month,
                    year:this.state.year,
                    reason:this.state.reason,
                    screen:"msil",
                    transporter_code:JSON.parse(transporter_code),
                    email:localStorage.getItem("email"),
                    username:localStorage.getItem("username")
                }
                redirectURL.post("/consignments/tptapproveConfirmGPSBillingTrucks", params)
                .then(async (response) => {
                    
                    if(response.data.status == "success")
                    {
                        await  this.setState({
                            show:true,
                            basicType:"success",
                            basicTitle:"Successfully approved",
                            open:false,
                            reason:""
                        })
                        
                        var parameters = {
                            // startDate:this.state.startDate,
                            // endDate:this.state.endDate,
                            month:this.state.month,
                            year:this.state.year,
                            transporter_code:JSON.parse(transporter_code)
                        }
                        this.onLoadBillingData(parameters)
                    }
                    else
                    {
                        await this.setState({
                            show:true,
                            basicTitle:"Please choose atleast one item",
                            basicType:'info',
                            open:false,
                            reason:""
                        })
                        
                    }
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Please enter reason",
                    basicType:'danger'
                })
            }
            
        }
    }

    revertSavedItem(item)
    {
        this.setState({
            disapproveopen:true,
            dispproveitem:item
        })
    }
    revertSavedItemAction(){
        var item = this.state.dispproveitem
        var cnf = window.confirm("Are you sure you want to disapprove item ?")
           // console.log("cnf ", cnf)
           
        var transporter_code = localStorage.getItem("transportercode");
        if(cnf == true)
        { 
            if(this.state.disapprovereason != "" && this.state.disapprovereason.trim() != "")
            {
                var params = {
                    revertdata:item.data,
                    reason:this.state.disapprovereason,
                    month:this.state.month,
                    year:this.state.year,
                    screen:"msil",
                    transporter_code:JSON.parse(transporter_code),
                    email:localStorage.getItem("email"),
                    username:localStorage.getItem("username")
                }
                redirectURL.post("/consignments/tptgpsrevertSaveItem", params)
                .then(async (response) => {
                    //console.log(response.data)
                    if(response.data.status == "success")
                    {
                        await this.setState({
                            show:true,
                            basicType:"success",
                            basicTitle:"Successfully disapproved item",
                            disapprovereason:"",
                            disapproveopen:false
                        })
                        var parameters = {
                            // startDate:this.state.startDate,
                            // endDate:this.state.endDate,
                            month:this.state.month,
                            year:this.state.year,
                            transporter_code:JSON.parse(transporter_code)
                        }
                        this.onLoadBillingData(parameters)
                       
                    }
                    else
                    {
                        await this.setState({
                            show:true,
                            basicTitle:"Please choose atleast one item",
                            basicType:'info',
                            disapprovereason:"",
                            disapproveopen:false
                        })
                    }
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Please enter reason",
                    basicType:"danger"
                })
            }
            
            
        }
    }
   

    onClickCheckboxItem = async (props) =>{
        console.log(props)
    }
    onPaginationChanged = (param) => {
        //console.log("param ", param)
        this.resetPaginationSelection();
    }
    resetPaginationSelection = (e) =>
    {
        //e.api.deselectAll();

        //Initialize pagination data
        let paginationSize = e.api.paginationGetPageSize();
        let currentPageNum = e.api.paginationGetCurrentPage();
        let totalRowsCount = e.api.getDisplayedRowCount();

        //Calculate current page row indexes
        let currentPageRowStartIndex = (currentPageNum * paginationSize);
        let currentPageRowLastIndex = (currentPageRowStartIndex + paginationSize);
        if(currentPageRowLastIndex > totalRowsCount) currentPageRowLastIndex = (totalRowsCount);

        for(let i = 0; i < totalRowsCount; i++)
        {
            //Set isRowSelectable=true attribute for current page rows, and false for other page rows
            let isWithinCurrentPage = (i >= currentPageRowStartIndex && i < currentPageRowLastIndex);
            e.api.getDisplayedRowAtIndex(i).setRowSelectable(isWithinCurrentPage);
        }

    };

    approveSavedItem(item)
    {
        this.setState({
            apprvopen:true,
            approveitem:item
        })
    }
    approveSavedItemAction(){
        var item = this.state.approveitem      
        var cnf = window.confirm("Are you sure you want to approve item ?")
           // console.log("cnf ", cnf)
        var transporter_code = localStorage.getItem("transportercode");
        if(cnf == true)
        { 
            if(this.state.approveitemreason != "" && this.state.approveitemreason.trim() != "")
            {
                var params = {
                    selecteditems:[item.data],
                    reason:this.state.approveitemreason,
                    month:this.state.month,
                    year:this.state.year,
                    screen:"msil",
                    transporter_code:JSON.parse(transporter_code),
                    email:localStorage.getItem("email"),
                    username:localStorage.getItem("username")
                }
                redirectURL.post("/consignments/tptapproveGPSBillingTrucks", params)
                .then(async (response) => {
                    //console.log(response.data)
                    if(response.data.status == "success")
                    {
                        await this.setState({
                            show:true,
                            basicType:"success",
                            basicTitle:"Successfully approved items",
                            approveitemreason:"",
                            apprvopen:false
                        })
                        var parameters = {
                            // startDate:this.state.startDate,
                            // endDate:this.state.endDate,
                            month:this.state.month,
                            year:this.state.year,
                            transporter_code:JSON.parse(transporter_code)
                        }
                        this.onLoadBillingData(parameters)
                       
                    }
                    else
                    {
                        await this.setState({
                            show:true,
                            basicTitle:"Please choose atleast one item",
                            basicType:'info',
                            approveitemreason:"",
                            apprvopen:false
                        })
                    }
                })
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:"Please enter reason",
                    basicType:'infdanger'
                })
            }
            
        }
    }
    revertMsilApproval(){
        this.setState({
            revertopen:true
        })
    }
    revertMsilApprovalAction(){
        var cnf = window.confirm("Are you sure you want to revert items ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            console.log("Success revert")
        }
    }

    approvalEnmovil(){
        this.setState({
            approveenopen:true
        })
    }
    approvalEnmovilAction(){
        var cnf = window.confirm("Are you sure you want to approve items ?")
           // console.log("cnf ", cnf)
        if(cnf == true)
        { 
            console.log("Success enmovil")
        }
    }

    onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	    this.setState({ 
            open:false,
            revertopen:false,
            approveenopen:false,
            apprvopen:false,
            disapproveopen:false, 
        });
     };
    

	changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    

    
	changeFileHandler = async (e) => {
		var dt = '';
		
		const config = {
			headers: [
				{ 
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                }
                
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
    }
    
    formBulkUpload(event){
        event.preventDefault();
        var records = this.state.csvcontent
        var params = {
            rowitems:records
        }
        console.log("params ",params)
        // redirectURL.post("/consignments/uploadgpsmsilfleet",params)
        // .then((response) => {
        //     console.log("resov ", response.data)
        // })
    }

    // onClickShowTanData(params){
    //     this.setState({
    //         loadshow:"show-m"
    //     })
    //     if(params == "MSIL")
    //     {
    //         this.setState({
    //             rowData:this.state.rowmsilData,
    //             activetab:"btn-success",
    //             activeNtab:"btn-default",
    //             loadshow:"show-n",
    //             atab:"msil"
    //         })
    //     }
    //     if(params == "NonMSIL")
    //     {
    //         this.setState({
    //             rowData:this.state.nonmsilrowData,
    //             activeNtab:"btn-success",
    //             activetab:"btn-default",
    //             loadshow:"show-n",
    //             atab:"nonmsil"
    //         })
    //     }
    // }

    
    onClickShowUpTimeLegs = async (rownode) =>{
        // console.log("rownde ", rownode)
       
        await this.setState({
                
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName:"Total Packet Sent",
                            field:"count",
                            width:160,
                        },
                        {
                            headerName:"Report Date",
                            field:"report_date",
                            width:160,
                            valueGetter:function(params){
                                try{
                                    if(params.data.report_date != "" && params.data.report_date != undefined){
                                        return getHyphenDDMMMYYYYHHMM(params.data.report_date);
                                    }
                                    else
                                    {
                                        return "";
                                    }
                                }
                                catch(e){
                                    return "";
                                }
                            }
                        },
                        
                    ]							
                },
                getDetailRowData: async function(params) {
                //    console.log("params ", params)
                   var sdate = getHyphenYYYYMMDDHHMMSS(params.data.billing_start);
                   var edate = getHyphenYYYYMMDDHHMMSS(params.data.billing_end);
                   var truck_no = params.data.truck_no;
                  
                    var paras = {
                        start_date:sdate,
                        end_date:edate,
                        truck_no:truck_no
                    }
                    redirectURL.post("/consignments/gpsbillinguptimelegs",paras)
                    .then((response) => {
                        console.log("response ", response.data)
                        var records = response.data.records;
                        params.successCallback(records)
                    })
                    .catch(function(e){
            
                    })
                    
                }
            }
        });
        
        if(rownode.column.colDef.field == 'uptime_days')
        {
            rownode.node.setExpanded(!rownode.node.expanded);
        }
        else{

            rownode.node.setExpanded(false);
        }
    
    }
    
    onClickDonwloadUpTimeLegs= async (rownode) =>{
        // console.log("rownde ", rownode)
        this.setState({
            loadshow:"show-m"
        })
        var sdate = getHyphenYYYYMMDDHHMMSS(rownode.data.billing_start);
        var edate = getHyphenYYYYMMDDHHMMSS(rownode.data.billing_end);
        var truck_no = rownode.data.truck_no;
        
        var paras = {
            start_date:sdate,
            end_date:edate,
            truck_no:truck_no
        }
        redirectURL.post("/consignments/gpsbillinguptimelegs",paras)
        .then((response) => {
            // console.log("response ", response.data)
            var records = response.data.records;
            this.setState({
                sliderUptime:"slider-translate-65p",
                uptimeRowData:records,
                overly:"show-m",
                loadshow:"show-n",
                uptimetruckno:truck_no
            })
        })
        .catch(function(e){

        })
                  
    
    }
    
    
    onClickUpTimeReport= async (rownode) =>{
        this.setState({
            loadshow:"show-m"
        })
        // console.log("rownde ", rownode)
        var sdate = getHyphenYYYYMMDDHHMMSS(this.state.billstart);
        var edate = getHyphenYYYYMMDDHHMMSS(this.state.billend);
        var transporter_code = localStorage.getItem("transportercode");
        var paras = {
            start_date:sdate,
            end_date:edate,
            transporter_code:transporter_code
        }
        redirectURL.post("/consignments/tptgpsbillinguptimereport",paras)
        .then((response) => {
            // console.log("response uptime ", response.data)
            var records = response.data.records;
            var recordsarr = []
            
            var mons = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep",
                        "Oct", "Nov", "Dec"]

            var colss = []
            var rowarr = []
            if(records.length > 0)
            {
                records.map((item) => {
                    var rdate = getHyphenDDMMMYYYY(item.report_date);
                    var dhy = getHyphenDDMMYYYY(item.report_date);
                    var dsp = dhy.split("-");
                    var monthno = parseInt(dsp[1])
                    var dateno = parseInt(dsp[0])
                    var yearno = parseInt(dsp[2])
                    var rw = {
                        count: item.count,
                        report_date: item.report_date,
                        transporer_code: item.transporer_code,
                        truck_no: item.truck_no,
                        _id: item._id,
                        month:rdate,
                        monthno:monthno,
                        dateno:dateno,
                        yearno:yearno
                    }
                    recordsarr.push(rw)

                    colss.push({monthname:rdate})
                })
            }
                     
            // console.log("recordsarr ", recordsarr)
            var columns =[{
                headerName:"Truck No",
                field:"truck_no",
                width:120
            }]
            var totalCnt = 0
            if(recordsarr.length > 0)
            {                
                recordsarr.sort(GetAscSortOrder("monthno"));
                var trucks = groupBy(recordsarr, rdata => (rdata.truck_no));
                // console.log("monthno ", monthnos)
                try{
                    trucks.forEach((itm,key) => {
                        itm.sort(GetAscSortOrder("dateno"));
                        var rows = {}
                        rows["truck_no"] = key;
                        var tot = 0
                        if(itm.length > 0)
                        {
                            itm.map((ditm) => {
                                rows[ditm.month] = ditm.count
                                // console.log("ditm.count ", ditm.count)
                                tot = parseInt(tot)+parseInt(ditm.count)                                
                            })
                            
                        }
                        rows["total"] = tot
                        totalCnt=parseInt(totalCnt)+tot;
                        // console.log("totalCnt ", totalCnt)
                        rowarr.push(rows)
                    })
                }
                catch(e){

                }

               
            }
            // console.log("rowarr ", rowarr)
            var newArray=[]
            let totuniqueObject = {}; 
            var totobjTitle;
            // Loop for the array elements 
            for (let i in colss) { 

                // Extract the title 
                totobjTitle = colss[i]['monthname']; 

                // Use the title as the index 
                totuniqueObject[totobjTitle] = colss[i]; 
            } 

            // Loop to push unique object into array 
            for (var a in totuniqueObject) { 
                newArray.push(totuniqueObject[a]); 
            } 
            if(newArray.length > 0)
            {
                newArray.map((mfield) => {
                    columns.push({
                        headerName:mfield.monthname,
                        field:mfield.monthname,
                        width:140
                    })
                })
            }
            columns.push({
                headerName:"Total",
                field:"total",
                width:140
            })
            
            // var pinnedBottomRowData = {}
            // if(rowarr.length > 0)
            // {
            //     var gridkeys = Object.keys(rowarr[0]);
            //     var temp = {};
            //     gridkeys.map(function(e){
            //         temp[e] = 0
            //     })
            // }
            // else
            // {
            //     var gridkeys = [];
            // }
            
            // gridkeys = gridkeys.filter(function(e){return e != "truck_no"})
            // rowarr.map(function(e, index){
            //     gridkeys.map(function(k)
            //     {
            //         var keyval;
            //         if(e[k] == "")
            //         {
            //             keyval = 0;
            //         }else{ keyval = e[k]}
            //         console.log("keyval ", keyval)
            //         if(keyval != undefined)
            //         {
            //             temp[k] = temp[k] + keyval
            //         }
            //         else
            //         {
            //             temp[k] = temp[k] + 0
            //         }
                    
            //     })
            // })
            // temp['Total'] = totalCnt;
            // temp['truck_no'] = "Total"
            // console.log(temp, "pinnedBottomRowData")

            this.setState({
                sliderUptime:"slider-translate-65p",
                uptimeRowData:rowarr,
                overly:"show-m",
                loadshow:"show-n",
                uptimecolumnsDefs:columns,
                // pinnedRegionBottomRowData:[temp],
            })
        })
        .catch(function(e){

        })
                  
    
    }
    render(){
        const { open } = this.state;
        const { revertopen } = this.state;
        const { approveenopen } = this.state;
        const { apprvopen } = this.state;
        const { disapproveopen } = this.state;
       

        var columnwithDefs = [
            { 
                headerName: "Truck No",
                field: "truck_no", 
                width:120,
                resizable: true,
            },
            { 
                headerName: "Transporter Code",
                field: "transporter_code", 
                width:100,
                resizable: true,
            },
            { 
                headerName: "Transporter Name",
                field: "transporter_name", 
                width:150,
                resizable: true,
                // valueGetter:function(params){
                //     try{
                //         if(params.data.registereddevices.length > 0 )
                //         {
                //             return params.data.registereddevices[0].transporter_name;
                //         }
                //         else
                //         {
                //             return "";
                //         }
                //     }
                //     catch(e){
                //         return "";
                //     }
                // }
            },
            { 
                headerName: "Date Of Installation",
                field: "device_registered_on", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.device_registered_on != "" && params.data.device_registered_on != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.device_registered_on);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Voice Box Installed On",
                field: "voicebox_installed_on", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.voicebox_installed_on != "" && params.data.voicebox_installed_on != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.voicebox_installed_on);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Freewheeling Installed On",
                field: "device_registered_on", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.device_registered_on != "" && params.data.device_registered_on != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.device_registered_on);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Billing Start Date",
                field: "billing_start", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.billing_start != "" && params.data.billing_start != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.billing_start);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            
            { 
                headerName: "Billing End Date",
                field: "billing_end", 
                width:150,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.billing_end != "" && params.data.billing_end != undefined )
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.billing_end);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Total Billing Days",
                field: "billing_days", 
                width:90,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.billing_days != "" && params.data.billing_days != undefined )
                        {
                            return params.data.billing_days;
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            { 
                headerName: "Uptime Days",
                field: "uptime_days", 
                width:90,
                resizable: true,
                // valueGetter:function(params){
                //     try{
                //         if(params.data.uptime_days != "" && params.data.uptime_days != undefined )
                //         {
                //             return params.data.uptime_days;
                //         }
                //         else
                //         {
                //             return "";
                //         }
                //     }
                //     catch(e){
                //         return "";
                //     }
                // }
                cellRendererSelector:function(params){
                    if(params.data.uptime_days != "" && params.data.uptime_days != undefined )
                    {
                        if(params.data.uptime_days != 'Total (%)')
                        {
                            var rendComponent = {
                                component: 'Showuptimelegs'
                            };
                            return rendComponent;
                        }
                        else{
                            return "";
                        }
                    }
                    else
                    {
                        return "";
                    }
                }
            },
            { 
                headerName: "Uptime (%)",
                field: "uptime_percentage", 
                width:100,
                resizable: true,
                valueGetter:function(params){
                    try{
                        if(params.data.uptime_percentage != "" && params.data.uptime_percentage != undefined )
                        {
                            return params.data.uptime_percentage.toFixed(2);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            // { 
            //     headerName: "Part Of MSIL Billing",
            //     field: "is_part_msil_fleet", 
            //     width:150,
            //     resizable: true,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.is_part_msil_fleet != "" && params.data.is_part_msil_fleet != undefined )
            //             {
            //                 if(params.data.is_part_msil_fleet != 'total')
            //                 {
            //                     return params.data.is_part_msil_fleet;
            //                 }
            //                 else
            //                 {
            //                     return "";
            //                 }
                                                        
            //             }
            //             else
            //             {
            //                 return "No";
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
            {
                headerName:"Status",
                field:"tpt_billing_status",
                width:100,
                pinned:"right",
                cellRendererSelector:function(params){
                    
                    // var rendComponent = {
                    //     component: 'RevokSaveBilling'
                    // };
                    // return rendComponent

                    if(params.data.tpt_billing_status != "total")
                    {
                        var rendComponent = {
                            component: 'RevokSaveBilling'
                        };
                        return rendComponent;
                    }
                    else
                    {
                        return "";
                    }

                }
            },
                                
        ]
    
        
        
        var uptimecolumnsDefs = [
            {
                headerName:"Truck No",
                field:"truck_no",
                width:140,
            },
            {
                headerName:"Transporter Code",
                field:"transporer_code",
                width:180,
            },
            
            {
                headerName:"Total Packet Sent",
                field:"count",
                width:160,
            },
            {
                headerName:"Report Date",
                field:"report_date",
                width:160,
                valueGetter:function(params){
                    try{
                        if(params.data.report_date != "" && params.data.report_date != undefined){
                            return getHyphenDDMMMYYYYHHMM(params.data.report_date);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
        ]
        
        return(
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>GPS Billing (MSIL Billing) - {this.state.billmonthtext} - {this.state.year}</span>
                                    <a href="/tptlogin/tptgpsbillingdashboard" className={"btn btn-primary float-right f12 "}> Back</a>
                                    <button type="button" onClick={this.onClickUpTimeReport.bind(this)} className={"btn btn-warning float-right f12 "}> Download Uptime Report</button>
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                               <div className="row">
                                    {/* <button type="button" onClick={this.onClickShowTanData.bind(this,'MSIL')} className={"btn "+(this.state.activetab)}>MSIL</button>
                                    <button type="button" onClick={this.onClickShowTanData.bind(this,'NonMSIL')} className={"btn "+(this.state.activeNtab)}>Non MSIL</button> */}
                                     <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            frameworkComponents={this.state.frameworkComponents}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            stopEditingWhenGridLosesFocus={true}
                                            enableCellChangeFlash={false}
                                            rowClassRules={this.state.rowClassRules}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            masterDetail={true}
                                            rowSelection={this.state.rowSelection}
                                            suppressRowClickSelection={true}
                                            onPaginationChanged={this.resetPaginationSelection}
                                            headerHeight={this.state.headerHeight}  
                                            pinnedBottomRowData={this.state.tottalDistance}                                        
                                        />
                                    </div> 
                                    
                                    <div className="col-xl-12 col-lg-12" style={{color:"#ff0000"}}>
                                        Note: Billing will be auto approved 7 calender days from the date of MSIL Approval, in case the transporter does not respond.
                                    </div>
                                    <div className="col-xl-4 col-lg-4">
                                        {/* {(this.state.approveby != "" || this.state.approvedon != "")?
                                            <span>
                                                Approved By: <span style={{fontWeight:"600"}}>{this.state.approveby}</span>
                                                <br />Approved On: <span style={{fontWeight:"600"}}>{getHyphenDDMMMYYYYHHMM(this.state.approvedon)}</span>
                                            </span>
                                        :""} */}
                                        
                                    </div>

                                    <div className="col-xl-8 col-lg-8" style={{textAlign:"right"}}>
                                        {/* <span>
                                            <button type="button" className="btn btn-danger" onClick={this.approvalEnmovil.bind(this)}>Approve (Enmovil)</button>
                                            <button type="button" className="btn btn-warning" onClick={this.revertMsilApproval.bind(this)}>Revert MSIL Approval</button>
                                        </span> */}
                                    
                                        <button type="button" className="btn btn-success" onClick={this.approveAllTrucks.bind(this)}>Approve All</button>
                                        
                                    </div>
                                    
                                       
                                </div>

                                <div className="row mt-20p">
                                    <h5>Billing Log Report:</h5>
                                   <BillingLogs 
                                        dept_code={this.state.dept_code}
                                        screen={this.state.screen}
                                   />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box">
                        <div className="loader-box">
                            <div className="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
				<Modal open={open} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
                        <div className="col-xl-11 col-lg-11">
                            <label>Update By:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updateby" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Contact Number:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatecontactno" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="reason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approveAllTrucksAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={revertopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
                    <div className="col-xl-11 col-lg-11">
                            <label>Update By:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatebymsil" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Contact Number:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatecontactnomsil" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="revertreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.revertMsilApprovalAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={approveenopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
                        <div className="col-xl-11 col-lg-11">
                            <label>Update By:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatebyenmovil" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Contact Number:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatecontactnoenmovil" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="approveenreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approvalEnmovilAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={apprvopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
                        <div className="col-xl-11 col-lg-11">
                            <label>Update By:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatebysaved" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Contact Number:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatecontactnosaved" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="approveitemreason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.approveSavedItemAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>
				<Modal open={disapproveopen} onClose={this.onCloseModal}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
                        <div className="col-xl-11 col-lg-11">
                            <label>Update By:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatebyrevert" onChange={this.changeHandler.bind(this)} required />
                        </div>
                        <div className="col-xl-11 col-lg-11">
                            <label>Contact Number:</label>
                            <input type="text" className="col-xl-12 col-lg-12" name="updatecontactnorevert" onChange={this.changeHandler.bind(this)} required />
                        </div><div className="col-xl-11 col-lg-11">
                            <label>Reason:</label>
                            <textarea className="col-xl-12 col-lg-12" name="disapprovereason" onChange={this.changeHandler.bind(this)} rows="5"></textarea>
                            <button className="btn btn-success" onClick={this.revertSavedItemAction.bind(this)}>Submit</button>
                        </div>
					</div>
				</Modal>

                

                <div className={"slide-r "+(this.state.sliderUptime)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Uptime - {this.state.billmonthtext} - {this.state.year}
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                            <div className="col-xl-12 col-lg-12">
                                <div id="myGrid1" style={{ height: "90vh",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.uptimecolumnsDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.uptimeRowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        stopEditingWhenGridLosesFocus={true}
                                        enableCellChangeFlash={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        masterDetail={true}
                                        rowSelection={this.state.rowSelection}
                                        suppressRowClickSelection={true}
                                        onPaginationChanged={this.resetPaginationSelection}   
                                        headerHeight={this.state.headerHeight}   
                                        pinnedBottomRowData={this.state.pinnedRegionBottomRowData}                                      
                                    />
                                </div> 
                            </div>
                        </div>
					</div>
				</div>

            </div>
    )}       
} 

function GetAscSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function GetSortOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
}

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}