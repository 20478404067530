import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import Select from 'react-select';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';

import CountUp from 'react-countup';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

 export default class LoadingYardReport extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode : "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderCommentTranslate : "",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			sliderSegmentTranslate : "",
			mapinfo:'',
			commentsRowData:[],
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{},
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				pivot : true,
				enableValue: true,
    			enableRowGroup: true,
      	    },
      	    rowData: null,
			maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
      loadingyard:{value:"",label:"All"},
      totalvins:0,
      atloading:0,
      atloadingarr:[],
      unloading:0,
      unloadingarr:[],
      loadingyards:[]
		}
    this.onLoadData = this.onLoadData.bind(this);

	}

	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
    var prms = {
			transporter_code:localStorage.getItem("transportercode")
		}
		redirectURL.post("/dashboard/tptloaddestpoints",prms)
		.then((response) => {
			var records = response.data.records;
			var loadings = [];
			var destinatinos = [];
			if(records.length > 0)
			{
				records.map((item) => {
					if(item.rail_loading_point == 1)
					{
						loadings.push(item)
					}
					if(item.rail_delivery_point == 1)
					{
						destinatinos.push(item)
					}
				})
			}
			this.setState({
				loadingyards:loadings
			});
		})

      this.onLoadData()
	};
  onLoadData(){
    this.setState({
      loadshow:"show-m"
    })
    var parameters={
      loadingyard:this.state.loadingyard.value,
      transporter_code:localStorage.getItem("transportercode")
    }
    redirectURL.post("/dashboard/loadingstockyarddata",parameters)
    .then((response) => {
      var records = response.data.records;
      var totalvins = [];
      var atloading=[];
      var unloading=[];

      if(records.length > 0)
      {
        records.map((item) =>{
          if(item.status == 3)
          {
            atloading.push(item);
          }
          if(item.status == 14)
          {
            unloading.push(item);
          }
          totalvins.push(item);

        })
      }
      this.setState({
        rowData:records,
        loadshow:"show-n",
        totalvins:totalvins.length,
        atloading:atloading.length,
        atloadingarr:atloading,
        unloading:unloading.length,
        unloadingarr:unloading
      })
    })
  }


	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n',
			slideuploadeway:""
		});
	}

	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}



	onGridReady = params => {
		this.gridApi = params.api;

	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;



	};


	onGridState = () =>{
		 //console.log(this.gridApi);

		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns,
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();

		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);



	}


	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:""
		});

	}

	showGoogleRouteAlert = () => {
        this.setState({
			show: true,
			basicTitle:"No google routes found",
			basicType:"danger"
        });
	}

	closeAlert = () => {
        this.setState({
            show: false
        });
	}

  selectsources(){
    let items = [];
    items.push({"value":"","label":"All"})
    try{
      if(this.state.loadingyards.length > 0)
      {
        this.state.loadingyards.map((item) =>{
          items.push({"value":item.geofence_name,"label":item.geofence_name})
        })
      }
    }
    catch(e){

    }
    return items;
  }
  onChangeSourceItem(loadingyard){
  this.setState(
    { loadingyard },
    () => console.log(`Option selected:`, this.state.loadingyard)
        );


    this.setState({
      errorMessage:'',
      successMessage:''
    })
  }
	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n',
			slideuploadeway:""
		});
	}
  onSubmitGetFilter(event){
    event.preventDefault();
    this.onLoadData();
  }
	render(){

		const columnwithDefs =
		[
          {
              headerName: "VIN No",
              field: "vehicle_id",
              width: 180,
              filter: false,
              resizable: true
          },
          {
              headerName: "Rail No",
              field: "rail_no",
              width: 140,
              filter: false,
              resizable: true
          },

          {
              headerName: "Loading Yard",
              field: "loadingyard",
              width: 160,
              filter: false,
              resizable: true
          },
          {
              headerName: "Status",
              field: "status",
              width: 160,
              filter: false,
              resizable: true,
              valueGetter:function(params){
                if(params.data.status == 3)
                {
                  return "At Rail Loading Yard";
                }
                if(params.data.status == 14)
                {
                  return "Unloading At Rail Yard";
                }
              }
          },

		];

		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}

				 <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                    <div className="card-header">
  				       			<h5>
  								         <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Loading Stock Report</span>
									    </h5>
   			            </div>
                    <div className="col-sm-12">
                      <form method="POST" className="row theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitGetFilter.bind(this)}>

                            <div className="col-xl-3 col-lg-3 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Loading Yard *</label>

                                <Select
                                placeholder={"Select Loading Yard"}
                                closeMenuOnSelect={true}
                                onChange={this.onChangeSourceItem.bind(this)}
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.sourceopt}
                                options={this.selectsources()} />
                            </div>

                            <div className="col-xl-3 col-lg-3 row form-group">
                              <label>&nbsp;</label>
                                <button type="submit" style={{height:"35px",marginTop:"30px"}} className="btn btn-success">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect pl-0">
          						<div className="card">
          							<div className="card-body" id="counterCardBody">
          								<div className="crm-numbers pb-0">
          									<div className="row">
          										<div className="col cirlce-d cursorPointer" >
          											<span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total Vins<br /></span>
          											<h4 className="txt-info f40">
                                  <span className="counter">
                                    <CountUp end={this.state.totalvins}/>
                                  </span>
                                </h4>
          										</div>

                              <div className="col cirlce-d cursorPointer" >
          											<span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> At Rail Loading Yard<br /></span>
          											<h4 className="txt-success f40">
                                  <span className="counter">
                                    <CountUp end={this.state.atloading}/>
                                  </span>
                                </h4>
          										</div>
                              <div className="col cirlce-d cursorPointer" >
                                <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Unloading At Rail Yard<br /></span>
                                <h4 className="txt-danger f40">
                                  <span className="counter">
                                    <CountUp end={this.state.unloading}/>
                                  </span>
                                </h4>
                              </div>

									          </div>
                          </div>
                        </div>
                      </div>
                    </div>
		            		<div className="card-body pt-10px" >

                      <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
			                        rowData={this.state.rowData}
									            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
            									sideBar={this.state.sideBar}
            									paginationPageSize={this.state.paginationPageSize}
            									pagination={true}
            									frameworkComponents={this.state.frameworkComponents}
            									detailCellRendererParams={this.state.detailCellRendererParams}
            									stopEditingWhenGridLosesFocus={true}
            									enableCellChangeFlash={false}
            									rowClassRules={this.state.rowClassRules}
            									gridOptions={{
            										context: { componentParent: this }
            									}}
            									masterDetail={true}
            									rowSelection={this.state.rowSelection}
            									suppressRowClickSelection={true}
            									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
            									excelStyles= {this.state.excelStyles}
		          		          />

		          		        </div>
				   			     </div>
				   		</div>
				   	</div>

				 </div>
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

			</div>

		);
	}
}



function GetSortDescOrder(prop) {
	return function(a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function(a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}
