import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ExportPDF from "./exportpdf"
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');
 
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
 
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
var containerslist=[];
export default class TPTTrips extends Component {

	constructor(props){
		super(props);
		this.displayData=[];
		this.state={
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode : "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			sliderCommentTranslate : "",
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			sliderSegmentTranslate : "",
			mapinfo:'',
			commentsRowData:[],
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{}, 
			sidebarSubHeader:'',
			googelRoutes:'',
			plants:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				pivot : true,
				enableValue: true,
    			enableRowGroup: true,
      	    },
      	    rowData: null,
			maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
				consignmentActions:ConsignmentActions,
            },

      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype:{"value":'all', "label":'All'},
			tripvalue:'',	
			consigner:[{"value":'all', "label":'All'}],
			originalData:[],
			isCounterDisplay:1,
			screenpage:'',
			//pagetitle:'Ashok Leyland & Jawa Bikes Trips',
			pagetitle:'Jawa Bikes Trips',
			countersjson:'',
			originalcountersjson:'',
			consigneecoords:'',
			consigner_coordinates:'',
			containerslist:[],
			forceclosedata:'',
			reached_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			reachedhh:'',
			reachedmm:'',
			reachedss:'00',
			left_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			lefthh:'',
			leftmm:'',
			leftss:'00',
			invoice_time:'',
			invoice_no:'',
			invoicehh:'',
			invoicemm:'',
			segementConsignmentCode : "",
			counter_screen : "",
			invoicess:'00',
			reasonforceclose:'',
			sliderTranslatesidebar:'',
			consignment_details:'',
			startDatetime:'',
			endDatetime:'',
			tolls : [],
            selectedTrip: "ALL",
            leyland:"show-m",
            jawa:"show-n",
            leylandcl:"btn-danger",
            jawacl:"",
			excelStyles : [
				{
					id : "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function(params) {
				if(typeof params.data.dct_comment != 'undefined'){
					//console.log("pars ", params.data.dct_comment.length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if(typeof params.data.vin_list != 'undefined'){
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.vin_list.toString().length/ 50))
					);
				}
				else if(typeof params.data.force_closure_reason != 'undefined'){
					//console.log("pars ", params.data.dct_comment.length)
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else{
					return 30;
				}
			  },
			  defaultsdate:'',
			  defaultedate:'',
			  movementtype:{"value":"all","label":"All"},
			  usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  showumncr:0,
			  showummove:0,
			  usermanualncrmodal:false,
			  usermanualmovemodal:false,
			  slideuploadeway:"",
			  cancelled_data: [],
			  pod_received_data: [],
              trip_closed_by_pod: [],
			  transporterTrucks:[],
			  tpttrucks:[],
			  adaniCheck:0,
			  addressColumn: 300,
			  pdfRowData: [],
			  pdfColumns: [],
			  tripDate:"",
			  bulkslide:"",
              pageType:1, 
			  selectedTruck:{"label":"",value:""},
			  //markbtn:"Mark Ashok Leyland & Jawa Trips",
			  markbtn:"Jawa Trips",
              allRecords :[]
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.uploadTafeTrip = this.uploadTafeTrip.bind(this);
		this.onCellClicked = this.onCellClicked.bind(this);
	}

	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	async componentDidMount(){
		loadDateTimeScript();
		// console.log("tafe trips");
		var pageType = 2;
		//var pagetitle = "Ashok Leyland & Jawa Bikes Trips";
		var pagetitle = "Jawa Bikes Trips";
		if(localStorage.getItem("email") == "ivc.ashokleyland@entrac.in")
		{
			pageType = 1;
			pagetitle = "Ashok Leyland Trips";
		}
		else if(localStorage.getItem("email") == "ivc.jawa@entrac.in" || localStorage.getItem("email") == "rajkamalsingh@ivclogistics.com")
		{
			pageType = 2;
			pagetitle = "Jawa Trips";
		}
		else
		{
			pageType = 2;
		}
		await this.setState({
			pageType : pageType,
			pagetitle : pagetitle,
			selectedTrip : pageType,
		});
		$("#selectedTrip").val(pageType)
		await this.onLoadGetConsignmentsList();
	};
	

	onLoadGetConsignmentsList()
	{
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		})
		// console.log(parameters);
		// console.log("urlpath ", urlpath)
		var deviceparams = {
			transporter_code:localStorage.getItem("transportercode"),
		}
		redirectURL.post("/dashboard/getLeyLandJawaTrips", deviceparams)
		  .then(async (response) => {
            var records = response.data;
            var pageType = this.state.pageType;
            console.log("records-all", records);
            var filteredRecords = records.filter(e => e.trip_type == pageType);
            console.log("records-filtered", filteredRecords);
			this.setState({
                allRecords : records,
				rowData : filteredRecords,
				loadshow:'show-n',
				overly:'show-n'
			});
		});
		redirectURL.post("/dashboard/tpttrucks", deviceparams).then((response1)=>{
			var trucks = response1.data.records;
			var transporterTrucks = [];
			trucks.map(function(tr){
				transporterTrucks.push({
					"label" : tr.truck_no,
					"value" : tr.truck_no,
				})
			});
			this.setState({
				transporterTrucks : transporterTrucks
			});		   
		});
	}

	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow:'show-m'
		});
		var reqData = {
            consignment_code:e.consignment_code
        }
        //console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("records", records);
		
			this.setState({
				uploadDivWidth:'30%',
				sliderTranslate:"slider-translate-50p",
				showDiv:'show-m',
				timelinedata:records,
				loadshow:'show-n',
				overly:'show-m'
			});
		})
		.catch(function (error) {
		console.log(error);
		});
		
	}
	
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n',
			slideuploadeway:""
		});
	}
    
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		
	};	

	onGridState = () =>{
		//console.log(this.gridApi);
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);		   
	}

	
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			slideuploadeway:'',
			sliderRouteTranslate:'',
			sliderSegmentTranslate : "",
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderCommentTranslate:"",
			bulkslide:"",
			selectedTruck:{"label":"",value:""},
		});
		
	}

	showGoogleRouteAlert = () => {
        this.setState({
			show: true,
			basicTitle:"No google routes found",
			basicType:"danger"
        });
	}

	closeAlert = () => {
        this.setState({
            show: false
        });
	}
	onClickCounterShowDataTEST(params){
		console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
	}

    
	onSelectTrip= triptype =>{
		this.setState(
			{ triptype },
			() => console.log(`Trip Option selected:`, this.state.triptype)
		  );
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	      () => console.log(`Consigner Option selected:`, this.state.consigner)
        );
        
        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			() => console.log(`Movement Option selected:`, this.state.movementtype)
		);
		
	}
	selectConsignerOptionsItems(){
        let items = [];     
		//console.log(this.state.trucks);
		var consigners=[];
		if(this.state.deptcode == 'SNDG')
		{
			var keys = ["B", "F", "G", "I","M", "N"]; 
      
			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-PRT')
		{
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
      
			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}


		if(this.state.deptcode == 'LOG-TNP')
		{
			var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
      
			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
			
			// Object created 
			var obj = {}; 
			
			// Using loop to insert key 
			// value in Object 
			for(var i = 0; i < keys.length; i++){ 
				consigners.push({"code":keys[i],"value":values[i]}); 
			} 
		}

		//console.log("consigners ", consigners)
		items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
       
        return items;
	}
	
	selectMovementTypeOptionsItems(){
        let items = [];     
		items.push({value:"all",label:"All"});
		items.push({value:true,label:"Local Movement"});
		items.push({value:false,label:"Non Local Movement"});
		return items;
	}


	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}
	onRowSelection(event){
		var containerslist=[];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
			//var containerlist=[];
			console.log("rowselect", event.data)
			var rowCount =  event.data
			//console.log("rowCount ", rowCount)
			
			rwCount.map((item) => {
				containerslist.push(item.data.truck_no)
			});
			
			this.setState({
				containerslist:containerslist
			})
			 console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }
		
	}
	onClickShowMapView = async()=>{
		//this.gridApi.deselectAll();
		if(this.state.containerslist.length > 0)
		{
			var reqparms = {
				truck_no:this.state.containerslist,
				dept_code:this.state.deptcode
			}
		}
		else{
			var reqparms = {
				truck_no:[],
				dept_code:this.state.deptcode
			}
		}
		
		await redirectURL.post("/consignments/mapview",reqparms)
		.then((response) => {
			//console.log("Res ",response.data.message)
			var records = response.data.message;
			
			 var truck = JSON.parse(records.truck_info);
			 if(truck.length > 0)
			 {
				 if( truck.length > 1)
				{
					var consinees = this.state.plants;
				}
				else{
					var consinees = records.consignee_coordinates;
				}
			 }
			 else{
				var consinees = [];
			}
			 console.log("truckinfo,",truck)
			 //console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
			this.setState({
				open:true,
				maprowData:truck,
				rownode:truck,
				maptruckno:records.truck_no,
				consigneecoords:consinees,
				consigner_coordinates:records.consigner_coordinates
			});
			
			
			//containerslist=[];
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
	 };
	 changeSegmentHandler=(e)=>{
		//  alert(e.consignment_code);
		this.setState({
			sliderSegmentTranslate : "slider-translate-60p",
			segementConsignmentCode : e.consignment_code,
			overly : "show-m"
		})
	}

	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	 };
	
	 onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ openforceclose: false });
	 };

	 

	 
	 onClickForceClose = (params) =>{
		 //console.log("Pareas ", params)
		 this.setState({
			 forceclosedata:params.data,
			 forceCloseRowNode : params,
			 sliderForceCloseTranslate:"slider-translate-40p",
			 overly:'show-m',
			 reasonforceclose:""
		 })
	 }
	 handleInput(val) {
		this.setState({ reasonforceclose: val[1],selectedReason : val[0] });
		// console.log(val)
	  }
	 changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    	}
	
    onCloseUserManualModal = () => {
        this.setState({ usermanualncrmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualncrmodal: true });
    };

    onCloseUserManualMoveModal = () => {
        this.setState({ usermanualmovemodal: false });
    };

    onClickShowUserMoveManaul = () => {
        this.setState({ usermanualmovemodal: true });
	};
	
	onShowUploadEWay()
	{
		this.setState({
			slideuploadeway:"slider-translate",
			overly:'show-m'
		})
	}

	onClickMarkTafe(){
		this.setState({
			overly :"show-m",
			bulkslide : "slider-translate",
			selectedTruck:{"label":"",value:""}
		})
	}
    
	resetUpload = () => {
		this.setState({
			slideuploadeway:'',
			overly:'show-n',
			file:'',
			bulkslide:'',
		});
	}
	
    onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		console.log("IN onShowRouteDiv, /Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});

		var gateouttime = moment.parseZone(e.trip_start_date).format('YYYY-MM-DD HH:mm:ss');
		var end_date = moment.parseZone(new Date()).format('YYYY-MM-DD HH:mm:ss');
		
		var reqData = {
            truck_no:e.truck_no,
			gate_out_time:gateouttime,
			seconddate:end_date,
			screen:"consignment"
        }
    console.log("reqData", reqData);
		redirectURL.post('/consignments/maproutesForTafe',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			console.log("Props data ", records)

			this.setState({
				loadshow:'show-m'
			});
			try{
				if(records.status == "failure" || records.coords.length == 0)
				{
                    //console.log("records", records.coords);
                    if(records.status != undefined)
                    {
                        this.setState({
                            show: true,
                            basicTitle:'No Route data available',
                            basicType:"danger",
                            loadshow:'show-n'
                        });
                    }
					
				}
				else{
					if(records != '')
					{
						var sdate = e.gate_out_time;
						if(e.invoice_time)
						{
							console.log("came into invoice time", e.invoice_time)
							//console.log("Step1", e.invoice_time)
							var sdate = e.invoice_time;
						}
						else{
							//console.log("Step2")
							if(e.gate_in_time)
							{
								console.log("came into gate in time", e.gate_in_time)
								//console.log("Step3")
								var sdate = e.gate_in_time;
							}
							else{
								//console.log("Step4")
								if(e.gate_out_time){
									//console.log("Step5")
									console.log("came into gate out time", e.gate_out_time)
									var sdate = e.gate_out_time;
								}
							}
						}


						var edate ='';
						if(e.status <= 4)
						{
							//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
							//var edatesecond = new Date(ms);
							end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
							edate = getHyphenDDMMYYYYHHMMSS(end_date);
						}
						else if(e.status == 5)
						{
							if (e.left_dealer_marked)
							{
								edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
							}
							else{
								end_date = moment.parseZone().format('DD-MM-YYYY HH:mm:ss')
								// edate = getHyphenDDMMYYYYHHMMSS(end_date);
							}
						}
						//console.log("End Date ", edate)
						this.setState({
							sliderRouteTranslate:"slider-translate-60p",
							showDiv:'show-m',
							mapinfo:records,
							dealer:e.consignee_code,
							consignment_code:"Truck No : "+e.truck_no,
							maptruckno:e.truck_no,
							routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": end_date},
							loadshow:'show-n',
							sidebarSubHeader:"Consignment Information",
							overly:'show-m',
							rownode:e,
							leg_no:0,
							tolls : records.tolls
						});
						this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
						this.renderMap();

					}
				}
			}
			catch(e){
				// this.setState({
				// 	show: true,
				// 	basicTitle:'No Route data available',
				// 	basicType:"danger",
				// 	loadshow:'show-n'
				// });
			}
			
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderCommentTranslate : "",
			sliderSegmentTranslate : "",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n'
		});
	}
    onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			sliderCommentTranslate:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n',
			slideuploadeway:""
		});
	}
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Hero Trips';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	setTripDate = (event) =>{
        let name = event.target.name;
        let value = event.target.value;
        // console.log(value);
        console.log(value)
        this.setState({tripDate:value});
    }

	onChangeTruckItem(selectedTruck){
		this.setState(
			{ selectedTruck },
			() => console.log(`Truck Option selected:`, this.state.selectedTruck)
		);    
	}
	uploadTafeTrip(event){
		event.preventDefault();
		var trip_type = this.state.pageType;
		var trip_start_date = $("#trip_start_date").val();
		var trip_no = $("#trip_no").val();
		var dealer_code = $("#dealer_code").val();
		var dealer_name = $("#dealer_name").val();
		var truckNo = this.state.selectedTruck;
		var truck_no = "";
		if(truckNo !=undefined && truckNo !='')
		{
			truck_no = truckNo.value;
		}
		if(trip_start_date !='' && trip_start_date !=undefined && truck_no !=undefined && truck_no !='' && trip_no !=undefined && trip_no !='' && dealer_code !=undefined && dealer_code !='' && dealer_name !=undefined && dealer_name !='')
		{
			var reqParams = {
				truck_no : truck_no,
                trip_type : trip_type,
				trip_start_date : trip_start_date,
				trip_no : trip_no,
				dealer_code : dealer_code,
				dealer_name : dealer_name,
				userid : localStorage.getItem("userid"),
			}
			reqParams['user_name'] = localStorage.getItem('username');
			reqParams['email'] = localStorage.getItem('email');
			reqParams['transporter_code'] = localStorage.getItem('transportercode');
			console.log("reqParams",reqParams)
			redirectURL.post("/dashboard/markLeyLandJawaTrips",reqParams).then((response)=>{
				var resp=response.data.message;
				console.log(resp)
				document.getElementById("upform").reset();
				if(resp == "Success")
				{
					this.setState({
						basicTitle : resp,
						basicType : "success",
						show : true,
						bulkslide:'',
						overly:'show-n',
						selectedTruck:{"label":"",value:""},
                        //selectedTrip:{"label":"",value:""},
					})
				}
				else
				{
					this.setState({
						basicTitle : resp,
						basicType : "warning",
						show : true,
						selectedTruck:{"label":"",value:""},
                        //selectedTrip:{"label":"",value:""},
					})
				}            
				this.onLoadGetConsignmentsList();
			}).catch((e)=>{
				console.log(e);
			})
		}
		else
		{
			this.setState({
				basicTitle : "Fields Should not be Empty",
				basicType : "warning",
				show : true,
			})
		}
	};

	

	onCellClicked(e)
	{
		if(e.colDef.field == "delete")
		{
			var rowId = e.data._id;
			console.log(e.data._id);
			var qry = {
				rowId:rowId,
				user_name: localStorage.getItem('username'),
				email: localStorage.getItem('email'),
			}
			//var confirm = confirm("Are You Sure! Do you want really delete?");
			if (window.confirm("Are You Sure! Do you want really delete?"))
			{
				redirectURL.post("/dashboard/deleteLeyLandJawaTrips",qry).then((response)=>{
					console.log(response.data);
					if(response.data.message == "Success")
					{
						this.setState({
							basicTitle:"Deleted Successfully.",
							basicType : "success",
							show : true
						})
						//window.location.reload();
						this.onLoadGetConsignmentsList();
					}
					else
					{
						this.setState({
							basicTitle:"Failed to Delete.",
							basicType : "warning",
							show : true
						})
					}
				}).catch((e)=>{
					console.log(e);
				})
			}        
		}
	}
    onClickTab(params)
    {
        if(params == 1){
            this.setState({
                pageType:params,
				selectedTrip : params,
                leyland:"show-m",
                jawa:"show-n",
                leylandcl:"btn-danger", 
                jawacl:""
            })
        }
        if(params == 2){
            this.setState({
                pageType:params,
				selectedTrip : params,
                leyland:"show-n",
                jawa:"show-m",
                leylandcl:"",
                jawacl:"btn-danger"
            })
        }
		$("#selectedTrip").val(params)
        var allRecords = this.state.allRecords;
        var filteredRecords = allRecords.filter(e => e.trip_type == params);
        this.setState({
            rowData : filteredRecords
        });
    }
	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		const { open } = this.state;
		const { openforceclose } = this.state;
		var maptransporter='';
		try{
			if(this.state.rownode.transporter)
			{
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter=this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch(e){
			console.log(e)
		}
		var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
		const columnwithDefs = 
		[
            {
                headerName: "",
                field: "_id",
                colId: "_id",
                width: 50,
                // pinned: 'left',
                cellRenderer:'consignmentActions',
                filter: false,resizable: true
                
            },
            {
                headerName: "Trip Type",
                field: "trip_type",
                width: 140,
                // pinned : "left",
                filter: "agSetColumnFilter",
                resizable: true,
                valueGetter: function(params){
                    if(params.data.trip_type == 1)
                    {
                        return "Ashok Leyland";
                    }
                    else{
                        return "Jawa Bikes";
                    }
                }
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 140,
                // pinned : "left",
                filter: "agSetColumnFilter",
                resizable: true
            },
			{
                headerName: "Trip Start Date",
                field: "trip_start_date",
                width: 150,
                //filter: "agDateColumnFilter",
                cellRenderer:'',resizable: true,
                comparator: dateComparator,
                
                valueGetter:function(params){
                        if(params.data.trip_start_date != '' && params.data.trip_start_date != undefined)
                        {
                            return getHyphenDDMMMYYYYHHMM(params.data.trip_start_date);
                        }
                        else{
                            return "NA";
                        }
                },	          
            },
			{
                headerName: "Trip No",
                field: "trip_no",
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer:''	,resizable: true    	          
            },
			{
                headerName: "Dealer Code",
                field: "dealer_code",
                width: 150,                 
                filter: "agSetColumnFilter",
                cellRenderer:'',resizable: true	    	          
            }, 
			{
                headerName: "Dealer Name",
                field: "dealer_name",
                width: 150,            
                filter: "agSetColumnFilter",
                cellRenderer:'',resizable: true	    	          
            }           
		];
		if(localStorage.getItem("is_sub_user") == 1)
		{

		}
		else
		{
			columnwithDefs.push(
				{
					pinned:"left",
					headerName : "",
					field:"delete",
					width:40,
					resizable : true,
					editable : false,
					cellRenderer:function(params){       
						return '<i class="icofont icofont-ui-delete"></i>';
					},
				}
			);
		}
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	}

		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				
				 <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
									{(localStorage.getItem("is_sub_user")) == 1 ? "":
									<span className="layoutbtns pull-right">
										<button className="float-right custom-btn white btn-danger"
                                         style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px",cursor: "pointer"}} onClick={this.onClickMarkTafe.bind(this)}>
											{this.state.markbtn}
										</button>
                                    </span>
									}
								</h5>
				   			</div>				   			
		            		<div className="card-body pt-10px" >
								{/* {(localStorage.getItem("email") !="ivc.ashokleyland@entrac.in" && localStorage.getItem("email") !="ivc.jawa@entrac.in" && localStorage.getItem("email") !="rajkamalsingh@ivclogistics.com") ?
                                <div className='row col-xl-12 col-lg-12 col-md-12'>
                                    <div className='col-xl-12 col-lg-12 col-md-12 mb-40p'>
                                        <button className = {"float-left btn " + this.state.leylandcl} 
                                        onClick={this.onClickTab.bind(this,1)}> 
                                            Ashok Leyland
                                        </button>
                                        <button className = {"float-left btn " + this.state.jawacl} 
                                        onClick={this.onClickTab.bind(this,2)}>
                                            Jawa
                                        </button>
                                    </div>
                                </div>
                                :""} */}
								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									//floatingFilter={true}
									
									enableCellChangeFlash={false}
									//suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
												    
									rowSelection={this.state.rowSelection}
									onRowSelected={this.onRowSelection.bind(this)}
									suppressRowClickSelection={true}
    								// suppressCellSelection= {true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									excelStyles= {this.state.excelStyles}
									onCellClicked={this.onCellClicked}
		          		          />
									
									{/* 
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

		          		        </div>
				   			</div>
				   		</div>	
				   	</div>
					   
				 </div>
				 
				
				
				
                
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap context={this} rownode={this.state.rownode} mapFor={"consignment"} tolls={this.state.tolls} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} adaniCheck={this.state.adaniCheck}/>
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">{(this.state.adaniCheck == 1) ? "Shuttle"  : "Truck"} No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>
											
											:""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.mapinfo.route_details.start_time != '')?getHyphenDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.mapinfo.route_details.end_time != '')?getHyphenDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance_without_google/1000):"0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance/1000)} Kms</div>
											</div>
											
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}
											
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
										
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				:""	
				}
				
				<div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
					<h3 className="subH">{this.state.markbtn}</h3>
					<div className="slide-r-body" style={{position:"relative"}}>
						
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadTafeTrip}>
							
                                {/* <div className="form-group">
									<select name="selectedTrip" id="selectedTrip" className="form-control" required>
										<option value="">Select Trip Type</option>
										<option value="1">Ashok Leyland</option>
										<option value="2">Jawa Bikes</option>
									</select>   
								</div> */}

								<div className="form-group">
									<label className="">Truck</label>
									<Select 
									placeholder={"Select Truck"}
									closeMenuOnSelect={true}
									onChange={this.onChangeTruckItem.bind(this)} 
									className={"border-radius-0"}
									style={{borderRadius:"0px"}}
									value={this.state.selectedTruck}
									options={this.state.transporterTrucks} />  
								</div>
								
								<div className="form-group mt-20p">
									<label>Trip Start Date</label>
									<input type="text" placeholder="Trip Start Date" name="trip_start_date" id="trip_start_date" className="datetimepicker_mask form-control" required />  
								</div>
								
								<div className="form-group mt-20p">
									<label>Trip No's</label>
									<input type="text" name="trip_no" id="trip_no" placeholder="Trip No's" className="form-control" required />
									<p style={{color:"red"}}>*Enter multiple trip numbers with comma seperated</p>
								</div>
								
								<div className="form-group mt-20p">
									<label>Dealer Code</label>
									<input type="text" name="dealer_code" id="dealer_code" placeholder="Dealer Code" className="form-control" required />
								</div>
								
								<div className="form-group mt-20p">
									<label>Dealer Name</label>
									<input type="text" name="dealer_name" id="dealer_name" placeholder="Dealer Name" className="form-control" required />
								</div>

								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>                   
						</div>
					</div>
				</div>
			</div>              	
		);
	}
}



function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}                 
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
	

	return diffHrs + " hour(s) and " + (diffMins/60) + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}

function onSetCritical(params, status){
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status,
		token:localStorage.getItem("token"),
		userId:localStorage.getItem("userid")
	})
		.then((response) => {
		
		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});
		
}

function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag(){
	var rs = document.getElementById("invalid_reason");
	var reg =/<(.|\n)*?>/g; 
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";		
		rs.value=''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		onShow:false
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y H:i',
		timepicker:true,
		onShow:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

