/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import {
    getDDMMYYYY, getDDMMMYYYY, getHyphenDDMMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import ShowTPTGpsMap from "./showtptgpsmap";
import ShowTPTGpsBreaks from "./showtptgpsbreaks";
import RoutemapAction from "./gpsroutemaplegs";
import ExportPDF from "./exportpdf"
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class TPTGpsEodAnalysis extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-n',
            overly: "show-n",
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            rowData: [],
            pdfRowData: [],
            pdfColumns: [],
            frameworkComponents: {
                showtptgpsmap: ShowTPTGpsMap,
                showtptgpsbreaks: ShowTPTGpsBreaks
            },
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            rowClassRules: {
                "boxbgblue": "data.to_be_approved == 1"
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 200,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "GPS EOD Analysis",
            sliderRouteTranslate: '',
            startDate: "",
            endDate: "",
            defaultStartDate: "",
            defaultEndDate: "",
            routeinfo: "",
            breaktruck: "",
            uniquetrucks: [],
            showTrucksList: "show-n",
            filteredData: [],
            tottalDistance: [],
            pinnedBottomRowData: [],
            pageType: 1,
            showDpk: { display: "none" },
            showMpk: { display: "none" },
            pageTitle: "Distance Travelled Report",
            tpttrucks: [],
            transporterTrucks: [],
            adaniCheck: 0,
        };

        this.onLoadData = this.onLoadData.bind(this);
        this.onShowTPTGPSMapRoute = this.onShowTPTGPSMapRoute.bind(this);
        this.onShowTPTGPSBreaks = this.onShowTPTGPSBreaks.bind(this);
        this.onShowRouteMap = this.onShowRouteMap.bind(this);

    }

    componentDidMount() {
        loadDateTimeScript()
        var tptCode = localStorage.getItem('transportercode');
        if (tptCode.includes("T293") == true) {
            var adaniCheck = 1;
        }
        else {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck: adaniCheck
        });
        var vehicle_group = localStorage.getItem("vehicle_group");
        if (vehicle_group != "") {
            var tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("records ", records)
                    this.setState({
                        tpttrucks: records
                    })
                })
        }

        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        // if(is_enmovil_transporter == 1)
        // {

        // }
        // else
        // {
        //     window.location.href="/";
        // }

        // console.log("transporter_code ",localStorage.getItem("transporter_code"));
        var sdate = moment.parseZone().subtract(2, 'days').format('DD-MM-YYYY');
        var edate = moment.parseZone().format('DD-MM-YYYY');
        $("#gpsstartdate").val(sdate + " 00:00");
        $("#gpsenddate").val(edate + " 23:59");
        // redirectURL.post("/consignments/getAllTrucksData").then((response)=>{
        //     if(response.data.length > 0)
        //     {
        //         var trucks = response.data.map(function(e)
        //         {
        //             if(e.truck_no != "")
        //             {
        //                 return e.truck_no;
        //             }
        //         })
        //         this.setState({
        //             truckNoList :trucks
        //         })
        //     }

        // })

        var stdate = moment.parseZone().subtract(2, 'days').format('DD-MM-YYYY');
        var etdate = moment.parseZone().format('DD-MM-YYYY');
        var parameters = {
            pageType: 2,
            startDate: stdate+" 00:00",
            endDate: etdate+" 23:59",
            truck_no: "",
            transporter_code: JSON.parse(localStorage.getItem("transportercode"))
        }


        var deviceparams = {
            transporter_code: localStorage.getItem("transportercode")
        }
        redirectURL.post("/dashboard/devices", deviceparams).then((response1) => {
            var transporterTrucks = [];
            var trunkslist = []
            var tptCode = JSON.parse(localStorage.getItem("transportercode"));

            var tpttrucks = this.state.tpttrucks;
            var tptunqrecords = []
            if (tpttrucks.length > 0) {
                response1.data.devices.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f.truck_no) {
                            transporterTrucks.push(f)
                            trunkslist.push(f.truck_no)
                        }
                    })
                );
            }
            else {
                response1.data.devices.map(function (e) {
                    if (tptCode.indexOf(e.transporter_code) > -1) {
                        transporterTrucks.push(e)
                        trunkslist.push(e.truck_no)
                    }
                })
            }

            var transportercode = localStorage.getItem("transportercode");
            var showMpk = { display: "none" };
            var showDpk = { display: "none" };

            var currentmonth = new Date();
            currentmonth = (currentmonth.getMonth() + 1).toString() + "-" + currentmonth.getFullYear().toString()
            $(".selected_date").val(currentmonth);
            var pageType = 2;
            var pageTitle = "Distance Travelled Report";
            var showMpk = { display: "block" };

            this.setState({
                pageType: pageType,
                pageTitle: pageTitle,
                showMpk: showMpk,
                showDpk: showDpk,
                // startDate:sdate,
                // endDate:edate,
                truckNoList: trunkslist,
                // defaultStartDate:sdate,
                // defaultEndDate:edate,
                transporterTrucks: transporterTrucks
            })
        })

        this.onLoadData(parameters);
        this.getUserGridState()
    }
    getUserGridState() {
        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
            .then(async (resp) => {
                //   console.log("resp ", resp)
                this.setState({
                    usergridstate: resp.data
                });
                this.restoreGridStates();
            })
    }

    onLoadData(parameters) {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        redirectURL.post("/consignments/tptgpseodanalysismonthly", parameters)
            .then(async(response) => {
                var recs = response.data.records;
                console.log(recs , "recs_1729")
                var records = [];
                var tottalDistance = 0;
                // var transporterTrucks = this.state.transporterTrucks;
                // if(recs.length > 0)
                // {
                //     var truckNoList = this.state.truckNoList;

                //     if(truckNoList.length > 0)
                //     {
                //         recs.map(function(t){
                //             // if(truckNoList.indexOf(t.truck_no) > -1 )
                //             // {
                //             //     tottalDistance = parseFloat(tottalDistance)+parseFloat(t.total_distance_travelled_km);
                //             //     records.push(t)
                //             // }
                //             transporterTrucks.filter((d )=> {
                //                 if(d.truck_no == t.truck_no){

                //                     if(d.registereddevices != undefined)
                //                     {
                //                         if(d.registereddevices.length > 0)
                //                         {
                //                             t.device_id = d.registereddevices[0].device_id;
                //                         }
                //                         records.push(t)
                //                     }
                //                 }
                //             })
                //         })

                //     }
                // }

                var tpttrucks = this.state.tpttrucks;
                var tptunqrecords = [];
                if(JSON.parse(localStorage.getItem("transportercode")).includes("T293") && localStorage.getItem("plant_location") != "" &&
                localStorage.getItem("plant_location") != undefined)
                {
                    var lsParams = {
                        transporter_code : JSON.parse(localStorage.getItem("transportercode")),
                        plant_location : localStorage.getItem("plant_location")
                    }
                    await redirectURL.post("/consignments/getDistinctTrucks",lsParams).then(async (response1) => {
                        var tpttrucks = response1.data.recordsdata;
                        //console.log(tpttrucks,"tpttrucks---");
                        //console.log(recs,"recs---");
                        if (tpttrucks.length > 0) {
                            // recs.filter(f =>
                            //     tpttrucks.filter((d) => {
                            //         console.log(d,f.truck_no,"truck comp")
                            //         if (d == f.truck_no) {
                            //             tottalDistance = parseFloat(tottalDistance) + parseFloat(f.distance_in_km);
                            //             tptunqrecords.push(f)
                            //         }
                                    tptunqrecords = await recs.filter(function(n) { return tpttrucks.indexOf(n.truck_no) !== -1;});
                                    tptunqrecords.map((item) => {
                                        tottalDistance = parseFloat(tottalDistance) + parseFloat(item.distance_in_km);
                                    })
                                    console.log(tptunqrecords,"tptunqrecords")

                            //     })
                            // );
                        }
                        else {
                            tptunqrecords = recs
                            if (tptunqrecords.length > 0) {
                                tptunqrecords.map((item) => {
                                    tottalDistance = parseFloat(tottalDistance) + parseFloat(item.distance_in_km);
                                })
                            }
                        }
                    })
                }
                else
                {
                    if (tpttrucks.length > 0) {
                        recs.filter(f =>
                            tpttrucks.filter((d) => {
                                if (d.truck_no == f.truck_no) {
                                    tottalDistance = parseFloat(tottalDistance) + parseFloat(f.distance_in_km);
                                    tptunqrecords.push(f)
                                }
                            })
                        );
                    }
                    else {
                        tptunqrecords = recs
                        if (tptunqrecords.length > 0) {
                            tptunqrecords.map((item) => {
                                tottalDistance = parseFloat(tottalDistance) + parseFloat(item.distance_in_km);
                            })
                        }
                    }

                }
                var pinnedBottomRowData = {}
                // pinnedBottomRowData['_id'] = "total";
                // pinnedBottomRowData['showbreaks'] = "total";
                pinnedBottomRowData['truck_no'] = "total";
                // pinnedBottomRowData['total_break_time_mins'] = "Total";
                pinnedBottomRowData['distance_in_km'] = tottalDistance.toFixed(1);
                // pinnedBottomRowData['total_travel_time_mins'] = "total";
                var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
                var pdfRowData = [];
                tptunqrecords.map(t => {
                    return (pdfRowData.push({
                        truck_no: t.truck_no,
                        total_distance_travelled_km: (t.distance_in_km).toFixed(2),
                        total_break_time_mins: minutesToDHM(t.breaks_duration),
                        travel_duration: minutesToDHM(t.travel_duration),
                        from_date: getHyphenDDMMMYYYYHHMM(t.from_date),
                        to_date: getHyphenDDMMMYYYYHHMM(t.to_date),
                    }))
                })

                pdfRowData.push({
                    truck_no: "Total",
                    total_distance_travelled_km:tottalDistance.toFixed(1),
                    total_break_time_mins:"",
                    travel_duration:"",
                    from_date:"",
                    to_date:""

                })
                console.log(pdfRowData, "pdfrowdatamon")

                var pdfColumns = [
                    { header: name, binding: 'truck_no' },
                    { header: 'Distance Travelled (KM)', binding: 'total_distance_travelled_km' },
                    { header: 'Break Duration', binding: 'total_break_time_mins' },
                    { header: 'Travel Duration', binding: 'travel_duration' },
                    { header: 'From Date', binding: 'from_date' },
                    { header: 'To Date', binding: 'to_date' },
                ]
                // console.log("EOD ",pdfRowData)
                
                console.log(tptunqrecords,"tptunqrecords--2")
                this.setState({
                    pdfRowData,
                    rowData: tptunqrecords,
                    pdfColumns: pdfColumns,
                    tottalDistance: [pinnedBottomRowData],
                    loadshow: "show-n",
                    overly: "show-n",

                });
                // console.log(tottalDistance.toFixed(1),"tottalDistance")
            })


    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    /*Alert Popups*/
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

    /*Alert Popups*/
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: '',
            sliderTranslatesidebar: '',
            sliderForceCloseTranslate: '',
            sliderRakeTranslate: '',
            sliderBulkRakeTranslate: "",
            bulkslide: '',
            sliderForceDestination: '',
            sliderBulkForceClose: "",
            showTrucksList: "show-n"
        });

    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }

    onChangeSourceItem(refno) {
        this.setState(
            { refno },
            // () => console.log(`Option selected:`, this.state.refno)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;


    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.gridApi.deselectAll();
        this.setState({ open: false });
    };

    onShowTPTGPSMapRoute(rownode) {
        var node = rownode.data;
        // console.log("Node ", node)
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
                "category": this.state.pagetitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        var parameters = {
            truck_no: node.truck_no,
            from_date: node.from_date,
            to_date: node.to_date,
            device_id: node.device_id
        }
        redirectURL.post("/consignments/showgpseodmap", parameters)
            .then((response) => {
                // console.log("response ", response.data)
                var truckinfo = { truck_no: node.truck_no }
                try {
                    if (response.data.route_details != undefined) {
                        truckinfo.start = response.data.route_details.start_time;
                        truckinfo.end = response.data.route_details.end_time;
                        truckinfo.distance = node.total_distance_travelled_km;
                    }
                    else {
                        truckinfo.start = "";
                        truckinfo.end = "";
                        truckinfo.distance = "";
                    }

                }
                catch (e) { }
                this.setState({
                    mapinfo: response.data,
                    sliderRouteTranslate: "slider-translate-60p",
                    routeinfo: truckinfo,
                    loadshow: 'show-n',
                    overly: 'show-m',

                });
            })

    }
    onShowTPTGPSBreaks = async (rownode) => {
        var node = rownode.data;
        this.setState({
            breaktruck: node.truck_no
        })
        // console.log("Node ", node)
        let eventLabel = googleAnalytics.page.action.overspeed;
        let eventOptions = {
            "category": this.state.pagetitle,
            "action": this.state.eventAction,
            "label": eventLabel,
        }
        googleAnalytics.logEvent(eventOptions);
        if (rownode != 0) {
            var overspeedData = [];

            var propsdata = rownode;
            await this.setState({

                detailCellRendererParams: {
                    detailGridOptions: {
                        columnDefs: [
                            {
                                headerName: "", field: "_id",
                                width: 50,
                                cellRendererFramework: RoutemapAction,
                                resizable: true
                            },
                            {
                                headerName: "Break Start",
                                field: "break_start",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Break End",
                                field: "break_end",
                                filter: true,
                                resizable: true
                            },

                            {
                                headerName: "Break Time",
                                field: "break_time_seconds",
                                filter: true,
                                resizable: true,
                                valueGetter: function (params) {
                                    try {
                                        return secondsToString(params.data.break_time_seconds);
                                    }
                                    catch (e) {
                                        return "";
                                    }
                                }
                            },
                            {
                                headerName: "Break Address",
                                field: "break_address",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Break City",
                                field: "city",
                                filter: true,
                                resizable: true
                            },
                            {
                                headerName: "Break State",
                                field: "break_state",
                                filter: true,
                                resizable: true
                            },

                        ],
                        overlayNoRowsTemplate: 'No rows to show',

                    },
                    getDetailRowData: function (param) {
                        var breakslist = eval(param.data.breaks);
                        // console.log(breakslist);
                        param.successCallback(breakslist);
                    },
                    masterDetail: true
                }
            });

            if (propsdata.column.colDef.field == 'showbreaks') {

                propsdata.node.setExpanded(!propsdata.node.expanded);
            }
            else {

                propsdata.node.setExpanded(false);
            }
            //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

        }

    }
    onShowRouteMap(rownode) {
        //  console.log("IN onShowRouteMap, params = ",rownode);
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": googleAnalytics.page.action.viewRoute,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        var breaktruck = this.state.breaktruck;
        this.setState({
            loadshow: 'show-n',
            sliderRouteTranslate: "slider-translate-50p",
            mapinfo: {
                coords: [{ lat: rownode.lat, lng: rownode.lng }],
                breaks: [{ lat: rownode.lat, lng: rownode.lng }]
            },
            routeinfo: { truck_no: breaktruck, start: rownode.break_start, end: rownode.break_end, distance: "" }
        })
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            startDate: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        this.setState({
            endDate: edate
        });
    }
    onClickFilterData() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        var pageType = this.state.pageType;
        var truck_no = $("#inputTruck").val().toUpperCase()
        // if(pageType == 1)
        // {
        var sdate = $("#gpsstartdate").val();
        var edate = $("#gpsenddate").val();
        if (!sdate || !edate) {
            this.setState({
                show: true,
                basicTitle: "Please provide both From and To Dates.",
                basicType: "danger",
                loadshow: "show-n",
                overly: "show-n",
            });
            return;
        }
        // Define date format
        var dateFormat = "DD-MM-YYYY HH:mm";
        // Use Moment to parse and validate the date formats
        var startDate = moment(sdate, dateFormat, true); // 'true' enforces strict parsing
        var endDate = moment(edate, dateFormat, true);
        // Validate the dates
        if (!startDate.isValid() || !endDate.isValid()) {
            this.setState({
                show: true,
                basicTitle: "Invalid Date Format, Please use DD-MM-YYYY HH:mm.",
                basicType: "danger",
                loadshow: "show-n",
                overly: "show-n",
            });
            return;
        }
        // Check if the start date is earlier than the end date
        if (startDate.isAfter(endDate)) {
            this.setState({
                show: true,
                basicTitle: "Invalid Date Range , The From date should be Less than To date.",
                basicType: "danger",
                loadshow: "show-n",
                overly: "show-n",
            });
            return;
        }
        var parameters = {
            pageType: pageType,
            startDate: sdate,
            endDate: edate,
            truck_no: truck_no,
            transporter_code: JSON.parse(localStorage.getItem("transportercode"))
        }
        // }
        // else
        // {
        //     var transporterTrucks = this.state.transporterTrucks;
        //     var currentmonth = $(".selected_date").val();
        //     var parameters = {
        //         pageType:pageType,
        //         selectedmonth:currentmonth,
        //         truck_no:truck_no,
        //         transporter_code:JSON.parse(localStorage.getItem("transportercode")),
        //         transporterTrucks:transporterTrucks
        //     } 
        // }

        this.onLoadData(parameters);
    }

    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        if(dataset !== undefined && dataset !== "undefined"){
            dataset = dataset.slice(0, 100);
            var tpttrucks = this.state.tpttrucks;
            var tptunqrecords = []
            if (tpttrucks.length > 0) {
                dataset.filter(f =>
                    tpttrucks.filter((d) => {
                        if (d.truck_no == f) {
                            tptunqrecords.push(f)
                        }
                    })
                );
            }
            else {
                tptunqrecords = dataset
            }
            this.setState({
                filteredData: tptunqrecords,
                showTrucksList: "show-m",
            })
        }
    }
    handlerForm = (event) => {
        //console.log("Select", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if (tpttrucks.length > 0) {
            dataset.filter(f =>
                tpttrucks.filter((d) => {
                    if (d.truck_no == f) {
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else {
            tptunqrecords = dataset
        }
        if(tptunqrecords !== undefined && tptunqrecords !== "undefined"){
            var filteredResult = tptunqrecords.filter(function (e) {
                if (e != "" && e != null) {
                    return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
                }
    
            });
            filteredResult = filteredResult.slice(0, 100);
            this.setState({
                filteredData: filteredResult,
                showTrucksList: "show-m",
            })
        }
    }
    hideList() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            showTrucksList: "show-n",
        });
    }
    onClickSaveGridState() {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Save Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();

        let screenpage = 'Distance Travelled Report';



        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenpage
        }
        //console.log("reqparams ", reqparams)
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                //console.log("State response ",response.data)
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }

    restoreGridStates() {
        ///console.log("this.state.usergridstate ", this.state.usergridstate)
        try {
            if (this.state.usergridstate.length > 0) {
                var windowstates = this.state.usergridstate;
                // console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

            }
        }
        catch (e) { }
    }


    resetState = () => {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Reset Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        //console.log('column state reset');
        var reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: this.state.screenpage
        }

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })

    };


    render() {
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        var pageType = this.state.pageType;
        if (pageType == 1) {
            var hidemonthly = false;
        }
        else {
            var hidemonthly = true;
        }
        var adaniCheck = this.state.adaniCheck;
        var tkName = "Truck No";
        if (adaniCheck == 1) {
            tkName = "Shuttle No";
        }
        var columnwithDefs = [

            {
                headerName: tkName,
                field: "truck_no",
                width: 120,
                valueGetter: function (params) {
                    if (params.data.showbreaks == "total") {
                        return "";
                    }
                    else {
                        return params.data.truck_no;
                    }
                }
            },

            {
                headerName: "Distance Travelled (KM)",
                field: "distance_in_km",
                width: 180,
                valueGetter: function (params) {
                    try {
                        return params.data.distance_in_km;
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "Break Duration",
                field: "breaks_duration",
                width: 180,
                valueGetter: function (params) {
                    try {
                        return secondsToDhms(parseInt(params.data.breaks_duration) * 60);
                    }
                    catch (e) {
                        return "";
                    }
                }
            },

            {
                headerName: "Travel Duration",
                field: "travel_duration",
                width: 180,
                valueGetter: function (params) {
                    try {
                        return secondsToDhms(parseInt(params.data.travel_duration) * 60);
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "From Date",
                field: "from_date",
                width: 180,
                valueGetter: function (params) {
                    try {
                        if (params.data.from_date != "" && params.data.from_date != undefined) {
                            return getDDMMMYYYYHHMMDefault(params.data.from_date);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            },
            {
                headerName: "To Date",
                field: "to_date",
                width: 180,
                valueGetter: function (params) {
                    try {
                        if (params.data.to_date != "" && params.data.to_date != undefined) {
                            return getDDMMMYYYYHHMMDefault(params.data.to_date);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            },

        ]
        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">

                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-truck-alt cus-i"></i> <span>{this.state.pageTitle}  </span>


                                    <span className="layoutbtns pull-right">
                                        <button className="float-right custom-btn white btn-danger"
                                            style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>

                                    {(this.state.pdfRowData.length > 0) ?
                                        <ExportPDF rowData={this.state.pdfRowData} pagetitle ="Distance Traveled Monthly Report" pdfColumns={this.state.pdfColumns} />
                                        : ""}
                                </h5>
                            </div>
                            <div className="card-body row">
                                <div className="row col-xl-12 col-lg-12">
                                    <div style={{ top: "65px" }} className={"trucks-dropdown " + this.state.showTrucksList}>
                                        <ul>
                                            {listData}
                                        </ul>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>{(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}</label>
                                        <input
                                            type="text"
                                            name="truck_no"
                                            id="inputTruck"
                                            placeholder={(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}
                                            autoComplete="off"
                                            className="form-control"
                                            onFocus={this.initalList.bind(this)}
                                            onChange={this.handlerForm.bind(this)} />
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>From Date</label>

                                        <input
                                            type="text"
                                            id="gpsstartdate"
                                            name="gpsstartdate"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>To Date</label>

                                        <input
                                            type="text"
                                            id="gpsenddate"
                                            name="gpsenddate"
                                            className="form-control"
                                        />
                                    </div>


                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button type="button" className="btn btn-success" style={{ marginTop: "29px" }} onClick={this.onClickFilterData.bind(this)}>Submit</button>
                                    </div>

                                </div>
                                <div className={"row col-xl-12 col-lg-12 "}>

                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            masterDetail={true}
                                            pinnedBottomRowData={this.state.tottalDistance}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                getRowStyle: function (params) {
                                                    if (params.node.rowPinned) {
                                                        return { 'font-weight': 'bold', 'font-size': '16px' };
                                                    }
                                                },
                                            }}
                                        />

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


                {this.state.sliderRouteTranslate != '' ?
                    <div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
                        <div className="slide-r-title">
                            <h4>
                                View Route
                                <span className="float-right closebtn" style={{ marginRight: "25px" }}
                                    onClick={this.onClickHideAll.bind(this)} >X</span>

                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>

                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap
                                    context={this}
                                    mapFor={"tpttruck"}
                                    mapinfo={this.state.mapinfo} />

                                <div className="col-xl-12 col-lg-12 n-p-0">
                                    <div className="crm-numbers pb-0">
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">{(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}</label>
                                                <div>{this.state.routeinfo.truck_no}</div>
                                            </div>

                                            <div className="col route-block">
                                                <label className="sidebar-label">Start Time</label>
                                                <div>{(this.state.routeinfo.start != '') ? getDDMMYYYYHHMMSS(this.state.routeinfo.start) : "NA"}</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">End Time</label>
                                                <div>{(this.state.routeinfo.end != '') ? getDDMMYYYYHHMMSS(this.state.routeinfo.end) : "NA"}</div>
                                            </div>
                                            {(this.state.routeinfo.distance != "") ?

                                                <div className="col route-block">
                                                    <label className="sidebar-label">Distance</label>
                                                    <div>{(this.state.routeinfo.distance != '') ? this.state.routeinfo.distance + " kms" : "NA"}</div>
                                                </div>
                                                : ""}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    : ""}

            </div>

        );
    }
}
window.onload = function () {
    // $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
    // });
    $('#gpsstartdate').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('#gpsenddate').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};
document.addEventListener('turbolinks:before-render', () => {
    loadDateTimeScript()
});

function loadDateTimeScript() {

    $("#report_date").datepicker({
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function (input, inst) {
            $('#ui-datepicker-div').addClass("report_date");
        }
    });

    $('#gpsstartdate').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('#gpsenddate').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}

function secondsToString(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function minutesToDHM(n) {
    var num = n;
    var d = Math.floor(num / (60 * 24));
    var h = Math.floor((num % (60 * 24)) / 60);
    var m = Math.floor((num % (60 * 24)) % 60);

    var dDisplay = d >= 0 ? d + (d === 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h === 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m === 1 ? " Mins " : " Mins ") : "";
    return dDisplay + hDisplay + mDisplay
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    // console.log(date.length);
    // console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
function arrayMin(arr) {
    return arr.reduce(function (p, v) {
        return (p < v ? p : v);
    });
}

function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}
$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})
