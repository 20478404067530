/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
// import "@grapecity/wijmo.styles/wijmo.css";
// import * as pdf from "@grapecity/wijmo.pdf";
// import * as wijmo from "@grapecity/wijmo";
import ExportPDF from "./exportpdf"
import Constant from "../common/googlemapkey";

import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
import TabContent from 'reactstrap/lib/TabContent';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var infoTitleBox = require('../common/google-title-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var moment = require('moment');
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var rad = function (x) {
	return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;
export default class ManageNearByTransporterTrucks extends Component {

	constructor(props) {
		super(props);
		this.colWidth = 100;
		this.rowHeight = 18;
		// console.log(this.props.match.url)
		this.displayData = [];
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform: 'show-m',
			loadshow: 'show-n',
			showrefresh: 'show-n',
			sliderTranslate: '',
			radius: '5000',
			rlatitude: '',
			rlongitude: '',
			latitude: '',
			longitude: '',
			
			defaultradius: 0,
			coordinates: [],
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			
			tpttrucks: [],
			adaniCheck: 0,
            transporter_code:"",
            plantLocations:[],
			utype:"msil"

		};
		this.popmarker = this.popmarker.bind(this);

	}
	/*Alert Popups*/
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount() {
        var transporter_code = this.props.match.params.transporter_code;
        var group = this.props.match.params.group;
		var tptCode = transporter_code;
		// console.log("tptCode.charAt[0] ", tptCode.charAt[0])
		if(tptCode.charAt(0) == "T")
		{
			var utype = "msil";
		}
		else{
			var utype = "hero";
		}
		this.setState({
			utype:utype
		})
		if (tptCode == "T293"){
			var adaniCheck = 1;
		}
		else {
			var adaniCheck = 0;
		}
		this.setState({
			adaniCheck: adaniCheck
		});
		var vehicle_group = group;
		var tptrucks = []
		// below api is not required as it trucklist is not required for getting the map data
		// if (vehicle_group != "") {
		// 	var tparms = {
		// 		transporter_code: JSON.stringify([tptCode]),
		// 		region: vehicle_group
		// 	}
		// 	redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
		// 		.then((response) => {
		// 			var records = response.data.records;
		// 			// console.log("records ", records)
		// 			this.setState({
		// 				tpttrucks: records
		// 			})
		// 		})
		// }
		//console.log(this.props.match.)
		var is_enmovil_transporter = 1;


		if (is_enmovil_transporter == 1 && tptCode != "T215") {
            if (transporter_code != undefined) {
                var tptCode = [transporter_code];
                //var transporter_code = tptCode[0];
            }
            if (vehicle_group != "" && vehicle_group != undefined) {
                var vgroup = vehicle_group;
            }
            else {
                var vgroup = "";
            }
            var formdata = {
                radius: 3000,
                latitude: "20.73568784876339",
                longitude: "79.38999444931353",
                dept_code: "SNDG",
                transporter_code: JSON.stringify(tptCode),
                status: this.state.truckstatus,
                trip_type: 1,
                group_code: vgroup
            }
            rlatitude = "20.73568784876339";
            rlongitude = "79.38999444931353";
            // console.log("formdata ", formdata)
            //http://autometrics.in:5000/getNearByTrucks
            redirectURL.post('/vicinity/getRadiusCoordinates', formdata, {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
                    'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

                }
            })
            .then(
                (response) => {
                    //console.log(response)
                    //console.log("response", JSON.parse(response.data.body));
                    // console.log(response.data.body)
                    var mrkers = JSON.parse(JSON.parse(response.data.body).trucks_data);
                    //console.log(mrkers,"markers");
                    //console.log(response.data.body,"resppp");
                    var deviceparams = {
                        transporter_code: JSON.stringify([transporter_code])
                    }
                    redirectURL.post("/dashboard/carrierDevices", deviceparams).then(async (response1) => {
                        // console.log(response1.data.devices, "devicesssssssssss")
                        var transporterTrucks = [];
                        var transptrucklist = [];
                        response1.data.devices.map(function (e) {
                            if (tptCode == e.transporter_code ) {
                                transporterTrucks.push(e)
                                transptrucklist.push(e.truck_no);
                            }
                        })

                        if (mrkers.length > 0) {
                            // console.log(transporterTrucks, "transporterTrucks")
                            var markers = [];
                            if (transporterTrucks.length > 0) {
                                // mrkers.map(function(t){
                                // 	{
                                // 		markers.push(t)
                                // 	}
                                // })
                                markers = await mrkers.filter(f =>
                                    transporterTrucks.filter((d) => {
                                        if (d.truck_no == f.truck_no) {
                                            // console.log("API ", d)
                                            // if(d.registereddevices != undefined)
                                            // {
                                            // 	if(d.registereddevices.length > 0)
                                            // 	{
                                            f.device_address = d.address
                                            f.device_city = d.city
                                            f.device_state = d.state
                                            var elasptm = "";
                                            
                                            var elspa = '';
                                            if (d.last_moved_time != "" && d.last_moved_time != undefined) {
                                                var td1 = moment.parseZone(new Date()).format("YYYY-MM-DD")
                                                var tday1 = moment.parseZone(new Date(td1 + " 00:00:00")).format("YYYY-MM-DD HH:mm:ss")
                                                // console.log("tday1 ", tday1)
                                                var e1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                                                // console.log("current date is: ", e1)
                                                var e2 = moment.parseZone(d.last_moved_time).format("YYYY-MM-DD HH:mm:ss")
                                                // console.log("last moved date is: ", e2)
                                                var ems = moment(e1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
                                                // console.log("Difference : ", ems)

                                                var ed = moment.duration(ems);
                                                // console.log("Duration : ", ed)
                                                var es = Math.floor(ed.asSeconds());
                                                // console.log("Seconds ",es, f.truck_no)
                                                var esmins = Math.floor(ed.asMinutes());
                                                // console.log("MInutes ",esmins, f.truck_no)

                                                var todayems = moment(tday1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
                                                // console.log("Today Difference : ", todayems)

                                                var todayed = moment.duration(todayems);
                                                var todaysec = Math.floor(todayed.asSeconds());

                                                // console.log("todayhrs ", todaysec)
                                                if (todaysec >= 0) {
                                                    elspa = 0
                                                }
                                                else {
                                                    elspa = todaysec
                                                }
                                                if (es > 0) {
                                                    elasptm = es
                                                }
                                                else {
                                                    elasptm = 0
                                                }
                                            }
                                            else {
                                                elasptm = ""
                                            }
                                            // console.log("elasptm ", elasptm)
                                            f.elaped_time_from_last_moved_seconds = elasptm

                                            // 	}
                                            // }
                                            // console.log(f, f.truck_no)
                                            return f;
                                        }
                                    })
                                );
                            }
                            // console.log(markers,"markersfilter")
                            var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
                            var pdfRowData = [];
                            var searchTrucks = [];
                           
                            this.setState({
                                defaultradius: formdata.radius,
                                coordinates: markers,
                                totalTrucksCount: markers.length,
                                mwidth: "col-xl-12 col-lg-12",
                                rowData: markers,
                                rowDataCopy: markers,
                                radius: 5000,
                                loadshow: 'show-n',
                                showform: 'show-n',
                                showrefresh: 'show-m',
                                rlatitude: "21.144644112601775",
                                rlongitude: "79.08860126768066",
                                alltrucks: markers,
                                //onTripCounter : JSON.parse(response.data.body).gps_trucks,
                                onTripCounter: markers.length,
                                transporterTrucks: transporterTrucks,
                                //truckNoList :transptrucklist,
                                truckNoList: searchTrucks
                            });
                            redirectURL.post("/weather/getPlants").then((rsp) => {
                                this.setState({
                                    plantLocations: rsp.data,
                                });
                                //console.log(rsp.data);
                                this.renderMap();

                            })

                        }
                        else {
                            this.setState({
                                show: true, basicType: 'warning', basicTitle: "No Data found.",
                                loadshow: 'show-n',
                                showform: 'show-m',
                            });

                        }
                    })

                })
            .catch(function (error) {
                console.log(error);
            });
        
            this.renderMap();
    
            this.logPageView(); // Log page view to GA


		}
		else {
			// window.location.href = "/";
		}
	}

	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	initMap = () => {
		allcords = [];
		this.state.coordinates.map((marker) => {
			allcords.push(marker);
		});

		var contentdata = this.state.contentString;
		if (this.state.adaniCheck == 1) {
			var lt = 28.495765038354467;
			var ln = 77.0713875520654;
			var mpZoom = 15;
		}
		else {
			mpZoom = 5;
			if (this.state.rlatitude == '' && this.state.rlongitude == '') {

				lt = 21.144644112601775;

				ln = 79.08860126768066;
			}
			else {

				lt = this.state.rlatitude;

				ln = this.state.rlongitude;
			}
		}
		try {
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: mpZoom,
				center: new window.google.maps.LatLng(lt, ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				// radius: 500,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },            
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
		catch (e) {
		
		}


		var markerLatlng1;

		markerLatlng1 = new window.google.maps.LatLng(28.49568960582423, 77.07074382127196);
		//console.log("markerLatlng", markerLatlng1)
		var circle = new window.google.maps.Circle({
			strokeColor: '#71caab',
			strokeOpacity: 0.7,
			strokeWeight: 1,
			fillColor: '#71caab',
			fillOpacity: 0.25,
			map: map,
			center: markerLatlng1,
			radius: 1000
		});

		var markers = allcords.map((marker) => {
		
			if (this.state.adaniCheck == 1) {
				var image = require('../../assets/icons/shuttle_na_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			if (marker.status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_inside_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_inside_24.png');
				}
			}
			else if (marker.status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_going_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_going_24.png');
				}
			}
			else if (marker.status == 3 || marker.status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_green.png');
				}
				else {
					var image = require('../../assets/icons/truck_atdealer_24.png');
				}
			}
			else if (marker.status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/truck_leftdealer_24.png');
				}
			}
			else if (marker.status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_return_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_return_24.png');
				}
			}
			else if (marker.status == 7) {
				var truckText = "Empty Truck Outside Plant";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_empty_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_empty_24.png');
				}
			}
			else {
				var truckText = marker.truck_no;
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_na_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}
			}
			if (this.state.adaniCheck == 1) {
				if (marker.speed > 20) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/shuttle_green.png');
				}
			}
			var contentarr = []
			var headerTitle = marker.truck_no;


			if (this.state.adaniCheck == 1) {
				contentarr.push({ "key": "Shuttle No", "value": marker.truck_no });
			}
			else {
				contentarr.push({ "key": "Truck No", "value": marker.truck_no });
			}
			contentarr.push({ "key": "Transporter Name", "value": marker.transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			//contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
			contentarr.push({ "key": "GPS Provider", "value": "Enmovil" });
			contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(marker.speed) });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(marker.timestamp) });
			if (marker.elaped_time_from_last_moved_seconds <= 180) {
				var trvalstat = "Travelling"
			}
			else {
				var rrr = secondsToDhms(marker.elaped_time_from_last_moved_seconds)
				var trvalstat = "Idle: " + rrr
			}
			contentarr.push({ "key": "Travelling Status", "value": trvalstat });
			contentarr.push({ "key": "Address", "value": marker.device_address });
			contentarr.push({ "key": "City", "value": marker.device_city });
			contentarr.push({ "key": "State", "value": marker.device_state });
			var contentString = infoBox(image, headerTitle, contentarr, '')

			//console.log(contentString,"contentString");
			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +marker.address+"</td></tr></table>";

			//var contentString = "Here "+marker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});


			var infowindow1 = new window.google.maps.InfoWindow({
				content: infoTitleBox(marker.shuttle_no)
			});
			// sample infowindow default view
			// if (this.state.adaniCheck ===1) {
			// 	window.google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
			// 		infowindow.open(map, marker);
			// 	  });
			// }


			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));

			if (this.state.adaniCheck == 1) {
				var image = require('../../assets/icons/shuttle_na_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			if (marker.status == 1) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_inside_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_inside_24.png');
				}
			}
			else if (marker.status == 2) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_going_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_going_24.png');
				}
			}
			else if (marker.status == 3 || marker.status == 4) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_green.png');
				}
				else {
					var image = require('../../assets/icons/truck_atdealer_24.png');
				}
			}
			else if (marker.status == 5) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/truck_leftdealer_24.png');
				}
			}
			else if (marker.status == 6) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_return_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_return_24.png');
				}
			}
			else if (marker.status == 7) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_empty_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_empty_24.png');
				}
			}
			else {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_na_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}
			}
			
			if (this.state.adaniCheck == 1) {
				if (marker.speed > 20) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/shuttle_green.png');
				}
			}

			var mark = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: "Status: " + truckText + " #" + marker.truck_no,
				icon: image
			});
			if (this.state.adaniCheck == 1) {
				mark.addListener('click', function () {
					infowindow.open(map, mark);
					infowindow1.close(map, mark);
				});
				new window.google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
					infowindow1.open(map, mark);
				});
				new window.google.maps.event.addListener(infowindow, 'closeclick', function () {
					infowindow1.open(map, mark);
				});
			}
			else {
				mark.addListener('click', function () {
					infowindow.open(map, mark);
				});
			}
			return mark
			// mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		try {
			var markerCluster = new MarkerClusterer(map, markers,
				{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
		}
		catch (e) { }
		// var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		//map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if(this.state.utype == "msil")
		{
			
			if (this.state.plantLocations.length > 0) {
				this.state.plantLocations.map(function (params) {
					var plant = {
						url: require("../../assets/icons/" + params.icon), // url
						scaledSize: new window.google.maps.Size(32, 32), // scaled size
						origin: new window.google.maps.Point(0, 0), // origin
						anchor: new window.google.maps.Point(0, 0) // anchor
					};
					var coords = JSON.parse(params.coordinates);
					var plantmarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(coords.lat, coords.lng),
						map: map,
						icon: plant,
						title: params.plant_name
					});
				})
			}
		}

	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
			marker.setAnimation(null);
		} else {
			marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	}

	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d / 1000; // returns the distance in meter
	};
	onShowInfo = (e) => {
		// console.log(JSON.parse(e.target.id));
		this.setState({
			contentString: "Here Success"
		});
		var ourMarker = e.target.id;
		window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		});
		infowindow.open(map, marker);

	}
	popmarker(e) {
		var truckNo = e.data.truck_no;
		var truck = [];
		var trucks = this.state.alltrucks;
		// console.log(truckNo, "input truck");
		var filteredValue = trucks.filter(function (e) {
			return e.truck_no == truckNo
		});
		if (filteredValue.length > 0) {
			// console.log("Matched")
			var lat = parseFloat(filteredValue[0].latitude);
			var lng = parseFloat(filteredValue[0].longitude);
			var data = filteredValue[0];
			map.setCenter(new window.google.maps.LatLng(lat, lng));
			map.setZoom(22);
			var markerLatlng = new window.google.maps.LatLng(lat, lng);

			if (filteredValue[0].status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 7) {
				var truckText = "Empty Truck Outside Plant";
				var color = "#333333";
			}
			else {
				var truckText = "N/A";
				var color = "#333333";
			}

			if (this.state.adaniCheck == 1) {
				var image = require('../../assets/icons/shuttle_na_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			if (filteredValue[0].status == 1) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_inside_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_inside_24.png');
				}
			}
			else if (filteredValue[0].status == 2) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_going_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_going_24.png');
				}
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_green.png');
				}
				else {
					var image = require('../../assets/icons/truck_atdealer_24.png');
				}
			}
			else if (filteredValue[0].status == 5) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/truck_leftdealer_24.png');
				}
			}
			else if (filteredValue[0].status == 6) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_return_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_return_24.png');
				}
			}
			else if (filteredValue[0].status == 7) {
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_empty_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_empty_24.png');
				}
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}

			if (this.state.adaniCheck == 1) {
				if (filteredValue[0].speed > 20) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/shuttle_green.png');
				}
			}

			var marker = new window.google.maps.Marker({
				position: markerLatlng,
				map: map,
				title: data.truck_no,
				icon: image
			});


			var contentarr = []
			var headerTitle = filteredValue[0].truck_no;
			if (this.state.adaniCheck == 1) {
				contentarr.push({ "key": "Shuttle No", "value": filteredValue[0].truck_no });
			}
			else {
				contentarr.push({ "key": "Truck No", "value": filteredValue[0].truck_no });
			}
			contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
			//contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
			contentarr.push({ "key": "GPS Provider", "value": "Enmovil" });
			contentarr.push({ "key": "Speed (KMPH)", "value": filteredValue[0].speed });
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
			// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
			if (filteredValue[0].elaped_time_from_last_moved_seconds <= 180) {
				var trvalstat = "Travelling"
			}
			else {
				var rrr = secondsToDhms(filteredValue[0].elaped_time_from_last_moved_seconds)
				var trvalstat = "Idle: " + rrr
			}
			contentarr.push({ "key": "Travelling Status", "value": trvalstat });
			contentarr.push({ "key": "Address", "value": filteredValue[0].device_address });
			contentarr.push({ "key": "City", "value": filteredValue[0].device_city });
			contentarr.push({ "key": "State", "value": filteredValue[0].device_state });

			var contentString = infoBox(image, headerTitle, contentarr, '')
			//console.log(contentarr,"contentarr")
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			marker.setAnimation(window.google.maps.Animation.DROP)
			marker.addListener('click', function () {
				infowindow.open(map, marker);
			});

			// infowindow.open({
			// 	anchor: marker,
			// 	map,
			// 	shouldFocus: true,
			// });		

			// infowindow.open(map,marker);

			marker.setMap(map);
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "No Data found.",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}
		// console.log(filteredValue);

	}
	
	render() {
		const modalStyles = {
			width: '1300px !important',
		}

		const { usermanualmodal } = this.state;
		const { truckstatus } = this.state;
	
		const dStyles = {
			width: '100%',
			marginLeft: '0px',
			paddingLeft: '0px',
			height: '100vh'
		}
		const btstye = {
			marginBottom: "10px"
		}
		var listData = []
		try {
			this.state.filteredData.map(function (e) {
				listData.push(<li className="dropdown-truck-no">{e}</li>)
			})
		} catch (e) { console.log(e) }
		var showonlysnd = true;
		var showonlyprt = true;
		var hidecol = true;
		var hideDistance = true;
		var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if (adaniCheck == 1) {
			tkName = "Shuttle No";
		}
		

		return (

			<div className="row">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
			
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                </div>
                
                <div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>


			</div>

		);
	}
}


function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	// console.log(len);
	var htmlStr = "";
	var coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}
function secondsToHM(seconds) {
	seconds = Number(seconds);
	// var d = Math.floor(seconds / (3600*24));
	// var h = Math.floor(seconds % (3600*24) / 3600);
	// var m = Math.floor(seconds % 3600 / 60);
	// var s = Math.floor(seconds % 60);
	
	// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	// return dDisplay + hDisplay + mDisplay;
	
	var hh = Math.floor((seconds / 3600));
	var hrr = Math.floor(seconds - (hh*3600));
	var mm = Math.floor(hrr/60)
	if(mm < 10)
	{
		var mins = "0"+mm;
	}
	else
	{
		var mins = mm;
	}

	if(hh < 10)
	{
		var hrs = "0"+hh;
	}
	else
	{
		var hrs = hh;
	}
	return hrs+":"+mins;
}
function ConvertSeconds(totalSeconds) {
	// var days = Math.floor(num/86400);
	// var hours = Math.floor(num/3600)
	// var minutes = Math.floor(num/60);
	var minutes = Math.round((totalSeconds % 3600) / 60);
	var hours = Math.round((totalSeconds % 86400) / 3600);
	var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
	// let d = (new Date(t0)) - (new Date(t1));
	// let weekdays     = Math.floor(d/1000/60/60/24/7);
	// let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
	// let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
	// let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
	return days + " Day(s) " + hours + " Hrs " + minutes + " mins"
}
function ConvertHHMMSeconds(n) {
	// console.log(totalSeconds,"totalSeconds")
	// var seconds = Math.round((totalSeconds % 3600));
	// var minutes = Math.round((totalSeconds % 3600) / 60);
	// var hours = Math.round((totalSeconds % 86400) / 3600);
	// var days = Math.round((totalSeconds % (86400 * 30)) / 86400);

	var days = Math.round(parseInt(n / (24 * 3600)));

	n = n % (24 * 3600);
	var hours = Math.round(parseInt(n / 3600));

	n %= 3600;
	var minutes = Math.round(n / 60);

	n %= 60;
	var seconds = Math.round(n);

	return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}
function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}
$(document).ready(function () {
	$(document).on("click", ".dropdown-truck-no", function () {
		var vals = $(this).text();
		$("#inputTruck").val(vals);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTruck");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})