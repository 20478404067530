/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import ShowTPTGpsMap from "./showtptgpsmap";
import ShowTPTGpsBreaks from "./showtptgpsbreaks";
import RoutemapAction from "./gpsroutemaplegs";
import "@grapecity/wijmo.styles/wijmo.css";
import * as pdf from "@grapecity/wijmo.pdf";
import * as wijmo from "@grapecity/wijmo";
import ExportPDF from "./exportpdf"
$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class TPTGpsEodAnalysis extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
            loadshow:'show-m',
            overly:"show-m",
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
            pdfColumns: [],
            frameworkComponents: {
                showtptgpsmap:ShowTPTGpsMap,
                showtptgpsbreaks:ShowTPTGpsBreaks
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"GPS EOD Analysis",
            sliderRouteTranslate:'',
            startDate:"",
            endDate:"",
            defaultStartDate:"",
            defaultEndDate:"",
            routeinfo:"",
            breaktruck:"",
            uniquetrucks : [],
			showTrucksList : "show-n",
            filteredData : [],
            tottalDistance : [],
            pinnedBottomRowData: [],
            pageType: 1,
            showDpk: {display:"none"},
            showMpk: {display:"none"},
            pageTitle: "Distance Travelled Report",
            tpttrucks:[],
            transporterTrucks:[],
			adaniCheck:0,
            pdfRowData: [],
        };
        
        this.onLoadData = this.onLoadData.bind(this);
        this.onShowTPTGPSMapRoute = this.onShowTPTGPSMapRoute.bind(this);
        this.onShowTPTGPSBreaks = this.onShowTPTGPSBreaks.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
        
	}
	
    componentDidMount(){
        loadDateTimeScript() 
        var tptCode = localStorage.getItem('transportercode');
        if(tptCode.includes("T293") == true)
        {
            var adaniCheck = 1;
        }
        else
        {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck : adaniCheck
        });
		var vehicle_group = localStorage.getItem("vehicle_group");
        // console.log("vehicle_group",vehicle_group)
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
            // console.log("tparams",tparms)
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			   var records = response.data.records;
				// console.log("records ", records)
				this.setState({
					tpttrucks:records
				})
			})
		}
        
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        // if(is_enmovil_transporter == 1)
        // {
            
        // }
        // else
        // {
        //     window.location.href="/";
        // }

        // console.log("transporter_code ",localStorage.getItem("transporter_code"));
        var sdate = moment.parseZone().subtract(2, 'days').format('DD-MM-YYYY');
        var edate = moment.parseZone().format('DD-MM-YYYY');
        $("#gpsstartdate").val(sdate+" 00:00");
        $("#gpsenddate").val(edate+" 23:59");
        // redirectURL.post("/consignments/getAllTrucksData").then((response)=>{
        //     if(response.data.length > 0)
        //     {
        //         var trucks = response.data.map(function(e)
        //         {
        //             if(e.truck_no != "")
        //             {
        //                 return e.truck_no;
        //             }
        //         })
        //         this.setState({
        //             truckNoList :trucks
        //         })
        //     }
            
        // })
        var deviceparams = {
            transporter_code:localStorage.getItem("transportercode")
        }
        redirectURL.post("/dashboard/carrierDevices", deviceparams).then((response1)=>{
            var transporterTrucks = [];
            var trunkslist = []
            var tptCode = JSON.parse(localStorage.getItem("transportercode"));
            response1.data.devices.map(function(e){
                if(tptCode.indexOf(e.transporter_code) > -1 )
                {
                    transporterTrucks.push(e)
                    trunkslist.push(e.truck_no)
                }
            })
            var transportercode = localStorage.getItem("transportercode");
            var showMpk = {display:"none"};
            var showDpk = {display:"none"};
            if(this.props.match.path == "/tptlogin/gpseodanalysis")
            {
                var pageType=1;
                var pageTitle="Day Wise Distance Travelled Report";
                var showDpk = {display:"block"};
                var parameters = {
                    pageType:pageType,
                    startDate:sdate+" 00:00",
                    endDate:edate+" 23:59",
                    truck_no:"",
                    transporter_code:JSON.parse(localStorage.getItem("transportercode"))
                } 
            }
            else
            {
                var currentmonth = new Date();
                currentmonth = (currentmonth.getMonth()+1).toString()+"-"+currentmonth.getFullYear().toString()
                $(".selected_date").val(currentmonth);
                var pageType=2;
                var pageTitle="Distance Travelled Report (Monthly)";
                var showMpk = {display:"block"};
                var parameters = {
                    pageType:pageType,
                    selectedmonth:currentmonth,
                    truck_no:"",
                    transporter_code:JSON.parse(localStorage.getItem("transportercode")),
                    transporterTrucks:transporterTrucks
                } 
            }
            
            this.setState({
                pageType:pageType,
                pageTitle:pageTitle,
                showMpk: showMpk,
                showDpk: showDpk,
                startDate:sdate,
                endDate:edate,
                truckNoList :trunkslist,
                defaultStartDate:sdate,
                defaultEndDate:edate,
                transporterTrucks:transporterTrucks
            })
            this.onLoadData(parameters); 
        })
          
	}
    
    async onLoadData(parameters)
    {
        if(localStorage.getItem("transportercode").includes("T293"))
        {
            parameters.plant_location = localStorage.getItem("plant_location")
        }
		redirectURL.post("/consignments/tptgpseodanalysis",parameters)
		.then((response) => {
            var recs = response.data.records;
            // console.log("recs",recs)
            var records = [];
            var tottalDistance = 0;
            var transporterTrucks = this.state.transporterTrucks;
            // console.log("transporterTrucks",transporterTrucks)
            if(recs !== undefined ) //recs.length !== 0
            {
                var truckNoList = this.state.truckNoList;

                if(truckNoList.length > 0)
                {
                    recs.map(function(t){
                        // if(truckNoList.indexOf(t.truck_no) > -1 )
                        // {
                        //     tottalDistance = parseFloat(tottalDistance)+parseFloat(t.total_distance_travelled_km);
                        //     records.push(t)
                        // }
                        transporterTrucks.filter((d )=> {
                            if(d.truck_no == t.truck_no){
                                tottalDistance = parseFloat(tottalDistance)+parseFloat(t.total_distance_travelled_km);
                        
                                if(d.device_id != undefined)
                                {
                                    // if(d.registereddevices.length > 0)
                                    // {
                                        t.device_id = d.device_id;
                                    // }
                                    records.push(t)
                                }
                            }
                        })
                    })
                    
                }
            }
            // records.map((item) => {
            //     console.log(item,"item")
            //     if(localStorage.getItem("transportercode").includes("T293"))
            //     {
            //         var plant_location_name = "Gujarat";
            //         if(["Manesar", "Gurgaon", "Haryana"].includes(item.plant_location_name))
            //         {
            //             plant_location_name = "Haryana"
            //         }
            //         if(plant_location_name == localStorage.getItem("plant_location"))
            //         {
            //             records.push(item);
            //         }
            //     }
            // })
            console.log(records,"tottalDistance1")
            // redirectURL.post("/consignments/getDistinctTrucks",parameters).then((response) => {
            //     var reportsData = response.data.recordsdata   
            //     console.log( response.data.recordsdata,"item data")
            //     if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
			// 	localStorage.getItem("plant_location") != undefined)
			// 	{
			// 		records = reportsData.filter((item)=>{
			// 			if(item.state == localStorage.getItem("plant_location"))
			// 			{
			// 				return item;
			// 			}
			// 		})
			// 	}
            // })
           
            var tpttrucks = this.state.tpttrucks;
            // console.log("tpttrucks", tpttrucks)
            var tptunqrecords = []
            
            if(tpttrucks.length > 0)
            { 
                records.filter(f =>
                    tpttrucks.filter((d )=> {
                        if(d.truck_no == f.truck_no){									
                            tottalDistance = parseFloat(tottalDistance)+parseFloat(f.total_distance_travelled_km);
                            console.log(localStorage.getItem("transportercode"),"transportercode")
                            tptunqrecords.push(f)
                        }
                    })
                );
                // console.log(tottalDistance,"tottalDistance2")
            }
            else
            {
                tptunqrecords = records
                
            }
            
            
            console.log(localStorage.getItem("plant_location"),"plant_location")
                // if(tptunqrecords.length > 0)
                // {
                //     tptunqrecords.map((item) => {
                //     //     tottalDistance = parseFloat(tottalDistance)+parseFloat(item.total_distance_travelled_km);
                            
                //     // })
                //     // console.log(tottalDistance,"tottalDistance3")
                //     if(localStorage.getItem("transportercode").includes("T293"))
				// 		{
				// 			var plant_location_name = "Gujarat";
				// 			if(["Manesar", "Gurgaon", "Haryana"].includes(item.plant_location_name))
				// 			{
				// 				plant_location_name = "Haryana"
				// 			}
				// 			if(plant_location_name == localStorage.getItem("plant_location"))
				// 			{
				// 				tptunqrecords.push(item);
				// 			}
				// 		}
						
				// 		else
				// 		{
				// 			tptunqrecords.push(item);
				// 		}
                //     })
                // }
            
            var pinnedBottomRowData = {}
            pinnedBottomRowData['_id'] = "total";
            pinnedBottomRowData['showbreaks'] = "total";
            pinnedBottomRowData['truck_no'] = "total";
            pinnedBottomRowData['total_break_time_mins'] = "Total";
            pinnedBottomRowData['total_distance_travelled_km'] = tottalDistance.toFixed(1);
            pinnedBottomRowData['total_travel_time_mins'] = "total";
            // console.log("EODrowData:=> ",tptunqrecords)
            var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'

            var pdfRowData = [];
            tptunqrecords.map( t => {
                return (pdfRowData.push({
                    name: t.truck_no,
                    total_break_time_mins: minutesToDHM(t.total_break_time_mins),
                    total_distance_travelled_km: t.total_distance_travelled_km,
                    total_travel_time_mins : minutesToDHM(t.total_travel_time_mins),
                }))
            })
            pdfRowData.push({
                name: "",
                total_break_time_mins: "Total (KM)",
                total_distance_travelled_km: tottalDistance.toFixed(1),
                total_travel_time_mins : "",
            })
            
            
            console.log("pdfRowData:", pdfRowData)
            var pdfColumns = [
                { header: name, binding: 'name'},
                { header: 'Total Break Time', binding: 'total_break_time_mins'},
                {header: 'Distance Travelled (KM)', binding: 'total_distance_travelled_km'},
                { header: 'Total Travel Time', binding: 'total_travel_time_mins'},
            ]
            this.setState({
                pdfRowData: pdfRowData,
                rowData:tptunqrecords,
                pdfColumns:pdfColumns,
                tottalDistance:[pinnedBottomRowData],
                loadshow:"show-n",
                overly:"show-n"
            });
            //console.log(tottalDistance.toFixed(1),"tottalDistance")
        })
       
        
    }
    
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}
	/*Alert Popups*/
	closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

	/*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
            sliderRakeTranslate:'',
            sliderBulkRakeTranslate:"",
			bulkslide:'',
			sliderForceDestination:'',
            sliderBulkForceClose:"",
            showTrucksList : "show-n"
		});
		
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    onChangeSourceItem(refno){
		this.setState(
			{ refno },
			// () => console.log(`Option selected:`, this.state.refno)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
   
    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	
	};
	 onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	 }

    onOpenModal = () => {
		this.setState({ open: true });
	};
	
	onCloseModal = () => {
        this.gridApi.deselectAll();
	    this.setState({ open: false });
    };
    
    onShowTPTGPSMapRoute(rownode){
        var node = rownode.data;
        // console.log("Node ", node)
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		
		var parameters = {
			truck_no:node.truck_no,
			from_date:node.from_date,
            to_date:node.to_date,
            device_id:node.device_id
        }
        redirectURL.post("/consignments/showgpseodmap", parameters)
        .then((response) => {
            // console.log("response ", response.data)
            var truckinfo = {truck_no:node.truck_no}
            try{
                if(response.data.route_details != undefined)
                {
                    truckinfo.start=response.data.route_details.start_time; 
                    truckinfo.end=response.data.route_details.end_time;
                    truckinfo.distance=node.total_distance_travelled_km;
                }
                else
                {
                    truckinfo.start=""; 
                    truckinfo.end="";
                    truckinfo.distance="";
                }
                
            }
            catch(e){}
            this.setState({
                mapinfo:response.data,
                sliderRouteTranslate:"slider-translate-60p",
                routeinfo:truckinfo,
                loadshow:'show-n',
                overly:'show-m',

            });
        })
        
    }
    onShowTPTGPSBreaks = async(rownode) => {
        var node = rownode.data;
        this.setState({
            breaktruck:node.truck_no
        })
        // console.log("Node ", node)
        let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		if(rownode != 0)
		{
			var overspeedData=[];
			
			var propsdata = rownode;
			await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ 
                                headerName: "",field: "_id", 
								width:50,
								cellRendererFramework:RoutemapAction,
								resizable: true
							},
							{ 
                                headerName: "Break Start",
                                field: "break_start", 
                                filter:true,
                                resizable: true 
                            },
							{ 
                                headerName: "Break End",
                                field: "break_end", 
                                filter:true,
                                resizable: true 
                            },
							
							{ 
                                headerName: "Break Time",
                                field: "break_time_seconds", 
                                filter:true,
                                resizable: true,
                                valueGetter:function(params){
                                    try{
                                        return secondsToString(params.data.break_time_seconds);
                                    }
                                    catch(e){
                                        return "";
                                    }
                                } 
                            },
							{ 
                                headerName: "Break Address",
                                field: "break_address", 
                                filter:true,
                                resizable: true
                            },
							{ 
                                headerName: "Break City",
                                field: "city", 
                                filter:true,
                                resizable: true
                            },
							{ 
                                headerName: "Break State",
                                field: "break_state", 
                                filter:true,
                                resizable: true
                            },
							
						],
						overlayNoRowsTemplate: 'No rows to show',
														
					},
					getDetailRowData: function(param) {
                        // eslint-disable-next-line no-eval
                        var breakslist = eval(param.data.breaks);
                        // console.log(breakslist);
						param.successCallback(breakslist);
					},
					masterDetail: true
				}
			});
			
			if(propsdata.column.colDef.field == 'showbreaks')
			{

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else{

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
			
		}
		
    }
    onShowRouteMap(rownode){
        //  console.log("IN onShowRouteMap, params = ",rownode);
         this.setState({
             loadshow:"show-m",
             overly:"show-m"
         })
         if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        var breaktruck = this.state.breaktruck;
		this.setState({
            loadshow:'show-n',
            sliderRouteTranslate:"slider-translate-50p",
            mapinfo:{
                coords:[{lat:rownode.lat,lng:rownode.lng}],
                breaks:[{lat:rownode.lat,lng:rownode.lng}]
            },
            routeinfo:{truck_no:breaktruck, start:rownode.break_start, end:rownode.break_end,distance:""}
		})
	}
    
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	
    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }
	
    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
    }
    onClickFilterData(){
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var pageType = this.state.pageType;
        var truck_no=$("#inputTruck").val().toUpperCase()
        if(pageType == 1)
        {
            var sdate = $("#gpsstartdate").val();
            var edate = $("#gpsenddate").val();
            var parameters = {
                pageType:pageType,
                startDate:sdate,
                endDate:edate,
                truck_no:truck_no,
                transporter_code:JSON.parse(localStorage.getItem("transportercode"))
            }
        }
        else
        {
            var transporterTrucks = this.state.transporterTrucks;
            var currentmonth = $(".selected_date").val();
            var parameters = {
                pageType:pageType,
                selectedmonth:currentmonth,
                truck_no:truck_no,
                transporter_code:JSON.parse(localStorage.getItem("transportercode")),
                transporterTrucks:transporterTrucks
            } 
        }
        
        this.onLoadData(parameters);
    }

    initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        
        dataset = dataset.slice(0,100);
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
                tpttrucks.filter((d )=> {
                    if(d.truck_no == f){									
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        this.setState({
            filteredData : tptunqrecords,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	// let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
                tpttrucks.filter((d )=> {
                    if(d.truck_no == f){									
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        var filteredResult = tptunqrecords.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }

    exportPDF() {
		let doc = new pdf.PdfDocument({
			header: {
				declarative: {
					text: 'Carrier Route\t&[Page]\\&[Pages]',
					font: new pdf.PdfFont('times', 12),
					brush: '#bfc1c2'
				}
            },
			lineGap: 2,
			pageSettings: {
				margins: {
					left: 10,
					right: 10,
					top: 10,
					bottom: 10
                },
                size:"A2"
            },
            pageAdded: function(s, e) {
                let image = require('../../assets/icons/logo.png');
                let enmovilImage = require('../../assets/icons/enmovil.png');
                //
                s.drawImage(image, null, null, {width: 150,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Center
                });
                s.drawImage(enmovilImage, 1000, 0, {width: 100,
                    height: 30,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Right
                });
            },
			ended: (sender, args) => pdf.saveBlob(args.blob, 'Document.pdf')
		});
		
		var consignees = this.state.rowData;
		// console.log(consignees,"consignees")
		// consignees.map(function(c){
            
            
        //     if(c.time_from_prev_point != '' && c.time_from_prev_point != undefined)
        //     {
        //         c.time_from_prev_point = ConvertSeconds(c.time_from_prev_point);
        //     }
        //     else
        //     {
        //         c.time_from_prev_point = "";
        //     }
            
        // });
        if(consignees.length > 75)
		{
            var totalPages = (consignees.length/75).toString();
            var lastRemainder = parseInt(totalPages.split(".")[1])
            totalPages = parseInt(totalPages.split(".")[0])
            //console.log(totalPages,"totalPages")
            //console.log(lastRemainder,"lastRemainder")
            var endPage = 75
            var startPage = 0
            for(var i=0;i<totalPages;i++)
            {
                //console.log(startPage, endPage);
                var sen = consignees.slice(startPage,endPage);
                this.$_drawEmployee(doc, sen);
                
                if((i+1) == totalPages)
                {
                    var sen1 = consignees.slice(endPage+1,lastRemainder);
                    this.$_drawEmployee(doc, sen1);
                }
                else
                {
                    
                    startPage = endPage + 1;
                    endPage = endPage + 75;
                }
                
            }
        }
		else
		{
			this.$_drawEmployee(doc, consignees);
		}
		
		// consignees.forEach((employee, i, arr) => {
		//     this.$_drawEmployee(doc, employee);
		//     if (i < arr.length - 1) {
		//         doc.addPage();
		//     }
        // });
        
		doc.end();
	}
	//
	$_drawEmployee(doc, consignees) {
		//let tot = employee.expenses.totals;
        let expenses = consignees;
        // console.log(expenses,"expenses")
        if(expenses.length > 0)
        {
            let name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
            let columns = [
                { header: {name}, binding: 'truck_no'},
                { header: 'Total Break Time', binding: 'total_break_time_min', format: 'c'},
                { header: 'Distance Travelled (KM)', binding: 'total_distance_travelled_km'},
                { header: 'Total Travel Time', binding: 'total_break_time_mins', format: 'c'},                
            ], bold = new pdf.PdfFont('times', 10, 'normal', 'bold'), colWidth = this.colWidth, rowHeight = this.rowHeight;
            
            let y = doc.y;
            // console.log("y check:=>", y, doc)
            doc.moveDown(2);
        
            // * draw table *
            doc.saveState();
            
            y = 0;
            let scale = doc.width / (columns.length * colWidth), docY = doc.y;
            // console.log("doc.width", doc.width, "columns.length",columns.length, "colWidth", colWidth )
            
            if (scale > 1) {
                scale = 1;
            }
            // console.log("scale: =>", scale)
            // if (isNaN(scale)){
            //     scale = 1
            // }
            doc.scale(scale, scale, new wijmo.Point(0, docY));
            doc.translate(0, docY);
            
            // header
            this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');
            
            y += rowHeight;
            
            // body
        
            expenses.map((item,index) => {
                //this.$_checkLineAvailable(doc);  
                this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format);
                y += rowHeight;
            });
            doc.addPage(doc.currentPageSettings);
            doc.footer.drawText("Report generated on enmovil.io by Enmovil Solutions");
        }
	}
	//
	$_checkLineAvailable(doc) {
		if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
			doc.addPage();
		}
	}
	//
	$_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
		let colWidth = this.colWidth, rowHeight = this.rowHeight;
		//
		values.forEach((v, idx) => {
			let x = idx * colWidth;
			//
			doc.paths
				.rect(x, y, colWidth, rowHeight)
				//.fill(brush || '#eaeaea')
				.stroke();
			//
			let value = valueGetter != null ? valueGetter(v) : v || '';
			let format = formatGetter != null ? formatGetter(v) : '';
			//
			if (value !== 'Total') {
				value = wijmo.changeType(value, wijmo.DataType.String, format);
			}
			//
			doc.drawText(value, x + 2, y + 2, {
				font: font,
				height: rowHeight,
				width: colWidth
            });
        });
        doc.moveDown(5);
	}


    
    render(){
        var listData = [] 
        this.state.filteredData.map(function(e){
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        var pageType = this.state.pageType;
        if(pageType == 1)
        {
            var hidemonthly = false;
        }
        else
        {
            var hidemonthly = true;
        }
        var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
        var columnwithDefs = [
			{
                headerName:"",
                field:"_id",
                width:50,
                hide:hidemonthly,
                cellRendererSelector:function(params){
                    if(params.data.showbreaks !="total")
                    {
                        return {
                            component:"showtptgpsmap"
                        }
                    }
                },
                valueGetter:function(params){
                    if(params.data.showbreaks == "total")
                    {
                        return "";
                    }
                }
            },
            {
                headerName:"",
                field:"showbreaks",
                width:50,
                hide:hidemonthly,
                cellRendererSelector:function(params){
                    if(params.data.showbreaks !="total")
                    {
                        return {
                            component:"showtptgpsbreaks"
                        }
                    }
                },
                valueGetter:function(params){
                    if(params.data.showbreaks == "total")
                    {
                        return "";
                    }
                }
            },
            {
                headerName:tkName,
                field:"truck_no",
                width:120,
                valueGetter:function(params){
                    if(params.data.showbreaks == "total")
                    {
                        return "";
                    }
                    else
                    {
                        return params.data.truck_no;
                    }
                }
            },
            // {
            //     headerName:"Is GPS Available",
            //     field:"gps_available",
            //     width:120,
            //     valueGetter:function(params){
            //         try{
            //             if(parseInt(params.data.gps_available) == 1)
            //             {
            //                 return "Yes";
            //             }
            //             else
            //             {
            //                 return "No";
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
            // {
            //     headerName:"GPS Available From",
            //     field:"gps_available_from",
            //     width:160,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.gps_available_from != "" && params.data.gps_available_from != undefined)
            //             {
            //                 return getDDMMMYYYYHHMMDefault(params.data.gps_available_from);
            //             }
            //             else
            //             {
            //                 return "";
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
            // {
            //     headerName:"GPS Available To",
            //     field:"gps_available_to",
            //     width:160,
            //     valueGetter:function(params){
            //         try{
            //             if(params.data.gps_available_to != "" && params.data.gps_available_to != undefined)
            //             {
            //                 return getDDMMMYYYYHHMMDefault(params.data.gps_available_to);
            //             }
            //             else
            //             {
            //                 return "";
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
            {
                headerName:"Report Date",
                field:"report_date",
                width:160,
                hide:hidemonthly,
                valueGetter:function(params){
                    try{
                        if(params.data.report_date != "" && params.data.report_date != undefined)
                        {
                            return getDDMMMYYYYHHMMDefault(params.data.report_date);
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },
            {
                headerName:"Total Break Time",
                field:"total_break_time_mins",
                width:180,
                valueGetter:function(params){
                    if(params.data.showbreaks != "total")
                    {
                        try{
                            return secondsToDhms(parseInt(params.data.total_break_time_mins)*60);
                        }
                        catch(e){
                            return "";
                        }
                    }
                    else
                    {
                        return "Total (KM)";
                    }
                }
            },
            {
                headerName:"Distance Travelled (KM)",
                field:"total_distance_travelled_km",
                width:180,
                valueGetter:function(params){
                    try{
                        return params.data.total_distance_travelled_km;
                    }
                    catch(e){
                        return "";
                    }                    
                }
            },
            {
                headerName:"Total Travel Time",
                field:"total_travel_time_mins",
                width:180,
                valueGetter:function(params){
                    if(params.data.showbreaks != "total")
                    {
                        try{
                            return secondsToDhms(parseInt(params.data.total_travel_time_mins)*60);
                        }
                        catch(e){
                            return "";
                        }
                    }
                }
            },
            // {
            //     headerName:"Transporter Code",
            //     field:"transporter_code",
            //     width:120,
            //     valueGetter:function(params){
            //         try{
            //             return params.data.transporter_code;
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
            // {
            //     headerName:"Transporter Name",
            //     field:"transporter_name",
            //     width:180,
            //     valueGetter:function(params){
            //         try{
            //             return params.data.transporter_name;
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            // },
           
		]
        
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                   
            		<div className="col-xl-12 col-lg-12">
                    	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-truck-alt cus-i"></i> <span>{this.state.pageTitle}  </span>
                                       {(this.state.rowData.length > 0) ?
                                      <ExportPDF  rowData={this.state.pdfRowData} pagetitle = "Distance Travelled Report" pdfColumns={this.state.pdfColumns}/>  
                                       : "" }       
                                      {/* <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.exportPDF.bind(this)}>Export To PDF</button> */}
								</h5>
                                
				   			</div>
				   			<div className="card-body row">
                                <div className="row col-xl-12 col-lg-12">
                                    <div style={{top:"65px"}} className={"trucks-dropdown "+this.state.showTrucksList}>
                                        <ul>
                                            {listData}
                                        </ul>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>{(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}</label>
                                        <input 
                                        type="text" 
                                        name="truck_no" 
                                        id="inputTruck" 
                                        placeholder={(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}
                                        autoComplete="off" 
                                        className="form-control" 
                                        onFocus={this.initalList.bind(this)} 
                                        onChange={this.handlerForm.bind(this)} />
                                    </div>

                                    <div className="col-xl-2 col-lg-2 form-group" style={this.state.showMpk}>
                                        <label>Select Month</label>
                                        <input type="text" className="selected_date form-control" id="report_date" required />
                                    </div>
                                    
                                    <div className="col-xl-3 col-lg-3 form-group" style={this.state.showDpk}>
                                        <label>From Date</label>
                                        
                                        <input 
                                            type="text" 
                                            id="gpsstartdate"
                                            name="gpsstartdate"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group" style={this.state.showDpk}>
                                        <label>To Date</label>
                                    
                                        <input 
                                            type="text" 
                                            id="gpsenddate"
                                            name="gpsenddate"
                                            className="form-control"
                                        />
                                    </div>
                                    
                                    
                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button type="button" className="btn btn-success" style={{marginTop:"29px"}} onClick={this.onClickFilterData.bind(this)}>Submit</button>
                                    </div>
                                    
                                </div>
                                <div className={"row col-xl-12 col-lg-12 "}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											detailCellRendererParams={this.state.detailCellRendererParams}
                                            masterDetail={true}
                                            pinnedBottomRowData={this.state.tottalDistance}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                getRowStyle: function (params) {
                                                    if (params.node.rowPinned) {
                                                      return { 'font-weight': 'bold','font-size':'16px' };
                                                    }
                                                  },
                                            }}
                                        />

                                    </div>
                                </div>
                                	
                                <div className="col-xl-12 col-lg-12 f12" style={{color:"#ff0000"}}>
                                    <b>Note:</b> For Current Day, Break Duration and Total Travel Time data will not be available
                                </div>
                            </div>

				   		</div>	
				   	</div>
				 </div>

				
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

                {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap 
                                    context={this} 
                                    mapFor={"tpttruck"} 
                                    mapinfo={this.state.mapinfo}  />

                                <div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">{(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}</label>
												<div>{this.state.routeinfo.truck_no}</div>
											</div>
										
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeinfo.start != '')?getDDMMYYYYHHMMSS(this.state.routeinfo.start):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeinfo.end != '')?getDDMMYYYYHHMMSS(this.state.routeinfo.end):"NA"}</div>
											</div>
                                            {(this.state.routeinfo.distance != "")?
                                            
											<div className="col route-block">
                                                <label className="sidebar-label">Distance</label>
                                                <div>{(this.state.routeinfo.distance != '')?this.state.routeinfo.distance+" kms":"NA"}</div>
                                            </div>  
                                            :""}
											
                                        </div>
                                    </div>
                                </div>
							</div>
                            
                        </div>
                    </div>
                :""}

            </div>
              
        );
    }
}
window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#gpsstartdate').datetimepicker({
		mask:'39-19-9999 29:59',
        format:'d-m-Y H:i'
	});
	$('#gpsenddate').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
    });

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

    $("#report_date").datepicker( {
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function(input, inst) {
            $('#ui-datepicker-div').addClass("report_date");
        }
    });

	$('#gpsstartdate').datetimepicker({
		mask:'39-19-9999 29:59',
        format:'d-m-Y H:i'
	});
	$('#gpsenddate').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}

function secondsToString(seconds)
{
	seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function minutesToDHM(n){
    var num = n;
    var d = Math.floor(num/(60*24));
    var h = Math.floor((num %(60*24))/60);
    var m = Math.floor((num %(60*24))%60);

    var dDisplay = d >= 0 ? d + (d === 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h === 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m === 1 ? " Mins " : " Mins ") : "";
    return dDisplay+ hDisplay + mDisplay
}

function timeConvert(n) {
	var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}   
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	// console.log(date.length);
	// console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function arrayMin(arr) {
	return arr.reduce(function (p, v) {
	  return ( p < v ? p : v );
	});
  }
  
  function arrayMax(arr) {
	return arr.reduce(function (p, v) {
	  return ( p > v ? p : v );
	});
  }
  $(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})

function ConvertSeconds(totalSeconds)
{
    // var days = Math.floor(num/86400);
    // var hours = Math.floor(num/3600)
    // var minutes = Math.floor(num/60);
    var minutes = Math.round((totalSeconds % 3600) / 60);
    var hours = Math.round((totalSeconds % 86400) / 3600);
    var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
    // let d = (new Date(t0)) - (new Date(t1));
    // let weekdays     = Math.floor(d/1000/60/60/24/7);
    // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
    // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
    // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
    return days+" Day(s) "+hours+" Hrs "+minutes+" mins"
}
