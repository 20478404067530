import React, { useEffect, useState } from 'react'
import '@nbai/nbmap-gl/dist/nextbillion.css'
import nextbillion, { NBMap, Marker, Popup } from '@nbai/nbmap-gl'
import './nbmaps.css'
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from './utils';

// marker

function addSimpleMarker(nbmap, mark) {
  // const popup = new Popup({ offset: 25, closeButton: false }).setText(
  //   'Simple Marker'
  // )
const marker = new Marker()
  .setLngLat(mark)
  // .setPopup(popup)
  .addTo(nbmap.map)
// marker.togglePopup()
}

// custom marker 


function addCustomMarkerForOrigin(nbmap, mark, route_details ,data) {

  var icon = require('../../assets/icons/track_start.png')

  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat(mark)
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3' src="${icon}">  Starting Point</div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key">Started at </td>
        <td class="popup-value">${route_details.start_time}</td>
      </tr>
    <tr>
      <td class="popup-key">Speed </td>
      <td class="popup-value">${data.speed} KHPM</td>
    </tr>
    <tr>
      <td class="popup-key">Area </td>
      <td class="popup-value">${data.area}</td>
    </tr>
    <tr>
     <td class="popup-key">City </td>
     <td class="popup-value">${data.city}</td>
    </tr>
    <tr>
      <td class="popup-key">State </td>
      <td class="popup-value">${data.state}</td>
    </tr>
    </table>
  </div>
</div>


`)
    .setMaxWidth('300px')

  const el = document.createElement('div')
  el.className = 'marker'
  el.style.backgroundImage =
    `url(${icon})`
  el.style.backgroundSize = 'cover'
  el.style.width = '36px'
  el.style.height = '36px'
  el.style.cursor = 'pointer'
  const marker = new Marker({
    element: el,
  })
    .setLngLat(mark)
    .addTo(nbmap.map)
    marker.setPopup(popup);
  //   marker.getElement().addEventListener('mouseenter', () => {
      marker.togglePopup();
  //   });
    
  //   marker.getElement().addEventListener('mouseleave', () => {
      marker.togglePopup();
  //   });

}

function addCustomMarkerForDealer(nbmap, consignee_coordinates) {
  // const popup = new Popup({ offset: 10, closeButton: false }).setText(
  //   'Custom Marker'
  // )
  var icon = require('../../assets/icons/customer_pin_38.png')

  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat({lat:consignee_coordinates.lat , lng : consignee_coordinates.lng})
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3 mb-1' src="${icon}"></div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key"></td>
        <td class="popup-value">${consignee_coordinates.name}</td>
      </tr>
    </table>
  </div>
</div>
`)
    .setMaxWidth('300px')


  const el = document.createElement('div')
  el.className = 'marker'
  el.style.backgroundImage =
    `url(${icon})`
  el.style.backgroundSize = 'cover'
  el.style.width = '36px'
  el.style.height = '36px'
  el.style.cursor = 'pointer'
  const marker = new Marker({
    element: el,
  })
    .setLngLat({lat:consignee_coordinates.lat , lng : consignee_coordinates.lng})
    .addTo(nbmap.map)
  marker.setPopup(popup);
  // marker.getElement().addEventListener('mouseenter', () => {
    marker.togglePopup();
  // });
  
  // marker.getElement().addEventListener('mouseleave', () => {
    marker.togglePopup();
  // });

}

function addCustomMarkerForDestination(nbmap, mark, route_details ,data) {
  // const popup = new Popup({ offset: 10, closeButton: false }).setText(
  //   'Custom Marker'
  // )
  var icon = require('../../assets/icons/truck-end.png')

  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat(mark)
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3 mb-1' src="${icon}">  Ending Point </div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key">Ended at </td>
        <td class="popup-value">${route_details.end_time}</td>
      </tr>
    <tr>
      <td class="popup-key">Speed </td>
      <td class="popup-value">${data.speed} KHPM</td>
    </tr>
    <tr>
      <td class="popup-key">Area </td>
      <td class="popup-value">${data.area}</td>
    </tr>
    <tr>
     <td class="popup-key">City </td>
     <td class="popup-value">${data.city}</td>
    </tr>
    <tr>
      <td class="popup-key">State </td>
      <td class="popup-value">${data.state}</td>
    </tr>
    </table>
  </div>
</div>
`)
    .setMaxWidth('300px')


  const el = document.createElement('div')
  el.className = 'marker'
  el.style.backgroundImage =
    `url(${icon})`
  el.style.backgroundSize = 'cover'
  el.style.width = '36px'
  el.style.height = '36px'
  el.style.cursor = 'pointer'
  const marker = new Marker({
    element: el,
  })
    .setLngLat(mark)
    .addTo(nbmap.map)
  marker.setPopup(popup);
  // marker.getElement().addEventListener('mouseenter', () => {
    marker.togglePopup();
  // });
  
  // marker.getElement().addEventListener('mouseleave', () => {
    marker.togglePopup();
  // });
}

function addCustomMarkerForBreaks(nbmap, breaks) {
  var icon = require('../../assets/icons/cf.png')
  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat({ lat: breaks.lat, lng: breaks.lng })
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3' src="${icon}"> Break</div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key">Break Start </td>
        <td class="popup-value">${breaks.break_start}</td>
      </tr>
      <tr>
        <td class="popup-key">Break End </td>
        <td class="popup-value">${breaks.break_end}</td>
      </tr>
      <tr>
        <td class="popup-key">Break Time </td>
        <td class="popup-value">${timeConvert(breaks.break_time_seconds)}</td>
      </tr>
      <tr>
      <td class="popup-key">Address </td>
      <td class="popup-value">${breaks.break_address}</td>
    </tr>
      </table>
  </div>
</div>
`)
    .setMaxWidth('300px')

  const el = document.createElement('div')
  el.className = 'marker'
  el.style.backgroundImage = `url(${icon})`
  el.style.backgroundSize = 'cover'
  el.style.width = '36px'
  el.style.height = '36px'
  el.style.cursor = 'pointer'
  const marker = new Marker({
    element: el
  })
    .setLngLat({ lat: breaks.lat, lng: breaks.lng })
    .addTo(nbmap.map)
    marker.setPopup(popup);
    // marker.getElement().addEventListener('mouseenter', () => {
      marker.togglePopup();
    // });
    
    // marker.getElement().addEventListener('mouseleave', () => {
      marker.togglePopup();
    // });
}

function addCustomMarkerForTolls(nbmap, tolls) {
  var icon = require('../../assets/icons/barrier.png')

  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat({ lat: tolls.lat, lng: tolls.lon })
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3' src="${icon}"> Toll - ${tolls.name}</div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key">Address  </td>
        <td class="popup-value">${tolls.location}</td>
      </tr>
      </table>
  </div>
</div>
`)
    .setMaxWidth('300px')

  const el = document.createElement('div')
  el.className = 'marker'
  el.style.backgroundImage =
    `url(${icon})`
  el.style.backgroundSize = 'cover'
  el.style.width = '36px'
  el.style.height = '36px'
  el.style.cursor = 'pointer'
  const marker = new Marker({
    element: el,
  })
    .setLngLat({ lat: tolls.lat, lng: tolls.lon })
    .addTo(nbmap.map)
    marker.setPopup(popup);
    // marker.getElement().addEventListener('mouseenter', () => {
      marker.togglePopup();
    // });
    
    // marker.getElement().addEventListener('mouseleave', () => {
      marker.togglePopup();
    // });
}

function addCustomMarkerForOemPlantLocation(nbmap, oem_plant_location) {
  var icon = require('../../assets/icons/business.png')

  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat({ lat: oem_plant_location.lat, lng: oem_plant_location.lng })
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3' src="${icon}"> Plant - ${oem_plant_location.name}</div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key">Address  </td>
        <td class="popup-value">${oem_plant_location.address}</td>
      </tr>
      </table>
  </div>
</div>
`)
    .setMaxWidth('300px')

  const el = document.createElement('div')
  el.className = 'marker'
  el.style.backgroundImage =
    `url(${icon})`
  el.style.backgroundSize = 'cover'
  el.style.width = '36px'
  el.style.height = '36px'
  el.style.cursor = 'pointer'
  const marker = new Marker({
    element: el,
  })
    .setLngLat({ lat: oem_plant_location.lat, lng: oem_plant_location.lng })
    .addTo(nbmap.map)
    marker.setPopup(popup);
    // marker.getElement().addEventListener('mouseenter', () => {
      marker.togglePopup();
    // });
    
    // marker.getElement().addEventListener('mouseleave', () => {
      marker.togglePopup();
    // });
}

function addCustomMarkerForOemDealerLocation(nbmap, oem_dealer_location) {
  var icon = require('../../assets/icons/customer_pin_38.png')

  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat({ lat: oem_dealer_location.lat, lng: oem_dealer_location.lng })
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3' src="${icon}"> Dealer - ${oem_dealer_location.name}</div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key">Address  </td>
        <td class="popup-value">${oem_dealer_location.address}</td>
      </tr>
      </table>
  </div>
</div>
`)
    .setMaxWidth('300px')

  const el = document.createElement('div')
  el.className = 'marker'
  el.style.backgroundImage =
    `url(${icon})`
  el.style.backgroundSize = 'cover'
  el.style.width = '36px'
  el.style.height = '36px'
  el.style.cursor = 'pointer'
  const marker = new Marker({
    element: el,
  })
    .setLngLat({ lat: oem_dealer_location.lat, lng: oem_dealer_location.lng })
    .addTo(nbmap.map)
    marker.setPopup(popup);
    // marker.getElement().addEventListener('mouseenter', () => {
      marker.togglePopup();
    // });
    
    // marker.getElement().addEventListener('mouseleave', () => {
      marker.togglePopup();
    // });
}

function addCustomMarkerForHarshBreaking(nbmap, harsh_breaking) {
  var icon1 = require('../../assets/icons/dot.png')
  var icon2 = require('../../assets/icons/gmarker.png')
  var heading = harsh_breaking.alert_id == 13? 'Harsh Breaking': 'Accelerations'
  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat({ lat: harsh_breaking.latitude, lng: harsh_breaking.longitude })
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3' src="${icon1}"> ${heading}</div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key">Truck No  </td>
        <td class="popup-value">${harsh_breaking.truck_no}</td>
      </tr>
      <tr>
        <td class="popup-key">Timestamp  </td>
        <td class="popup-value">${getHyphenDDMMYYYYHHMMSS(harsh_breaking.timestamp)}</td>
      </tr>
      <tr>
        <td class="popup-key">Transporter Code  </td>
        <td class="popup-value">${harsh_breaking.transporter_code}</td>
      </tr>
      </table>
  </div>
</div>
`)
    .setMaxWidth('300px')

  const el = document.createElement('div')
  el.className = 'marker'
  el.style.backgroundImage =
    `url(${icon2})`
  el.style.backgroundSize = 'cover'
  el.style.width = '36px'
  el.style.height = '36px'
  el.style.cursor = 'pointer'
  const marker = new Marker({
    element: el,
  })
    .setLngLat({ lat: harsh_breaking.latitude, lng: harsh_breaking.longitude})
    .addTo(nbmap.map)
    marker.setPopup(popup);
    marker.getElement().addEventListener('mouseenter', () => {
      marker.togglePopup();
    });
    
    marker.getElement().addEventListener('mouseleave', () => {
      marker.togglePopup();
    });
}

function addCustomMarkerForViewMarkers(nbmap, data) {
  var icon1 = require('../../assets/icons/dot.png')
  const popup = new Popup({ offset: 10, closeButton: false })
    .setLngLat({ lat: data.lat, lng: data.lng })
    .setHTML(`<div class="popup-card">
  <div class="popup-header-wrapper">
    <div class="popup-header"><img class='mr-3' src="${icon1}"> Current Status</div>
  </div>
  <div class="popup-body">
    <table class="popup-table">
      <tr>
        <td class="popup-key">Time </td>
        <td class="popup-value">${(data.stime)}</td>
      </tr>
      <tr>
        <td class="popup-key">Speed </td>
        <td class="popup-value">${data.speed} KHPM</td>
      </tr>
      <tr>
      <td class="popup-key">Area </td>
      <td class="popup-value">${data.area}</td>
    </tr>
    <tr>
     <td class="popup-key">City </td>
     <td class="popup-value">${data.city}</td>
    </tr>
    <tr>
    <tr>
    <td class="popup-key">State </td>
    <td class="popup-value">${data.state}</td>
  </tr>
      </table>
  </div>
</div>
`)
    .setMaxWidth('300px')

  const el = document.createElement('div')
  el.className = 'marker'
  el.style.width = '8px'
  el.style.height = '8px'
  el.style.cursor = 'pointer'
  el.style.backgroundColor = '#157254'
  el.style.borderRadius = '50%'
  const marker = new Marker({
    element: el,
  })
    .setLngLat({ lat: data.lat, lng: data.lng})
    .addTo(nbmap.map)
    marker.setPopup(popup);
    marker.getElement().addEventListener('mouseenter', () => {
      marker.togglePopup();
    });
    
    marker.getElement().addEventListener('mouseleave', () => {
      marker.togglePopup();
    });

    
    marker.getElement().style.display = 'none'
    var checkbox = document.getElementById('point-toggle');
    checkbox.addEventListener('change', function () {
      var visibility = checkbox.checked ? marker.getElement().style.display = 'block' : marker.getElement().style.display = 'none';
    });
}




// draw a polyline
function drawPolyline(nbmap, coordinates) {
  nbmap.map.addSource('polyline-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            color: '#157254' 
          },
          geometry: {
            type: 'LineString',
            coordinates: coordinates
          }
        },

      ]
    }
  })

  
 
    // you can get more information about setting the style of lines here. https://maplibre.org/maplibre-gl-js-docs/style-spec/layers/#line
  nbmap.map.addLayer({
    id: 'polyline-layer',
    type: 'line',
    source: 'polyline-source',
    paint: {
      'line-width': 3,
      // get color from the source
      'line-color': ['get', 'color']
    }
  })
}

// draw a polygon
function drawPolygon(nbmap) {
  nbmap.map.addSource('polygon-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [-118.24455192, 34.05649731],
                [-118.24646327, 34.05811205],
                [-118.24751855, 34.05716641],
                [-118.2479062, 34.05678279],
                [-118.24834231, 34.05621183],
                [-118.24870843, 34.05567655],
                [-118.24661649, 34.05436717],
                [-118.24455192, 34.05649731]
              ]
            ]
          }
        }
      ]
    }
  })

  // you can get more information about setting the style of layers here. https://maplibre.org/maplibre-gl-js-docs/style-spec/layers/
  nbmap.map.addLayer({
    id: 'polygon-layer',
    type: 'fill',
    source: 'polygon-source',
    paint: {
      // fill with green color and a opacity of 0.6
      'fill-color': '#469300',
      'fill-opacity': 0.6
    }
  })
}

// draw a circle
function GeofenceFor25km(nbmap, lat, lon) {

  var earthRadius = 6371; // Earth's radius in kilometers
  // Center longitude
  var radius = 25
  // Convert radius from kilometers to radians
  var radiusInRadians = radius / earthRadius;

  // Calculate the coordinates of the geofence boundary points
  var coordinates = [];
  for (let angle = 0; angle <= 360; angle += 10) {
    var bearing = angle * (Math.PI / 180); // Convert angle to radians

    var lat2 = Math.asin(Math.sin(lat * (Math.PI / 180)) * Math.cos(radiusInRadians) +
      Math.cos(lat * (Math.PI / 180)) * Math.sin(radiusInRadians) * Math.cos(bearing));

    var lon2 = lon * (Math.PI / 180) + Math.atan2(Math.sin(bearing) * Math.sin(radiusInRadians) * Math.cos(lat * (Math.PI / 180)),
      Math.cos(radiusInRadians) - Math.sin(lat * (Math.PI / 180)) * Math.sin(lat2));

    // Convert back to degrees
    var lat2Degrees = lat2 * (180 / Math.PI);
    var lon2Degrees = lon2 * (180 / Math.PI);

    coordinates.push([lon2Degrees, lat2Degrees]);
  }
  console.log(coordinates.length, "coordinates.length")
  nbmap.map.addSource('geofence-25km', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates]
          }
        }
      ]
    }
  })
  // you can get more information about setting the style of layers here. https://maplibre.org/maplibre-gl-js-docs/style-spec/layers/#circle
  nbmap.map.addLayer({
    id: 'geofence-layer-25km',
    type: 'fill',
    source: 'geofence-25km',
    paint: {
      'fill-color': '#71caab',
      'fill-opacity': 0.6
    }
  })
}

function GeofenceFor5km(nbmap, lat, lon) {

  var earthRadius = 6371; // Earth's radius in kilometers
  // Center longitude
  var radius = 5
  // Convert radius from kilometers to radians
  var radiusInRadians = radius / earthRadius;

  // Calculate the coordinates of the geofence boundary points
  var coordinates = [];
  for (let angle = 0; angle <= 360; angle += 10) {
    var bearing = angle * (Math.PI / 180); // Convert angle to radians

    var lat2 = Math.asin(Math.sin(lat * (Math.PI / 180)) * Math.cos(radiusInRadians) +
      Math.cos(lat * (Math.PI / 180)) * Math.sin(radiusInRadians) * Math.cos(bearing));

    var lon2 = lon * (Math.PI / 180) + Math.atan2(Math.sin(bearing) * Math.sin(radiusInRadians) * Math.cos(lat * (Math.PI / 180)),
      Math.cos(radiusInRadians) - Math.sin(lat * (Math.PI / 180)) * Math.sin(lat2));

    // Convert back to degrees
    var lat2Degrees = lat2 * (180 / Math.PI);
    var lon2Degrees = lon2 * (180 / Math.PI);

    coordinates.push([lon2Degrees, lat2Degrees]);
  }

  console.log(coordinates.length, "coordinates.length_5km")
  nbmap.map.addSource('geofence-5km', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [coordinates]
          }
        }
      ]
    }
  })

  // you can get more information about setting the style of layers here. https://maplibre.org/maplibre-gl-js-docs/style-spec/layers/#circle
  nbmap.map.addLayer({
    id: 'geofence-layer-5km',
    type: 'fill',
    source: 'geofence-5km',
    paint: {
      'fill-color': '#f3a228',
      'fill-opacity': 0.6
    }
  })



}




// draw symbol
function drawSymbol(nbmap) {
  nbmap.map.addSource('symbol-source', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [-118.24501946, 34.05507342]
          }
        }
      ]
    }
  })
  // you can get more information about setting the style of layers here. https://maplibre.org/maplibre-gl-js-docs/style-spec/layers/#symbol
  nbmap.map.addLayer({
    id: 'symbol-layer',
    type: 'symbol',
    source: 'symbol-source',
    layout: {
      'symbol-placement': 'point',
      visibility: 'visible',
      'symbol-spacing': 1,
      'icon-allow-overlap': true,
      'icon-image': 'nb-logo',
      'icon-size': 0.16
    }
  })
}

const NextBillionMaps = (props) => {
  console.log(props, "nbmap_1729")
  var { mapinfo , oem_plant_location , oem_dealer_location , harsh_breaking} = props
  var height  = '70%'
  var zoom  = 7
  if(!["" , undefined , null , "undefined"].includes(mapinfo)){
    var coords = mapinfo.coords
    var breaks = mapinfo.breaks
    var tolls = mapinfo.tolls
    var route_details = mapinfo.route_details
    var consignee_coordinates = mapinfo.consignee_coordinates
    var center = {}
    var coordinates = []
    var origin = {}
    var destination = {}
    coords.map((e, index) => {
      if (index == 0) {
        origin = { lat: e.lat, lng: e.lng }
      }
      if(index == parseInt(coords.length/2)){
        center = { lat: e.lat, lng: e.lng }
      }
      if (index == coords.length - 1) {
        destination = { lat: e.lat, lng: e.lng }
      }
      coordinates.push([parseFloat(`${e.lng}`), parseFloat(`${e.lat}`)])
    })
  }
  console.log(origin , center , destination , "orgin,center,destination")
  if(!["" , undefined , null , "undefined"].includes(oem_plant_location)){
      height = '70%'
      center = {lat : oem_plant_location[0].lat , lng : oem_plant_location[0].lng}
  }
  if (!["", undefined, null, "undefined"].includes(oem_dealer_location)) {
    height = '70%'
    center = { lat: oem_dealer_location[0].lat, lng: oem_dealer_location[0].lng }
  }
  if (!["", undefined, null, "undefined"].includes(harsh_breaking)) {
    height = '100%'
    center = { lat: harsh_breaking.latitude, lng: harsh_breaking.longitude }
  }

  var style = 'https://api.nextbillion.io/maps/streets/style.json'
  
  // const [route_info , routeInfo] = useState(route_info)
  useEffect(() => {
    nextbillion.setApiKey('2faa75eaaca14429ba7e6646e5854fb6')
    const nbmap = new NBMap({
      container: 'map',
      style: style,
      zoom: zoom,
      center: center,
      styleSwitcherControl:true,
      attributionControl: false,
    })

    nbmap.on('load', () => {
      if (!["", undefined, null, "undefined"].includes(mapinfo)) {
        drawPolyline(nbmap, coordinates)
        mapinfo.coords.map(e => {
          addCustomMarkerForViewMarkers(nbmap, e)
        })
        if (!["", undefined, 'undefined', null].includes(breaks)) {
          breaks.map(e => {
            addCustomMarkerForBreaks(nbmap, e)

          })
        }
        if (!["", undefined, 'undefined', null].includes(tolls)) {
          tolls.map(e => {
            addCustomMarkerForTolls(nbmap, e)
          })
        }

        if (!["", undefined, null, "undefined"].includes(consignee_coordinates)) {
          addCustomMarkerForDealer(nbmap, consignee_coordinates)
          GeofenceFor25km(nbmap, consignee_coordinates.lat, consignee_coordinates.lng)
          GeofenceFor5km(nbmap, consignee_coordinates.lat, consignee_coordinates.lng)
        }

      }
      if (!["", undefined, null, "undefined"].includes(oem_plant_location)) {
        addCustomMarkerForOemPlantLocation(nbmap, oem_plant_location[0])
      }
      if (!["", undefined, null, "undefined"].includes(oem_dealer_location)) {
        addCustomMarkerForOemDealerLocation(nbmap, oem_dealer_location[0])
      }

      if (!["", undefined, null, "undefined"].includes(harsh_breaking)) {
        addCustomMarkerForHarshBreaking(nbmap, harsh_breaking)
      }
      addCustomMarkerForOrigin(nbmap, origin, route_details , mapinfo.coords[0])
      addCustomMarkerForDestination(nbmap, destination, route_details , mapinfo.coords[coords.length -1])

    })

    document.getElementById('map-view').addEventListener('click', function () {
      nbmap.map.setStyle('https://api.nextbillion.io/maps/streets/style.json')

      setTimeout(() => {
        var source = nbmap.map.getSource('polyline-source')
        if (["", undefined, null, "undefined"].includes(source)) {
          drawPolyline(nbmap, coordinates)
        }

      }, 500);
      setTimeout(() => {
        var source = nbmap.map.getSource('polyline-source')
        if (["", undefined, null, "undefined"].includes(source)) {
          drawPolyline(nbmap, coordinates)
        }

      }, 500);
    })

    document.getElementById('satellite-view').addEventListener('click', function () {
      nbmap.map.setStyle('https://api.nextbillion.io/maps/hybrid/style.json')

      setTimeout(() => {
        var source = nbmap.map.getSource('polyline-source')
        if (["", undefined, null, "undefined"].includes(source)) {
          drawPolyline(nbmap, coordinates)
        }

      }, 500);
      setTimeout(() => {
        var source = nbmap.map.getSource('polyline-source')
        if (["", undefined, null, "undefined"].includes(source)) {
          drawPolyline(nbmap, coordinates)
        }

      }, 500);


    })

    nbmap.map.addControl(new nextbillion.maps.FullscreenControl({ container: document.querySelector('map') }));
    nbmap.map.addControl(new nextbillion.maps.NavigationControl({ container: document.querySelector('map') }));



    var customControlContainer = document.querySelector('.map-control');
    nbmap.map.getContainer().appendChild(customControlContainer);
    
  }, [])

  return (
    <div style={{position:"relative" , height:height}}>
      <ul>
        <li className="float-left">
          <input type="checkbox" id="point-toggle" /> <span style={{ fontSize: "14px" }}>View Markers</span>
        </li>
      </ul>
         <div
      style={{
        width: '100%',
        height: '100%',

      }}
      id="map"></div>
      <div id='map-buttons' className='map-control'>
        <button id='map-view' className='map-button1'>
          Map
        </button>
        <button  id='satellite-view' className='map-button2'>
          Satellite
        </button>
      </div>
    </div>
   

  )
}
export default NextBillionMaps

function timeConvert(n) {
  var num = n;
  var hours = (num / (3600));
  var rhours = parseInt(hours);
  var minutes = (num - (rhours * 3600)) / (60);
  var rminutes = Math.round(minutes);
  return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}