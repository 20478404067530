import React, { Component } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import redirectURL from "../redirectURL";
import SweetAlert from "react-bootstrap-sweetalert";
import e from 'jquery-datetimepicker';
import 'react-datetime/css/react-datetime.css';
import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
} from "../common/utils";
import $ from "jquery";
import * as Datetime from "react-datetime";
import CountUp from "react-countup";
var moment = require("moment");
var googleAnalytics = require("../common/googleanalytics");
var infoBox = require("../common/google-infowindow");




class TruckStatusOneMonthData extends Component {
    state = {
        pivotmodeis: false,
        usergridstate1: [],
        pivotcols: [],
        show: false,
        type: "",
        title: "",
        modules: AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
        },
        frameworkComponents: {},
        rowData: [],
        rowgpspacketData: null,
        rowModelhighchartoptionsType: "enterprise",
        rowGroupPanelShow: "always",
        animateRows: true,
        debug: true,
        showToolPanel: true,
        adaniCheck: 0,
        suppressAggFuncInHeader: true,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left",
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center",
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" },
            ],
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },
        usergridstate: [],
        screenurl: "",
        screentitle: "",
        pagetitle: "",
        eventGridAction: "gridAction",
        defaulttab: "all",
        loadshow: "show-n",
        overly: "show-n",
        columnwithDefs: [],
        sliderTranslate: "",
        trucks_without_driver: [],
        respData: [],
        allrowData: [],
        truckNoList: [],
        locationDetails : [],
    };

    async componentDidMount() {
        loadDateTimeScript();
        

        
        

        var edate = moment.parseZone().format('YYYY-MM-DD');
        var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD')
        $("#fromOsDate").val(sdate);
        $("#toOsDate").val(edate);
        
        this.onLoadData(sdate , edate)
    }

    onLoadData = async (from_date , to_date) => {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var columns = [{
            headerName: "Status",
            field: "truck_status",
            width: 200
        }]

        var diff = moment.parseZone(new Date(to_date+" 00:00:00")).diff(moment.parseZone(new Date(from_date +" 00:00:00")) , "days")
        console.log(diff , "diff")
        for (let i = 0; i <= diff; i++) {
            var field_name =  moment.parseZone(new Date(to_date+" 00:00:00")).subtract(diff - i, "days").format('YYYY-MM-DD')
            columns.push({
                headerName:field_name,
                field:field_name,
                width: 120,
            })

        }
        
        this.setState({
            columnwithDefs: columns,
        })

        var params = {
            from_date: from_date,
            to_date: to_date,
            transporter_code: JSON.parse(localStorage.getItem("transportercode")),
        }

        var group_code = localStorage.getItem("vehicle_group")
        if (![undefined, null, "", "undefined"].includes(group_code)) {
            params.group_code = group_code
        }
        console.log(params , "params_163")
        await redirectURL.post("dashboard/getTruckStatusData", params).then(response => {

            var distinct_status = response.data.distinct_status
            var records = response.data.records.filter(e => e.truck_status != "")

            var rowData = []
            var locationDetails=[]

            var group_by_status = groupBy(records, data => data.truck_status)
            group_by_status.forEach((status_values, status_keys) => {
                var row = {
                    truck_status: status_keys
                }
                var location = {
                    truck_status : status_keys
                }
                var group_by_status_date = groupBy(status_values, data => data.status_date)
                group_by_status_date.forEach((date_values, date_keys) => {
                    var date = getHyphenYYYYMMDD(date_keys)
                    row[date] = date_values.length
                    location[date]=date_values

                })

                rowData.push(row)
                locationDetails.push(location)

            })

            console.log(rowData , columns , "row_Data")

            this.setState({
                rowData : rowData,
                locationDetails:locationDetails,
                loadshow:"show-n",
                overly:'show-n'
            })
        })

    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    };


    closeAlert = () => {
        this.setState({
            show: false,
        });
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            sliderTranslate: "",
            sliderRso: "",
            sliderUpdate: "",
            sliderBulk: "",
        });
    }



    onClickBack = () => {
        window.location.href = "/tptlogin/gpsintegrationstatus";
    }


    onClickFilterOverspeedReport = () => {
        var  sdate =$("#fromOsDate").val();
        var edate = $("#toOsDate").val();
        
        this.onLoadData(sdate , edate)
    }

    onCellClicked = (e) =>
	{
        if(e.colDef.field !="truck_status")
        {
            console.log(e,"truck status")
            var clickedStatus = e.data.truck_status;
            var allData = this.state.locationDetails;
            var clickedField = e.colDef.field;
            var filteredData = allData.filter((f) => f.truck_status == clickedStatus);
            console.log(filteredData,"filteredData")
            if(filteredData.length > 0 )
            {
                var filData = filteredData[0][clickedField];
                console.log(filData,"filData")
                // var truckstatusdata = []
                // var trucksdata = filData.map((e) => {
                //     console.log(e,"trucks eeeee")
                //     truckstatusdata.push({"label":e.truck_no,"value":e.truck_no})
                // })
                // console.log(truckstatusdata,"trucksdata")
                this.setState({
                    trucks_without_driver:filData,
                    sliderTranslate:"slider-translate-60p",
                    overly:"show-m"
                });
                console.log(this.state.trucks_without_driver,"trucks_without_driver")
            }
        }
        
        // if(e.data.truck_status == "Without Driver")
        // {
        //     if(e.colDef.field == "yesterday_count")
        //     {
        //         
        //     }
        // }
	}

    // onBtExport = () => {
    //     // this.gridApi.exportDataAsExcel({skipColumnHeaders : false, skipColumnGroupHeaders : false})
    //     let params = getParams();
    //     this.gridApi.exportDataAsExcel(params);
    //     };



    render() {

        let columnwithtrucksDefs = [
            {
                headerName: `Truck No`,
                field: "truck_no",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter",
                // valueGetter: function(params){
                //     console.log(params,"params data")
                //     return params.data.tommorow_count.length
                // }
            },
            {
                headerName: `Location`,
                field: "city",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter",
                valueGetter: function(params){
                    // console.log(params,"params data")
                    if(params.data.city != undefined && params.data.city != "")
                    {
                        return params.data.city
                    }
                    else
                    {
                        return params.data.current_location
                    }
                    // return params.data.tommorow_count.length
                }
            },
    
        ]


        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">

                            <div className="card-header" style={{ display: 'flex', flexDirection: 'column' }}>

                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                    <h5>
                                        <i className=" icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
                                        <span>Truck Status </span>
                                    </h5>
                                    <button className="btn btn-primary p-2" onClick={this.onClickBack}>Back</button>
                                </div>

                                <br />


                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', }}>

                                    <div class="col-xl-2 col-lg-2 form-group">
                                        <label>From Date</label>
                                        <input type="text" name="date" autoComplete="off" id="fromOsDate" className="os_filters_date form-control" required readOnly/>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 form-group">
                                        <label>To Date</label>
                                        <input type="text" name="date" autoComplete="off" id="toOsDate" className="os_filters_date form-control" required readOnly/>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 mr-0 form-group">
                                        <label>&nbsp;</label>
                                        <br />
                                        <button type="button" onClick={this.onClickFilterOverspeedReport} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>

                            </div>




                            <div className="card-body">
                                <div
                                    id="myGrid"
                                    style={{ height: "450px", width: "100%" }}
                                    className="ag-theme-balham"
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        statusBar={this.state.statusBar}
									    sideBar={this.state.sideBar}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        suppressRowClickSelection={true}
                                    onCellClicked={this.onCellClicked}
                                    // rowSelection={this.state.rowSelection}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + this.state.sliderTranslate}>
                    <div className="slide-r-title">
                        <h4>Trucks Without Driver
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        {/* <button className="float-right export-btn white btn-danger" onClick={() => this.onBtExport()} 
                        style={{marginRight:"1em", padding:"6px"}}> Export to Excel</button> */}
                       </h4>
                       
                    </div>
                    
                    <div className="slide-r-body" style={{ position: "relative" }}>
                    {/* <button className="export-btn white btn-danger my-2" onClick={() => this.onBtExport()}> Export to Excel</button> */}
                        {/* {this.state.trucks_without_driver} */}
                                <div
                                    id="myGrid"
                                    style={{ height: "450px", width: "100%" }}
                                    className="ag-theme-balham"
                                    >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithtrucksDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.trucks_without_driver}
                                        enableCharts={false}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady1}
                                        statusBar={this.state.statusBar}
									    sideBar={this.state.sideBar}
                                        onGridState={this.onGridState1}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                    /> 
                                </div>
                        {/* {(this.state.trucks_without_driver.map(function(e){
                            return <div className="col-xl-12 col-lg-12">
                                <h6>{e}</h6>
                            </div>
                        }))} */}
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function getParams() {

    return {
        allColumns: false,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: false,
        skipGroups: true,
        skipHeader: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
    };

}
export default TruckStatusOneMonthData

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    // console.log(date.length);
    // console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y H:i'
    });
    $('.os_filters_date').datetimepicker({
        format: 'Y-m-d',
        timepicker: false,
        // minDate:true,

    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    // script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

$(document).on("change", "#toOsDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#fromOsDate").val();
    var cargoDate = $("#toOsDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    // var startDate = new Date(bookingDate);
    // var endDate = new Date(cargoDate);
    // var startTime = startDate.getTime();
    // var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    // console.log(bookingDate,cargoDate)
    // if(![undefined,null,"","undefined"].includes(bookingDate) && ![undefined,null,"","undefined"].includes(cargoDate)){
    //     if (bookingDate > cargoDate) {
    //         $("#toOsDate").val("");
    //         alert("To Date should be greater than or equal to From Date");
    //     }
    
    // }
})

$(document).on("change", "#fromOsDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#fromOsDate").val();
    var cargoDate = $("#toOsDate").val();
    console.log(bookingDate, cargoDate)
    // var bookingDate = bookingDate[2]+"-"+bookingDate[1]+"-"+bookingDate[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    // var startDate = new Date(bookingDate);
    // var endDate = new Date(cargoDate);
    // var startTime = bookingDate.getTime();
    // var endTime = cargoDate.getTime();
    // console.log(startTime,endTime);
    // if(![undefined,null,"","undefined"].includes(bookingDate) && ![undefined,null,"","undefined"].includes(cargoDate)){
    //     if (bookingDate > cargoDate) {
    //         $("#fromOsDate").val("");
    //         alert("From Date should be less than equal to To Date");
    //     }
    // }
    
})

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
// // 
// function loadDateTimeScript(){
//     // alert("timeDate");
//     $('.datetimepicker_mask').datetimepicker({
//         format:'d-m-Y H:i'
//     });
//     $('.os_filters_date').datetimepicker({
//         format:'Y-m-d H:i',
//         timepicker:true
//     });
//     var index  =window.document.getElementsByTagName("script")[1]
//     var script = window.document.createElement("script")
//     script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
//     //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }

$(document).on("change", "#toOsDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#fromOsDate").val();
    var cargoDate = $("#toOsDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if (startTime > endTime) {
        $("#toOsDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#fromOsDate", function (e) {
    e.preventDefault();
    var bookingDate = $("#fromOsDate").val();
    var cargoDate = $("#toOsDate").val();
    console.log(bookingDate, cargoDate)
    // var bookingDate = bookingDate[2]+"-"+bookingDate[1]+"-"+bookingDate[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    // var startDate = new Date(bookingDate);
    // var endDate = new Date(cargoDate);
    // console.log(bookingDate,cargoDate)
    // var startTime = bookingDate.getTime();
    // var endTime = cargoDate.getTime();
    // console.log(startTime,endTime);
    if (bookingDate > cargoDate) {
        $("#fromOsDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}