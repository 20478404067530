import React, {Component} from 'react';

import { Button, Form, FormGroup, Label, Input, FormText,Alert, UncontrolledAlert } from 'reactstrap';
import _ from "lodash";
import Select from 'react-select';
var redirectURL = require('../redirectURL');
var images = require.context('../../assets/images/', true);
var googleAnalytics = require("../common/googleanalytics");

class TPTSubUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "New User",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            firstname:'',
            lastname:'',
            email:'',
            password:'',
            dept_code:'',
            alldepartments:[],
            alertFade:true,
            formMessage:'',
            formStatus:'',
            groups:[],
            group:[{value:"", label:"All"}]
        };
        this.changeHandler=this.changeHandler.bind(this);
        this.formHandler = this.formHandler.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        this.logPageView(); // Log page view to GA
        var transporter_code = localStorage.getItem("transportercode")
        var parameters = {
            transporter_code:transporter_code
        }
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)

        redirectURL.post("/dashboard/tptgroups",parameters)
        .then((response) => {
            var records = response.data.records
            this.setState({
                groups:records,
                // rowData:records
            })
        })
    }
   
    changeHandler(event){
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]:value
        });
    }
    formHandler(event){
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUserRegistration,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //console.log(event.target);

   
       // console.log("Form data ",data);
       var email = this.state.email.replace(/\s/g,"").toLowerCase();
       var regions = []
       if(this.state.group.length > 0)
       {
            this.state.group.map((item) => {
                regions.push(item.value)
            })
       }
       var formdata = {
        firstname:this.state.firstname,
        lastname:this.state.lastname,
        email: email,
        password:this.state.password,
        vehicle_group:regions,
        transporter_code:localStorage.getItem('transportercode'),
       }
    //    console.log("formdata ", formdata);
        redirectURL.post('/consignments/saveTptSubUser',formdata)
        .then((response) => {
            this.setState({
                formMessage:response.data.message,
                formStatus:response.data.status,
                alertFade:false,
                firstname:'',
                lastname:'',
                email:'',
                password:'',
                dept_code:'',
            });
        
        })
        .catch((error) => {
            console.log(error)
        })
    }
    selectGroups(){
        var items=[]
        if(this.state.groups.length > 0)
        {
            this.state.groups.map((item) => {
                items.push({
                    value:item.group_code,
                    label:item.group_title
                })
            })
        }
        return items;
    }
    onChangeGroup(group){
		this.setState(
			{ group },
			() => console.log(`group selected:`, this.state.group)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    render() {
        let style = this.state.style;
        const background = require('../../assets/images/auth-layer.png')

        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                <div className="page-wrapper">

                    <div className="container-fluid">

                        {/*sign up page start*/}
                        <div className="authentication-main">
                            <div className="row">
                                
                                <div className="col-md-2 p-0"></div>
                                <div className="col-md-8 p-0">
                                    <div className="auth-innerright">
                                        <div className="authentication-box">
                                            <h3 className="text-center">ADD SUB USER</h3>
                                            <div className="card mt-4 p-4">
                                            
                                                {this.state.formStatus != ""?
                                                        <div>
                                                                {(this.state.formStatus == 'Success')?
                                                                    <Alert color="secondary" fade={this.state.alertFade}>
                                                                        
                                                                    <i className="icon-thumb-up"></i>   {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                    {/* &nbsp; An email has been sent to you. Please Verify before logging in. */}
                                                                </Alert>
                                                                :
                                                                <Alert color="danger" fade={this.state.alertFade}>
                                                                    <i className="icon-alert"></i>  {this.state.formMessage}
                                                                    {this.state.alertFade=true}
                                                                </Alert>
                                                                }
                                                        </div>
                                                    :""
                                                }
                                                <form className="theme-form" onSubmit={this.formHandler}>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">First Name</label>
                                                                <input required type="text" className="form-control" name="firstname" value={this.state.firstname} onChange={this.changeHandler} placeholder="Enter First Name"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="col-form-label">Last Name</label>
                                                                <input required type="text" className="form-control" name="lastname" value={this.state.lastname} onChange={this.changeHandler} placeholder="Enter Last Name"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Email Address</label>
                                                        <input type="email" required className="form-control" name="email" value={this.state.email} onChange={this.changeHandler} placeholder="Email Address"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">Password</label>
                                                        <input type="password" required className="form-control" name="password" value={this.state.password} onChange={this.changeHandler} placeholder="**********"/>
                                                    </div>
                                                    <div className="form-group mt-20p">
                                                        <label className="">Select Group <span style={{color:"#FF0000"}}>*</span></label> 
                                                        <Select 
                                                            isMulti={true}
                                                            placeholder="Select"
                                                            value={this.state.group}
                                                            onChange={this.onChangeGroup.bind(this)} 
                                                            options={this.selectGroups()} 
                                                        required/>
                                                    </div>
                            
                                                    <div className="form-row">
                                                        <div className="col-sm-3">
                                                            <button type="submit" className="btn btn-secondary">Register
                                                            </button>
                                                        </div>
                                                        

                                                    </div>
                                                    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        {/*sign up page Ends*/}

                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


export default TPTSubUsers;