import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 

import Modal from 'react-responsive-modal';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import MarkerClusterer from "@google/markerclusterer";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import Constant from "../common/googlemapkey";
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Select from 'react-select';
import redirectURL from '../redirectURL';
var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var viewMarkersArr=[];
var coords_copy = [];
var geoFencingArea;
var markersArray;
var map;
var route_deviation_polyline_dict = {1: "route_deviation_polyline_1",2: "route_deviation_polyline_2",3: "route_deviation_polyline_3"}
var pathcolor = ["#157254","blue","red"]
class HeatMapStoppageView extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			tolls : [],
			proptruckno:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:false,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
			open: false,
			open1: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
			  filter: true,
			  resizable: true

			},
			rowData: null,
			rowBreakData:[],
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
			  customLoadingCellRenderer: CustomLoadingCellRenderer
		  },

			animateRows: true,
			debug: true,
			showToolPanel: false,
		  uppressAggFuncInHeader: true,
			  
		  childconfs: [],
		  childrow:[],
		  rowModelType: "serverSide",
		  paginationPageSize:50,
		  cacheBlockSize: 10,
		  maxBlocksInCache: 1,
		  showMarkers:0,
		  statusBar: {
			  statusPanels: [
				  {
				  statusPanel: "agTotalAndFilteredRowCountComponent",
				  align: "left"
				  },
				  {
				  statusPanel: "agTotalRowCountComponent",
				  align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
			  ]
		  },
		  sideBar: {
			  toolPanels: [
				  {
				  id: "columns",
				  labelDefault: "Columns",
				  labelKey: "columns",
				  iconKey: "columns",
				  toolPanel: "agColumnsToolPanel"
				  },
				  {
				  id: "filters",
				  labelDefault: "Filters",
				  labelKey: "filters",
				  iconKey: "filter",
				  toolPanel: "agFiltersToolPanel"
				  }
			  ]
		  },
		  detailCellRendererParams:{},
		  rowClassRules: {
			  "highlitRow": "data.transit_delay == 1",
			  "green":"data.status >= 3"

		  },
		  data_type : "",
		  selectedorigin:"",
		  selecteddestination:"",
          returnTripsDashboardRowData: [],
          mapviewfor:"",
          mapinfo:[],
          duration:{value:"week",label:"Last 7 Days"}
		};
        this.plotMultiColorPolyline = this.plotMultiColorPolyline.bind(this)
        this.onLoadData = this.onLoadData.bind(this);
	}
	componentDidMount(){
        // loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMapStoppage");
	
        // this.renderMapStoppage()
        if(localStorage.getItem("transportercode") != undefined)
		{
			var transporter = JSON.parse(localStorage.getItem("transportercode"))
		}
		else{
			var transporter = "";
		}
		if(Array.isArray(transporter))
		{
			var parameters = {
				transporter_code : transporter[0]
			}
		}
		else{
			var parameters = {
				transporter_code : transporter
			}
        }
        parameters.from_date = moment.parseZone().subtract(1,"weeks").format("YYYY-MM-DD");
		parameters.to_date = moment.parseZone().format("YYYY-MM-DD");
        this.onLoadData(parameters);
    }
    
    onLoadData(parameters){
        redirectURL.post("/dashboard/stoppageheatmaps",parameters)
		.then((response) => {
			// console.log("Overspeeding ", response.data)
			var records = response.data;
			

			this.setState({
				mapinfo:records
			})
			this.renderMapStoppage();
		})
    }

	// componentWillReceiveProps(newprops){
	//     // loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMapStoppage");
	// 	// if(newprops.mapFor != this.state.mapviewfor)
	// 	// {
	// 		this.renderMapStoppage()
	// 		this.setState({
	// 			mapviewfor:newprops.mapFor
	// 		})
	// 	// }
		
	// }
    renderMapStoppage = () => {   
        loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing,visualization&callback=initMapStoppage");
		
		window.initMapStoppage = this.initMapStoppage
	}
	
	
plotMultiColorPolyline(arr2,map,bounds)
{
	if(arr2.length)
	{
		var data_type_list = [...new Set(arr2.map(item => item.type))];
		if(data_type_list.includes("transshipment"))
		{
			var data_type = "transshipment"
		}
		if(data_type_list.includes("rake"))
		{
			var data_type = "rake"
		}
		this.setState({
			data_type : data_type
		})
	}
	
	if(this.props.stepcoords == undefined)
	{
		if(arr2.length <= 1000)
		{
			var icon_cnt = 60;
			var icon_divisible = 60;
		}
		else
		{
			var icon_cnt = 500;
			var icon_divisible = 500;
		}
	}
	else
	{
		var icon_cnt = 1;
		var icon_divisible = 1;
	}
	//console.log(arr2, "coords")
	
	for(var i=0;i< arr2.length-1;i++)
	{
		var locationLatLng = [];
		locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
		locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
		if(icon_cnt % icon_divisible == 0)
		{
			var lineicon = [{
						icon: {
								path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
								strokeColor:'#FF0000',
								fillColor:'#FF0000',
								fillOpacity:1,
								strokeWeight: 0.5
						},
						repeat:'100px',
						path:[]
					}];
		}else{lineicon=[]}
		icon_cnt = icon_cnt + 1
		var consignments_missing_route_line = new window.google.maps.Polyline({
			map:map,
			path : locationLatLng,
			strokeColor: '#157254',
			strokeOpacity: 1.0,
			strokeWeight: 2,
			icons: lineicon
		});
		if(arr2[i].msgtype == "G")
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#452a68",
				strokeWeight: 2,
			})
		}
		
		// if(arr2[i].set == undefined && arr2[i].msgtype != "G")
		// {
		// 	consignments_missing_route_line.setOptions({
		// 		strokeColor : "#157254"
		// 	})
		// }
		// else if(arr2[i].set == 1 && arr2[i].msgtype != "G")
		// {
		// 	consignments_missing_route_line.setOptions({
		// 		strokeColor : "#157254"
		// 	})
		// }
		// else if(arr2[i].set == 2 && arr2[i].msgtype != "G")
		// {
		// 	consignments_missing_route_line.setOptions({
		// 		strokeColor : "#0000ff",
		// 		strokeWeight: 5.5,
		// 	})
		// }
		// else if(arr2[i].set == 3 && arr2[i].msgtype != "G")
		// {
		// 	consignments_missing_route_line.setOptions({
		// 		strokeColor : "#980000",
		// 		strokeWeight: 5.5,
		// 	})
        // }
        
        
		if(arr2[i].set == undefined)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#157254"
			})
		}
		else if(arr2[i].set == 1)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#157254"
			})
		}
		else if(arr2[i].set == 2)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#0000ff",
				strokeWeight: 2,
			})
		}
		else if(arr2[i].set == 3 && arr2[i].directly_to_dealer == 0)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#980000",
				strokeWeight: 2,
			})
        }
		else if(arr2[i].set == 3 && arr2[i].directly_to_dealer == 1)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#452a68",
				strokeWeight: 2,
			})
        }
		else if(arr2[i].set == 4)
		{
			consignments_missing_route_line.setOptions({
				strokeColor : "#452a68",
				strokeWeight: 2,
			})
        }
        
		bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
	}
}
plotBoundaryLines(boundaryarr){
	//console.log("boundaries ",boundaryarr)
	if(boundaryarr.length > 0)
	{
		
		boundaryarr.map((item) => {
			if(item.length>0)
			{
				var line=new window.google.maps.Polyline(
					{
						map:map,
						strokeColor: '#0ABB50',
						strokeOpacity: 1.0,
						strokeWeight: 2.5
					});
				for(var i =0; i<item.length;i++)
				{
					var locationLatLng = [];
					locationLatLng.push(new window.google.maps.LatLng(item[i].lat,item[i].lng));
					var consignments_missing_route_line = new window.google.maps.Polyline({
						map:map,
						path : locationLatLng,
						strokeColor: '#0ABB50',
						strokeOpacity: 1.0,
						strokeWeight: 2.5,
						fillColor: '#1D9567',
						fillOpacity: 0.8
						//icons: lineicon
					});
					
					var path=line.getPath().getArray();								
					let latLng=new window.google.maps.LatLng(item[i].lat,item[i].lng);
					path.push(latLng);
					line.setPath(path);
				}


				// var geoFencingArea = new window.google.maps.Polygon({
				// 	//map:map,
				//   paths: item,
				//   editable: true,
				//   strokeColor: '#1D9567',
				//   strokeOpacity: 0.70,
				//   strokeWeight: 0,
				//   fillColor: '#1D9567',
				//   fillOpacity: 0.35,
				//   draggable:true
				  
				// });
				// geoFencingArea.setMap(map);
				// //console.log("test ",geoFencingArea.getPath());
				// //window.google.maps.event.addListener(geoFencingArea, "dragend", getPolygonCoords);
	  
				// window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at',getPolygonCoords);
				// window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);
				


				
			}
		})
	}
}

initMapStoppage = () => 
{
	var currentwindow;
	var dealer='';
    var tolls = [];
    var coords_list=[]
    var chokepoints = [];
    
	var arr=[];
	// console.log("dealerCode ",this.props.mapFor)
		var jsondata = this.state.mapinfo;
		// var locations = jsondata.breaks;
		var arr = jsondata;
		// coords_copy = arr;
	
	// console.log("ARR ", arr)
	if( arr == undefined)
	{
		var lt=28.4519751;
		var ln=77.0310713;
	}
	else
	{
		if(arr.length > 0 && typeof arr.length != undefined)
		{
			// if(this.props.mapFor == 'stoppage')
			// {
				var lt=arr[0].lat;
				var ln=arr[0].lng;
			// }
			// else{
			// 	var lt=arr[0].lat;
			// 	var ln=arr[0].lng;
			// }
		}
		else{
			
            var lt=28.4519751;
            var ln=77.0310713;
			
		}
	}

	try{
		var mapOptions = {
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels:true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt,ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
				
	}
	catch(e){
		var mapOptions = {
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels:true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(28.4519751,77.0310713),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
				
	}
	try{
		map = new window.google.maps.Map(document.getElementById('stoppagemap'), mapOptions);
	}catch(e){}
	
	var bounds = new window.google.maps.LatLngBounds();
	bounds.extend(new window.google.maps.LatLng(lt,ln));
		// Create our info window content
	var currentinfowindow = null;
	var line=new window.google.maps.Polyline(
	{
		map:map,
		strokeColor: '#157254',
		strokeOpacity: 1.0,
		strokeWeight: 0.5,
		icons: [{
				icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor:'#ff8c52',
						fillColor:'#ff8c52',
						fillOpacity:1,
						strokeWeight: 0.5
				},
				repeat:'100px',
				path:[]
			}]
	});
	if(arr == undefined){

	}
	else{
		try{
			if(arr.length > 0)
			{

				var allpoints = [];
				var arr1=[];
				//console.log("Total ",ratio);
				// if(this.state.timelinesmarkers.length > 0)
				// {
				// 	arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
				// 		return (index % ratio == 0);
				// 	} );
				// }
				
				var arr2=[];
				var darr=[];
				if(arr.length > 0)
				{
					
                    arr2 = arr
					//var arr1 = this.state.timelinesmarkers;
				}

                /* ROUTE DEVIATION MAP */
                // console.log("Array list ",arr)
				// if(this.props.mapFor == "stoppage")
				// {
                    var heatMapData=[]
                    arr.map(function(e,index){
                        heatMapData.push(new window.google.maps.LatLng(e.lat, e.lng));
                        // var tollMarker = new window.google.maps.Marker({
                        //     position: new window.google.maps.LatLng(e.lat, e.lng),
                        //     icon:{
                        //         path: window.google.maps.SymbolPath.CIRCLE,
                        //         strokeColor:'#E1AC14',
                        //         fillColor:'#E1AC14',
                        //         fillOpacity:0.5,
                        //         strokeWeight: 1,
                        //         // scaledSize: new window.google.maps.Size(10, 10), // scaled size
                        //         scale:5,
                        //     },
                        //     map: map,
                        // });

                        // var markerLatlng1;
                        // markerLatlng1 = new window.google.maps.LatLng(e.lat,e.lng);
                        // new window.google.maps.Circle({
                        //     strokeColor: '#FF0000',
                        //     strokeOpacity: 0.5,
                        //     strokeWeight: 0.5,
                        //     fillColor: '#FF0000',
                        //     fillOpacity: 0.5,
                        //     map: map,
                        //     center: markerLatlng1,
                        //     radius: 3000
                        // });
                        
				        bounds.extend(new window.google.maps.LatLng(e.lat,e.lng));
                    })  
				// }
				var heatmap = new window.google.maps.visualization.HeatmapLayer({
                    data: heatMapData
                  });
                  heatmap.setMap(map);
				
			}
		}
		catch(e){

		}
		map.fitBounds(bounds)
	}
	
	
	
	
	
}


	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
	   this.setState({ open: false, open1:false });
	 };

	onShowTimelines(){
		this.setState({
			viewtimelinemarkers:!this.state.viewtimelinemarkers
		})
		if(this.state.viewtimelinemarkers === true)
		{			
			var arr=[];
			if(this.props.mapFor == 'consignment' || this.props.mapFor == 'ticket')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if(this.props.mapFor == 'importsconsignment')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.points;
				var arr = jsondata.route;
			}
			if(this.props.mapFor == 'truckgps')
			{
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if(arr.length > 0)
			{

				var allpoints = [];
				

				// if(arr.length < 50)
				// {
				// 	var ratio = (arr.length/5);
				// }
				// else if(arr.length > 50 && arr.length <200)
				// {
				// 	var ratio = 5;
				// }
				// else if(arr.length > 200 && arr.length <400)
				// {
				// 	var ratio = 20;
				// }
				// else{
				// 	var ratio = 100;
				// }
				
				// //console.log("Total ",arr.length);
				// var arr1 = arr.filter(function (value, index, arr) {
				// 	//return (index % ratio == 0);
				// 	return index;
				// } );
				var darr = [];
				darr = arr.filter(function (value, index, arr) {
					//console.log("Vale ",value)
					return (value.dist_from_prev_point > 0);
				} );
				if(darr.length < 50)
				{
					var ratio = (arr.length/5);
				}
				else if(darr.length > 50 && arr.length <200)
				{
					var ratio = 5;
				}
				else if(darr.length > 200 && darr.length <400)
				{
					var ratio = 20;
				}
				else{
					var ratio = 100;
				}
				var arr2=[]
				//console.log("darr.length ", darr.length)
				arr2 = darr.filter(function (value, index, darr) {
					return (index % ratio == 0);
				} );

				this.setState({
					timelinesmarkers:arr2
				})
			}
		}
		else{
			this.setState({
				timelinesmarkers:[]
			})
		}
			this.renderMapStoppage()
		
	}
	onViewTimelines(e){
		this.setState({
			viewtimelinemarkers:!this.state.viewtimelinemarkers
		})
		//console.log("viewtimelinemarkers ", this.state.viewtimelinemarkers)
	}
	onShowGridPop(e){
		if(this.props.mapFor == 'mllrailconsignments')
		{
			this.setState({
				loadshow:'show-m'
			})
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var coordinates = jsondata.coords;
			//console.log("truckno ", this.props.truckno)
			//console.log("coordinates ", coordinates)
			var dataarr=[]
			//console.log("coordinates ", coordinates)
			coordinates.map((item) => {
				dataarr.push({
					"truck_no":item.truck_no, 
					"lat":item.lat, 
					"lng":item.lng, 
					"speed":item.speed, 
					"stime":item.stime,
					"dist_from_prev_point":item.dist_from_prev_point, 
					"received_on":item.received_on, 
					"time_from_prev_point":item.time_from_prev_point,
					"truck_type":item.truck_type
				})
			});
			this.setState({
				rowData:dataarr,
				open:true,
				loadshow:'show-n'
			})
		}
	}
 
	onShowBreakGridPop(e){
		if(this.props.mapFor == 'mllrailconsignments')
		{
			this.setState({
				loadshow:'show-m'
			})
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var coordinates = jsondata.coords;
			//console.log("truckno ", this.props.truckno)
			//console.log("coordinates ", coordinates)
			var dataarr=[]
			console.log("locations ", locations)
			locations.map((item) => {
				dataarr.push({
					"break_start":item.break_start, 
					"break_end":item.break_end, 
					"break_time_seconds":item.break_time_seconds,
					"lat":item.lat, 
					"lng":item.lng
				})
			});
			this.setState({
				rowBreakData:dataarr,
				open1:true,
				loadshow:'show-n'
			})
		}
	}
 
	onShowGoogleRoutes(){
		this.setState({
			viewgoogleroutes:!this.state.viewgoogleroutes
		})
		if(this.state.viewgoogleroutes == true)
		{			
			if(this.props.mapFor == 'consignment')
			{
				try{
					var googleroutes = JSON.parse(this.props.googleroutes);
					//console.log("googleroutes ", googleroutes)
					if(googleroutes == 0)
					{
						this.props.context.showGoogleRouteAlert();
						this.setState({
							viewgoogleroutes:!this.state.viewgoogleroutes
						});
					}
					else{
						this.setState({
							googleroutepath:googleroutes
						})
					}
				}
				catch(e){
					console.log(e)
				}
			
			}
		}
		else{
			this.setState({
				googleroutepath:[],
				viewgoogleroutes:!this.state.viewgoogleroutes
			})
		}
			
		this.renderMapStoppage()
	}

	showMarkers(event){
		if(event.target.value == 0)
		{
			this.setState({
				showMarkers : 1
			});
			// viewMarkersArr.map(function(e){
			// 	e.setVisible(true);
			// })
			var infowindow = new window.google.maps.InfoWindow();
			if(markersArray.length > 0)
			{
				var stepby = Math.round(markersArray.length * 0.007)
				// var counter = ( stepby > 0)?stepby:1;
				// var incrementby = counter
				var counter = 1;
				var incrementby = 1;
				console.log(markersArray.length,counter);
				while(counter < markersArray.length)
				{
					var marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(markersArray[counter].lat, markersArray[counter].lng),
						icon: {
							path: window.google.maps.SymbolPath.CIRCLE,
							strokeColor:'#157254',
							fillColor:'#157254',
							fillOpacity:1,
								strokeWeight: 5,
								scale:1,
							},
						map: map,
						content:markersArray[counter]
					});
					// marker.setVisible(false);
					window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
						return function() {
							//console.log("Arr ",marker)
							var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "Current Status"
						contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
						contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
						
						var contentString = infoBox("", header, contentarr,'')
						
						
						infowindow.setContent(contentString);
						infowindow.open(map, marker);
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
					window.google.maps.event.addListener(marker, 'mouseout', function() {
						infowindow.close();
					});
	
					viewMarkersArr.push(marker);
					counter = counter + incrementby;
					console.log("counter",counter)
				}
				// for(var a=0;a<markersArray.length;a++)
				// {
					
				// }
			}
			
		}
		else
		{
			this.setState({
				showMarkers : 0
			});
			viewMarkersArr.map(function(e){
				e.setVisible(false);
			});
		}
	}

setRouteDeviationPathVisibility(routeno){
	routeno = routeno+1
	console.log(route_deviation_polyline_dict[routeno])
	if(route_deviation_polyline_dict[routeno].getVisible())
	{
		route_deviation_polyline_dict[routeno].setVisible(false)
	}
	else{
		route_deviation_polyline_dict[routeno].setVisible(true)
	}
}
onRTDashboardRowClicked(row){
	var breaklocations = [];
	var bounds = new window.google.maps.LatLngBounds();
	row.data.break_clusters.map(function(e){
		bounds.extend(new window.google.maps.LatLng(e.lat,e.lng));
	})
	map.fitBounds(bounds)
}

onSelectState(duration){
    this.setState({
        duration
    })
}
onClickGetData(){
    if(localStorage.getItem("transportercode") != undefined)
    {
        var transporter = JSON.parse(localStorage.getItem("transportercode"))
    }
    else{
        var transporter = "";
    }
    if(Array.isArray(transporter))
    {
        var parameters = {
            transporter_code : transporter[0]
        }
    }
    else{
        var parameters = {
            transporter_code : transporter
        }
    }
    parameters.to_date = moment.parseZone().format("YYYY-MM-DD");
    var option = this.state.duration.value;
    if(option == "week"){
        parameters.from_date = moment.parseZone().subtract(1,"weeks").format("YYYY-MM-DD");
    }
    if(option == "15"){
        parameters.from_date = moment.parseZone().subtract(15,"days").format("YYYY-MM-DD");
    }
    if(option == "month"){
        parameters.from_date = moment.parseZone().subtract(1,"months").format("YYYY-MM-DD");
    }
    this.onLoadData(parameters);
}
render () {
 const modalStyles  = {
              width:'500px !important',
      		}
	  const { open } = this.state;
	 
    return (
        <div className="col-sm-12 white-bg">
            <div className="col-sm-12 row">
                
                <h5 className="col-sm-12">Frequent Stoppage Locations</h5>
                <div className="col-sm-3">
                    <Select 
                        name="duration" 
                        isMulti={false} 
                        placeholder="Select States" 
                        className="rselect" 
                        value={this.state.duration} 
                        onChange={this.onSelectState.bind(this)} 
                        options={[
                            {
                                value:"week",
                                label:"Last 7 Days"
                            },
                            {
                                value:"15",
                                label:"Last 15 Days"
                            },
                            {
                                value:"month",
                                label:"Last 1 Month"
                            }
                        ]} 
                    />
                </div>
                <div className="col-sm-2">
                    <button type="button" className="btn btn-warning" onClick={this.onClickGetData.bind(this)}>Get Data</button>
                </div>
            </div>
            <div id="stoppagemap" className="map-bx mt-20p" style={{width:'100%',height:"70vh"}}></div>
        </div>
		
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}


function groupBy(list, keyGetter) {
    const map11 = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map11.get(key);
         if (!collection) {
             map11.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function setBg(){
	const randomColor = Math.floor(Math.random()*16777215).toString(16);
	return "#"+randomColor
  }
export default HeatMapStoppageView;