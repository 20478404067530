/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import {
    getDDMMYYYY, getDDMMMYYYY, getHyphenDDMMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import UploadPODReceit from "./uploaditem";
import ViewAttachments from "./viewallattachment";
import ViewIdentityAttachments from "./viewidentityattachment";
import EditItem from "./tptedititem";
import ExportPDF from "./exportpdf"
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class VehicleRegister extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            rowData: [],
            frameworkComponents: {
                uploaddoc: UploadPODReceit,
                viewattachments: ViewAttachments,
                viewidentityattachments: ViewIdentityAttachments,
                edititem: EditItem
            },
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            rowClassRules: {
                "boxbgblue": "data.to_be_approved == 1"
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            detailCellRendererParams: {},
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 200,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "RSO POD",
            sliderRouteTranslate: '',
            file: '',
            csvcontent: [],
            startDate: "",
            endDate: "",
            defaultsdate: "",
            defaultedate: "",
            headerHeight: 80,
            showform: "show-n",
            sliderForceDestination: "",
            truck_no: "",
            transporter_mobile_no: "",
            transporter_address: "",
            address_proof_no: "",
            address_proof: { value: "", label: "Select" },
            identity_proof_no: "",
            identity_proof: { value: "", label: "Select" },
            uploads: [],
            files: [],
            identityfiles: [],
            shwdata: "show-n",
            sliderDefectData: "",
            sliderBulk: "",
            transporter_code: "",
            engine_no: "",
            chassis_no: "",
            username: "",
            userid: "",
            email: "",
            filteredData: [],
            showTrucksList: "show-n",
            trucks: [],
            rowid: "",
            carriers: [],
            carrier: { value: "", label: "Select" },
            groups: [],
            group: [],
            adaniCheck: 0,
            pdfRowData: [],
            pdfColumns: [],
            addressColumn: 300,
            tptGrouptrucks: [],
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickShowUploadModal = this.onClickShowUploadModal.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
        this.onClickIdentityDownload = this.onClickIdentityDownload.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
        this.updateRowHandler = this.updateRowHandler.bind(this);
    }

    componentDidMount() {
        loadDateTimeScript()
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate = moment().endOf('month').format('YYYY-MM-DD');
        var transporter_code = localStorage.getItem("transportercode");
        if (transporter_code.includes("T293") == true) {
            var adaniCheck = 1;
        }
        else {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck: adaniCheck
        });
        var username = localStorage.getItem("username")
        var userid = localStorage.getItem("userid");
        var email = localStorage.getItem("email");
        // console.log("transporter_code ", transporter_code)
        this.setState({
            startDate: sdate,
            endDate: edate,
            defaultsdate: sdate,
            defaultedate: edate,
            transporter_code: transporter_code,
            username: username,
            userid: userid,
            email: email
        })
        var mindate = moment.parseZone().format('YYYY-MM-DD');
        var minactdate = moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
        // console.log("path ", this.props.match.path)
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });

        var parameters = {
            transporter_code: transporter_code
        }
        var vehicle_group = localStorage.getItem("vehicle_group");

        if (vehicle_group != "") {
            var tparms = {
                transporter_code: transporter_code,
                region: vehicle_group
            }
            redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
                .then((response) => {
                    var records = response.data.records;
                    // console.log("records ", records)
                    this.setState({
                        tptGrouptrucks: records
                    })
                })
        }
        redirectURL.post("/dashboard/tpttrucks", parameters)
            .then((response) => {
                var records = response.data.records
                this.setState({
                    trucks: records,
                    // rowData:records
                })
            })
        redirectURL.post("/dashboard/tptcarriers", parameters)
            .then((response) => {
                var records = response.data.records
                this.setState({
                    carriers: records,
                    // rowData:records
                })
            })
        redirectURL.post("/dashboard/tptgroups", parameters)
            .then((response) => {
                var records = response.data.records
                this.setState({
                    groups: records,
                    // rowData:records
                })
            })
        this.onLoadData(parameters)

    }

    onLoadData(parameters) {
        this.setState({
            rowData: [],
            loadshow: "show-m"
        });
        console.log("parameters ", parameters)
        redirectURL.post("/dashboard/vehicleregisters", parameters)
            .then(async (response) => {
                var records = response.data;
                //    console.log("recordssc ", records)
                let filterData = []
                if (this.state.tptGrouptrucks.length != 0) {
                    records.filter(f => this.state.tptGrouptrucks.filter(d => {
                        if (d.truck_no == f.truck_no) {
                            filterData.push(f)
                        }
                    }))
                   console.log("filterData ", filterData)
                }else{
                    filterData = records
                }
                var trucksarr = []
                if (filterData.length > 0) {
                    filterData.map((item) => {
                        trucksarr.push(item.truck_no)
                    })
                }
                var params = {
                    trucks: trucksarr,
                    transporter_code: parameters.transporter_code
                }
                await redirectURL.post("/dashboard/tptrucksdata", params)
                    .then((response) => {
                        var recss = response.data.records;
                        this.setState({
                            trucks: recss
                        })
                    })
                var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
                var pdfRowData = [];
                // console.log("summaryData", records)
                filterData.map(function (t) {
                    pdfRowData.push({
                        name: t.truck_no,
                        carrier_code: t.carrier_code,
                        carrier_title: t.carrier_title,
                        group_code: t.group_code[0],
                        group_title: t.group_title[0],
                        chassis_no: t.chassis_no,
                        engine_no: t.engine_no,
                        transporter_mobile_no: t.transporter_mobile_no,
                        transporter_address: t.transporter_address,
                        address_proof: t.address_proof,
                        address_proof_no: t.address_proof_no,
                        address_proof_attachment: t.address_proof,
                        identity_proof: t.identity_proof,
                        identity_proof_no: t.identity_proof_no,
                        identity_proof_attachment: t.identity_proof,

                    });
                });
                // console.log("pdfRow", pdfRowData)

                var pdfColumns = [
                    { header: name, binding: 'name' },
                    { header: 'Carrier Code', binding: 'carrier_code' },
                    { header: 'Carrier Title', binding: 'carrier_title' },
                    { header: 'Group Code', binding: 'group_code' },
                    { header: 'Group Title', binding: 'group_title' },
                    { header: 'Chassis No', binding: 'chassis_no' },
                    { header: 'Engine No', binding: 'engine_no' },
                    { header: 'Mobile Number', binding: 'transporter_mobile_no' },
                    { header: 'Address', binding: 'transporter_address' },
                    { header: 'Address Proof', binding: 'address_proof' },
                    { header: 'Address Proof No', binding: 'address_proof_no' },
                    { header: 'Proof Attachment', binding: 'address_proof' },
                    { header: 'Identity Proof', binding: 'identity_proof' },
                    { header: 'Identity Proof No', binding: 'identity_proof_no' },
                    { header: 'Identity Proof Attachment', binding: 'identity_proof_attachment' },
                ]
                this.setState({
                    pdfRowData,
                    pdfColumns,
                })

                await this.setState({
                    rowData: filterData,
                    loadshow: "show-n",
                    overly: "show-n"
                });
            })
    }


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    /*Alert Popups*/
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

    /*Alert Popups*/
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: '',
            sliderTranslatesidebar: '',
            sliderForceCloseTranslate: '',
            sliderRakeTranslate: '',
            sliderBulkRakeTranslate: "",
            bulkslide: '',
            sliderForceDestination: '',
            sliderBulkForceClose: "",
            sliderDefectData: "",
            sliderBulk: "",
            rowid: "",
        });

    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }


    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;


    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.gridApi.deselectAll();
        this.setState({ open: false });
    };

    onAddRow() {
        // var newItem = 
        // {
        //     sr_no:"",
        //     defect_type:"",
        //     defect_location:"",
        //     defect_remarks:""
        // };
        //console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
            sliderDefectData: "slider-translate",
            overly: 'show-m',
        })
    }

    selectvins() {
        let items = [];
        var vins = this.state.vins;
        if (vins.length > 0) {
            vins.map((item) => {
                items.push({ "value": item.vehicle_id, "label": item.vehicle_id })
            })
        }
        return items;
    }

    onChangeVINs(vinno) {
        this.setState(
            { vinno },
            () => console.log(`Option selected:`, this.state.vinno)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeCarrier(carrier) {
        this.setState(
            { carrier },
            () => console.log(`carrier selected:`, this.state.carrier)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeGroup(group) {
        this.setState(
            { group },
            () => console.log(`group selected:`, this.state.group)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }


    onClickShowUploadModal(params) {
        this.setState({
            open: true
        })
    }
    onCloseModal = () => {
        this.setState({ open: false, rejectopen: false });
    };

    changeFileHandler = async (e) => {
        // console.log(e.target.files)
        var files = Array.from(e.target.files);
        this.setState({
            files: files,
            open: false
        })
    }

    changeIdentityFileHandler = async (e) => {
        // console.log(e.target.files)
        var files = Array.from(e.target.files);
        this.setState({
            identityfiles: files,
            open: false
        })
    }

    formRegister(event) {
        event.preventDefault();
        var truck_no = $("#inputTruck").val();
        // if(this.state.truck_no != "")
        // {
        //     var truck_no = this.state.truck_no
        // }
        // else
        // {
        //     var truck_no = "";
        // }

        if (this.state.address_proof.value != "") {
            var address_proof = this.state.address_proof.value
        }
        else {
            var address_proof = "";
        }
        if (this.state.identity_proof.value != "") {
            var identity_proof = this.state.identity_proof.value
        }
        else {
            var identity_proof = "";
        }

        if (this.state.identity_proof_no != "") {
            var identity_proof_no = this.state.identity_proof_no
        }
        else {
            var identity_proof_no = ""
        }
        if (this.state.address_proof_no != "") {
            var address_proof_no = this.state.address_proof_no
        }
        else {
            var address_proof_no = ""
        }

        if (this.state.chassis_no != "") {
            var chassis_no = this.state.chassis_no
        }
        else {
            var chassis_no = "";
        }
        if (this.state.engine_no != "") {
            var engine_no = this.state.engine_no
        }
        else {
            var engine_no = "";
        }

        if (this.state.carrier.value != "") {
            var carrier = this.state.carrier.value
        }
        else {
            var carrier = ""
        }


        if (this.state.group.length > 0) {
            var group = []
            this.state.group.map((item) => {
                group.push(item.value)
            })
        }
        else {
            var group = []
        }
        // console.log("group arr ", group)
        if (this.state.rowid == "") {
            if (truck_no != "" && this.state.transporter_mobile_no != "" && this.state.transporter_address != ""
                && this.state.transporter_address != ""
                && chassis_no != "" && engine_no != ""
                && carrier != "" && group.length > 0) {

                var formdata = new FormData()
                var ftype = "";
                var fname = "";
                for (var x = 0; x < this.state.files.length; x++) {
                    // console.log("this.state.files[x] ", this.state.files[x])
                    ftype = this.state.files[x].type
                    fname = this.state.files[x].name


                    formdata.append('file', this.state.files[x])
                }
                var identityfile = "";

                for (var x1 = 0; x1 < this.state.identityfiles.length; x1++) {
                    // console.log("this.state.identityfiles[x] ", this.state.identityfiles[x1])
                    ftype = this.state.identityfiles[x1].type
                    fname = this.state.identityfiles[x1].name

                    identityfile = this.state.identityfiles[x1]
                    formdata.append('identityfile', this.state.identityfiles[x1])
                }
                // console.log("identityfile ", identityfile)
                var transportercode = this.state.transporter_code;
                var username = this.state.username;
                var userid = this.state.userid;
                var email = this.state.email;

                formdata.append("truck_no", truck_no)
                formdata.append("transporter_mobile_no", this.state.transporter_mobile_no)
                formdata.append("transporter_address", this.state.transporter_address)
                formdata.append("carrier", carrier)
                formdata.append("group", group)
                formdata.append("address_proof", address_proof)
                formdata.append("identity_proof", identity_proof)
                formdata.append("address_proof_no", address_proof_no)
                formdata.append("identity_proof_no", identity_proof_no)
                formdata.append("transporter_code", transportercode)
                formdata.append("chassis_no", chassis_no)
                formdata.append("engine_no", engine_no)
                formdata.append("transporter_name", username)
                formdata.append("username", username)
                formdata.append("userid", userid)
                formdata.append("email", email)
                // console.log("formdata ", formdata)
                var ft = fname.split(".")
                var extens = ft[parseInt(ft.length) - 1];
                // console.log("extension ", extens)
                // if (extens == "pdf") {
                    redirectURL.post("/dashboard/savetptvehicleregister", formdata, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                            // "Content-Type": "multipart/form-data"
                        }
                    })
                        .then((response) => {
                            // console.log("resj ", response.data)
                            if (response.data.status == "success") {
                                this.setState({
                                    show: true,
                                    basicType: "success",
                                    basicTitle: "Successfully register data",
                                    truck_no: "",
                                    transporter_mobile_no: "",
                                    transporter_address: "",
                                    engine_no: "",
                                    chassis_no: "",
                                    address_proof: { value: "", label: "Select" },
                                    identity_proof: { value: "", label: "Select" },
                                    file: [],
                                    files: [],
                                    sliderDefectData: "",
                                    overly: 'show-n',
                                })

                                var parameters = {
                                    transporter_code: this.state.transporter_code
                                }
                                this.onLoadData(parameters)
                            }
                            else {
                                this.setState({
                                    show1: true,
                                    basicType1: "danger",
                                    basicTitle1: "Failed to register data"
                                })
                            }
                        })
                // }
                // else {
                //     this.setState({
                //         show1: true,
                //         basicType1: "danger",
                //         basicTitle1: "File formats allowed only pdf"
                //     })
                // }

            }
            else {
                this.setState({
                    show1: true,
                    basicType1: "danger",
                    basicTitle1: "All * fields are mandatory"
                })
            }
        }
        else {
            if (truck_no!=""&&this.state.transporter_mobile_no != "" && this.state.transporter_address != ""
                && chassis_no != "" && engine_no != "" && carrier != "" && group != "") {
                var formdata = new FormData()
                var ftype = "";
                var fname = "";
                for (var x = 0; x < this.state.files.length; x++) {
                    console.log("this.state.files[x] ", this.state.files[x])
                    ftype = this.state.files[x].type
                    fname = this.state.files[x].name


                    formdata.append('file', this.state.files[x])
                }
                var identityfile = "";

                for (var x1 = 0; x1 < this.state.identityfiles.length; x1++) {
                    // console.log("this.state.identityfiles[x] ", this.state.identityfiles[x1])
                    ftype = this.state.identityfiles[x1].type
                    fname = this.state.identityfiles[x1].name

                    identityfile = this.state.identityfiles[x1]
                    formdata.append('identityfile', this.state.identityfiles[x1])
                }
                // console.log("identityfile ", identityfile)
                var transportercode = this.state.transporter_code;
                var username = this.state.username;
                var userid = this.state.userid;
                var email = this.state.email;

                formdata.append("truck_no",truck_no)
                formdata.append("transporter_mobile_no", this.state.transporter_mobile_no)
                formdata.append("transporter_address", this.state.transporter_address)
                formdata.append("carrier", carrier)
                formdata.append("group", group)
                formdata.append("address_proof", address_proof)
                formdata.append("identity_proof", identity_proof)
                formdata.append("address_proof_no", address_proof_no)
                formdata.append("identity_proof_no", identity_proof_no)
                formdata.append("transporter_code", transportercode)
                formdata.append("chassis_no", chassis_no)
                formdata.append("engine_no", engine_no)
                formdata.append("transporter_name", username)
                formdata.append("username", username)
                formdata.append("userid", userid)
                formdata.append("email", email)
                formdata.append("rowid", this.state.rowid)
                // console.log("formdata ", formdata)
                var ft = fname.split(".")
                var extens = ft[parseInt(ft.length) - 1];
                // console.log("extension ", extens)
                // if(extens == "pdf")
                // {
                redirectURL.post("/dashboard/updatetptvehicleregister", formdata, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                        // "Content-Type": "multipart/form-data"
                    }
                })
                    .then((response) => {
                        // console.log("resj ", response.data)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicType: "success",
                                basicTitle: "Successfully update data",
                                truck_no: "",
                                transporter_mobile_no: "",
                                transporter_address: "",
                                engine_no: "",
                                chassis_no: "",
                                address_proof: { value: "", label: "Select" },
                                identity_proof: { value: "", label: "Select" },
                                file: [],
                                files: [],
                                sliderDefectData: "",
                                overly: 'show-n',
                            })

                            var parameters = {
                                transporter_code: this.state.transporter_code
                            }
                            this.onLoadData(parameters)
                        }
                        else {
                            this.setState({
                                show1: true,
                                basicType1: "danger",
                                basicTitle1: "Failed to update data"
                            })
                        }
                    })
                // }
                // else
                // {
                //     this.setState({
                //         show1:true,
                //         basicType1:"danger",
                //         basicTitle1:"File formats allowed only pdf"
                //     })
                // }
            }
            else {
                this.setState({
                    show1: true,
                    basicType1: "danger",
                    basicTitle1: "All * fields are mandatory"
                })

            }

        }

    }

    onClickDownload(params) {
        console.log("params ", params.data)
        var parameters = {
            truck_no: params.data.truck_no,
            rowid: params.data._id,
            prooftype: "address"
        }
        redirectURL.post("/dashboard/downloadvehicleregfile", parameters)
            .then((response) => {
                console.log("resj ", response.data)
                var filename = params.data.address_proof_file;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })


    }

    onClickIdentityDownload(params) {
        // console.log("params ",params.data)

        var parameters = {
            truck_no: params.data.truck_no,
            rowid: params.data._id,
            prooftype: "identity"
        }
        redirectURL.post("/dashboard/downloadvehicleregfile", parameters)
            .then((response) => {
                console.log("resj ", response.data)
                var filename = params.data.identity_proof_file;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })


    }
    onChangeAddress = address_proof => {
        this.setState({
            address_proof: address_proof
        })
    }
    onChangeIdentity = identity_proof => {
        this.setState({
            identity_proof: identity_proof
        })
    }

    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var trucks = this.state.trucks;
        var alltrucks = []
        var alltrucks = trucks.filter(f =>
            !this.state.rowData.some(d => d.truck_no == f.truck_no)
        );
        var dataset = trucks
        dataset = dataset.slice(0, 100);
        this.setState({
            filteredData: dataset,
            showTrucksList: "show-m",
        })
    }

    handlerForm = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var trucks = this.state.trucks;
        var alltrucks = []
        var alltrucks = trucks.filter(f =>
            !this.state.rowData.some(d => d.truck_no == f.truck_no)
        );
        var dataset = trucks
        var filteredResult = dataset.filter(function (e) {
            if (e != "" && e != null) {
                return e.truck_no.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }

        });
        filteredResult = filteredResult.slice(0, 100);
        this.setState({
            filteredData: filteredResult,
            showTrucksList: "show-m",
        })
    }


    updateRowHandler(rownode) {
        console.log("rownode ", rownode.data)

        if (rownode.data.address_proof == "aadhar card") {
            var aproof = "Aadhar Card"
        }
        else if (rownode.data.address_proof == "electricity bill") {
            var aproof = "Electricity Bill"
        }
        else {
            var aproof = "";
        }


        if (rownode.data.identity_proof == "voter card") {
            var iproof = "Voter Card"
        }
        else if (rownode.data.identity_proof == "pan card") {
            var iproof = "Pan Card"
        }
        else if (rownode.data.identity_proof == "driving license") {
            var iproof = "Driving License"
        }
        else {
            var iproof = "";
        }
        var groups = rownode.data.group_code;
        var allgroups = this.state.groups;
        var groupcodes = []
        var carrieritem = {}
        if (groups.length > 0) {
            allgroups.map((item) => {
                for (var i = 0; i < groups.length; i++) {
                    if (item.group_code == groups[i]) {
                        groupcodes.push({
                            value: item.group_code,
                            label: item.group_title
                        })
                    }

                }
            })

        }
        var carriers = this.state.carriers;
        if (carriers.length > 0) {
            carriers.map((item) => {
                if (item.carrier_code == rownode.data.carrier_code) {
                    carrieritem.value = item.carrier_code
                    carrieritem.label = item.carrier_title
                }
            })
        }
        // console.log("groupcodes ", groupcodes)
        // console.log("carrieritem ", carrieritem)
        this.setState({
            rowid: rownode.data._id,
            truck_no: rownode.data.truck_no,
            group: groupcodes,
            carrier: carrieritem,
            transporter_mobile_no: rownode.data.transporter_mobile_no,
            transporter_address: rownode.data.transporter_address,
            engine_no: rownode.data.engine_no,
            chassis_no: rownode.data.chassis_no,
            address_proof: { value: rownode.data.address_proof, label: aproof },
            address_proof_no: rownode.data.address_proof_no,
            identity_proof: { value: rownode.data.identity_proof, label: iproof },
            identity_proof_no: rownode.data.identity_proof_no,
            sliderDefectData: "slider-translate",
            overly: 'show-m',
        })
        $("#inputTruck").val(rownode.data.truck_no);
    }
    onClickShowBulk() {
        this.setState({
            sliderBulk: "slider-translate",
            overly: 'show-m'
        })
    }

    changeBulkFileHandler = async (e) => {
        var dt = '';

        const config = {
            headers: [
                {
                    name: 'truck_no',
                    inputName: 'truck_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'carrier_code',
                    inputName: 'carrier_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'group_code',
                    inputName: 'group_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'chassis_no',
                    inputName: 'chassis_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'engine_no',
                    inputName: 'engine_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'transporter_mobile_no',
                    inputName: 'transporter_mobile_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'transporter_address',
                    inputName: 'transporter_address',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'address_proof',
                    inputName: 'address_proof',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'address_proof_no',
                    inputName: 'address_proof_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'identity_proof',
                    inputName: 'identity_proof',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'identity_proof_no',
                    inputName: 'identity_proof_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData1) => {
                this.setState({
                    csvcontent: csvData1.data
                });
                console.log(csvData1.data);
            })
            .catch(err => { })
        // console.log("e.target.files[0]",e.target.result);

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file: e.target.files[0]
        });

    }
    formBulkRegister(event) {
        event.preventDefault()
        if (this.state.csvcontent.length > 0) {
            var params = {
                csvcontent: this.state.csvcontent,
                transporter_name: this.state.username,
                transporter_code: this.state.transporter_code,
                username: this.state.username,
                userid: this.state.userid,
                email: this.state.email
            }
            console.log("params ", params)
            redirectURL.post("/dashboard/tptbulkregister", params)
                .then((response) => {
                    if (response.data.status == "success") {
                        this.setState({
                            csvcontent: [],
                            show: true,
                            basicTitle: "Successfully registered",
                            basicType: "success",
                            sliderBulk: "",
                            overly: 'show-n'
                        })
                    }
                    else if (response.data.status == "exists") {
                        this.setState({
                            csvcontent: [],
                            show: true,
                            basicTitle: "Already Trucks exists",
                            basicType: "danger"
                        })
                    }
                    else {
                        if (response.data.status == "notvalid") {
                            this.setState({
                                csvcontent: [],
                                show: true,
                                basicTitle: "Invalid data uploaded",
                                basicType: "danger"
                            })
                        }
                        else {
                            this.setState({
                                csvcontent: [],
                                show: true,
                                basicTitle: "Failed to registered",
                                basicType: "danger"
                            })
                        }

                    }
                })
        }
        else {
            this.setState({
                csvcontent: [],
                show: true,
                basicTitle: "Data not exists in uploaded file",
                basicType: "danger"
            })
        }


    }
    selectCarriers() {
        var items = []
        if (this.state.carriers.length > 0) {
            this.state.carriers.map((item) => {
                items.push({
                    value: item.carrier_code,
                    label: item.carrier_title
                })
            })
        }
        return items;
    }
    selectGroups() {
        var items = []
        if (this.state.groups.length > 0) {
            this.state.groups.map((item) => {
                items.push({
                    value: item.group_code,
                    label: item.group_title
                })
            })
        }
        return items;
    }
    render() {
        // console.log("rowid ", this.state.rowid)
        var rr = this.state.rowid
        if (rr.trim() == "") {
            var rshw = "show-m";
            var isreq = true
        }
        else {
            var rshw = "show-n";
            var isreq = false
        }
        const modalStyles = {
            width: '300px !important',
        }
        const { open } = this.state;

        var adaniCheck = this.state.adaniCheck;
        var tkName = "Truck No";
        if (adaniCheck == 1) {
            tkName = "Shuttle No";
        }
        var columnwithDefs = [

            {
                headerName: tkName,
                field: "truck_no",
                width: 140,
            },
            {
                headerName: "Carrier Code",
                field: "carrier_code",
                width: 140,
            },
            {
                headerName: "Carrier Title",
                field: "carrier_title",
                width: 140,
            },
            {
                headerName: "Group Code",
                field: "group_code",
                width: 140,
            },
            {
                headerName: "Group Title",
                field: "group_title",
                width: 140,
            },
            {
                headerName: "Chassis No",
                field: "chassis_no",
                width: 140,
            },
            {
                headerName: "Engine No",
                field: "engine_no",
                width: 140,
            },
            {
                headerName: "Mobile Number",
                field: "transporter_mobile_no",
                width: 150,
                resizable: true,
                editable: false,
            },
            {
                headerName: "Address",
                field: "transporter_address",
                width: 300,
                resizable: true,
                editable: false,
            },

            {
                headerName: "Address Proof",
                field: "address_proof",
                width: 250,
                resizable: true,
                editable: false
            },

            {
                headerName: "Address Proof No",
                field: "address_proof_no",
                width: 250,
                resizable: true,
                editable: false
            },
            {
                headerName: "Proof Attachment",
                field: "address_proof",
                width: 250,
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    return {
                        component: "viewattachments"
                    }
                }

            },
            {
                headerName: "Identity Proof",
                field: "identity_proof",
                width: 250,
                resizable: true,
                editable: false
            },

            {
                headerName: "Identity Proof No",
                field: "identity_proof_no",
                width: 250,
                resizable: true,
                editable: false
            },
            {
                headerName: "Identity Proof Attachment",
                field: "identity_proof",
                width: 250,
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    return {
                        component: "viewidentityattachments"
                    }
                }

            },
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                pinned: "left",
                resizable: true,
                editable: false,
                cellRendererSelector: function (params) {
                    return {
                        component: "edititem"
                    }
                }

            },

        ]
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e.truck_no}</li>)
        })

        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">

                    <div className="col-xl-12 col-lg-12">

                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>Vehicle Registration Details  </span>

                                    <button
                                        type="button"
                                        onClick={this.onClickShowBulk.bind(this)}
                                        className="btn btn-danger float-right">
                                        Bulk Register
                                    </button>
                                    <button
                                        type="button"
                                        onClick={this.onAddRow.bind(this)}
                                        className="btn btn-warning float-right">
                                        Vehicle Register
                                    </button>
                                    {(this.state.pdfRowData.length > 0) ?
                                        <ExportPDF rowData={this.state.pdfRowData} pagetitle = "RSO POD" pdfColumns={this.state.pdfColumns}
                                            addressColumn={this.state.addressColumn}
                                        />
                                        : ""}
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className={"row col-xl-12 col-lg-12 "}>

                                        <div className={"col-xl-12 col-lg-12 "}>
                                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    headerHeight={this.state.headerHeight}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    masterDetail={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>




                <div className={"slide-r " + (this.state.sliderDefectData)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Vehicle Register
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" id="defectForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRegister.bind(this)}>
                                <div className={"form-group mt-20p "}>
                                    <div id="trucks-dropdown" style={{ top: "80px", left: "16px", width: "19.2em" }} className={"trucks-dropdown " + this.state.showTrucksList}>
                                        <ul>
                                            {listData}
                                        </ul>
                                    </div>
                                    <label className="">{(this.state.adaniCheck == 1) ? "Shuttle" : "Truck" + " No"} <span style={{ color: "#FF0000" }}>*</span></label>
                                    {/* <input type="text" name="truck_no" value={this.state.truck_no} onChange={this.changeHandler.bind(this)}  className="form-control" required  /> */}

                                    <input
                                        type="text"
                                        name="truck_no"
                                        id="inputTruck"
                                        placeholder={(this.state.adaniCheck == 1) ? "Select Shuttle" : "Select Truck"}
                                        autoComplete="off"
                                        className="form-control f13"
                                        onFocus={this.initalList.bind(this)}
                                        onChange={this.handlerForm.bind(this)} />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Carrier Type <span style={{ color: "#FF0000" }}>*</span></label>
                                    <Select placeholder="Select"
                                        value={this.state.carrier}
                                        onChange={this.onChangeCarrier.bind(this)}
                                        options={this.selectCarriers()}
                                        required />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Select Group <span style={{ color: "#FF0000" }}>*</span></label>
                                    <Select
                                        isMulti={true}
                                        placeholder="Select"
                                        value={this.state.group}
                                        onChange={this.onChangeGroup.bind(this)}
                                        options={this.selectGroups()}
                                        required />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Engine No <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="engine_no" value={this.state.engine_no} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Chassis No <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="chassis_no" value={this.state.chassis_no} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Mobile Number <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="transporter_mobile_no" value={this.state.transporter_mobile_no} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>


                                <div className="form-group mt-20p">
                                    <label className="">Address <span style={{ color: "#FF0000" }}>*</span></label>
                                    <textarea name="transporter_address" value={this.state.transporter_address} onChange={this.changeHandler.bind(this)} rows="4" className="form-control" ></textarea>
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Address Proof </label>
                                    <Select placeholder="Select"
                                        value={this.state.address_proof}
                                        onChange={this.onChangeAddress.bind(this)}
                                        options={[
                                            { "value": "", "label": "Select" },
                                            { "value": "aadhar card", "label": "Aadhar Card" },
                                            { "value": "electricity bill", "label": "Electricity Bill" }
                                        ]}
                                        />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Address Proof No</label>
                                    <input
                                        type="text"
                                        placeholder="Ex: 0000000000000000"
                                        id="address_proof_no"
                                        name="address_proof_no"
                                        onChange={this.changeHandler.bind(this)}
                                        className="form-control"
                                        value={this.state.address_proof_no}
                                         />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Upload Address File</label>
                                    <input
                                        type="file"
                                        id="address_proof_file"
                                        name="address_proof_file"
                                        onChange={this.changeFileHandler.bind(this)}
                                        className="form-control"
                                        />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Identity Proof </label>
                                    <Select placeholder="Select"
                                        value={this.state.identity_proof}
                                        onChange={this.onChangeIdentity.bind(this)}
                                        options={[
                                            { "value": "", "label": "Select" },
                                            { "value": "voter card", "label": "Voter Card" },
                                            { "value": "pan card", "label": "Pan Card" },
                                            { "value": "driving license", "label": "Driving License" }
                                        ]}
                                         />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Identity Proof No </label>
                                    <input
                                        type="text"
                                        placeholder="Ex: 0000000000000000"
                                        id="identity_proof_no"
                                        name="identity_proof_no"
                                        value={this.state.identity_proof_no}
                                        onChange={this.changeHandler.bind(this)}
                                        className="form-control"
                                        />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Upload Identity File </label>
                                    <input
                                        type="file"
                                        id="identity_proof_file"
                                        name="identity_proof_file"
                                        onChange={this.changeIdentityFileHandler.bind(this)}
                                        className="form-control" />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>

                            <div className="col-xl-12 col-lg-12 form-group">
                                Note: File formats allowed : pdf
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"slide-r " + (this.state.sliderBulk)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Bulk Register
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkRegister.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input
                                        type="file"
                                        id="bulk"
                                        name="uploadFile"
                                        onChange={this.changeBulkFileHandler.bind(this)}
                                        className="form-control" required={isreq} />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                <div className="col-xl-12 col-lg-12">
                                    <a
                                        href={require("../../assets/json/sample_bulk_tpt_register.csv")}
                                        target="_blank"
                                        className="btn btn-danger">Download Sample File</a>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>

        );
    }
}


window.onload = function () {
    // $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
    // });
    $('#nvrr_date').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $("#lr_no_date").datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $("#rso_stn_date").datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};
document.addEventListener('turbolinks:before-render', () => {
    loadDateTimeScript()
});

function loadDateTimeScript() {

    $('#nvrr_date').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $("#lr_no_date").datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $("#rso_stn_date").datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });


    // $('.datetimepicker_date').datetimepicker({
    // 	mask:'39-19-9999',
    // 	format:'d-m-Y',
    // 	timepicker:false
    // });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        console.log(date1Number, date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $("#trucks-dropdown").removeClass("show-m");
        $("#trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($("#trucks-dropdown").attr("class").includes("show-m") || $("#trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $("#trucks-dropdown").removeClass("show-m");
                    $("#trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })


})