/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import '../../../../node_modules/react-grid-layout/css/styles.css';
// import '../../../../node_modules/react-resizable/css/styles.css';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import CryptoJS from 'crypto-js';
import Breadcrumb from '../common/breadcrumb.component';
import GpsIntegrationCounters from './tptgpsintegrationscounters';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import redirectURL from '../redirectURL';
import ComponentIndex from '../common/appendComponents';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import NextBillionMaps from '../common/nbmaps';
import trucklocaction from '../layouts/trucklocaction';
import TPTtrucklocaction from '../layouts/tpttrucklocaction';
import RouteAnimate from '../tptlogin/routeAnimate';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import $ from "jquery";
import * as Datetime from 'react-datetime';
import ExportPDF from "./exportpdf";
import Constant from "../common/googlemapkey";
var OnTripContext;

var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
const ResponsiveReactGridLayout = WidthProvider(Responsive);
var map;
var marker;
var circle;
var viewMarkersArr=[];
var moment = require('moment'); 
var googleAnalytics = require("../common/googleanalytics");
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var fencingLength=0;
var mapZoom=12;
var coordinatesgeo=[];
var selectedColor;
var infoBox = require('../common/google-infowindow');

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	try {
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
	catch (e) {

	}

}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

class tptGpsIntegrationSingleScreen extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			totalTrucks: [],
			uniqueActiveConsignments: null,

			totalActiveConsignments: null,
			gpsNotTodayActiveConsignments: [],
			totalActiveConsignmentsCount: 0,
			gpsNotTodayActiveConsignmentsCount: 0,
			currentDepartmentTitle: null,
			showdata : this.displayData,
			truckInfo:'',
			consignments:'',
			sndshow:'show-n',
			departmentchrtshow:'show-m',
			currenttrucklocation:'',
			truckoperates:0,
			truckgpslifetime:0,
			lspInfo:0,
			deptServes:0,
			transrecordserves:0,
			gpssendtoday:0,
			nopetransporters:0,
			gpstoday:0,
        	alldepts:[],
        	//deptvalue:'all',
        	deptvalue:null,
			sliderTranslate:'',
        	truckscnt:0,
        	trucklocations:0,
        	notrans:0,
        	trucktoday:0,
        	trucknogps:0,
            data: "grouped",
            legendPosition: "top-left",
            interpolate: "linear",
            xLabel: "M",
            yLabel: "GGGGGGGGGG",
            hidePoints: false,  
            hideLines: false,
            yMin: null,
            yMax: null,
            xMax: null,
            xMin: null,
            loadsData:[], 
            transportschart:[],
            transaxis:[],
			transyaxis:[],
			trans_no_gps_axis:[],
			trans_no_gps_yaxis:[],
            deptaxis:[],
            deptyaxis:[],
            lspaxis:[],
            lspyaxis:[],
            lsp_no_gps_axis:[],
            lsp_no_gps_yaxis:[],
            gpsintervalaxis:[],
            gpsintervalyaxis:[],
            modules: AllModules,
  	      open: false,
  	      filterstatess:{},
  	      columnsdatas:{},
  	      rowgroupdcols:[],
  	      valuecolumns:[],
  	      pivotmodeis:false,
			usergridstate1: [],
  	      pivotcols:[],
			show: false,
			type:"",
			title:"",
  	      
  	      defaultColDef: {
  	        sortable: true,
			  filter: true,
			  resizable: true

			},
			frameworkComponents: {
			dateFormater:DateFormater,
			trucklocaction:trucklocaction,
			tpttrucklocaction:TPTtrucklocaction
		},

  	      rowData: [],
			// pdfRowData: [],
            // pdfColumns: [],
  	      rowwithoutData: [],
			rowgpspacketData:null,
  	      rowModelhighchartoptionsType: 'enterprise',
  	      rowGroupPanelShow: 'always',
  	      animateRows: true,
  	      debug: true,
  	      showToolPanel: true,
  	      suppressAggFuncInHeader: true,
			statusBar: {
				statusPanels: [
				  {
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				  },
				  {
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
				]
			  },
			  sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
			  },
			  usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  pagetitle:"",
			  eventGridAction: "gridAction",  
			  defaulttab:"all",
			  loadshow:"show-n" ,
			  overly:"show-n",
			  ontripwithgps:0,
			  ontripwithgpsdata:[],
			  ontripgpsna:0,
			  trucksWithGpsLocation:[],
			  atdealertruckcnt:0,
			  atdealernogps:[],
			  alldealertrucks:[],
			  rowdataatdealer:[],
			  primaryontrips:[],
			  secondaryontrips:[],  
			  usermanualmodal: false,
			  mapData:[],
			  sliderRouteTranslate:'',
			  timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			frequency:"",
			startDate: moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY-MM-DD"+" 00:00:00"),
			endDate:moment.parseZone(new Date()).utcOffset("+05:30").format("YYYY-MM-DD"+" 00:00:00"),
			inputTruck:"",
			oem_name:[],
			showmarkers:0,
            tpttrucks:[],
            originalData:[],
            allrowData:[],
			inrowData:[],
            gpsrowData:[],
            gpsnodata:[],
            msiltrips:[],
            ontrips:[],
			msiltripitems:[],
			deviceid:"",
			adaniCheck:0,
			addressColumn: 300,
			nonmsil:[],
			nonmsillist:[],
			fastag:[],
			plant_location:[],
			onTripTptTrucks : [],
			nonMsilActiveTrucks: [],
			onTripsTrucksData: [],
			resultsdataarr:[],
			onTripsData:[],
			onTripData:[],
			t329Check : 0,
			selectedInfo:"",
			oem_code:[],
			truck_number_options:[],
			selectedtrucknumber:[],
			trucknumber:"",
			mapToggle:false,
			sliderRso:"",
			geo_fence_name:"",
			geo_address:"",
			geo_alias_adresss:"",
			geo_latitude:"",
			geo_longitude:"",
			geo_radius:"15",
			geo_fence_address:[],
			geo_fence_aliasname:[],
			geo_fence_latitude:[],
			geo_fence_longitude:[],
			clickCnt:0,
			idle_time_remarks:[],
			detailCellRendererParams:{},
			statusList: [],
			shownnbmaps:true,
			share_link_data : "",
			sliderForShareLink : "",
			whatsup_no:"",
			expired_time : {label : "1 Day" , value:1}
		};
		this.onShowGridData = this.onShowGridData.bind(this);
		this.onClickShowTPTTruckLocation = this.onClickShowTPTTruckLocation.bind(this);
        this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
        this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.onShowEditAction = this.onShowEditAction.bind(this);
		this.popmarker=this.popmarker.bind(this);
	}
	
    componentDidMount(){
		
		this.renderMap()
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		})
		// console.log(this.props.information_tab_data,"information_tab_data")
		var truck_number = []
		this.props.information_tab_data.allrowData.map((e) => {
			// console.log(e,"truck no data")
			truck_number.push({"label":e.truck_no,"value":e.truck_no})
		})
		// console.log(truck_number,"truck_number")
		var test = this.props.information_tab_data.allrowData.filter( e=> e.last_moved_time > 0)
		console.log("this.props.information_tab_data.allrowData",test)
		this.setState({
			allrowData: this.props.information_tab_data.allrowData,
			inrowData: this.props.information_tab_data.allrowData,
			truck_number_options:truck_number,
			loadshow:'show-n',
			overly:'show-n'
		})
		
		try{
			if(localStorage.getItem("transportercode") != undefined)
			{
				var transporter = JSON.parse(localStorage.getItem("transportercode"))
			}
			else{
				var transporter = "";
			}
			//console.log("transporter ", transporter)
			if(Array.isArray(transporter))
			{
			var transporter_code = transporter[0];
			}
			else{
				var transporter_code = transporter;
			}
			//console.log("transporter_code ", transporter_code);
			if(transporter_code != "" && transporter_code == "T329")
			{
				var loginType = "";
				if (localStorage.getItem("is_hero") != undefined && localStorage.getItem("is_hero") == 1) {
					loginType = "hero"
				}
				else{
					loginType = "msil"
				}

				var params = { 
					transporter_code: localStorage.getItem("transportercode"),
					loginType: loginType,
				}
				redirectURL.post('/dashboard/getDetailsByStatus', params).then((response1) => {
					var records1 = response1.data;
					// console.log(records1,"records1")
					let statusDropDown = records1.dropList
					// console.log("statusDropDown", statusDropDown)
					this.setState({
						statusList: statusDropDown,
					});
				})
			}
		}
		catch(e){

		}
		// this.props.information_filter_data;
		//googleAnalytics.initGA();
		// var tptCode = localStorage.getItem('transportercode');		
		// var vehicle_group = localStorage.getItem("vehicle_group");
		// if(vehicle_group != "")
		// {
		// 	var tparms = {
		// 		transporter_code:tptCode,
		// 		region:vehicle_group
		// 	}
		// 	redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
		// 	.then((response) => {
		// 	   var records = response.data.records;
		// 		// console.log("records ", records)
		// 		this.setState({
		// 			tpttrucks:records
		// 		})
		// 	})
		// }
		// var edate = moment.parseZone().format('YYYY-MM-DD');
		
        // var sdate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD');
		
        // var consignDatapp = {
        //     transporter_code:tptCode,
        //     startDate:sdate,
        //     endDate:edate,
        //     consigner:[{"value":'all', "label":'All'}]
        // } 
		// redirectURL.post("/dashboard/oemconsignments",consignDatapp)
		// 	.then((response) => {
		// 	   var records = response.data.records;
		// 		// console.log("nonmsil records ", records)
		// 		var recordsarr = []
		// 		let nonMsilActiveTrucks = [];
		// 		if(records.length > 0)
		// 		{
		// 			records.map((item) => {
		// 				if(item.status == 2 || item.status == 3 || item.status == 4) nonMsilActiveTrucks.push(item.truck_no)
		// 				recordsarr.push(item.truck_no)
		// 			})
		// 		} 
		// 		this.setState({
		// 			nonMsilActiveTrucks,
		// 			nonmsil:recordsarr,
		// 			nonmsillist:records
		// 		})
		// 	})
	
        // var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        // if(is_enmovil_transporter == 1)
        // {
        //     this.logPageView(); // Log page view to GA
        //     let eventAction = googleAnalytics.page.actionType.gridAction + " - " + "/trucksgpsintegrationdata";
        //     this.setState({eventAction: eventAction});
        //     var pagetitle='GPS Integration'

		// 	if(tptCode.includes("T293") == true)
		// 	{
		// 		var adaniCheck = 1;
		// 	}
		// 	else if(tptCode.includes("T329") == true){
		// 		var t329Check = 1
		// 	}else
		// 	{
		// 		 adaniCheck = 0;
		// 	}
		// 	this.setState({
		// 		't329Check' : t329Check,
		// 		'adaniCheck' : adaniCheck
		// 	});
        //     // console.log("localStorage.getItem(is_hero) ",localStorage.getItem("is_hero"))
		// 	var oemcode = "";
		// 	if(localStorage.getItem("is_hero") != undefined && localStorage.getItem("is_hero") == 1)
		// 	{
		// 		oemcode = "HERO";
		// 	}
		// 	else
		// 	{
		// 		oemcode = "Maruti"
		// 	}
		// 		// console.log("S1")
		// 		var sdate = moment.parseZone().subtract(10,"days").format("YYYY-MM-DD");
		// 		var edate = moment.parseZone().format("YYYY-MM-DD");
		// 		if(localStorage.getItem("transportercode") != undefined)
		// 		{
		// 			var transporter = JSON.parse(localStorage.getItem("transportercode"))
		// 		}
		// 		else{
		// 			var transporter = "";
		// 		}
		// 		// console.log("transporter ", transporter)
		// 		if(Array.isArray(transporter))
		// 		{
		// 		var transporter_code = JSON.stringify(transporter)
		// 		}
		// 		else{
		// 			var transporter_code = JSON.stringify([transporter])
		// 		}

		// 		var consignData = {
		// 			tpt_code:localStorage.getItem("transportercode"),
		// 			transporter_code:transporter_code,
		// 			consigner:[{"value":"all","label":"All"}],
		// 			startDate:sdate,
		// 			endDate:edate,
		// 			oem_name:oemcode
		// 		} 
		// 		// console.log("consignData ", consignData)
		// 		redirectURL.post("/consignments/tptherotrips",consignData).then((response1)=>{
		// 			var consignments = response1.data.consignments;
		// 			// console.log("tpthero consignments ", consignments)
		// 			var consignmentsarr = []
		// 			var consignmentitems = []
		// 			let onTripTptTrucks = []

		// 			if(consignments.length > 0)
		// 			{
		// 				consignments.map((item) => {
		// 					console.log(item.status,"item data")
		// 					if(item.status == 2 || item.status == 3 || item.status == 4) onTripTptTrucks.push(item.truck_no)
		// 					consignmentsarr.push(item.truck_no);
		// 					consignmentitems.push(item);
		// 				})
		// 			}
					
		// 			this.setState({
		// 				onTripTptTrucks,
		// 				msiltrips:consignmentsarr,
		// 				msiltripitems:consignmentitems
		// 			})
		// 		})
		// 	// }
		// 	// else{
		// 	// 	var consignData = {
		// 	// 		tpt_code:localStorage.getItem("transportercode"),
		// 	// 		consigner:[{"value":"all","label":"All"}]
		// 	// 	}
		// 	// 	    redirectURL.post("/dashboard/tptmsiltrips",consignData).then((response1)=>{
		// 	// 			try
		// 	// 			{

		// 	// 				var consignments = JSON.parse(response1.data.consignments);
		// 	// 				// console.log("msil consignments ", consignments)
		// 	// 				var consignmentsarr = []
		// 	// 				var consignmentitems = []
		// 	// 				let onTripTptTrucks = []
		// 	// 				if(consignments.length > 0)
		// 	// 				{
		// 	// 					consignments.map((item) => {
		// 	// 						// console.log("item check ", item)
		// 	// 						if(item.status == 2 || item.status == 3 || item.status == 4) onTripTptTrucks.push(item.truck_no)
		// 	// 						consignmentsarr.push(item.truck_no);
		// 	// 						consignmentitems.push(item);
		// 	// 					})
		// 	// 				}
							
		// 	// 				this.setState({
		// 	// 					onTripTptTrucks,
		// 	// 					msiltrips:consignmentsarr,
		// 	// 					msiltripitems:consignmentitems
		// 	// 				})
		// 	// 			}
		// 	// 			catch(e)
		// 	// 			{
							
		// 	// 			}
		// 	// 		})
				
		// 	// }
        //     // Active consignments are not applicable for LOG-SC
		// 	// let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
			
			// var deviceparams = {
			// 	transporter_code:localStorage.getItem("transportercode")
			// }
			// redirectURL.post("/dashboard/devices", deviceparams).then((response1)=>{
			// 	var transporterTrucks = [];
			// 	var tptCode = localStorage.getItem("transportercode");
			// 	response1.data.devices.map(function(e){
			// 		console.log(e,"devices data")
			// 		if(tptCode.indexOf(e.transporter_code) > -1 )
			// 		{
			// 			transporterTrucks.push(e)
			// 		}
			// 	})
			// 	// console.log(transporterTrucks,"transporterTrucks")
			// 	this.setState({
			// 		transporterTrucks : transporterTrucks
			// 	});
			// 	// this.filterByDepartment();
            // });
        //     // this.filterByDepartment();
        // }
        // else
        // {
        //     window.location.href="/";
        // }
		this.getUserGridState()
	}
	componentWillReceiveProps(newprops){
		// console.log(this.props.information_tab_data,"information_tab_data")
		
	}
	getUserGridState(){
		let params = {
			screentitle : 'Trucks Information Data',
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname
		}
		redirectURL.post("/consignments/usergridstates",params)
		  .then( async (resp) => {
			//   console.log("resp ", resp)
			  this.setState({
				  usergridstate:resp.data
			  });
			   this.restoreGridStates();
			   
		  })
		  
	  }
	

	// logPageView = () => {
	// 	try {
	// 	    if (googleAnalytics.page.enableGA) {
	// 	        googleAnalytics.logPageView();
	// 	        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + "/trucksgpsintegrationdata";
	// 	        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + "/trucksgpsintegrationdata";
	// 	        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + "/trucksgpsintegrationdata";
	// 	        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
	// 	    } else {
	// 	        // console.log("GA disabled...");
	// 	    }
	// 	} catch(error) {
	// 	    console.error("Error occured while logging to GA, error = ", error);
	// 	}
    // }
	onGridReady = params => {
			this.gridApi = params.api;
			//console.log("Grid is: ", this.gridOptionsWrapper);
			this.gridColumnApi = params.columnApi;	
	};
	onGridReady1 = params => {
		this.gridApi1 = params.api;
		//console.log("Grid is: ", this.gridOptionsWrapper);
		this.gridColumnApi1 = params.columnApi;	
	};
	onGridReady2 = params => {
		this.gridApi2 = params.api;
		//console.log("Grid is: ", this.gridOptionsWrapper);
		this.gridColumnApi2 = params.columnApi;	
	};
	onCellClicked(e)
	{
		// this.setState({
		// 	sliderTranslate:""
		// });
		// console.log(e.data,"map data")
        // if(e.colDef.field == "address")
        // {
        //         this.setState({
        //             sliderTranslate:"slider-translate-50p",
		// 			geo_address:e.data.address,
		// 			geo_latitude:e.data.last_known_location.lat,
		// 			geo_longitude:e.data.last_known_location.lng
        //         });
		// 		this.renderMap()
        //     }
	}

	
	onChartOptionsChanged(event) {
		//console.log("where is this", event)
			var chartOptions = event.chartOptions;
			//savedLegendUserPreference = chartOptions.legend;
		// savedUserPreferenceByChartType[event.chartType] = chartOptions;
	}
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }
	onFirstDataRendered(params) {
		var createRangeChartParams = {
		cellRange: {
			columns: ["country", "sugar", "fat", "weight"]
		},
		chartContainer: document.querySelector("#myChart"),
		chartType: "bubble",
		suppressChartRanges: true
		};
	// currentChartRef = params.api.createRangeChart(createRangeChartParams);
	}
  
	onGridState = () =>{
		//console.log(this.gridApi);
		
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
			
		
		
	}
	onGridState1 = () =>{
		//console.log(this.gridApi);
		
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi1.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi1.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi1.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi1.getFilterModel();
		this.gridApi1.setFilterModel(window.filterState);
			
		
		
	}
	onGridState2 = () =>{
		//console.log(this.gridApi);
		
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi2.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi2.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi2.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi2.getFilterModel();
		this.gridApi2.setFilterModel(window.filterState);
			
		
		
	}
	onClickHideAll = () => {
		this.setState({
			overly : "show-n",
			sliderTranslate: "",
			sliderRouteTranslate:'',
			sliderForShareLink:"",
			whatsup_no:"",
			expired_time : {label : "1 Day" , value:1}
		})
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
		
		this.setState({
			startDate:getHyphenYYYYMMDDHHMMSS(d)
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime  = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
		this.setState({
			endDate:getHyphenYYYYMMDDHHMMSS(d)
		});
		
		
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    onScatterHover(point) {     
        return `<b>Date: </b>${point.x}<br /><b>Value: </b>${point.y}`;
    }
    createSelectItems() {
        let items = [];         
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All</option>);  
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
    } 
    handleChange(e) {
    	//console.log("V",e.target.value);
        this.setState({ deptvalue: e.target.value });
	  }
	  
	getCurrentDepartmentCode(pathToMatch = null) {
		let departmentCode = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/dashboard/snddefault":
				departmentCode='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/dashboard/prtdefault":
				departmentCode='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/dashboard/tnpdefault":
				departmentCode='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/dashboard/scdefault":
				departmentCode='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				departmentName = " default ";
				// console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			deptvalue:departmentCode,
			currentDepartmentTitle:departmentName,
		});
		return departmentCode;
	}
    // filterByDepartment = async () => {
	// 	this.setState({
	// 		loadshow:'show-m',
	// 		overly:"show-n",
    //     })
    //     var depmntCode = "";
    //     if(localStorage.getItem("dept_code") !=undefined)
    //     {            
    //         var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
    //         var depmntCode = depmntCodeJns[0];
    //     }
	// 	let currentDepartmentCode = depmntCode;
		
	// 	if(currentDepartmentCode == 'SNDG')
	// 	{
	// 		var defaulttab = this.state.defaulttab
	// 	}
	// 	else{
	// 		var defaulttab = ""
	// 	}
		
	// 	var israil = 0;
    //     var userroles = localStorage.getItem("roles");
    //     if (userroles.indexOf("tpt_rail") > 0)
	// 	{
	// 		israil=1;
	// 		var defaulttab = this.state.defaulttab
    //     }
    //     var depmntCode = "";
    //     if(localStorage.getItem("dept_code") !=undefined)
    //     {            
    //         var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
    //         var depmntCode = depmntCodeJns[0];
	// 	}
	// 	var tptCode = localStorage.getItem('transportercode');
	// 	let layoutdata={
	// 		token:localStorage.getItem('token'),
	// 		israil:israil,
    //         defaulttab:defaulttab,
    //         deptcode:currentDepartmentCode,
    //         is_enmovil_transporter:localStorage.getItem('is_enmovil_transporter'),
    //         transporter_code:localStorage.getItem('transportercode'),
	// 	}
	// 	this.setState({ deptvalue: currentDepartmentCode});
    
	// 	if(currentDepartmentCode == 'SNDG')
	// 	{
	// 		this.setState({
	// 			sndshow:'show-m',
	// 			departmentchrtshow:'show-n'
	// 		});
	// 	}
	// 	else{
	// 		this.setState({
	// 			sndshow:'show-n',
	// 			departmentchrtshow:'show-m'
	// 		});
	// 	}
	// 	// Get active consignments for grid display
		
	// 	let activeConsignmentsLayoutData = {
	// 		token:localStorage.getItem('token'),
	// 		is_enmovil_transporter:localStorage.getItem('is_enmovil_transporter'),
	// 		transportercode:localStorage.getItem('transportercode'),
	// 		dept_code:currentDepartmentCode,
	// 		defaulttab:defaulttab,
	// 		israil:israil,
	// 		transporter_code:localStorage.getItem('transportercode')
	// 	}
    //     // console.log(activeConsignmentsLayoutData,"activeConsignmentsLayoutData")
    //     var userroles = localStorage.getItem("roles");
    //     if (userroles.indexOf("tpt_rail") >= 0)
	// 	{
	// 		// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
	// 		redirectURL.post('/dashboard/getrailtrucks', activeConsignmentsLayoutData, {
	// 			headers:{
	// 				"Access-Control-Allow-Origin": "*",
	// 				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
	// 			}
	// 		})
	// 		.then(async (response) => {
    //             var result3arr = []
    //             // console.log(response.data,"tptresponse")
	// 			var onTripsTrucksData = response.data.ontrips
	// 			console.log(onTripsTrucksData,"onTripsTrucksData")
	// 			var msiltrips = this.state.msiltrips;
	// 			var onTripTrucks = this.state.onTripTptTrucks;
	// 			var nonmsil = this.state.nonmsil;
	// 			let nonMsilActiveTrucks = this.state.nonMsilActiveTrucks;
	// 			let result = JSON.parse(JSON.stringify(response)).data;
				
    //             var uqTrucks = result.uniqueTrucks;
    //             // console.log("uqTrucks ", uqTrucks)
	// 			// var transporterTrucks = this.state.transporterTrucks;
	// 			// var uqTrck = [];
	// 			var uqTrck = uqTrucks;
	// 			// var uqTrck =await uqTrucks.filter(f =>
	// 			// 	transporterTrucks.some(d => d.truck_no == f.truck_no)
	// 			// );
	// 			let totalTrucks = (uqTrck) ? uqTrck : [];
	// 			let totalTrucksCount = (uqTrck) ? uqTrck.length : 0;

	// 			let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
	// 			let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;

	// 			// console.log("In totaltrucks, totaltrucks and count = ", result, totalTrucksCount);
				
	// 			//totalActiveConsignmentsCount
	// 			result3arr =await totalTrucks.filter(f =>{
	// 				// console.log("Here f ", f)
    //                 // transporterTrucks.filter((d )=> {
    //                     // if(d.truck_no == f.truck_no){
    //                         // if(f.registereddevices != undefined)
    //                         // {
    //                         //     if(f.registereddevices.length > 0)
    //                         //     {
    //                                 f.device_address = f.address
    //                                 f.device_city = f.city
    //                                 f.device_state = f.state
    //                         //     }
    //                         // }
    //                         f.timestamp = f.last_active_on;
	// 						// eslint-disable-next-line no-self-assign
	// 						f.modified_date = f.modified_date;
	// 						// console.log("f.last_moved_time ", f.last_moved_time)
	// 						var elasptm = "";
	// 						var elspa = ''
	// 						if(f.last_moved_time != "" && f.last_moved_time != undefined)
	// 						{
	// 							var td1 = moment.parseZone(new Date()).format("YYYY-MM-DD")
	// 							var tday1 = moment.parseZone(new Date(td1+" 00:00:00")).format("YYYY-MM-DD HH:mm:ss")
	// 							// console.log("tday1 ", tday1)
	// 							var e1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
	// 							// console.log("current date is: ", e1)
	// 							var e2 = moment.parseZone(f.last_moved_time).format("YYYY-MM-DD HH:mm:ss")
	// 							// console.log("last moved date is: ", e2)
	// 							var ems = moment(e1,"YYYY-MM-DD HH:mm:ss").diff(moment(e2,"YYYY-MM-DD HH:mm:ss"));
	// 							// console.log("Difference : ", ems)

	// 							var ed = moment.duration(ems);
	// 							// console.log("Duration : ", ed)
	// 							var es = Math.floor(ed.asSeconds());
	// 							// console.log("Seconds ",es, f.truck_no)
	// 							var esmins = Math.floor(ed.asMinutes());
	// 							// console.log("MInutes ",esmins, f.truck_no)

	// 							var todayems = moment(tday1,"YYYY-MM-DD HH:mm:ss").diff(moment(e2,"YYYY-MM-DD HH:mm:ss"));
	// 							// console.log("Today Difference : ", todayems)

	// 							var todayed = moment.duration(todayems);
	// 							var todaysec = Math.floor(todayed.asSeconds());
	// 							var todaymin = Math.floor(todayed.asMinutes());

	// 							// console.log("todayhrs ", todaysec)
	// 							if(todaysec > 0)
	// 							{
	// 								// elspa = 0
	// 								elspa = todaysec
	// 							}
	// 							else
	// 							{
	// 								// elspa = todaysec
	// 								elspa = 0
	// 							}
	// 							if(es > 0)
	// 							{
	// 								elasptm = es
	// 							}
	// 							else{
	// 								elasptm = 0
	// 							}
	// 						}
	// 						else
	// 						{
	// 							elasptm = ""
	// 						}
	// 						// console.log("elasptm ", elasptm)
	// 						f.elaped_time_from_last_moved_seconds = elasptm
	// 						f.last_moved_time = elspa
	// 						if(f.last_active_on != undefined)
	// 						{
	// 							var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
	// 							var d2 = moment.parseZone(f.last_active_on).format("YYYY-MM-DD HH:mm:ss")
	// 							var ms = moment(d1,"YYYY-MM-DD HH:mm:ss").diff(moment(d2,"YYYY-MM-DD HH:mm:ss"));
	// 							var d = moment.duration(ms);
	// 							var s = Math.floor(d.asHours());


	// 							// console.log("last_active_on ", d2, f.last_active_on)
	// 							f.travelstatus = s;
	// 							if(s >= 24)
	// 							{                                        
	// 								// f.today_distance = "0";
	// 								f.travel_sttaus = "Inactive";
	// 							}
	// 							// console.log(onTripsTrucksData.length,"onTripsTrucksData.length")
	// 							var ottData = [];
	// 							onTripsTrucksData.map((ot) => {
	// 								ottData.push(ot.truck_no);
	// 							})
	// 							if(ottData.length > 0)
	// 							{
	// 								if(ottData.indexOf(f.truck_no) >= 0)
	// 								{
	// 									// console.log(f.truck_no,"truckkkkkkkkkkNOOOOOOOOOOOOOOOO")
	// 									f.trip_status = "On Trip"
	// 								}
	// 								else
	// 								{
	// 									f.trip_status = "Not On Trip"
	// 								}
	// 							}
	// 							else if(nonMsilActiveTrucks.length > 0)
	// 							{
	// 								if(nonMsilActiveTrucks.includes(f.truck_no)){
	// 									f.trip_status = "On Trip"
	// 								}else{
	// 									f.trip_status = "Not On Trip"
	// 								}
	// 								// if(nonmsil.indexOf(f.truck_no) >= 0)
	// 								// {
	// 								// 	f.trip_status = "On Trip"
	// 								// }
	// 								// else
	// 								// {
	// 								// 	f.trip_status = "Not On Trip"
	// 								// }
	// 							}
	// 							else
	// 							{
	// 								f.trip_status = "Not On Trip"
	// 							}
	// 							// else{
	// 							// 	if(f.last_moved_time == "" && f.last_moved_time == undefined)
	// 							// 	{      
	// 							// 		f.today_distance = "0";
	// 							// 		f.travel_sttaus = "Inactive";
	// 							// 	}
	// 							// }
								
	// 							var t1 = moment.parseZone(new Date(f.timestamp)).format("x")

	// 						}
	// 						else{
	// 							f.travel_sttaus = "Inactive";
	// 							// f.today_distance = "0"
	// 						}
	// 						if(elspa == 0)
	// 						{
	// 							f.today_distance = "0"
	// 						}
	// 						else
	// 						{
	// 							// eslint-disable-next-line no-self-assign
	// 							f.today_distance = f.today_distance
	// 						}
    //                         f.packettimeseconds = t1;
    //                         return f;
    //                     // }
    //                 // })
	// 				});
    //             var msiltripitems = this.state.msiltripitems;
    //             var resultsdata =await result3arr.filter(f =>
    //                 onTripsTrucksData.filter((d )=> {
	// 					// console.log(d,"trips data")
    //                     if(d.truck_no == f.truck_no){
    //                         f.aprox_dist_travelled_from_start = d.aprox_dist_travelled_from_start;
    //                         return f;
    //                     }
    //                 })
    //             );
    //             var rowDataarr = []
    //             var gpsrowData = []
    //             var ontrips = onTripsTrucksData
    //             if(resultsdata.length > 0)
    //             {
    //                 resultsdata.map((item, index) => {
    //                     // console.log("item12 ",item)
    //                     // console.log("index ", index+1)
    //                     if(item.travel_sttaus == "Inactive")
    //                     {
    //                         rowDataarr.push(item)
    //                     }
    //                     else
    //                     {
    //                         gpsrowData.push(item)
    //                     }
    //                     // if(item.trip_status == "On Trip")
    //                     // {
    //                     //     ontrips.push(item)
    //                     // }
    //                 })
	// 				// console.log(ontrips,"ontrips")
    //             }
	// 			var onTripsData =await totalTrucks.filter(f =>
	// 				onTripsTrucksData.filter((d )=> {
	// 					if(d.truck_no == f.truck_no){
	// 						f.trip_status = "On Trip"
	// 						return f;
	// 					}
	// 				})
	// 			);
	// 			console.log(onTripsData,"onTripsData")
	// 			var onTripData = []
	// 			if(onTripsData.length > 0)
	// 			{
	// 				// console.log(resultsdataarr.length,"length")
	// 				onTripsData.map((item) => {
	// 					console.log("itemdata ",item)
	// 					// console.log("index ", index+1)
	// 					if(item.trip_status == "On Trip")
	// 					{
	// 						onTripData.push(item)
	// 					}
	// 				})
	// 			}
	// 			console.log(onTripData,"onTripData")
	// 			console.log(rowDataarr,"rowDataarr")
    //             if(resultsdata.length > 0)
    //             {
    //                 resultsdata = resultsdata.sort(GetSortDescOrder("packettimeseconds"));
	// 			}
	// 			console.log("resultsdata ", resultsdata)

	// 			await this.setState({
	// 				totalTrucks: totalTrucks,
	// 				truckscnt: totalTrucksCount,
	// 				uniqueActiveConsignments: uniqueActiveConsignments,
	// 				totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,
    //                 loadshow:'show-n',                    
    //                 allrowData:resultsdata,
    //                 originalData:resultsdata,
    //                 gpsnodata:rowDataarr,
    //                 gpsrowData:gpsrowData,
    //                 ontrips:ontrips,
	// 				onTripData:onTripData
	// 			});
	// 			await this.onShowGridData();
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});


	// 		/*Trucks with GPS Data*/
	// 		// Counter for "Trucks with GPS Data"
	// 		redirectURL.post('/dashboard/getrailtruckswithgpsdata', activeConsignmentsLayoutData, {
	// 			headers:{
	// 				"Access-Control-Allow-Origin": "*",
	// 				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
	// 			}
	// 		}) 
	// 		.then(async (response) => {
	// 			let result = JSON.parse(JSON.stringify(response)).data;
				
	// 			var twgl = result.trucksWithGpsLocation;
	// 			var transporterTrucks = this.state.transporterTrucks;
	// 			var trucksWithGpsLocation = [];
	// 			// if(twgl.length > 0)
	// 			// {
	// 			// 	if(transporterTrucks.length > 0)
	// 			// 	{
	// 			// 		twgl.map(function(t){
	// 			// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
	// 			// 			{
	// 			// 				trucksWithGpsLocation.push(t)
	// 			// 			}
	// 			// 		})
	// 			// 		var trucksWithGpsLocationCount = trucksWithGpsLocation.length;
	// 			// 	}
	// 			// }

	// 			var trucksWithGpsLocation =await twgl.filter(f =>
	// 				transporterTrucks.some(d => d.truck_no == f.truck_no)
	// 			);
	// 			var trucksWithGpsLocationCount = trucksWithGpsLocation.length;

	// 			var ontripwithgps = (result.ontripwithgps) ? result.ontripwithgps : [];
				
	// 			let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
	// 				if (truck.timestamp) {
	// 					let result = false;
	// 					let consignmentDate = truck.timestamp;
	// 					let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
	// 					let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);
						
	// 						// console.log("consignmentDateOnly ", consignmentDateOnly)
	// 						// console.log("todayDateOnly ", todayDateOnly)
	// 					var s1 = moment.parseZone(consignmentDateOnly).format("x")
	// 					var s2 = moment.parseZone(todayDateOnly).format("x")
	// 					// if (consignmentDateOnly !== todayDateOnly) {
	// 					// 	result = true;
	// 					// }
						
	// 					// console.log("consignmentDateOnly s1", s1)
	// 					// 	console.log("todayDateOnly s1", s2)
	// 					// if(s1<s2)
	// 					// {
	// 						result = true;
	// 					//} 
	// 					return result;

	// 				} else {
	// 					//console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
	// 				}
	// 			});
				
	// 			//console.log("gpsDataNotReportedToday")
	// 			let gpsDataNotReportedTodayCount = (trucksWithGpsLocation) ? trucksWithGpsLocation.length : 0;
	// 			let trucksWithGpsDataCount = (trucksWithGpsLocationCount && gpsDataNotReportedTodayCount) ? trucksWithGpsLocationCount - gpsDataNotReportedTodayCount : 0;
		

	// 			this.setState({
	// 				trucklocations: trucksWithGpsLocation.length,
	// 				trucksWithGpsLocation:trucksWithGpsLocation,
	// 				ontripwithgps:ontripwithgps.length
					
	// 			});

	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});

	// 	}
	// 	else{
	// 		// Counters data for "Total Trucks" and "On Trip Trucks" under "Total Trucks"
	// 		redirectURL.post('/dashboard/gettrucks', activeConsignmentsLayoutData, {
	// 			headers:{
	// 				"Access-Control-Allow-Origin": "*",
	// 				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
	// 			}
	// 		})
	// 		.then(async (response) => {
	// 			let result = JSON.parse(JSON.stringify(response)).data;
	// 			console.log("resultgettrucks ", response.data)
	// 			var onTripsTrucksData = response.data.ontrips
	// 			console.log(onTripsTrucksData,"onTripsTrucksData")
	// 			var result3arr=[]
	// 			var uqTrucks = result.uniqueTrucks;
	// 			// console.log(uqTrucks.length,"length234")
	// 			// var transporterTrucks = this.state.transporterTrucks;
	// 			// var uqTrck = [];
	// 			var uqTrck = uqTrucks;
	// 			// var uqTrck =await uqTrucks.filter(f =>
	// 			// 	transporterTrucks.some(d => d.truck_no == f.truck_no)
	// 			// );
	// 			var tpttrucks = this.state.tpttrucks;
	// 			// console.log(tpttrucks.length,"length 1")
	// 			var tptunqrecords = []
	// 			if(tpttrucks.length > 0)
	// 			{
	// 				await uqTrck.filter(f =>
	// 					tpttrucks.filter((d )=> {
	// 						if(d.truck_no == f.truck_no){									
	// 							tptunqrecords.push(f)
	// 						}
	// 					})
	// 				);
	// 			}
	// 			else
	// 			{
	// 				tptunqrecords = uqTrck
	// 			}
	// 			var ottData = [];
	// 			onTripsTrucksData.map((ot) => {
	// 				if(ottData.includes(ot.truck_no)){
	// 				}else{
	// 					ottData.push(ot.truck_no);
	// 				}
	// 			})
	// 			let totalTrucks = (tptunqrecords) ? tptunqrecords : [];
	// 			console.log("totalTrucks",totalTrucks)
	// 			// let totalTrucksCount = (uqTrck) ? uqTrck.length : 0;

	// 			let uniqueActiveConsignments = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments : [];
	// 			let uniqueActiveConsignmentsCount = (result.uniqueActiveConsignments) ? result.uniqueActiveConsignments.length : 0;

	// 			var msiltrips = this.state.msiltrips;
	// 			let onTripTptTrucks = this.state.onTripTptTrucks;
	// 			var nonmsil = this.state.nonmsil;
	// 			let nonMsilActiveTrucks = this.state.nonMsilActiveTrucks;
	// 			//totalActiveConsignmentsCount
    //             // console.log("uniqueActiveConsignmentsCount ", msiltrips)
	// 			// console.log(totalTrucks.length,"length12")
	// 			var allontripstrucks = []
    //             result3arr =await totalTrucks.filter(f =>{
	// 						f.device_address = f.address
	// 						f.device_city = f.city
	// 						f.device_state = f.state                            
    //                         f.timestamp = f.last_active_on;
    //                         // f.modified_date = f.modified_date;                     
	// 						var elasptm = "";
	// 						var elspa = ''
	// 						var tchk = f.last_moved_time+"==="+f.truck_no;
	// 						// console.log("f.last_moved_time" , tchks)
	// 						if(f.last_moved_time != "" && f.last_moved_time != undefined)
	// 						{
	// 							var td1 = moment.parseZone(new Date()).format("YYYY-MM-DD")
	// 							var tday1 = moment.parseZone(new Date(td1+" 00:00:00")).format("YYYY-MM-DD HH:mm:ss")
	// 							// console.log("tday1 ", tday1)
	// 							var e1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
	// 							// console.log("current date is: ", e1)
	// 							var e2 = moment.parseZone(f.last_moved_time).format("YYYY-MM-DD HH:mm:ss")
	// 							// console.log("last moved date is: ", e2)
	// 							var ems = moment(e1,"YYYY-MM-DD HH:mm:ss").diff(moment(e2,"YYYY-MM-DD HH:mm:ss"));
	// 							// console.log("Difference : ", ems)

	// 							var ed = moment.duration(ems);
	// 							// console.log("Duration : ", ed)
	// 							var es = Math.floor(ed.asSeconds());
	// 							// console.log("Seconds ",es, f.truck_no)
	// 							var esmins = Math.floor(ed.asMinutes());
	// 							// console.log("MInutes ",esmins, f.truck_no)

	// 							var todayems = moment(tday1,"YYYY-MM-DD HH:mm:ss").diff(moment(e2,"YYYY-MM-DD HH:mm:ss"));
	// 							// console.log("Today Difference : ", todayems)

	// 							var todayed = moment.duration(todayems);
	// 							var todaysec = Math.floor(todayed.asSeconds());

	// 							// console.log("todayhrs ", todaysec)
	// 							if(todaysec >= 0)
	// 							{
	// 								elspa = 0
	// 							}
	// 							else
	// 							{
	// 								elspa = todaysec
	// 							}
	// 							if(es > 0)
	// 							{
	// 								elasptm = es
	// 							}
	// 							else{
	// 								elasptm = 0
	// 							}
	// 						}
	// 						else
	// 						{
	// 							elasptm = ""
	// 						}
	// 						// console.log("elasptm ", elasptm)
	// 					   // console.log("local", JSON.parse(localStorage.getItem("transportercode")).indexOf("T297"))
	// 						if(JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0)
	// 						{
	// 							if(f.last_known_speed > 0)
	// 							{
	// 								f.elaped_time_from_last_moved_seconds = 0
	// 							}
	// 							else
	// 							{
	// 								f.elaped_time_from_last_moved_seconds = elasptm
	// 							}
	// 						}
	// 						else
	// 						{
	// 							f.elaped_time_from_last_moved_seconds = elasptm
	// 						}
	// 						if(JSON.parse(localStorage.getItem("transportercode")).indexOf("T293") >= 0)
	// 						{
	// 							if(f.last_known_speed > 0)
	// 							{
	// 								f.last_moved_time = 0
	// 							}
	// 							else
	// 							{
	// 								f.last_moved_time = elspa
	// 							}
	// 						}
	// 						else
	// 						{
	// 							f.last_moved_time = elspa
	// 						}
	// 						if(f.last_active_on != undefined)
	// 						{
	// 							var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
	// 							var d2 = moment.parseZone(f.last_active_on).format("YYYY-MM-DD HH:mm:ss")
	// 							var ms = moment(d1,"YYYY-MM-DD HH:mm:ss").diff(moment(d2,"YYYY-MM-DD HH:mm:ss"));
	// 							var d = moment.duration(ms);
	// 							var s = Math.floor(d.asHours());


	// 							// console.log("last_active_on ", d2, f.last_active_on)
	// 							f.travelstatus = s;
	// 							if(s >= 24)
	// 							{                                        
	// 								// f.today_distance = "0";
	// 								f.travel_sttaus = "Inactive";
	// 							}	
	// 							if(ottData.length > 0)
	// 							{
	// 								if(ottData.indexOf(f.truck_no) >= 0)
	// 								{
	// 									console.log(f.truck_no,"truckNO1291")
	// 									f.trip_status = "On Trip"
	// 									allontripstrucks.push(f);
	// 								}
	// 								else
	// 								{
	// 									// console.log("checking else")
	// 									f.trip_status = "Not On Trip"
	// 								}
	// 							}
	// 							else if(nonMsilActiveTrucks.length > 0)
	// 							{
	// 								// console.log("Step ", nonmsil);
	// 								if(nonMsilActiveTrucks.indexOf(f.truck_no) >= 0)
	// 								{
	// 									// console.log("Step 2");
	// 									f.trip_status = "On Trip"
	// 								}
	// 								else
	// 								{
	// 									// console.log("Step 3");
	// 									f.trip_status = "Not On Trip"
	// 								}
	// 							}
	// 							else
	// 							{
	// 								// console.log("Step 4");
	// 								f.trip_status = "Not On Trip"
	// 							}

	// 							var t1 = moment.parseZone(new Date(f.timestamp)).format("x")

	// 						}
	// 						else{
	// 							f.travel_sttaus = "Inactive";
	// 							// f.today_distance = "0"
	// 						}
	// 						if(elspa == 0)
	// 						{
	// 							f.today_distance = "0"
	// 						}
	// 						else
	// 						{
	// 							// eslint-disable-next-line no-self-assign
	// 							f.today_distance = f.today_distance
	// 						}
    //                         f.packettimeseconds = t1;
    //                         return f;
    //                     // }
	// 				// })
	// 			});
    //             var msiltripitems = this.state.msiltripitems;
	// 			// console.log(result3arr.length,"length 23")
    //             var resultsdataarr =await result3arr.filter(f =>
    //                 onTripsTrucksData.filter((d )=> {
    //                     if(d.truck_no == f.truck_no){
    //                         f.aprox_dist_travelled_from_start = d.aprox_dist_travelled_from_start;
    //                         f.invoice_time = d.invoice_time;
    //                         f.gate_out_time = d.gate_out_time;
    //                         return f;
    //                     }
    //                 })
    //             );

				
	// 			console.log(localStorage.getItem('transportercode'),"plant_location")
	// 			if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
	// 			localStorage.getItem("plant_location") != undefined)
	// 			{
	// 				resultsdataarr = resultsdataarr.filter((item)=>{
	// 					if(item.state == localStorage.getItem("plant_location"))
	// 					{
	// 						return item;
	// 					}
	// 				})
	// 			}
				
    //             var rowDataarr = []
    //             var gpsrowData = []
	// 			var rtrucks = this.state.tpttrucks;
	// 			if(rtrucks.length > 0)
	// 			{
	// 				console.log("allontrip",allontripstrucks)
	// 				var regionwiseontrips =await allontripstrucks.filter(f =>
	// 					onTripsTrucksData.filter((d )=> {
	// 						if(d.truck_no == f.truck_no){
	// 							f.aprox_dist_travelled_from_start = d.aprox_dist_travelled_from_start;
	// 							f.invoice_time = d.invoice_time;
	// 							f.gate_out_time = d.gate_out_time;
	// 							return f;
	// 						}
	// 					})
	// 				);
	// 				var ontrips = regionwiseontrips;
	// 				console.log("ontrips spareparys ", ontrips);
	// 			}
	// 			else{
	// 				var ontrips = onTripsTrucksData
	// 			}
	// 			console.log("resultsdata12 ", resultsdataarr)
    //             // if(resultsdata.length > 0)
    //             // {
    //             //     resultsdata.map((item, index) => {
    //             //         console.log("item ",item.trip_status)
    //             //         // console.log("index ", index+1)
    //             //         if(item.travel_sttaus == "Inactive")
    //             //         {
    //             //             rowDataarr.push(item)
    //             //         }
    //             //         else
    //             //         {
    //             //             gpsrowData.push(item)
    //             //         }
    //             //         if(item.trip_status == "On Trip")
    //             //         {
    //             //             ontrips.push(item)
    //             //         }
    //             //     })
    //             // }
	// 				if(resultsdataarr.length > 0)
	// 				{
	// 					// console.log(resultsdataarr.length,"length")
	// 					resultsdataarr.map((item) => {
	// 						console.log("item1 ",item.travel_sttaus)
	// 						// console.log("index ", index+1)
	// 						if(item.travel_sttaus == "Inactive")
	// 						{
	// 							rowDataarr.push(item)
	// 						}
	// 						else
	// 						{
	// 							gpsrowData.push(item)
	// 						}
	// 						// if(item.trip_status == "On Trip")
	// 						// {
	// 						// 	ontrips.push(item)
	// 						// }
	// 					})
	// 				}
	// 				var onTripsData =await totalTrucks.filter(f =>
	// 					onTripsTrucksData.filter((d )=> {
	// 						if(d.truck_no == f.truck_no){
	// 							f.trip_status = "On Trip"
	// 							return f;
	// 						}
	// 					})
	// 				);
	// 				console.log(onTripsData,"onTripsData")
	// 				var onTripData = []
	// 				if(onTripsData.length > 0)
	// 				{
	// 					// console.log(resultsdataarr.length,"length")
	// 					onTripsData.map((item) => {
	// 						console.log("itemdata ",item)
	// 						// console.log("index ", index+1)
	// 						if(item.trip_status == "On Trip")
	// 						{
	// 							onTripData.push(item)
	// 						}
	// 					})
	// 				}
	// 				console.log(onTripData,"onTripData")
	// 				let totalTrucksCount = (resultsdataarr) ? resultsdataarr.length : 0;
    //             if(resultsdataarr.length > 0)
    //             {
    //                 resultsdataarr = resultsdataarr.sort(GetSortDescOrder("packettimeseconds"));
    //             }
				
	// 			// console.log(rowDataarr,"rowDataarr")
	// 			// console.log("resultsdata",resultsdataarr)
	// 			await this.setState({
	// 				totalTrucks: totalTrucks,
	// 				truckscnt: totalTrucksCount,
	// 				uniqueActiveConsignments: uniqueActiveConsignments,
	// 				totalActiveConsignmentsCount: uniqueActiveConsignmentsCount,                    
    //                 allrowData:resultsdataarr,
    //                 originalData:resultsdataarr,
    //                 gpsnodata:rowDataarr,
    //                 gpsrowData:gpsrowData,
    //                 ontrips:ontrips,
	// 				onTripData:onTripData
	// 				//loadshow:'show-n'
	// 			});
	// 			await this.onShowGridData();
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});


	// 		/*Trucks with GPS Data*/
	// 		// Counter for "Trucks with GPS Data"
	// 		redirectURL.post('/dashboard/gettruckswithgpsdata', activeConsignmentsLayoutData, {
	// 			headers:{
	// 				"Access-Control-Allow-Origin": "*",
	// 				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
	// 			}
	// 		}) 
	// 		.then(async (response) => {
	// 			let result = JSON.parse(JSON.stringify(response)).data;
	// 			//console.log("gettruckswithgpsdata, result = ", result);

	// 			var twgl = result.trucksWithGpsLocation;
	// 			var transporterTrucks = this.state.transporterTrucks;
	// 			var trucksWithGpsLocation = [];
	// 			// if(twgl.length > 0)
	// 			// {
	// 			// 	if(transporterTrucks.length > 0)
	// 			// 	{
	// 			// 		twgl.map(function(t){
	// 			// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
	// 			// 			{
	// 			// 				trucksWithGpsLocation.push(t)
	// 			// 			}
	// 			// 		})
	// 			// 		var trucksWithGpsLocationCount = trucksWithGpsLocation.length;
	// 			// 	}
	// 			// }
	// 			var trucksWithGpsLocation =await twgl.filter(f =>
	// 				transporterTrucks.some(d => d.truck_no == f.truck_no)
	// 			);
	// 			var trucksWithGpsLocationCount = trucksWithGpsLocation.length;

	// 			var ontripwithgps = (result.ontripwithgps) ? result.ontripwithgps : [];
				
	// 			let gpsDataNotReportedToday = trucksWithGpsLocation.filter(truck => {
	// 				if (truck.timestamp) {
	// 					let result = false;
	// 					let consignmentDate = truck.timestamp;
	// 					let consignmentDateOnly = consignmentDate.toString().slice(0, 10);
	// 					let todayDateOnly = moment.parseZone().format("YYYY-MM-DD HH:mm:ss").toString().slice(0, 10);
						
	// 						// console.log("consignmentDateOnly ", consignmentDateOnly)
	// 						// console.log("todayDateOnly ", todayDateOnly)
	// 					var s1 = moment.parseZone(consignmentDateOnly).format("x")
	// 					var s2 = moment.parseZone(todayDateOnly).format("x")
	// 					// if (consignmentDateOnly !== todayDateOnly) {
	// 					// 	result = true;
	// 					// }
						
	// 					// console.log("consignmentDateOnly s1", s1)
	// 					// 	console.log("todayDateOnly s1", s2)
	// 					// if(s1<s2)
	// 					// {
	// 						result = true;
	// 					//} 
	// 					return result;

	// 				} else {
	// 					//console.log("CONSIGNMENT WITHOUT TIMESTAMP = ", consignment);
	// 				}
	// 			});
				
	// 			//console.log("gpsDataNotReportedToday")

				
	// 			var tpttrucks = this.state.tpttrucks;
	// 			var tptgpsrecords = []
	// 			if(tpttrucks.length > 0)
	// 			{
	// 				await trucksWithGpsLocation.filter(f =>
	// 					tpttrucks.filter((d )=> {
	// 						if(d.truck_no == f.truck_no){									
	// 							tptgpsrecords.push(f)
	// 						}
	// 					})
	// 				);
	// 			}
	// 			else
	// 			{
	// 				tptgpsrecords = trucksWithGpsLocation
	// 			}
	// 			var tptgpsrecordscnt = (tptgpsrecords) ? tptgpsrecords : 0;
	// 			let gpsDataNotReportedTodayCount = (tptgpsrecords) ? tptgpsrecords.length : 0;
	// 			let trucksWithGpsDataCount = (tptgpsrecordscnt && gpsDataNotReportedTodayCount) ? tptgpsrecordscnt - gpsDataNotReportedTodayCount : 0;
				
				
	// 			await this.setState({
	// 				trucklocations: tptgpsrecords.length,
	// 				ontripwithgpsdata:ontripwithgps,
	// 				ontripwithgps:ontripwithgps.length,
	// 				trucksWithGpsLocation:tptgpsrecords,
	// 				loadshow:'show-n',
	// 				atdealertruckcnt:result.totaldealertrucks,
	// 				atdealernogps:result.atdealernogps,
	// 				alldealertrucks:result.alldealertrucks
					
					
	// 			});
	// 			await this.onShowGridData();
				
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// 	}
		
	// 	if(currentDepartmentCode == 'LOG-SC')
	// 	{
	// 		//// Counter for "Trucks with GPS Data"
	// 		redirectURL.post('/dashboard/getSCData', activeConsignmentsLayoutData)
	// 		.then(async (response) => {
	// 		//	console.log("Response SC ", response.data)
	// 			var ontriptrucks = response.data[0].ontriptrucks;
	// 			var nogpslgsc = response.data[0].nogpslogsc;
	// 			var transporterTrucks = this.state.transporterTrucks;
	// 			var nogpslogsc = [];
	// 			// if(nogpslgsc.length > 0)
	// 			// {
	// 			// 	if(transporterTrucks.length > 0)
	// 			// 	{
	// 			// 		nogpslgsc.map(function(t){
	// 			// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
	// 			// 			{
	// 			// 				nogpslogsc.push(t)
	// 			// 			}
	// 			// 		})
	// 			// 	}
	// 			// }
	// 			var nogpslogsc =await nogpslgsc.filter(f =>
	// 				transporterTrucks.some(d => d.truck_no == f.truck_no)
	// 			);
	// 			this.setState({
	// 				totalActiveConsignmentsCount:ontriptrucks.length,
	// 				gpsNotTodayActiveConsignments: nogpslogsc,
	// 				gpsNotTodayActiveConsignmentsCount:nogpslogsc.length
	// 			})
	// 		})
	// 		.catch(function(e){

	// 		})

	// 	}
	// 	else{
    //         var userroles = localStorage.getItem("roles");
    //          if (userroles.indexOf("tpt_rail") >= 0)
	// 		{
	// 			// Counter for "GPS Data Not Reported Today -> On Trip Trucks"
	// 			redirectURL.post('/dashboard/getrailGpsDataNotReportedTodayOnTripTrucks', activeConsignmentsLayoutData, {
	// 				headers:{
	// 					"Access-Control-Allow-Origin": "*",
	// 					"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
	// 				}
	// 			})
	// 			.then(async (response) => {
	// 				let result = JSON.parse(JSON.stringify(response)).data;
	// 				// console.log("result = ", result);
	// 				let twglt = (result.trucksWithGpsLocationNotToday.length) ? result.trucksWithGpsLocationNotToday : [];
	// 				let trucksWithGpsLocationNotTodayCount = (result.trucksWithGpsLocationNotToday.length) ? result.trucksWithGpsLocationNotToday.length : 0;
	// 				let ontripgpsna = (result.ontripgpsna) ? result.ontripgpsna : [];
	// 				var transporterTrucks = this.state.transporterTrucks;
	// 				var trucksWithGpsLocationNotToday = [];
	// 				// if(twglt.length > 0)
	// 				// {
	// 				// 	if(transporterTrucks.length > 0)
	// 				// 	{
	// 				// 		twglt.map(function(t){
	// 				// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
	// 				// 			{
	// 				// 				trucksWithGpsLocationNotToday.push(t)
	// 				// 			}
	// 				// 		})
	// 				// 	}
	// 				// }
	// 				var trucksWithGpsLocationNotToday =await twglt.filter(f =>
	// 					transporterTrucks.some(d => d.truck_no == f.truck_no)
	// 				);
	// 				this.setState({
	// 					gpsNotTodayActiveConsignments: trucksWithGpsLocationNotToday,
	// 					gpsNotTodayActiveConsignmentsCount: trucksWithGpsLocationNotToday.length,
	// 					ontripgpsna:ontripgpsna
						
	// 				});
	// 			})
	// 			.catch(function (error) {
	// 				console.log(error);
	// 			});

	// 		}
	// 		else{
	// 		}
			
	// 	}
		
		
   		
	// 	redirectURL.post('/overview/trucksWithNoTransportersDataCount', layoutdata)
 	// 	  .then((response) => {
 			 
 	// 	   var trucks = JSON.parse(JSON.stringify(response)).data.count;
 	// 	  	// console.log("Truck GPS",trucks);
 	// 	   this.setState({
 	// 		 notrans:trucks
 	// 	   });
 		   
 		    
 	// 	  })
 	// 	  .catch(function (error) {
 	// 	    console.log(error);
 	// 	  });	
   		
   		 
    //        /*Truck with gpsdata*/	
    //        //console.log("bhanuteja",layoutdata)
	// 	//    redirectURL.post('/overview/truckWithGPSData', layoutdata)
	// 	//   .then(async (response) => {
			 
	// 	//    	var recs = JSON.parse(JSON.stringify(response)).data;
	// 	//    	if(recs.length > 0)
	// 	//    	{
	// 	// 		var transporterTrucks = this.state.transporterTrucks;
	// 	// 		if(transporterTrucks.length > 0)
	// 	// 		{
	// 	// 			var records = [];
	// 	// 			var recordsarr = [];
	// 	// 			// recs.map((t) => {
	// 	// 			// 	if(transporterTrucks.indexOf(t.truck_no) >= 0 )
	// 	// 			// 	{
	// 	// 			// 		recordsarr.push(t)
	// 	// 			// 	}
	// 	// 			// })

	// 	// 			var recordsa =await recs.filter(f =>
	// 	// 				transporterTrucks.filter((d )=> {
	// 	// 					if(d.truck_no == f.truck_no){
	// 	// 						// console.log("API ", d)
	// 	// 						if(d.registereddevices != undefined)
	// 	// 						{
	// 	// 							if(d.registereddevices.length > 0)
	// 	// 							{
	// 	// 								f.device_address = d.registereddevices[0].address
	// 	// 								f.device_city = d.registereddevices[0].city
	// 	// 								f.device_state = d.registereddevices[0].state
	// 	// 							}
	// 	// 						}
	// 	// 						f.timestamp = d.last_active_on;
	// 	// 						f.modified_date = d.modified_date;
	// 	// 						// return f;
	// 	// 						records.push(f)
	// 	// 					}
	// 	// 				})
	// 	// 			);
	// 	// 			var tpttrucks = this.state.tpttrucks;
	// 	// 			var tptrecords = []
	// 	// 			if(tpttrucks.length > 0)
	// 	// 			{
	// 	// 				await records.filter(f =>
	// 	// 					tpttrucks.filter((d )=> {
	// 	// 						if(d.truck_no == f.truck_no){									
	// 	// 							tptrecords.push(f)
	// 	// 						}
	// 	// 					})
	// 	// 				);
	// 	// 			}
	// 	// 			else
	// 	// 			{
	// 	// 				tptrecords = records
	// 	// 			}
	// 	// 			// console.log("records ", recs)
	// 	// 			//console.log("In indexcomponent, /overview/truckWithGPSData, records = ", records);
	// 	// 			this.setState({
    //     //                 rowData:tptrecords,
    //     //                 // gpsrowData:tptrecords
	// 	// 			});
	// 	// 		}
	// 	//    	}	   
		    
	// 	//   })
	// 	//   .catch(function (error) {
	// 	//     console.log(error);
	// 	//   });
	// }
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
	onShowGridData = async () =>{
		this.setState({
			rowwithoutData:[]
		});
		try{
            // var result3arr=[]
			var totalTrucks = this.props.information_filter_data.totalTrucks;
			if(totalTrucks.length > 0)
			{
				
				var trucksWithGpsLocation = this.state.trucksWithGpsLocation;
				if(trucksWithGpsLocation.length > 0)
				{
					var resultTrucks = _(totalTrucks) 
					.differenceBy(trucksWithGpsLocation, 'truck_no')
					.value();
					var result3 = [];
					if(resultTrucks.length > 0)
					{						
						var transporterTrucks = this.state.transporterTrucks;
						// if(transporterTrucks.length > 0)
						// {
						// 	resultTrucks.map(function(t){
						// 		if(transporterTrucks.indexOf(t.truck_no) > -1 )
						// 		{
						// 			result3.push(t)
						// 		}
						// 	})
						// }
						var result3 =await resultTrucks.filter(f =>
							transporterTrucks.filter((d )=> {
								if(d.truck_no == f.truck_no){
									if(d.registereddevices != undefined)
									{
										if(d.registereddevices.length > 0)
										{
											f.device_address = d.registereddevices[0].address
											f.device_city = d.registereddevices[0].city
											f.device_state = d.registereddevices[0].state
										}
									}
									f.timestamp = d.last_active_on;
                                    f.modified_date = d.modified_date;
                                    f.today_distance = "0";
                                    f.travel_sttaus = "inactive";
									return f;
								}
							})
                        );
                        // console.log("totalTrucks here ", totalTrucks)
                        // result3arr =await totalTrucks.filter(f =>
						// 	transporterTrucks.filter((d )=> {
						// 		if(d.truck_no == f.truck_no){
						// 			if(d.registereddevices != undefined)
						// 			{
						// 				if(d.registereddevices.length > 0)
						// 				{
						// 					f.device_address = d.registereddevices[0].address
						// 					f.device_city = d.registereddevices[0].city
						// 					f.device_state = d.registereddevices[0].state
						// 				}
						// 			}
						// 			f.timestamp = d.last_active_on;
                        //             f.modified_date = d.modified_date;
                        //             var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        //             var d2 = moment.parseZone(new Date(d.last_active_on)).format("YYYY-MM-DD HH:mm:ss")
                        //             var ms = moment(d1,"YYYY-MM-DD HH:mm:ss").diff(moment(d2,"YYYY-MM-DD HH:mm:ss"));
                        //             var d = moment.duration(ms);
                        //             var s = Math.floor(d.asHours());
                        //             // console.log("Hrs ", s)
                        //             f.travelstatus = s;
                        //             if(s >= 24)
                        //             {                                        
                        //                 f.today_distance = "0";
                        //                 f.travel_sttaus = "Inactive";
                        //             }
                        //             else
                        //             {

                        //             }
                                    
						// 			return f;
						// 		}
						// 	})
                        // );
                        
					}
				}
				else{
					var result3 = []
				}
                // console.log("result3 here ",result3arr)
                // var rowDataarr = []
                // if(result3arr.length > 0)
                // {
                //     result3arr.map((item) => {
                //         console.log("item ",item)
                //         if(item.travel_sttaus == "Inactive")
                //         {
                //             rowDataarr.push(item)
                //         }
                        
                //     })
                // }
                // console.log("rowDataarr ", rowDataarr)
				this.setState({
                    rowwithoutData:result3,
                    // allrowData:result3arr,
                    // originalData:result3arr,
                    // gpsnodata:rowDataarr
				});
				// console.log("result3 ",trucksWithGpsLocation);
			}
            // var userroles = localStorage.getItem("roles");
            // if (userroles.indexOf("tpt_rail") >= 0)
			// {
			// 	var uniqueActiveConsignments = this.state.uniqueActiveConsignments
			// 	var ontripwithgpsdata = this.state.ontripwithgpsdata

			// 	 //console.log("uniqueActiveConsignments ", JSON.stringify(uniqueActiveConsignments))
			// 	// console.log("ontripwithgpsdata ", ontripwithgpsdata)

			// 	var ontripnoresult =await uniqueActiveConsignments.filter(f =>
			// 		!ontripwithgpsdata.some(d => d.truck_no == f.truck_no)
			// 	);
			// 	//console.log("ontripnoresult ", JSON.stringify(ontripnoresult))
				
			// 	var primaryontrips = []
			// 	var secondaryontrips = []
			// 	if(ontripnoresult.length > 0)
			// 	{
			// 		await ontripnoresult.map((rd) => {
			// 			if(rd.consigner_code == "F" || rd.consigner_code == "M" 
			// 			|| rd.consigner_code == "G")
			// 			{
			// 				primaryontrips.push(rd)
			// 			}
			// 			else
			// 			{
			// 				secondaryontrips.push(rd)
			// 			}
			// 		})

			// 		await this.setState({
			// 			gpsNotTodayActiveConsignments:ontripnoresult,
			// 			primaryontrips:primaryontrips.length,
			// 			secondaryontrips:secondaryontrips.length
			// 		})
				
					
			// 	}
			// 	else
			// 	{
			// 		await this.setState({
			// 			gpsNotTodayActiveConsignments:[],
			// 			primaryontrips:0,
			// 			secondaryontrips:0
			// 		})
			// 	}
			// 	//console.log("onshow ontripnoresult ", ontripnoresult.length)
			// }

			var alldealertrucks = this.state.alldealertrucks
			var atdealernogps = this.state.atdealernogps

			var adngn =await alldealertrucks.filter(f =>
				atdealernogps.some(d => d.truck_no == f.truck_no)
			);
			var atdealernogpsNA = [];
			var transporterTrucks = this.state.transporterTrucks;
			var atdealernogpsNA = [];
			// if(adngn.length > 0)
			// {
			// 	if(transporterTrucks.length > 0)
			// 	{
			// 		adngn.map(function(t){
			// 			if(transporterTrucks.indexOf(t.truck_no) > -1 )
			// 			{
			// 				atdealernogpsNA.push(t)
			// 			}
			// 		})
			// 	}
			// }
			var atdealernogpsNA =await adngn.filter(f =>
				transporterTrucks.some(d => d.truck_no == f.truck_no)
			);
			await this.setState({
				rowdataatdealer:atdealernogpsNA
			})
			//console.log("atdealernogpsNA ", atdealernogpsNA)
		}
		catch(e){

		}
		//console.log("trucksWithGpsLocation ",this.state.trucksWithGpsLocation)
		//console.log("uniqueActiveConsignments ",this.state.totalTrucks)
		


		// var difftrucks = []
		// var result =await totalTrucks.filter(f =>
		// 	!trucksWithGpsLocation.some(d => d.truck_no == f.truck_no)
		//   );
		// //console.log("difftrucks ", result)
		// if(result.length > 0)
		// {

		// 	await this.setState({
		// 		rowwithoutData:result
		// 	});
		// }
		// else
		// {

		// 	await this.setState({
		// 		rowwithoutData:[]
		// 	});
		// }

		
	}
	
	/*Truck Sidebar Component Starts*/
    async onShowSidebarAquireComponent(e){
		this.setState({
			sliderTranslate:""
		});
		//console.log(e);
		if(e.colDef.field == 'truck_no')
		{
			let ComponentInfo = ComponentIndex['truckLoc'];
			console.log("ComponentInfo", e);
			var layoutdata1 = {
				truck_no:e.data[e.colDef.field]
			};
			try{
				let coords = [{'longitude' : e.data.last_known_location.lng,'latitude' : e.data.last_known_location.lat}]
				//console.log("layoutdata",layoutdata);
				// await redirectURL.post('/masters/truckByNo', layoutdata1)
				// 	.then((response) => {
				// 		//console.log("trucks response", response);
				// 		var truckrecords = JSON.parse(JSON.stringify(response)).data;
						
				// 		this.setState({
				// 			truckInfo:truckrecords
				// 		});
				// 	})
				// 	.catch(function (error) {
				// 		console.log(error);
				// 	});
	
				// 	/*Trucks details by Truck No*/
				// await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
				// 	.then((response) => {
				// 		//console.log(response);
				// 		var consignrecords = JSON.parse(JSON.stringify(response)).data;
						
				// 		this.setState({
				// 			consignments:consignrecords
				// 		});
				// 	})
				// 	.catch(function (error) {
				// 		console.log(error);
				// 	});
				
				// /*Trucks details by Truck No*/
				// await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
				// 	.then((response) => {
				// 		//console.log("HR55W4880",response);
				// 		var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
						
				// 		this.setState({
				// 			currenttrucklocation:currenttrucklocation
				// 		});
				// 	})
				// 	.catch(function (error) {
				// 		console.log(error);
				// 	});
				//console.log("currenttrucklocation",this.state.currenttrucklocation);
				//setTimeout(function(){})
				// this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
				
				this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} currenttrucklocation={coords}></ComponentInfo>);
						
				this.setState({
					showdata : this.displayData,
					sliderTranslate:"slider-translate",
					overly : "show-m",
				});

			}catch(e){
				// console.log("",e)
			}
		
		}

		if(e.colDef.field == "address")
        {
			var clickCnt = this.state.clickCnt;
			let ComponentInfo = ComponentIndex['address'];
			// console.log("ComponentInfo", e);
			try{
				
				this.displayData=await (<ComponentInfo context={this} geo_address={e.data.address} geo_latitude={e.data.last_known_location.lat} geo_longitude={e.data.last_known_location.lng}></ComponentInfo>);
				clickCnt = parseInt(clickCnt)+1;
				if(clickCnt > 1)
				{
					// console.log(clickCnt,"clickCnt")
				}
				this.setState({
					showdata : this.displayData,
					sliderTranslate:"slider-translate-50p",
					overly : "show-m",
					clickCnt : clickCnt
				});
			}
			catch(e){
				// console.log("",e)
			}
		}
		/*Transporters Sidebar*/
		
		if(e.colDef.field == 'transporter_name')
		{
			let ComponentInfo = ComponentIndex['transporter'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				transporter_name:e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/transporterByName', layoutdata1)
				.then((response) => {
					//console.log("transporterByName response", response);
					var transporterrecords = JSON.parse(JSON.stringify(response)).data;
					var deptcodes=[];
					var transportName='';
					var transportCode='';
					transporterrecords.map(item=>{
						transportName=item.transporter_name;
						transportCode=item.transporter_code;
						deptcodes.push(item.dept_code+", ");
					});
					//console.log(transportName+","+transportCode+","+deptcodes)
					var transportData = [{
						transporter_name:transportName,
						transporter_code:transportCode,
						dept_code:deptcodes
					}]
					this.setState({
						transporterInfo:transportData
					});
				})
				.catch(function (error) {
					// console.log(error);
				});

			/*Total Trucks Operating*/
			await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckoperates:response
				});
			})
			.catch(function (error) {
				// console.log(error);
			});

			/*Total Trucks With GPS Integration Lifetime*/
			await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					truckgpslifetime:response
				});
			})
			.catch(function (error) {
				// console.log(error);
			});
				
			/*Total Trucks With GPS Integration Today*/
			await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
			.then((response) => {
				//console.log("Total Trucks Operating response", response);
				this.setState({
					gpstoday:response
				});
			})
			.catch(function (error) {
				// console.log(error);
			});

				/*Transporters Consignment details*/
			await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					var consignrecordslist=[];
					consignrecords.map(item=>{
						if(item.consigns.length > 0)
						{
							item.consigns.map(cons => {
								consignrecordslist.push(cons)
							})
							
						}
					});
					
					//console.log("consignrecords list", consignrecordslist);
					this.setState({
						consignments:consignrecordslist
					});
				})
				.catch(function (error) {
					// console.log(error);
				});
			
			
			this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
		
		/*LSP Sidebar*/
		
		if(e.colDef.field == 'actual_lspuser')
		{
			let ComponentInfo = ComponentIndex['lsp'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata2 = {
				actual_lspuser:e.data[e.colDef.field]
			};
			// console.log("layoutdata2", layoutdata2);
			//Total Trucks Integrate;
			await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
				.then((response) => {
					var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
					//console.log("LSP response serveeeee", lsprecords);
					
					var result = lsprecords.reduce(function(sum, item){
						return sum = sum+item.count;
					},0);
						//console.log("result", result);
					this.setState({
						lspInfo:result
					});
				})
				.catch(function (error) {
					// console.log(error);
				});

			/*Department Serving*/
			await redirectURL.post('/masters/totalDeptServe', layoutdata2)
			.then((response) => {
				var deprecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("LSP DeptServe response", deprecords);
				
				var deptcnt=[];
				deprecords.map(item => {
					deptcnt.push(item._id)
				});
				this.setState({
					deptServes:deptcnt.length
				});
			})
			.catch(function (error) {
				// console.log(error);
			});

			/*No Of Transporters Serving*/
			await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
			.then((response) => {
				var transrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("Transporter serve response", transrecords);
				var trasportcnt=[];
				transrecords.map(item => {
					trasportcnt.push(item._id)
				});
				this.setState({
					transrecordserves:trasportcnt.length
				});
			})
			.catch(function (error) {
				// console.log(error);
			});

			/*No Of GPS DATA Send Today*/
			await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
			.then((response) => {
				var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("GPS Send Data response", gpssendrecords);
				
				this.setState({
					gpssendtoday:gpssendrecords.length
				});
			})
			.catch(function (error) {
				// console.log(error);
			});

			/*Total Trucks with no transporter data*/
			await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
			.then((response) => {
				var notransrecords = JSON.parse(JSON.stringify(response)).data;
				//console.log("No Trasnporter Data response", notransrecords);
				
				this.setState({
					nopetransporters:notransrecords.length
				});
			})
			.catch(function (error) {
				// console.log(error);
			});
			
			this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
					
			this.setState({
				showdata : this.displayData,
				sliderTranslate:"slider-translate"
			});
		}
		/*End*/
	} 
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	onGridReady = params => {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		//this.gridApi.sizeColumnsToFit();
	}
	/*Truck Sidebar Component End*/
	onCloseUploadDiv = () => {
		this.onClickHideAll()
	}

	onClickShowTab = async(tab) =>{
		await this.setState({
			defaulttab:tab
		});
		await this.filterByDepartment()
	}
	onClickShowRailTab = async(tab) => {
		await this.setState({
			defaulttab:tab
		});
		await this.filterByDepartment()
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

  onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};

	onClickShowTPTTruckLocation(e){
		// console.log("e.data ", e.data)
		var deviceparams = {
			transporter_code:localStorage.getItem("transportercode")
		}
		redirectURL.post("/dashboard/devices", deviceparams).then((response1)=>{
			var transporterTrucks = [];
			var tptCode = localStorage.getItem("transportercode");
			response1.data.devices.map(function(e){
				// console.log(e,"devices data")
				if(tptCode.indexOf(e.transporter_code) > -1 )
				{
					transporterTrucks.push(e)
				}
			})
			this.setState({
				transporterTrucks : transporterTrucks
			});
			// this.filterByDepartment();
		});
		this.setState({
			loadshow:'show-m',			
			overly:'show-m',
			inputTruck:e.data.truck_no,
			deviceid:e.data.device_id
		})
		var sdate = moment.parseZone().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');		
		var enddate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		let transp_code = localStorage.getItem("transportercode") || "";
		if (transp_code) {
			try {
				const parsedData = JSON.parse(transp_code);
				transp_code = parsedData && parsedData[0] ? parsedData[0] : "";
			} catch (error) {
				console.error("Error parsing transportercode:", error);
				transp_code = "";
			}
		}
        var formdata= {
			truck_no:e.data.truck_no,
			device_id:e.data.device_id,
			startDate: sdate,
			endDate:enddate,
			frequency:"",
			source:"tptlogin",
			transporter_code : transp_code,
		}
		this.setState({
			startDate: sdate,
			endDate:enddate
		})
		// console.log("formdata ", formdata)
		redirectURL.post("/gmap",formdata,{
			headers:{
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin':"*",
				'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
			}
		})
		//.then(res => res.json())
		.then(
		async (result) => {
			// console.log("result ",result.data);
			var jsondata = result.data;
			//  console.log(jsondata)
			if(jsondata.status == 'failure')
			{
				this.setState({
					show:true,
					basicTitle:'No GPS data available for this period',
					basicType:"danger",
					loadshow:'show-n',
					overly:"show-n",
					mapData:null,
					mapinfo:'',
				})
			}
			else{
				var coordinates = JSON.parse(jsondata.data);
				//console.log("truckno ", this.props.truckno)
				// console.log("coordinates ", coordinates)
				var dataarr=[]
				var transporterTrucks = this.state.transporterTrucks;
				// var uqTrck = [];
				// var uqTrck = uqTrucks;
				var uqTrck =await coordinates.coords.filter(f =>
					transporterTrucks.some(d => d.truck_no == f.truck_no)
				);

				// console.log("coordinates ", coordinates)
				// await coordinates.coords.filter(f =>{
                //     transporterTrucks.filter((d )=> {
                //         if(d.truck_no == f.truck_no){
                //             if(d.registereddevices != undefined)
                //             {
                //                 if(d.registereddevices.length > 0)
                //                 {
                //                     f.device_address = d.registereddevices[0].address
                //                     f.device_city = d.registereddevices[0].city
                //                     f.device_state = d.registereddevices[0].state
                //                 }
				// 			}
				// 			dataarr.push(f)
				// 		}
				// 	})
				coordinates.coords.map((item) =>{
					// totalDistance.push(parseFloat(item.cumm_distance));
					dataarr.push({
						"truck_no":item.truck_no, 
						"lat":item.lat, 
						"lng":item.lng, 
						"speed":item.speed, 
						"stime":item.stime,
						"address":item.area,
						//"state":item.state,
						//"area":item.area,
						//"city":item.city,
						//"location_type":item.location_type,
						"dist_from_prev_point":item.dist_from_prev_point, 
						"received_on":item.received_on,
                        "cumm_distance":item.cumm_distance,
						"time_from_prev_point":item.time_from_prev_point,
						"area" : item.area,
						"state" : item.state,
					})
				});
				console.log("Modified ", dataarr)
				this.setState({
					mapData:dataarr,
					mapinfo:JSON.parse(jsondata.data),
					loadshow:'show-n',
					overly:'show-m',
					tolls : JSON.parse(jsondata.data).tolls,
					sliderRouteTranslate:"slider-translate-75p",
				})
				// this.renderMap()
				redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:"/trucksgpsintegrationmapgriddata"})
		  		.then( async (response) => {
					this.setState({
						usergridstate1:response.data
					});
					this.restoreGridStates1();
		  		})
		
			}
				
		}
		)
		// .catch(function (error) {
		// 	console.log(error);
		// });
		
    }
    showmarkers(event)
    {
        // console.log("showmarkers ", event.target.value);
        if(event.target.value == 0)
        {
            viewMarkersArr.map(function(e){
				e.setVisible(true);
            });
            this.setState({
                showmarkers : 1
            })
        }
        else
        {
			viewMarkersArr.map(function(e){
				e.setVisible(false);
            });
            this.setState({
                showmarkers : 0
            })
        }
	}
	onClickShowTruckLocation(e){
        // console.log("CLiclable ", e)
        // var lat = parseFloat(e.data.lat);
		// var lng =parseFloat(e.data.lng);
		// var data =e.data;
		// map.setCenter(new window.google.maps.LatLng(lat,lng));
		// map.setZoom(22);
		// var markerLatlng = new window.google.maps.LatLng(lat, lng);
		// //var image =  require('../../assets/icons/truckblue.png');
		// var image =  require('../../assets/icons/gmarker.png');
		
		// var marker = new window.google.maps.Marker({
		// 	position: markerLatlng,
		// 	map: map,
		// 	title:data.truck_no,
		// 	icon:image
		// });


		// var contentarr = []
		// var header = data.truck_no
		// //contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
		// contentarr.push({"key":"City/Area", "value":data.area})
		// contentarr.push({"key":"State", "value":data.state})

		// var contentString = infoBox(marker.icon, header, contentarr,'')
			
		// var infowindow = new window.google.maps.InfoWindow({
		// 	content: contentString
		// });
		
		// marker.setAnimation(window.google.maps.Animation.DROP)
		// marker.addListener('click', function() {
		// 	infowindow.open(map, marker);
		// });
		
		// marker.setMap(map);
		
    }
	renderMap = () => {   
		
		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
    initMap = () => {
        //console.log("arr ",arr)
		try
		{
			var currentwindow;
			var dealer='';
			var tolls = this.state.tolls;
			
			var arr=[];
			var jsondata = this.state.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			if(arr.length > 0 && typeof arr.length != undefined)
			{
				var lt=arr[0].lat;
				var ln=arr[0].lng;
			}
			else{
				var lt=28.4519751;
				var ln=77.0310713;
			}
			var routeinfo ;
			try{
				var mapOptions = {
					zoom: 13,
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: true,
					streetViewControl: true,
					rotateControl: true,
					fullscreenControl: true,
					labels:true,
					mapTypeControlOptions: {
						mapTypeIds: ['satellite', 'roadmap'],
					},
					center: new window.google.maps.LatLng(lt,ln),
					mapTypeId: window.google.maps.MapTypeId.ROADMAP
				};
						
			}
			catch(e){
				var mapOptions = {
					zoom: 13,
					zoomControl: true,
					mapTypeControl: true,
					scaleControl: true,
					streetViewControl: true,
					rotateControl: true,
					fullscreenControl: true,
					labels:true,
					mapTypeControlOptions: {
						mapTypeIds: ['satellite', 'roadmap'],
					},
					center: new window.google.maps.LatLng(28.4519751,77.0310713),
					mapTypeId: window.google.maps.MapTypeId.ROADMAP
				};
						
			}
			try{
				map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
			}catch(e){}
			
			var bounds = new window.google.maps.LatLngBounds();
			bounds.extend(new window.google.maps.LatLng(lt,ln));
				// Create our info window content
			var currentinfowindow = null;
			var line=new window.google.maps.Polyline(
			{
				map:map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
						// icon: {
						// 		path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						// 		strokeColor:'#ff8c52',
						// 		fillColor:'#ff8c52',
						// 		fillOpacity:1,
						// 		strokeWeight: 2
						// },
						repeat:'100px',
						path:[]
					}]
				});
			if(arr.length > 0)
			{
				var allpoints = [];
			
				var arr1=[];
				//console.log("Total ",ratio);
				if(this.state.timelinesmarkers.length > 0)
				{
					arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
						return (index % ratio == 0);
					} );
					
		
					//var arr1 = this.state.timelinesmarkers;
				}
				
				var arr2=[];
				var darr=[];
				if(arr.length > 0)
				{
					darr = arr.filter(function (value, index, arr) {
						//console.log("Vale ",value)
						return (value.dist_from_prev_point > 0);
					} );
					if(darr.length < 2000)
					{
						var ratio = 1;
					}
					else{
						var ratio = 20;
					}
					//console.log("darr.length ", darr.length)
					arr2 = darr.filter(function (value, index, darr) {
						return (index % ratio == 0);
					} );
					
		
					//var arr1 = this.state.timelinesmarkers;
				}
				
				// view markers code
				if(arr2.length > 0)
				{
						// //console.log("allpoints ", allpoints)
						
						for(var a=0;a<arr2.length;a++)
						{
							//console.log(arr2[a],"arr2[a]");
							var marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
								icon: {
										path: window.google.maps.SymbolPath.CIRCLE,
										strokeColor:'#157254',
										fillColor:'#157254',
										fillOpacity:1,
										strokeWeight: 5,
										scale:1,
									},
								map: map,
								content:arr2[a]
							});
							marker.setVisible(false);
							// 	//console.log("Arr ",arr1[a])
							window.google.maps.event.addListener(marker, 'mouseover', (function(marker) {
								return function() {
									//console.log("Arr ",marker)
									var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Current Status"
								contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(marker.content.stime)})
								contentarr.push({"key":"Speed ", "value":marker.content.speed+" KMPH"})
								
								var contentString = infoBox("", header, contentarr,'')
								
								
								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker));
							window.google.maps.event.addListener(marker, 'mouseout', function() {
								infowindow.close();
							});
			
							viewMarkersArr.push(marker);
							
						}
				}
				var routeinfo = jsondata.route_details;
				var icon_cnt = 100
				for(var i=0;i< arr2.length-1;i++)
				{
					var locationLatLng = [];
					locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
					locationLatLng.push(new window.google.maps.LatLng(arr2[i+1].lat,arr2[i+1].lng));
					if(icon_cnt % 10 == 0)
					{
						var lineicon = [{
									// icon: {
									//         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
									//         strokeColor:'#FF0000',
									//         fillColor:'#FF0000',
									//         fillOpacity:1,
									//         strokeWeight: 2
									// },
									repeat:'100px',
									path:[]
								}];
					}else{lineicon=[]}
					icon_cnt = icon_cnt + 1
					var consignments_missing_route_line = new window.google.maps.Polyline({
						map:map,
						path : locationLatLng,
						strokeColor: '#157254',
						strokeOpacity: 1.0,
						strokeWeight: 2.5,
						icons: lineicon
					});
					// console.log(arr2[i].msgtype)
					if(arr2[i].msgtype == "G")
					{
						
						consignments_missing_route_line.setOptions({
							strokeColor : "#452a68",
							strokeWeight: 5.5,
						})
					}
					bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
				}
					
					// for (let i = 0; i < arr2.length; i++) {
					//     //if(arr2[i].speed > 0)
					//     //{
					//         var linecolr = "#157254";
					//         if(arr2[i].set  == 2)
					//         {
					//             linecolr = "#0000ff";
					//         }
					//         if(arr2[i].set  == 3)
					//         {
					//             linecolr = "#980000"
					//         }
					//         line.setOptions({strokeColor: linecolr}); 
					//     var path=line.getPath().getArray();
								
					//     let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
					//     path.push(latLng);
					//     line.setPath(path);
							
					//     //Change line color based on map type
					//     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
					//         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
					//         {
					//             var line=new window.google.maps.Polyline(
					//             {
					//                 map:map,
					//                 strokeColor: '#FFFFFF',
					//                 strokeOpacity: 1.0,
					//                 strokeWeight: 2.5,
					//                 icons: [{
					//                         icon: {
					//                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
					//                                 strokeColor:'#ff8c52',
					//                                 fillColor:'#ff8c52',
					//                                 fillOpacity:1,
					//                                 strokeWeight: 2
					//                                 },
					//                         repeat:'100px',
					//                         path:[]
					//                     }]
					//                 });                
					//         }
					//         else
					//         {
					//             var line=new window.google.maps.Polyline(
					//             {
					//                 map:map,
					//                 strokeColor: '#157254',
					//                 strokeOpacity: 1.0,
					//                 strokeWeight: 2.5,
					//                 icons: [{
					//                         icon: {
					//                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
					//                                 strokeColor:'#ff8c52',
					//                                 fillColor:'#ff8c52',
					//                                 fillOpacity:1,
					//                                 strokeWeight: 2
					//                                 },
					//                         repeat:'100px',
					//                         path:[]
					//                     }]
					//                 });

					//         }

					//         for (i = 0; i < arr2.length; i++) {
					//                 var path=line.getPath().getArray();
					//                 let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
					//                 path.push(latLng);
					//                 line.setPath(path);
					//                         //map.setCenter(latLng);
					//             }
											
					//         var x = map.getZoom();
					//         var c = map.getCenter();
					//         window.google.maps.event.trigger(map, 'resize');
					//         map.setZoom(x);
					//         map.setCenter(c);
					//     } );
					//     bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
					//     //}
					// }

					var infowindow = new window.google.maps.InfoWindow();
						var marker, l;

						for (l = 0; l < locations.length; l++) {
						marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
						icon:require('../../assets/icons/cf.png'),
						map: map,
						});

						window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
							return function() {
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";
							
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Break";
							try{
								if(typeof this.props.mapFor != 'undefined')
								{
									var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
									contentarr.push({"key":"Break Start", "value":locations[l].break_start})
									contentarr.push({"key":"Break End", "value":locations[l].break_end})
									contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
								}
								else
								{
									contentarr.push({"key":"Break Start", "value":locations[l].break_start})
									contentarr.push({"key":"Break End", "value":locations[l].break_end})
									contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
								}
							}
							catch(e){
								contentarr.push({"key":"Break Start", "value":locations[l].break_start})
								contentarr.push({"key":"Break End", "value":locations[l].break_end})
								contentarr.push({"key":"Break time", "value":timeConvert(locations[l].break_time_seconds)})
							}
							
							
							var contentString = infoBox(marker.icon, header, contentarr,'')
							
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							//console.log(marker.position.toJSON());
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker, l));
						}
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
							icon:require('../../assets/icons/track_start.png'),
							map: map,	
						});
					window.google.maps.event.addListener(marker, 'click', (function(marker) {
							return function() {
								//	console.log(clusters[0])
								
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Starting Point"
							contentarr.push({"key":"Started at", "value":routeinfo.start_time})
							
							var contentString = infoBox(marker.icon, header, contentarr,'')
								
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker));
						if(this.state.adaniCheck == 1)
						{
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
								icon:require('../../assets/icons/shuttle-end.png'),
								map: map,	
							});
						}
						else
						{
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
								icon:require('../../assets/icons/truck-end.png'),
								map: map,	
							});
						}
					window.google.maps.event.addListener(marker, 'click', (function(marker) {
							return function() {
								//	console.log(clusters[0])
								
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "End Point"
							contentarr.push({"key":"End at", "value":routeinfo.end_time})
							
							var contentString = infoBox(marker.icon, header, contentarr,'')
								
							infowindow.setContent(contentString);	
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker));
				
				}
				try{
					if(tolls.length > 0)
					{
						var infowindow = new window.google.maps.InfoWindow();
						var l;
						tolls.map(function(e,index){
							var tollMarker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(e.lat, e.lon),
								icon:require('../../assets/icons/barrier.png'),
								map: map,
							});
							window.google.maps.event.addListener(tollMarker, 'mouseover', (function(tollMarker, index) {
								return function() 
								{
									var contentarr = []
									var header = "Toll - "+e.name
									contentarr.push({"key":"Address", "value":e.location})
									var contentString = infoBox(tollMarker.icon, header, contentarr)
									infowindow.setContent(contentString);
									var currentwindow = infowindow;
									infowindow.open(map, tollMarker);
								}
							})(tollMarker, index));
							window.google.maps.event.addListener(tollMarker, 'mouseout', function() {
								infowindow.close();
								});
						})  
						
					}
				}catch(e){}
				
			
				map.fitBounds(bounds)

		}
		catch(e){}
	}
// 	renderGeoMap = () => {
    	
// 		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initGeoMap");
// 		window.initGeoMap = this.initGeoMap
//  }
 

initGeoMap = () => {
		
	var lt=28.4519751;
	var ln=77.0310713;

	map = new window.google.maps.Map(document.getElementById('map_canvas_tpt_info'), {
		zoom: 13,
		center: new window.google.maps.LatLng(lt,ln),
		mapTypeId: window.google.maps.MapTypeId.ROADMAP,
		/*mapTypeControl: true,
		gestureHandling: 'greedy',
		mapTypeControlOptions: {
			style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
			position: window.google.maps.ControlPosition.TOP_RIGHT
		  },            
		disableDefaultUI: true,
		zoomControl: true*/
	});

	var input = document.getElementById('search');
	var searchBox = new window.google.maps.places.SearchBox(input);
	map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

	// Bias the SearchBox results towards current map's viewport.
	map.addListener('bounds_changed', function() {

	  searchBox.setBounds(map.getBounds());
	});
	
	var markers = [];
	//console.log("searchBox",searchBox);
	searchBox.addListener('places_changed', function() 
	{
	  var places = searchBox.getPlaces();
		
	  if (places.length == 0) {
		return;
	  }

	  // Clear out the old markers.
	  markers.forEach(function(marker) {
		//   console.log("Search Marker ", marker)
		marker.setMap(null);
	  });
	  markers = [];
		// For each place, get the icon, name and location.
	  var bounds = new window.google.maps.LatLngBounds();
	  places.forEach(function(place) {
		if (!place.geometry) {
		//   console.log("Returned place contains no geometry");
		  return;
		}
		var icon = {
		  url: place.icon,
		  size: new window.google.maps.Size(71, 71),
		  origin: new window.google.maps.Point(0, 0),
		  anchor: new window.google.maps.Point(17, 34),
		  scaledSize: new window.google.maps.Size(25, 25)
		};

		// Create a marker for each place.
		//console.log("Plae Lat", place.geometry.location.lat());
		document.getElementById("geo_latitude").value =place.geometry.location.lat();

		document.getElementById("geo_longitude").value =place.geometry.location.lng();
		
		markers.push(new window.google.maps.Marker({
		  map: map,
		  icon: icon,
		  title: place.name,
		  position: place.geometry.location
		}));

		if (place.geometry.viewport) {
		  // Only geocodes have viewport.
		  bounds.union(place.geometry.viewport);
		} else {
		  bounds.extend(place.geometry.location);
		}
	  });
	  
	  map.fitBounds(bounds);
	});
	
	circle = new window.google.maps.Circle({
		map: map
	});
	
	marker = new window.google.maps.Marker({
		map: map,
		draggable: true,
		animation: window.google.maps.Animation.DROP,
		//position: {lat: 28.48, lng: 77.06}
	});
	
	marker.addListener('click', function(){
		marker.setAnimation(window.google.maps.Animation.BOUNCE)			
	});
	
	  window.google.maps.event.addListener(map, 'click', function(event) {
		
		document.getElementById("latitude").value = event.latLng.lat();
		document.getElementById("longitude").value = event.latLng.lng();
		marker.setPosition(event.latLng);
		
	});
	circle.bindTo('center', marker, 'position');
	
	
	
	var eventtrigger = document.getElementsByClassName('truck');

	  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
	  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
	 
}
	formHandler = async (event) => {
        this.setState({
			loadshow:'show-m',
			overly:'show-m',
			shownnbmaps:false,
        })
        event.preventDefault();
        var inputTruck = this.state.inputTruck;
        var dataset = this.state.truckNoList;
        
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGenerate,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//var formdata = new FormData(event.target);
		// console.log($("#inputTruck").val())
		let transp_code = localStorage.getItem("transportercode") || "";
		if (transp_code) {
			try {
				const parsedData = JSON.parse(transp_code);
				transp_code = parsedData && parsedData[0] ? parsedData[0] : "";
			} catch (error) {
				console.error("Error parsing transportercode:", error);
				transp_code = "";
			}
		}
	
		var formdata= {
				truck_no:this.state.inputTruck,
				device_id:this.state.deviceid,
				startDate:getHyphenYYYYMMDDHHMMSS(this.state.startDate),
				endDate:getHyphenYYYYMMDDHHMMSS(this.state.endDate),
				frequency:this.state.frequency,
				transporter_code : transp_code,
		}
		// console.log("formdata ", formdata)
		await redirectURL.post("/gmap",formdata,{
			headers:{
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin':"*",
				'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
				'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
			}
		})
		//.then(res => res.json())
		.then(
		async (result) => {
			// console.log("result ",result.data);
			var jsondata = result.data;
			//  console.log(JSON.parse(jsondata.data))
			if(result.data.status == 'failure')
			{
				this.setState({
					show:true,
					basicTitle:'No GPS data available for this period',
					basicType:"danger",
					loadshow:'show-n',
					mapData:null,
					mapinfo:'',
				})
			}
			else{
				var coordinates = JSON.parse(jsondata.data);
				//console.log("truckno ", this.props.truckno)
				//console.log("coordinates ", coordinates)
				var dataarr=[]
				// console.log("coordinates ", coordinates)
				var dataarr=[]
				var tottalDistance = [];
				//var transporterTrucks = this.state.transporterTrucks;
				// var uqTrck = [];
				// var uqTrck = uqTrucks;
				// var uqTrck =await coordinates.coords.filter(f =>
				// 	transporterTrucks.some(d => d.truck_no == f.truck_no)
				//);

				// console.log("coordinates ", coordinates)
				// await coordinates.coords.filter(f =>{
                //     transporterTrucks.filter((d )=> {
                //         if(d.truck_no == f.truck_no){
                //             if(d.registereddevices != undefined)
                //             {
                //                 if(d.registereddevices.length > 0)
                //                 {
                //                     f.device_address = d.registereddevices[0].address
                //                     f.device_city = d.registereddevices[0].city
                //                     f.device_state = d.registereddevices[0].state
                //                 }
				// 			}
				// 			dataarr.push(f)
				// 		}
				// 	});
				// });
				coordinates.coords.map((item) => {
					// console.log("item ,", item)
					tottalDistance.push(parseFloat(item.cumm_distance));

					dataarr.push({
						"truck_no":item.truck_no, 
						"lat":item.lat, 
						"lng":item.lng, 
						"speed":item.speed, 
						"stime":item.stime,
						"address":item.area,
						//"state":item.state,
						//"area":item.area,
						//"city":item.city,
						//"location_type":item.location_type,
						"dist_from_prev_point":item.dist_from_prev_point,
						"cumm_distance" :item.cumm_distance,
						"received_on":item.received_on, 
						"time_from_prev_point":item.time_from_prev_point,
						"area" : item.area,
						"state" : item.state
					})
				});
				// console.log("Modified form ", dataarr)
				await this.setState({
					mapData:dataarr,
					mapinfo:JSON.parse(jsondata.data),
					tolls : JSON.parse(jsondata.data).tolls,
					shownnbmaps:true,
					loadshow:"show-n",
				})

				

				

				
		
			}
				
		}
		)
		.catch(function (error) {
			// console.log(error);
		});
        
    }
	geoFenceSuccessUpdate = () => {
		var geofencename = this.state.geo_fence_aliasname
		// console.log(geofencename,"geofencename")
		this.setState({
			show: true,
			basicTitle:'Successfully Marked Geo Fence',
			basicType:"success",
			sliderTranslate:"",
			geofencename:"",
			overly:"show-n"
		});
		this.componentDidMount();
	}
	onClickFilterReport = async (event) => {
        event.preventDefault();
        // var inputTruck = this.state.inputTruck;
        // var dataset = this.state.truckNoList;
        
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedGenerate,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		// if(coordinatesgeo.length > 0)
		// {
			var geo_fence_address = $("#geo_address").val();
			var geo_fence_aliasname = $("#geo_alias_adresss").val();
			var geo_fence_latitude = $("#geo_latitude").val();
			var geo_fence_longitude = $("#geo_longitude").val();
			var transporter_code = localStorage.getItem('transportercode');
			
			
			var params ={
				geo_fence_address:geo_fence_address,
				geo_fence_aliasname:geo_fence_aliasname,
				geo_fence_latitude:geo_fence_latitude,
				geo_fence_longitude:geo_fence_longitude,
				transporter_code:transporter_code
			} 
			// console.log(params,"paramsgeofence");
			
			redirectURL.post("/consignments/updatetptgeofence", params)
			.then((response) => {
				// console.log(response,"popup1");
				if(response.data == "success")
				{
					
					// console.log(response,"popup");
					this.setState({
						show: true,
						basicTitle:'Successfully Marked Geo Fence',
						basicType:"success",
						sliderTranslate:"",
						geo_fence_aliasname:"",
					}); 
					this.componentDidMount()
				}
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
			})
			.catch(function(e){
				// console.log("Error ", e)
			})

		// }
		// else
		// {
		// 	this.setState({
		// 		erroMessage:'Please Add Coordinates'
		// 	});
		// }
        
    }
    onClickCounterShowData(val)
    {
        // // console.log("Val ", val)
        // console.log("total data ",this.props.information_tab_data.originalData)
		// console.log("gpsnodata data ",this.props.information_tab_data.gpsnodata)
        if(val == 0)
        {
            this.setState({
                allrowData:this.props.information_tab_data.originalData,
				selectedtrucknumber:""
            })
        }
        if(val == 1)
        {
            this.setState({
                allrowData:this.props.information_tab_data.gpsrowData,
				selectedtrucknumber:""
            })
        }
        if(val == 3)
        {
            this.setState({
                allrowData:this.props.information_tab_data.gpsnodata,
				selectedtrucknumber:""
            })
        }
        if(val == 4)
        {
            this.setState({
                allrowData:this.props.information_tab_data.onTripData,
				selectedtrucknumber:""
            })
        }
    }
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Trucks Information Data';
		// if(this.props.match.path == "/sndconsignments")
		// {
		// 	screenpage='snd active consignments';
		// }
		// console.log("window.location.pathname;",window.location.pathname)

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		// console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			// console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			// console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		// console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}

	restoreGridStates1()
	{
		// console.log("this.state.usergridstate1", this.state.usergridstate1)
		try{
			if(this.state.usergridstate1.length > 0)
			{
				var windowstates = this.state.usergridstate1;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi2.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi2.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi2.setSortModel(windowstates[0].gridcolsort);
				this.gridApi2.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}
	

	onClickSaveGridState1(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi2.getColumnState();
		window.groupState = this.gridColumnApi2.getColumnGroupState();
		window.sortState = this.gridApi2.getSortModel();
		window.filterState = this.gridApi2.getFilterModel();
		
		let screenpage = 'Trucks Gps Data';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:"/trucksgpsintegrationmapgriddata",
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			// console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:"/trucksgpsintegrationdata",
				screentitle:screenpage
			})
		})
		.catch(function(e){
			// console.log("Error ", e)
		})
	}

	// restoreGridStates()
	// {
	// 	///console.log("this.state.usergridstate ", this.state.usergridstate)
	// 	try{
	// 		if(this.state.usergridstate.length > 0)
	// 		{
	// 			var windowstates = this.state.usergridstate;
	// 			console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
	// 			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
	// 			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
	// 			this.gridApi.setSortModel(windowstates[0].gridcolsort);
	// 			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
	// 		}
	// 	}
	// 	catch(e){}
	// }

	changeHandlerforWhatsUp = (event) => {
		var name = event.target.name;
		var value = event.target.value;
		const alphanumericRegex = /^\d+$/
		if ((alphanumericRegex.test(value) && value.length < 11) || value === '') {
			this.setState({
				[name]: value
			})
		}
		console.log(this.state.whatsup_no , "whatsup_no")

	}

	
	handleEncrypt = (word) => {
		const plaintext = word;
		const key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
		const iv = CryptoJS.enc.Hex.parse('0102030405060708090a0b0c0d0e0f00');
		const encryptedBytes = CryptoJS.AES.encrypt(plaintext, key, {
			iv: iv
		});
		const encryptedBase64 = encryptedBytes.toString();
		const encryptedText = encodeURIComponent(encryptedBase64);
		return encryptedText;
	};
	
	handleDecrypt = (word) => {
		const encryptedText = decodeURIComponent(word);
		const key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
		const iv = CryptoJS.enc.Hex.parse('0102030405060708090a0b0c0d0e0f00');
		const decryptedBytes = CryptoJS.AES.decrypt(encryptedText, key, {
			iv: iv
		});
		const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
		return decryptedText;
	};

	copyTextToClipBoard = async () => {
		var data = this.state.share_link_data

		var current_date = new Date()
		var current_date = new Date(current_date.setHours(current_date.getHours() - 1))

		var expired_time = current_date.getTime() + this.state.expired_time.value * 24 * 60 * 60 * 1000
		var expired_date = moment.parseZone(new Date(expired_time)).format('YYYY-MM-DD HH:mm:ss')
		var expiry_date = await this.handleEncrypt(expired_date)

		var from_date = moment.parseZone(new Date(current_date)).format('YYYY-MM-DD HH:mm:ss')
		var from_date = await this.handleEncrypt(from_date)

		console.log(from_date , expiry_date , "3697")
		var link = `https://enmovil.io/truckdetails/sharelink/tptlogin/${data.truck_no}/${from_date}/${expiry_date}`
		await navigator.clipboard.writeText(link);
		alert('Text copied to clipboard');
	}

	SubmitShareLink = async (event) => {
		event.preventDefault()
		var data = this.state.share_link_data

		var current_date = new Date()
		var current_date = new Date(current_date.setHours(current_date.getHours() - 1))

		if (this.state.whatsup_no.length == 10) {

			var expired_time = current_date.getTime() + this.state.expired_time.value * 24 * 60 * 60 * 1000
			var expired_date = moment.parseZone(new Date(expired_time)).format('YYYY-MM-DD HH:mm:ss')
			var expiry_date = await this.handleEncrypt(expired_date)

			var from_date = moment.parseZone(new Date(current_date)).format('YYYY-MM-DD HH:mm:ss')
			var from_date = await this.handleEncrypt(from_date)

			console.log(from_date, expiry_date, "3697")
			var link = `https://enmovil.io/truckdetails/sharelink/tptlogin/${data.truck_no}/${(from_date)}/${(expiry_date)}`
			// window.open(`/truckdetails/sharelink/tptlogin/${data.truck_no}/${from_date}/${expiry_date}`) 
			// window.open(`/truckdetails/sharelink/tptlogin/${data.truck_no}`)
			// window.open(`https://api.whatsapp.com/send?text=${link}` , '_blank')

			window.open(`https://web.whatsapp.com/send?phone=${this.state.whatsup_no}&text=${encodeURIComponent(link)}`)

			this.setState({
				sliderForShareLink: "",
				whatsup_no: "",
				expired_time: { label: "1 Day", value: 1 },
				overly: "show-n"
			})
		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "WhatApp Number should be 10 Digits"
			})
		}

	}

	async popmarker(e)
	{
        
        
        if(e.colDef.field == "idletimeview")
        {
            // console.log(e)
			redirectURL.post("/consignments/getidletimeremarks")
			.then(async (response) =>{
				var idletimedata = response.data
				// console.log(idletimedata,"respData")
			
        // console.log(fasttagtnx)
        await this.setState({
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName: "Truck No",
                            field: "truck_no",
                            width: 140,
						},
						{
                            headerName: "Idle Time Remarks",
                            field: "idle_time_remarks",
                            width: 140,
						},
						{
                            headerName: "Email Id",
                            field: "email_id",
                            width: 140,
						},
						{
							headerName: "Remarks Date",
							field: "created_date",
							width: 200,
							valueGetter:function(params){
							return getHyphenDDMMMYYYYHHMM(params.data.created_date)}
			
						},
                        
                          
                    ],
                    overlayNoRowsTemplate: 'No rows to show',
                    refreshStrategy: 'everything',
                },
                getDetailRowData:async  (param) =>{
                    // console.log(param)
                    var insidefilterData = await idletimedata.filter(function (k) {
                        return k.truck_no == param.data.truck_no;
                  });
                    // console.log("inside filterData ", insidefilterData)
                    param.successCallback(insidefilterData);
                },
                masterDetail: true
            }
        	});
            e.node.setExpanded(!e.node.expanded);
		})
        }

		if(e.colDef.field == 'share_link'){
			if(e.colDef.field== 'share_link' ){
				this.setState({
					share_link_data : e.data,
					sliderForShareLink:"slider-translate-30p",
					overly:'show-m'
				})
			}
			// window.open(`/truckdetails/sharelink/tptlogin/${truck_no}`)
		}
	}
	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
	onShowEditAction = (row) => {
        // console.log(row,"row data")
		this.setState({
			sliderTranslate: 'slider-translate-50p',
			overly: 'show-m',
			geo_address:row.address
		})

	}
	toggle = () => {
        const currentState = this.state.mapToggle;
        this.setState({ mapToggle: !currentState });
    }
	resetState1 = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi2.resetColumnState();
		this.gridColumnApi2.resetColumnGroupState();
		this.gridApi2.setSortModel(null);
		this.gridApi2.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:"/trucksgpsintegrationmapgriddata",
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	searchTruckChange = (e) =>{
		this.setState({
			selectedtrucknumber:e
		});
		var trucknumber = e;
		// console.log(e,"e.value")
		// console.log(trucknumber,"trucknumber")
        var truck_number = [];
		var searchtrucks = this.state.inrowData
		var filteredData = [];
        if(trucknumber !=null && trucknumber != "null")
        {
            if(trucknumber.length > 0)
            {
                trucknumber.map(function(e){
                    // truck_number.push(e.value)
					searchtrucks.map(function(f){
						if(f.truck_no == e.value)
						{
							filteredData.push(f);
						}
					})
                });
            }
			else
			{
				filteredData = searchtrucks
			}
        }
		else
		{
			filteredData = searchtrucks
		}
		this.setState({
			allrowData:filteredData
		});
	}

	onClickServiceRequest = () => {
		window.location.href = "/tptlogin/tickets"
	}

    render(){
		var pdfRowData = [...this.state.allrowData]		
		const statedeptcode = this.state.deptvalue;
		//console.log("statedeptcode ",statedeptcode)
		const {t329Check,adaniCheck} = this.state;
		var t329_check = t329Check;
		var tkName = "Truck No";

		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
		var isAdminRake = localStorage.getItem("email").includes("admin_rake@enmovil.in") ? false :true
		var hideForEntrac = localStorage.getItem("transportercode").includes('_ENTRAC')? true : false
		if (localStorage.getItem("transportercode").includes("_RAKE")){
			var columnwithDefs= [
				{
					headerName:"",
					field:"",
					width:50,
					cellRenderer:'tpttrucklocaction'
				},
				{
				  headerName: "Device Name",
				  field: "truck_no",
				  width: 100,
				},
				{
					headerName: "Device ID",
					field: "device_id",
					width: 100,
				},
				{
					headerName:"Transporter Code",
					field:"transporter_code",
					width:150,
					hide:isAdminRake,
				},
				{
					headerName: "Last Packet Time",
					field: "timestamp",
					width: 130,
					//cellRenderer:'dateFormater'
					valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// colId:"timestamp",
				  
					  
				},
				{
					headerName: "Last Moved Time",
					field: "truck_last_moved",
					width: 130,
					//cellRenderer:'dateFormater'
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.truck_last_moved)
						},
					hide : true,
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// colId:"timestamp",
				  
					  
				},
				{
					headerName: "Battery Percentage",
					field: "battery_percentage",
					width: 200,
					filter: true,
					resizable: true,
					valueGetter:function(params){
						try{
						  if(params.data.battery_percentage != undefined && params.data.battery_percentage != "")
						  {
							  return parseInt(params.data.battery_percentage);
						  }
						  else{
							  return 0;
						  }
						}
						catch(e){
		
						}
					}
				},
			{
				headerName: "Address",
				field: "address",
				width: 200,
				
			},
			
			{
				headerName: "City",
				field: "city",
				width: 110,
				// colId:"device_city",
			},
			{
				headerName: "District",
				field: "district",
				width: 170,
				// colId:"device_city",
			},
			{
				headerName: "State",
				field: "state",
				width: 110,
				// colId:"device_state",
			},
			{
				headerName: "Speed (KM/H)",
				field: "last_known_speed",
				width: 100,
				filter: true,
				resizable: true,
				valueGetter : function(params) {
					if (![undefined, "", null, "undefined"].includes(params.data.last_active_on)) {
						var last_active_on = moment.utc(new Date(params.data.last_active_on)).format("YYYY-MM-DD HH:mm:ss")
						var current_date = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
						var minutes = moment.parseZone(new Date(current_date)).diff(moment.parseZone(new Date(last_active_on)), "minutes")
						if (minutes <= 30) {
							if (params.data.last_known_speed > 0) {
								return params.data.last_known_speed
							}
							else {
								return 0
							}
						} else {
							return 0
						}
					} else {

						return 0

					}
				}
				// colId:"last_known_speed",
				
			},
			  {
				headerName: "Travel Status",
				field: "elaped_time_from_last_moved_seconds",
				width: 100,
				// colId:"elaped_time_from_last_moved_seconds",
				valueGetter:function(params){
					try{
						
						// console.log("params.data.travel_sttaus ", params.data.travel_sttaus)
						if (params.data.travel_sttaus != "" && params.data.travel_sttaus != undefined) {
							return "Inactive";
						}
						else {
							// console.log("params.data.last_known_speed ", params.data.last_known_speed)
							if (![undefined, "", null, "undefined"].includes(params.data.last_active_on)) {
								var last_active_on = moment.utc(new Date(params.data.last_active_on)).format("YYYY-MM-DD HH:mm:ss")
								var current_date = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
								var minutes = moment.parseZone(new Date(current_date)).diff(moment.parseZone(new Date(last_active_on)), "minutes")
								if (minutes <= 30) {
									if (params.data.last_known_speed > 0) {
										return "Travelling";
									}
									else {
										var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
										return "Idle (" + mre + ")";
									}
								} else {
									var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
									return "Idle (" + mre + ")";
								}
							} else {

								var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
								return "Idle (" + mre + ")";

							}


						}
						
					}
					catch(e){
						return "";
					}
				},
				cellClass:function(params){
					try{
						if(params.data.travel_sttaus == "Inactive")
						{
							return ["maroon","fbold"];
						}
						else
						{
							
									if(params.data.last_known_speed > 0)
									{
										return ["greenfont","fbold"];
									}
									else
									{
										return ["redfont","fbold"];
									}
							
						}
						
					}
					catch(e){
						return "";
					}
				}
			  },
				{
					headerName: "Distance Today (KM)",
					field: "today_distance",
					width: 110,
					// colId:"today_distance",
				},
				
				{
					headerName: "Distance Yesterday (KM)",
					field: "yesterday_distance_km",
					width: 140,
					// colId:"yesterday_distance_km",
				},
				{
					headerName:"Total Trip Distance (Km)",
					field:"aprox_dist_travelled_from_start",
					width: 150,                 
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true	,
					// colId:"aprox_dist_travelled_from_start",
					valueGetter:function(params){
						// console.log("params ", params.data);
						if(typeof params.data.aprox_dist_travelled_from_start == 'undefined')
						{
						return 0;
						}
						else
						{
							return Math.round(params.data.aprox_dist_travelled_from_start);
						}
						
					}
				},
			  ];
		}
		else{
			var columnwithDefs= [
				{
					headerName:"",
					field:"",
					width:50,
					cellRenderer:'tpttrucklocaction'
				},
				{
				  headerName: tkName,
				  field: "truck_no",
				  width: 100,
				//   colId: "truck_no",
				  
				},
				{
					headerName: "Truck Status",
					field: "truck_status",
					width: 110,
					hide: (localStorage.getItem("transportercode").includes("T329"))?false:true,
					editable: true,
					filter: "agSetColumnFilter",
					cellEditor: 'agSelectCellEditor',
					cellEditorParams: {
						values: this.state.statusList,
					},
					valueSetter:(params)=>{
						// console.log(params,"parms truck")
							if(params.oldValue != params.newValue)
							{
								var data = {
									truck_no:params.data.truck_no,
									// _id:params.data._id,
									truck_status:params.newValue,
									email:localStorage.getItem("email")
								}
								// console.log(data,"truck daata")
								params.data.truck_status = params.newValue;
								// console.log(params.data.truck_status = params.newValue,"params.data.truck_status = params.newValue")
								redirectURL.post("/consignments/updatetruckstatustpt", data,{
								headers:{
									'Content-Type': 'application/json'
									}
									}).then((response)=>{
								})
							}
						}
				},
			// 	{
			// 	  headerName: "LSP Code",
			// 	  field: "transporter_code",
			// 	  width: 200,
					  
			//   },  	        
			//   {
			// 	headerName: "LSP Name",
			// 	field: "transporter_name",
			// 	width: 200,
					
			//   },
			// 	{
			// 	headerName: "Department Code",
			// 	field: "dept_code",
			// 	width: 200,
					
			//   },
			// 	{
			// 	headerName: "Actual GPS Provider",
			// 	field: "actual_lspuser",
			// 	width: 200,
			// 	valueGetter: function(params){
			// 		return "Enmovil";
			// 	}
			//   },
			
			{
				headerName: "Address",
				field: "address",
				width: 200,
				// cellRenderer : function(params){
				// 	return '<button class="btn white btn-info" style="padding:0 6px 6px;color:#ccc" >Create GeoFence</button>'
				// }
				// colId:"device_address",
			},
			// {
			// 	headerName: "",
			// 	field: "markgeofence",
			// 	width: 200,
			// 	cellRenderer : function(params){
			// 		return '<button class="btn white btn-info" style="padding:0 6px 6px;color:#ccc" >Mark GeoFence</button>'
			// 	}
			// },
			  
			{
				headerName: "Current Geofence",
				field: "current_geofence_name",
				width: 200,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					try{
						if (params.data.current_geofence_name!=undefined && params.data.current_geofence_name!= ""){
							return params.data.current_geofence_name
						}
						else{
							if(params.data.distance_from_dealer_location != undefined && params.data.distance_from_dealer_location != ""&&params.data.distance_from_dealer_location <=5)
							{
								  return params.data.dealer_name
							}
							else { 
								if((params.data.status == 4 || params.data.status == "4")){
									if(![undefined , "undefined" , "" , null].includes(params.data.dealer_name)){
										console.log(params.data.truck_no , params.data.dealer_name , "dealer_name_1729")
										return params.data.dealer_name
									}else{
										return 'DEALER'
									}
								}
							}
							
						}
					 
					}
					catch(e){

					}
				}
			},	
			{
				headerName: "City",
				field: "city",
				width: 110,
				// colId:"device_city",
			},
			{
				headerName: "District",
				field: "district",
				width: 170,
				// colId:"device_city",
			},
			{
				headerName: "State",
				field: "state",
				width: 110,
				// colId:"device_state",
			},
			//   {
			// 	  headerName: "Last Known City/Town",
			// 	  field: "city",
			// 	  width: 170,
			//   },
			//   {
			// 	headerName: "Last Known State",
			// 	field: "state",
			// 	width: 170,
			//   },
			{
				headerName: "Speed (KM/H)",
				field: "last_known_speed",
				width: 100,
				filter: true,
				resizable: true,
				valueGetter : function(params) {
					if (![undefined, "", null, "undefined"].includes(params.data.last_active_on)) {
						var last_active_on = moment.utc(new Date(params.data.last_active_on)).format("YYYY-MM-DD HH:mm:ss")
						var current_date = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
						var minutes = moment.parseZone(new Date(current_date)).diff(moment.parseZone(new Date(last_active_on)), "minutes")
						if (minutes <= 30) {
							if (params.data.last_known_speed > 0) {
								return params.data.last_known_speed
							}
							else {
								return 0
							}
						} else {
							return 0
						}
					} else {

						return 0

					}
				}
				// colId:"last_known_speed",
				
			},
			  {
				headerName: "Travel Status",
				field: "elaped_time_from_last_moved_seconds",
				width: 100,
				// colId:"elaped_time_from_last_moved_seconds",
				valueGetter:function(params){
					try{
						
						// console.log("params.data.travel_sttaus ", params.data.travel_sttaus)
						if (params.data.travel_sttaus != "" && params.data.travel_sttaus != undefined) {
							return "Inactive";
						}
						else {
							// console.log("params.data.last_known_speed ", params.data.last_known_speed)
							if (![undefined, "", null, "undefined"].includes(params.data.last_active_on)) {
								var last_active_on = moment.utc(new Date(params.data.last_active_on)).format("YYYY-MM-DD HH:mm:ss")
								var current_date = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
								var minutes = moment.parseZone(new Date(current_date)).diff(moment.parseZone(new Date(last_active_on)), "minutes")
								if (minutes <= 30) {
									if (params.data.last_known_speed > 0) {
										return "Travelling";
									}
									else {
										var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
										return "Idle (" + mre + ")";
									}
								} else {
									var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
									return "Idle (" + mre + ")";
								}
							} else {

								var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
								return "Idle (" + mre + ")";

							}


						}
						
					}
					catch(e){
						return "";
					}
				},
				cellClass:function(params){
					try{
						if(params.data.travel_sttaus == "Inactive")
						{
							return ["maroon","fbold"];
						}
						else
						{
							// if(params.data.elaped_time_from_last_moved_seconds == "" && params.data.travel_sttaus != "" && params.data.travel_sttaus != undefined )
							// {
								// if(JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0)
								// {
									if(params.data.last_known_speed > 0)
									{
										return ["greenfont","fbold"];
									}
									else
									{
										return ["redfont","fbold"];
									}
								// }
								// else{
								// 	return ["redfont","fbold"];
								// }
							// }
							// else
							// {
							// 	// console.log("params.data.elaped_time_from_last_moved_seconds ", params.data.elaped_time_from_last_moved_seconds)
							// 	if(JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0)
							// 	{
							// 		if(params.data.last_known_speed > 0)
							// 		{
							// 			return ["greenfont","fbold"];
							// 		}
							// 		else
							// 		{
							// 			return ["redfont","fbold"];
							// 		}
							// 	}
							// 	else
							// 	{
							// 		if(params.data.elaped_time_from_last_moved_seconds <= 180)
							// 		{
							// 			return ["greenfont","fbold"];
							// 		}
							// 		else{
										
							// 			var mre = secondsToDhms(params.data.elaped_time_from_last_moved_seconds)
							// 			return ["redfont","fbold"];
							// 		}
							// 	}	
							// }
						}
						
					}
					catch(e){
						return "";
					}
				}
			  },
			  {
				headerName: "Idle Time Remarks",
				field: "idle_time_remarks",
				width: 110,
				editable:true,
				hide: (localStorage.getItem("transportercode").includes("T305")|| localStorage.getItem("transportercode").includes("T329"))?false:true,	
				valueSetter:(params)=>{
				// console.log(params)
					if(params.oldValue != params.newValue)
					{
						var data = {
							truck_no:params.data.truck_no,
							idle_time_remarks:params.newValue,
							email:localStorage.getItem("email")
						}
						params.data.idle_time_remarks = params.newValue;
						redirectURL.post("/consignments/updateidletimeremarks", data,{
						headers:{
							'Content-Type': 'application/json'
							}
							}).then((response)=>{
						})
					}
				}
	
	
			},
			{
				headerName: "",
				field: "idletimeview",
				width: 80,
				hide: (localStorage.getItem("transportercode").includes("T329"))?false:true,
				cellRenderer : function(params){
					return '<button class="btn white btn-info" style="padding:0 6px 6px;color:#ccc" >View</button>'
				}
			  },
			  {
					headerName: "Trip Status",
					field: "trip_status",
					width: 100,
					// colId:"trip_status",
					hide:hideForEntrac,
					cellClass:function(params){
						try{
							if(params.data.trip_status == "Not On Trip")
							{
								return ["ainfo","fbold"];
							}
							else
							{
								return ["greenfont","fbold"];
							}
							
						}
						catch(e){
							return "";
						}
					}
				},
				{
					headerName: "Distance Today (KM)",
					field: "today_distance",
					width: 110,
					// colId:"today_distance",
				},
				
				{
					headerName: "Distance Yesterday (KM)",
					field: "yesterday_distance_km",
					width: 140,
					// colId:"yesterday_distance_km",
				},
				{
					headerName:"Total Trip Distance (Km)",
					field:"aprox_dist_travelled_from_start",
					width: 150,                 
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true	,
					// colId:"aprox_dist_travelled_from_start",
					valueGetter:function(params){
						// console.log("params ", params.data);
						if(typeof params.data.aprox_dist_travelled_from_start == 'undefined')
						{
						return 0;
						}
						else
						{
							return Math.round(params.data.aprox_dist_travelled_from_start);
						}
						
					}
				},
				
				{
				  headerName: "Last Packet Time",
				  field: "timestamp",
				  width: 130,
				  //cellRenderer:'dateFormater'
				  valueGetter:function(params){
				  return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
				  },
				  //filter: "agDateColumnFilter",
				  comparator: dateComparator,
				  // colId:"timestamp",
				
					
			  },
			  {
				headerName: "Last Moved Time",
				field: "truck_last_moved",
				width: 130,
				//cellRenderer:'dateFormater'
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.truck_last_moved)
					},
				hide : true,
				//filter: "agDateColumnFilter",
				comparator: dateComparator,
				// colId:"timestamp",
			  
				  
			},
			  {
				headerName: 'Driver Name',
				field: "driver_name",
				width: 100,
				hide:hideForEntrac,
				filter: true
			},
			{
				headerName: 'Driver Mobile No',
				field: "driver_mobile_no", 
				width: 100,
				hide:hideForEntrac,
				filter: true
			},
			{
				headerName: 'OEM Name',
				field: "oem_name",
				width: 100,
				hide:hideForEntrac,
				filter: true,
				valueGetter:function(params){
					if(params.data.oem_name != "" && params.data.oem_name != undefined)
					{
						return params.data.oem_name
					}
				},
			}
			//   {
			// 	  headerName: "Data Received On",
			// 	  field: "modified_date",
			// 	  width: 130,
			// 	  //cellRenderer:'dateFormater'
			// 	  valueGetter:function(params){
			// 		  return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
			// 	  },
			// 	  //filter: "agDateColumnFilter",
			// 	  comparator: dateComparator,
				 
			//   } 
			  ];
	
		}
		
		columnwithDefs.push({
			headerName : "Share Link",
			field : "share_link",
			width:100,
			editable : false,
			// pinned:"right",
			cellRenderer: function(params){
				return "<button class='btn btn-info' style='padding:0px;width:76px'>Share Link</button>"
			}
		})

		if (localStorage.getItem("transportercode").includes('T332')) {
			columnwithDefs.push(
				{
					headerName: "Near Location",
					field: "near_location",
					width: 240,
					// colId:"near_location",
					filter: true,
					resizable: true,
					sortable: true
				},
				{
					headerName: "Near Distance",
					field: "near_location_distance",
					width: 240,
					// colId:"near_location_distance",
					filter: true,
					resizable: true,
					sortable: true
				})
		}

		// if(t329_check == 1){
		// 	columnwithDefs = [...columnwithDefs,{
		// 		headerName: 'Driver Name',
		// 		field: "driver_name",
		// 		width: 100,
		// 		filter: true
		// 	},{
		// 		headerName: 'Driver Mobile No',
		// 		field: "driver_mobile_no",
		// 		width: 100,
		// 		filter: true
		// 	},
		// 	{
		// 		headerName: 'OEM Name',
		// 		field: "org_name",
		// 		width: 100,
		// 		filter: true
		// 	}
		// 	]
		// }  
		  pdfRowData = pdfRowData.map(t => {
			var travelStatus = "";
			if(t.travel_sttaus != "" && t.travel_sttaus != undefined)
			{
				travelStatus = "Inactive";
			}
			else
			{
						if(t.last_known_speed > 0)
						{
							travelStatus = "Travelling";
						}
						else
						{
							var mre = secondsToHM(t.elaped_time_from_last_moved_seconds);
							travelStatus = "Idle ("+mre+")";
						}
			}
			var mre = secondsToHM(t.elaped_time_from_last_moved_seconds)
			  return ({truck_no : t.truck_no,
				device_address: t.device_address,
				device_city : t.device_city,
				district: t.district,
				device_state: t.device_state,
				last_known_speed: t.last_known_speed,
				elaped_time_from_last_moved_seconds: travelStatus,
				trip_status: t.trip_status,
				current_geofence_name:t.current_geofence_name,
				today_distance: t.today_distance,
				yesterday_distance_km: t.yesterday_distance_km,
				aprox_dist_travelled_from_start: (typeof(t.aprox_dist_travelled_from_start) == "undefined" ? 0 : Math.round(t.aprox_dist_travelled_from_start)),
				timestamp: getHyphenDDMMMYYYYHHMM(t.timestamp),
				current_geofence_name : t.current_geofence_name
			})
		  });
		
		// console.log("pdfRowData",pdfRowData)
				var pdfColumns = [
					{ header: tkName, binding: 'truck_no'},
					{header : 'Geofence' , binding : 'current_geofence_name'},            
					{ header: 'Address', binding: 'device_address'},
					{ header: 'City', binding: 'device_city'},
					{ header: 'District', binding: 'district'},
					{ header: 'State', binding: 'device_state'},
					{ header: 'Speed (KM/H)', binding: 'last_known_speed'},
					{ header: 'Travel Status', binding: 'elaped_time_from_last_moved_seconds'},
					{ header: 'Trip Status', binding: 'trip_status'},
					{ header: 'Current Geofence', binding: 'current_geofence_name'},
					{ header: 'Distance Today (KM)', binding: 'today_distance'},
					{ header: 'Distance Yesterday (KM)', binding: 'yesterday_distance_km'},
					{ header: 'Total Trip Distance (Km)', binding: 'aprox_dist_travelled_from_start'},
					{ header: 'Last Packet Time', binding: 'timestamp'},
				]		
		
		var columnwithoutDefs = [
			{
			  headerName: tkName,
			  field: "truck_no",
			  width: 150,
			  
		  },
		// 	{
		// 	  headerName: "LSP Code",
		// 	  field: "transporter_code",
		// 	  width: 200,
				  
		//   },  				  
		// 	{
		// 	headerName: "LSP Name",
		// 	field: "transporter_name",
		// 	width: 200,
				
		//   },
		// 	{
		// 	headerName: "Department Code",
		// 	field: "dept_code",
		// 	width: 200,
				
		//   },
		//   {
		// 	headerName: "Actual LSP Provider",
		// 	field: "actual_lspuser",
		// 	width: 200,
		// 	valueGetter: function(params){
		// 		return "Enmovil";
		// 	}	
		//   },
		{
			headerName: "Address",
			field: "device_address",
			width: 200,
		},
		{
			headerName: "City",
			field: "device_city",
			width: 170,
		},
		{
			headerName: "State",
			field: "device_state",
			width: 170,
		},

		//   {
		// 	  headerName: "Last Known City/Town",
		// 	  field: "area",
		// 	  width: 170,
		//   },
		//   {
		// 	headerName: "Last Known State",
		// 	field: "state",
		// 	width: 170,
		//   },
		//   {
		// 	headerName: "Distance Travelled Today (KM)",
		// 	field: "today_distance",
		// 	width: 200,
		//   },	
		  {
			  headerName: "Last Packet Time",
			  field: "last_active_on",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
			  return getHyphenDDMMMYYYYHHMM(params.data.last_active_on)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			//   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  console.log(cellValue,filterValue);
			// 			  console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  }
		  
	  ];
		var columnGpsNotTodayDefs = [
			{
			  headerName: tkName,
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 140,
				  
		  },  				  
			{
			  headerName: "LSP Name",
			  field: "transporter_name",
			  width: 200,
			//   valueGetter:function(params) {
			// 	  let transporter_name = null;
			// 	  if (params.data.transporter) {
			// 		  if (params.data.transporter.transporter_name) {
			// 			  transporter_name = params.data.transporter.transporter_name;
			// 		  }
			// 	  }
			// 	  return transporter_name;
			//   }
		  },
		  				  
		  {
			headerName: "Plant Code",
			field: "consigner_code",
			width: 200,
				
		  },
		  	
		  {
			headerName: "Consignee City",
			field: "consignee_city",
			width: 140	
		  },
		  {
			headerName: "Consignee State",
			field: "consignee_state",
			width: 140
		  },
		  
		  {
			headerName: "Cluster",
			field: "cluster",
			width: 140
		  },
		{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
		  {
			  headerName: "Actual LSP Provider",
			  field: "actual_lspuser",
			  width: 200,
			  valueGetter: function(params){
				return "Enmovil";
				}	
			//   valueGetter:function(params){
			// 	  if(params.data.dept_code == 'LOG-SC')
			// 	  {
			// 		return params.data.actual_lspuser;
			// 	  }
			// 	  else
			// 	  {
			// 		let actual_lspuser = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.actual_lspuser) {
			// 				actual_lspuser = params.data.truck.actual_lspuser;
			// 			}
			// 		}
			// 		return actual_lspuser;
			// 	  }
				 
			//   }
		  },
		  {
			headerName: "Last Known City/Town",
			field: "area",
			width: 200,
			// valueGetter:function(params){
			// 	if(params.data.dept_code == 'LOG-SC')
			// 	{
			// 		return params.data.area;
			// 	}
			// 	else
			// 	{
			// 		let area = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.area) {
			// 				area = params.data.truck.area;
			// 			}
			// 		}
			// 		return area;
			// 	}	
			// }
		  },
		  {
			  headerName: "Last Known State",
			  field: "state",
			  width: 200,
			//   valueGetter:function(params){
			// 	if(params.data.dept_code == 'LOG-SC')
			// 	{
			// 		return params.data.state;
			// 	}
			// 	else
			// 	{
			// 		let state = null;
			// 		if (params.data.truck) {
			// 			if (params.data.truck.state) {
			// 				state = params.data.truck.state;
			// 			}
			// 		}
			// 		return state;
			// 	}
			//   }
		  },
		  // {
		  //   headerName: "Last Packet Time",
		  //   field: "timestamp",
		  //   width: 200,
		  //   //cellRenderer:'dateFormater'
		  //   valueGetter:function(params){
		  // 	return getDDMMYYYYHHMMSS(params.data.timestamp)
		  //   }
		  // },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				 // console.log(params.data)
				 try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
					else
					{
					    //return getHyphenDDMMMYYYYHHMM(params.data.fromtrucks[0].timestamp)
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				 }
				 catch(e){
					return '';
				 }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
					else
					{
					  return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].modified_date)
					
					  //return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
				  }
				  catch(e){
					return "";
				  }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			  //   filterParams: {
			// 	  browserDatePicker: true,
			// 	  filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	  // provide comparator function
			// 	  comparator: function(filteredDate,cellValue,secondDate) {
			// 		  if(cellValue != "" && cellValue != " "){
			// 			  cellValue = cellValue.split(" ")[0].split("-");
			// 			  cellValue = cellValue[2]+"-"+cellValue[1]+"-"+cellValue[0];
			// 			  var cellDate = new Date(cellValue);
			// 			  if(filteredDate.getMonth() < 10){
			// 			  var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  else{
			// 				  var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 			  }
			// 			  if(filterValue.split("-")[2].length < 2)
			// 			  {
			// 				  var temp = filterValue;
			// 				  filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 			  }
			// 			  var filteredDate = new Date(filterValue);
			// 			  cellDate = cellDate.getTime();
			// 			  filteredDate = filteredDate.getTime();
			// 			  // console.log(cellDate,filteredDate);
			// 			  if(cellDate === filteredDate)
			// 			  {
			// 				  return 0;
			// 			  }
			// 			  if(cellDate < filteredDate){
			// 				  return -1;
			// 			  }
			// 			  if(cellDate > filteredDate)
			// 			  {
			// 				  return 1;
			// 			  }
			// 		  }
			// 	  }
			//   },
		  },
		  // {
		  // 	headerName: "Data Received On",
		  // 	field: "modified_date",
		  // 	width: 200,
		  // 	//cellRenderer:'dateFormater'
		  // 	valueGetter:function(params){
		  // 		return getDDMMYYYYHHMMSS(params.data.modified_date)
		  // 	  }
		  //   }
		  
		  ];
		  var columngpspacketDefs =  [
			  {
				headerName: "GPS Provider",
				field: "_id",
				width: 200,
				
				
			  },
			  {
				headerName: "< 3 Minutes",
				field: "less_180",
				width: 200,
					
			},  	        
			{
			  headerName: "Between 3 to 5 Minutes",
			  field: "180_300",
			  width: 200,
				  
			},  	        
			{
			  headerName: "Between 5 to 10 Minutes",
			  field: "300_600",
			  width: 200,
				  
			},  	        
			{
			  headerName: "Between 10 to 15 Minutes",
			  field: "600_900",
			  width: 200,
				  
			},  	        
			{
			  headerName: "More than 15 Minutes",
			  field: "greater_900",
			  width: 120,
				  
			}
			   
		  ];

		var dealerGpsNotTodayDefs = [
			{
			  headerName: tkName,
			  field: "truck_no",
			  width: 200,
			  
		  },
			{
			  headerName: "LSP Code",
			  field: "transporter_code",
			  width: 200,
				  
		  },  				  
			{
			  headerName: "LSP Name",
			  field: "transporter_name",
			  width: 200,
			
		  },
		  				  
		  {
			headerName: "Plant Code",
			field: "consigner_code",
			width: 200				
		  },
		  	
		  {
			headerName: "Consignee City",
			field: "consignee_city",
			width: 140	
		  },
		  {
			headerName: "Consignee State",
			field: "consignee_state",
			width: 140
		  },
		  
		  {
			headerName: "Cluster",
			field: "cluster",
			width: 140
		  },
		{
			headerName: "Department Code",
			field: "dept_code",
			width: 200,
				
		  },
		  {
			  headerName: "Actual LSP Provider",
			  field: "actual_lspuser",
			  width: 200,
			  valueGetter: function(params){
				return "Enmovil";
			}	
		  },
		  {
			headerName: "Last Known City/Town",
			field: "area",
			width: 200,
			
		  },
		  {
			  headerName: "Last Known State",
			  field: "state",
			  width: 200,
			
		  },
		  {
			  headerName: "Last Packet Time",
			  field: "timestamp",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				 // console.log(params.data)
				 try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
					else
					{
					    //return getHyphenDDMMMYYYYHHMM(params.data.fromtrucks[0].timestamp)
						return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
					}
				 }
				 catch(e){
					return '';
				 }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			
				
		  },
		  {
			  headerName: "Data Received On",
			  field: "modified_date",
			  width: 200,
			  //cellRenderer:'dateFormater'
			  valueGetter:function(params){
				  try{
					if(params.data.dept_code == 'LOG-SC')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
					else
					{
					  return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].modified_date)
					
					  //return getHyphenDDMMMYYYYHHMM(params.data.modified_date)
					}
				  }
				  catch(e){
					return "";
				  }
				
			  },
			  //filter: "agDateColumnFilter",
			  comparator: dateComparator,
			
		  },
		  
		  ];

    	let order_cnt=[];

    	rendJsonData(this.state.loadsData);
		
        //console.log("Default",localStorage.getItem('token'));
        const dStyles={
				width:'100%',
				height:'55%'
		}
		const highchartoptions = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.transaxis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"With GPS",
	      		      data: this.state.transyaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const transporterNoGPSChart = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.trans_no_gps_axis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"Without GPS",
	      		      data: this.state.trans_no_gps_yaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };

        const lsphighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lspaxis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lspyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const lspNoGPShighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lsp_no_gps_axis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lsp_no_gps_yaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  				  
        const highchartoptions1 = {
	      		  chart: {
	      		    type: 'pie'
	      		  },
	      		  plotOptions: {
	      			pie: {
	    		        allowPointSelect: true,
	    		        cursor: 'pointer',
	    		        showInLegend: true,
	    		        dataLabels: {
	    		          enabled: true,
	    		          format: '{point.x:,.0f}'			    		          
	    		        }
	    		      }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.deptaxis
	      	     },
	      	     legend: {
		    		  enabled: true,
		    		  labelFormatter: function() {

							//console.log("test",this.series)

			    		    var legendIndex = this.index;
			    		    var legendName = this.series.chart.axes[0].categories[legendIndex];

			    		    return legendName;
			    		  }
		    		},
	      		  series: [
	      		    {
	      		      data: this.state.deptyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: true
	                        }
	                    }
	                }]
	      		  }
	      		};
        			  
			const highchartgpsinterval = {
				chart: {
					type: 'column'
				},
				title: {
					text: ' '
				},
				xAxis: {
					categories: this.state.gpsintervalaxis
				},
				yAxis: {
					min: 0,
					
					stackLabels: {
						enabled: true,
						style: {
							fontWeight: 'bold',
							color: ( // theme
								Highcharts.defaultOptions.title.style &&
								Highcharts.defaultOptions.title.style.color
							) || 'gray'
						}
					}
				},
				legend: {
					align: 'right',
					x: -30,
					verticalAlign: 'top',
					y: 25,
					floating: true,
					backgroundColor:
						Highcharts.defaultOptions.legend.backgroundColor || 'white',
					borderColor: '#CCC',
					borderWidth: 1,
					shadow: false
				},
				tooltip: {
					headerFormat: '<b>{point.x}</b><br/>',
					pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: true
						}
					}
				},
				series: this.state.gpsintervalyaxis
			};
            var userroles = localStorage.getItem("roles");
			var israil = (userroles.indexOf("tpt_rail") > 0)?1:0

		const {usermanualmodal} = this.state;
		const modalStyles  = {
		width:'1300px !important',
		}

		const columnwithTPTDefs=[
			// {
			// 	headerName:"",
			// 	field:"",
			// 	width:50,
			// 	cellRenderer:'trucklocaction'
			// },
			{
				headerName: tkName,
				field: "truck_no",
				width: 100,
				filter: true,
				resizable: true
				
			},
			{
				headerName: "Latitude",
				field: "lat",
				width: 120,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					return params.data.lat.toFixed(6);
				}
				
			},
			{
				headerName: "Longitude",
				field: "lng",
				width: 120,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					return params.data.lng.toFixed(6);
				}
				
			},
			{
			  headerName: "Address",
			  field: "address",
			  width: 200,
			  filter: true,
			  resizable: true,
			//   valueGetter:function(params){
			// 	  try{
			// 		if(params.data.current_geofence_name != undefined)
			// 		{
			// 			return params.data.current_geofence_name;
			// 		}
			// 		else{
			// 			return params.data.address;
			// 		}
			// 	  }
			// 	  catch(e){

			// 	  }
			//   }
		  },
		  {
			  headerName: "Area / City",
			  field: "area",
			  width: 130,
			  filter: true,
			  resizable: true,
		  },
		  {
			headerName: "State",
			field: "state",
			width: 130,
			filter: true,
			resizable: true,
		},
			{
				headerName: "Speed (Kms)",
				field: "speed",
				width: 80,
				filter: true,
				resizable: true
				
			},
			{
				headerName: "Packet Time",
				field: "stime",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					//console.log(params);
					if(params.data.stime != ""){
					  return getHyphenDDMMMYYYYHHMM(params.data.stime);
					}
					else{
						return "";
					}
					
			   },
				  comparator: dateComparator,
			},
			{
				headerName: "Received On",
				field: "received_on",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter:function(params){
					// console.log(params.data.received_on);
					if(params.data.received_on != "NaT")
					{
						return getHyphenDDMMMYYYYHHMM(params.data.received_on);
					}
					else{
						return "";
					}
					
				},
				comparator: dateComparator,
			},
			{
				headerName: "Time from previous point",
				field: "time_from_prev_point",
				width: 240,
				filter: true,
				resizable: true,
				valueGetter : function(params)
				{
					try{
						return ConvertSeconds(params.data.time_from_prev_point);
					}
					catch(e){
						return 0
					}
				  
				}
				
			},
			{
				headerName: "Distance from previous point (KM)",
				field: "dist_from_prev_point",
				width: 240,
				filter: true,
				resizable: true,
				valueGetter : function(params)
				{
				  return  convertMeters(params.data.dist_from_prev_point);
				}
				
			},
			{
				headerName: "Cummulative Distance (KM)",
				field: "cumm_distance",
				width: 140,
				filter: true,
				resizable: true,
				valueGetter : function(params)
				{
				  return  convertMeters(params.data.cumm_distance);
				}
				
			},
			
			
			
	  ]

        return (
            <div >
                {/*Container-fluid starts*/} 
                <div className="">
					
                	{/* <div style={{marginBottom:'10px'}} className="row">
						<div className="col-xl-6 col-lg-12">
                            <ul className="d-tabs">
                                <li>
                                    <a href="/tptlogin/carrierlocationonmap" className={"btn btn-default "+(this.state.defaulttab == "all"?"activetpt":"")}>Map View</a>
                                </li>
                            </ul>
                	    </div>
                	</div> */}
					{(Object.keys(this.props.information_tab_data).length > 0)?
						<GpsIntegrationCounters 
							tottrucks={this.props.information_tab_data.truckscnt} 
							truckgps={this.props.information_tab_data.gpsrowData.length} 
							notruckgps={this.state.notrans} 
							totalActiveConsignmentsCount={this.props.information_tab_data.totalActiveConsignmentsCount} 
							gpsNotTodayActiveConsignmentsCount={this.state.gpsNotTodayActiveConsignmentsCount} 
							truckno={this.state.trucknogps}
							gpsnotreport = {this.props.information_tab_data.gpsnodata.length}
							ontripwithgps={this.state.ontripwithgps}
							israil={israil}
							context={this}
							ontripgpsna={this.state.ontripgpsna}
							atdealertruckcnt={this.state.atdealertruckcnt}
							atdealernogps={this.state.atdealernogps.length}
							primaryontrips={this.state.primaryontrips}
							secondaryontrips={this.state.secondaryontrips}
							deptcode={this.state.deptvalue}
							ontrips={this.props.information_tab_data.ontrips.length}
							/>
					:""}
							
					<SweetAlert
						show={this.state.show}
						type={this.state.basicType}
						title={this.state.basicTitle}
						onConfirm={this.closeAlert}
						>
					</SweetAlert>
                    <div className="row">
						<div className="col-xl-12 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header " style={{padding:'10px'}}>
									{(this.state.adaniCheck == 1) ?
	                                <h5>Shuttle List</h5>
									:
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
										<h5 style={{float: "left", width: "22%"}}>
											{localStorage.getItem("transportercode").includes("_RAKE") ? "Rake Devices List":"Trucks List" }</h5>					
										 <span className="layoutbtns pull-right">
											<button className="float-right custom-btn white btn-danger"
											style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.onClickSaveGridState.bind(this)}>
												<i className="icofont icofont-save"></i> Save Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{marginRight:"10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.resetState.bind(this)}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>
                                    	</span>
									
										{(this.props.information_tab_data.allrowData.length > 0) ?
										<ExportPDF  rowData={pdfRowData} pagetitle = "GPS Integration" pdfColumns={  pdfColumns } addressColumn={this.state.addressColumn}/>  
										: "" }

											<button className="float-right btn white btn-success" style={{marginRight:"10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.onClickServiceRequest} >
												Service Request
											</button>

										<div className={"border-radius-0"} >
											<Select
												value= {this.state.selectedtrucknumber}
												placeholder={((localStorage.getItem("transportercode").includes("_RAKE"))? "Search Rake Device" : "Search Truck")}
												closeMenuOnSelect={true}
												isMulti={true}
												className="information-tab-search-truck"
												// onChange={this.getRailConsginments.bind(this)}
												// style={{float: "left", width: "20%", marginRight: "1em"}}
												// styles={{float: "left", width: "20%", marginRight: "1em"}}
												options={this.state.truck_number_options}
												onChange={this.searchTruckChange.bind(this)}
											/>
											{/* <button type="submit" className={"btn btn-success "} onClick={this.onClickFilterReport}>Submit</button> */}
										</div>
										{/* <form className={" theme-form col-xl-12 col-lg-12 "} style ={{marginLeft:"280px",marginTop: "-1em"}} onSubmit={this.onClickFilterReport}>
											<div className="row">
												<div className="col-xl-4 col-lg-4 row form-group">
													<label>Search Trucks:</label>
													<input
														type="text"
														name="userinputTruck"
														id="userinputTruck"
														className="form-control"
													/>
												</div>
												<div className="form-group col-xl-2 col-lg-2">
													<button type="submit" style={{ marginTop: "30px" }} className={"btn btn-success "}>Submit</button>
												</div>
											</div>
										</form> */}
									</div>
									
	                                }                     
									   
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
									 {console.log(this.props.information_tab_data.allrowData,"allrowdata")}
										<div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
											<AgGridReact
											context={this}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
											modules={this.state.modules}
											columnDefs={columnwithDefs}
											defaultColDef={this.state.defaultColDef}
											rowData={this.state.allrowData}
											enableCharts={false}
											enableRangeSelection={true}
											autoGroupColumnDef={this.state.autoGroupColumnDef}
											frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
											onGridState={this.onGridState}
											onCellClicked={this.popmarker}
											masterDetail={true}
											detailCellRendererParams={this.state.detailCellRendererParams}
											statusBar={this.state.statusBar}
											sideBar={this.state.sideBar}
											//   pagination={false}
											gridOptions={{
											context: { componentParent: this }
											}}
											/>
										</div>
	                                
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

	                    
	                    

                        <div className="col-xl-12 col-lg-12 show-n">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnwithoutDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowwithoutData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady1}
	    		          		            onGridState={this.onGridState1}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											//   pagination={false}
											  gridOptions={{
												context: { componentParent: this }
											}}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>



                        {/* <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours (On Trip Trucks)</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={columnGpsNotTodayDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.gpsNotTodayActiveConsignments}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div>

                        <div className="col-xl-6 col-lg-12">
	                        <div className="card height-equal equal-height-lg">
	                            <div className="card-header" style={{padding:'10px'}}>
	                                <h5>GPS Data Not Reported Last 24 Hours (At Dealer)</h5>
	                            </div>
	                            <div className="card-body" style={{padding:'10px'}}>
	                                <div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
	    		          		            modules={this.state.modules}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            columnDefs={dealerGpsNotTodayDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowdataatdealer}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={true}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            frameworkComponents={this.state.frameworkComponents}
											onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
	    		          		        </div>
	                            </div>
	                            
	                        </div>
	                        
	                    </div> */}


						{/* <div className="col-xl-12 col-lg-12 hidden">
                            <div className="card">
                                <div className="card-header">
                                    <h5>GPS Data Time Interval (Between Packets)</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
										<div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
											<AgGridReact
												context={this}
												onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												modules={this.state.modules}
												columnDefs={this.state.columngpspacketDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowgpspacketData}
												enableCharts={false}
												enableRangeSelection={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												//sideBar={this.state.sideBar}
												pagination={true}
											/>
										</div>
                                    </div>
                                </div>
                            </div>
                        </div>				 */}
                    {/* <div className="col-xl-7 col-lg-7 ">
                        <div className="card">
                            <div className="card-header">
                                <h5>LSP wise trucks with GPS Data</h5>
                            </div>
                            <div className="card-body">
                                <div className="dashboard-chart-container sales-overview-chart">
                                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={lsphighchartoptions} />

                                </div>
                            </div>
                        </div>
                    </div> */}
				
					{/* <div className={"col-xl-5 col-lg-5 "+(this.state.sndshow)} >
                        <div className="card">
                            <div className="card-header">
                                <h5>LSP wise trucks with NO GPS Data</h5>
                            </div>
                            <div className="card-body">
                                <div className="dashboard-chart-container sales-overview-chart">
                                <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={lspNoGPShighchartoptions} />

                                </div>
                            </div>
                        </div>
                    </div> */}
						
                        {/* <div className={"col-xl-5 col-lg-5 "+(this.state.departmentchrtshow)}>
                            <div className="card default-widget-count">
	                            <div className="card-header">
		                            <h5>Department wise trucks with GPS Data</h5>
		                        </div>
                                <div className="card-body" style={{paddingBottom:'15px'}}>
                                    <div className="media">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions1} />

                                    </div>
                                </div>
                            </div>
                           
						</div> */}
						 
						{/*
                        <div className={"col-xl-12 col-lg-12 "+(this.state.departmentchrtshow)}>
                            <div className="card default-widget-count">
	                            <div className="card-header">
		                            <h5>GPS Packet Interval</h5>
		                        </div>
                                <div className="card-body" style={{paddingBottom:'15px'}}>
									<div id="myGrid" style={{ height: "600px",width: "100%"}}  className="ag-theme-balham">
	    		          		          <AgGridReact
											context={this}
											onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
	    		          		            modules={this.state.modules}
	    		          		            columnDefs={this.state.columngpspacketDefs}
	    		          		            defaultColDef={this.state.defaultColDef}
	    		          		            rowData={this.state.rowgpspacketData}
	    		          		            enableCharts={false}
	    		          		            enableRangeSelection={false}
	    		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
	    		          		            onGridReady={this.onGridReady}
	    		          		            onGridState={this.onGridState}
											  statusBar={this.state.statusBar}
											  //sideBar={this.state.sideBar}
											  pagination={true}
	    		          		          />
									</div>
									
									<div className="media">
                                    	<HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartgpsinterval} />

                                    </div>
                                </div>
                            </div>
                           
                        </div> 

						*/}
                        
{/*                     
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Transporter wise trucks with GPS Data</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Transporter wise trucks Without GPS Data</h5>
                                </div>
                                <div className="card-body">
                                    <div className="dashboard-chart-container sales-overview-chart">
                                    <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={transporterNoGPSChart} />

                                    </div>
                                </div>
                            </div>
                        </div>	
						                     */}
	                    
	                    
						<div className={"dataLoadpage " +(this.state.loadshow)}>
						</div>
						<div className={"dataLoadpageimg " +(this.state.loadshow)}>
							<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
						</div>
						<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

						<div className={"slide-r " + (this.state.sliderForShareLink)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Share Link
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.SubmitShareLink}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Link Expiry Date:</label>
									<Select
										closeMenuOnSelect={true}
										onChange={(e) => {
											this.setState({
												expired_time: e
											})
										}}
										className="col-xl-12 col-lg-12"
										style={{ borderRadius: "0px" }}
										value={this.state.expired_time}
										options={[
											{ label: "1 Day", value: 1 },
											{ label: "3 Days", value: 3 },
											{ label: "5 Days", value: 5 },
											{ label: "7 Days", value: 7 },
										]} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Whatsapp No</label>
									<input
										type="text"
										name="whatsup_no"
										id="whatsup_no"
										className="col-xl-12 col-lg-12 form-control"
										style={{marginLeft:"13px" }}
										value={this.state.whatsup_no}
										onChange={this.changeHandlerforWhatsUp}
										required
									/>
								</div>
								

					
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success" style={{width:'125px' ,marginLeft:"10px"}}>Share</button>
									<button type="button" className="btn btn-info" style={{marginLeft:"25px" ,width:"125px"}} onClick={this.copyTextToClipBoard}>Copy</button>
								</div>
							</form>
						</div>
					</div>
				</div>

						{this.state.sliderRouteTranslate != ''?
						<div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
							<div className="slide-r-title">
								<h4>
									View Route
									<span className="float-right closebtn" style={{marginRight:"25px"}} 
									onClick={this.onClickHideAll.bind(this)} >X</span>
											
								</h4>
							</div>
							<div className="container-fluid">
								<div className="row col-xl-12 col-lg-12">						
									<form style={{marginTop:"2%"}} className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
										<div className="col-xl-3 col-lg-3 form-group">
											<Datetime 
											inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off' }} 
											dateFormat="YYYY-MM-DD" 
											timeFormat="HH:mm:ss"  
											name="startDate"
											value={this.state.startDate} 
											onChange={this.handlerStartDateTime.bind(this)} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group">
											<Datetime  inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off' }} 
											dateFormat="YYYY-MM-DD" 
											timeFormat="HH:mm:ss"
											name="endDate"
											value={this.state.endDate} 
											onChange={this.handlerEndDateTime.bind(this)} />
										</div>
										<div className="col-xl-3 col-lg-3 form-group">
											<input type="number" name="frequency" id="frequency" value={this.state.frequency} placeholder="Data Interval (Mins)" className="validateNumber form-control" onChange={this.checkHandler.bind(this)} />
										</div>
										<div className="col-xl-2 col-lg-2 form-group">
											<button type="submit" className="btn btn-success">Generate</button>
										</div>
									</form>
								</div>
								<span className="layoutbtns pull-right">
										{(this.state.mapfor !="show_location") ?
											<button className="btn btn-warning float-right" style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.toggle}>{(this.state.mapToggle) ? "Back To Map" : "Replay"}</button>

										:""}
                                         <button className="float-right custom-btn white btn-danger"
                                         style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.onClickSaveGridState1.bind(this)}>
                                             <i className="icofont icofont-save"></i> Save Grid Layout
                                         </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.resetState1.bind(this)}>
                                             <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
									
								{(this.state.mapData != null)?
									<div className="row col-xl-12 col-lg-12">
										{/* <button type="button" className={"btn "+(this.state.actgridbtn)} onClick={this.onClickShowGrid.bind(this)}>Grid</button>
										<button type="button" className={"btn "+(this.state.actmapbtn)} onClick={this.onClickShowMap.bind(this)}>Map</button> */}
										<div className="col-xl-6 col-lg-6">
												<div id="myGrid" style={{ height: "580px",width: "100%",marginTop:'10px'}}  className={"ag-theme-balham "+(this.state.gridshow)}>
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnwithTPTDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.mapData}
													enableCharts={true}
													enableRangeSelection={true}
													onGridReady={this.onGridReady2}
													onGridState={this.onGridState2}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													// paginationPageSize={this.state.paginationPageSize}
													// pagination={false}
													frameworkComponents={this.state.frameworkComponents}
													enableCellChangeFlash={true}
													suppressCellFlash={true}
													gridOptions={{
														context: { componentParent: this }
													}}
													/>


												</div>
											</div>
											{/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
											<div className={"col-xl-6 col-lg-6 "} style={{padding:"10px"}}>
												{/* <input type="checkbox" name="viewtimelinemarkers" style={{marginLeft: "2em",marginBottom: "1em",}} value={this.state.showmarkers} onClick={this.showmarkers.bind(this)} /> view markers */}
												{(this.state.mapToggle) ?
													<RouteAnimate
														coords={this.state.mapinfo}
													/>
													:	
													<DrawMap
																context={this}
																mapFor={localStorage.getItem("transportercode").includes("_RAKE") ? "tptrakegps" : "tpttruckgps"}
																tolls={this.state.tolls}
																mapinfo={this.state.mapinfo}
																defTransitCoords={""}
																adaniCheck={this.state.adaniCheck}
																location_coords={this.state.location_coords}
																dealers={this.state.dealers}
																currentStatus={this.state.currentStatus}
																baseRouteCoords={this.state.baseRouteCoords}
															/>

															// (this.state.shownnbmaps?
														
															// 	<NextBillionMaps
															// 				context={this}
															// 				mapFor={localStorage.getItem("transportercode").includes("_RAKE") ? "tptrakegps" : "tpttruckgps"}
															// 				tolls={this.state.tolls}
															// 				mapinfo={this.state.mapinfo}
															// 				defTransitCoords={""}
															// 				adaniCheck={this.state.adaniCheck}
															// 				location_coords={this.state.location_coords}
															// 				dealers={this.state.dealers}
															// 				currentStatus={this.state.currentStatus}
															// 				baseRouteCoords={this.state.baseRouteCoords}
															// 			/>:"")		
													
												}

												
													

												
												  
												<div className="col-xl-12 col-lg-12 n-p-0 mt-20p">
													<div className="crm-numbers pb-0">
														<h3 className="subH">View Details</h3>
														<div className="col-xl-12 col-lg-12 row">
															
															<div className="col route-block">
																<label className="sidebar-label">Start Time</label>
																<div>{(this.state.mapinfo.route_details.start_time != '')?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"NA"}</div>
															</div>
															<div className="col route-block">
																<label className="sidebar-label">End Time</label>
																<div>{(this.state.mapinfo.route_details.end_time != '')?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"NA"}</div>
															</div>
															
															<div className="col route-block">
																<label className="sidebar-label">Distance</label>
																<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
															</div>
															
														</div>
													</div>
												</div>
											</div> 
											{/* :""} */}
									</div>
											
							:""}

								
							</div>
						</div>
					:""}
                    </div>

					<div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 	{this.displayData}
						 
						{/* <div className="col-xl-6 col-lg-6">
							<div className="form-group">
								<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save Geofence</button>
							</div>
						</div> */}
				 	</div>


                </div>
                {/*Container-fluid Ends*/}

				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">GPS Integration</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								GPS integration report comprises of below mentioned reports
							</div>
							<div className="col-xl-12 col-lg-12">
								<ul>
									<li>
										a. GPS integration status:
										<ul>
											<li>
												{(this.state.adaniCheck == 1) ?
												<div className="col-xl-12 col-lg-12">
													This screen provides data of overall shuttles that are present in system for that particular department and its current integration status regarding GPS data
												</div> : 
												<div className="col-xl-12 col-lg-12">
													This screen provides data of overall trucks that are present in system for that particular department and its current integration status regarding GPS data
												</div>
												}
												<div className="col-xl-12 col-lg-12">
											 		Image here
												</div>
												<div className="col-xl-12 col-lg-12">
													This screen provides analytics related to GPS data vehicle wise. This screen shows data in four grids.  The details are present in all four analytics in below mentioned columns
											 		<table className="table table-border">
														<tr>
															<th>
																Column
															</th>
															<th>
																Remarks
															</th>
														</tr>
														<tr>
															{(this.state.adaniCheck == 1) ?
															<td>
																Shuttle No
															</td> :
															<td>
																Truck No
															</td>
															}
															{(this.state.adaniCheck == 1) ?
															<td>
																Shuttle number for which details are provided
															</td> :
															<td>
																Truck number for which details are provided
															</td>
															}
														</tr>
														<tr>
															<td>
																LSP Code
															</td>
															<td>
															Transporter code provided by MSIL
															</td>
														</tr>
														<tr>
															<td>
															LSP Name
															</td>
															<td>
															Transporter Name
															</td>
														</tr>
														<tr>
															<td>
															Department Code
															</td>
															<td>
															Department details
															</td>
														</tr>
														<tr>
															<td>
															Actual GPS Provider
															</td>
															<td>
															GPS service provider
															</td>
														</tr>
														<tr>
															<td>
															Last Known City/Town	
															</td>
															<td>
															Last known Location as per GPS data
															</td>
														</tr>
														<tr>
															<td>
															Last Known State
															</td>
															<td>
															Last known State as per GPS data
															</td>
														</tr>
														<tr>
															<td>
															Last Packet Time
															</td>
															<td>
															packet date and time sent by GPS provider
															</td>
														</tr>
														<tr>
															<td>
															Data Received On
															</td>
															<td>
															Data packet received from service provider
															</td>
														</tr>
														
													 </table>
												</div>
												<div className="col-xl-12 col-lg-12">
											 		<ul>
														<li>
														{(this.state.adaniCheck == 1) ?
															"Shuttle with GPS Data"  : "Trucks with GPS Data"
														}
														</li>
														<li>
															GPS data not reported last 24 hours
														</li>
														<li>
															GPS data not reported in last 24 hours
															{(this.state.adaniCheck == 1) ? "(On trip shuttles)"  : "On trip trucks"}
														</li>
														<li>
															GPS data not reported in last 24 hours (At dealer)
														</li>
													 </ul>
												</div>
												<div className="col-xl-12 col-lg-12">
													For SND department data is available in two sections primary trips and Secondary trips. The section is divided based on movements which are done from plants for primary and from TVP for Secondary
												</div>
											</li>
										</ul>
									</li>
									<li>
										{(this.state.adaniCheck == 1) ? "b.Shuttle GPS data:"  : "b. Truck GPS data:"}
										
										<ul>
											<li>
												<div className="col-xl-12 col-lg-12">
													This screen helps in tracking individual {(this.state.adaniCheck == 1) ? "shuttle"  : "truck"} (departmental wise) in a particular time frame. The user just needs to enter the {(this.state.adaniCheck == 1) ? "shuttle"  : "truck"} number and the time frame for which user wants to know the trail. 
												</div>
												<div className="col-xl-12 col-lg-12">
													Image Here
												</div>
												<div className="col-xl-12 col-lg-12">
												Once the data is filled as per required fields, Click on generate button, required details will be displayed
												</div>
											</li>
										</ul>

									</li>
									<li>
										c. Manual GPS integration:
										<ul>
											<li>
												<div className="col-xl-12 col-lg-12">
												This screen helps in adding particular location details for the vehicle which GPS data has not been provided by its respective service provider. If a user is well aware of the location it can be added in system manually by user by using this option. For adding the GPS location the user needs to add details like the vehicle number the location co-ordinates which needs to be added (can be searched from map provided) and the time at which the vehicle was present at that location. Click on save button then the details will be added for the vehicle at that particular time	
												</div>
												<div className="col-xl-12 col-lg-12">
													Image Here
												</div>
												
											</li>
										</ul>
									</li>
								</ul>
								
							</div>
						</div>
					</div>
				</Modal>  
            </div>
        );
    }
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       

	function rendJsonData(data)
	{
		//console.log(data);
		var order_cnt=[];
		order_cnt=data.map( (seris) => 
			seris.key
		)
		//console.log(order_cnt)
	}

	function dateComparator(date1, date2) {
		// console.log("dateComparator1");
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		if (date1Number === null && date2Number === null) {
		  return 0;
		}
		if (date1Number === null) {
		  return -1;
		}
		if (date2Number === null) {
		  return 1;
		}
		return date1Number - date2Number;
	  }
	function monthToComparableNumber(date) {
		if (date === undefined || date === null || date.length !== 19) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}
	function loadScript(url){
		var index  =window.document.getElementsByTagName("script")[0]
		var script = window.document.createElement("script")
		script.src=url
		script.async=true
		script.defer = true
		index.parentNode.insertBefore(script,index)
	}
	function initOMS(){
		// Initializing OMS
		oms = new oms.OverlappingMarkerSpiderfier(map, {
		  //markersWontMove: true,
		  //keepSpiderfied: true,
		  //nearbyDistance: 10,
		  //circleFootSeparation: 60,
		  //legWeight: 1.5
		  markersWontMove: true,
		markersWontHide: true,
		keepSpiderfied: true,
		basicFormatEvents: true
		});
	  }
	  function timeConvert(n) {
		  var num = n;
		  var hours = (num / (3600));
		  var rhours = parseInt(hours);
		  var minutes = (num-(rhours*3600)) / (60);
		  var rminutes = Math.round(minutes);
		  return rhours + " hour(s) and " + rminutes + " minute(s).";
	  }   
	
	function ConvertMinutes(num){
		var d = Math.floor(num/1440); // 60*24
		var h = Math.floor((num-(d*1440))/60);
		var m = Math.round(num%60);
		return d+" Day(s) "+h+" Hrs "+m+" mins"
	}
	
	function ConvertSeconds(totalSeconds)
	{
		// var days = Math.floor(num/86400);
		// var hours = Math.floor(num/3600)
		// var minutes = Math.floor(num/60);
		var minutes = Math.round((totalSeconds % 3600) / 60);
		var hours = Math.round((totalSeconds % 86400) / 3600);
		var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
		// let d = (new Date(t0)) - (new Date(t1));
		// let weekdays     = Math.floor(d/1000/60/60/24/7);
		// let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
		// let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
		// let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
		return days+" Day(s) "+hours+" Hrs "+minutes+" mins"
	}
	function ConvertHHMMSeconds(n)
	{
		var days =Math.round(parseInt( n / (24 * 3600)));
	
		n = n % (24 * 3600);
		var hours = Math.round(parseInt(n / 3600));
	
		n %= 3600;
		var minutes = Math.round(n / 60);
	
		n %= 60;
		var seconds = Math.round(n);
	
		return days+" Day(s) "+hours+" Hrs "+minutes+" mins "+ seconds+" secs"
	}
	function secondsToDhms(seconds) {
		seconds = Number(seconds);
		var d = Math.floor(seconds / (3600*24));
		var h = Math.floor(seconds % (3600*24) / 3600);
		var m = Math.floor(seconds % 3600 / 60);
		var s = Math.floor(seconds % 60);
		
		var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
		var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
		var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
		var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
		return dDisplay + hDisplay + mDisplay;
	}
	function secondsToHM(seconds) {
		seconds = Number(seconds);
		// var d = Math.floor(seconds / (3600*24));
		// var h = Math.floor(seconds % (3600*24) / 3600);
		// var m = Math.floor(seconds % 3600 / 60);
		// var s = Math.floor(seconds % 60);
		
		// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
		// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
		// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
		// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
		// return dDisplay + hDisplay + mDisplay;
		
		var hh = Math.floor((seconds / 3600));
		var hrr = Math.floor(seconds - (hh*3600));
		var mm = Math.floor(hrr/60)
		if(mm < 10)
		{
			var mins = "0"+mm;
		}
		else
		{
			var mins = mm;
		}

		if(hh < 10)
		{
			var hrs = "0"+hh;
		}
		else
		{
			var hrs = hh;
		}
		return hrs+":"+mins;
	}
	function convertMeters(metersValue)
	{
		return (metersValue/1000).toFixed(2);
	}

	function groupBy(list, keyGetter) {
		const map11 = new Map();
		list.forEach((item) => {
			 const key = keyGetter(item);
			 const collection = map11.get(key);
			 if (!collection) {
				 map11.set(key, [item]);
			 } else {
				 collection.push(item);
			 }
		});
		return map;
	}

    function GetSortDescOrder(prop) {    
        return function(a, b) {    
            if (a[prop] < b[prop]) {    
                return 1;    
            } else if (a[prop] > b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    } 
    
	function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }
	  function selectColor(color) {
		selectedColor = color;
		for (var i = 0; i < colors.length; ++i) {
		  var currColor = colors[i];
		  //console.log(colorButtons[currColor]);
		  //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
		}

		// Retrieves the current options from the drawing manager and replaces the
		// stroke or fill color as appropriate.
		var polylineOptions = drawingManager.get('polylineOptions');
		polylineOptions.strokeColor = color;
		drawingManager.set('polylineOptions', polylineOptions);

		var rectangleOptions = drawingManager.get('rectangleOptions');
		rectangleOptions.fillColor = color;
		drawingManager.set('rectangleOptions', rectangleOptions);

		var circleOptions = drawingManager.get('circleOptions');
		circleOptions.fillColor = color;
		drawingManager.set('circleOptions', circleOptions);

		var polygonOptions = drawingManager.get('polygonOptions');
		polygonOptions.fillColor = color;
		drawingManager.set('polygonOptions', polygonOptions);
	  }
export default tptGpsIntegrationSingleScreen;