
import React, { Component, useDebugValue } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "../layouts/updatelmstates";
import UpdateTransitButton from "../layouts/updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ShowTPTGpsMap from "./showtptgpsmap";
import ExportPDF from "./exportpdf"
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

export default class WireDisconnection extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            pageTitle: "Wire Disconnection",
            screenpage: "Wire Disconnection",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loadshow: 'show-n',
            modules: AllModules,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true
            },
            rowData: [],
            context: { componentParent: this },
            overly: "show-n",
            screenView: "",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            truckNoList: [],
            showTrucksList: "show-n",
            truckNos: [],
            tpttrucks: [],
            pdfRowData: [],
            pdfColumns: [],
            addressColumn: 300,
            startDate: moment.parseZone(new Date()).subtract(1, 'days').startOf("day").format("YYYY-MM-DD HH:mm"),
            endDate: moment.parseZone(new Date()).endOf("day").format("YYYY-MM-DD HH:mm"),
        };
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    componentDidMount = async () => {
        const edate = this.state.endDate
        const sdate = this.state.startDate
        var tptCode = localStorage.getItem("transportercode")
        const vehicle_group = localStorage.getItem("vehicle_group") || "";
        if (vehicle_group) {
            const tparms = {
                transporter_code: tptCode,
                region: vehicle_group
            };
            try {
                const response = await redirectURL.post("/dashboard/tpregiontrucksdata", tparms);
                const { records } = response.data;
                this.setState({ tpttrucks: records });
            } catch (error) {
                console.error("Error fetching region trucks data", error);
            }
        }
        // Load distinct trucks
        const tptParams = { tpt_code: tptCode };
        try {
            const response = await redirectURL.post("/dashboard/getDistinctTrucks", tptParams);
            const { records } = response.data;
            const { tpttrucks } = this.state;
            // console.log(records, "records ")
            // console.log(tpttrucks, "tpt trucks data ")
            let truckNoList = tpttrucks.length > 0
                ? records.filter(record => tpttrucks.some(t => t.trucks_no === record))
                : records;

            const trucks = [{ value: "Alllist", label: "ALL" }, ...truckNoList.map(item => ({ label: item, value: item }))];

            this.setState({ truckNoList: trucks });
        } catch (error) {
            console.error("Error fetching distinct trucks", error);
        }
        // Load data and user grid state
        this.onLoadData();
        this.getUserGridState();
    };

    getUserGridState() {
        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname, screentitle: 'Wire Disconnection' })
            .then(async (resp) => {
                this.setState({
                    usergridstate: resp.data
                });
                this.restoreGridStates();
            })
    }

    onClickHideAll() {
        this.setState({
            overly: "show-n",
            showTrucksList: "show-n",
        })
    }



    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onClickGetDealersData = () => {
        this.onLoadData()
    }

    onLoadData = () => {
        var truckNos = this.state.truckNos;
        var truck_nos = []
        if (truckNos != null) {
            if (truckNos.length > 0) {
                truckNos.map(e => {
                    if (!(e.label == "ALL")) {
                        truck_nos.push(e.value)
                    }
                })
            }
        }
        const fromDate = this.state.startDate
        const toDate = this.state.endDate
        if (fromDate != '' && toDate != '') {
            var transporter_code = JSON.parse(localStorage.getItem('transportercode'))
            if (transporter_code != undefined && transporter_code != "") {
                var reqparams = {
                    transporter_code: transporter_code,
                    truck_no: truck_nos,
                    fromDate: fromDate,
                    toDate: toDate,
                }
            }
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m',
            })
            redirectURL.post("/consignments/getWireDisconnectionData", reqparams)
                .then((response) => {
                    var records = response.data.records || [];
                    // console.log(records)
                    var pdfRowData = []
                    records.map(e => {
                        // Push data into pdfRowData
                        pdfRowData.push({
                            truck_no: e.truck_no,
                            transporter_code: e.transporter_code,
                            // device_id: e.device_id,
                            // version: e.version,
                            // packet_type: e.packet_type,
                            // altitude: e.altitude,
                            // speed: e.speed,
                            timestamp: getHyphenDDMMMYYYYHHMM(e.timestamp) || "",
                            latitude: e.latitude,
                            longitude: e.longitude,
                            // battery: e.battery,
                            // alert_id: e.alert_id,
                            // ignition: e.ignition,
                            // main_power_status: e.main_power_status,
                            // process: e.process,
                            alert_address: e.alert_address,
                        });
                    });

                    const pdfColumns = [
                        { header: 'Truck No', binding: 'truck_no' },
                        { header: 'Transporter Code', binding: 'transporter_code' },
                        // { header: 'Device Id', binding: 'device_id' },
                        {
                            header: 'Disconnected On',
                            binding: 'timestamp',
                            valueGetter: (params) => {
                                return params.data.timestamp ? getHyphenDDMMMYYYYHHMM(params.data.timestamp) : 'N/A';
                            }
                        },
                        { header: 'Latitude', binding: 'latitude' },
                        { header: 'Longitude', binding: 'longitude' },                        
                        { header: 'Alert Address', binding: 'alert_address' },
                    ];
                    this.setState({
                        loadshow: 'show-n',
                        overly: 'show-n',
                        rowData: records,
                        pdfColumns: pdfColumns,
                        pdfRowData: pdfRowData
                    })
                })
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Please select dates to continue',
                basicType: "danger",
                loadshow: 'show-n',
            })
        }
    }

    onChangeDepartment(truckNos) {
        this.setState({ truckNos });
    }

    onClickSaveGridState() {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Save Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();
        let screenpage = 'Wire Disconnection';

        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenpage
        }

        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }

    restoreGridStates() {
        try {
            if (this.state.usergridstate.length > 0) {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
            }
        }
        catch (e) { }
    }

    resetState = () => {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Reset Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        var reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: this.state.screenpage
        }

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })
    };

    handlerStartDateTime = (event) => {
        const eventDate = moment(event._d);
        const formattedDate = eventDate.format('YYYY-MM-DD HH:mm');
        if (!this.state.endDate) {
            this.setState({ startDate: formattedDate });
            return;
        }
        const endDate = moment(this.state.endDate);
        if (eventDate.isAfter(endDate)) {
            this.setState({
                startDate: "",
                show: true,
                basicType: "danger",
                basicTitle: "From Date should not be greater than To Date"
            });
        } else {
            this.setState({ startDate: formattedDate });
        }
    }

    handlerEndDateTime = (event) => {
        let eventDate = moment(event._d);
        let formattedDate = eventDate.format('YYYY-MM-DD HH:mm');
        const timeIncluded = moment(formattedDate).format("HH:mm") !== "00:00"; // Default time would be midnight if not selected
        if (!timeIncluded) {
            eventDate.set({ hour: 23, minute: 59 });
            formattedDate = eventDate.format('YYYY-MM-DD HH:mm');
        }
        if (!this.state.startDate) {
            this.setState({ endDate: formattedDate });
            return;
        }
        const startDate = moment(this.state.startDate);
        if (startDate.isAfter(eventDate)) {
            this.setState({
                endDate: "",
                show: true,
                basicType: "danger",
                basicTitle: "To Date should be greater than From Dat"
            });
        } else {
            this.setState({ endDate: formattedDate });
        }
    }

    render() {

        var columnWithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 100,
                filter: true,
                resizable: true,
                sortable: true,
                flex:1,
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
                flex:1,
            },
            // {
            //     headerName: "Device Id",
            //     field: "device_id",
            //     width: 160,
            //     filter: true,
            //     resizable: true,
            //     sortable: true,
            // },        
                {
                headerName: "Disconnected On",
                field: "timestamp",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
                flex:1,
                valueGetter: function (params) {
                    if (params.data.timestamp != undefined && params.data.timestamp != '') {
                        return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Lattitude",
                field: "latitude",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
                flex:1,
            },
            {
                headerName: "Longitutde",
                field: "longitude",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
                flex:1,
            },            
            {
                headerName: "Alert Address",
                field: "alert_address",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
                hide:true,
                flex:1,
            },
        ];
        var listData = []

        return (
            <div className={(this.state.screenView == "Mobile") ? "" : "container-fluid"} style={(this.state.screenView == "Mobile") ? { marginTop: "2em" } : {}}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="card">
                    <div className="card-header">
                        <h5>
                            <i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                            Wire Disconnection
                            <div className="col-xl-12 col-lg-12">

                                <span className="layoutbtns pull-right">
                                    <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
                                </span>
                            </div>
                        </h5>
                    </div>
                    <div className={(this.state.screenView == "Mobile") ? "card-body" : "card-body col-xl-12 col-lg-12"}>
                        <div className={"trucks-dropdown " + this.state.showTrucksList} style={{ top: "94px" }}>
                            <ul>
                                {listData}
                            </ul>
                        </div>
                        <div class="row">
                            <div class={" col-xl-2 col-lg-2 form-group"}>
                                <label>Trucks</label>
                                <Select
                                    placeholder={"Trucks"}
                                    closeMenuOnSelect={false}
                                    isMulti={true}
                                    onChange={this.onChangeDepartment.bind(this)}
                                    className={"border-radius-0"}
                                    autoComplete="off"
                                    style={{ borderRadius: "0px" }}
                                    options={this.state.truckNoList} />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>From Date </label>
                                <Datetime
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off', required: true, value: this.state.startDate }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    name="startDate"
                                    onChange={this.handlerStartDateTime.bind(this)}
                                />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>To Date </label>
                                <Datetime
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off', required: true, value: this.state.endDate }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    name="endDate"
                                    onChange={this.handlerEndDateTime.bind(this)}
                                />

                            </div>
                            <div class="col-xl-2 col-lg-2 mr-0 form-group">
                                <label>&nbsp;</label>
                                <br />
                                <button type="submit" onClick={this.onClickGetDealersData.bind(this)} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                            <div class="col-xl-2 col-lg-2 form-group">
                                <label>&nbsp;</label>
                                <br />
                                {(this.state.pdfRowData.length > 0) ?
                                    <ExportPDF rowData={this.state.pdfRowData} pageTitle="Wire Disconnection Reports" pdfColumns={this.state.pdfColumns}
                                        addressColumn={this.state.addressColumn} />
                                    : ""}
                            </div>
                        </div>
                        {this.state.rowData != null ?
                            <div id="myGrid" style={{ width: "100%", height: "350px" }} className={"ag-theme-balham"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnWithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={50}
                                    pagination={true}
                                    gridOptions={{
                                        context: { componentParent: this },
                                    }}
                                    enableRangeSelection={true}
                                />
                            </div> : ""
                        }
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}