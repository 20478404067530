
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
const UpdateItem = (props) => {
    //console.log("props ", props)
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickEditRowItem(props);
    };
   
    return (
        <div>
                <button onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-save"></i>Update</button>

                {/* <button
                    style={{border:"0px", backgroundColor:"transparent"}}
                    type="button"
                    className="btn btn-default"
                    onClick={handleClick} >
                        Edit
                </button> */}
            
          
        </div>
    );
};

export default UpdateItem;
