/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import Drawmap from "./dealermap";
import ShowMapicon from "./showmaplocation";

import axios from 'axios';

import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class NextLoadePlaneer extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			// rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem,
                ShowMapicon:ShowMapicon
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:{value:"",label:""},
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            oems:[],
            dealer_code:"",
            dealer_name:"",
            dealer_address:"",
            latitude:"",
            longitude:"",
            city:"",
            state:"",
            region:"",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            latLngArray:[]

		};
        this.onLoadData = this.onLoadData.bind(this);
        this.onClickShowTPTTruckLocation = this.onClickShowTPTTruckLocation.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		
        department='SNDG';
        departmentName = " ";
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
        if(this.props.pageLoad == "8")
        {
            if (googleAnalytics.page.enableGA) {
                this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({eventAction: eventAction});

            }
            // get department code to filter records by department
            // let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
            // this.setState({
            // 	departmentCode:currentDepartmentCode
            // });
            this.onLoadData();
        }
	  
    };

    componentWillReceiveProps(newprops) {
		console.log(newprops,"this.props----MapView-Componentwill")
		if(newprops.pageLoad == "8" && this.state.rowData.length == 0)
		{
			this.onLoadData();
		}
	}

    onLoadData(){
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var tptCode = localStorage.getItem('transportercode');
        var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			   var records = response.data.records;
				console.log("records 162", records)
                tptrucks = records;
				this.setState({
					tpttrucks:records
				})
			})
		}
        var tptcode = JSON.parse(localStorage.getItem("transportercode"));
        var parameters = {};
        if(tptcode.length > 0)
        {
            var transporter_code = [];
            transporter_code.push(tptcode[0]);
            parameters.transporter_code = transporter_code;
        }
        // var parameters = {
        //     // transporter_code: JSON.parse(localStorage.getItem("transportercode"))
        //     transporter_code:tptcode[0]
        // }
        // console.log(parameters,"parameters")
        redirectURL.post('/consignments/getNextLoadPlanner', parameters)    
        .then((response) => {
            if(response.data.status != "Failure"){
                var records = (response.data);
                // console.log("records",records)
                var tpttrucks = tptrucks;
                // console.log("tpttrucks ",tpttrucks)
                var trucksarr = []
                if(tpttrucks.length > 0)
                {
                    records.filter(f =>
                        tpttrucks.filter((d )=> {
                            // console.log(f,"frecords data",d,"drecords data")
                            if(d.truck_no == f.truck_no){									
                                trucksarr.push(f)
                            }
                        })
                    );
                }
                else
                {
                    trucksarr = records
                }
                console.log("records nextloadplan", records)
                this.setState({
                    rowData:trucksarr,
                    loadshow:"show-n",
                    overly:"show-n"
                });
            }
        })
        .catch(function (error) {
          console.log(error);
      });
     
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }


    
    
    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    /*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        await window.location.reload();
    }

    
	 

    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            bulkslide:"",
            sliderBulk:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    
     
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeOfficerType(officertype){
		this.setState(
			{ officertype },
			() => console.log(`Option selected:`, this.state.officertype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.oems.length > 0)
			{
				this.state.oems.map((item) =>{
					items.push({"value":item.oem_code,"label":item.oem_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }
    
    onClickShowBulk()
    {
        this.setState({
            overly:"show-m",
			sliderBulk:'slider-translate',
        })
    }
    

	
		
  

    onClickShowTPTTruckLocation(e){
		console.log("Data ", e.data)
		this.setState({
            overly:"show-m",
            bulkslide : "slider-translate-60p",
            latLngArray:[{
                lat : e.data.latitude,
                lng : e.data.longitude,
                name : e.data.dealer_name,
                address:e.data.dealer_address
            }]
        })
    }

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
            
	  	        {
	  	          headerName: "Truck No",
	  	          field: "truck_no",
	  	          width: 120,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
	  	          headerName: "Location",
	  	          field: "Location",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "GPS Status",
	  	          field: "GPS Active",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
                {
	  	          headerName: "Closest Plant",
	  	          field: "Closest Plant",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Closest Plant Code",
	  	          field: "closest_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Closest Distance",
	  	          field: "Closest Distance",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
                {
	  	          headerName: "Closest Plant Planned Trips",
	  	          field: "Closest Plant Planned Trips",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Closest Plant Actual Trips",
	  	          field: "Closest Plnat Actual Trips",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Recommended Plant",
	  	          field: "Recommended Plant",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Recommended Plant Code",
	  	          field: "sob_plant_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Recommended Distance",
	  	          field: "Recommended Distance",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                 
                {
	  	          headerName: "Recommended Plant Planned Trips",
	  	          field: "Recommended Plant Planned Trips",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Recommended Plant Actual Trips",
	  	          field: "Recommended Plant Actual Trips",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Truck Status",
	  	          field: "Status",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:(params)=>{
                    var value = params.data.Status
                    
                    if(value==5){
                        return 'Left Destination'
                    }else if(value==6){
                        return 'Force Close'
                    }else{
                        return "No Consignments Found"
                    }
                  }
                },
                {
	  	          headerName: "Consigner",
	  	          field: "Plant Location Name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Consignee",
	  	          field: "Dealer Name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },

	    ];
		return(
			<div className="">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Next Load Planner </span>
                                       {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add Edit</button> */}
                                       {/* <button type="button" onClick={this.onClickShowBulk.bind(this)} className="btn btn-danger float-right">Bulk Upload</button> */}
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                



                <div className={"slide-r "+(this.state.sliderUpdate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Update OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						

						
                        </div>
					</div>
				</div>


          

                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subH">View Location</h3>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <Drawmap 
                            context={this} 
                            coords={this.state.latLngArray} 
                            stepcoords={1} 
                            mapType={2} 
                            locInfo={""} 
                            mapFor={"dealer"}  
                        />
                    </div>
                </div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}