/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ExportPDF from './exportpdf';

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import {
    getDDMMYYYY, getDDMMMYYYY, getHyphenDDMMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import UploadPODReceit from "./uploaditem";
import ViewAttachments from "./viewallattachment";
import ViewIdentityAttachments from "./viewidentityattachment";
import EditItem from "./tptedititem";
import DeleteItem from "./deleteitem";
import RakeUnmapping from './rakeunmappingbutton';
import RenameRakedevice from './renamerakedevice';
import EditRakeDevices from './editrakedevices';
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class TPTDriverData extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            rowData: [],
            frameworkComponents: {
                uploaddoc: UploadPODReceit,
                viewattachments: ViewAttachments,
                viewidentityattachments: ViewIdentityAttachments,
                edititem: EditItem,
                deleteitem: DeleteItem,
                RakeUnmapping:RakeUnmapping,
                EditRakeDevices:EditRakeDevices,
                RenameRakedevice:RenameRakedevice,
            },
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            rowClassRules: {
                "boxbgblue": "data.to_be_approved == 1"
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            detailCellRendererParams: {},
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 200,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "RSO POD",
            sliderRouteTranslate: '',
            file: '',
            csvcontent: [],
            startDate: "",
            endDate: "",
            defaultsdate: "",
            defaultedate: "",
            headerHeight: 80,
            showform: "show-n",
            sliderForceDestination: "",
            truck_no: "",
            address_proof_no: "",
            address_proof: { value: "", label: "Select" },
            identity_proof_no: "",
            identity_proof: { value: "", label: "Select" },
            uploads: [],
            files: [],
            identityfiles: [],
            shwdata: "show-n",
            sliderDefectData: "",
            sliderBulk: "",
            transporter_code: "",
            username: "",
            userid: "",
            email: "",
            filteredData: [],
            showTrucksList: "show-n",
            trucks: [],
            rowid: "",
            driver_name: "",
            driver_mobile_no: "",
            driver_mobile_no_1: "",
            driver_address: "",
            driver_code: "",
            vehicle_no: "",
            license_no: "",
            driver_father_name: "",
            expiry_date: "",
            pdfRowData: [],
            pdfColumns: [],
            addressColumn: "",
            vehicle_no: { value: "", label: "Select" },
            vehiclenos: [],
            rake_no:"",
            device_id:"",
            rake_org_id:"",
            transporter_rake_code:"",
            selectedRowNode:[],
            selectedordid:[],
            organisation_options:[],
            transporter_code_options :[],
            updatedData:[],
            sliderForUpdateRowData : "",
            sliderForRenameRakeDevice:"",
            DataForupdateRowData:[],
            update_device_id:"",
            update_rake_id:"",
            update_transporter_code:"",
            rake_id_options:[],
            device_id_options:[],
            rename_rake_id:"",
            uniqueRakeDevices:[],
        };
        this.changeHandler = this.changeHandler.bind(this);
        this.onClickShowUploadModal = this.onClickShowUploadModal.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);
        this.onClickIdentityDownload = this.onClickIdentityDownload.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
    }

    componentDidMount() {
        loadDateTimeScript()
        redirectURL.post('dashboard/getdistinctRakeDevies').then(response => {
            var rake_ids = response.data 
            var rake_id_options = []
            rake_ids.map(e=>{
                rake_id_options.push({label:e , value:e})
            })
            this.setState({
                rake_id_options:rake_id_options,
                uniqueRakeDevices:rake_ids
            })
        })

        redirectURL.post('dashboard/getdistinctDeviceIds').then(response => {
            var device_ids = response.data 
            var device_id_options = []
            device_ids.map(e=>{
                device_id_options.push({label:e , value:e})
            })
            this.setState({
                device_id_options:device_id_options
            })
        })
        redirectURL.post("/dashboard/getorganisations")
            .then(async (response) => {
                // console.log(response,"response org")
                var getorganizationdata = response.data
                var organisationoptions = []
                getorganizationdata.map((e)=>{
                    organisationoptions.push({value:e.org_id,label:e.alias_name})
                })
                var transporter_code_options = [
                    {
                        value:"IVC_RAKE",label:"IVC"
                    },
                    {
                        value:"MSIL_RAKE",label:"MSIL"
                    },
                    {
                        value:"JKTI_RAKE",label:"JKTI"
                    },
                    {
                        value:"ADANI_RAKE",label:"ADANI"
                    },
                    {
                        value:"INOX_RAKE",label:"INOX"
                    },
                    {
                        value:"KIA_RAKE",label:"KIA"
                    },
                ]
                this.setState({
                    organisation_options:organisationoptions,
                    transporter_code_options:transporter_code_options,
                })
                
            // console.log(this.state.organisation_options,"organisation_options")
            })
        var sdate = moment().startOf('month').format('YYYY-MM-DD');
        //var sdate = "2020-09-23";
        var edate = moment().endOf('month').format('YYYY-MM-DD');
        var transporter_code = localStorage.getItem("transportercode")
        var username = localStorage.getItem("username")
        var userid = localStorage.getItem("userid");
        var email = localStorage.getItem("email");
        // console.log("transporter_code ", transporter_code)
        this.setState({
            startDate: sdate,
            endDate: edate,
            defaultsdate: sdate,
            defaultedate: edate,
            transporter_code: transporter_code,
            username: username,
            userid: userid,
            email: email
        })
        var mindate = moment.parseZone().format('YYYY-MM-DD');
        var minactdate = moment.parseZone(new Date(mindate)).subtract(1, 'days').format('YYYY-MM-DD');
        // console.log("path ", this.props.match.path)
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });

        // var parameters = {
        //     transporter_code: transporter_code
        // }

        this.onLoadData()

    }

    onLoadData = () => {
        this.setState({
            rowData: [],
            loadshow: "show-m"
        });
        // console.log("parameters ", parameters)
        redirectURL.post("/dashboard/rakedevicemappingdata")
            .then(async (response) => {
                var records = response.data;
                var vehicles = []
                var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
                var pdfRowData = [];
                // console.log("summaryData", records)
                records.map(function (t) {
                    vehicles.push(t.vehicle_no);
                    pdfRowData.push({
                        driver_code: t.driver_code,
                        driver_name: t.driver_name,
                        driver_father_name: t.driver_father_name,
                        vehicle_no: t.vehicle_no,
                        license_no: t.license_no,
                        expiry_date: t.expiry_date,
                        driver_address: t.driver_address,
                        driver_mobile_no: t.driver_mobile_no,
                        driver_mobile_no_1: t.driver_mobile_no_1,
                        address_proof: t.address_proof,
                        address_proof_no: t.address_proof_no,
                        address_proof_attachment: t.address_proof,
                        identity_proof: t.identity_proof,
                        identity_proof_no: t.identity_proof_no,
                        identity_proof_attachment: t.identity_proof,

                    });
                });

                var pdfColumns = [
                    { header: "Driver Code", binding: 'driver_code' },
                    { header: 'Driver Name', binding: 'driver_name' },
                    { header: 'Father Name', binding: 'driver_father_name' },
                    { header: 'Vehicle No', binding: 'vehicle_no' },
                    { header: 'License No', binding: 'license_no' },
                    { header: 'Expiry Date', binding: 'expiry_date' },
                    { header: 'Driver Address', binding: 'driver_address' },
                    { header: 'Driver Mobile Number', binding: 'driver_mobile_no' },
                    { header: 'Alternate Mobile No', binding: 'driver_mobile_no_1' },
                    { header: 'Address Proof', binding: 'address_proof' },
                    { header: 'Address Proof No', binding: 'address_proof_no' },
                    { header: 'Proof Attachment', binding: 'address_proof' },
                    { header: 'Identity Proof', binding: 'identity_proof' },
                    { header: 'Identity Proof No', binding: 'identity_proof_no' },
                    { header: 'Identity Proof Attachment', binding: 'identity_proof_attachment' },
                ]

                this.setState({
                    pdfRowData,
                    pdfColumns,
                    rowData: records,
                    loadshow: "show-n",
                    overly: "show-n"
                });

            })
    }


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    /*Alert Popups*/
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

    /*Alert Popups*/
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        //await window.location.reload()
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRouteTranslate: '',
            tabsliderTranslate: '',
            sliderTranslatesidebar: '',
            sliderForceCloseTranslate: '',
            sliderRakeTranslate: '',
            sliderBulkRakeTranslate: "",
            bulkslide: '',
            sliderForceDestination: '',
            sliderBulkForceClose: "",
            sliderDefectData: "",
            sliderBulk: "",
            rowid: "",
            selectedRowNode:"",
            sliderForUpdateRowData:"",
            sliderForRenameRakeDevice:"",
            DataForupdateRowData : "",
            update_rake_id:"",
            update_device_id:"",
            update_transporter_code : "",
            rename_rake_id:""
        });

    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        if(name == "device_id")
        {
            value = value.replace(/\D/g,'');
        }
        this.setState({ [name]: value });
    }


    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;


    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.gridApi.deselectAll();
        this.setState({ open: false });
    };

    onAddRow() {
        // var newItem = 
        // {
        //     sr_no:"",
        //     defect_type:"",
        //     defect_location:"",
        //     defect_remarks:""
        // };
        //console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
            sliderDefectData: "slider-translate",
            selectedRowNode: "",
            overly: 'show-m',
            rake_no: "",
            device_id: "",
            transporter_rake_code: "",
            rake_org_id: "",
        })
    }

    selectvins() {
        let items = [];
        var vins = this.state.vins;
        if (vins.length > 0) {
            vins.map((item) => {
                items.push({ "value": item.vehicle_id, "label": item.vehicle_id })
            })
        }
        return items;
    }

    onChangeVINs(vinno) {
        this.setState(
            { vinno },
            () => console.log(`Option selected:`, this.state.vinno)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeCarrier(carrier) {
        this.setState(
            { carrier },
            () => console.log(`carrier selected:`, this.state.carrier)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeGroup(group) {
        this.setState(
            { group },
            () => console.log(`group selected:`, this.state.group)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }


    onClickShowUploadModal(params) {
        this.setState({
            open: true
        })
    }
    onCloseModal = () => {
        this.setState({ open: false, rejectopen: false });
    };

    changeFileHandler = async (e) => {
        // console.log(e.target.files)
        var files = Array.from(e.target.files);
        this.setState({
            files: files,
            open: false
        })
    }

    changeIdentityFileHandler = async (e) => {
        // console.log(e.target.files)
        var files = Array.from(e.target.files);
        this.setState({
            identityfiles: files,
            open: false
        })
    }

    formRegister(event) {
		event.preventDefault();
        // console.log(this.state.organisation_options,"this.state.organisation_options")
		var rake_no = this.state.rake_no
        var device_id = this.state.device_id
		// var device_id1 = this.state.device_id.trim()
		var transporter_rake_code = this.state.transporter_rake_code.value
        // console.log(this.state.selectedordid,"this.state.selectedordid")
        // console.log(this.state.selectedordid.value,"this.state.selectedordid")
		var rake_org_id = this.state.selectedordid.value
        // this.state.organisation_options.map((oid)=>{
        //     rake_org_id.push(oid.org_id)
        // })
		// console.log(device_id,"device_id")
        // console.log(device_id1,"device_id1")
			var params = {
				rake_no: rake_no,
				device_id: device_id,
				transporter_rake_code:transporter_rake_code,
				org_id: rake_org_id,
			}
            // console.log(this.state.selectedRowNode,"this.state.selectedRowNode")
			if (this.state.selectedRowNode != "") {
				params.row_id = this.state.selectedRowNode.data._id
			}
			// console.log("params ", params)
            if (transporter_rake_code!="" &&transporter_rake_code!=undefined && rake_org_id !="" && rake_org_id !=undefined) {
                redirectURL.post("/dashboard/saverakedevicemappingdata", params, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).
                    then(
                        (response) => {
                            // console.log("update response is : ",response);
                            //console.log("Update Response ",response.data.result)
                            if (response.data.status == "Success") {
                                this.setState({
                                    show: true,
                                    basicTitle: 'Successfully added data',
                                    basicType: "success",
                                    sliderRso: "",
                                    selectedRowNode: "",
                                    overly: "show-n",
                                    loadshow: "show-n"
                                });
                                this.componentDidMount()
                            }
                            else {
                                this.setState({
                                    show1: true,
                                    basicTitle1: 'Something went wrong',
                                    basicType1: "danger",
                                });
                            }
    
                        }
                    )
            }
            else {
                this.setState({
                    show1: true,
                    basicTitle1: 'All * fields should not be empty',
                    basicType1: "danger",
                });
            }

	}

    onClickDownload(params) {
        console.log("params ", params.data)
        var parameters = {
            rowid: params.data._id,
            prooftype: "address"
        }
        redirectURL.post("/dashboard/downloaddriverfile", parameters)
            .then((response) => {
                console.log("resj ", response.data)
                var filename = params.data.address_proof_file;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })


    }

    onClickIdentityDownload(params) {
        // console.log("params ",params.data)

        var parameters = {
            rowid: params.data._id,
            prooftype: "identity"
        }
        redirectURL.post("/dashboard/downloaddriverfile", parameters)
            .then((response) => {
                console.log("resj ", response.data)
                var filename = params.data.identity_proof_file;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            })


    }
    onChangeTruck = vehicle_no => {
        this.setState({ vehicle_no })
    }
    onChangeAddress = address_proof => {
        this.setState({
            address_proof: address_proof
        })
    }
    onChangeIdentity = identity_proof => {
        this.setState({
            identity_proof: identity_proof
        })
    }

    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var trucks = this.state.trucks;
        var alltrucks = []
        var alltrucks = trucks.filter(f =>
            !this.state.rowData.some(d => d.truck_no == f.truck_no)
        );
        var dataset = trucks
        dataset = dataset.slice(0, 100);
        this.setState({
            filteredData: dataset,
            showTrucksList: "show-m",
        })
    }

    handlerForm = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var trucks = this.state.trucks;
        var alltrucks = []
        var alltrucks = trucks.filter(f =>
            !this.state.rowData.some(d => d.truck_no == f.truck_no)
        );
        var dataset = trucks
        var filteredResult = dataset.filter(function (e) {
            if (e != "" && e != null) {
                return e.truck_no.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }

        });
        filteredResult = filteredResult.slice(0, 100);
        this.setState({
            filteredData: filteredResult,
            showTrucksList: "show-m",
        })
    }


    updateRowHandler(rownode) {
        // console.log("rownode ", rownode.data)


        this.setState({
            rowid: rownode.data._id,
            rake_no: rownode.data.truck_no,
            device_id: rownode.data.device_id,
            transporter_rake_code: rownode.data.transporter_code,
            rake_org_id: rownode.data.org_id,
            selectedRowNode: rownode,
            sliderDefectData: "slider-translate",
            overly: 'show-m',
        })
    }
    onClickShowBulk() {
        this.setState({
            sliderBulk: "slider-translate",
            overly: 'show-m'
        })
    }

    changeBulkFileHandler = async (e) => {
        var dt = '';

        const config = {
            headers: [

                {
                    name: 'driver_code',
                    inputName: 'driver_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_name',
                    inputName: 'driver_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_father_name',
                    inputName: 'driver_father_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'vehicle_no',
                    inputName: 'vehicle_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'license_no',
                    inputName: 'license_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'expiry_date',
                    inputName: 'expiry_date',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_mobile_no',
                    inputName: 'driver_mobile_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_mobile_no_1',
                    inputName: 'driver_mobile_no_1',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'driver_address',
                    inputName: 'driver_address',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },

                {
                    name: 'address_proof',
                    inputName: 'address_proof',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'address_proof_no',
                    inputName: 'address_proof_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'identity_proof',
                    inputName: 'identity_proof',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'identity_proof_no',
                    inputName: 'identity_proof_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData1) => {
                this.setState({
                    csvcontent: csvData1.data
                });
                console.log(csvData1.data);
            })
            .catch(err => { })
        // console.log("e.target.files[0]",e.target.result);

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file: e.target.files[0]
        });

    }
    formBulkRegister(event) {
        event.preventDefault()
        if (this.state.csvcontent.length > 0) {
            var params = {
                csvcontent: this.state.csvcontent,
                transporter_name: this.state.username,
                transporter_code: this.state.transporter_code,
                username: this.state.username,
                userid: this.state.userid,
                email: this.state.email
            }
            console.log("params ", params)
            redirectURL.post("/dashboard/tptbulkdrivers", params)
                .then((response) => {
                    if (response.data.status == "success") {
                        this.setState({
                            csvcontent: [],
                            show: true,
                            basicTitle: "Successfully store data",
                            basicType: "success",
                            sliderBulk: "",
                            overly: 'show-n'
                        })
                    }
                    else if (response.data.status == "exists") {
                        this.setState({
                            csvcontent: [],
                            show: true,
                            basicTitle: "Already Trucks exists",
                            basicType: "danger"
                        })
                    }
                    else {
                        if (response.data.status == "notvalid") {
                            this.setState({
                                csvcontent: [],
                                show: true,
                                basicTitle: "Invalid data uploaded",
                                basicType: "danger"
                            })
                        }
                        else {
                            this.setState({
                                csvcontent: [],
                                show: true,
                                basicTitle: "Failed to registered",
                                basicType: "danger"
                            })
                        }

                    }
                })
        }
        else {
            this.setState({
                csvcontent: [],
                show: true,
                basicTitle: "Data not exists in uploaded file",
                basicType: "danger"
            })
        }


    }
    selectCarriers() {
        var items = []
        if (this.state.carriers.length > 0) {
            this.state.carriers.map((item) => {
                items.push({
                    value: item.carrier_code,
                    label: item.carrier_title
                })
            })
        }
        return items;
    }
    selectGroups() {
        var items = []
        if (this.state.groups.length > 0) {
            this.state.groups.map((item) => {
                items.push({
                    value: item.group_code,
                    label: item.group_title
                })
            })
        }
        return items;
    }
    deleteRowHandler(cell) {
        console.log("cell ", cell.data)
    }
 
    onClickUnmapRakeDevice =  (params) =>{
        this.setState({
            alert: (
                <SweetAlert
                    showCancel
                    confirmBtnText="OK"
                    confirmBtnBsStyle='success'
                    cancelBtnBsStyle="default"
                    type='warning'
                    title="Do you want to Unmap Device ?"
                    onCancel={this.hideAlert}
                    onConfirm={this.onConformUnmap.bind(this , params)}
                />
            ),
        });
    }
    

    onConformUnmap = (params) => {
        var data = params.data
        redirectURL.post('dashboard/updateUnmappingData',data).then(response => {
            if (response.data.status == "success") {
                this.setState({
                    show: true,
                    basicTitle: 'Successfully Unmapped the device',
                    basicType: "success",
                    overly: "show-n",
                    loadshow: "show-n",
                    alert: null
                });
            }
            else {
                this.setState({
                    show1: true,
                    basicTitle1: 'Something went wrong',
                    basicType1: "danger",
                });
            }

        })

    }

    onClickEditData = params => {
        this.setState({
            sliderForUpdateRowData:'slider-translate',
            overly: 'show-m',
            DataForupdateRowData : params.data,
            update_device_id:{label:params.data.device_id,value:params.data.device_id},
            update_rake_id:{label:params.data.truck_no,value:params.data.truck_no},
            update_transporter_code : {label:params.data.transporter_code,value:params.data.transporter_code},
        })
    }

    onClickRenameRakeDevice = params => {
        this.setState({
            sliderForRenameRakeDevice:'slider-translate',
            overly: 'show-m',
            DataForupdateRowData : params.data,
            rename_rake_id:params.data.truck_no
        })
    }

    UpdateRakeDevicesData = (event) => {
        event.preventDefault()
        var rake_id = this.state.update_rake_id.value
        var device_id = this.state.update_device_id.value
        var transporter_code = this.state.update_transporter_code.value
        var rowdata = this.state.DataForupdateRowData
        if (rake_id==rowdata.truck_no&& device_id==rowdata.device_id && transporter_code== rowdata.transporter_code){
            this.setState({
                show1: true,
                basicTitle1: 'No Changes Made',
                basicType1: "danger",
            });
        }else{
            var formdata = {
                transporter_code:transporter_code,
                truck_no:rake_id,
                device_id:device_id,
                old_rake_id:rowdata.truck_no,
                old_device_id:rowdata.device_id,
                old_transporter_code:rowdata.transporter_code
            }
            redirectURL.post('dashboard/updateRakeDeviceData' , formdata).then(response=>{
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicTitle: 'Successfully Updated Data',
                        basicType: "success",
                        overly: "show-n",
                        loadshow: "show-n",
                    });
                }
                else {
                    this.setState({
                        show1: true,
                        basicTitle1: 'Something went wrong',
                        basicType1: "danger",
                    });
                }
            })
        }
        
    }

    renameRakeDevice = event => {
        event.preventDefault()
        var rake_id = this.state.rename_rake_id
        var rowdata = this.state.DataForupdateRowData
        var uniqueRakeDevices = this.state.uniqueRakeDevices
        if (rake_id.toUpperCase() == rowdata.truck_no){
            this.setState({
                show1: true,
                basicTitle1: 'No Changes Made',
                basicType1: "info",
            });
        }
        else if(uniqueRakeDevices.includes(rake_id.toUpperCase())){
            this.setState({
                show1: true,
                basicTitle1: 'Rake Name Already Exists',
                basicType1: "warning",
            });
        }
        else{
            var renameData = {rake_id : rake_id.toUpperCase()}
            renameData['old_rake_id'] = rowdata.truck_no
            redirectURL.post('dashboard/renameRakeDevice',renameData).then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicTitle: 'Successfully Renamed Rake Device',
                        basicType: "success",
                        overly: "show-n",
                        loadshow: "show-n",
                    });
                }
                else {
                    this.setState({
                        show1: true,
                        basicTitle1: 'Something went wrong',
                        basicType1: "danger",
                    });
                }
            })
        }

    }


   
    render() {
        // console.log("rowid ", this.state.rowid)
        var rr = this.state.rowid
        if (rr.trim() == "") {
            var rshw = "show-m";
            var isreq = true
        }
        else {
            var rshw = "show-n";
            var isreq = false
        }
        const modalStyles = {
            width: '300px !important',
        }
        const { open } = this.state;
        var columnwithDefs = [
            {
                headerName: "Device Id",
                field: "device_id",
                width: 140,
            },
            {
                headerName: "Rake Id",
                field: "truck_no",
                width: 140,
            },
            {
                headerName:"Transporter Code",
                field:"transporter_code",
                width:140,
            },
            {
                headerName : "Unmapping",
                field:"",
                width:140,
                cellRendererSelector: function (params) {
                           
                                var component =  {
                                    component: "RakeUnmapping"
                                }
                                return component
                        }
            },
            {
                headerName : "Action",
                field:"",
                width:140,
                cellRendererSelector: function (params) {
                           
                                var component =  {
                                    component: "EditRakeDevices"
                                }
                                return component
                        }
            },
            {
                headerName : "Assit Change",
                field:"",
                width:140,
                cellRendererSelector: function (params) {
                           
                                var component =  {
                                    component: "RenameRakedevice"
                                }
                                return component
                        }
            },
            
        ]
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e.truck_no}</li>)
        })

        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">

                    <div className="col-xl-12 col-lg-12">

                        <div className="card">

                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>Rake Device Mapping</span>

                                    {/* <button
                                        type="button"
                                        onClick={this.onClickShowBulk.bind(this)}
                                        className="btn btn-danger float-right">
                                        Bulk Upload
                                    </button> */}
                                    <button
                                        type="button"
                                        onClick={this.onAddRow.bind(this)}
                                        className="btn btn-warning float-right">
                                        Add Rake
                                    </button>
                                    {/* {(this.state.pdfRowData.length > 0) ?
                                        <ExportPDF rowData={this.state.pdfRowData} pagetitle="RSO POD" pdfColumns={this.state.pdfColumns}
                                        // addressColumn={this.state.addressColumn}
                                        />
                                        : ""}
                                    <span className="layoutbtns pull-right">
                                        <a className="btn btn-info mr-2" href="/tptlogin/driverlogs">
                                            Driver Logs
                                        </a>
                                    </span> */}
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className={"row col-xl-12 col-lg-12 "}>

                                        <div className={"col-xl-12 col-lg-12 "}>
                                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    headerHeight={this.state.headerHeight}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    masterDetail={true}
                                                    onCellValueChanged={this.onCellValueChanged}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>




                <div className={"slide-r " + (this.state.sliderDefectData)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Driver Details
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" id="defectForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formRegister.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">Rake No<span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="rake_no" value={this.state.rake_no} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Device Id<span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="device_id" value={this.state.device_id} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>
                                {/* <div className="form-group mt-20p">
                                    <label className="">Vehicle No </label> */}
                                    {/* <input type="text" name="vehicle_no" value={this.state.vehicle_no} onChange={this.changeHandler.bind(this)}  className="form-control" required  /> */}
                                    {/* <Select placeholder="Select"
                                        value={this.state.vehicle_no}
                                        onChange={this.onChangeTruck.bind(this)}
                                        options={this.state.vehiclenos}
                                        /> */}
                                {/* </div> */}
                                <div className="col-xl-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Select Transporter Code <span style={{ color: "#FF0000" }}>*</span></label>
                                        <Select
                                            value= {this.state.transporter_rake_code}
                                            closeMenuOnSelect={true}
                                            isMulti={false}
                                            className={"border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.transporter_code_options}
                                            onChange={(e)=>{this.setState({transporter_rake_code:e})}}
                                        />
                                    </div>
                                </div>

                                {/* <div className="form-group mt-20p">
                                    <label className="">ORG Id<span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="rake_org_id" value={this.state.rake_org_id} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div> */}
                                <div className="col-xl-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Select ORG ID <span style={{ color: "#FF0000" }}>*</span></label>
                                        <Select
                                            value= {this.state.selectedordid}
                                            // placeholder="All"
                                            closeMenuOnSelect={true}
                                            isMulti={false}
                                            // onChange={this.getRailConsginments.bind(this)}
                                            className={"border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.organisation_options}
                                            onChange={(e)=>{this.setState({selectedordid:e})}}
                                        />
                                    </div>
                                </div>
                                {/* <div className="form-group mt-20p">
                                    <label className="">Alternate Mobile Number <span style={{ color: "#FF0000" }}></span></label>
                                    <input type="text" name="driver_mobile_no_1" value={this.state.driver_mobile_no_1} onChange={this.changeHandler.bind(this)} className="form-control" />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Address <span style={{ color: "#FF0000" }}></span></label>
                                    <textarea name="driver_address" value={this.state.driver_address} onChange={this.changeHandler.bind(this)} rows="4" className="form-control" ></textarea>
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Address Proof <span style={{ color: "#FF0000" }}></span></label>
                                    <Select placeholder="Select"
                                        value={this.state.address_proof}
                                        onChange={this.onChangeAddress.bind(this)}
                                        options={[
                                            { "value": "", "label": "Select" },
                                            { "value": "aadhar card", "label": "Aadhar Card" },
                                            { "value": "electricity bill", "label": "Electricity Bill" }
                                        ]}
                                        required />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Address Proof No <span style={{ color: "#FF0000" }}></span></label>
                                    <input
                                        type="text"
                                        placeholder="Ex: 0000000000000000"
                                        id="address_proof_no"
                                        name="address_proof_no"
                                        onChange={this.changeHandler.bind(this)}
                                        className="form-control"
                                        value={this.state.address_proof_no}
                                    />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Upload Address File <span style={{ color: "#FF0000" }}></span></label>
                                    <input
                                        type="file"
                                        id="address_proof_file"
                                        name="address_proof_file"
                                        onChange={this.changeFileHandler.bind(this)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Identity Proof <span style={{ color: "#FF0000" }}></span></label>
                                    <Select placeholder="Select"
                                        value={this.state.identity_proof}
                                        onChange={this.onChangeIdentity.bind(this)}
                                        options={[
                                            { "value": "", "label": "Select" },
                                            { "value": "voter card", "label": "Voter Card" },
                                            { "value": "pan card", "label": "Pan Card" },
                                            { "value": "driving license", "label": "Driving License" }
                                        ]}
                                        required />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Identity Proof No <span style={{ color: "#FF0000" }}></span></label>
                                    <input
                                        type="text"
                                        placeholder="Ex: 0000000000000000"
                                        id="identity_proof_no"
                                        name="identity_proof_no"
                                        value={this.state.identity_proof_no}
                                        onChange={this.changeHandler.bind(this)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Upload Identity File <span style={{ color: "#FF0000" }}></span></label>
                                    <input
                                        type="file"
                                        id="identity_proof_file"
                                        name="identity_proof_file"
                                        onChange={this.changeIdentityFileHandler.bind(this)}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">License No <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="license_no" value={this.state.license_no} onChange={this.changeHandler.bind(this)} className="form-control" required />
                                </div>

                                <div className="form-group mt-20p">
                                    <label className="">Expiry Date <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="expiry_date" id="expiry_date" value={this.state.expiry_date} className="datetimepicker_mask form-control" required />
                                </div> */}
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>

                            {/* <div className="col-xl-12 col-lg-12 form-group">
                                Note: File formats allowed : pdf
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderForUpdateRowData)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Update Data
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" id="defectForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.UpdateRakeDevicesData.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">Rake No<span style={{ color: "#FF0000" }}>*</span></label>
                                    <Select
                                            value= {this.state.update_rake_id}
                                            closeMenuOnSelect={true}
                                            isMulti={false}
                                            className={"border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.rake_id_options}
                                            onChange={(e)=>{this.setState({update_rake_id:e})}}
                                        />
                                </div>
                                <div className="form-group mt-20p">
                                    <label className="">Device Id<span style={{ color: "#FF0000" }}>*</span></label>
                                    <Select
                                            value= {this.state.update_device_id}
                                            closeMenuOnSelect={true}
                                            isMulti={false}
                                            className={"border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.device_id_options}
                                            onChange={(e)=>{this.setState({update_device_id:e})}}
                                        />                                
                                </div>
                               
                                <div className="col-xl-12 col-lg-12">
                                    <div className="form-group">
                                        <label>Select Transporter Code <span style={{ color: "#FF0000" }}>*</span></label>
                                        <Select
                                            value= {this.state.update_transporter_code}
                                            closeMenuOnSelect={true}
                                            isMulti={false}
                                            className={"border-radius-0"}
                                            style={{borderRadius:"0px"}}
                                            options={this.state.transporter_code_options}
                                            onChange={(e)=>{this.setState({update_transporter_code:e})}}
                                        />
                                    </div>
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>

                            
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderForRenameRakeDevice)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Rename Rake Device
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" id="defectForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.renameRakeDevice.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">Rake No<span style={{ color: "#FF0000" }}>*</span></label>
                                    <input type="text" name="rename_rake_id" value={this.state.rename_rake_id} onChange={this.changeHandler.bind(this)} className="form-control"  />
                                </div>          
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>

                           
                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.sliderBulk)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Bulk Register
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" id="bulkForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkRegister.bind(this)}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File <span style={{ color: "#FF0000" }}>*</span></label>
                                    <input
                                        type="file"
                                        id="bulk"
                                        name="bulk"
                                        onChange={this.changeBulkFileHandler.bind(this)}
                                        className="form-control" required={isreq} />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                <div className="col-xl-12 col-lg-12">
                                    <a
                                        href={require("../../assets/json/sample_bulk_tpt_driver.csv")}
                                        target="_blank"
                                        className="btn btn-danger">Download Sample File</a>

                                </div>
                                <div className="col-xl-12 col-lg-12 f12 mt-20" style={{ color: "#FF0000" }}>
                                    Note: Expiry Date should be "YYYY-MM-DD"
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </div>

        );
    }
}


window.onload = function () {
    $("#expiry_date").datetimepicker({
        mask: '9999-19-39',
        format: 'Y-m-d',
        timepicker: false
    });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};
document.addEventListener('turbolinks:before-render', () => {
    loadDateTimeScript()
});

function loadDateTimeScript() {

    $('#expiry_date').datetimepicker({
        mask: '9999-19-39',
        format: 'Y-m-d',
        timepicker: false
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        console.log(date1Number, date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $("#trucks-dropdown").removeClass("show-m");
        $("#trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($("#trucks-dropdown").attr("class").includes("show-m") || $("#trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $("#trucks-dropdown").removeClass("show-m");
                    $("#trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })


})
$(document).on("input", ".num-cls", function() {

    this.value = this.value.replace(/\D/g,'');

});