/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component, useContext } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { parseMarker } from '@fullcalendar/react';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');

export default class TruckStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: 'multiple',
            context: { componentParent: this },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            frameworkComponents: {
                updateItem: UpdateItem,
                deleteItem: DeleteItem
                //statusChange:StatusChange
            },
            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk: "",
            file: "",
            csvcontent: [],
            statusList: [],
            citiesList:[],
            msiltrips: [],
            msiltripitems: []

        };
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
    }
    getCurrentDepartmentCode(pathToMatch = null) {
        let department = null;
        let departmentName = null;
        switch (pathToMatch) {
            case "/tptoemmaster":
                department = 'SNDG';
                departmentName = " ";
                break;

            default:
                console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
        }
        this.setState({
            currentDepartmentTitle: departmentName
        });
        return department;
    }
    componentDidMount() {
        if (googleAnalytics.page.enableGA) {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({ eventAction: eventAction })
        }
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        // get department code to filter records by department
        let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
        this.setState({
            departmentCode: currentDepartmentCode
        });
        /*Consignments List*/
        //this.getOnTripData()
        this.onLoadData();
        
    };

getOnTripData = () => {

    var sdate = moment.parseZone().subtract(10, "days").format("YYYY-MM-DD");
    var edate = moment.parseZone().format("YYYY-MM-DD");
    if (localStorage.getItem("transportercode") != undefined) {
        var transporter = JSON.parse(localStorage.getItem("transportercode"))
    }
    else {
        transporter = "";
    }
    console.log("transporter ", transporter)
    if (Array.isArray(transporter)) {
        var transporter_code = JSON.stringify(transporter)
    }
    else {
        transporter_code = JSON.stringify([transporter])
    }

    var consignDatapp = {
        transporter_code: transporter_code,
        startDate: sdate,
        endDate: edate,
        consigner: [{ "value": 'all', "label": 'All' }]
    }
    redirectURL.post("/dashboard/oemconsignments", consignDatapp)
        .then((response) => {
            var records = response.data.records;
            // console.log("nonmsil records ", records)
            var recordsarr = []
            if (records.length > 0) {
                records.map((item) => {
                    recordsarr.push(item.truck_no)
                })
            }
            this.setState({
                nonmsil: recordsarr,
                // nonmsillist:records
            })
        })
    if (localStorage.getItem("is_hero") != undefined && localStorage.getItem("is_hero") == 1) {
        // console.log("S1")

        console.log("transporter ", transporter)
        if (Array.isArray(transporter)) {
            transporter_code = JSON.stringify(transporter)
        }
        else {
            transporter_code = JSON.stringify([transporter])
        }

        var consignData = {
            tpt_code: localStorage.getItem("transportercode"),
            transporter_code: transporter_code,
            consigner: [{ "value": "all", "label": "All" }],
            startDate: sdate,
            endDate: edate
        }
        // console.log("consignData ", consignData)
        redirectURL.post("/consignments/tptherotrips", consignData).then((response1) => {
            var consignments = response1.data.consignments;
            // console.log("response1 consignments ", consignments)
            var consignmentsarr = []
            var consignmentitems = []
            if (consignments.length > 0) {
                consignments.map((item) => {
                    consignmentsarr.push(item.truck_no);
                    // consignmentitems.push(item);
                })
            }

            this.setState({
                msiltrips: consignmentsarr,
                // msiltripitems:consignmentitems
            })
        })
    } else {
        consignData = {
            tpt_code: localStorage.getItem("transportercode"),
            consigner: [{ "value": "all", "label": "All" }]
        }
        redirectURL.post("/dashboard/tptmsiltrips", consignData).then((response1) => {
            var consignments = JSON.parse(response1.data.consignments);
            console.log("response1 consignments ", consignments)
            var consignmentsarr = []
            var consignmentitems = []
            if (consignments.length > 0) {
                consignments.map((item) => {
                    consignmentsarr.push(item.truck_no);
                    // consignmentitems.push(item);
                })
            }

            this.setState({
                msiltrips: consignmentsarr,
                // msiltripitems:consignmentitems
            })
        })
    }
    this.onLoadData();
}


onLoadData = () => {
    var loginType = "";
    if (localStorage.getItem("is_hero") != undefined && localStorage.getItem("is_hero") == 1) {
        loginType = "hero"
    }
    else{
        loginType = "msil"
    }
    var tptCode = localStorage.getItem('transportercode');
        var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			   var records = response.data.records;
				// console.log("records ", records)
                tptrucks = records;
				this.setState({
					tpttrucks:records
				})
			})
		}
    var params = {
        transporter_code: localStorage.getItem("transportercode"),
        current_date: moment.parseZone().format("YYYY-MM-DD"),
        previous_date: moment.parseZone().subtract(1, 'days').format("YYYY-MM-DD"),
        loginType: loginType,
    }
    redirectURL.post('/dashboard/getTruckStatus', params).then((response) => {
        var records = response.data;
        // console.log("records ", records)
        redirectURL.post('/dashboard/getTruckDetailsByStatus', params).then((response1) => {
            var records1 = response1.data;
            // console.log(records1,"records1")
            let statusDropDown = records1.dropList
            let citiesDropDown = records1.citiesList
            let truckStatusData = records1.griddata
            var tpttrucks = tptrucks;
            // console.log("citiesDropDown ",citiesDropDown)
			var trucksarr = []
			if(tpttrucks.length > 0)
			{
				truckStatusData.filter(f =>
					tpttrucks.filter((d )=> {
						if(d.truck_no == f.truck_no){									
							trucksarr.push(f)
						}
					})
				);
			}
			else
			{
				trucksarr = truckStatusData
			}
            // console.log("statusDropDown", statusDropDown)
            citiesDropDown.sort(function(a, b){
                if(a < b) { return -1; }
                if(a > b) { return 1; }
                return 0;
            })
            this.setState({
                rowData: trucksarr,
                statusList: statusDropDown,
                citiesList: citiesDropDown,
                loadshow: "show-n",
                overly: "show-n"
            });
        })
    })
    .catch(function (error) {
        console.log(error);
    });

}
logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
        } else {
            console.log("GA disabled...");
        }
    } catch (error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}

onCloseUserManualModal = () => {
    this.setState({ usermanualmodal: false });
};

onClickShowUserManaul = () => {
    this.setState({ usermanualmodal: true });
};

onGridReady = params => {
    this.gridApi = params.api;
    //console.log("Grid is: ", this.gridOptionsWrapper);
    this.gridColumnApi = params.columnApi;

};
onGridState = () => {
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();

    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);



}

onClickUpdateRowHandler(event) {
    event.preventDefault();
    if (googleAnalytics.page.enableGA) {
        let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
        let pageTitle = "Update OEM Master Data";
        if (currentDepartmentTitle) {
            pageTitle = "Update OEM Master Data" + currentDepartmentTitle;
        }

        let eventOptions = {
            "category": pageTitle,
            "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
            "label": googleAnalytics.page.action.rowUpdated,
        }
        googleAnalytics.logEvent(eventOptions);
    }

    //    if(this.state.oem_name != "" && this.state.oem_code != ""
    //    && this.state.industry_type != "")
    //    {
    //        var params = {
    //            rowID:this.state.rowid,
    //            oem_code:this.state.oem_code,
    //            oem_name:this.state.oem_name,
    //            industry_type:this.state.industry_type,
    //            transporter_code:localStorage.getItem("transportercode")
    //        }
    //        redirectURL.post("/dashboard/updateoemitem", params,{
    //            headers:{
    //                'Content-Type': 'application/json'
    //            }
    //        }).
    //        then(
    //            (response)=>{
    //                //console.log("update response is : ",response.data);
    //                //console.log("Update Response ",response.data.result)
    //                if(response.data.status == "success")
    //                {
    //                    this.setState({
    //                        show: true,
    //                        basicTitle:'Successfully update data',
    //                        basicType:"success",
    //                        sliderUpdate:"",
    //                        overly:"show-n"
    //                    });
    //                    this.onLoadData();
    //                }

    //                else{
    //                    this.setState({
    //                        show: true,
    //                        basicTitle:'Something went wrong',
    //                        basicType:"danger",
    //                    });
    //                }

    //            }
    //        )
    //        .catch(function(error){
    //            console.log(error);
    //        });
    //    }
    //    else{
    //        this.setState({
    //            show: true,
    //            basicTitle:'All * fields should not be empty',
    //            basicType:"danger",
    //        });
    //    }

}


onClickNewAddRow(event) {
    event.preventDefault();
    if (googleAnalytics.page.enableGA) {
        let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
        let pageTitle = "Add OEM Master Data";
        if (currentDepartmentTitle) {
            pageTitle = "Add OEM Master Data" + currentDepartmentTitle;
        }

        let eventOptions = {
            "category": pageTitle,
            "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
            "label": googleAnalytics.page.action.rowUpdated,
        }
        googleAnalytics.logEvent(eventOptions);
    }
    if (this.state.oem_name != "" && this.state.oem_code != ""
        && this.state.industry_type != "") {
        var params = {
            oem_code: this.state.oem_code,
            oem_name: this.state.oem_name,
            industry_type: this.state.industry_type,
            transporter_code: localStorage.getItem("transportercode")
        }
        var fdata = {
            rownode: params,
            transporter_code: localStorage.getItem("transportercode")
        }
        redirectURL.post("/dashboard/saveoemitem", params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            (response) => {
                //console.log("update response is : ",response.data);
                //console.log("Update Response ",response.data.result)
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicTitle: 'Successfully added data',
                        basicType: "success",
                        sliderRso: "",
                        overly: "show-n",
                        loadshow: "show-n"
                    });
                    this.onLoadData();
                }

                else {
                    this.setState({
                        show: true,
                        basicTitle: 'Something went wrong',
                        basicType: "danger",
                    });
                }

            }
        )
            .catch(function (error) {
                console.log(error);
            });
    }
    else {
        this.setState({
            show: true,
            basicTitle: 'All * fields should not be empty',
            basicType: "danger",
        });
    }

}

/*Alert Popups*/
closeAlert = () => {
    this.setState({
        show: false
    });
}


onAddRow() {
    // var newItem = 
    // {
    //     "truck_no":"",
    //     "truck_type":"",
    //     "transporter_code":"",
    //     "dept_code":""
    // };
    // console.log("newItem",newItem);
    // var res = this.gridApi.updateRowData({ add: [newItem] });
    this.setState({
        sliderRso: 'slider-translate',
        overly: 'show-m',
        oem_code: "",
        oem_name: "",
        industry_type: ""
    })

}


updateRowHandler = async (cell) => {

    var rowid = cell._id;
    var oem_code = cell.oem_code;
    var oem_name = cell.oem_name;
    var industry_type = cell.industry_type;
    await this.setState({
        sliderUpdate: 'slider-translate',
        overly: 'show-m',
        oem_code: oem_code,
        oem_name: oem_name,
        industry_type: industry_type,
        rowid: rowid
    })

}



onClickHideAll() {
    this.setState({
        loadshow: 'show-n',
        overly: 'show-n',
        sliderRso: "",
        sliderUpdate: "",
        sliderBulk: ""
    });

}

onChangeTruckType(trucktype) {
    this.setState(
        { trucktype },
        () => console.log(`Option selected:`, this.state.trucktype)
    );


    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}

changeHandler = (event) => {
    //console.log("Select", event.target.value);
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
}


onChangeTransporter(transporter) {
    this.setState(
        { transporter },
        () => console.log(`Option selected:`, this.state.transporter)
    );


    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}

onChangeDepartment(deptcode) {
    this.setState(
        { deptcode },
        () => console.log(`Option selected:`, this.state.deptcode)
    );


    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}

deleteRowHandler(cell) {
    //  console.log("Here",cell);
    if (googleAnalytics.page.enableGA) {
        let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
        let pageTitle = "OEM Master Data";
        if (currentDepartmentTitle) {
            pageTitle = "OEM Master Data" + currentDepartmentTitle;
        }

        let eventOptions = {
            "category": pageTitle,
            "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
            "label": googleAnalytics.page.action.rowUpdated,
        }
        googleAnalytics.logEvent(eventOptions);
    }

    var fdata = {
        rownode: cell
    }
    redirectURL.post("/dashboard/deleteoemitem", fdata, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).
        then(
            (response) => {
                //console.log("update response is : ",response.data);
                //console.log("Update Response ",response.data.result)
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicTitle: 'Successfully delete item',
                        basicType: "success",
                        loadshow: "show-n",
                        overly: "show-n",
                        sliderRso: ""
                    });
                    this.onLoadData();
                }

                else {
                    this.setState({
                        show: true,
                        basicTitle: 'Something went wrong',
                        basicType: "danger",
                    });
                }

            }
        )
        .catch(function (error) {
            console.log(error);
        });

}

onChangeSourceItem(sourceopt) {
    this.setState(
        { sourceopt },
        () => console.log(`Option selected:`, this.state.sourceopt)
    );


    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}
onChangeOfficerType(officertype) {
    this.setState(
        { officertype },
        () => console.log(`Option selected:`, this.state.officertype)
    );


    this.setState({
        errorMessage: '',
        successMessage: ''
    })
}
selectsources() {
    let items = [];
    try {
        if (this.state.sources.length > 0) {
            this.state.sources.map((item) => {
                items.push({ "value": item.geofence_name, "label": item.geofence_name })
            })
        }
    }
    catch (e) {

    }
    return items;
}

onClickShowBulk() {
    this.setState({
        overly: "show-m",
        sliderBulk: 'slider-translate',
    })
}


changeFileHandler = async (e) => {
    //console.log(e.target.files[0])
    var dt = '';
    // const config = {
    // 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
    // }

    const config = {
        headers: [          
            {
                name: 'truck_no',
                inputName: 'truck_no',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            {
                name: 'current_status',
                inputName: 'current_status',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})

    var out = new Promise(function (reject, resolve) {
        var reader = new FileReader();
        reader.onload = async function (e) {
            var contents = await e.target.result;
            // console.log("contents ", contents)

            resolve(contents);
        };
        var tt = reader.readAsText(e.target.files[0]);
        console.log("tt ", tt)
    });
    //console.log("Out ", out);

    this.setState({
        file: e.target.files[0]
    });

}
onSubmitBulkUpload = (event) =>{
    event.preventDefault()

    if (googleAnalytics.page.enableGA) {
        let pageTitle = this.state.pagetitle;
        let eventOptions = {
            "category": "Buk Upload",
            "action": "form",
            "label": "Buk Upload",
        }
        googleAnalytics.logEvent(eventOptions);
    }
    var vehicle_group = localStorage.getItem("vehicle_group");
    var email = localStorage.getItem("email");
    var transporter_code = JSON.parse(localStorage.getItem("transportercode"))

    var params = {
        csvcontent: this.state.csvcontent,
        transporter_code,
        email,
    }
    if(vehicle_group)params.vehicle_group = vehicle_group
    // console.log("Params ", params)
    redirectURL.post("/dashboard/bulkuploadtruckcurrentstatus", params)
        .then((response) => {
            if (response.data.message == "success") {
                this.setState({
                    show: true,
                    basicTitle: 'Successfully added items',
                    basicType: "success",
                    loadshow: "show-n",
                    overly: "show-n",
                    sliderBulk: "",
                    file: ""
                });
                this.onLoadData();
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: 'Something went wrong',
                    basicType: "danger",
                });
            }
        })
}

onCellRowClicked = e => {
    if (e.colDef.field == "update") {
        console.log("e", e.data)
        let params = e.data
        var vehicle_group = localStorage.getItem("vehicle_group");
        var email = localStorage.getItem("email");
        params.email = email
        // console.log("paRAMS", params)
        var confirm = window.confirm("Are you sure you want to Update item?")
        if (confirm == true) {
            params.transporter_code = localStorage.getItem("transportercode");
            if(vehicle_group)params.vehicle_group = vehicle_group
            if (params.current_status !== "" && params.current_status !== undefined || params.current_location !== "" && params.current_location !== undefined) {
                redirectURL.post("/dashboard/updateCurrentTruckStatus", params)
                    .then((response) => {
                        if (response.data.message == "Success") {
                            this.setState({
                                basicTitle: "Success",
                                basicType: "success",
                                show: true,
                                overly: "show-n",
                                loadshow: "show-n",
                            })
                            //window.location.reload();
                            this.getOnTripData()
                        }
                        else if (response.data.message == "Details Already Exists") {
                            this.setState({
                                basicTitle: "Details Already Exists",
                                basicType: "warning",
                                show: true
                            })
                        }
                        else {
                            this.setState({
                                basicTitle: "Failed to update.",
                                basicType: "warning",
                                show: true
                            })
                        }
                    })
            }
            else {
                this.setState({
                    basicTitle: "Please Select Values to update.",
                    basicType: "warning",
                    show: true
                })
            }
        }
    }
}
render() {
    const modalStyles = {
        width: '1300px !important',
    }
    var transporters = []
    var trans = this.state.transporters;
    if (trans.length > 0) {
        trans.map((item) => {
            transporters.push(item.value)
        })
    }
    const { usermanualmodal } = this.state;
    var hideChilds = true;
    if (this.state.departmentCode == "LOG-PRT") {
        hideChilds = false
    }
    const columnwithDefs = [
        {
            headerName: "Truck no",
            field: "truck_no",
            width: 120,
            editable: false,
            filter: "agSetColumnFilter"
        },

        {
            headerName: "Yesterday Status",
            field: "yesterday_status",
            width: 150,
            editable: false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Current Status",
            field: "current_status",
            width: 150,
            editable: true,
            filter: "agSetColumnFilter",
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.statusList,
            },
        },
        {
            headerName: "Current Locaiton",
            field: "city",
            width: 150,
            editable: false,
            filter: "agSetColumnFilter",
            // cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: this.state.citiesList,
            // },
        },
        {
            headerName: "Update",
            field: "update",
            width: 140,
            filter: false,
            resizable: true,
            cellRenderer: function (params) {
                var htmloption = '<button class="btn btn-primary" style="padding-top:0px">Update</button>';
                return htmloption;
            },
        },

    ];
    return (
        <div className="container-fluid">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Update Truck Status </span>
                                {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button> */}
                                <button type="button" onClick={this.onClickShowBulk.bind(this)} className="btn btn-danger float-right">Bulk Upload</button>
                            </h5>
                        </div>
                        <div className="card-body">
                            <div id="myGrid" style={{ height: "450px", width: "100%" }} className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    frameworkComponents={this.state.frameworkComponents}
                                    stopEditingWhenGridLosesFocus={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    suppressRowClickSelection={true}
                                    onCellClicked={this.onCellRowClicked}
                                // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                //editType={this.state.editType}
                                // floatingFilter={true}
                                //  paginationAutoPageSize={true}
                                //updateRowHandler={this.updateRowHandler}
                                //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"slide-r " + (this.state.sliderTranslate)} >

                <div className="slide-r-title">
                    <h4>
                        Sidebars
                    </h4>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>

                    <div className="col-xl-12 col-lg-12">
                        <div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

                    </div>
                </div>
            </div>


            <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
                <div className="slide-r-title">
                    <h4>
                        Add OEM Item
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                        <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Code *</label>
                                <input
                                    type="text"
                                    name="oem_code"
                                    value={this.state.oem_code}
                                    className="form-control"
                                    onChange={this.changeHandler.bind(this)} />
                            </div>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Name *</label>
                                <input
                                    type="text"
                                    name="oem_name"
                                    value={this.state.oem_name}
                                    className="form-control"
                                    onChange={this.changeHandler.bind(this)} />
                            </div>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Industry Type *</label>
                                <input
                                    type="text"
                                    name="industry_type"
                                    value={this.state.industry_type}
                                    className="form-control"
                                    onChange={this.changeHandler.bind(this)} />
                            </div>


                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>



            <div className={"slide-r " + (this.state.sliderUpdate)} style={{ overflow: "auto" }}>
                <div className="slide-r-title">
                    <h4>
                        Update OEM Item
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                        <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Code *</label>
                                <input
                                    type="text"
                                    name="oem_code"
                                    value={this.state.oem_code}
                                    className="form-control"
                                    onChange={this.changeHandler.bind(this)} />
                            </div>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Name *</label>
                                <input
                                    type="text"
                                    name="oem_name"
                                    value={this.state.oem_name}
                                    className="form-control"
                                    onChange={this.changeHandler.bind(this)} />
                            </div>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Industry Type *</label>
                                <input
                                    type="text"
                                    name="industry_type"
                                    value={this.state.industry_type}
                                    className="form-control"
                                    onChange={this.changeHandler.bind(this)} />
                            </div>


                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>

            <div className={"slide-r " + (this.state.sliderBulk)} style={{ overflow: "auto" }}>
                <div className="slide-r-title">
                    <h4>
                        Bulk Upload
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                    </h4>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                        <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload}>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File *</label>
                                <input
                                    type="file"
                                    name="uploadfile"
                                    className="form-control"
                                    onChange={this.changeFileHandler} />
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                            <p style={{backgroundColor :'#bcd3f7'}}>*Note: Only status of <span style={{fontSize: '12px', fontWeight : 'bold',color:"#ff0000"}}><br/>Home Stop<br/> Break Down<br/> Without Driver<br/>Available For Load<br/>Empty Load Travelling <br/></span> is accepted under current status.</p>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/sample_current_status_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                        </form>


                    </div>
                </div>
            </div>


            <div className={"dataLoadpage " + (this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


        </div>

    );
}
}