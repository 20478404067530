/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
// eslint-disable-next-line strict
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "../layouts/updatelmstates";
import UpdateTransitButton from "../layouts/updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CountUp from 'react-countup';

import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils';
// const $ = window.$;
import ExportPDF from "./exportpdf"
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class CurrentGeofences extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            pageTitle: "Transporter GPS Installation Status",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            radius: '15',
            rlatitude: '',
            rlongitude: '',
            latitude: '',
            longitude: '',
            defaultradius: 0,
            coordinates: [],
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            originalrowData: [],
            selectedTabLat: '',
            selectedTabLng: '',
            cluster_centroids: [],
            deftab: 'all',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            frameworkComponents: {
                UpdateButton: UpdateButton,
                UpdateTransitButton: UpdateTransitButton,
            },
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,

            showPlant: 'show-n',
            showAddr: 'show-n',
            request_type: '',
            view_type: '',
            tabplants: [],
            clickabletab: '',
            plantlist: [],
            stateFormData: "",
            sliderStateEmailsTranslate: "",
            overly: "show-n",
            rowData: [],
            pdfRdata: [],
            pdfColumns: [],
            rakeslist: [],
            loadingpoints: [],
            destinationpoints: [],
            userType: "",
            pinnedRegionBottomRowData: [],
            pinnedTransitBottomRowData: [],
            stockData: [],
            totalStock: 0,
            totalSales: 0,
            totalDispatches: 0,
            railShare: 0,
            totalFySales: 0,
            totalFyDispatches: 0,
            railFyShare: 0,
            pendingForDispatchStock: 0,
            transitStock: 0,
            unloadingUpdatedDate: "",
            intransitUpdatedDate: "",
            screenView: "",
            todayInstalled: [],
            totalInstalled: [],
            pendingData: [],
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            tpttrucks: [],
            adaniCheck: 0,
            current_delete_data_id : "",
        };
    }
    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
        if(this.state.basicType=="success"||this.state.basicType1 == "success"){
            window.location.reload()
        }

    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                // console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    async componentDidMount() {
        var tpt_code = localStorage.getItem('transportercode')
        if(!localStorage.getItem('roles') == "SITE_ADMIN"&& !["undefined" , "" , null , undefined].includes(tpt_code)){
            var tptcode = tpt_code
        }else{
            var tptcode = ""
        }
        redirectURL.post("dashboard/getCurrntGeofenceData",{transporter_code:tptcode}).then (response => {
            console.log(response.data , "records")
            var records = response.data
            this.setState({
                rowData:records
            })
        })
        
        this.getUserGridState()
    }
    getUserGridState() {
        redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
            .then(async (resp) => {
                console.log("resp ", resp)
                this.setState({
                    usergridstate: resp.data
                });
                this.restoreGridStates();
            })
    }
    onClickHideAll() {
        this.setState({
            overly: "show-n",
            showTrucksList: "show-n",
            sliderRouteTranslate: '',
        })
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onGridReady1 = params => {
        this.gridApi1 = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;
    };
    
    
    onClickSaveGridState() {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Save Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();

        let screenpage = 'Registered Devices';



        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: screenpage
        }
        //console.log("reqparams ", reqparams)
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                //console.log("State response ",response.data)
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }

    restoreGridStates() {
        ///console.log("this.state.usergridstate ", this.state.usergridstate)
        try {
            if (this.state.usergridstate.length > 0) {
                var windowstates = this.state.usergridstate;
                console.log("windowstates[0].gridcolumns ", windowstates[0].gridcolumns)
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

            }
        }
        catch (e) { }
    }


    resetState = () => {
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventGridAction,
                "label": "Reset Grid Layout",
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        //console.log('column state reset');
        var reqparams = {
            userId: localStorage.getItem("userid"),
            screenurl: window.location.pathname,
            screentitle: this.state.screenpage
        }

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })

    };

    hideAlert = () => {
        this.setState({
            show1:false
        })
    }

    deleteCurrentGeofenceData = (params) =>{
        if(params.colDef.field == "delete"){
            console.log(params.data)
            this.setState({
                show1:true, 
                basicType1:"warning",
                basicTitle1:"Do want to Delete Data ?",
                current_delete_data_id : params.data._id,
            })
        }
    }

    onClickDeleteData = () => {
        if(this.state.current_delete_data_id!=""){
            var params = {
                row_id : this.state.current_delete_data_id
            }
            console.log(params)
            redirectURL.post("dashboard/deleteCurrentGeofenceData" , params).then(response => {
                if(response.data.status == 'success'){
                    this.setState({
                        show:true,
                        basicType:'success',
                        basicTitle:"Successfully Deleted the Data",
                        show1:false,
                        current_delete_data_id:"",
    
                    })
                }else{
                    this.setState({
                        show:true,
                        basicType:'danger',
                        basicTitle:"Data not Deleted",
                        show1:false,
                        current_delete_data_id:"",
                    })
                }
            })
        }
    }


    render() {
        var columnWithDefs = [
            {
                headerName: "Delete",
                field: "delete",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
                cellRenderer:function(params){
                    let basestring = params.data._id;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<a class="custom-btn label label-success"><i class="icofont icofont-trash"></i>Delete</a>';
                    return htmloption;
                }
            },
            {
                headerName: "Geo Fence Name",
                field: "geo_fence_aliasname",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Transporter Name',
                width: 160,
                field: 'transporter_code',
                filter: true,
            },
            {
                headerName: 'Geo Fence Address',
                field: "geo_fence_address",
                width: 200,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: "Geo Fence Lattitude",
                field: "geo_fence_latitude",
                width: 160,
                filter: true,
                resizable: true,
                sortable: true,
            },
            {
                headerName: 'Geo Fence Lattitude',
                width: 160,
                field: 'geo_fence_longitude',
                filter: true,
            },
            

        ];

        return (

            <div className={(this.state.screenView == "Mobile") ? "" : "container-fluid"} style={(this.state.screenView == "Mobile") ? { marginTop: "2em" } : {}}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    showCancel
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    cancelBtnBsStyle="default"
                    onCancel={this.hideAlert}
                    onConfirm={this.onClickDeleteData}>
                </SweetAlert>
                <div className={"col-xl-12 col-lg-12 col-sm-12 beffect "}>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h4>
                            Geofences
                            <span className="layoutbtns pull-right">
                                <button className="float-right custom-btn white btn-danger"
                                    style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.onClickSaveGridState.bind(this)}>
                                    <i className="icofont icofont-save"></i> Save Grid Layout
                                </button>
                                <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.resetState.bind(this)}>
                                    <i className="icofont icofont-refresh"></i> Reset Default Layout
                                </button>
                            </span>

                            {/* {(this.state.rowData.length > 0) ?
                                <ExportPDF rowData={this.state.pdfRdata} pagetitle = "Transporter GPS Installation Status" pdfColumns={this.state.pdfColumns} />
                                : ""} */}
                        </h4>
                    </div>
                    <div className={(this.state.screenView == "Mobile") ? "card-body" : "card-body col-xl-12 col-lg-12"}>

                        <div id="myGrid" style={{ width: "100%", height: "350px" }} className={"ag-theme-balham"}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnWithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={50}
                                pagination={true}
                                enableRangeSelection={true}
                                gridOptions={{
                                    context: { componentParent: this },
                                }}
                                onCellClicked={this.deleteCurrentGeofenceData.bind(this)}
                                // components={this.state.components}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

            </div>

        );
    }
}


function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    // console.log(date.length);
    // console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
