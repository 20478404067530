
import React from 'react';
// import ReactDOM from 'react-dom';
// var redirectURL = require('../redirectURL');
// var moment = require('moment');
 
const DeleteItem = (props) => {
    //console.log("props ", props)
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.deleteRowHandler(props);
    };
   
    return (
        <div>
          			<button onClick={handleClick} className="custom-btn label label-success"><i className="icofont icofont-trash"></i>Delete</button>

                {/* <button
                    style={{border:"0px", backgroundColor:"transparent"}}
                    type="button"
                    className="btn btn-default"
                    onClick={handleClick} >
                        Delete
                </button> */}            
          
        </div>
    );
};

export default DeleteItem;
