/* eslint-disable no-eval */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import CSVFileValidator from 'csv-file-validator';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
//import VicinityCounter from "./vicinityCounter";
import Constant from "../common/googlemapkey";

var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require("moment");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords = [];
var map;
var doOnce = true;
var rad = function (x) {
	return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;
var consigners = [];
export default class TptEmptyTrucksVicinity extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			pageTitle: "Carrier Arrival Status",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform: 'show-m',
			loadshow: 'show-n',
			showrefresh: 'show-n',
			sliderTranslate: '',
			showdata: this.displayData,
			modules: AllModules,
			radius: '15',
			rlatitude: '',
			rlongitude: '',
			latitude: '',
			longitude: '',
			defaultradius: 0,
			coordinates: [],
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			contentString: "testing",
			mwidth: "col-xl-12 col-lg-12",
			dept_code: '',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: editAdmin,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat: '',
			selectedTabLng: '',
			cluster_centroids: [],
			deftab: 'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single",
			context: { componentParent: this },

			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,

			plant_code: '',
			showPlant: 'show-n',
			showAddr: 'show-n',
			request_type: '',
			view_type: '',
			tabplants: [],
			clickabletab: '',
			plantlist: [],
			stateFormData: "",
			sliderStateEmailsTranslate: "",
			overly: "show-n",
			showStateTrucksMsg: "show-n",
			states_list: [],
			selectedState: [],
			showStates: "show-n",
			showRadius: "show-m",
			usermanualmodal: false,
			tabslist: [],
			type1: [],
			type2: [],
			type3: [],
			type4: [],
			tabs: [],
			selectedTab: "",
			plant_wise_data: [],
			max_vicinity: "",
			selectedPlant: [],
			summaryRowData: [],
			columntype: "",
			bulkUploadData: [],
			uploadData: [],
			rowClassRules: {
				"subtotalrow": "data.plant_name.includes('Total')"
			},
			screenType: "",
			bulkuploadopen: false,
			hideForm: "show-m",
			hideUploadStatusGrid: "show-n",
			screenView: "",
			transporterTrucks: [],
			tpttrucks: [],
			adaniCheck: 0,
		};
		this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
	}
	/*Alert Popups*/

	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
		var tptCode = localStorage.getItem('transportercode');
		if (tptCode.includes("T293") == true) {
			var adaniCheck = 1;
		}
		else {
			var adaniCheck = 0;
		}
		this.setState({
			adaniCheck: adaniCheck
		});
		var vehicle_group = localStorage.getItem("vehicle_group");
		if (vehicle_group != "") {
			var tparms = {
				transporter_code: tptCode,
				region: vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
				.then((response) => {
					var records = response.data.records;
					console.log("records ", records)
					this.setState({
						tpttrucks: records
					})
				})
		}

		if (is_enmovil_transporter == 1) {
			// console.log(moment().format("YYYY-MM-DD HH:MM:SS"),'moment().format("YYYY-MM-DD HH:MM:SS")')
			var dept_code = "SNDG";
			var hText = 'All';
			var screenType = "user";
			var screenView = "";
			var transporter_code = [];
			var tptCode = [];
			if (localStorage.getItem('transportercode') != undefined) {
				var tptCode = JSON.parse(localStorage.getItem("transportercode"));
				//transporter_code.push(tptCode[0]);
				var transporter_code = JSON.stringify(tptCode);
			}
			this.setState({
				dept_code: dept_code,
				screenType: screenType,
				screenView: screenView,
				transporter_code: tptCode,
			});

			var deviceparams = {
				transporter_code: localStorage.getItem("transportercode")
			}
			redirectURL.post("/dashboard/carrierDevices", deviceparams).then(async (response1) => {
				// console.log("response1 ",response1)
				var transporterTrucks = response1.data.devices;
				if (response1.data.devices.length > 0) {
					response1.data.devices.map(function (e) {
						transporterTrucks.push(e)
					})
				}

				this.setState({
					transporterTrucks: transporterTrucks
				})
			})

			if (vehicle_group != "" && vehicle_group != undefined) {
				var vgroup = JSON.stringify([vehicle_group]);
			}
			else {
				var vgroup = "0";
			}

			redirectURL.post("/dashboard/tptcarrierArrivalsReport", { "dept_code": dept_code, "transporter_codes": transporter_code, group_codes: vgroup }).then((response) => {
				var totalcount = {}
				var summarydata = eval(eval(response.data).carrier_arrival)
				summarydata = summarydata.sort(function (a, b) { return a['sort_order'] - b['sort_order']; });
				var keys = ["Arrived", "Not Arrived", "200 - 400 KMS", "60 - 200 KMS"];
				// var keys = Object.keys(summarydata[0])
				var pinnedrow = { "plant_name": "Total" };
				var type1 = 0;
				var type2 = 0;
				var type3 = 0;
				var type4 = 0;
				var type5 = 0;
				var rowTotal = 0;
				summarydata.map(function (e) {
					var total = 0;
					keys.map(function (k) {
						if (e[k] != undefined && e[k] != "") {
							total = total + e[k];
						}
						else {
							total = total + 0
						}
					})
					e['total_count'] = total;
					if (e['Arrived'] != undefined && e['Arrived'] != "") {
						type1 = type1 + e['Arrived']
					} else { type1 = type1 + 0 }
					if (e['Not Arrived'] != undefined && e['Not Arrived'] != "") {
						type2 = type2 + e['Not Arrived']
					} else { type2 = type2 + 0 }
					if (e['60 - 200 KMS'] != undefined && e['60 - 200 KMS'] != "") {
						type3 = type3 + e["60 - 200 KMS"]
					} else { type3 = type3 + 0 }
					if (e['200 - 400 KMS'] != undefined && e['200 - 400 KMS'] != "") {
						type4 = type4 + e["200 - 400 KMS"]
					} else { type4 = type4 + 0 }
					// type5 = type5 + e['300 - 400 KMS']
					rowTotal = rowTotal + e['total_count']
				})
				pinnedrow["Arrived"] = type1;
				pinnedrow["Not Arrived"] = type2;
				pinnedrow['60 - 200 KMS'] = type3;
				pinnedrow['200 - 400 KMS'] = type4;
				// pinnedrow['300 - 400 KMS'] = type5;
				pinnedrow['total_count'] = rowTotal;
				summarydata.push(pinnedrow)
				this.setState({
					summaryRowData: summarydata
				})
			}).catch((e) => { console.log(e) })

			let pageTitle = "Carrier Arrival Status";
			this.setState({ pageTitle: pageTitle });

			this.logPageView(); // Log page view to GA
		}
		else {
			window.location.href = "/";
		}
	}

	renderMap = () => {
		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}


	onChangePlantCode = async plant_code => {
		//console.log("Select", event.target.value);
		await this.setState(
			{ plant_code },
			() => console.log(`Option selected:`, this.state.plant_code)
		);

	}

	selectOptionsItems() {
		let items = [];
		var consigners = [];
		//console.log("this.state.dept_code ", this.state.dept_code)

		if (this.state.dept_code == 'SNDG' || this.state.dept_code == 'sndg') {
			var keys = ["B", "H", "G", "I", "N"];

			// An array of values 
			var values = ["TVP Bangalore", "Haryana", "Gujarat", "Siliguri", "TVP Nagpur"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}


		else if (this.state.dept_code == 'LOG-PRT' || this.state.dept_code == 'log-prt') {
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN", "RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"];

			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore", "RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}


		else if (this.state.dept_code == 'LOG-TNP' || this.state.dept_code == 'log-tnp') {
			var keys = ["MSIL-G", "MSIL-M", "MSILMC", "MSILME", "SMG"];

			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting", "Power Train", "SMG Gujarat"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}

		//console.log("consigners ", consigners)
		//items.push({value:'all',label:'All'}); 
		consigners.forEach(item => {
			items.push({ value: item.code, label: item.value });
		});

		return items;
	}

	submitFormHandler(formdata) {
		var urlpath = '/consignments/emptytrucksvicinitydata';
		redirectURL.post(urlpath, formdata)
			.then(
				async (response) => {
					var plant_wise_data = JSON.parse(response.data.records).plant_wise_data;
					var cluster_centroids = JSON.parse(response.data.records).cluster_centriods;
					var type1 = eval(plant_wise_data[Object.keys(plant_wise_data)[0]]['0 - 60 KMS']);
					var type2 = eval(plant_wise_data[Object.keys(plant_wise_data)[0]]['60 - 200 KMS']);
					var type3 = eval(plant_wise_data[Object.keys(plant_wise_data)[0]]['200 - 300 KMS']);
					var type4 = eval(plant_wise_data[Object.keys(plant_wise_data)[0]]['300 - 400 KMS']);

					this.setState({
						rowData: type1,
						type1: type1,
						type2: type2,
						type3: type3,
						type4: type4,
						cluster_centroids: cluster_centroids,
						tabs: Object.keys(plant_wise_data),
						selectedTab: Object.keys(plant_wise_data)[0],
						overly: "show-n",
						loadshow: "show-n",
						plant_wise_data: plant_wise_data,
						max_vicinity: 60,
					})
					window.initMap = await this.initMap
					await this.renderMap();
				})
			.catch(function (error) {
				console.log(error);
			});
	}

	async getPlantData(plant) {
		this.setState({
			overly: "show-m",
			loadshow: "show-m"
		})
		var type1 = eval(this.state.plant_wise_data[plant]['0 - 60 KMS']);
		var type2 = eval(this.state.plant_wise_data[plant]['60 - 200 KMS']);
		var type3 = eval(this.state.plant_wise_data[plant]['200 - 300 KMS']);
		var type4 = eval(this.state.plant_wise_data[plant]['300 - 400 KMS']);

		this.setState({
			rowData: type1,
			type1: type1,
			type2: type2,
			type3: type3,
			type4: type4,
			selectedTab: plant,
			overly: "show-n",
			loadshow: "show-n",
		})
		window.initMap = await this.initMap
		await this.renderMap();
	}

	formHandler = (event) => {
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m',
		});
		var plantcodes = [];
		if (this.state.plant_code.length > 0) {
			try {
				this.state.plant_code.forEach(item => {
					if (item.value == "H") {
						plantcodes.push("F")
						//plantcodes.push("M")
					}
					else {
						plantcodes.push(item.value)
					}

				})
			} catch (e) { }

		}
		var formdata = {
			plant_codes: plantcodes,
			dept_code: this.state.dept_code.toUpperCase()
		}
		// console.log($("#gps_record_flag").prop("checked"))
		if ($("#gps_record_flag").prop("checked") == true) {
			formdata['gps_records_flag'] = 1;
		}
		else {
			formdata['gps_records_flag'] = 0;
		}
		this.setState({
			overly: "show-m",
			loadshow: "show-m"
		})
		this.submitFormHandler(formdata);

	}

	initMap = () => {
		var currentwindow;
		var maxvicinity = this.state.max_vicinity;
		var infowindow = new window.google.maps.InfoWindow();
		var mapOptions = {
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['satellite', 'roadmap'],
			},
			center: new window.google.maps.LatLng(28.4519751, 77.0310713),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};
		var bounds = new window.google.maps.LatLngBounds();
		map = new window.google.maps.Map(document.getElementById('map_canvas'), mapOptions);
		if (this.state.rowData.length > 0) {
			this.state.rowData[0].plant_coordinates.map(function (e) {
				var coords = JSON.parse(e.plant_coordinates)
				var pmarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(coords.lat, coords.lng),
					icon: require('../../assets/icons/business.png'),
					map: map,
				});
				window.google.maps.event.addListener(pmarker, 'click', (function (pmarker) {
					return function () {
						//	console.log(clusters[0])

						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = ""
						contentarr.push({ "key": "Plant", "value": e.plant_name })
						var contentString = infoBox(pmarker.icon, header, contentarr, '')

						infowindow.setContent(contentString);
						infowindow.open(map, pmarker);
					}
				})(pmarker));
				console.log(maxvicinity, "maxvicinity")
				const cityCircle = new window.google.maps.Circle({
					strokeColor: "#71caab",
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: "#71caab",
					fillOpacity: 0.35,
					map,
					center: { lat: coords.lat, lng: coords.lng },
					radius: maxvicinity * 1000
				});
			})

		}
		var adaniCheck = this.state.adaniCheck;
		this.state.rowData.map(function (e) {
			console.log("Marker ", e)
			bounds.extend(new window.google.maps.LatLng(e.latitude, e.longitude));
			if (adaniCheck == 1) {
				if (e.speed > 20) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/shuttle_green.png');
				}
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(e.latitude, e.longitude),
					icon: image,
					map: map,
				});
			}
			else {
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(e.latitude, e.longitude),
					icon: require('../../assets/icons/truck-end.png'),
					map: map,
				});
			}
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])

					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					var header = ""
					contentarr.push({ "key": "Speed", "value": e.speed })
					try {
						console.log("e.device_address ", e.device_address)
						if (e.device_address != undefined) {
							var deviceaddr = e.device_address
						}
						else {
							var deviceaddr = ""
						}

						if (e.device_city != undefined) {
							var devicecity = e.device_city
						}
						else {
							var devicecity = ""
						}

						if (e.device_state != undefined) {
							var deviceste = e.device_state
						}
						else {
							var deviceste = ""
						}
						contentarr.push({ "key": "Address", "value": deviceaddr })
						contentarr.push({ "key": "City", "value": devicecity })
						contentarr.push({ "key": "State", "value": deviceste })

					}
					catch (e) { }

					// contentarr.push({"key":"Area", "value": e.area})
					// contentarr.push({"key":"State", "value": e.state})
					contentarr.push({ "key": "Distance From Coordinates", "value": e.distance_from_coordinates })
					contentarr.push({ "key": "Distance Range", "value": e.distance_range })
					var contentString = infoBox(marker.icon, header, contentarr, '')

					infowindow.setContent(contentString);
					infowindow.open(map, marker);
				}
			})(marker));

		})
		map.fitBounds(bounds)

	}



	onClickHideAll() {
		this.setState({
			overly: "show-n",
			sliderStateEmailsTranslate: "",
			columntype: ""

		})
	}



	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};

	onShowMap(e) {
		if (e.colDef.field == "map") {
			var bounds = new window.google.maps.LatLngBounds();
			bounds.extend(new window.google.maps.LatLng(e.data.latitude, e.data.longitude))
			map.fitBounds(bounds);
		}
		else if (e.colDef.field == "update") {
			var rowdata = e.data;
			console.log("rowdata ", rowdata)
			delete rowdata['plant_coordinates']
			if (rowdata['timestamp'].includes("T")) {
				rowdata['timestamp'] = rowdata['timestamp'].split("T")[0] + " " + rowdata['timestamp'].split("T")[1].split(".")[0]

			}
			if (rowdata['report_generated_at'].includes("T")) {
				rowdata['report_generated_at'] = rowdata['report_generated_at'].split("T")[0] + " " + rowdata['report_generated_at'].split("T")[1].split(".")[0]
			}
			redirectURL.post("/dashboard/updateTruckCarrierData", { "rowdata": rowdata }).then((response) => {
				this.setState({
					basicTitle: "Updated Successfully",
					basicType: "success",
					show: true
				})
			}).catch((e) => { console.llog(e) })
		}

		// map.setZoom(21) 
	}
	onClickCounterShowData(type) {
		if (type == "type1") {
			var max_vicinity = 60
		}
		if (type == "type2") {
			var max_vicinity = 200
		}
		if (type == "type3") {
			var max_vicinity = 300
		}
		if (type == "type4") {
			var max_vicinity = 400
		}
		this.setState({
			rowData: this.state[type],
			max_vicinity: max_vicinity
		})
		this.renderMap()
	}
	async showCompleteData(cell) {
		// console.log(cell)
		if (cell.colDef.field != "plant_name" && cell.colDef.field != "remarks"
			&& cell.colDef.field != "approval_time" && cell.colDef.field != "approve") {
			this.setState({
				overly: "show-m",
				loadshow: "show-m"
			});
			var distance_range = cell.colDef.field
			if (cell.data.plant_name == "Total" || (distance_range == "total_count" && cell.data.plant_name == "Total")) {
				var plant = [];
				this.state.summaryRowData.map(function (e) {
					plant.push(e.plant_name)
				})
			} else {
				var plant = [cell.data.plant_name];
			}
			var truck_arrived = ""
			if (distance_range == "total_count") {
				distance_range = "ALL"
			}
			else if (distance_range == "Arrived" || distance_range == "Not Arrived") {
				if (distance_range == "Arrived") { this.setState({ "columntype": "Arrived" }) }
				distance_range = "0 - 60 KMS"
			}
			redirectURL.post("/dashboard/getTptPlantCarrierData", {
				"transporter_code": this.state.transporter_code, "plant_name": plant,
				"distance_range": distance_range, "type": this.state.screenType
			})
				.then(async (response) => {
					var rowdata = response.data;
					console.log(rowdata, "rowdata");

					if (cell.colDef.field == "Arrived") {
						rowdata = rowdata.filter(function (e) { return e.truck_arrived == 1 })
					}
					if (cell.colDef.field == "Not Arrived") {
						rowdata = rowdata.filter(function (e) { return e.truck_arrived == 0 })
					}
					var recordsarr = []
					// console.log("this.state.transporterTrucks ", this.state.transporterTrucks)
					if (rowdata.length > 0) {
						recordsarr = await rowdata.filter(f =>
							this.state.transporterTrucks.filter((d) => {
								if (d.truck_no == f.truck_no) {
									// console.log("API ", d)
									if (d.registereddevices != undefined) {
										if (d.registereddevices.length > 0) {
											f.device_address = d.registereddevices[0].address
											f.device_city = d.registereddevices[0].city
											f.device_state = d.registereddevices[0].state
										}
									}
									return f;
								}
							})
						);
					}
					// console.log("recordsarr ", recordsarr)
					var tpttrucks = this.state.tpttrucks;
					// console.log("tpttrucks ",tpttrucks)
					var tptunqrecords = []
					if (tpttrucks.length > 0) {
						await recordsarr.filter(f =>
							tpttrucks.filter((d) => {
								if (d.truck_no == f.truck_no) {
									// console.log("FFFFF ", f)									
									tptunqrecords.push(f)
								}
							})
						);
					}
					else {
						tptunqrecords = recordsarr
					}
					// console.log("tptunqrecords ", tptunqrecords)
					await this.setState({
						loadshow: "show-n",
						rowData: tptunqrecords,
						sliderStateEmailsTranslate: "slider-translate-90p"
					});
					var distance_ranges = ["0 - 60 KMS", "60 - 200 KMS", "200 - 400 KMS", "ALL"];
					if (distance_ranges.indexOf(distance_range) >= 0) {
						var type = distance_ranges.indexOf(distance_range);
						if (type == 0) {
							var max_vicinity = 60
						}
						if (type == 1) {
							var max_vicinity = 200
						}
						if (type >= 2) {
							var max_vicinity = 400
						}


					}

					this.setState({
						max_vicinity: max_vicinity
					})
					window.initMap = this.initMap
					this.renderMap();
				}).catch((e) => {
					console.log(e)
				})

		}
		else if (cell.colDef.field == "approve" && cell.data.plant_name != "Total") {
			var query = {
				dept_code: this.state.dept_code,
				plant_code: cell.data.plant_code,
				approved_user: localStorage.getItem("userid"),
				approver_email: localStorage.getItem("email"),
			}
			redirectURL.post("/dashboard/updateApprovalForCarriers", query).then((response) => {
				this.setState({
					basicTitle: "Successfully Approved.",
					basicType: "success",
					show: true
				})
			})
		}
	}
	updateRemarksForCarriers(cell) {
		redirectURL.post("/dashboard/updateRemarksForCarriers", { "remarks": cell.value, "plant_name": cell.data.plant_name }).then((response) => {
			console.log("updated.!!")
		})
	}
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				console.log(csvData)
				this.setState({
					bulkUploadData: csvData.data
				});
			})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
		});
		console.log(contentList);
		this.setState({
			file: e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);
		console.log(e.target.files[0].name, "e.target.files[0].type")
		if (e.target.files[0].name.split(".")[1] == 'csv') {
			// console.log(fileData);
			// console.log(typeof(fileData))

		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				file: "",
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}

	}
	onCloseModal = () => {
		this.setState({
			open: false,
			bulkuploadopen: false
		});
	};
	showUploadModal() {
		this.setState({
			open: false,
			bulkuploadopen: true,
			hideForm: "show-m",
			hideUploadStatusGrid: "show-n"
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			sliderStateEmailsTranslate: "",
			overly: 'show-n'
		});
	}
	onUploadSubmit(e) {
		e.preventDefault();
		var trucksdata = []
		this.state.bulkUploadData.map(function (e) {
			trucksdata.push(e['truck_no'])
		})
		var params = {
			"trucksData": JSON.stringify(trucksdata),
			"dept_code": this.state.dept_code
		}
		console.log(params)
		redirectURL.post("/dashboard/uploadTrucksInCarrierArrivals", params).then((response) => {
			console.log(JSON.parse(response.data.uploadStatus))
			this.setState({
				uploadData: JSON.parse(response.data.uploadStatus),
				hideForm: "show-n",
				hideUploadStatusGrid: "show-m"
			})
		}).catch((e) => { console.log(e) })
	}
	render() {
		var editAdmin = (this.state.screenType == "admin") ? false : true;
		// var showAdmin = (this.state.screenType == "admin")?true:false;
		var showonlysnd = (this.props.match.path == "/sndemptytruckvicinity" || this.props.match.path == "/sndemptytruckvicinitymgmt") ? false : true;
		var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if (adaniCheck == 1) {
			tkName = "Shuttle No";
		}
		var columnDefs = [
			{
				headerName: "",
				field: "map",
				width: 60,
				cellRenderer: function (params) {
					return "<i class='icofont icofont-map-pins f25'></i>"
				},
				editable: false
			},
			{
				headerName: tkName,
				field: "truck_no",
				width: 120,
				editable: editAdmin
			},
			// {
			// 	headerName: "Transporter Name",
			// 	field: "transporter_name",
			// 	width: 130,
			// 	editable: editAdmin
			// },
			{
				headerName: "Plant Code",
				field: "plant_code",
				width: 100,
				editable: editAdmin
			},
			{
				headerName: "Segment",
				field: "segment",
				width: 110,
				editable: editAdmin,
				hide: showonlysnd,
				valueGetter: function (params) {
					if (params.data.segment == "P") {
						return "Primary"
					}
					else {
						return "Secondary"
					}
				}
			},
			{
				headerName: "Distance From Co-ordinates (KMS)",
				field: "distance_from_coordinates",
				width: 160,
				editable: editAdmin,
				valueGetter: function (params) {
					return Math.round(params.data.distance_from_coordinates)
				}
			},
			// {
			// 	headerName: "Actual LSP User",
			// 	field: "actual_lspuser",
			// 	width: 130,
			// 	valueGetter: function(params){
			// 		return "Enmovil";
			// 	},	
			// 	editable: editAdmin
			// },

			{
				headerName: "Address",
				field: "device_address",
				width: 200,
				editable: false
			},
			{
				headerName: "City",
				field: "device_city",
				width: 130,
				editable: false
			},
			{
				headerName: "State",
				field: "device_state",
				width: 130,
				editable: false
			},

			// {
			// 	headerName: "Area",
			// 	field: "area",
			// 	width: 130,
			// 	editable: editAdmin
			// },
			// {
			// 	headerName: "State",
			// 	field: "state",
			// 	width: 130,
			// 	editable: editAdmin
			// },
			{
				headerName: "Speed",
				field: "speed",
				width: 130,
				editable: editAdmin
			},
			{
				headerName: "Distance Range",
				field: "distance_range",
				width: 130,
				editable: editAdmin
			},
			// {
			// 	headerName: "Manual Insertion",
			// 	field: "added_from_bulk",
			// 	width: 120,
			// 	valueGetter: function(params){
			// 		if(params.data.added_from_bulk == 1)
			// 		{
			// 			return "Yes";
			// 		}
			// 		else
			// 		{
			// 			return "No";
			// 		}
			// 	}
			// },
			// {
			// 	headerName: "",
			// 	field: "update",
			// 	width:"130",
			// 	cellRenderer: function(params){
			// 		return "<button class='btn btn-success' style='padding:0px 18px 1px 20px'>Update</button>"
			// 	},
			// 	hide: showAdmin
			// }

		];
		var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if (adaniCheck == 1) {
			tkName = "Shuttle No";
		}
		const summarycolumns = [

			{
				headerName: "Plant Name",
				children: [
					{
						headerName: "",
						field: "plant_name",
						width: 130,
						resizable: true,
						editable: false
					}
				]
			},
			{
				headerName: "0 - 60 Kms",
				children: [
					{
						headerName: "Transporter Arrival Marked",
						field: "Arrived",
						width: 130,
						resizable: true,
						editable: false
					}, {
						headerName: "Not Arrived",
						field: "Not Arrived",
						width: 130,
						resizable: true,
						editable: false
					}
				]
			},
			{
				headerName: "60 - 200 KMS",
				children: [
					{
						headerName: "",
						field: "60 - 200 KMS",
						width: 130,
						resizable: true,
						editable: false
					}
				]
			},
			{
				headerName: "200 - 400 KMS",
				children: [
					{
						headerName: "",
						field: "200 - 400 KMS",
						width: 130,
						resizable: true,
						editable: false
					}
				]
			}
			,
			// {
			// 	headerName: "300 - 400 Kms",
			// 	children : [
			// 		{
			// 			headerName: "",
			// 			field: "300 - 400 KMS",
			// 			width: 130,
			// 			resizable: true,
			// 			editable: false
			// 		}
			// 	]
			// },
			{
				headerName: "Total",
				children: [
					{
						headerName: "",
						field: "total_count",
						width: 130,
						cellClass: "cellstylegridG"
					}
				]

			},
		]
		const uploadColumns = [
			{
				headerName: tkName,
				field: "truck_no",
				width: 120,
				filter: true
			},
			{
				headerName: "Status",
				field: "status",
				width: 200,
				filter: true
			}
		]
		const dStyles = {
			width: '48%',
			height: '500px'
		}
		// const btstye={
		// 	marginBottom:"10px"
		// }

		return (

			<div className="container-fluid" style={(this.state.screenView == "Mobile") ? { marginTop: "2em" } : {}}>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					{/* <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
						<div className="row">
							
							<div className={"form-group col-xl-6 col-lg-6 "}>
									<label className="c-lbl">Select Plant *:</label>
									<Select 
									closeMenuOnSelect={false} 
									name="plant_code" 
									isMulti={true} 
									placeholder="Select Plant" 
									className="rselect" 
									value={this.state.plant_code} 
									onChange={this.onChangePlantCode.bind(this)} 
									options={this.selectOptionsItems()} 
								/>
								
							</div>
								<div className="form-group  col-xl-3 col-lg-3">
								<br />
								<br />
								<label className="c-lbl"><input type="checkbox" id="gps_record_flag" /> &nbsp;&nbsp;Trucks with GPS (24 Hrs)</label>
							</div>
							<div className="form-group col-xl-2 col-lg-2">
								<button type="submit" style={{marginTop:"35px"}} className={"btn btn-success "}>Submit</button>
							</div>
						</div>
						
	
					</form> */}
					{/* <div className={"col-xl-12 col-lg-12 row "}>
						{(this.state.tabs.length > 0)?
							<ul className="col-xl-10 col-lg-10 float-left  mpatabs">
								{this.state.tabs.map((item) =>
									<li className={"btn "+(this.state.selectedTab == item?"btn-success":"")} onClick={this.getPlantData.bind(this,item)}
									style={{float:"left", padding:"10px", cursor:"pointer", background:"#eee",margin:"0px 0px 0px 5px"}} >{item}</li>
								)}
							</ul>
							:""}
					</div> */}
					{/* <div className="col-xl-12 col-lg-12">
						<VicinityCounter 
							context={this}
							type1={this.state.type1.length}
							type2={this.state.type2.length}
							type3={this.state.type3.length}
							type4={this.state.type4.length}
						/>
					</div> */}
					<div className="col-xl-12 col-lg-12">
						<div className="card">

							<div className="card-header">
								<h5 style={{ float: "left", width: "87%" }}>
									<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>

								</h5>
								{(this.state.screenType == "admin" && this.state.screenView != "Mobile") ?
									<button className="btn btn-warning" style={{ padding: "0px 33px 5px 44px" }} onClick={() => { window.location.href = "/managementdashboard" }}><i className="icofont icofont-left-arrow f25"></i>Go Back</button>
									: ""
								}

							</div>
							<div className="card-body row">

								<div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={summarycolumns}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.summaryRowData}
										enableCharts={false}
										enableRangeSelection={false}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										gridOptions={{
											context: { componentParent: this }
										}}
										floatingFilter={false}
										onCellClicked={this.showCompleteData.bind(this)}
										rowClassRules={this.state.rowClassRules}
										onCellEditingStopped={this.updateRemarksForCarriers.bind(this)}
									//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									/>
								</div>

							</div>
						</div>
					</div>
				</div>
				{this.state.sliderStateEmailsTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderStateEmailsTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Complete Data
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body row" style={{ position: "relative" }}>
							<div className="col-xl-6 col-lg-6">
								{/* <div className="col-xl-12 col-lg-12" style={{"textAlign": "right"}}>
										{(this.state.columntype == "Arrived" && this.state.screenType == "user")?
											<button className="btn btn-success" onClick={this.showUploadModal.bind(this)}>Bulk Upload</button>
										:""}
									</div> */}
								<div id="myGrid" style={{ height: "520px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={false}
										enableRangeSelection={false}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										gridOptions={{
											context: { componentParent: this }
										}}
										floatingFilter={false}
										onCellClicked={this.onShowMap.bind(this)}
									//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									/>
								</div>
							</div>
							<div id="map_canvas" className="" style={dStyles}></div>
						</div>
					</div>
					: ""
				}

				<Modal open={this.state.bulkuploadopen} onClose={this.onCloseModal}>
					<div className="card-header">
						<h5>Bulk Upload</h5>
					</div>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						<form className={"col-xl-12 col-lg-12 " + this.state.hideForm} method="post" onSubmit={this.onUploadSubmit.bind(this)}>
							<div className="form-group">
								<label>Upload File:</label>
								<input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler} className="form-control" required />
							</div>
							<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "center" }}>
								<button type="submit" className="form-control btn btn-success col-xl-2 col-lg-2">Submit</button>
							</div>
						</form>
						<div id="myGrid" style={{ height: "480px", width: "100%" }} className={"ag-theme-balham " + this.state.hideUploadStatusGrid}>
							<AgGridReact
								modules={this.state.modules}
								columnDefs={uploadColumns}
								defaultColDef={this.state.defaultColDef}
								rowData={this.state.uploadData}
								enableCharts={false}
								enableRangeSelection={false}
								autoGroupColumnDef={this.state.autoGroupColumnDef}
								onGridReady={this.onGridReady}
								onGridState={this.onGridState}
								statusBar={this.state.statusBar}
								sideBar={this.state.sideBar}
								paginationPageSize={this.state.paginationPageSize}
								pagination={true}
								gridOptions={{
									context: { componentParent: this }
								}}
								floatingFilter={false}
								onCellClicked={this.onShowMap.bind(this)}
							//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
							/>
						</div>
					</div>
				</Modal>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

			</div>

		);
	}
}


function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	console.log(len);
	var htmlStr = "";
	var coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}
function initOMS() {
	// Initializing OMS
	oms = new oms.OverlappingMarkerSpiderfier(map, {
		//markersWontMove: true,
		//keepSpiderfied: true,
		//nearbyDistance: 10,
		//circleFootSeparation: 60,
		//legWeight: 1.5
		markersWontMove: true,
		markersWontHide: true,
		keepSpiderfied: true,
		basicFormatEvents: true
	});
}