import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _, { get } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import $ from 'jquery';
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');



export default class uploadMasterList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },

            frameworkComponents: {
                updateItem: UpdateItem,
                deleteItem: DeleteItem
                //statusChange:StatusChange
            },

            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk1: "",
            sliderBulk2: "",
            file: "",
            csvcontent: [],
            billing_month: "",
        };
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
    }
    getCurrentDepartmentCode(pathToMatch = null) {
        let department = null;
        let departmentName = null;
        switch (pathToMatch) {
            case "/tptoemmaster":
                department = 'SNDG';
                departmentName = " ";
                break;

            default:
                console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
        }
        this.setState({
            currentDepartmentTitle: departmentName
        });
        return department;
    }
    componentDidMount() {
        if (googleAnalytics.page.enableGA) {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({ eventAction: eventAction });

        }

        this.setState({
            loadshow: "show-n",
            overly: "show-n"
        })


        // get department code to filter records by department
        let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
        this.setState({
            departmentCode: currentDepartmentCode
        });
        /*Consignments List*/
        this.onLoadData();

    };
    onLoadData() {
        redirectURL.post('/dashboard/mastertrucklistdata')
            .then((response) => {
                var records = response.data;
                //   console.log("records ", records)
                this.setState({
                    rowData: records
                });
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }

    onClickUpdateRowHandler(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "Update OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "Update OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        if (this.state.oem_name != "" && this.state.oem_code != ""
            && this.state.industry_type != "") {
            var params = {
                rowID: this.state.rowid,
                oem_code: this.state.oem_code,
                oem_name: this.state.oem_name,
                industry_type: this.state.industry_type,
                transporter_code: localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/updateoemitem", params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).
                then(
                    (response) => {
                        //console.log("update response is : ",response.data);
                        //console.log("Update Response ",response.data.result)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicTitle: 'Successfully update data',
                                basicType: "success",
                                sliderUpdate: "",
                                overly: "show-n"
                            });
                            this.onLoadData();
                        }

                        else {
                            this.setState({
                                show: true,
                                basicTitle: 'Something went wrong',
                                basicType: "danger",
                            });
                        }

                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'All * fields should not be empty',
                basicType: "danger",
            });
        }

    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = getHyphenYYYYMMDD(d)

        this.setState({
            billing_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }


    onClickNewAddRow(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "Add OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "Add OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        if (this.state.oem_name != "" && this.state.oem_code != ""
            && this.state.industry_type != "") {
            var params = {
                oem_code: this.state.oem_code,
                oem_name: this.state.oem_name,
                industry_type: this.state.industry_type,
                transporter_code: localStorage.getItem("transportercode")
            }
            var fdata = {
                rownode: params,
                transporter_code: localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/saveoemitem", params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).
                then(
                    (response) => {
                        //console.log("update response is : ",response.data);
                        //console.log("Update Response ",response.data.result)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicTitle: 'Successfully added data',
                                basicType: "success",
                                sliderRso: "",
                                overly: "show-n",
                                loadshow: "show-n"
                            });
                            this.onLoadData();
                        }

                        else {
                            this.setState({
                                show: true,
                                basicTitle: 'Something went wrong',
                                basicType: "danger",
                            });
                        }

                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'All * fields should not be empty',
                basicType: "danger",
            });
        }

    }

    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    onClickGenerateBill(){
        
        var billing_id= moment.parseZone().format("YYYYMMDDHHmmss");
        // console.log(billing_id,"billing_id");
        var billing_getter = this.state.billing_month;
        console.log(billing_getter,"billing_getter");
        var billing_time=billing_getter.split("-");
         console.log(billing_time,"billing time");
        var billing_year = billing_time[0];
        // console.log(billing_year,"billing_year");
        var billing_month = billing_time[1];
        // console.log(billing_month,"billing_month");
 
        if(billing_getter !== "" && billing_getter !== undefined){
            var params={
                billing_year:billing_year,
                billing_month:billing_month,
                billing_id : billing_id
            }
            console.log(params,"params")
            redirectURL.post('/dashboard/generateBillingMonth',params)
            .then((response) => {
                var records = response.data.records;
                //   console.log("records ", records)
                this.setState({
                    rowData: records
                });
                window.location.href="/tptlogin/billingsummary" 
            })
            .catch(function (error) {
                console.log(error);
            });
     
        }
      
    }


    updateRowHandler = async (cell) => {

        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
            sliderUpdate: 'slider-translate',
            overly: 'show-m',
            oem_code: oem_code,
            oem_name: oem_name,
            industry_type: industry_type,
            rowid: rowid
        })

    }



    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRso: "",
            sliderUpdate: "",
            sliderBulk1: "",
            sliderBulk2:"",
        });

    }

    onChangeTruckType(trucktype) {
        this.setState(
            { trucktype },
            () => console.log(`Option selected:`, this.state.trucktype)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }


    onChangeTransporter(transporter) {
        this.setState(
            { transporter },
            () => console.log(`Option selected:`, this.state.transporter)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeDepartment(deptcode) {
        this.setState(
            { deptcode },
            () => console.log(`Option selected:`, this.state.deptcode)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    deleteRowHandler(cell) {
        //  console.log("Here",cell);
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        var fdata = {
            rownode: cell
        }
        redirectURL.post("/dashboard/deleteoemitem", fdata, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).
            then(
                (response) => {
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if (response.data.status == "success") {
                        this.setState({
                            show: true,
                            basicTitle: 'Successfully delete item',
                            basicType: "success",
                            loadshow: "show-n",
                            overly: "show-n",
                            sliderRso: ""
                        });
                        this.onLoadData();
                    }

                    else {
                        this.setState({
                            show: true,
                            basicTitle: 'Something went wrong',
                            basicType: "danger",
                        });
                    }

                }
            )
            .catch(function (error) {
                console.log(error);
            });

    }

    onChangeSourceItem(sourceopt) {
        this.setState(
            { sourceopt },
            () => console.log(`Option selected:`, this.state.sourceopt)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onChangeOfficerType(officertype) {
        this.setState(
            { officertype },
            () => console.log(`Option selected:`, this.state.officertype)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    selectsources() {
        let items = [];
        try {
            if (this.state.sources.length > 0) {
                this.state.sources.map((item) => {
                    items.push({ "value": item.geofence_name, "label": item.geofence_name })
                })
            }
        }
        catch (e) {

        }
        return items;
    }

    onClickShowBulk = (param) => {
        console.log(param,"showSLider")
        if (param == 1) {
            this.setState({
                tab : 'tab1',
                overly: "show-m",
                sliderBulk1: 'slider-translate',
            })
        }
        else if (param == 2) { 
            this.setState({
                tab : 'tab2',
                overly: "show-m",
                sliderBulk2: 'slider-translate',
            })
        }
    }

    
    changeFileHandler = async (e) => {
        console.log(e.target.files,"filevalue")
     
        var dt = '';
        // const config = {
        // 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
        // }

        const config = {
            headers: [
                {
                    name: 'Truck No',
                    inputName: 'truck_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Transporter Name',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Transporter Code',
                    inputName: 'transporter_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
               

            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                this.setState({
                    csvcontent: csvData.data
                });
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                console.log("contents ", contents)

                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
            console.log("tt ", tt)
        });
        //console.log("Out ", out);

        this.setState({
            file: e.target.files[0]
        });

    }

    changeRemovedFileHandler = async (e) => {
        console.log(e,"remove")
        var dt = '';
        // const config = {
        // 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
        // }

        const config = {
            headers: [
                {
                    name: 'Truck No',
                    inputName: 'truck_no',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Transporter Name',
                    inputName: 'transporter_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Transporter Code',
                    inputName: 'transporter_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Device Registered Date',
                    inputName: 'device_registered_on',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Device Removed Date',
                    inputName: 'device_removed_on',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }

            ]
        }
        var data = CSVFileValidator(e.target.files[0], config)
            .then((csvData) => {
                this.setState({
                    csvcontent: csvData.data
                });
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                console.log("contents ", contents)

                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
            console.log("tt ", tt)
        });
        //console.log("Out ", out);

        this.setState({
            file: e.target.files[0]
        });

    }
    onSubmitBulkUpload(event) {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pagetitle;
            let eventOptions = {
                "category": "Buk Upload",
                "action": "form",
                "label": "Buk Upload",
            }
            googleAnalytics.logEvent(eventOptions);
        }

        var params = {
            csvcontent: this.state.csvcontent,
            tab : this.state.tab
        }
        console.log("content ", this.state.csvcontent)
        console.log("Params ", params)
        redirectURL.post("/dashboard/uploadtruckmasterlist", params)
            .then((response) => {
                if (response.data.status == 200) {
                    this.setState({
                        show: true,
                        basicTitle: response.data.message,
                        basicType: "success",
                        loadshow: "show-n",
                        overly: "show-n",
                        sliderBulk1: "",
                        sliderBulk2: "",
                        file: ""
                    });
                    $("#uploaded1").val('');
                    $("#uploaded2").val('');
                    this.onLoadData();
                }

                else {
                    this.setState({
                        show: true,
                        basicTitle: 'Something went wrong',
                        basicType: "danger",
                    });
                }
            })
    }
    render() {
        const modalStyles = {
            width: '1300px !important',
        }
        var transporters = []
        var trans = this.state.transporters;
        if (trans.length > 0) {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
        const { usermanualmodal } = this.state;
        var hideChilds = true;
        if (this.state.departmentCode == "LOG-PRT") {
            hideChilds = false
        }
        const columnwithDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 150,
                editable: true,
                filter: "agSetColumnFilter"
            },

            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 300,
                editable: true,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 180,
                editable:true,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Uploaded Time",
                field: "created_date",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                valueGetter:function(params){
                    try{
                        if(params.data.created_date != '' && params.data.created_date != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.created_date)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
            },

            
        ];
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span> Upload Master Truck List</span>
                                    {/* <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-warning float-right">Generate Bill</button> */}
                                    {/* <button type="button" onClick={() => this.onClickShowBulk(1)} className="btn btn-primary float-right">Upload Removed Trucks List</button> */}
                                    <button type="button" onClick={() => this.onClickShowBulk(2)} className="btn btn-danger float-right">Upload Trucks Master List</button>
                                </h5>
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div> */}
                           <div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div> 
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderTranslate)} >

                    <div className="slide-r-title">
                        <h4>
                            Sidebars
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="col-xl-12 col-lg-12">
                            <div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Add OEM Item
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">OEM Code *</label>
                                    <input
                                        type="text"
                                        name="oem_code"
                                        value={this.state.oem_code}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">OEM Name *</label>
                                    <input
                                        type="text"
                                        name="oem_name"
                                        value={this.state.oem_name}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Industry Type *</label>
                                    <input
                                        type="text"
                                        name="industry_type"
                                        value={this.state.industry_type}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>


                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>



                <div className={"slide-r " + (this.state.sliderUpdate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Update OEM Item
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">OEM Code *</label>
                                    <input
                                        type="text"
                                        name="oem_code"
                                        value={this.state.oem_code}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">OEM Name *</label>
                                    <input
                                        type="text"
                                        name="oem_name"
                                        value={this.state.oem_name}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Industry Type *</label>
                                    <input
                                        type="text"
                                        name="industry_type"
                                        value={this.state.industry_type}
                                        className="form-control"
                                        onChange={this.changeHandler.bind(this)} />
                                </div>


                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.sliderBulk1)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                        Upload Master List
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
                                {(this.state.tab == "tab2") ?
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload Master Truck List *</label>
                                    <input
                                        
                                        type="file"
                                        name="uploadfile"
                                        className="form-control"
                                        onChange={this.changeFileHandler.bind(this)}  required/>

                                </div>
                                :
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload Removed Truck List *</label>
                                    <input
                                        id="uploaded2"
                                        type="file"
                                        name="uploadfile"
                                        className="form-control"
                                        onChange={this.changeRemovedFileHandler.bind(this)} required />
                                </div>
                                
                                }
                                
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                    
                                

                                 {/* <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Sample Template</a>
                                </div> */}
                            </form>


                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderBulk2)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                        Upload Master List
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12 " onSubmit={this.onSubmitBulkUpload.bind(this)}>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload Truck Master List *</label>
                                    <input
                                       id="uploaded1"
                                        type="file"
                                        name="uploadfile"
                                        className="form-control"
                                        onChange={this.changeFileHandler.bind(this)} required/>
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                {/* <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-info">Sample Template</button>
                                </div> */}

                                 <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/sample_MSIL_MasterList.csv")} target="_blank" className="btn btn-info">Sample Template</a>
                                </div> 
                            </form>


                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


            </div>

        );
    }
}