import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/icons/logo.png'; // Assuming logo.png is in your project
import enmovilImage from '../../assets/icons/enmovil.png'; // Assuming logo.png is in your project
import {getHyphenDDMMMYYYYHHMM} from '../common/utils';


const reactPDFDownload = ({ data }) => {
    const doc = new jsPDF('landscape'); // Set landscape orientation

    // Add the image (logo)

    // Add title to the PDF
    doc.setFontSize(12);
    doc.text('Carrier Route', 14, 22);
    doc.addImage(logo, 'PNG', 12, 5, 50, 15); // Add logo text or image here
    doc.addImage(enmovilImage, 'PNG', 240, 10);


    // Define the columns and the rows for the table
    const columns = [
      'Truck No',
      'Latitude', 
      'Longitude', 
      'Address', 
      'Geofence Name', 
      'Speed (Kms)', 
      'Packet Time', 
      'Near Dealer Name', 
      'Near Dealer Distance (in KM)', 
      'Time from previous point', 
      'Cumulative Distance (KM)'
    ];
    const rows = data.map(item => [
      item.truck_no,
      item.lat,
      item.lng,
      item.address,
      item.geofence_name,
      item.speed,
      getHyphenDDMMMYYYYHHMM(item.stime),
      item.near_dealer_name,
      item.near_dealer_distance,
      secondsToDhms(item.time_from_prev_point),
      convertMeters(item.cumm_distance)
    ]);

    // Create the table with the autoTable plugin
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 30, // Adjust the starting point
    });
    // Add footer to the bottom-right corner
    const pageHeight = doc.internal.pageSize.height; // Get page height
    const pageWidth = doc.internal.pageSize.width;   // Get page width
    doc.setFontSize(10); // Set smaller font size for the footer
    const footerText = 'Report generated on enmovil.io by Enmovil Solutions';
    const textWidth = doc.getTextWidth(footerText); // Get the width of the text

    // Position the text at the right-bottom corner, with a small margin
    doc.text(footerText, pageWidth - textWidth - 14, pageHeight - 10); 
    // Save the PDF
    doc.save('report.pdf');
  
};
function convertMeters(metersValue) {
  return (metersValue / 1000).toFixed(2);
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}
export default reactPDFDownload;