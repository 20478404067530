import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from './mlldrawmap';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import ConfirmDelivered from "./confirmdelivered";


$.datetimepicker.setLocale('en');    
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class TPTVinsConfirmDelivered extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
            sliderTranslate:'',
            sliderAllTranslate:"",
			showdata:this.displayData,
			modules: AllModules,
        	alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			detailCellRendererParams:{},
            frameworkComponents: {
                ConfirmDelivered:ConfirmDelivered
            },
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
            context: { componentParent: this },
            rowClassRules: {
				"boxbgblue": "data.to_be_approved == 1"
			},
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:200,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            pagetitle:"",
			sliderRouteTranslate:'',
			sliderForceDestination:"",
            usergridstate:[],
            screenurl:"",
            screentitle:"",
            startDate:"",
            endDate:"",
            defaultsdate:"",
            defaultedate:"",
            file:'',
			csvcontent:[],
            sliderRakeTranslate:"",
            sliderBulkRakeTranslate:"",
            file:'',
            uploadFile:'',
            confirmrowdata:[],
            refno:"",
            railno:"",
            batchnos:[],
            bno:{"value":"","label":""} 
			
        };
        this.onLoadShowBatchVins = this.onLoadShowBatchVins.bind(this);
        // this.onClickUpdateItem = this.onClickUpdateItem.bind(this);
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.onClickShowUpload = this.onClickShowUpload.bind(this);
        this.onClickConfirmItem = this.onClickConfirmItem.bind(this);
        this.onClickAllConfirmItem = this.onClickAllConfirmItem.bind(this);
	}
	
    componentDidMount(){
		loadDateTimeScript();
        if(this.props.match.params.refno != undefined)
        {
            var refno = this.props.match.params.refno
        }
        else{
            var refno = "";
		}
		// railno
		
        if(this.props.match.params.railno != undefined)
        {
            var railno = this.props.match.params.railno
        }
        else{
            var railno = "";
		}
        this.setState({
            refno:refno,
            railno:railno
        })

        var parameters = {
            refno : refno
        }

        this.onLoadShowBatchVins(parameters);

	}
    
    onLoadShowBatchVins(parameters)
    {
		var transporter_code = localStorage.getItem("transportercode")
		redirectURL.post("/dashboard/reachdealerlist",parameters)
        .then((response) => {
            console.log("response" , response.data)
            var records = response.data.records
            var batchnos = []
            if(records.length > 0)
            {
                records.map((item) => {
                    batchnos.push({batchno:item.batchno})
                })
            }
            
            var newArray=[]
          // Declare an empty object 
          let totuniqueObject = {}; 
          var totobjTitle;
          // Loop for the array elements 
          for (let i in batchnos) { 

              // Extract the title 
              totobjTitle = batchnos[i]['batchno']; 

              // Use the title as the index 
              totuniqueObject[totobjTitle] = batchnos[i]; 
          } 

          // Loop to push unique object into array 
          for (var a in totuniqueObject) { 
              newArray.push({
                  value:totuniqueObject[a].batchno,
                  label:totuniqueObject[a].batchno
                }); 
          } 
            this.setState({
                rowData:records,
                loadshow:"show-n",
                overly:"show-n",
                batchnos:newArray
            })
        })
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        await window.location.reload()
    }

	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        // await window.location.reload()
    }
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    
    
	onClickHideAll(){
		this.setState({
			overly : "show-n",
            sliderStateEmailsTranslate : "",
            sliderRouteTranslate:"",
            sliderRakeTranslate:"",
            sliderTranslate:"",
            sliderAllTranslate:""
			
		})
	}
	
   onGridReady = params => {
		this.gridApi = params.api;
		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
    }
    onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			sliderRakeTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
            sliderTranslate:"",
            sliderAllTranslate:"",
            overly:'show-n'
		});
    }
    
    onClickConfirmItem(params)
    {
        this.setState({
            sliderTranslate:"slider-translate",
            overly:"show-m",
            confirmrowdata:params
        })
       
    }
    onClickConfirmItemUpdate(event)
    {
        event.preventDefault();
        // console.log("parmas ", params)
        var confirmrowdata = this.state.confirmrowdata;
        var confirm_delivery_date = $("#confirm_delivery_date").val();
        var parameters = {
            vehicle_id:confirmrowdata.data.vehicle_id,
            confirm_delivery_date:confirm_delivery_date,
            email:localStorage.getItem("email"),
            username:localStorage.getItem("firstname"),
            transporter_code:localStorage.getItem("transportercode")
        }
        redirectURL.post("dashboard/updateConfirmDeliveredStatus",parameters)
        .then((response) => {
            console.log("response ", response.data)
            if(response.data.status == "Success")
            {
                $("#confirm_delivery_date").val("")
                this.setState({
                    sliderTranslate:"",
                    overly:"show-n",
                    show:true,
                    basicTitle:"Successfully updated delivered status",
                    basicType:"success"
                })
            }
            else
            {

                this.setState({
                    sliderTranslate:"",
                    overly:"show-n",
                    show:true,
                    basicTitle:"Failed to updated delivered status",
                    basicType:"danger"
                })
            }
            
        })
       
    }
    onClickAllConfirmItem()
    {
        this.setState({
            sliderAllTranslate:"slider-translate",
            overly:"show-m"
        })
       
    }

    onClickAllConfirmData(event)
    {
        event.preventDefault();
        // let records = [];
        // this.gridApi.forEachNode(node => records.push(node.data));
        // console.log("parmas ", records)
        var batchno = this.state.bno.value;
        var confirm_delivery_date = $("#all_confirm_delivery_date").val();
        var parameters = {
            batchno:batchno,
            confirm_delivery_date:confirm_delivery_date,
            email:localStorage.getItem("email"),
            username:localStorage.getItem("firstname"),
            transporter_code:localStorage.getItem("transportercode")
        }
        if(confirm_delivery_date != "" && confirm_delivery_date != "__-__-____ __:__" && batchno != "")
        {
            redirectURL.post("dashboard/updateAllConfirmDeliveredStatus",parameters)
            .then((response) => {
                // console.log("response ", response.data)
                if(response.data.status == "Success")
                {
                    $("#all_confirm_delivery_date").val("")
                    this.setState({
                        sliderAllTranslate:"",
                        overly:"show-n",
                        show:true,
                        basicTitle:"Successfully updated delivered status",
                        basicType:"success"
                    })
                }
                else
                {
    
                    this.setState({
                        sliderAllTranslate:"",
                        overly:"show-n",
                        show:true,
                        basicTitle:"Failed to updated delivered status",
                        basicType:"danger"
                    })
                }
                
            })
        }
        
       
    }
    
    onChangeSourceItem(bno){
		this.setState(
			{ bno },
			() => console.log(`Option selected:`, this.state.bno)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    render(){
        var columnwithDefs= [
            {
                headerName:"Batch No",
                field:"batchno",
                width:150,
                resizable:true,
                editable:false
            },
            {
                headerName:"VIN No",
                field:"vehicle_id",
                width:150,
                resizable:true,
                editable:false
            },
            
            {
                headerName:"Truck No",
                field:"trailer_no",
                width:150,
                resizable:true,
                editable:false
            },
            
            {
                headerName:"Bill To Party Code",
                field:"bill_to_party",
                width:150,
                resizable:true,
                editable:false
            },
            
            {
                headerName:"Bill To Party Name",
                field:"bill_to_party_name",
                width:150,
                resizable:true,
                editable:false
            },
            {
                headerName:"Action",
                field:"_id",
                width:140,
                resizable:true,
                editable:false,
                cellRendererSelector:function(params){
                    return {
                        component:"ConfirmDelivered"
                    }
                }
            }
        ];
        console.log("this.state.batchnos ", this.state.batchnos)
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        >
	        </SweetAlert>
	            {this.state.alert}
				
            	<div className="row">
                   
            		<div className="col-xl-12 col-lg-12 pl-0">
                        
		            	<div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span> Vehicle Delivered Confirmation</span>
                                    <a href={"/tptlogin/tptconsignments/"+(this.state.refno)+"/"+(this.state.railno)} className="btn btn-primary float-right"> Back</a>
					    			<button type="button" className="btn btn-warning float-right" onClick={this.onClickAllConfirmItem.bind(this)}>Confirm Batchwise</button>
                                </h5>
                            </div>
				   			<div className="card-body row">
                                
                                <div className={"row col-xl-12 col-lg-12 "}>
                                   
                                    <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
											masterDetail={true}
											detailCellRendererParams={this.state.detailCellRendererParams}
									
										/>

                                    </div>
                                </div>
                                
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				
				<div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                            Confirm Delivered
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                            <form method="POST" id="updateForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickConfirmItemUpdate.bind(this)}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Confirm Delivered Date</label>
                                    <input type="text" placeholder="Confirm Delivered Date" id="confirm_delivery_date" name="confirm_delivery_date" className="datetimepicker_mask form-control" required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>

                        </div>
					</div>
				</div>

                <div className={"slide-r "+(this.state.sliderAllTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                            Confirm Delivered
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                                    
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                            <form method="POST" id="updateForm" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickAllConfirmData.bind(this)}>
                          
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Select Batch No *</label>
                                    <Select 
                                    placeholder={"Select Batch No"}
                                    closeMenuOnSelect={true}
                                    onChange={this.onChangeSourceItem.bind(this)} 
                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    value={this.state.bno}
                                    options={this.state.batchnos} />  
                                </div>                                    
                            
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Confirm Delivered Date</label>
                                    <input type="text" placeholder="Confirm Delivered Date" id="all_confirm_delivery_date" name="all_confirm_delivery_date" className="datetimepicker_mask form-control" required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>

                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
              
        );
    }
}


window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('#confirm_delivery_date').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('#all_confirm_delivery_date').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	
	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('#confirm_delivery_date').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
    });
    $('#all_confirm_delivery_date').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


function GetSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 


function GetSortAscOrder(prop) {    
	return function(a, b) {    
		if (a[prop] > b[prop]) {    
			return 1;    
		} else if (a[prop] < b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 

function secondsToDays(seconds)
{
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}