
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');
 
const ViewIdentityttachment = (props) => {
    //console.log("props ", props)
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickIdentityDownload(props);
    };
   
    //console.log(" donwloads ", donwloads)
    var filename = props.data.identity_proof_file;
    console.log("filename ", filename)
    return (
        <div>
          
                <button
                    style={{border:"0px", backgroundColor:"transparent"}}
                    type="button"
                    className="btn btn-default"
                    onClick={handleClick} >
                        {filename}
                </button>
            
            {/* <a
                    href={"http://localhost:3004/static/media/"+filename}
                    target="_blank"  
                    //onClick={e => download(file.path, file.name)} 
                >{filename}
                </a>
            */}
        </div>
    );
};

export default ViewIdentityttachment;
