/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import ExportStockyardPdf from './exportstockyardpdf';
import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDDHHMMSS,getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
//import { getConsignmentsNoGpsLifeTimeCounter } from '../../../../tpt_node/models/consignmentModel';

import CountUp from 'react-countup';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
export default class StockyardTransportersInfo extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Month Wise Report",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },

			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            frameworkComponents: {
				// editActions: EditActions,
                ExportStockyardPdf: ExportStockyardPdf
			},
            defaultExcelExportParams: {
                allColumns: true,
            },
			plant_code:'',
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
            markersList:[],
            btnClicked:[],
            CellRendererFunc:[],
			detailCellRendererParams:{},
            vin_number_options:[],
            selectedvinnumber:[],
            selectedtransportername:[],
            transporter_code:[],
            bay_number_options:[],
            selectedbaynumber:[],
            start_date:[],
            end_date:[],
            transporter_name_options:[],
            selecteddealername:[],
            selectedoemplantname:[],
            dealer_name_options:[],
            oem_plant_options:[],
            bay_dealer_name:[],
            bay_oem_plant_name:[],
            sliderRso:"",
            sliderTitle:"",
            sliderType:"",
            gate_in_images_view:[],
            gate_out_images_view:[],
            srcImage: "",
            truck_number_options:[],
            selectedtrucknumber:[],
            truk_no:[],
            usergridstate: []
		};
		// this.popmarker=this.popmarker.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        this.setState({
            loadshow:'show-m'
        })
        loadDateTimeScript()
        var from_date = moment.parseZone().subtract(1,'days').format("YYYY-MM-DD")
        var to_date = moment.parseZone().format("YYYY-MM-DD")
        $("#frommonthwisedate").val(from_date);
		$("#tomonthwisedate").val(to_date);
        console.log($("#frommonthwisedate").val(),"from_date",$("#tomonthwisedate").val(),"to_date")
		var params = {
			// transporter_code:transporter_code,
            stockyardemailid:localStorage.getItem("email"),
			start_date : from_date,
			end_date : to_date
		}
        if(![undefined , "undefined" , "" , null].includes(localStorage.getItem("transportercode"))){
			params.transporter = localStorage.getItem("transportercode")
		}
		redirectURL.post("/consignments/getmonthwisestockyarddata",params).then((response)=>{
            var monthwisedata = response.data.data
            console.log(monthwisedata,"monthwisedata")
            this.setState({
            loadshow:'show-n',
            rowData: monthwisedata,
            })
            redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
            .then( async (resp) => {
                await this.setState({
                    usergridstate:resp.data
                });
                await this.restoreGridStates();
            })
        })

    }
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	  }
      onBtExport = () => {
        // this.gridApi.exportDataAsExcel({skipColumnHeaders : false, skipColumnGroupHeaders : false})
        let params = getParams();
        this.gridApi.exportDataAsExcel(params);
        };
formHandler = (event) =>{
    this.setState ({
        loadshow:'show-m',
        overly:'show-n'
    })
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}
 
        var from_date =$("#frommonthwisedate").val();
		var to_date = $("#tomonthwisedate").val();
        console.log(from_date,"from_date",to_date,"to_date")
        // /||dealer_names!=''||oem_plant_names!=''||truck_datas != ''
        if(from_date != '' || to_date != '' )
        {	
            if(from_date != undefined && from_date != "" && to_date != undefined && to_date != "")
            {
                from_date = moment.parseZone(new Date(from_date)).utcOffset("+05:30").format("YYYY-MM-DD")
                to_date = moment.parseZone(new Date(to_date)).utcOffset("+05:30").format("YYYY-MM-DD")
            }
            var check = 0 ; 
            if(from_date !=undefined && from_date !="")
            {
                if(to_date ==undefined || to_date == "")
                {
                    check  = 1 ;
                }
            } 
            if(to_date !=undefined && to_date !="")
            {
                if(from_date ==undefined || from_date == "")
                {
                    check  = 2 ;
                }
            }
            if(check == 0)
            {
                var params ={
                    stockyardemailid:localStorage.getItem("email"),
                    start_date:from_date,
                    end_date:to_date,
                    // transporter_code:transporter_names,
                }
                if(![undefined , "undefined" , "" , null].includes(localStorage.getItem("transportercode"))){
                    params.transporter = localStorage.getItem("transportercode")
                }
                redirectURL.post("/consignments/getmonthwisestockyarddata",params).then((response)=>{
                    var monthwisehistorydata = response.data.data;
                    console.log(monthwisehistorydata,"monthwisehistorydata")
                    this.setState({
                    rowData: monthwisehistorydata,
                    loadshow:'show-n',
                    overly:'show-n'
                    })
                })
            }    
            // }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:'From Date and To Date are required',
                    basicType:"danger",
                    loadshow:'show-n',
                })
            }
        }
    
        else
        {
            this.setState({
                show:true,
                basicTitle:'From Date and To Date are required',
                basicType:"danger",
                loadshow:'show-n',
            })
        }
        
    }
	onClickHideAll(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",
            sliderRso: "",

		})
	}
    
   onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};
    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Parking Information';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			// console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		// console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}
    onGridState = () =>{
		//console.log(this.gridApi);
		
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
			
		
		
	}
    resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
    onCellClicked(e)
	{
        if(e.colDef.field == "gateinview")
        {
            var gate_in_images = e.data.bay_in_images;
            if(gate_in_images !=undefined)
            {  
                this.setState({
                    gate_in_images_view:gate_in_images,
                    sliderRso:"slider-translate-50p",
                    sliderType:1,
                    sliderTitle:"Damage Pictures"
                });
            }
        }

        if(e.colDef.field == "gateoutview")
        {
            var gate_out_images = e.data.bay_out_images;
            if(gate_out_images !=undefined)
            {  
                this.setState({
                    gate_out_images_view:gate_out_images,
                    sliderRso:"slider-translate-50p",
                    sliderType:2,
                    sliderTitle:"Damage Pictures"
                });
            }
        }
	}
	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		
		var columnDefs= [
            {
                headerName: "Receipt",
                field: "receipt",
                width: 150,
                cellRendererSelector: function(params){
                  var rendComponent = {
                      component : 'ExportStockyardPdf'
                  }
                  return rendComponent;
              },
            },
			{
                headerName: "Vin Number",
                field: "vin_number",
                width: 200,
  
            },
            {
                headerName: "MSIL Rake Id",
                field: "rake_no",
                width: 200,

            },
            {
                headerName: "Transporter",
                field: "transporter_name",
                width: 200,

            },
            {
                headerName: "Gate In Time",
                field: "gate_in_time",
                width: 150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)}
            },
            {
                headerName: "Bay In Damage Remarks",
                field: "bay_in_damages",
                width: 250,

            },
            {
            headerName: "Bay In Image",
            field: "gateinview",
            width: 80,
                cellRenderer : function(params){
                    return '<button class="btn white btn-info" style="padding:0 6px 6px;color:#ccc" >View</button>'
                }
            },
            {
                headerName: "Bay No",
                field: "parking_cell",
                width: 100,

            },
            {
                headerName: "Bay Allocation Date",
                field: "bay_in_time",
                width: 150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.bay_in_time)}
            },
            {
                headerName: "Bay De-allocation Date",
                field: "bay_out_time",
                width: 150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.bay_out_time)}
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)}
            },
            {
                headerName: "Bay Out Time Remarks",
                field: "bay_out_damages",
                width: 250,

            },
            {
            headerName: "Bay Out Image",
            field: "gateoutview",
            width: 250,
                cellRenderer : function(params){
                    return '<button class="btn white btn-info" style="padding:0 6px 6px;color:#ccc" >View</button>'
                }

            },
            {
            headerName: "Time Spent in Parking(HH:MM)",
            field: "parking_duration_secs",
            width: 150,
            valueGetter:function(params){
                try{
                    //console.log("params.data.night_driving_duration_secs ", params.data.night_driving_duration_secs)
                    if(params.data.gate_in_time != "" && params.data.gate_in_time != undefined && params.data.gate_out_time != "" && params.data.gate_out_time != undefined)
                    {
                        var firstinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(params.data.gate_in_time))).utcOffset("+05:30")._d
                        // console.log(firstinstance,"firstinstance")
                        var recentinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time))).utcOffset("+05:30")._d
                        // console.log(recentinstance,"firstinstance")
                        var data = (recentinstance - firstinstance)
                        // console.log(data,"data data")
                        return secondsToHM(data/1000);
                    }
                    else{
                        return "";
                    }
                }
                catch(e){
                    return "";
                }
            }
        }
            
		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}

        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>
                                       <button className="float-right export-btn white btn-danger" onClick={() => this.onBtExport()} style={{padding:"6px"}}>
                                        Export to Excel</button>
                                        <span className="layoutbtns pull-right">
                                            <button className="float-right custom-btn white btn-danger"
                                            style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                                <i className="icofont icofont-save"></i> Save Grid Layout
                                            </button>
                                            <button className="float-right custom-btn white btn-info" style={{marginRight:"10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.resetState.bind(this)}>
                                                <i className="icofont icofont-refresh"></i> Reset Default Layout
                                            </button>
                                        </span>

								</h5>
				   			</div>
				   			<div className="card-body row">
							   <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
								   <div className={"col-xl-12 col-lg-12 "+this.state.showStateTrucksMsg}>
								   	{/* <p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p> */}
								   </div>

	                    			<div className="row">
                                        <div class="col-xl-2 col-lg-2 form-group">
                                            <label>From Date</label>
                                            <input type="text" name="datewise" id="frommonthwisedate" autoComplete="off" className="datetimepicker_date_parking form-control" />
                                        </div>
                                        <div class="col-xl-2 col-lg-2 form-group">
                                            <label>To Date</label>
                                            <input type="text" name="datewise" id="tomonthwisedate" autoComplete="off" className="datetimepicker_date_parking form-control" />
                                        </div>
										<div className="form-group col-xl-2 col-lg-2">
											<button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
										</div>
									</div>


								</form>
								<div className="row col-xl-12 col-lg-12 col-sm-12">

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                    <img id="ItemPreview" src={this.state.srcImage} />
                                    <div className="col-xl-12 col-lg-12 ">

                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                masterDetail = {true}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                frameworkComponents={this.state.frameworkComponents}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                defaultExcelExportParams={this.state.defaultExcelExportParams}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                // enableRangeSelection={false}
                                                onCellClicked={this.onCellClicked.bind(this)}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                floatingFilter={false}
                                                // onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>

								</div>

                            </div>
				   		</div>
				   	</div>
                       <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							{this.state.sliderTitle}
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                             {(this.state.sliderType == 1)?
                            <div className="row col-sm-12">
                                {(this.state.gate_in_images_view.map(function(g,index){
                                     return <div className="col-sm-4" style={{marginBottom:"2%"}}>
                                        {(index == 0)?
                                            <img src={"data:application/octet-stream;base64,"+g.replace(/ /g,"+")}/>
                                        :
                                            <img src={"data:application/octet-stream;base64,"+g.replace(/ /g,"+").replace("+","")}/>
                                        }
                                    </div>
                                }))}
                            </div>
                            :""}
                            {(this.state.sliderType == 2)?
                            <div className="row col-sm-12">
                                {(this.state.gate_out_images_view.map(function(g,index){
                                     return <div className="col-sm-4" style={{marginBottom:"2%"}}>
                                        {(index == 0)?
                                            <img src={"data:application/octet-stream;base64,"+g.replace(/ /g,"+")}/>
                                        :
                                            <img src={"data:application/octet-stream;base64,"+g.replace(/ /g,"+").replace("+","")}/>
                                        }
                                    </div>
                                }))}
                            </div>
                            :""}
						</div>
					</div>
				</div>
				 </div>


				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
            </div>
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{

	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);

}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }
  function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date_parking').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
$(document).on("change", "#tomonthwisedate", function(e) {
    e.preventDefault();
    var bookingDate=$("#frommonthwisedate").val();
    var cargoDate=$("#tomonthwisedate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#tomonthwisedate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#frommonthwisedate", function(e) {
    e.preventDefault();
    var bookingDate=$("#frommonthwisedate").val();
    var cargoDate=$("#tomonthwisedate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#frommonthwisedate").val("");
        alert("From Date should be less than equal to To Date");
    }
})
function getParams() {

    return {
        allColumns:false,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: false,
        skipGroups: true,
        skipHeader: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
    };

  }