import React, { Component } from 'react';
import redirectURL from '../redirectURL';
import $ from "jquery";

import SweetAlert from 'react-bootstrap-sweetalert';

var addressmap;
var addressmarker;
var addresscircle;

export default class AddressSidebarComponent extends Component {
    state = {
        geo_address: this.props.geo_address,
        geo_latitude: this.props.geo_latitude,
        geo_longitude: this.props.geo_longitude,
        geo_radius:"15",
        isUpdated : 0,
        show:false
    };
    componentDidMount(){
        console.log(this.props,"this.props---didmount")
        this.setState({
            isUpdated : 0
        });
        this.initGeoMap();
    }
    componentWillReceiveProps(){
        console.log(this.props,"this.props---didupdate")
        this.setState({
            isUpdated : 1
        });
        // this.initGeoMap();
    }
    initGeoMap = () => {
		if(this.props.geo_latitude !="" && this.props.geo_latitude && this.props.geo_longitude !="" && this.props.geo_longitude !=undefined)
        {
            var lt=28.4519751;
            var ln=77.0310713;
        }
        else
        {
            var lt=this.state.geo_latitude;
            var ln=this.state.geo_longitude;
        }
        console.log(lt,ln,"latlng")
        addressmap = new window.google.maps.Map(document.getElementById('map_canvas_tpt_info'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            /*mapTypeControl: true,
            gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
        });
        
        var input = document.getElementById('addresssearch');
        var searchBox = new window.google.maps.places.SearchBox(input);
        addressmap.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
    
        // Bias the SearchBox results towards current map's viewport.
        addressmap.addListener('bounds_changed', function() {
    
          searchBox.setBounds(addressmap.getBounds());
        });
        
        var markers = [];
        //console.log("searchBox",searchBox);
        searchBox.addListener('places_changed', function() 
        {
          var places = searchBox.getPlaces();
            
          if (places.length == 0) {
            return;
          }
    
          // Clear out the old markers.
          markers.forEach(function(addressmarker) {
              console.log("Search Marker ", addressmarker)
              addressmarker.setMap(null);
          });
          markers = [];
            // For each place, get the icon, name and location.
          var bounds = new window.google.maps.LatLngBounds();
          places.forEach(function(place) {
            if (!place.geometry) {
              console.log("Returned place contains no geometry");
              return;
            }
            var icon = {
              url: place.icon,
              size: new window.google.maps.Size(71, 71),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(17, 34),
              scaledSize: new window.google.maps.Size(25, 25)
            };
    
            // Create a marker for each place.
            //console.log("Plae Lat", place.geometry.location.lat());
            document.getElementById("geo_latitude").value =place.geometry.location.lat();
    
            document.getElementById("geo_longitude").value =place.geometry.location.lng();
            
            markers.push(new window.google.maps.Marker({
              map: addressmap,
              icon: icon,
              title: place.name,
              position: place.geometry.location
            }));
    
            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          
          addressmap.fitBounds(bounds);
        });
        
        addresscircle = new window.google.maps.Circle({
            map: addressmap
        });
        
        addressmarker = new window.google.maps.Marker({
            map: addressmap,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            //position: {lat: 28.48, lng: 77.06}
        });
        
        addressmarker.addListener('click', function(){
            addressmarker.setAnimation(window.google.maps.Animation.BOUNCE)			
        });
        
          window.google.maps.event.addListener(addressmap, 'click', function(event) {
            
            document.getElementById("latitude").value = event.latLng.lat();
            document.getElementById("longitude").value = event.latLng.lng();
            addressmarker.setPosition(event.latLng);
            
        });
        addresscircle.bindTo('center', addressmarker, 'position');
        
        
        
        var eventtrigger = document.getElementsByClassName('truck');
    
          // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
          //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
         
    }
    handleClick = (e) => {
        e.stopPropagation();
        this.props.context.onClickHideAll();
    };
    closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
    onClickFilterReport = async (event) => {
        event.preventDefault();
        var geo_fence_address = $("#geo_address").val();
        var geo_fence_aliasname = $("#geo_alias_adresss").val();
        var geo_fence_latitude = $("#geo_latitude").val();
        var geo_fence_longitude = $("#geo_longitude").val();
        var transporter_code = localStorage.getItem('transportercode');
        
        
        var params ={
            geo_fence_address:geo_fence_address,
            geo_fence_aliasname:geo_fence_aliasname,
            geo_fence_latitude:geo_fence_latitude,
            geo_fence_longitude:geo_fence_longitude,
            transporter_code:transporter_code
        } 
        console.log(params,"paramsgeofence");
        
        redirectURL.post("/consignments/updatetptgeofence", params,{
        }).
        then(
            (response)=>{
                console.log(response.data,"response.data1");
                if(response.data == "success")
                    {
                        // this.setState({
                            // show: true,
                            // basicTitle:'Successfully Marked Geo Fence',
                            // basicType:"success",
                            // sliderTranslate:"",
                            // geo_fence_aliasname:"",
                        // });                         
                        this.props.context.geoFenceSuccessUpdate();
                    }
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                
            }
        )
        
    }
    render() {
        const dStyles={
                width:'100%',
                height:'350px'
        }
        return (
            <div className="col-xl-12 xol-lg-12">
                <div className="row">
					<SweetAlert
						show={this.state.show}
						type={this.state.basicType}
						title={this.state.basicTitle}
						onConfirm={this.closeAlert}
						>
					</SweetAlert>
                    <div className="slide-r-title col-xl-12 col-lg-12" style={{ padding: "0px" }}>
                            <h4>
                                Mark GeoFence
                                <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.handleClick.bind(this)} >X</span>
                            </h4>
                            <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" style={{marginTop:"12px", height:"37px"}} id="addresssearch" placeholder="Enter Address to search" />
                        </div>
                        <div className="slide-r-body col-xl-12 col-lg-12" style={{ position: "relative" }}>
                            <div id="map_canvas_tpt_info" className="col-xl-12 col-lg-12" style={dStyles}></div>
                            <form className=" theme-form col-xl-12 col-lg-12" onSubmit={this.onClickFilterReport}>
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 row form-group">
                                            <label className="col-xl-9 col-lg-9">Address</label>
                                                <input 
                                                type="text" 
                                                name="geo_address"
                                                id="geo_address"
                                                autoComplete="off"
                                                style ={{marginLeft:"1em"}}
                                                value={this.props.geo_address}
                                                className="form-control" 
                                                onChange={this.changeHandler.bind(this)} 
                                            />   
                                        </div>
                                        <div className="col-xl-6 col-lg-6 row form-group">
                                            <label className="col-xl-9 col-lg-9">Alias Name</label>
                                            <input 
                                                type="text"
                                                name="geo_alias_adresss"
                                                id="geo_alias_adresss"
                                                autoComplete="off"
                                                style ={{marginLeft:"1em"}}
                                                value={this.state.geo_alias_adresss}
                                                className="form-control" 
                                                onChange={this.changeHandler.bind(this)} 
                                            />    
                                        </div>
                                        <div className="col-xl-6 col-lg-6 row form-group">
                                            <label className="col-xl-6 col-lg-6">Latititude</label>
                                            <input 
                                                type="text"
                                                name="geo_latitude"
                                                id="geo_latitude"
                                                autoComplete="off"
                                                style ={{marginLeft:"1em"}}
                                                value={this.props.geo_latitude}
                                                className="form-control" 
                                                onChange={this.changeHandler.bind(this)} 
                                            />    
                                        </div>
                                        <div className="col-xl-6 col-lg-6 row form-group">
                                            <label className="col-xl-6 col-lg-6">Longitude</label>
                                            <input 
                                                type="text"
                                                name="geo_longitude"
                                                id="geo_longitude"
                                                autoComplete="off"
                                                style ={{marginLeft:"1em"}}
                                                value={this.props.geo_longitude}
                                                className="form-control" 
                                                onChange={this.changeHandler.bind(this)} 
                                            />    
                                        </div>
                                        {/* <div className="col-xl-6 col-lg-6 row form-group">
                                            <label className="col-xl-9 col-lg-9">Radius</label>
                                            <input 
                                                type="text"
                                                name="geo_radius"
                                                id="geo_radius"
                                                autoComplete="off"
                                                style ={{marginLeft:"1em"}}
                                                value={this.state.geo_radius}
                                                className="form-control" 
                                                onChange={this.changeHandler.bind(this)} 
                                            />    
                                        </div> */}
                                        <div className="form-group col-xl-6 col-lg-6">
                                            <button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
                                        </div>
                                    </div>
                                    
                                </form>	
                        </div>
                
                
                </div>
            </div>
        );
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}