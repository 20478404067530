import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class BillingSummary extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
			csvcontent:[],
            billing_month:"",
            from_date:moment.parseZone(new Date()).subtract(3,"month").format("YYYY-MM"),
            to_date:moment.parseZone(new Date()).format("YYYY-MM"),
            transporter_type:"",
            remainder_type:"",
            transporterValue:[],
            multipleTransporterOptions: [],
            bill_generated_month:"",
            remainder_billing_id:"",
            remarks_history_cols:[
                {
	  	          headerName: "Billing Id",
	  	          field: "billing_id",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
	  	          headerName: "Remark",
	  	          field: "complete_remarks",
	  	          width: 180,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Updated Time",
	  	          field: "remark_time",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    try{
                        if(params.data.remark_time != '' && params.data.remark_time != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.remark_time)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
                },
                {
                    headerName: "Remarks By",
                    field: "admin_email",
                    width: 180,
                    editable:false,
                    filter: "agSetColumnFilter"
                }

            ],
            remarks_data:[],

		};
        
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/tptoemmaster":
				department='SNDG';
				departmentName = " ";
				break;
			
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })

       
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/		
	   this.onLoadData();
       if (localStorage.getItem("email") == "billing@enmovil.in"){
       this.setState({
            is_admin:1
       })
    
    }
	  
    };
    onLoadData = () =>{
        var transporter_code = localStorage.getItem('transportercode')
        console.log(transporter_code,"transporter_code");
        
        let bmonth = {
            from_date:this.state.from_date,
            to_date:this.state.to_date
        }
        if(transporter_code.length !== 0 && transporter_code !== undefined && transporter_code !==""){
            bmonth.transporter_code=transporter_code;
        }
        redirectURL.post('/dashboard/getDistinctTransporters',{}).then((response) => {
            var records = response.data.records;
            // console.log(records.length,"records.length")
            this.setState({
                multipleTransporterOptions : records
            });
          })
          .catch(function (error) {
            console.log(error);
        });

        redirectURL.post('/dashboard/billingSummary',bmonth).then((response) => {
          var records = response.data;
        //   records = records.sort(GetSortDescOrder("billing_id"));
          console.log("records ", records)
          this.setState({
              rowData:records,
              overly: "show-n",
            loadshow: "show-n"
          });
        })
        .catch(function (error) {
          console.log(error);
      });
     
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    onClickUpdateRowHandler(event)
	{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
           let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
           let pageTitle = "Update OEM Master Data";
           if (currentDepartmentTitle) {
               pageTitle = "Update OEM Master Data" +  currentDepartmentTitle;
           }
           
           let eventOptions = {
               "category": pageTitle,
               "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
               "label": googleAnalytics.page.action.rowUpdated,
           }
           googleAnalytics.logEvent(eventOptions);
       }
      
       if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
       {
           var params = {
               rowID:this.state.rowid,
               oem_code:this.state.oem_code,
               oem_name:this.state.oem_name,
               industry_type:this.state.industry_type,
               transporter_code:localStorage.getItem("transportercode")
           }
           redirectURL.post("/dashboard/updateoemitem", params,{
               headers:{
                   'Content-Type': 'application/json'
               }
           }).
           then(
               (response)=>{
                   //console.log("update response is : ",response.data);
                   //console.log("Update Response ",response.data.result)
                   if(response.data.status == "success")
                   {
                       this.setState({
                           show: true,
                           basicTitle:'Successfully update data',
                           basicType:"success",
                           sliderUpdate:"",
                           overly:"show-n"
                       });
                       this.onLoadData();
                   }
                   
                   else{
                       this.setState({
                           show: true,
                           basicTitle:'Something went wrong',
                           basicType:"danger",
                       });
                   }
                   
               }
           )
           .catch(function(error){
               console.log(error);
           });
       }
       else{
           this.setState({
               show: true,
               basicTitle:'All * fields should not be empty',
               basicType:"danger",
           });
       }

    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate = getHyphenYYYYMMDD(d)

        this.setState({
            billing_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    handlerStartDateTimeViewBill= (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var startdate =  moment.parseZone(event._d).format('YYYY-MM')

        this.setState({
            selection_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    changeTransporterType = (params) => {
        this.setState({
            transporter_type: params.value
        })
    }

    onChangeRemainderType = (params) => {
        this.setState({
            remainder_type: params.value
        })
    }

    onChangeMultipleTransporter = (item) => {
        this.setState(
			{ transporterValue:item }
		);
    }

    onClickSendRemainders = (event) => {
        event.preventDefault();
        if(this.state.remainder_type != "" && this.state.transporterValue.length != 0){
            var params = {
                remainder_billing_id : this.state.remainder_billing_id,
                bill_generated_month : this.state.bill_generated_month,
                remainder_type:this.state.remainder_type,
                transporterValue : this.state.transporterValue.map((item)=>{
                    return item.value
                }),
            }
            redirectURL.post("/dashboard/sendRemainderMails", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).
            then(
                (response)=>{
                    if(response.data.message == "Success")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'Successfully sent remainder mails',
                            basicType:"success",
                            // sliderUpdate:"",
                            sliderRemainder:"",
                            overly:"show-n"
                        });
                        // this.onLoadData();
                    }
                    
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                    
                }
            )
            .catch(function(error){
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }
        
    }
    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Add OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "Add OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        if(this.state.oem_name != "" && this.state.oem_code != ""
       && this.state.industry_type != "")
        {
           var params = {
                oem_code:this.state.oem_code,
                oem_name:this.state.oem_name,
                industry_type:this.state.industry_type,
                transporter_code:localStorage.getItem("transportercode")
            }
            var fdata = {
                    rownode:params,
                    transporter_code:localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/saveoemitem", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).
            then(
                (response)=>{
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'Successfully added data',
                            basicType:"success",
                            sliderRso:"",
                            overly:"show-n",
                            loadshow:"show-n"
                        });
                        this.onLoadData();
                    }
                    
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                    
                }
            )
            .catch(function(error){
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }

    }
    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
            overly:'show-m',
            oem_code:"",
            oem_name:"",
            industry_type:""
		})
        
    }
    onRowClicked(cell){
        if(cell.colDef.field == "view_remarks")
        {
            this.setState({
                overly:"show-m",
            })
            let params = {billingId:cell.data.billingId}
            let tpt_code = localStorage.getItem("transportercode")
            if(tpt_code !== undefined) params.transporter_code = tpt_code
            let email = localStorage.getItem("email")
            params.admin_email = email
            redirectURL.post("/dashboard/getbillingremarks",params)
            .then((response) => {
                var records = response.data;
            //   records = records.sort(GetSortDescOrder("billing_id"));
              console.log("records ", records)
              this.setState({
                  
                sliderUpdate:'slider-translate-40p',
                remarks_data:records
              });
            })
            .catch(function (error) {
                console.log(error);
            });
        }
        
        if(cell.colDef.field == 'remainder'){
			this.setState({
				sliderRemainder: "slider-translate",
				overly: "show-m",
                bill_generated_month: cell.data.billingMonth[0],
                remainder_billing_id: cell.data.billingId
			})
		}
        
    }
    
    
	 updateRowHandler = async(cell) => {
       
        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
			sliderUpdate:'slider-translate',
            overly:'show-m',
            oem_code:oem_code,
            oem_name:oem_name,
            industry_type:industry_type,
            rowid:rowid
		})
        
    }


    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderRemainder:"",
            sliderBulk:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    onClickGenerateBill(){
        
        var billing_id= moment.parseZone().format("YYYYMMDDHHmmss");
        // console.log(billing_id,"billing_id");
        var billing_getter = this.state.billing_month;
        console.log(billing_getter,"billing_getter");
        var billing_time=billing_getter.split("-");
         console.log(billing_time,"billing time");
        var billing_year = billing_time[0];
        // console.log(billing_year,"billing_year");
        var billing_month = billing_time[1];
        // console.log(billing_month,"billing_month");
        var transporter_type = this.state.transporter_type
 
        if(billing_getter !== "" && billing_getter !== undefined && transporter_type !== "" && transporter_type != undefined){
            var params={
                billing_year:billing_year,
                billing_month:billing_month,
                billing_id : billing_id,
                transporter_type:transporter_type
            }
            console.log(params,"params")
            redirectURL.post('/dashboard/generateBillingMonth',params)
            .then((response) => {
                var records = response.data.records;
                // records = records.sort(GetSortDescOrder("billing_id"));
                //   console.log("records ", records)
                this.setState({
                    rowData: records
                });
                window.location.href="/tptlogin/billingsummary" 
            })
            .catch(function (error) {
                console.log(error);
            });
     
        }
      
    }
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/dashboard/deleteoemitem", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
     
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeOfficerType(officertype){
		this.setState(
			{ officertype },
			() => console.log(`Option selected:`, this.state.officertype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }
    
    onClickShowBulk()
    {
        this.setState({
            overly:"show-m",
			sliderBulk:'slider-translate',
        })
    }

    
    
    handlerStartDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var from_date = d.getFullYear() + "-" + ((d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1));
        
        if (from_date <= this.state.to_date) {
            this.setState({
                from_date: from_date,
            });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'From date should be less than or equal To date',
                basicType: "success",
                loadshow: "show-n",
                overly: "show-n",
            })
        }
    }

    handlerEndDate = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var to_date = d.getFullYear() + "-" + ((d.getMonth() +1)<10?"0"+(d.getMonth()+1):(d.getMonth()+1));
         if (to_date >= this.state.from_date) {
            this.setState({
                to_date: to_date
            });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'To date should be greater than or equal to From date',
                basicType: "success",
                loadshow: "show-n",
                overly: "show-n",
            })
        }
    }

    filterDataByFromAndToDate = (event) => {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        this.onLoadData();
    }

	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'oem_name',
					inputName: 'oem_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'oem_code',
					inputName: 'oem_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'industry_type',
					inputName: 'industry_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
	}
    onSubmitBulkUpload(event)
    {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        var params = {   
            csvcontent:this.state.csvcontent,
        }
		console.log("content ",this.state.csvcontent)
        console.log("Params ", params)
        redirectURL.post("/dashboard/bulkuploadoemdata", params)
        .then((response) => {
            if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully added items',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderBulk:"",
                        file:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
        })
    }
    onClickFilterMOnth(event){
        var transporter_code = localStorage.getItem('transportercode')
        console.log(transporter_code,"TRANSPORTER CODE")
        var billing_getter = this.state.selection_month;
        console.log(billing_getter,"billing_getter");
        
        if(billing_getter !== "" && billing_getter !== undefined){
            var params={                
                billing_year_month:billing_getter, 
                transporter_code:transporter_code,             
            }
        
            console.log(params,"params"); 
            this.setState({
                loadshow:"show-m",
                overly:"show-m",
            });
            
        redirectURL.post("/dashboard/billingdatepicker", params)
        .then((response) => {
            var records = response.data
					this.setState({
						rowData: records,
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderBulk:"",
                    });
        })
        }
    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}

        let isTpt = localStorage.getItem('email') === 'billing@enmovil.in'
		const columnwithDefs = [
	  	        {
                      //view data
	  	          headerName: "",
	  	          field: "",
	  	          width: 80,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  cellRenderer:function(params){
                    let basestring = params.data.billingId;
                    let encryptedstring = window.btoa(basestring);
                    var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="icofont icofont-eye"></i> View</a>';
                    return htmloption
                  }
                },
                {
                    // View Remarks
                    headerName: "",
                    field: "view_remarks",
                    width: 40,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false,
                    cellRenderer:function(params){
                        let basestring = params.data.billingId;
                        let encryptedstring = window.btoa(basestring);
                        var htmloption = '<i class="fa fa-comment f15"></i>';
                        //var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="fa fa-comment f15"></i>View</a>';
                        return htmloption
                      }
		        },
                {
	  	          headerName: "Billing Id",
	  	          field: "billingId",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Billing Approve Status - MSIL",
	  	          field: "billing_status_msil",
	  	          width: 210,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                        if(params.data.billing_status_msil == 1)
                        {
                            return "Approved"
                        }
                        else if(params.data.billing_status_msil ==2)
                        {
                            return "Disapproved"
                        }
                        else if(params.data.billing_status_msil ==3)
                        {
                            return "Final Approved"
                        }
                        else{
                             return "Pending..."
                        }                   
                }
                },
                {
	  	          headerName: "Approval Time - MSIL",
	  	          field: "bill_accept_time_msil",
	  	          width: 210,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                //   hide : isTpt,
                //   hide:function(){
                //     if(localStorage.getItem("email") == "billing@enmovil.in"){
                //         return true;
                //     }
                //     else
                //     {
                //         return false;
                //     }
                //   },
                valueGetter:function(params){
                    try{
                        if(params.data.bill_accept_time_msil != '' && params.data.bill_accept_time_msil != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.bill_accept_time_msil)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
                },
                {
	  	          headerName: "Billing Approve Status - TPT",
	  	          field: "billing_status_transporter",
	  	          width: 210,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  hide : isTpt,
                //   hide:function(){
                //     if(localStorage.getItem("email") == "billing@enmovil.in"){
                //         return true;
                //     }
                //     else
                //     {
                //         return false;
                //     }
                //   },
                  valueGetter:function(params){
                    console.log("params.data.billing_status_transporter",params.data.billing_status_transporter)
                        if(params.data.billing_status_transporter == 1)
                        {
                            return "Approved"
                        }
                        else if(params.data.billing_status_transporter ==2)
                        {
                            return "Disapproved"
                        }
                        else{
                             return "Pending..."
                        }                   
                }
                },
                {
	  	          headerName: "Approval Time - TPT",
	  	          field: "bill_accept_time_transporter",
	  	          width: 210,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  hide : isTpt,
                //   hide:function(){
                //     if(localStorage.getItem("email") == "billing@enmovil.in"){
                //         return true;
                //     }
                //     else
                //     {
                //         return false;
                //     }
                //   },
                valueGetter:function(params){
                    try{
                        if(params.data.bill_accept_time_transporter != '' && params.data.bill_accept_time_transporter != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.bill_accept_time_transporter)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
                },
                
		        {
	  	          headerName: "Bill Generated Month",
	  	          field: "billingMonth",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Total Trucks",
	  	          field: "total_trucks",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
                    headerName: "Primary Trucks",
                    field: "primaryTrucks",
                    width: 150,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false
		        },
                {
                    headerName: "Secondary Trucks",
                    field: "secondaryTrucks",
                    width: 150,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false
		        },
                {
                    headerName: "Billable Amount",
                    field: "billingTCost",
                    width: 180,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false
		        },
                {
                    headerName: "Primary Truck Cost",
                    field: "billingPCost",
                    width: 180,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false
		        },
                {
                    headerName: "Secondary Truck Cost",
                    field: "billingSCost",
                    width: 180,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false
		        },
                
               
                {
                    headerName: "Remarks",
                    field: "complete_remarks",
                    width: 180,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:true,
                    valueSetter:(params)=>{
                        console.log(params)
                        var transporter_code = localStorage.getItem('transportercode');
                        console.log(transporter_code,"transporter_code");
                        var admin_mail = localStorage.getItem('email');
                        if(params.oldValue != params.newValue)
                        {
                            var data = {
                                complete_remarks:params.newValue,
                                billing_id:params.data.billingId,
                                admin_email:admin_mail,
                                transporter_code:transporter_code,
                            }
                            console.log(data,"gettingbillingsummaryvalue");
                            params.data.complete_remarks = params.newValue;
                            
                            redirectURL.post("/dashboard/saveremarksfrombillingsummary", data,{
                                headers:{
                                 'Content-Type': 'application/json'
                                }
                            }).then((response)=>{
                            })
                        }
                    }
		        },
                {
                    headerName: "",
                    field: "remainder",
                    width: 180,
                    filter: false,
                    editable:false,
                    hide : !isTpt,
                    cellRenderer: function (params) {
                        return `<button class="btn btn-info" style="font-size:14px">Send Billing Remainder</button>`
                    },
		        },
              
                
		        // {
                //     headerName: "Action",
                //     field: "_id",
                //     width: 100,
                //     filter: false,
                //     cellRenderer:'deleteItem',
                //     editable:false
		        // }
	    ];

       if( this.state.is_admin == 1 ){
        columnwithDefs.splice(11,0, {
            headerName: "System Status",
            field: "status",
            width: 180,
            filter: false,
            // cellRenderer:'updateItem',
            editable:false,
            valueGetter:function(params){
                if(params.data.status == 0)
                {
                    return "Billing Process Success"
                }
                else if(params.data.status ==1)
                {
                    return "No uptime Data from DB"
                }
                else if(params.data.status ==2)
                {
                     return "No master list from DB"
                }
                else if(params.data.status ==3)
                {
                     return "Registered Devices Data not available in DB"
                } 
                else if(params.data.status ==4)
                {
                     return "Future Billing Date"
                }
                else if(params.data.status ==99)
                {
                     return "Unknown Error"
                } 
                else{
                    return "Status unknown"
                }                      
        }
        },)
       }
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className='card'>
                            <div className="card-header">
                                <div className="row col-xl-12 col-lg-12">
                                    <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <label>From Date</label>
                                            {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                            <Datetime
                                                value={this.state.from_date}
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                inputProps={{
                                                    placeholder: 'Select Month',
                                                    name: 'plan_month',
                                                    autoComplete: 'off',
                                                    readOnly: "readOnly"
                                                }}
                                                dateFormat="YYYY-DD"
                                                name="plan_month"
                                                onChange={this.handlerStartDate.bind(this)}
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <label>To Date</label>
                                            {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                            <Datetime
                                                value={this.state.to_date}
                                                disableCloseOnClickOutside={false}
                                                closeOnSelect={true}
                                                inputProps={{
                                                    placeholder: 'Select Month',
                                                    name: 'plan_month',
                                                    autoComplete: 'off',
                                                    readOnly: "readOnly"
                                                }}
                                                dateFormat="YYYY-DD"
                                                name="plan_month"
                                                onChange={this.handlerEndDate.bind(this)}
                                            />
                                        </div>
                                        <div className="form-group col-xl-2 col-lg-2">
                                            <label>&nbsp;</label><br />
                                            <button type="button" className="btn btn-success" style={{ padding: "5px 10px", marginRight: "2%" }} onClick={this.filterDataByFromAndToDate.bind(this)}>Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Billing Summary </span>
                                       {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button> */}
                                        {/* <button href="/tptlogin/billingsummary" type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-warning float-right">Generate Bill</button> */}
                                        { (localStorage.getItem("email") == "billing@enmovil.in") ?
                                        <button type="button" onClick={() => this.onClickShowBulk(2)} className="btn btn-warning float-right">Plan New Bill</button>:
                                        <button type="button" onClick={() => this.onClickShowBulk(2)} className="btn btn-success float-right">View Bill</button>
                                        }
                                </h5>
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div> */}
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                            <label>Select Month</label>
                                <Datetime
                                    value={this.state.billing_month} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ 
                                        placeholder: '', 
                                        name: 'billing_month', 
                                        autoComplete:'off' 
                                    }}
                                    dateFormat="YYYY-DD" 
                                    name="billing_month" 
                                    onChange={this.handlerStartDateTime.bind(this)} 

                                    />
                            </div>				   			 */}
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Code *</label>
								<input 
									type="text" 
									name="oem_code"
									value={this.state.oem_code}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Name *</label>
								<input 
									type="text" 
									name="oem_name"
									value={this.state.oem_name}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Industry Type *</label>
								<input 
									type="text" 
									name="industry_type"
									value={this.state.industry_type}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							                       
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>



                <div className={"slide-r "+(this.state.sliderUpdate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Remarks History
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>
                        <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            
						</form>

						
                        </div>
					</div>
				</div>
                
                { (localStorage.getItem("email") == "billing@enmovil.in") ?
                <div className={"slide-r "+(this.state.sliderRemainder)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Send Remainder Mails
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Remainder Type*</label>
                                <Select 
                                placeholder={"Select Transporter"}
                                closeMenuOnSelect={true}
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={[
                                    {value:"First Reminder",label:"First Remainder"},
                                    {value:"Second Reminder",label:"Second Remainder"},
                                    {value:"Third Reminder",label:"Third Remainder"},
                                    {value:"Final Reminder",label:"Final Remainder"}
                                ]   
                                }
                                onChange={this.onChangeRemainderType.bind(this)}
                                required
                                /> 
                            </div> 
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Transporters*</label>
                                <Select placeholder={"Select Transporters"} closeMenuOnSelect={false} value={this.state.transporterValue} isMulti="true" className="border-radius-0" onChange={this.onChangeMultipleTransporter.bind(this)} style={{ borderRadius: "0px" }} options={this.state.multipleTransporterOptions} required />
                            </div> 
                            </div>
							 
							<div className="col-xl-12 col-lg-12 c">
                            <button type="button" onClick={this.onClickSendRemainders.bind(this)} className="btn btn-success">Send</button>
							</div>
						</form> 
                    
						
                        </div>
					</div>
				</div>:""}

                { (localStorage.getItem("email") == "billing@enmovil.in") ?
                <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Plan New Bill
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Transporter*</label>
                                <Select 
                                placeholder={"Select Transporter"}
                                closeMenuOnSelect={true}
                                className={"border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={[
                                    {value:"GBFC",label:"GBFC"},
                                    {value:"ALL",label:"Non GBFC (all others)"}
                                ]   
                                }
                                onChange={this.changeTransporterType.bind(this)}
                                required
                                /> 
                            </div> 
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Billing Date*</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off',
                                        required: true,
                                    }}
                                    dateFormat="YYYY-MM-DD"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTime.bind(this)}

                                />
                            </div>  
                            </div>
							 
							<div className="col-xl-12 col-lg-12 c">
                            <button type="button" onClick={this.onClickGenerateBill.bind(this)} className="btn btn-success">Generate Bill</button>
							</div>
                            
						    {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
						</form> 
                    
						
                        </div>
					</div>
				</div>:
                <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           View Bill
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickFilterMOnth.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Select Billing Date</label>
                                <Datetime
                                    value={this.state.billing_month}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    inputProps={{
                                        placeholder: '',
                                        name: 'billing_month',
                                        autoComplete: 'off'
                                    }}
                                    dateFormat="YYYY-MM"
                                    name="billing_month"
                                    onChange={this.handlerStartDateTimeViewBill.bind(this)}

                                />
                            </div>  
                            </div>
							 
							<div className="col-xl-12 col-lg-12 c">
                            <button type="button" onClick={this.onClickFilterMOnth.bind(this)} className="btn btn-success">Get Bill</button>
							</div>
                            
						    {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
						</form> 
                
						
                        </div>
					</div>
				</div>
                }


                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}