
var GoogleInfoWindow = (headerTitle) => {
    //console.log("Props ", headerTitle)
    var content = "<div>";
    content += "<div style='width: 36px;margin-top: 22%;font-weight: bold;margin-left: 3%;'>"+headerTitle+"</div>";
    
    content += "</div>";
    //console.log("Geo Infowindow content ", content)
    return content;
}


module.exports = GoogleInfoWindow;