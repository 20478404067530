import React, { Component } from 'react';
// import { useState } from 'react';

// import ReactDOM from 'react-dom';
// import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
// import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
// import { scaleQuantile } from 'd3-scale';
// import ReactTooltip from 'react-tooltip';
import Select from "react-select";
// import LinearGradient from './LinearGradient';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
// import MarkerClusterer from "@google/markerclusterer";
import Map from './mapLocationComponent';
import Constant from "../common/googlemapkey";
import redirectURL from '../redirectURL';
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';
var globForecastDay="Today";
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=5;
var coordinates=[];
var map;
var marker;
var markers = [];
var circle;
var moment = require('moment');
// const INDIA_TOPO_JSON = require('../india.topo.json');

//         const PROJECTION_CONFIG = {
//           scale: 350,
//           center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
//         };
        
//         // Red Variants
//         const COLOR_RANGE = [
//           '#ffedea',
//           '#ffcec5',
//           '#ffad9f',
//           '#ff8a75',
//           '#ff5533',
//           '#e2492d',
//           '#be3d26',
//           '#9a311f',
//           '#782618'
//         ];
        
//         const DEFAULT_COLOR = '#EEE';
        
//         const getRandomInt = () => {
//           return parseInt(Math.random() * 100);
//         };
        
//         const geographyStyle = {
//           default: {
//             outline: 'none'
//           },
//           hover: {
//             fill: '#ccc',
//             transition: 'all 250ms',
//             outline: 'none'
//           },
//           pressed: {
//             outline: 'none'
//           },
//         };
        
//         // will generate random heatmap data on every call
//         const getHeatMapData = () => {
//           return [
//             { id: 'AP', state: 'Andhra Pradesh', value: getRandomInt() },
//             { id: 'AR', state: 'Arunachal Pradesh', value: getRandomInt() },
//             { id: 'AS', state: 'Assam', value: getRandomInt() },
//             { id: 'BR', state: 'Bihar', value: getRandomInt() },
//             { id: 'CT', state: 'Chhattisgarh', value: getRandomInt() },
//             { id: 'GA', state: 'Goa', value: 21 },
//             { id: 'GJ', state: 'Gujarat', value: 22 },
//             { id: 'HR', state: 'Haryana', value: getRandomInt() },
//             { id: 'HP', state: 'Himachal Pradesh', value: 24 },
//             { id: 'JH', state: 'Jharkhand', value: 26 },
//             { id: 'KA', state: 'Karnataka', value: 27 },
//             { id: 'KL', state: 'Kerala', value: getRandomInt() },
//             { id: 'MP', state: 'Madhya Pradesh', value: getRandomInt() },
//             { id: 'MH', state: 'Maharashtra', value: getRandomInt() },
//             { id: 'MN', state: 'Manipur', value: getRandomInt() },
//             { id: 'ML', state: 'Meghalaya', value: 59 },
//             { id: 'MZ', state: 'Mizoram', value: getRandomInt() },
//             { id: 'NL', state: 'Nagaland', value: 59 },
//             { id: 'OR', state: 'Odisha', value: 59 },
//             { id: 'PB', state: 'Punjab', value: getRandomInt() },
//             { id: 'RJ', state: 'Rajasthan', value: getRandomInt() },
//             { id: 'SK', state: 'Sikkim', value: getRandomInt() },
//             { id: 'TN', state: 'Tamil Nadu', value: getRandomInt() },
//             { id: 'TG', state: 'Telangana', value: getRandomInt() },
//             { id: 'TR', state: 'Tripura', value: 14 },
//             { id: 'UT', state: 'Uttarakhand', value: getRandomInt() },
//             { id: 'UP', state: 'Uttar Pradesh', value: 15 },
//             { id: 'WB', state: 'West Bengal', value: 17 },
//             { id: 'WB', state: 'West Bengal', value: 17 },
//             { id: 'AN', state: 'Andaman and Nicobar Islands', value: getRandomInt() },
//             { id: 'CH', state: 'Chandigarh', value: getRandomInt() },
//             { id: 'DN', state: 'Dadra and Nagar Haveli', value: 19 },
//             { id: 'DD', state: 'Daman and Diu', value: 20 },
//             { id: 'DL', state: 'Delhi', value: 59 },
//             { id: 'JK', state: 'Jammu and Kashmir', value: 25 },
//             { id: 'LA', state: 'Ladakh', value: getRandomInt() },
//             { id: 'LD', state: 'Lakshadweep', value: getRandomInt() },
//             { id: 'PY', state: 'Puducherry', value: getRandomInt() }
//           ];
//         };
        


// export default className WeatherMapComponent extends Component {

//     constructor(props) {
//         super(props);
//         this.state={
//             modules: AllModules,
//             truckData:this.props.truckInfo,
//             consignmentlists:this.props.consignments,
//             defaultColDef: {
//                 sortable: true,
//                 filter: true,
//                 editable: true,
//             },
//             rowData: null,
//             stateName:"States",
// 			animateRows: true,

//         };
//     }

//     render()
//     {
        
        
        
//           var tooltipContent="State";
//           var setTooltipContent="";
//           const data = getHeatMapData();
//           const setData = getHeatMapData();
//         // console.log(data);
//           const gradientData = {
//             fromColor: COLOR_RANGE[0],
//             toColor: COLOR_RANGE[COLOR_RANGE.length - 1],
//             min: 0,
//             max: data.reduce((max, item) => (item.value > max ? item.value : max), 0)
//           };
        
//           const colorScale = scaleQuantile()
//             .domain(data.map(d => d.value))
//             .range(COLOR_RANGE);
        
//           const onMouseEnter = (geo, current = { value: 'NA' }) => {
//             return () => {
                
//                 var stateName =geo.properties.name;
//                 this.setState({
//                     stateName : stateName
//                 })
//                 // alert(stateName)

//             };
//           };
        
//           const onMouseLeave = () => {
//             tooltipContent = "";
//           };
        
//           const onChangeButtonClick = () => {
//             setData(getHeatMapData());
//           };
        
       
        
//         return(
//             <div classNameName="full-width-height container">
//       {/* <h1 classNameName="no-margin center">States and UTs</h1> */}
//       <ReactTooltip>{this.state.stateName}</ReactTooltip>
//         <ComposableMap
//           projectionConfig={PROJECTION_CONFIG}
//           projection="geoMercator"
//           width={325}
//           height={232}
//           data-tip=""
//         >
//           <Geographies geography={INDIA_TOPO_JSON}>
//             {({ geographies }) =>
//               geographies.map(geo => {
//                 //console.log(geo.id);
//                 const current = data.find(s => s.id === geo.id);
//                 return (
//                   <Geography
//                     key={geo.rsmKey}
//                     geography={geo}
//                     fill={current ? colorScale(current.value) : DEFAULT_COLOR}
//                     style={geographyStyle}
//                     onMouseEnter={onMouseEnter(geo, current)}
//                     onMouseLeave={onMouseLeave}
//                   />
//                 );
//               })
//             }
//           </Geographies>
//         </ComposableMap>
//         <LinearGradient data={gradientData} />
//         <div classNameName="center">
//           {/* <button classNameName="mt16" onClick={onChangeButtonClick}>Change</button> */}
//         </div>
//     </div>
//         )
//     }
// }


export default class WeatherMap extends Component {

      constructor(props) {
          super(props);
          this.state={
            weatherData : "",
            forecastDay : "",
            forecastOptions : [],
            weatherPlants : [],
            rowData : [],
            modules: AllModules,
            path : "",
          };
      }
  
      componentDidMount(){
        redirectURL.post("/weather/getWeatherData").then((response)=>{
          console.log(response.data);
          const uniqueDAta = [...new Set(response.data.ForecastData.map(item => item.description))];
          console.log(uniqueDAta,"uniqueDAta")
          this.setState({
            weatherData : response.data.ForecastData
          })
          redirectURL.post("/weather/getPlants").then((response)=>{
            console.log(response.data.ForecastData);
            
            var path = window.location.href.split("//")[1].split("/")[0];
            
            this.setState({
              weatherPlants : response.data,
              forecastDay : "Today",
              path : path
            })
            this.renderMapWeather();
          })
          
        }).catch(function(error){
          console.log(error);
        })
        // this.renderMap() 
        
      }
      // componentWillReceiveProps(newProps){
      //   this.setState({
      //     weatherData : newProps.weatherData,
      //     forecastDay : newProps.forecastDay
      //   })
      // }

      renderMapWeather = () => {    	
        loadScriptWeather("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMapWeather");
        window.initMapWeather = this.initMapWeather
       }
    
       initMapWeather = () => {
        try
          {
        //console.log("here slidebar")
          // $("#mapweather").empty();
          //var coordsvalues;
          var map = new window.google.maps.Map(document.getElementById('mapweather'), {
            zoom: 8,
            center: new window.google.maps.LatLng(28.497236,77.062363),
            mapTypeId: window.google.maps.MapTypeId.HYBRID,
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: window.google.maps.ControlPosition.TOP_RIGHT
            },            
            disableDefaultUI: true,
            fullscreenControl: true,
            zoomControl: true
          });
      
          if(this.state.weatherPlants.length > 0){
            this.state.weatherPlants.map(function(params){
              var plant = {
                url: require("../../assets/icons/"+params.icon), // url
                scaledSize: new window.google.maps.Size(32,32), // scaled size
                origin: new window.google.maps.Point(0,0), // origin
                anchor: new window.google.maps.Point(0, 0) // anchor
              };
              var coords = JSON.parse(params.coordinates);
              // console.log(coords);
              var marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(coords.lat,coords.lng),
                map: map,
                icon: plant,
                title : params.plant_name
              });
              var cityCircle = new window.google.maps.Circle({
                strokeColor: '#9fc9b9',
                strokeOpacity: 0.3,
                strokeWeight: 2,
                fillColor: '#9fc9b9',
                fillOpacity: 0.6,
                map: map,
                center:coords,
                radius: 50 * 1000
              });
            })
          }
          // var iconObject = {"heavyrain":heavyrain,"Sandstorm":Sandstorm,"cyclone":cyclone,"wind":wind,"hailstorm":hailstorm,"thunderstorm":thunderstorm}
          var bounds = new window.google.maps.LatLngBounds();
          
            if(this.state.weatherData.length > 0)
            {
              if(globForecastDay == "Today")
              {
                var filterRecDate = moment.parseZone(new Date()).format("DD-MM-YYYY");
                var filterRecords =  this.state.weatherData.filter(function(params){
                  return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate
                })
              }
              else
              {
                var filterRecDate = moment.parseZone(new Date(+new Date() + 86400000)).format("DD-MM-YYYY");
                var filterRecords =  this.state.weatherData.filter(function(params){
                  return moment.parseZone(new Date(params.forecast_date)).format("DD-MM-YYYY") == filterRecDate;
                })
              }
              console.log("filterRecords ", filterRecords)
              console.log("filterRecords.length ",filterRecords.length)
              if(filterRecords.length > 0)
              {
                filterRecords.map(function(params)
                {            
                  if(params.address.includes("NA") == false && params.address != "/")
                  {
                    bounds.extend(new window.google.maps.LatLng(params.latitude,params.longitude));
                    var url="";
                    var iconName = "";
                    
                    if(params.description.includes("Thunderstorm") == true){
                      url = require("../../assets/icons/thunderstorm.png")
                    }
                    else if(params.description.includes("Heavy Rain and Strong Winds") == true){
                      url = require("../../assets/icons/wind.png")
                    }
                    else if(params.description.includes("Heavy Rain") == true){
                      url = require("../../assets/icons/Heavyrain.png")
                    }
                    else if(params.description.includes("Cyclone") == true){
                      url = require("../../assets/icons/cyclone.png")
                    }
                    else if(params.description.includes("Hailstroms") == true){
                      url = require("../../assets/icons/hailstorm.png")
                    }
                    else if(params.description.includes("Sandstorm") == true || params.description.includes("Duststorm") == true){
                      url = require("../../assets/icons/sandstorm.png")
                    }
                    else
                    {
                      
                    }
                    var iconName = {
                      url: url, // url
                      scaledSize: new window.google.maps.Size(32, 32), // scaled size
                      origin: new window.google.maps.Point(0,0), // origin
                      anchor: new window.google.maps.Point(0, 0) // anchor
                    };
                    
                    var marker = new window.google.maps.Marker({
                      position: new window.google.maps.LatLng(params.latitude,params.longitude),
                      map: map,
                      icon: iconName,
                      // title : ""+params.address+","+params.forecast_date+","+params.description
                    });
                    var infowindow = new window.google.maps.InfoWindow({
                      content: "<div class='weather-marker-tooltip'><div class='forecast-date'><label class='forecast-labels'> Forecast Date : </label> "+moment.parseZone(new Date(params.forecast_date)).format("DD-MMM-YYYY")+"</div><div class='address'> <label class='forecast-labels'>Address :</label> "+params.address+"</div><div class='weather-desc'><label class='forecast-labels'> Weather : </label> "+params.description+"</div><div class='last-updated'><label class='forecast-labels'> Last Updated On : </label> "+getHyphenDDMMMYYYYHHMM(params.last_updated_on)+"</div>"
                      });
                    window.google.maps.event.addListener(marker, 'mouseover', function() {
                      infowindow.open(map,marker);
                      });
                      window.google.maps.event.addListener(marker, 'mouseout', function() {
                      infowindow.close();
                      });
                  }
                // }
                })
                $("#mapweather").css("visibility","visible");
                $(".forecast-warning").css("display","none");
              }
              else
              {
                //$("#mapweather").css("visibility","hidden");
                $(".forecast-warning").css("display","block");
              }
              map.addListener('zoom_changed', function() {
                mapZoom=map.getZoom();
              });
              map.fitBounds(bounds)
            }
          }catch(e){}
          
        }
      
        forecastOption(params){
          // let forecastOptions = [];
          this.state.forecastOptions=[];
          this.state.forecastOptions.push({value:"Today",label:"Today"});
          this.state.forecastOptions.push({value:"Tomorrow",label:"Tomorrow"})
          return this.state.forecastOptions;
        }
      changeForecastData = (event) =>{
        this.setState({
          forecastDay : event.value
        });
        globForecastDay = event.value;
        // console.log(event.value);
        this.renderMapWeather()
          // this.initMapWeather()
      }  
      onCloseRouteDiv = (event) => {
        this.props.context.onCloseRouteDiv();
      }
      ShowImage()
      {
      }
      render(){
        const columnwithDefs = [
          {
            headerName : "Plant Name",
            field : "plant_name",
            resizable : true,
            hide:false,
            width : 80
          },
          {
            headerName : "Forecast Today",
            field : "weather_today",
            resizable : true,
            width : 110
          },
          {
            headerName : "Forecast Tomorrow",
            field : "weather_tomorrow",
            resizable : true,
            width : 110
          }
        ]
        const dStyles={
          width:'65%',
          height:'600px',
          // marginTop : "20px"
        }
        const forecastOption = [
          { value: "Today", label: "Today" },
          { value: "Tomorrow", label: "Tomorrow" },
        ];
        const colourStyles = {
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            console.log("stlel");
            console.log({ data, isDisabled, isFocused, isSelected });
            return {
              ...styles,
              backgroundColor: isFocused ? "blue" : null,
              color: isFocused?"white":"black",
            };
          }
        };
        var mgmtweathermap = ""
        try{
          mgmtweathermap = (this.props.match.path == "/mgmtweathermap")?"Mobile": "";
        }
        catch(e){
          
        }
        return(
          <div style={{width:"100%",height:"100%"}}>
            {(mgmtweathermap != "Mobile")?
                <div className="slide-r slider-translate-60p weather-sidebar" style={{overflow:"auto"}}>
                <div className="slide-r-title row col-xl-12 col-lg-12" style={{background:"#21759d",color:"#fff"}}>
                    <div className="col-sm-3 col-xs-3 col-md-3"></div>
                    <div className="col-sm-3 col-xs-3 col-md-3" style={{textAlign: "right"}}>
                      <label className="Forecast-Header" style={{paddingTop: "9px"}}>Weather Forecast For</label>
                    </div>
                    <div className="col-sm-3 col-xs-3 col-md-3" style={{paddingLeft:"0px"}}>
                        <Select
                          defaultValue={forecastOption[0]}
                          // placeholder="Today"
                          onChange={this.changeForecastData.bind(this)} 
                          closeMenuOnSelect={true}
                          options={forecastOption}
                          styles={colourStyles}
                        />
                    </div>
                    <div class="col-xl-2"></div>
                    <div class="col-xl-1">
                    <span className="float-right closebtn" style={{marginTop: "4px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
                    </div>
                </div> 
                <div className="slide-r-body" style={{position:"relative"}}>
                  <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv">
                        <div id="mapweather" className="col-sm-8 col-xs-12 col-md-8 two" style={dStyles}></div>
                        {(this.state.weatherPlants != '')?
                          <div className="col-sm-4 col-xs-12 col-md-4 ag-theme-balham one">
                            <div className="col-sm-12 col-xs-12 col-md-12" id="weathermapgrid" style={{width:"100%",height:"350px"}}>
                              <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.weatherPlants}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                // rowSelection={this.state.rowSelection}
                                // frameworkComponents={this.state.frameworkComponents}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
                                //editType={this.state.editType}
                                // stopEditingWhenGridLosesFocus={true}
                                //floatingFilter={true}
                                // enableCellChangeFlash={true}
                                // suppressCellFlash={true}
                                // rowClassRules={this.state.rowClassRules}
                                //onCellClicked={this.onRowClicked.bind(this)}
                                // gridOptions={{
                                //     context: { componentParent: this }
                                // }}
                                // onRowSelected={this.onRowSelection.bind(this)}
                                // suppressRowClickSelection={true}
  
                              />
                            </div>
                          <div className="col-sm-12 col-xs-12 col-md-12 forecast-warning three">
                            <span className="error-message"><i class="icofont icofont-exclamation-square"></i> No Forecast Information Available</span>
                          </div>
                            <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                              {/* <div className="col-xl-12 col-lg-12"> */}
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/Heavyrain.png')}></img> <figcaption>Heavy Rain</figcaption>
                                </div>
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/wind.png')}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
                                </div>
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/thunderstorm.png')}></img><figcaption> Thunderstorm </figcaption>
                                </div>
                              {/* </div> */}
                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                              {/* <div className="col-xl-6"> */}
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/hailstorm.png')}></img>&nbsp;<figcaption> Hailstorm </figcaption>
                                </div>
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require("../../assets/icons/cyclone.png")}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
                                </div>
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/sandstorm.png')}></img><figcaption> Sandstorm </figcaption>
                                </div>
                              {/* </div> */}
                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-12 three" style={{marginTop:"35px"}}>
                              <a href={"http://3.6.65.252/get_cyclone_report"} target="_blank" onClick={this.ShowImage.bind(this)} style={{color:"red"}}><h6><i className="icofont icofont-link-alt"></i> Click here for IMD Cyclone Information</h6></a>
                            </div>
                        </div>
                          :""}
                        
                        </div>
                        
                  </div>
                </div>
            :
              <div className="col-xl-12 col-lg-12 card" style={{marginTop:"2em"}}>
                <div className="row" style={{marginBottom:"1em", marginTop:"1em"}}>
                    <div className="col-sm-2 col-xs-3 col-md-2"></div>
                    <div className="col-sm-3 col-xs-3 col-md-3" style={{textAlign: "right"}}>
                      <label className="Forecast-Header" style={{paddingTop: "9px"}}>Weather Forecast For</label>
                    </div>
                    <div className="col-sm-3 col-xs-3 col-md-3" style={{paddingLeft:"0px"}}>
                        <Select
                          defaultValue={forecastOption[0]}
                          // placeholder="Today"
                          onChange={this.changeForecastData.bind(this)} 
                          closeMenuOnSelect={true}
                          options={forecastOption}
                          styles={colourStyles}
                        />
                    </div>
                </div>
                <div class="col-xl-12 col-sm-12 col-xs-12 col-md-12 row wdiv">
                    <div id="mapweather" className="col-sm-8 col-xs-12 col-md-8 two" style={dStyles}></div>
                        {(this.state.weatherPlants != '')?
                          <div className="col-sm-4 col-xs-12 col-md-4 ag-theme-balham one">
                            <div className="col-sm-12 col-xs-12 col-md-12" id="weathermapgrid" style={{width:"100%",height:"350px"}}>
                              <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.weatherPlants}
                                enableRangeSelection={true}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                // rowSelection={this.state.rowSelection}
                                // frameworkComponents={this.state.frameworkComponents}
                                // detailCellRendererParams={this.state.detailCellRendererParams}
                                //editType={this.state.editType}
                                // stopEditingWhenGridLosesFocus={true}
                                //floatingFilter={true}
                                // enableCellChangeFlash={true}
                                // suppressCellFlash={true}
                                // rowClassRules={this.state.rowClassRules}
                                //onCellClicked={this.onRowClicked.bind(this)}
                                // gridOptions={{
                                //     context: { componentParent: this }
                                // }}
                                // onRowSelected={this.onRowSelection.bind(this)}
                                // suppressRowClickSelection={true}
  
                              />
                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-12 forecast-warning three">
                              <span className="error-message"><i class="icofont icofont-exclamation-square"></i> No Forecast Information Available</span>
                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                              {/* <div className="col-xl-12 col-lg-12"> */}
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/Heavyrain.png')}></img> <figcaption>Heavy Rain</figcaption>
                                </div>
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/wind.png')}></img>&nbsp;&nbsp; <figcaption>Heavy Rain & Winds</figcaption>
                                </div>
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/thunderstorm.png')}></img><figcaption> Thunderstorm </figcaption>
                                </div>
                              {/* </div> */}
                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-12 map-legends row three" style={{ marginTop: "10px",marginLeft: "10px"}}>
                              {/* <div className="col-xl-6"> */}
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/hailstorm.png')}></img>&nbsp;<figcaption> Hailstorm </figcaption>
                                </div>
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require("../../assets/icons/cyclone.png")}></img>&nbsp;&nbsp; <figcaption>Cyclone</figcaption>
                                </div>
                                <div className="col-sm-4 col-xs-4 col-md-4">
                                  <img src={require('../../assets/icons/sandstorm.png')}></img><figcaption> Sandstorm </figcaption>
                                </div>
                              {/* </div> */}
                            </div>
                            <div className="col-sm-12 col-xs-12 col-md-12 three" style={{marginTop:"35px"}}>
                              <a href={"http://3.6.65.252/get_cyclone_report"} target="_blank" onClick={this.ShowImage.bind(this)} style={{color:"red"}}><h6><i className="icofont icofont-link-alt"></i> Click here for IMD Cyclone Information</h6></a>
                            </div>
                          </div>
                          :""}
                                                
                    </div>
                
                </div>

            }
            
            </div>
        )
      }
    }
          
function loadScript(url){
      var index  =window.document.getElementsByTagName("script")[0]
      var script = window.document.createElement("script")
      script.src=url
      script.async=true
      script.defer = true
      index.parentNode.insertBefore(script,index)
  }

  function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = Math.floor(hours);
    var minutes = (n) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
  }                              
  var currentinfowindow = null;
  function getTimeInfo(marker,timedet,coords)
  {
    var timeinfo = new window.google.maps.InfoWindow({
            content: "Time at this location: " + timedet
          });
  
    marker.addListener('click', function() {
      
          if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
          }
          else
          {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
          }
          
          });
  }
  
  
   function clearSelection() {
        if (selectedShape) {
          selectedShape.setEditable(false);
          selectedShape = null;
        }
      }
  
     function setSelection(shape) {
        clearSelection();
        selectedShape = shape;
        //console.log("selectedShape",selectedShape);
        shape.setEditable(true);
        
        selectColor(shape.get('fillColor') || shape.get('strokeColor'));
      }
  
    function deleteSelectedShape() {
      fencingLength=0;  
        if (selectedShape) {
          selectedShape.setMap(null);
        }
        // To show:
         drawingManager.setOptions({
           drawingControl: true
         });
      }
  
      
     function selectColor(color) {
          selectedColor = color;
          for (var i = 0; i < colors.length; ++i) {
            var currColor = colors[i];
            //console.log(colorButtons[currColor]);
            //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
          }
  
          // Retrieves the current options from the drawing manager and replaces the
          // stroke or fill color as appropriate.
          var polylineOptions = drawingManager.get('polylineOptions');
          polylineOptions.strokeColor = color;
          drawingManager.set('polylineOptions', polylineOptions);
  
          var rectangleOptions = drawingManager.get('rectangleOptions');
          rectangleOptions.fillColor = color;
          drawingManager.set('rectangleOptions', rectangleOptions);
  
          var circleOptions = drawingManager.get('circleOptions');
          circleOptions.fillColor = color;
          drawingManager.set('circleOptions', circleOptions);
  
          var polygonOptions = drawingManager.get('polygonOptions');
          polygonOptions.fillColor = color;
          drawingManager.set('polygonOptions', polygonOptions);
        }
  
    function setSelectedShapeColor(color) {
          if (selectedShape) {
            if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
              selectedShape.set('strokeColor', color);
            } else {
              selectedShape.set('fillColor', color);
            }
          }
        }
  
      function makeColorButton(color) {
          var button = document.createElement('span');
          button.classNameName = 'color-button';
          button.style.backgroundColor = color;
          window.google.maps.event.addDomListener(button, 'click', function() {
            selectColor(color);
            setSelectedShapeColor(color);
          });
  
          return button;
        }
  
      function buildColorPalette() {
           var colorPalette = document.getElementById('color-palette');
           for (var i = 0; i < colors.length; ++i) {
             var currColor = colors[i];
             var colorButton = makeColorButton(currColor);
             colorPalette.appendChild(colorButton);
             colorButtons[currColor] = colorButton;
           }
           selectColor(colors[0]);
         }  
      
     function getSelectedShape(coords){
      var lt=[]
      //console.log("coordsLatlngs ",coords);
      for (let value of Object.values(coords)) {
        //console.log("Val is ",value); // John, then 30
        lt.push(value)
      }
      //console.log("Combine ",lt);
      document.getElementById('coords').value=lt;
      //return lt;
    }
  
    function loadScriptWeather(url){
      console.log(window.screen.availHeight,
      window.screen.availWidth)
      if(window.screen.availHeight <= 1024)
      {
        $("#mapweather").removeClass("col-md-8");
        $("#weathermapgrid").removeClass("col-md-4");
        $("#mapweather").addClass("col-md-8");
        $("#weathermapgrid").addClass("col-md-8");
      }
      var index  =window.document.getElementsByTagName("script")[1]
        var script = window.document.createElement("script")
        script.src=url
        script.async=true
        script.defer = true
        index.parentNode.insertBefore(script,index)
    }
  
