/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
// import UpdateButton from "../layouts/updatelmstates";
// import UpdateTransitButton from "../layouts/updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ShowTPTGpsMap from "./showtptgpsmap";
import DrawMap from '../common/drawmap';
import NextBillionMaps from '../common/nbmaps';
import ExportPDF from "./exportpdf"

import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class TptStoppagesReport extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "TPT Stoppage Report",
            screenpage :'TPT Stoppage Report',
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				showtptgpsmap:ShowTPTGpsMap,
                // UpdateButton:UpdateButton,
                // UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            
            screenView: "",
            todayInstalled: [],
            totalInstalled: [],
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			showTrucksList : "show-n",
			startDate:'',
			endDate:'',
			truckNos:[],
			mapinfo:[],
			sliderRouteTranslate:'',
			tpttrucks:[],
			trucklist:[],
            truckno:[{"value":"","label":""}],
            truckNoList :[],
            transporterTrucks:[],
            filteredData:[],
            duration:{"value":"30","label":"30 Mins"},
			adaniCheck:0,
            pdfRowData: [],
            pdfColumns: [],
            addressColumn : 300,
		};
		this.onShowTPTGPSMapRoute = this.onShowTPTGPSMapRoute.bind(this);
		this.loadData = this.loadData.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount = async () =>{
       
        var tptCode = localStorage.getItem('transportercode');
        if(tptCode.includes("T293") == true)
        {
            var adaniCheck = 1;
        }
        else
        {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck : adaniCheck
        });
		var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			    var records = response.data.records;
               this.setState({
					tpttrucks:records
				})
			})
		}        
        
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if(is_enmovil_transporter == 1)
        {
			loadDateTimeScript();
			var edate = moment.parseZone().format('DD-MM-YYYY');
			var sdate = moment.parseZone().subtract(5, 'days').format('DD-MM-YYYY')
			var enddate = moment.parseZone().format('YYYY-MM-DD');
			var startdate = moment.parseZone().subtract(5, 'days').format('YYYY-MM-DD')
			// $("#filterFromDate").val(sdate);
			// $("#toDate").val(edate);
			this.setState({
				loadshow:'show-m',
				overly:'show-m'
			});
            this.logPageView(); // Log page view to GA
            var screenView = "";
            var transporter_code = [];
            // if(localStorage.getItem('transportercode') !=undefined)
            // {
            //     var transporter_code = JSON.parse(localStorage.getItem("transportercode"));
            //     //var transporter_code = tptCode[0];
			// }
            if(localStorage.getItem('transportercode') !=undefined)
            {
				if(localStorage.getItem("transportercode") != "all")
				{
					var transporter_code = JSON.parse(localStorage.getItem("transportercode"));
            	}
				else{
					var transporter_code = localStorage.getItem("transportercode");
            	}
                //var transporter_code = tptCode[0];
			}
            var deviceparams = {
                transporter_code:localStorage.getItem("transportercode")
            }
            this.loadData(deviceparams);
        }
        else
        {
            window.location.href="/";
        }
	}
	loadData = async (parameters) =>{
// 		trucks_list - json array (required)
// from_date - %Y-%m-%d %H:%M:%S (required)
// to_date - %Y-%m-%d %H:%M:%S (required)
// break_duration - (optional)

        redirectURL.post("/dashboard/carrierDevices", parameters).then((response1)=>{
            var transporterTrucks = [];
            var tptrucks = [];
            if(localStorage.getItem('transportercode') !=undefined)
            {
				if(localStorage.getItem("transportercode") != "all")
				{
					var tptCode = JSON.parse(localStorage.getItem("transportercode"));
                    response1.data.devices.map(function(e){
                        if(tptCode.indexOf(e.transporter_code) > -1 )
                        {
                            transporterTrucks.push(e)
                            tptrucks.push(e.truck_no)
                        }
                    })
            	}
				else{
					var tptCode = localStorage.getItem("transportercode");
                    response1.data.devices.map(function(e){
                            transporterTrucks.push(e)
                            tptrucks.push(e.truck_no)
                    })
            	}
                //var transporter_code = tptCode[0];
			}
            // var tptCode = JSON.parse(localStorage.getItem("transportercode"));
            // console.log("transporterTrucks ", transporterTrucks)
            this.setState({
                truckNoList :tptrucks,
                transporterTrucks:transporterTrucks,
                loadshow:'show-n',
				overly:'show-n'
            })
        });
        this.getUserGridState()
	}
    getUserGridState(){
		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname , screentitle:"TPT Stoppage Report"})
		.then( async (resp) => {
			// console.log("resp ", resp)
			this.setState({
				usergridstate:resp.data
			});
			this.restoreGridStates();
		})
	}
	onClickHideAll(){
		this.setState({
			overly : "show-n",
			showTrucksList : "show-n",
			sliderRouteTranslate:'',
		})
	}
	// hideList(){
    //     this.setState({
	// 		loadshow:'show-n',
	// 		overly:'show-n',
	// 		showTrucksList : "show-n",
    //     });
    // }
	
    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };
    truclist(){
		var items=[]
		var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
                tpttrucks.filter((d )=> {
                    if(d.truck_no == f){									
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        items.push({
            value:"ALLlist",
            label:"ALL"
        })
		if(tptunqrecords.length > 0)
		{
			tptunqrecords.map((item) => {
                // console.log(item,"item data")
				items.push({
					value:item,
					label:item
				})
			})
		}
        // console.log("items",items)
		return items;
	}
	onChangeDepartment(truckno){
		this.setState(
			{ truckno },
			// () => console.log(`truckNos Option selected:`, this.state.truckno)
		);
	}
	onChangeDuration(duration){
		this.setState(
			{ duration },
			// () => console.log(`duration Option selected:`, this.state.duration)
		);
	}
	onClickFilterReport = () => {
		// var truckNos = $("#inputTruck").val();
        // console.log("truckNos ", truckNos)
        var truckNos = this.state.truckno;
		// console.log("truckNos ", truckNos)
        var truck_nos = [];
        if(truckNos != null && truckNos != "null")
		{
            if(truckNos.length > 0 && truckNos != null)
            {
                truckNos.map(function(e){
                    if(e.value != "")
                    {
                        truck_nos.push(e.value)
                    }
                    
                });
            }
        }
		var duration = this.state.duration.value
		var fromDate = $("#filterFromDate").val();
		var toDate = $("#filterToDate").val();
		if(fromDate !='' && toDate !='')
		{
			var transporter_code = [];
			if(localStorage.getItem('transportercode') !=undefined)
            {
				if(localStorage.getItem("transportercode") != "all")
				{
					var transporter_code = JSON.parse(localStorage.getItem("transportercode"));
            	}
				else{
					var transporter_code = localStorage.getItem("transportercode");
            	}
                //var transporter_code = tptCode[0];
			}
            if(truck_nos.length > 0)
            {
                var selectedtrucks = JSON.stringify(truck_nos);
            }
            else
            {
                var selectedtrucks = JSON.stringify(["ALLlist"]);
            }
			var reqparams = {
				transporter_code: transporter_code,
				truck_no : selectedtrucks,
				fromDate : fromDate,
                toDate : toDate,
                duration:duration
			}
			// console.log("reqparams ", reqparams)
			this.setState({
				loadshow:'show-m',
				overly:'show-m',
			})
            // if(truck_nos.length == 0)
            // {
            //     this.setState({
            //         show:true,
            //         basicTitle:"Please select truck from list",
            //         basicType:"danger",
            //         loadshow:"show-n",
            //         overly:"show-n"
            //     })
            // }
            // else{
                redirectURL.post("/dashboard/stoppagereportdata",reqparams)    
                .then(async(response) => {
                    var records = response.data;
                    var tpttrucks = this.state.tpttrucks;
                    var trucksarr = []
                    // if(JSON.parse(localStorage.getItem("transportercode")).includes("T293") && localStorage.getItem("plant_location") != "" &&
                    // localStorage.getItem("plant_location") != undefined)
                    // {
                    //     var lsParams = {
                    //         transporter_code : JSON.parse(localStorage.getItem("transportercode")),
                    //         plant_location : localStorage.getItem("plant_location")
                    //     }
                    //     await redirectURL.post("/consignments/getDistinctTrucks",lsParams).then(async (response1) => {
                    //         var tpttrucks = response1.data.recordsdata;
                            if(tpttrucks.length > 0)
                            {
                                records.filter(f =>
                                    tpttrucks.filter((d )=> {
                                        if(d.truck_no == f.truck_no){									
                                            trucksarr.push(f)
                                        }
                                    })
                                );
                            //     trucksarr = await records.filter(function(n) { return tpttrucks.indexOf(n.truck_no) !== -1;});
                            //     console.log(trucksarr,"tptunqrecords")
                            // }
                        // })
                    }
                      
                    else
                    {
                        trucksarr = records
                    }
                    if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
                    localStorage.getItem("plant_location") != undefined)
                    {
                        trucksarr = trucksarr.filter((item)=>{
                            if(item.break_state == localStorage.getItem("plant_location"))
                            {
                                return item;
                            }
                        })
				    } 
                    // console.log(trucksarr,"trucksarrs")
                    var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
                    var pdfRowData = [];
                    
                    trucksarr.map(t => {
                        return(								
                            pdfRowData.push({								
                            name: t.truck_no,
                            break_start: getHyphenDDMMMYYYYHHMM(t.break_start),
                            break_end: getHyphenDDMMMYYYYHHMM(t.break_end),
                            break_address: t.break_address,
                            city: t.city,
                            break_district: t.break_district,	
                            break_state: t.break_state,
                            break_time_seconds: secondsToHM(t.break_time_seconds),														
                        }))
                    })
                    var pdfColumns = [
                        { header: name, binding: 'name'},                
                        { header: 'Start Date', binding: 'break_start'},
                        { header: 'End Date', binding: 'break_end'},
                        { header: 'Address', binding: 'break_address'},
                        { header: 'City', binding: 'city'},
                        { header: 'District', binding: 'break_district'},
                        { header: 'State', binding: 'break_state'},
                        { header: 'Break Time (HH:mm)', binding: 'break_time_seconds'}

                    ]
                    
                    this.setState({
                        loadshow:'show-n',
                        overly:'show-n',
                        rowData:trucksarr,
                        pdfRowData,
                        pdfColumns
                    })
                })
                .catch(function(e){
                    console.log("Error ",e)
                })
            //}
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:'Please select dates to continue',
				basicType:"danger",
				loadshow:'show-n',
			})
		}
		
	}

	onShowTPTGPSMapRoute(rownode){
        var node = rownode.data;
        // console.log("Node ", node)
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		const toDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var parameters = {
			truck_no:node.truck_no,
			from_date:moment.parseZone(node.break_start).format('YYYY-MM-DD HH:mm:ss'),
			to_date:moment.parseZone(node.break_end).format('YYYY-MM-DD HH:mm:ss'),
			device_id:""
        }
        redirectURL.post("/consignments/showgpseodmap", parameters)
        .then((response) => {
            // console.log("response ", response.data)
            this.setState({
                mapinfo:response.data,
                sliderRouteTranslate:"slider-translate-60p",
                loadshow:'show-n',
                overly:'show-m',

        });
        })
    }
    
    initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
                tpttrucks.filter((d )=> {
                    if(d.truck_no == f){									
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        tptunqrecords = tptunqrecords.slice(0,100);
        this.setState({
            filteredData : tptunqrecords,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
    	//console.log("Select", event.target.value);
    	// let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
                tpttrucks.filter((d )=> {
                    if(d.truck_no == f){									
                        tptunqrecords.push(f)
                    }
                })
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        var filteredResult = tptunqrecords.filter(function(e){
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        // console.log("filteredResult ", filteredResult)
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }
    hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
        console.log(window.colState , "stoppages_columns")
		let screenpage = 'TPT Stoppage Report';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
    render(){
        var listData = [] 
        this.state.filteredData.map(function(e){
            listData.push(<li className="tptdropdown-truck-no">{e}</li>)
        })
        var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
        var transporter_code = JSON.parse(localStorage.getItem("transportercode"))[0]
        console.log(transporter_code , "transporter_code")
        var hideForOtherthanGBFCT331T268 = ["GBFC" , "T331" , "T268"].includes(transporter_code)?false :true
        console.log(hideForOtherthanGBFCT331T268 , "hideForOtherthanGBFCT331T268")
		var columnWithDefs= [
			{
                headerName:"",
                field:"",
                width:50,
                cellRendererSelector:function(params){
                    return {
                        component:"showtptgpsmap"
                    }
                }
            },
            {
				headerName: tkName,
				field: "truck_no",
                width: 100,
                filter: true,
                resizable: true,
                sortable:true,
			},
            
           
			{
				headerName: "Start Date",
				field: "break_start",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter: function(params){
                    if(params.data.break_start !=undefined && params.data.break_start !='')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.break_start)
					}
					else
					{
						return "N/A";
					}
                }
            },
            
            {
				headerName: "End Date",
				field: "break_end",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter: function(params){
                    if(params.data.break_end !=undefined && params.data.break_end !='')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.break_end)
					}
					else
					{
						return "N/A";
					}
                }
            },

             
			{
				headerName: "Dealer Code",
				field: "dealer_code",
                width: 100,
                filter: true,
                resizable: true,
                sortable:true,
                hide:hideForOtherthanGBFCT331T268

            },
             
			{
				headerName: "Dealer Name",
				field: "dealer_name",
                width: 160,
                filter: true,
                resizable: true,
                sortable:true,
                hide:hideForOtherthanGBFCT331T268

            },
			{
				headerName: "Address",
				field: "break_address",
                width: 240,
                filter: true,
                resizable: true,
                sortable:true
            },
			{
				headerName: "City",
				field: "city",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true
            },
			{
				headerName: "District",
				field: "break_district",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true
            },
			{
				headerName: "State",
				field: "break_state",
                width: 140,
                filter: true,
                resizable: true,
                sortable:true
            },
            {
				headerName: "Break Time (HH:mm)",
				field: "break_time_seconds",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter:function(params){
                    try{
                        if(params.data.break_time_seconds != "" && params.data.break_time_seconds != undefined)
                        {   
                            return secondsToHM(params.data.break_time_seconds);
                        }
                        else{
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
			},
			{
				headerName:"Geofence Name",
				field:"stoppage_geofence_name",
				width:240,
				filter: true,
                resizable: true,
                sortable:true
			},
		];
		if(localStorage.getItem("transportercode").indexOf("T305") >= 0)
		{
			// console.log('columnWithDefs',columnWithDefs)
			columnWithDefs.push(
				{
					headerName:"Carrier Title",
					field:"carrier_title",
					width:240,
					filter: true,
					resizable: true,
					sortable:true
				},
				{
					headerName:"Group Title",
					field:"group_title",
					width:240,
					filter: true,
					resizable: true,
					sortable:true
				}
			)
		}
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="card">
                    <div className="card-header">
                        <h5>
							<i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                        	Safety Reports - Stoppage Report
                            <div className="col-xl-12 col-lg-12">
                                    
                                    <span className="layoutbtns pull-right">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                </div>
                        </h5>
                    </div>
                   
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>
                        
						<div class="row">
                            {/* <div className={"trucks-dropdown "+this.state.showTrucksList} style={{top:"93px"}}>
                                <ul>
                                    {listData}
                                </ul>
                            </div> */}
							<div class={" col-xl-2 col-lg-2 form-group"}>
								<label>{(this.state.adaniCheck == 1) ?	"Shuttles"  : "Trucks"} </label>
								{/* <input 
                                    type="text" 
                                    name="truck_no" 
                                    id="inputTruck"  
                                    placeholder="Truck No" 
                                    autoComplete="off" 
                                    className="form-control" 
                                    onFocus={this.initalList.bind(this)} 
                                    onChange={this.handlerForm.bind(this)} /> */}
                                    <Select 
                                    placeholder={(this.state.adaniCheck == 1) ?	"Shuttles"  : "Trucks"}
                                    closeMenuOnSelect={false}
                                    isMulti={true}
                                    autoComplete="off"
                                    onChange={this.onChangeDepartment.bind(this)}
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    options={this.truclist()} />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>From Date</label>
								<input type="text" name="date" autoComplete="off" id="filterFromDate" className="datetimepicker_date form-control" />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>To Date</label>
								<input type="text" name="date" autoComplete="off" id="filterToDate" className="datetimepicker_date form-control" />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>Duration</label>
                                <Select 
                                    placeholder={"Duration"}
                                    // isMulti={true}s
                                    onChange={this.onChangeDuration.bind(this)}
                                    className={"border-radius-0"}
                                    style={{borderRadius:"0px"}}
                                    autoComplete="off"
                                    value={this.state.duration}
                                    options={[
                                        {value:"2",label:"2 Mins"},
                                        {value:"5",label:"5 Mins"},
                                        {value:"10",label:"10 Mins"},
                                        {value:"15",label:"15 Mins"},
                                        {value:"30",label:"30 Mins"},
                                        {value:"60",label:"1 Hour"},
                                        {value:"120",label:"2 Hours"},
                                        {value:"300",label:"5 Hours"},
                                    ]} />	
                            </div>
							<div class="col-xl-2  col-lg-2 form-group">
								<label>&nbsp;</label>
								<br />
								<button type="submit" onClick={this.onClickFilterReport.bind(this)} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                
							</div>
                            <div class="col-xl-2  col-lg-2  form-group">
								<label>&nbsp;</label>
								<br />
								{(this.state.pdfRowData.length > 0) ?
									<ExportPDF  rowData={this.state.pdfRowData} pageTitle = "Stoppage Reports" pdfColumns={this.state.pdfColumns} 
                                    addressColumn={this.state.addressColumn}/>  
									: "" }
							</div>
						</div>
                        <div id="myGrid" style={{width:"100%",height:"350px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnWithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={50}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this },
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
						<div className="col-xl-12 col-lg-12 mt-20p txt-danger f12">
						</div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="row col-xl-12 col-lg-12 slide-r-body" style={{height:"100%" , padding:"5px"}}>
							
							<div className="col-xl-12 col-lg-12 ">
                                <DrawMap 
                                    context={this} 
                                    mapFor={"truck"} 
                                    mapinfo={this.state.mapinfo}                                     
									adaniCheck={this.state.adaniCheck}
                                    />

                                {/* <NextBillionMaps 
                                    context={this} 
                                    mapFor={"truck"} 
                                    mapinfo={this.state.mapinfo}                                     
									adaniCheck={this.state.adaniCheck}
                                />     */}

								<div className="col-xl-12 col-lg-12  mt-20p">
									<div className="crm-numbers pb-0">
										<h3 className="subH">View Details</h3>
										<div className="col-xl-12 col-lg-12 row">
											
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.mapinfo.route_details.start_time != '')?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.mapinfo.route_details.end_time != '')?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">Total Break Time</label>
												<div>{secondsToHM(this.state.mapinfo.total_break_secs)} </div>
											</div>
											
										</div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                :""}
				
            </div>
              
        );
    }
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}

function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'d-m-Y H:i',
        timepicker:true,
        step:5
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#filterToDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#filterFromDate").val();
    var cargoDate=$("#filterToDate").val();
    if(bookingDate !=undefined && bookingDate !="" && cargoDate !=undefined && cargoDate !="")
    {
        // console.log(bookingDate,cargoDate,"testt")
        var bookingDateSpt = bookingDate.split(" ");
        var cargoDateSpt = cargoDate.split(" ");
        bookingDate = bookingDateSpt[0].split("-")[2]+"-"+bookingDateSpt[0].split("-")[1]+"-"+bookingDateSpt[0].split("-")[0]+" "+bookingDateSpt[1]
        cargoDate = cargoDateSpt[0].split("-")[2]+"-"+cargoDateSpt[0].split("-")[1]+"-"+cargoDateSpt[0].split("-")[0]+" "+cargoDateSpt[1]
        // console.log(bookingDate,cargoDate)
        var startDate = new Date(bookingDate);
        var endDate = new Date(cargoDate);
        var startTime = startDate.getTime();
        var endTime = endDate.getTime();
        if(startTime > endTime)
        {
            $("#filterToDate").val("");
            alert("To Date should be greater than or equal to From Date");
        }
    }
})

$(document).on("change", "#filterFromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#filterFromDate").val();
    var cargoDate=$("#filterToDate").val();
    if(bookingDate !=undefined && bookingDate !="" && cargoDate !=undefined && cargoDate !="")
    {
        // console.log(bookingDate,cargoDate,"testt")
        var bookingDateSpt = bookingDate.split(" ");
        var cargoDateSpt = cargoDate.split(" ");
        bookingDate = bookingDateSpt[0].split("-")[2]+"-"+bookingDateSpt[0].split("-")[1]+"-"+bookingDateSpt[0].split("-")[0]+" "+bookingDateSpt[1]
        cargoDate = cargoDateSpt[0].split("-")[2]+"-"+cargoDateSpt[0].split("-")[1]+"-"+cargoDateSpt[0].split("-")[0]+" "+cargoDateSpt[1]
        // console.log(bookingDate,cargoDate)
        var startDate = new Date(bookingDate);
        var endDate = new Date(cargoDate);
        var startTime = startDate.getTime();
        var endTime = endDate.getTime();
        if(startTime > endTime)
        {
            $("#filterToDate").val("");
            alert("To Date should be greater than or equal to From Date");
        }
    }
})

$(document).ready(function(){
    $(document).on("click",".tptdropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})
