import React, { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
var redirectURL = require('../redirectURL');

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    // const [loadshow ,setloadshow] = useState('show-n');
    // const [overly ,setoverly] = useState('show-n');
    // const [modules ,setmodules] = useState(AllModules);
    // const [show ,setshow] = useState(false);
    // const [basicTitle ,setbasicTitle] = useState('');
    // const [basicType ,setbasicType] = useState('default');

    // loadshow: 'show-n',
    // overly: "show-n",
    // modules: AllModules,
    // alert: null,
    // show: false,
    // basicTitle: '',
    // basicType: "default",
    // Inline style object
    const styles = {
        container: {
            width: '50%',
            margin: '20px auto',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            textAlign: 'center',
            backgroundColor: '#f9f9f9', // Light background color
        },
        input: {
            margin: '10px 0',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '5px',
            border: '1px solid #ccc',
        },
        button: {
            padding: '10px 20px',
            backgroundColor: '#007BFF', // Green color
            color: 'white',
            border: 'none',
            borderRadius: '5px', // Rounded corners
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            marginLeft:"6px",
        },
        buttonHover: {
            backgroundColor: '#0056b3', // Darker green on hover
        },
        errorMessage: {
            color: 'red',
            fontSize: '14px',
            marginTop: '10px',
            fontWeight: 'bold',
        },
    };
    // Button hover effect using state
    const [buttonStyle, setButtonStyle] = useState(styles.button);
    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile && uploadedFile.type === 'application/json') {
            setFile(uploadedFile);
            setError('');
        } else {
            setError('Please upload a valid JSON file.');
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (!file) return; // Check if file is selected
    
        const formData = new FormData();
        formData.append('jsonFile', file); // Append the file to the FormData
    
        redirectURL.post("/consignments/bulkUploadTransporter", formData)
            .then((response) => {
                const data = response.data; // Assuming the data is in the 'data' field
                if (data.success) {
                    alert('File uploaded and processed successfully');
                } else {
                    alert(data.message || "An error occurred while uploading the file");
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('An error occurred while uploading the file');
            });
    };
    
    // Change button style on hover
    const handleMouseEnter = () => setButtonStyle({ ...styles.button, ...styles.buttonHover });
    const handleMouseLeave = () => setButtonStyle(styles.button);
    return (
        <div className="container-fluid">
                {/* <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert> */}
        <div style={styles.container}>
            <h6>BULK TRANSPORTER UPLOAD</h6>
            <form onSubmit={handleSubmit}>

                <input
                    type="file"
                    accept=".json"
                    onChange={handleFileChange}
                    style={styles.input}
                />
                {error && <div style={styles.errorMessage}>{error}</div>}
                <button
                    type="submit"
                    style={buttonStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    Upload
                </button>
            </form>
        </div>
        </div>
    );
};

export default FileUpload;

