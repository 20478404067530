/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import {
    getDDMMYYYY, getDDMMMYYYY, getHyphenDDMMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import UploadPODReceit from "./uploaditem";
import ViewAttachments from "./viewallattachment";
import ViewIdentityAttachments from "./viewidentityattachment";
import EditItem from "./tptedititem";
import ExportPDF from "./exportpdf"
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class TPTLoginDetails extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            showform: 'show-m',
            loadshow: 'show-n',
            showrefresh: 'show-n',
            sliderTranslate: '',
            showdata: this.displayData,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            contentString: "testing",
            mwidth: "col-xl-12 col-lg-12",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
                resizable: true
            },
            rowData: [],
            frameworkComponents: {
                uploaddoc: UploadPODReceit,
                viewattachments: ViewAttachments,
                viewidentityattachments: ViewIdentityAttachments,
                edititem: EditItem
            },
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            rowClassRules: {
                "boxbgblue": "data.to_be_approved == 1"
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            detailCellRendererParams: {},
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 200,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "RSO POD",
            sliderRouteTranslate: '',
            file: '',
            csvcontent: [],
            startDate: "",
            endDate: "",
            defaultsdate: "",
            defaultedate: "",
            headerHeight: 80,
            showform: "show-n",
            sliderForceDestination: "",
            truck_no: "",
            transporter_mobile_no: "",
            transporter_address: "",
            address_proof_no: "",
            address_proof: { value: "", label: "Select" },
            identity_proof_no: "",
            identity_proof: { value: "", label: "Select" },
            uploads: [],
            files: [],
            identityfiles: [],
            shwdata: "show-n",
            sliderDefectData: "",
            sliderBulk: "",
            transporter_code: "",
            engine_no: "",
            chassis_no: "",
            username: "",
            userid: "",
            email: "",
            filteredData: [],
            showTrucksList: "show-n",
            trucks: [],
            rowid: "",
            carriers: [],
            carrier: { value: "", label: "Select" },
            groups: [],
            group: [],
            adaniCheck: 0,
            pdfRowData: [],
            pdfColumns: [],
            addressColumn: 300,
            tptGrouptrucks: [],
        };
    }

    componentDidMount() {
        loadDateTimeScript()
       
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({ eventAction: eventAction });

       
        
        this.onLoadData()

    }

 


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    
    

   

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;


    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onLoadData = () => {
        this.setState({
            loadshow: "show-m"
        })
        redirectURL.post('/dashboard/getLoginDetails').then(response => {
            console.log(response.data.records)
            var records = response.data.records
            this.setState({
                loadshow:"show-n",
                rowData:records
            })
        })
    }

    
    render() {
        
        var columnwithDefs = [

            {
                headerName: "Username",
                field: "username",
                width: 140,
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 140,
            },
            {
                headerName: "Email",
                field: "email",
                width: 200,
            },
            {
                headerName: "Password",
                field: "password_txt",
                width: 140,
            },
            {
                headerName: "Billing Login",
                field: "is_billing",
                width: 140,
                valueGetter : function(params){
                            if(params.data.is_billing == 1){
                                return "Billing Login"
                            }
                            else {
                                return ""
                            }
                }
            }

           
            

        ]
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e.truck_no}</li>)
        })

        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                   <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>Login Credentials  </span>
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className={"row col-xl-12 col-lg-12 "}>

                                        <div className={"col-xl-12 col-lg-12 "}>
                                            <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    headerHeight={this.state.headerHeight}
                                                    frameworkComponents={this.state.frameworkComponents}
                                                    gridOptions={{
                                                        context: { componentParent: this }
                                                    }}
                                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                                    detailCellRendererParams={this.state.detailCellRendererParams}
                                                    masterDetail={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
            </div>

        );
    }
}


window.onload = function () {
    // $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
    // });
    $('#nvrr_date').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $("#lr_no_date").datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $("#rso_stn_date").datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};
document.addEventListener('turbolinks:before-render', () => {
    loadDateTimeScript()
});

function loadDateTimeScript() {

    $('#nvrr_date').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $("#lr_no_date").datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $("#rso_stn_date").datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });


    // $('.datetimepicker_date').datetimepicker({
    // 	mask:'39-19-9999',
    // 	format:'d-m-Y',
    // 	timepicker:false
    // });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}



function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        console.log(date1Number, date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $("#trucks-dropdown").removeClass("show-m");
        $("#trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($("#trucks-dropdown").attr("class").includes("show-m") || $("#trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $("#trucks-dropdown").removeClass("show-m");
                    $("#trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })


})