/* eslint-disable eqeqeq */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
// import UpdateButton from "../layouts/updatelmstates";
// import UpdateTransitButton from "../layouts/updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ShowTPTGpsMap from "./showtptgpsmap";
import DrawMap from '../common/drawmap';
import NextBillionMaps from '../common/nbmaps';
import $ from 'jquery';
import ExportPDF from "./exportpdf"

import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
import ConsolidateEwayBill from './consolidateewaybill';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class TptContinuousReport extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Continous Travel Report",
			screenpage : 'Continous Travel Report',
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				showtptgpsmap:ShowTPTGpsMap,
                // UpdateButton:UpdateButton,
                // UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            
            screenView: "",
            rowData: [],
            todayInstalled: [],
            totalInstalled: [],
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			showTrucksList : "show-n",
			startDate:'',
			endDate:'',
			truckNos:[],
			mapinfo:[],
			sliderRouteTranslate:'',
			tpttrucks:[],
			trucklist:[],
			truckno:[{"value":"","label":""}],
			adaniCheck:0,
			pdfRowData: [],
			pdfColumns: [],
			addressColumn: 300,
			showMap  :'show-n'
		};
		this.onShowTPTGPSMapRoute = this.onShowTPTGPSMapRoute.bind(this);
		this.loadData = this.loadData.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				// console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount = async () =>{
       
		var tptCode = localStorage.getItem('transportercode');
		if(tptCode.includes("T293") == true)
        {
            var adaniCheck = 1;
        }
        else
        {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck : adaniCheck
        });
		var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		// if(vehicle_group != "")
		// {
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			    var records = response.data.records;
				// console.log(records , "records")
				// for (let i=0 ; i < records.length ; i++){
				// 	if (records[i].truck_no == "HR55W6481"){
				// 		console.log(records[i].truck_no , "srinu1")
				// 	}
				// }
               this.setState({
					tpttrucks:records
				})
			})
		// }
        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if(is_enmovil_transporter == 1)
        {
			loadDateTimeScript();
			// var edate = moment.parseZone().format('YYYY-MM-DD HH:mm');
			// var sdate = moment.parseZone().subtract(2, 'days').format('YYYY-MM-DD HH:mm')
			// var enddate = moment.parseZone().format('YYYY-MM-DD');
			// var startdate = moment.parseZone().subtract(5, 'days').format('YYYY-MM-DD')
			// $("#fromctDate").val(sdate);
			// $("#toctDate").val(edate);
			this.setState({
				loadshow:'show-m',
				overly:'show-m'
			});
            this.logPageView(); // Log page view to GA
            var screenView = "";
            var transporter_code = [];
            if(localStorage.getItem('transportercode') !=undefined)
            {
				// console.log(localStorage.getItem("transportercode"),"localStorage.getItem")
				if(localStorage.getItem("transportercode") != "all")
				{
                	var transporter_code = JSON.parse(localStorage.getItem("transportercode"));
				}
				else{
					var transporter_code = localStorage.getItem("transportercode");
            	}
                //var transporter_code = tptCode[0];
			}
			// var query = {
            //     transporter_code : transporter_code,
            //     fromDate:startdate,
			// 	toDate:enddate,
			// 	truck_no:[]
			// }
            this.loadData();
        }
        else
        {
            window.location.href="/";
        }
	}
	loadData = async (parameters) =>{
		var edate = moment.parseZone().format('YYYY-MM-DD HH:mm');
		var sdate = moment.parseZone().subtract(5, 'days').format('YYYY-MM-DD HH:mm')
		$("#fromctDate").val(sdate);
		$("#toctDate").val(edate);
	
		var transporter_code = [];
		if(localStorage.getItem('transportercode') !=undefined)
		{
			if(localStorage.getItem("transportercode") != "all")
			{
				var transporter_code = JSON.parse(localStorage.getItem("transportercode"));
			}
			else{
				var transporter_code = localStorage.getItem("transportercode");
			}
			//var transporter_code = tptCode[0];
		}
		var parameters = {
			transporter_code: transporter_code,
			truck_no : [],
			fromDate : sdate,
			toDate : edate,
		}

			
		await redirectURL.post("/dashboard/tptcontinuousreportfilter",parameters)
		.then(async (response)=>{
			// console.log("response ", response.data.records)
			var recordsarr = response.data.records;
			// for (let i=0 ; i < recordsarr.length ; i++){
			// 	if (recordsarr[i].truck_no == "HR55W6481"){
			// 		console.log(recordsarr[i].truck_no , "srinu2")
			// 	}
			// }
			var records = []
			var tpttrucks = this.state.tpttrucks;
			if(this.state.tpttrucks.length > 0)
			{
				recordsarr.filter(f =>
					tpttrucks.filter((d )=> {
						if(d.truck_no == f.truck_no){							
							records.push(f)
						}
					})
				);
			}
			else{
				records=recordsarr	
			}
			var trucklist=[]
			if(records != undefined && records != "undefined")
			{
				if(records.length > 0)
				{
					var newArray=[]
					// Declare an empty object 
					let totuniqueObject = {}; 
					var totobjTitle;
					// Loop for the array elements 
					for (let i in records) { 

						// Extract the title 
						totobjTitle = records[i]['truck_no']; 

						// Use the title as the index 
						totuniqueObject[totobjTitle] = records[i]; 
					} 

					// Loop to push unique object into array 
					for (var a in totuniqueObject) { 
						newArray.push(totuniqueObject[a]); 
					} 
					if(newArray.length > 0)
					{
						newArray.map((item) => {
							trucklist.push(item.truck_no)
						})
					}
					
				}
			}
			var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
			var pdfRowData = [];
			if(recordsarr != undefined && recordsarr != "undefined")
			{
				recordsarr.map(t => {
					return(								
						pdfRowData.push({								
						name: t.truck_no,
						gps_from_date: getHyphenDDMMMYYYYHHMM(t.gps_from_date),
						gps_to_date: getHyphenDDMMMYYYYHHMM(t.gps_to_date),
						start_address: t.start_address,
						end_address: t.end_address,
						distance_travelled_km: t.distance_travelled_km,															
					}))
				})
			}
			var pdfColumns = [
				{ header: name, binding: 'name'},                
				{ header: 'From Date', binding: 'gps_from_date'},
				{ header: 'To Date', binding: 'gps_to_date'},
				{ header: 'Start Address', binding: 'start_address'},
				{ header: 'End Address', binding: 'end_address'},
				{ header: 'Distance Travelled (KM)', binding: 'distance_travelled_km'},						
				]


			this.setState({
				rowData:records,
				trucklist:trucklist,
				loadshow:'show-n',
				overly:'show-n',
				pdfRowData,
				pdfColumns,
			})
			
		})
		this.getUserGridState()
	}
	getUserGridState(){
		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname , screentitle :"Continous Travel Report"})
		.then( async (resp) => {
			// console.log("resp ", resp)
			this.setState({
				usergridstate:resp.data
			});
			this.restoreGridStates();
		})
	}
	onClickHideAll(){
		this.setState({
			overly : "show-n",
			showTrucksList : "show-n",
			sliderRouteTranslate:'',
		})
	}
	hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	
    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };
    truclist(){
		var items=[]
		items.push({
			value:"Alllist",
			label:"ALL"
		})
		var trucks = this.state.trucklist;
		// console.log(trucks,"trucks")
		if(trucks.length > 0)
		{
			trucks.map((item) => {
				items.push({
					value:item,
					label:item
				})
			})
		}
		return items;
	}
	onChangeDepartment(truckno){
		this.setState(
			{ truckno },
			() => console.log(`truckNos Option selected:`, this.state.truckno)
		);
	}
	onClickFilterReport = () => {
		var truckNos = this.state.truckno;
		// console.log("truckNos ", truckNos)
		var truck_nos = [];
		if(truckNos != null && truckNos != "null")
		{
			if(truckNos.length > 0 && truckNos != null)
			{
				truckNos.map(function(e){
					if(e.value != "")
					{
						truck_nos.push(e.value)
					}
					
				});
			}
		}
		
		var fromDate = $("#fromctDate").val();
		var toDate = $("#toctDate").val();
		if(fromDate !='' && toDate !='')
		{
			var transporter_code = [];
			if(localStorage.getItem('transportercode') !=undefined)
			{
				if(localStorage.getItem("transportercode") != "all")
				{
                	var transporter_code = JSON.parse(localStorage.getItem("transportercode"));
				}
				else{
					var transporter_code = localStorage.getItem("transportercode");
            	}
				//var transporter_code = tptCode[0];
			}
			if(truck_nos.length > 0)
            {
                var selectedtrucks = JSON.stringify(truck_nos);
            }
            else
            {
                var selectedtrucks = JSON.stringify(["Alllist"]);
            }
			var reqparams = {
				transporter_code: transporter_code,
				truck_no : selectedtrucks,
				fromDate : fromDate,
				toDate : toDate,
			}
			// console.log("reqparams ", reqparams)
			this.setState({
				loadshow:'show-m',
				overly:'show-m',
			})

			redirectURL.post("/dashboard/tptcontinuousreportfilter",reqparams)    
			.then((response) => {
				var records = response.data.records;
				// console.log(records,"records")
				var tpttrucks = this.state.tpttrucks;
				var trucksarr = []
				if(tpttrucks.length > 0)
				{
					records.filter(f =>
						tpttrucks.filter((d )=> {
							if(d.truck_no == f.truck_no){									
								trucksarr.push(f)
							}
						})
					);
				}
				else
				{
					trucksarr = records
				}				
				var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
				var pdfRowData = [];
				trucksarr.map(t => {
					return(								
						pdfRowData.push({								
						name: t.truck_no,
						first_instance: getHyphenDDMMMYYYYHHMM(t.first_instance),
						speed: t.speed,
						recent_instance: getHyphenDDMMMYYYYHHMM(t.recent_instance),
						first_instance_address: t.first_instance_address,								
					}))
				})
				// console.log("pdfRowDat", trucksarr)
				var pdfColumns = [
					{ header: name, binding: 'name'},                
					{ header: 'Start Time', binding: 'first_instance'},
					{ header: 'Speed (km/h)', binding: 'speed'},
					{ header: 'End Time', binding: 'recent_instance'},
					{ header: 'Address', binding: 'first_instance_address'},							
				]

				this.setState({		
					pdfRowData,
					pdfColumns,			
					loadshow:'show-n',
					overly:'show-n',
					rowData:trucksarr
				})
			})
			.catch(function(e){
				console.log("Error ",e)
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:'Please select dates to continue',
				basicType:"danger",
				loadshow:'show-n',
			})
		}
		
	}

	onShowTPTGPSMapRoute(rownode){
        var node = rownode.data;
        // console.log("Node ", node)
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// const toDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var parameters = {
			truck_no:node.truck_no,
			from_date:moment.parseZone(node.probable_start_date).format('YYYY-MM-DD HH:mm:ss'),
			to_date:moment.parseZone(node.probable_end_date).format('YYYY-MM-DD HH:mm:ss'),
			device_id:""
        }
		// console.log('node.probable_start_date',node.probable_start_date,'\n node.probable_end_date ',node.probable_end_date,'\n parameters',parameters)
        redirectURL.post("/consignments/showgpseodmap", parameters)
        .then((response) => {
            // console.log("response ", response.data)			
			if(response.data.status === 'failure'){
				this.setState({
					show : true,
					basicType  :'danger',
					basicTitle : response.data.message,
					loadshow:'show-n',
					overly:'show-n',
				})
			}else{
				this.setState({
					mapinfo:response.data,
					sliderRouteTranslate:"slider-translate-60p",
					loadshow:'show-n',
					overly:'show-m',
					showMap : 'show-m',
				});
			}
        })
		

		
		
        
	}
	onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		console.log(window.colState , "contiuoustravel_columns")
		let screenpage = 'Continous Travel Report';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
    render(){
        var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
        
		var columnWithDefs= [
			{
                headerName:"",
                field:"",
                width:50,
                cellRendererSelector:function(params){
                    return {
                        component:"showtptgpsmap"
                    }
                }
            },
            {
				headerName: tkName,
				field: "truck_no",
                width: 160,
                filter: true,
                resizable: true,
                sortable:true,
			},
            
            // {
			// 	headerName: "Probable Start Time",
			// 	field: "probable_start_date",
            //     width: 160,
            //     filter: true,
            //     resizable: true,
            //     sortable:true,
            //     valueGetter: function(params){
            //         if(params.data.probable_start_date !=undefined && params.data.probable_start_date !='')
			// 		{
			// 			return getHyphenDDMMMYYYYHHMM(params.data.probable_start_date)
			// 		}
			// 		else
			// 		{
			// 			return "N/A";
			// 		}
            //     }
            // },
            
            // {
			// 	headerName: "Probable End Time",
			// 	field: "probable_end_date",
            //     width: 160,
            //     filter: true,
            //     resizable: true,
            //     sortable:true,
            //     valueGetter: function(params){
            //         if(params.data.probable_end_date !=undefined && params.data.probable_end_date !='')
			// 		{
			// 			return getHyphenDDMMMYYYYHHMM(params.data.probable_end_date)
			// 		}
			// 		else
			// 		{
			// 			return "N/A";
			// 		}
            //     }
			// },
			{
				headerName: "From Date",
				field: "gps_from_date",
                width: 160,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter: function(params){
                    if(params.data.gps_from_date !=undefined && params.data.gps_from_date !='')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.gps_from_date)
					}
					else
					{
						return "N/A";
					}
                }
            },
            
            {
				headerName: "To Date",
				field: "gps_to_date",
                width: 160,
                filter: true,
                resizable: true,
                sortable:true,
                valueGetter: function(params){
                    if(params.data.gps_to_date !=undefined && params.data.gps_to_date !='')
					{
						return getHyphenDDMMMYYYYHHMM(params.data.gps_to_date)
					}
					else
					{
						return "N/A";
					}
                }
            },
            
			{
				headerName: "Start Address",
				field: "start_address",
                width: 240,
                filter: true,
                resizable: true,
                sortable:true
            },
			{
				headerName: "End Address",
				field: "end_address",
                width: 240,
                filter: true,
                resizable: true,
                sortable:true
            },
            {
				headerName: "Distance Travelled (KM)",
				field: "distance_travelled_km",
                width: 150,
                filter: true,
                resizable: true,
                sortable:true,
			},
			// {
			// 	headerName:"Current Geofence Name",
			// 	field:"current_geofence_name",
			// 	width:240,
			// 	filter: true,
            //     resizable: true,
            //     sortable:true
			// },
            
		];
		if(localStorage.getItem("transportercode").indexOf("T305") >= 0)
		{
			// console.log('columnWithDefs',columnWithDefs)
			columnWithDefs.push(
				{
					headerName:"Carrier Title",
					field:"carrier_title",
					width:240,
					filter: true,
					resizable: true,
					sortable:true
				},
				{
					headerName:"Group Title",
					field:"group_title",
					width:240,
					filter: true,
					resizable: true,
					sortable:true
				}
			)
		}
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                
                <div className="card">
                    <div className="card-header">
                        <h5>
							<i class="icofont icofont-vehicle-delivery-van cus-i"></i>
                        	Safety Reports - Continuous Travel Report
							<div className="col-xl-12 col-lg-12">
                                    
                                    <span className="layoutbtns pull-right">
                                        <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>
                                </div>
                        </h5>
                    </div>
					
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>
						
						<div class="row">
							<div class={" col-xl-2 col-lg-2 form-group"}>
								<label>{(this.state.adaniCheck == 1) ?	"Shuttles"  : "Trucks"} </label>
								<Select 
								placeholder={(this.state.adaniCheck == 1) ?	"Shuttles"  : "Trucks"}
								closeMenuOnSelect={false}
								isMulti={true}
								autoComplete="off"
								onChange={this.onChangeDepartment.bind(this)}
								className={"border-radius-0"}
								style={{borderRadius:"0px"}}
								options={this.truclist()}
								 />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>From Date</label>
								<input type="text" name="date" autoComplete="off" id="fromctDate" className="filters_date form-control" />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>To Date</label>
								<input type="text" name="date" autoComplete="off" id="toctDate" className="filters_date form-control" />
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>&nbsp;</label>
								<br />
								<button type="submit" onClick={this.onClickFilterReport.bind(this)} class="btn btn-success">Get Data</button>&nbsp;&nbsp;&nbsp;&nbsp;
							</div>
							<div class="col-xl-2 col-lg-2 form-group">
								<label>&nbsp;</label>
								<br />
								{(this.state.pdfRowData.length > 0) ?
									<ExportPDF  rowData={this.state.pdfRowData} pageTitle ="Continous Travel Report" pdfColumns={this.state.pdfColumns} 
									addressColumn={this.state.addressColumn}/>  
									: "" }
							</div>
						</div>
                        <div id="myGrid" style={{width:"100%",height:"350px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnWithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={50}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this },
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
						<div className="col-xl-12 col-lg-12 mt-20p txt-danger f12">
							Note: Trucks which are travelling for more than 6 hrs with less than 15 mins break are considered as continuously travelling
						</div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="row col-xl-12 col-lg-12 slide-r-body" style={{height:"100%" , padding:"5px"}}>
							
							<div className={" col-xl-12 col-lg-12"+ this.state.showMap}>
                                <DrawMap 
                                    context={this} 
                                    mapFor={"truck"} 
                                    mapinfo={this.state.mapinfo}   
									adaniCheck={this.state.adaniCheck}
									/>
								{/* <NextBillionMaps 
									context={this} 
                                    mapFor={"truck"} 
                                    mapinfo={this.state.mapinfo}   
									adaniCheck={this.state.adaniCheck}
								/> */}
								<div className="col-xl-12 col-lg-12  mt-20p">
									<div className="crm-numbers pb-0">
										<h3 className="subH">View Details</h3>
										<div className="col-xl-12 col-lg-12 row">
											
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.mapinfo.route_details.start_time != '' && this.state.mapinfo.route_details.start_time !== undefined)?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.mapinfo.route_details.end_time != '' && this.state.mapinfo.route_details.end_time !== undefined)?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"NA"}</div>
											</div>
											
											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											
										</div>
									</div>
								</div>
							</div>
                        </div>
                    </div>
                :""}
				
            </div>
              
        );
    }
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       



function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.filters_date').datetimepicker({
		format:'Y-m-d H:i',
		timepicker:true
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#toctDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromctDate").val();
    var cargoDate=$("#toctDate").val();
    var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#toctDate").val("");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#fromctDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#fromctDate").val();
    var cargoDate=$("#toctDate").val();
	console.log(bookingDate,cargoDate)
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    // var startDate = new Date(bookingDate);
    // var endDate = new Date(cargoDate);
    // var startTime = startDate.getTime();
    // var endTime = endDate.getTime();
    // console.log(startTime,endTime);
    if(bookingDate > cargoDate)
    {
        $("#fromctDate").val("");
        alert("From Date should be less than equal to To Date");
    }
})