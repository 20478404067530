import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import $ from "jquery";
import Constant from "../common/googlemapkey";
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
export default class TptVicinityScreen extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			plant_code:'',
			showPlant:'show-n',
			showStockYard:'show-n',
			showParking : "show-n",
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
			stockyard_options : [],
			parking_options : [],
		};
		this.popmarker=this.popmarker.bind(this);
		this.goBack = this.goBack.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
		var tparams = {
		}
		redirectURL.post("/dashboard/getdistinctstockyards",tparams)
			.then((response) => {
			   var stockyard_records = response.data.stockyard_records;
			   var stockyard_options = []
				console.log("stockyard_records ", stockyard_records)
				stockyard_records.map((item) => {
					var stockyard_value = {value :item, label : item}
					stockyard_options.push(stockyard_value)
				})
				console.log(stockyard_options,"stockyard_options")

				var parking_records = response.data.parking_records;
			   	var parking_options = []
				console.log("parking_records ", parking_records)
				parking_records.map((item) => {
					var parking_value = {value :item, label : item}
					parking_options.push(parking_value)
				})

				this.setState({
					stockyard_options : stockyard_options,
					parking_options : parking_options,
				})
			})
		// console.log(stockyard_records,"Stockyard")
		var tptCode = localStorage.getItem('transportercode');
        if(tptCode.includes("T293") == true)
        {
            var adaniCheck = 1;
        }
        else
        {
            var adaniCheck = 0;
        }
        this.setState({
            adaniCheck : adaniCheck
        });
		var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if(vehicle_group != "")
		{
			var tparms = {
				transporter_code:tptCode,
				region:vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
			.then((response) => {
			   var records = response.data.records;
				// console.log("records ", records)
				this.setState({
					tpttrucks:records
				})
			})
		}
		var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if(is_enmovil_transporter == 1)
        {
			//console.log("path ", this.props.match.path)
			if(localStorage.getItem("dept_code") !=undefined)
			{            
				var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
				var deptcode = depmntCodeJns[0];
			}
			var pageTitle = "Trucks Within Geofence - Transporters";
			if(adaniCheck == 1)
			{
				var pageTitle = "Shuttles Within Geofence - Transporters";
			}
			this.setState({
				dept_code:deptcode,
				overly : "show-m",
				loadshow : "show-m"
			});
			this.renderMapDefault();
			
			this.setState({pageTitle: pageTitle});

			this.logPageView(); // Log page view to GA
			redirectURL.post("/vicinity/getDistinctStates").then((response)=>{
				console.log(response.data,"response")
				this.setState({
					states_list : response.data,
					overly : "show-n",
					loadshow : "show-n"
				});
			}).catch((e)=>{
				console.log(e)
			})
		}
		else
		{
			window.location.href="/";
		}
    }
	renderMapDefault = () => {
    	
		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMapDefault");
		window.initMapDefault = this.initMapDefault
 	}
	renderMap = () => {		
		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	initMapDefault = () => {
		
		var lt=28.4519751;
		var ln=77.0310713;
	
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
		});

		var input = document.getElementById('search');
		var searchBox = new window.google.maps.places.SearchBox(input);
		map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

		// Bias the SearchBox results towards current map's viewport.
		map.addListener('bounds_changed', function() {

		  searchBox.setBounds(map.getBounds());
		});
		
		var markers = [];
		//console.log("searchBox",searchBox);
		searchBox.addListener('places_changed', function() 
		{
		  var places = searchBox.getPlaces();
			
		  if (places.length == 0) {
			return;
		  }

		  // Clear out the old markers.
		  markers.forEach(function(marker) {
			  console.log("Search Marker ", marker)
			marker.setMap(null);
		  });
		  markers = [];
			// For each place, get the icon, name and location.
		  var bounds = new window.google.maps.LatLngBounds();
		  places.forEach(function(place) {
			if (!place.geometry) {
			  console.log("Returned place contains no geometry");
			  return;
			}
			var icon = {
			  url: place.icon,
			  size: new window.google.maps.Size(71, 71),
			  origin: new window.google.maps.Point(0, 0),
			  anchor: new window.google.maps.Point(17, 34),
			  scaledSize: new window.google.maps.Size(25, 25)
			};

			// Create a marker for each place.
			//console.log("Plae Lat", place.geometry.location.lat());
			document.getElementById("latitude").value =place.geometry.location.lat();

			document.getElementById("longitude").value =place.geometry.location.lng();
			
			markers.push(new window.google.maps.Marker({
			  map: map,
			  icon: icon,
			  title: place.name,
			  position: place.geometry.location
			}));

			if (place.geometry.viewport) {
			  // Only geocodes have viewport.
			  bounds.union(place.geometry.viewport);
			} else {
			  bounds.extend(place.geometry.location);
			}
		  });
		  
		  map.fitBounds(bounds);
		});
		
		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			document.getElementById("latitude").value = event.latLng.lat();
			document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		
		
		
		var eventtrigger = document.getElementsByClassName('truck');

		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		 
	}
	 
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}
		
	  }
	
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
	changeRadiusHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
		circle.setRadius(value);
    }
    onChangePlantCode = async plant_code => {
		//console.log("Select", event.target.value);
		await this.setState(
			{ plant_code },
			() => console.log(`Option selected:`, this.state.plant_code)
          );
        
    }
	onChangeStockYard = async stock_yard => {
		await this.setState(
			{ stock_yard },
			() => console.log(`Option selected:`, this.state.stock_yard)
          );
        
    }

	onChangeParking = async parking=> {
		await this.setState(
			{ parking },
			() => console.log(`Option selected:`, this.state.parking)
          );
        
    }
    onChangeViewType = (event) => {
    	let name = event.target.name;
        let value = event.target.value;
        if(value == 'plant')
        {
            this.setState({
                showPlant:"show-m",
				showAddr:"show-n",
				showStateTrucksMsg : "show-n",
				radius : 15,
				showStates : "show-n",
				showRadius : "show-m",
				showStockYard:"show-n",
				showParking:"show-n"
            });
        }
        else if(value == 'address')
        {
            this.setState({
                showPlant:"show-n",
				showAddr:"show-m",
				showStateTrucksMsg : "show-n",
				radius : 15,
				showStates : "show-n",
				showRadius : "show-m",
				showStockYard:"show-n",
				showParking:"show-n"
            });
		}
		else if(value == "state")
		{
			this.setState({
                showPlant:"show-n",
				showAddr:"show-n",
				showStateTrucksMsg : "show-m",
				radius : 5,
				showStates : "show-n",
				showRadius : "show-m",
				showStockYard:"show-n",
				showParking:"show-n"
            });	
		}
		else if(value == "within_states")
		{

			this.setState({
                showPlant:"show-n",
				showAddr:"show-n",
				showStateTrucksMsg : "show-n",
				showStates : "show-m",
				radius : 15,
				showRadius : "show-n",
				showStockYard:"show-n",
				showParking:"show-n"

            });
		}
		else if(value == "stockyard"){
			this.setState({
                showPlant:"show-n",
				showAddr:"show-n",
				showStateTrucksMsg : "show-n",
				showStates : "show-n",
				radius : 15,
				showRadius : "show-m",
				showStockYard:"show-m",
				showParking:"show-n"

            });
		}
		else if(value == "parkinggg"){
			this.setState({
                showPlant:"show-n",
				showAddr:"show-n",
				showStateTrucksMsg : "show-n",
				showStates : "show-n",
				radius : 15,
				showRadius : "show-m",
				showStockYard:"show-n",
				showParking:"show-m"

            });
		}
		else{}
    	this.setState({[name]:value});
    }
	

	selectStateOptions(){
		var states = this.state.states_list;
		var items = [];
		states.map(function(e){
			if(e != "" && e != "NA" && e != "Chukha District" && e != "Dadra and Nagar Haveli and Daman and Diu" && e != "Kachchh"){
				items.push({"label":e,"value":e})
			}	
			
		});
		return items;
	}
	onSelectState(states){
		try{
			this.setState({
				selectedState : states
			})
		}catch(e){}
	}
	selectOptionsItems() {
		let items = [];     
		var consigners = [];
        //console.log("this.state.dept_code ", this.state.dept_code)
		if(localStorage.getItem("is_hero") != 1 && localStorage.getItem("is_hero") != "1")
		{
			if(this.state.dept_code == 'SNDG' || this.state.dept_code == 'sndg')
			{
				var keys = ["B", "F", "G", "I","M", "N","MUNDRA","MBPT"]; 
		
				// An array of values 
				var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur", 'Mundra', "Mumbai Port"]; 
				
				// Object created 
				var obj = {}; 
				
				// Using loop to insert key 
				// value in Object 
				for(var i = 0; i < keys.length; i++){ 
					consigners.push({"code":keys[i],"value":values[i]}); 
				} 
			}


			else if(this.state.dept_code == 'LOG-PRT' || this.state.dept_code == 'log-prt')
			{
				var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"]; 
		
				// An array of values 
				var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"]; 
				
				// Object created 
				var obj = {}; 
				
				// Using loop to insert key 
				// value in Object 
				for(var i = 0; i < keys.length; i++){ 
					consigners.push({"code":keys[i],"value":values[i]}); 
				} 
			}


			else if(this.state.dept_code == 'LOG-TNP' || this.state.dept_code == 'log-tnp')
			{
				var keys = ["MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
		
				// An array of values 
				var values = ["Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
				
				// Object created 
				var obj = {}; 
				
				// Using loop to insert key 
				// value in Object 
				for(var i = 0; i < keys.length; i++){ 
					consigners.push({"code":keys[i],"value":values[i]}); 
				} 
			}
		}
		else
		{
			consigners.push({"code":"HHHU","value":"Haridwar"})
			consigners.push({"code": "HHHD","value": "Dharuhera"})
			consigners.push({"code": "HHHG","value": "Gurgaon"})
			consigners.push({"code": "HM4N","value": "Neemrana"})
			consigners.push({"code": "HM5V","value": "Halol"})
			consigners.push({"code": "HM6C","value": "Chittoor"})
		}
		//console.log("consigners ", consigners)
		//items.push({value:'all',label:'All'}); 
		consigners.forEach(item =>{
			items.push({value:item.code,label:item.value}); 
		});
		
        return items;
}

submitFormHandler(formdata)
{
	formdata.transporterCodes = JSON.parse(formdata.transporterCodes)
	if(localStorage.getItem("is_hero") != undefined)
	{
		if(localStorage.getItem("is_hero") == 1 || localStorage.getItem("is_hero") == "1")
		{
			formdata.userfrom = "hero"
		}
		else{
			
			formdata.userfrom = "msil"
		}
	}
	rlatitude=document.getElementById("latitude").value;
	rlongitude=document.getElementById("longitude").value;
	var urlpath = '/vicinity/getTptPlantRadiusCoordinates';
	console.log(formdata , "formdata_629")
	redirectURL.post(urlpath,formdata)
	.then(
		async (response) =>{
		console.log("response", response);
		if(response.data == "success")
		{
			$("#email_ids").val("");
			this.setState({
				sliderStateEmailsTranslate : "",
				show : true,
				basicTitle : "Reports will be sent to provided emails",
				basicType : "success",
				overly : "show-n"
			})
		}
		else
		{
			var tabplants = [];
			console.log(response.data.cluster_centriods , "t305")
			if(response.data.cluster_centriods.length > 0)
			{
				console.log("consigners ", this.state.plant_code)
				response.data.cluster_centriods.forEach(item => {
					// var title = consigners.filter(codes =>{
					// 	console.log("codes ",codes)
					// 	if(codes.code == item.plant_code)
					// 	{
					// 		return codes.value;
					// 	} 
					// });
					console.log(this.state.view_type , "view_type")
					if(this.state.view_type == 'stockyard'){
						item.label=this.state.stock_yard[0].value;
					}else if(this.state.view_type == 'parkinggg'){
						item.label=this.state.parking[0].value;
					}else {
						item.label=this.state.plant_code[0].value;
					}
					tabplants.push(item);
				})
			}
			console.log(tabplants , "tabplants_1729")
			var trucksData = JSON.parse(response.data.trucks_data);
			console.log(trucksData , "trucks_data")
			var trucks_data = [];
			var tptCode = JSON.parse(localStorage.getItem("transportercode"));
			if(trucksData.length > 0)
			{
				var deviceparams = {
					transporter_code:localStorage.getItem("transportercode")
				}
				redirectURL.post("/dashboard/carrierDevices", deviceparams).then(async (response1)=>{
					var transporterTrucks = [];
					console.log(response1.data.devices , "681")
					response1.data.devices.map(function(e){
						if(tptCode.indexOf(e.transporter_code) > -1 )
						{
							transporterTrucks.push(e.truck_no)
						}
					})
					if(transporterTrucks.length > 0)
					{
						trucksData.map(function(t){
							if(transporterTrucks.indexOf(t.truck_no) > -1 )
							{
								trucks_data.push(t)
							}
						})
						var allmarkers=response.data.trucks_data;
						console.log("allmarkers ", trucks_data);
						var dCode = this.state.dept_code;
						if(trucks_data.length > 0)
						{

							var tpttrucks = this.state.tpttrucks;
							var tptunqrecords = []
							if(tpttrucks.length > 0)
							{
								await trucks_data.filter(f =>
									tpttrucks.filter((d )=> {
										if(d.truck_no == f.truck_no){									
											tptunqrecords.push(f)
										}
									})
								);
							}
							else
							{
								tptunqrecords = trucks_data
							}
							console.log(localStorage.getItem('plant_location'),"plant_location")
							if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
							localStorage.getItem("plant_location") != undefined)
							{
								tptunqrecords = tptunqrecords.filter((item)=>{
									console.log(item,"item state")
									if(item.state == localStorage.getItem("plant_location"))
									{
										return item;
									}
								})
							}
							//console.log("UPpcase Dept ", dCode);
							var markers = [];
							var mainlat='';
							var mainlng='';
							var maincoords=[];
							
							var mlat='';
							var mlng='';
							
							this.setState({
								defaultradius:formdata.radius,
								coordinates:tptunqrecords,
								mwidth:"col-xl-7 col-lg-7",
								rowData:tptunqrecords,
								originalrowData:tptunqrecords,
								radius:formdata.radius,
								loadshow:'show-n',
								showform:'show-n',
								showrefresh:'show-m',
								rlatitude:mlat, 
								rlongitude:mlng,
								tabplants:tabplants,
							});
							
							
							var coords = markers;
							
							var p1 = {lat:parseFloat(formdata.latitude), lng:parseFloat(formdata.longitude)};
							
							console.log("allcords.length ", allcords)
							
							window.initMap = await this.initMap
							await this.renderMap();
						}
						else{
							this.setState({
								show: true, basicType:'warning', basicTitle:"No Data found.",
								loadshow:'show-n',
								showform:'show-m',
							});
							
						}
					}
					else{
						this.setState({
							show: true, basicType:'warning', basicTitle:"No Data found.",
							loadshow:'show-n',
							showform:'show-m',
						});
						
					}
				});
			}
			else{
				this.setState({
					show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		}
	})
	.catch(function(error){
		console.log(error);
	});
}


submitMailForm = (event) =>{
	event.preventDefault();
	var emails = document.getElementById("email_ids").value;
	console.log(emails);
	try{
		if(emails.includes(","))
		{
			emails = emails.replace(/\s/g,"");
			emails = emails.split(",");
		}
		else{

			emails = [emails]
		}
		var formdata = this.state.stateFormData;
		formdata['emails'] = JSON.stringify(emails);
		if($("#gps_record_flag").prop("checked"))
		{
			formdata['gps_records_flag'] = 1;
		}
		else
		{
			formdata['gps_records_flag'] = 0;	
		}
		var transporter_code = "";
		var tptNCode = [];
		if(localStorage.getItem('transportercode') !=undefined)
		{
			var tptCode = JSON.stringify(localStorage.getItem("transportercode"));
			// var transporter_code = tptCode[0];
			// if(transporter_code !='')
			// {
			// 	tptNCode.push(transporter_code);
			// 	tptNCode = JSON.stringify(tptNCode);
			// }
		}
		console.log(tptCode,"tptCode")
		formdata['transporterCodes'] = tptCode;
		this.submitFormHandler(formdata);
	}
	catch(e){
		console.log(e)
	}
}

formHandler = (event) =>{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow:'show-m',
		});
		var plantcodes=[];
		if(this.state.plant_code !=undefined)
		{
			if(this.state.plant_code.length > 0)
			{
				try{
					this.state.plant_code.forEach(item=>{
						plantcodes.push(item.value)
					})
				}catch(e){}
				
			}
		}
		var states_list = [];
		if(this.state.selectedState !=undefined)
		{
			if(this.state.selectedState.length)
			{
				console.log(this.state.selectedState);
				this.state.selectedState.map(function(e){
					states_list.push(e.value);
				})
				if(states_list.indexOf("ALL") != -1)
				{
					states_list = ["ALL"]
				}
			}
			else
			{
				states_list = ["ALL"]
			}
		}
		else
		{
			states_list = ["ALL"]
		}	
		var stock_yard = [];
		if(this.state.stock_yard !=undefined)
		{
			if(this.state.stock_yard.length > 0)
			{
				console.log(this.state.stock_yard);
				this.state.stock_yard.map(function(e){
					stock_yard.push(e.value);
				})
				if(stock_yard.indexOf("ALL") != -1)
				{
					stock_yard = ["ALL"]
				}
			}
			else
			{
				stock_yard = ["ALL"]
			}
		}
		else
		{
			stock_yard = ["ALL"]
		}	
		
		var parking = [];
		if(this.state.parking !=undefined)
		{
			if(this.state.parking.length > 0)
			{
				console.log(this.state.parking);
				this.state.parking.map(function(e){
					parking.push(e.value);
				})
				if(parking.indexOf("ALL") != -1)
				{
					parking = ["ALL"]
				}
			}
			else
			{
				parking = ["ALL"]
			}
		}
		else
		{
			parking = ["ALL"]
		}			
		//console.log("this.state.plant_code ", this.state.plant_code);
        if(this.state.view_type == 'plant')
        {
            var formdata = {
                plant_codes:plantcodes,
                radius:this.state.radius,
                request_type:this.state.request_type,
                dept_code:this.state.dept_code.toUpperCase()
            }
		}
		else if(this.state.view_type == "state")
		{
            var formdata = {
				radius:this.state.radius,
				state_borders : 1,
				request_type:this.state.request_type,
                dept_code:this.state.dept_code.toUpperCase()
            }
		}
		else if(this.state.view_type == "within_states")
		{
			try{
				var formdata = {
					radius:this.state.radius,
					states_list : JSON.stringify(states_list),
					request_type:this.state.request_type,
					dept_code:this.state.dept_code.toUpperCase()
				}
				console.log(formdata,"statuslist")
			}
			catch(e){}
			
		}
		else if(this.state.view_type == "stockyard")
		{
			try{
				var formdata = {
					radius:this.state.radius,
					stock_yard : JSON.stringify(stock_yard),
					request_type:this.state.request_type,
					dept_code:this.state.dept_code.toUpperCase()
				}
				console.log(formdata,"stocklist")
			}
			catch(e){}
			
		}
		else if(this.state.view_type == "parkinggg")
		{
			try{
				var formdata = {
					radius:this.state.radius,
					parking : JSON.stringify(parking),
					request_type:this.state.request_type,
					dept_code:this.state.dept_code.toUpperCase()
				}
				console.log(formdata,"parking")
			}
			catch(e){}
			
		}
        else{
            
            var formdata = {
                radius:this.state.radius,
                latitude:document.getElementById("latitude").value,
                longitude:document.getElementById("longitude").value,
                request_type:this.state.request_type,
                dept_code:this.state.dept_code.toUpperCase()
            }
		}
		if($("#gps_record_flag").prop("checked"))
		{
			formdata['gps_records_flag'] = 1;
		}
		else
		{
			formdata['gps_records_flag'] = 0;	
		}
		//console.log("urlpath ", urlpath)
		//console.log("formadata ", formdata)
		rlatitude=document.getElementById("latitude").value;
		rlongitude=document.getElementById("longitude").value;
		//http://autometrics.in:5000/getNearByTrucks
		
			if(this.state.view_type != "state")
			{
				if(this.state.view_type == "within_states")
				{
					if(this.state.selectedState.length)
					{
						var transporter_code = "";
						var tptNCode = [];
						if(localStorage.getItem('transportercode') !=undefined)
						{
							var tptCode = (localStorage.getItem("transportercode"));
							//var transporter_code = tptCode[0];
							// if(transporter_code !='')
							// {
							// 	tptNCode.push(transporter_code);
							// 	tptNCode = JSON.stringify(tptNCode);
							// }
						}
						console.log(tptCode,"tptCode")
						formdata['transporterCodes'] = tptCode;
						this.submitFormHandler(formdata);
					}
					else{
						this.setState({
							show : true,
							basicTitle : "Please Select a State",
							basicType : "warning",
							overly : "show-n",
							loadshow : "show-n"
						})
					}
					
				}
				else
				{
					var transporter_code = "";
					var tptNCode = [];
					if(localStorage.getItem('transportercode') !=undefined)
					{
						var tptCode = (localStorage.getItem("transportercode"));
						//var transporter_code = tptCode[0];
						// if(transporter_code !='')
						// {
						// 	tptNCode.push(transporter_code);
						// 	tptNCode = JSON.stringify(tptNCode);
						// }
					}
					console.log(tptCode,"tptCode")
					formdata['transporterCodes'] = tptCode;
					this.submitFormHandler(formdata);
				}
			}
			else
			{
				this.setState({
					stateFormData : formdata,
					loadshow : "show-n",
					overly : "show-m",
					sliderStateEmailsTranslate : "slider-translate-40p"
				})
			}
		
		
		
    }
    onCloseRouteDiv(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : ""
		})
	}

    initMap = () => {
		try{
			var currentwindow;
			// console.log("markers.length ", markers.length);
			// if(markers.length > 0)
			// {
			// 	for(let i = 0; i < markers.length; i++) {
			// 		markers[i].setMap(null);
			// 	  }
			// }
			//setMapOnAll(null);
			//console.log("markers initmap ", this.state.rowData)
			// var contentdata = this.state.contentString;
			// console.log(this.state.rlatitude,this.state.rlongitude,"this.state.rlatitude,this.state.rlongitude");
			// if(this.state.rlatitude == '' && this.state.rlongitude == '')
			// {
			// 	var lt=28.4519751;
			// 	var ln=77.0310713;
			// }
			// else{
				
				if(this.state.selectedTabLat != '' && this.state.selectedTabLng != '')
				{
					var lt=this.state.selectedTabLat;
					var ln=this.state.selectedTabLng;
				}
				else{
					var lt=this.state.rlatitude;
					var ln=this.state.rlongitude;
				}
				
			// }
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 8,
				center: new window.google.maps.LatLng(lt,ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				},            
				disableDefaultUI: true,
				zoomControl: true*/
			});

			var input = document.getElementById('search');
			var searchBox = new window.google.maps.places.SearchBox(input);
			map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

			// Bias the SearchBox results towards current map's viewport.
			map.addListener('bounds_changed', function() {

			searchBox.setBounds(map.getBounds());
			});
			
			markers = this.state.rowData; 
			/*SEARCH START*/
			//console.log("searchBox",searchBox);
			searchBox.addListener('places_changed', function() {
			var places = searchBox.getPlaces();
				
			if (places.length == 0) {
				return;
			}

			// Clear out the old markers.
			markers.forEach(function(marker) {
				console.log("Search Marker ", marker)
				marker.setMap(null);
			});
			markers = [];
	// For each place, get the icon, name and location.
			var bounds = new window.google.maps.LatLngBounds();
			places.forEach(function(place) {
				if (!place.geometry) {
				console.log("Returned place contains no geometry");
				return;
				}
				var icon = {
				url: place.icon,
				size: new window.google.maps.Size(71, 71),
				origin: new window.google.maps.Point(0, 0),
				anchor: new window.google.maps.Point(17, 34),
				scaledSize: new window.google.maps.Size(25, 25)
				};

				// Create a marker for each place.
				//console.log("Plae Lat", place.geometry.location.lat());
				document.getElementById("latitude").value =place.geometry.location.lat();

				document.getElementById("longitude").value =place.geometry.location.lng();
				
				markers.push(new window.google.maps.Marker({
				map: map,
				icon: icon,
				title: place.name,
				position: place.geometry.location
				}));

				if (place.geometry.viewport) {
				// Only geocodes have viewport.
				bounds.union(place.geometry.viewport);
				} else {
				bounds.extend(place.geometry.location);
				}
			});
			map.fitBounds(bounds);
			});	

			/*SEARCH END*/

			circle = new window.google.maps.Circle({
				map: map
			});
			
			marker = new window.google.maps.Marker({
				map: map,
				draggable: true,
				animation: window.google.maps.Animation.DROP,
				//position: {lat: 28.48, lng: 77.06}
			});
			
			marker.addListener('click', function(){
				marker.setAnimation(window.google.maps.Animation.BOUNCE)			
			});
			
			window.google.maps.event.addListener(map, 'click', function(event) {
				
				document.getElementById("latitude").value = event.latLng.lat();
				document.getElementById("longitude").value = event.latLng.lng();
				marker.setPosition(event.latLng);
				
			});
			circle.bindTo('center', marker, 'position');
			var markerLatlng1;
			//console.log("Radius ", this.state.radius)
			//console.log("state Lat ",this.state.rlatitude)
			var bounds = new window.google.maps.LatLngBounds();
			var infowindow = new window.google.maps.InfoWindow();
			if(this.state.rlatitude != '' && this.state.rlongitude != '')
			{
				//var geoFencingObj = allcords;
				//console.log(parseFloat(this.state.rlatitude),parseFloat(this.state.rlongitude));
				markerLatlng1 = new window.google.maps.LatLng(this.state.rlatitude,this.state.rlongitude);
				//console.log("markerLatlng", markerLatlng1)
				circle = new window.google.maps.Circle({
					strokeColor: '#71caab',
					strokeOpacity: 0.7,
					strokeWeight: 1,
					fillColor: '#71caab',
					fillOpacity: 0.25,
					map: map,
					center: markerLatlng1,
					radius: Math.sqrt(this.state.radius) * 10000
				});


			}
			
			
			
			
			//console.log("allcords initmap ", allcords)
			if(doOnce){
				initOMS();
				doOnce = false;
			}

		//console.log("allcords ", allcords)
		if(this.state.rowData.length > 0)
		{
			var markers = this.state.rowData.map((marker) => {
				//console.log("marker ", marker)
				var image =  require('../../assets/icons/truck_na_24.png');
				if(marker.status == 1)
				{
					var truckText =  "Inside Plant";
					var color =  "#333333";
					var image =  require('../../assets/icons/truck_inside_24.png');
				}
				else if(marker.status == 2)
				{
					var truckText =  "Going to Destination";
					var color =  "#333333";
					var image =  require('../../assets/icons/truck_going_24.png');
				}
				else if(marker.status == 3 || marker.status == 4) 
				{
					var truckText =  "At Destination Location";
					var color =  "#333333";
					var image =  require('../../assets/icons/truck_atdealer_24.png');
				}
				else if(marker.status == 5)
				{
					var truckText =  "Left Destination Location";
					var color =  "#333333";
					var image =  require('../../assets/icons/truck_leftdealer_24.png');
				}
				else if(marker.status == 6)
				{
					var truckText =  "Return to Plant";
					var color =  "#333333";
					var image =  require('../../assets/icons/truck_return_24.png');
				}
				else if(marker.status == 7)
				{
					var truckText =  "Empty Truck Outside Plant";
					var color =  "#333333";
					var image =  require('../../assets/icons/truck_empty_24.png');
				}
				else{
					var truckText =  "N/A";
					var color =  "#333333";
					var image =  require('../../assets/icons/truck_na_24.png');
				}
				if(this.state.adaniCheck == 1)
				{
					if(marker.speed > 20)
					{
						var image =  require('../../assets/icons/shuttle_red.png');
					}
					else
					{
						var image =  require('../../assets/icons/shuttle_green.png');
					}
				}
				var contentarr = []
				var headerTitle = truckText
				if(this.state.adaniCheck == 1)
				{
					contentarr.push({"key":"Shuttle No", "value":marker.truck_no});
				}
				else
				{
					contentarr.push({"key":"Truck No", "value":marker.truck_no});
				}
				contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
				contentarr.push({"key":"Distance from geofence", "value":marker.distance_from_coordinates});
				//contentarr.push({"key":"LSP Name", "value":marker.lsp_name});
				contentarr.push({"key":"Address", "value":marker.area+", "+marker.state});
				
				var contentString = infoBox(image, headerTitle, contentarr, '')


				//var contentString = "Here "+marker.truck_no;
				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});
				
				var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
				bounds.extend(new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));

				var image =  require('../../assets/icons/truck_na_24.png');
				
				/*if(marker.overspeeding_exception == 1)
				{
					var image =  require('../../assets/icons/truck_overspeed.png');
				}
				if(marker.nightdriving_exception == 1)
				{
					var image =  require('../../assets/icons/truck-end.png');
				}*/

				if(marker.status == 1)
				{
					var image =  require('../../assets/icons/truck_inside_24.png');
				}
				else if(marker.status == 2)
				{
					var image =  require('../../assets/icons/truck_going_24.png');
				}
				else if(marker.status == 3 || marker.status == 4) 
				{
					var image =  require('../../assets/icons/truck_atdealer_24.png');
				}
				else if(marker.status == 5)
				{
					var image =  require('../../assets/icons/truck_leftdealer_24.png');
				}
				else if(marker.status == 6)
				{
					var image =  require('../../assets/icons/truck_return_24.png');
				}
				else if(marker.status == 7)
				{
					var image =  require('../../assets/icons/truck_empty_24.png');
				}
				else{
					var image =  require('../../assets/icons/truck_na_24.png');
				}
				if(this.state.adaniCheck == 1)
				{
					if(marker.speed > 20)
					{
						var image =  require('../../assets/icons/shuttle_red.png');
					}
					else
					{
						var image =  require('../../assets/icons/shuttle_green.png');
					}
				}
				var mark = new window.google.maps.Marker({
					position: markerLatlng,
					map: map,
					title:marker.truck_no,
					icon: image
				});
				mark.addListener('click', function() {
					infowindow.open(map, mark);
				});
				mark.setMap(map);
				return mark
			});
			var markerCluster = new MarkerClusterer(map, markers,
				{imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
			}
			var eventtrigger = document.getElementsByClassName('truck');
			console.log("this.state.selectedTabLat "  , this.state.selectedTabLat)
			
			if(this.state.selectedTabLat != '' && this.state.selectedTabLng != '')
			{
				//console.log(" this.state.selectedTabLat ",parseFloat(this.state.selectedTabLat.toFixed(6)))
				//console.log(" this.state.selectedTabLng ",parseFloat(this.state.selectedTabLng.toFixed(6)))
				markerLatlng1 = new window.google.maps.LatLng(this.state.selectedTabLat,this.state.selectedTabLng);
				//console.log("markerLatlng", markerLatlng1)
				circle = new window.google.maps.Circle({
					strokeColor: '#71caab',
					strokeOpacity: 0.7,
					strokeWeight: 1,
					fillColor: '#71caab',
					fillOpacity: 0.25,
					map: map,
					center: markerLatlng1,
					radius: Math.sqrt(this.state.radius) * 8000
				});
				bounds.extend(new window.google.maps.LatLng(parseFloat(this.state.selectedTabLat), parseFloat(this.state.selectedTabLng)));
				var mainmark = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(parseFloat(this.state.selectedTabLat), parseFloat(this.state.selectedTabLng)),
					map: map,
					//title:item.plant_code
				});
				
				mainmark.setMap(map);
				map.setZoom(8);
				console.log("mainmark.getPosition() ", mainmark.getPosition())
			//	map.setCenter(mainmark.getPosition());
				//map.setZoom(10)
				//map.fitBounds(bounds)
			}
			else{
				console.log("Here")
				if(this.state.tabplants.length > 0)
				{
					this.state.tabplants.forEach(item=>{
						console.log("Item Marker ", item)
						markerLatlng1 = new window.google.maps.LatLng(item.lat,item.lng);
						//console.log("markerLatlng", markerLatlng1)
						circle = new window.google.maps.Circle({
							strokeColor: '#71caab',
							strokeOpacity: 0.7,
							strokeWeight: 1,
							fillColor: '#71caab',
							fillOpacity: 0.25,
							map: map,
							center: markerLatlng1,
							radius: Math.sqrt(this.state.radius) * 8000
						});
						bounds.extend(new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)));
						var mainmark = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng)),
							map: map,
							title:item.plant_code
						});
						mainmark.addListener( 'click', (function() {
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Plant"
							contentarr.push({"key":"Code", "value":item.plant_code})
							
							var contentString = infoBox("", header, contentarr,'')
								
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							
						})(marker));
						mainmark.setMap(map);
					})
				}
			}
		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		  map.fitBounds(bounds)
		}
		catch(e)
		{
			console.log(e);
		}
		
		 
		  
	}

	goBack(){
		window.location.reload();
	}
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d/1000; // returns the distance in meter
	  };
	onShowInfo = (e) =>{
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString:"Here Success"
		});
		var ourMarker = e.target.id;
 			window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		  });
		infowindow.open(map, marker);

	}
	onClickHideAll(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",
			
		})
	}
	popmarker(e)
	{
		console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		//var image =  require('../../assets/icons/truckblue.png');
		var image =  require('../../assets/icons/truck_na_24.png');
		/*if(data.overspeeding_exception == 1)
		{
			var image =  require('../../assets/icons/truck_overspeed.png');
		}
		
		if(data.nightdriving_exception == 1)
		{
			var image =  require('../../assets/icons/truck-end.png');
		}*/
		
		var image =  require('../../assets/icons/truck_na_24.png');
		if(data.status == 1)
		{
			var truckText =  "Inside Plant";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_inside_24.png');
		}
		else if(data.status == 2)
		{
			var truckText =  "Going to Destination";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_going_24.png');
		}
		else if(data.status == 3 || data.status == 4) 
		{
			var truckText =  "At Destination Location";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_atdealer_24.png');
		}
		else if(data.status == 5)
		{
			var truckText =  "Left Destination Location";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_leftdealer_24.png');
		}
		else if(data.status == 6)
		{
			var truckText =  "Return to Plant";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_return_24.png');
		}
		else if(data.status == 7)
		{
			var truckText =  "Empty Truck Outside Plant";
			var color =  "#333333";var image =  require('../../assets/icons/truck_na_24.png');
			var image =  require('../../assets/icons/truck_empty_24.png');
		}
		else{
			var truckText =  "N/A";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_na_24.png');
		}
		if(this.state.adaniCheck == 1)
		{
			if(data.speed > 20)
			{
				var image =  require('../../assets/icons/shuttle_red.png');
			}
			else
			{
				var image =  require('../../assets/icons/shuttle_green.png');
			}
		}
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});


		var contentarr = []
		var headerTitle = truckText
		if(this.state.adaniCheck == 1)
		{
			contentarr.push({"key":"Shuttle No", "value":data.truck_no});
		}
		else
		{
			contentarr.push({"key":"Truck No", "value":data.truck_no});
		}
		contentarr.push({"key":"Transporter Name", "value":data.transporter_name});
		contentarr.push({"key":"Distance from geofence", "value":data.distance_from_coordinates});
		//contentarr.push({"key":"LSP Name", "value":data.lsp_name});
		contentarr.push({"key":"Address", "value":data.area+", "+data.state});
		
		var contentString = infoBox(image, headerTitle, contentarr, '')
		/*
		var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Department:" + data.dept_code+"<br />Address:"+data.address;
		var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+truckText+"</td></tr><tr><th>Distance from Geofence: </th><td style='text-align: right'>"+data.distancel
		contentString = contentString + " (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
		contentString = contentString + data.transporter_name + "</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name+"</td></tr><tr><th>Department:</th>";
		contentString = contentString + "<td style='text-align: right'>"+data.dept_code+"</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
			*/	
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
		
	}
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	 /*Truck Sidebar Component Starts*/
	 async onShowSidebarAquireComponent(e){
	   this.setState({
		sliderTranslate:'slider-translate',
		loadshow:'show-m'
	   });
	   //console.log(e);
	   if(e.colDef.field == 'truck_no')
	   {
		   let ComponentInfo = ComponentIndex['truck'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata1 = {
			   truck_no:e.data[e.colDef.field]
		   };
		   //console.log("layoutdata",layoutdata);
		   await redirectURL.post('/masters/truckByNo', layoutdata1)
			   .then((response) => {
				   //console.log("trucks response", response);
				   var truckrecords = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   truckInfo:truckrecords
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

			   /*Trucks details by Truck No*/
		   await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
			   .then((response) => {
				   //console.log(response);
				   var consignrecords = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   consignments:consignrecords
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   
		   /*Trucks details by Truck No*/
		   await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
			   .then((response) => {
				   //console.log("HR55W4880",response);
				   var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   currenttrucklocation:currenttrucklocation
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   //console.log("currenttrucklocation",this.state.currenttrucklocation);
		   //setTimeout(function(){})
		   
		   this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
		   /*		
		   this.setState({
			   showdata : this.displayData,
			   sliderTranslate:"slider-translate"
		   });
		   */

		  this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });
	   }

	   /*Transporters Sidebar*/
	   
	   if(e.colDef.field == 'transporter_name')
	   {
		   let ComponentInfo = ComponentIndex['transporter'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata1 = {
			   transporter_name:e.data[e.colDef.field]
		   };
		   //console.log("layoutdata",layoutdata);
		   await redirectURL.post('/masters/transporterByName', layoutdata1)
			   .then((response) => {
				   console.log("transporterByName response", response);
				   var transporterrecords = JSON.parse(JSON.stringify(response)).data;
				   var deptcodes=[];
				   var transportName='';
				   var transportCode='';
				   transporterrecords.map(item=>{
					   transportName=item.transporter_name;
					   transportCode=item.transporter_code;
					   deptcodes.push(item.dept_code+", ");
				   });
				   //console.log(transportName+","+transportCode+","+deptcodes)
				   var transportData = [{
					   transporter_name:transportName,
					   transporter_code:transportCode,
					   dept_code:deptcodes
				   }]
				   this.setState({
					   transporterInfo:transportData
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

		   /*Total Trucks Operating*/
		   await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
		   .then((response) => {
			   //console.log("Total Trucks Operating response", response);
			   this.setState({
				   truckoperates:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*Total Trucks With GPS Integration Lifetime*/
		   await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
		   .then((response) => {
			   console.log("Total Trucks Operating response", response);
			   this.setState({
				   truckgpslifetime:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });
			   
		   /*Total Trucks With GPS Integration Today*/
		   await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
		   .then((response) => {
			   //console.log("Total Trucks Operating response", response);
			   this.setState({
				   gpstoday:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

			   /*Transporters Consignment details*/
		   await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
			   .then((response) => {
				   //console.log(response);
				   var consignrecords = JSON.parse(JSON.stringify(response)).data;
				   var consignrecordslist=[];
				   consignrecords.map(item=>{
					   if(item.consigns.length > 0)
					   {
						   item.consigns.map(cons => {
							   consignrecordslist.push(cons)
						   })
						   
					   }
				   });
				   
				   console.log("consignrecords list", consignrecordslist);
				   this.setState({
					   consignments:consignrecordslist
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   
		   
		   this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
		   
		   this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });
	   }
	   /*End*/
	   
	   /*LSP Sidebar*/
	   
	   if(e.colDef.field == 'actual_lspuser')
	   {
		   let ComponentInfo = ComponentIndex['lsp'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata2 = {
			   actual_lspuser:e.data[e.colDef.field]
		   };
		   console.log("layoutdata2", layoutdata2);
		   //Total Trucks Integrate;
		   await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
			   .then((response) => {
				   var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
				   //console.log("LSP response serveeeee", lsprecords);
				   
				   var result = lsprecords.reduce(function(sum, item){
					   return sum = sum+item.count;
				   },0);
					   console.log("result", result);
				   this.setState({
					   lspInfo:result
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

		   /*Department Serving*/
		   await redirectURL.post('/masters/totalDeptServe', layoutdata2)
		   .then((response) => {
			   var deprecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("LSP DeptServe response", deprecords);
			   
			   var deptcnt=[];
			   deprecords.map(item => {
				   deptcnt.push(item._id)
			   });
			   this.setState({
				   deptServes:deptcnt.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*No Of Transporters Serving*/
		   await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
		   .then((response) => {
			   var transrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("Transporter serve response", transrecords);
			   var trasportcnt=[];
			   transrecords.map(item => {
				   trasportcnt.push(item._id)
			   });
			   this.setState({
				   transrecordserves:trasportcnt.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*No Of GPS DATA Send Today*/
		   await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
		   .then((response) => {
			   var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("GPS Send Data response", gpssendrecords);
			   
			   this.setState({
				   gpssendtoday:gpssendrecords.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*Total Trucks with no transporter data*/
		   await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
		   .then((response) => {
			   var notransrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("No Trasnporter Data response", notransrecords);
			   
			   this.setState({
				   nopetransporters:notransrecords.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });
		   
		   this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
		   /*		
		   this.setState({
			   showdata : this.displayData,
			   sliderTranslate:"slider-translate"
		   });
		   */

		  this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });

	   }
	   /*End*/
   }
   onGridReady = params => {
		this.gridApi = params.api;
		
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
		
	};

   onClickShowMap = async (tabcode) =>{
	   console.log("tabcode ", tabcode)
	   var field = "plant_code"
	   if(this.state.view_type == "stockyard"){
		field = "stockyard"
	   }else if(this.state.view_type == "parking"){
		field = "parking"
	   }
	   console.log(this.state.originalrowData , field , "1980")
	   var dataarray = [];
	   if(tabcode != 'all')
		{
			await this.state.originalrowData.map((item) => {
				if(item[field] == tabcode[field])
				{
					dataarray.push(item);
				}
			});
			await this.setState({
				rowData:dataarray,
				selectedTabLat:tabcode.lat,
				selectedTabLng:tabcode.lng,
				deftab:tabcode[field]
			});
			
			this.gridColumnApi.setColumnsVisible([field], false);
		
		}
		else{
			await this.setState({
				rowData:this.state.originalrowData,
				selectedTabLat:"",
				selectedTabLng:"",
				deftab:"all"
			});
			this.gridColumnApi.setColumnsVisible([field], true);
		
		}
		//window.initMap = await this.initMap
		await this.renderMap();
	
	//    if(tabcode != 'all')
	//    {
	// 		console.log("Code ", tabcode.plant_code)
	// 		let isFilterDataAvailable = this.state.rowData.filter(rec => {
	// 			if (rec["plant_code"]) {
	// 				return rec["plant_code"] == tabcode.plant_code;
	// 			}
	// 		});
	// 		var filterComponent = this.gridApi.getFilterInstance("plant_code");
	// 		filterComponent.selectNothing();
	// 		filterComponent.selectValue(tabcode.plant_code);
			
	// 		filterComponent.applyModel();
			
	// 		this.gridApi.setRowData(isFilterDataAvailable);
	// 		this.gridApi.onFilterChanged();
	//    }
	//    else{
	// 		//ALL
	// 		this.gridApi.setRowData(this.state.originalrowData);
	// 		this.gridApi.onFilterChanged();
	//    }
	
   }
    onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		if(this.state.request_type == 2)
		{
			var showonlysnd = (this.state.dept_code == 'SNDG' || this.state.dept_code == 'sndg')?false:true;
			var showonlyprt = (this.state.dept_code == 'LOG-PRT' || this.state.dept_code == 'log-prt')?false:true;
			var hidecol = false;
		}
		else
		{
			var showonlysnd = true;
			var showonlyprt = true;
			var hidecol = true;
		}
		console.log("dept_code ",this.state.dept_code)
		console.log("request_type ", this.state.request_type)
		console.log("showonlyprt ", showonlyprt)
		var hideDistance = (this.state.view_type == "within_states")?true:false;
		var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if(adaniCheck == 1)
		{
			tkName = "Shuttle No";
		}
		var columnDefs= [
			{
			  headerName: tkName,
			  field: "truck_no",
			  width: 140,
			  
			  
			},
		// 	{
		// 	  headerName: "LSP Code",
		// 	  field: "transporter_code",
		// 	  width: 200,
				  
		//   },  	        
			{
				headerName: "LSP Name",
				field: "transporter_name",
				width: 200,
					
			},
				{
				headerName: "Department Code",
				field: "dept_code",
				width: 120,
					
			},
				{
				headerName: "Actual GPS Provider",
				field: "actual_lspuser",
				width: 200,
				valueGetter: function(params){
					return "Enmovil";
				}	
			},
			{
				headerName: "Plant Code",
				field: "plant_code",
				width: 120,
				valueGetter:function(params){
					
						var keys = ["B", "F", "G", "I","M", "N","MUNDRA","MBPT", "MSILDM", "MSILG", "MSILM", "RPDC-BAN","RPDC-GUJ", "RPDC-NAG", "RPDC-SIL", "MSIL-G", "MSIL-M", "MSILMC","MSILME", "SMG"]; 
				
						// An array of values 
						var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri","Manesar", "TVP Nagpur", 'Mundra', "Mumbai Port", "MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore","RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri", "Gurgaon", "Manesar", "Casting","Power Train", "SMG Gujarat"]; 
						
						// Object created 
						var obj = {}; 
						
						// Using loop to insert key 
						// value in Object 
						for(var i = 0; i < keys.length; i++){ 
							if(keys[i] == params.data.plant_code)
							{
								return values[i];
							}
							
						}
				
				}
				
			},
			{
				headerName: "Invoice Time",
				field: "invoice_time",
				width: 150,
				hide:showonlysnd,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.invoice_time)
				},

				
			} ,
			{
				headerName: "Distance from Location (KM)",
				field: "distance_from_coordinates",
				width: 120,
				hide : hideDistance
			},
			{
				headerName: "Last Known City/Town",
				field: "area",
				width: 150,
			},
			{
				headerName: "Last Known State",
				field: "state",
				width: 150,
			},
			{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 150,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.timestamp)
				}
					
			},			  
			{
				headerName: "Gate Out Time",
				field: "gate_out_time",
				width: 150,
				valueGetter:function(params){
					return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)
				},
				hide:showonlyprt
				
			},
			
			{
				headerName: "Consignee City",
				field: "consignee_city",
				width: 140,
				hide:hidecol
			},

			{
				headerName: "Consignee State",
				field: "consignee_state",
				width: 140,
				hide:hidecol
			},
			{
				headerName: "Cluster",
				field: "cluster",
				width: 120,
				hide:showonlysnd
			},
			{
				headerName: "Route",
				field: "route_id",
				width: 120,
				hide:showonlysnd
			},
			 
		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}
		const btstye={
			marginBottom:"10px"
		}

        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				{/* <i className="icon-map cus-i"></i> <span>Trucks Within Geofence ( {hText} ) </span> */}
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>
									   
				       				{/*<span className="float-right">
										<img className="mapico2" src={require('../../assets/icons/truck_overspeed.png')} /> - Overspeed
										&nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck-end.png')} /> - Night Driving
									
									</span> */}
									<span className="col-xl-12 col-lg-12 pull-right" style={{fontSize:"12px",marginBottom:"20px"}}>
										{/*<img className="mapico1" src={require('../../assets/icons/truck_na_32.png')} /> - N/A
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_inside_32.png')} /> - Inside Plant
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_going_32.png')} /> - Going to Dealer
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_atdealer_32.png')} /> - At Dealer Location
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_leftdealer_32.png')} /> - Left Dealer Location
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_return_32.png')} /> - Return to Plant
										&nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck_empty_32.png')} /> - Empty Truck Outside Plant
										*/}
									</span>									
								</h5>
								{/* <br /> <b className="txt-danger-medium">*Note:</b> Only trucks that have pushed GPS data today */}
				   			</div>
				   			<div className="card-body row">
							   <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
								   <div className={"col-xl-12 col-lg-12 "+this.state.showStateTrucksMsg}>
								   	<p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p>
								   </div>

	                    			<div className="row">
                                        <div className="form-group  col-xl-2 col-lg-2">
					               			<label className="c-lbl">Search Type *:</label>
											   <select name="view_type" className="form-control" onChange={this.onChangeViewType.bind(this)} required >
                                                    <option value="">Select</option>
                                                    <option value="plant">Plant</option>
                                                    <option value="address">Address</option>
													<option value="within_states">Within States</option>
													<option value="state">Near state borders</option>
													<option value="stockyard">Stock Yard</option>
													{/* <option value="parkinggg">Parking</option>  */}
                                               </select>
										</div>
										<div className={"form-group  col-xl-2 col-lg-2 "+this.state.showStates}>
					               			<label className="c-lbl">Select States *:</label>
											<Select 
											closeMenuOnSelect={false} 
											name="plant_code" 
											isMulti={true} 
											placeholder="Select States" 
											className="rselect" 
											value={this.state.selectedState} 
											onChange={this.onSelectState.bind(this)} 
											options={this.selectStateOptions()} />

										</div>
                                        {/* <div className="form-group  col-xl-2 col-lg-2">
					               			<label className="c-lbl">Select Type *:</label>
											   <select name="request_type" className="form-control" onChange={this.changeInputHandler.bind(this)} required >
                                                    <option value="">Select</option>
                                                    <option value="1">All Trucks</option>
                                                    <option value="2">In Transit Trucks</option>
													<option value="5">Waiting at Dealer</option>
                                                    <option value="3">Empty Returned Trucks</option>
													
                                               </select>
										</div> */}
										<div className={"form-group col-xl-2 col-lg-2 "+(this.state.showPlant)}>
					               			<label className="c-lbl">Select Plant *:</label>
											   <Select closeMenuOnSelect={false} name="plant_code" isMulti={true} placeholder="Select Plant" className="rselect" value={this.state.plant_code} onChange={this.onChangePlantCode.bind(this)} options={this.selectOptionsItems()} required/>
											  
										</div>
										<div className={"form-group col-xl-2 col-lg-2 "+(this.state.showStockYard)}>
					               			<label className="c-lbl">Select Stock Yard *:</label>
											   <Select closeMenuOnSelect={false} name="stock_yard" isMulti={true} placeholder="Select Stock Yard" className="rselect" value={this.state.stock_yard} onChange={this.onChangeStockYard.bind(this)} options={this.state.stockyard_options} required/>
			
										</div>
										<div className={"form-group col-xl-2 col-lg-2 "+(this.state.showParking)}>
					               			<label className="c-lbl">Select Parking *:</label>
											   <Select closeMenuOnSelect={false} name="parking" isMulti={true} placeholder="Select Parking" className="rselect" value={this.state.parking} onChange={this.onChangeParking.bind(this)} options={this.state.parking_options} required/>
			
										</div>
					               		<div className={"form-group  col-xl-2 col-lg-2 "+this.state.showRadius}>
					               			<label className="c-lbl">Radius *:</label>
					               			<input type="text" name="radius" id="radius" value={this.state.radius} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Radius" required />
					               		</div>
                                           <div className={"form-group col-xl-2col-lg-2 "+(this.state.showAddr)}>
					               			<label className="c-lbl">Latitude *:</label>
					               			<input type="text" name="latitude" id="latitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Latitude" />
					               		</div>
                                        <div className={"form-group col-xl-2 col-lg-2 "+(this.state.showAddr)}>
					               			<label className="c-lbl">Longitude *:</label>
					               			<input type="text" name="longitude" id="longitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Longitude" />
					               		</div>
										<div className="form-group  col-xl-3 col-lg-3">
											<br />
											<label className="c-lbl"><input type="checkbox" id="gps_record_flag" /> &nbsp;&nbsp;{(this.state.adaniCheck == 1) ?	"Shuttles"  : "Trucks"} with GPS (24 Hrs)</label>
										</div>
										<div className="form-group col-xl-2 col-lg-2">
											<button type="submit" style={{marginTop:"35px"}} className={"btn btn-success "}>Submit</button>
										</div>
									</div>
									
				
								</form>
								<div className={"col-xl-12 col-lg-12 row "+(this.state.showrefresh)}>
									{(this.state.tabplants.length > 0)?
										<ul className="col-xl-10 col-lg-10 float-left  mpatabs">
											<li className={"btn "+(this.state.deftab == 'all'?"btn-success":"")} style={{float:"left", padding:"10px", cursor:"pointer", background:"#eee",margin:"0px 0px 0px 5px"}} onClick={this.onClickShowMap.bind(this, 'all')}>All</li>
											{this.state.tabplants.map((item) =>
												<li className={"btn "+(this.state.deftab == (this.state.view_type == 'stockyard' ? item['stockyard'] : this.state.view_type == 'parking'?item['parking']:item.plant_code)?"btn-success":"")} style={{float:"left", padding:"10px", cursor:"pointer", background:"#eee",margin:"0px 0px 0px 5px"}} onClick={this.onClickShowMap.bind(this, item)}>{(this.state.view_type == 'stockyard' ? item['stockyard'] : this.state.view_type == 'parking'?item['parking']:item.plant_code)}</li>
											)}
										</ul>
										:""}
										<div className="col-xl-2 col-lg-2 float-right ">
											<button type="button" style={{marginRight:"30px"}} className={"btn btn-danger float-right"} onClick={this.goBack} >Back</button>
										</div>
								</div>
								<div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" style={{marginTop:"12px", height:"37px"}} id="search" placeholder="Enter Address to search" />
									
		
									<div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
									{
									(this.state.rowData.length > 0)?
										<div className="col-xl-5 col-lg-5 sidediv">
										
											<div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={columnDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.rowData}
													enableCharts={false}
													enableRangeSelection={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
												
													floatingFilter={false}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    onCellClicked={this.popmarker}
												/>
											</div>
										</div>
										: ""
									}
								</div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				 
				<div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					
				 	<div className="slide-r-body" style={{position:"relative"}}>
					 <div className={"dataLoadpage " +(this.state.loadshow)}>
					</div>
					<div className={"dataLoadpageimg " +(this.state.loadshow)}>
						<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
					</div>
				 		<div className="col-xl-12 col-lg-12">
				 			 {this.displayData}
						</div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderStateEmailsTranslate)} >
					<div className="slide-r-title">
						<h4>
							Near By State 
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
									
						</h4>
					</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
				 		<div className="col-xl-12 col-lg-12">
						 	<form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.submitMailForm}>
								<div className="form-group  col-xl-12 col-lg-12">
									<label className="c-lbl">Email *:</label>
									<textarea className="form-control" id="email_ids" placeholder="Enter multiple e-mail addresses separated by comma" required></textarea>
								</div>
								<button type="submit" class="btn btn-info">Submit</button>
							</form>
						</div>
						<div className="form-group mt-20p">
								<p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p>
								
							</div>
					</div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> 					
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
          		<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">{(this.state.adaniCheck == 1) ?	"Shuttle"  : "Truck"} within Geofence</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This utility is used for getting data of number of vehicles which are present at a particular location within a stipulated radius defined by the user. By default the radius mentioned is 15 kms which can be customised as per the user’s requirement. The utility can be selected in below mentioned search types
							</div>
							<div className="col-xl-12 col-lg-12">
								<ul>
									<li>
										<span style={{fontWeight:"bold"}}>a. Plant:</span>
										<ul>
											<li>
												Based on the plant location as center one can find the {(this.state.adaniCheck == 1) ?	"shuttles"  : "trucks"} present near the vincinity of the plant within a stipulated radius.
											</li>
										</ul>
									</li>
									<li>
										<span style={{fontWeight:"bold"}}>b. Address:</span>
										<ul>
											<li>
												Based on a particular address (city, or area) one can find the {(this.state.adaniCheck == 1) ?	"shuttles"  : "trucks"} present in the vincinity of address within a stipulated radius
											</li>
										</ul>
									</li>
									<li>
										<span style={{fontWeight:"bold"}}>c. Within States:</span>
										<ul>
											<li>
												With this option selected one can find {(this.state.adaniCheck == 1) ?	"shuttles"  : "trucks"} which are present at a particular set of states as per the user’s requirement
											</li>
										</ul>
									</li>
									<li>
										<span style={{fontWeight:"bold"}}>d. Near State borders:</span>
										<ul>
											<li>
												With this option selected one can find {(this.state.adaniCheck == 1) ?	"shuttles"  : "trucks"} which are present at border of various states. This report will be mailed to user or set of users as per requirement. Mailing is done as the data fetching takes time and cannot be displayed on screen.
											</li>
										</ul>
									</li>
								</ul>
							</div>
							<div className="col-xl-12 col-lg-12">
								With the options selected the {(this.state.adaniCheck == 1) ?	"shuttles"  : "trucks"} can be search as below mentioned options
							</div>
							<div className="col-xl-12 col-lg-12">
								<ul>
									<li>
										a. All {(this.state.adaniCheck == 1) ?	"shuttles"  : "trucks"}
									</li>
									<li>
										b. In transit {(this.state.adaniCheck == 1) ?	"shuttles"  : "trucks"}
									</li>
									<li>
										c. Waiting at dealer locations
									</li>
									<li>
										d. Empty returned {(this.state.adaniCheck == 1) ?	"shuttles"  : "trucks"}
									</li>
								</ul>
							</div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }