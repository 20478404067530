import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import Drawmap from "./dealermap";
import NextBillionMaps from '../common/nbmaps';
import ShowMapicon from "./showmaplocation";

import axios from 'axios';

import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class TPTOEMLocations extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem,
                ShowMapicon:ShowMapicon
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:{value:"",label:""},
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            oems:[],
            plant_location_code:"",
            plant_location_name:"",
            plant_location_address:"",
            latitude:"",
            longitude:"",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            bulkslide:"",
            is_nbmap_calling: false,

		};
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
        // this.onClickShowTPTTruckLocation = this.onClickShowTPTTruckLocation.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		
        department='SNDG';
        departmentName = " ";
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        
        this.setState({
            loadshow:"show-n",
            overly:"show-n"
        })

       
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
        });
        redirectURL.post('/dashboard/oemmasterdata')    
        .then((response) => {
          var records = response.data.records;
        //   console.log("records ", records)
          this.setState({
              oems:records
          });
        })
        .catch(function (error) {
          console.log(error);
      });
	   this.onLoadData();
	  
    };
    onLoadData(){
        var parameters = {
            transporter_code:localStorage.getItem("transportercode")
        }
        redirectURL.post('/dashboard/oemlocations', parameters)    
        .then((response) => {
          var records = response.data.records;
        //   console.log("records ", records)
          this.setState({
              rowData:records
          });
        })
        .catch(function (error) {
          console.log(error);
      });
     
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    onClickUpdateRowHandler(event)
	{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
           let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
           let pageTitle = "Update OEM Location Data";
           if (currentDepartmentTitle) {
               pageTitle = "Update OEM Location Data" +  currentDepartmentTitle;
           }
           
           let eventOptions = {
               "category": pageTitle,
               "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
               "label": googleAnalytics.page.action.rowUpdated,
           }
           googleAnalytics.logEvent(eventOptions);
       }
      
       if(this.state.sourceopt.value != "" && this.state.plant_location_name != ""
       && this.state.plant_location_code != "" && this.state.plant_location_address != ""
       && this.state.latitude != "" && this.state.longitude != "")
       {
           var params = {
               rowID:this.state.rowid,
                oem_code:this.state.sourceopt.value,
                oem_name:this.state.sourceopt.label,
                plant_location_name:this.state.plant_location_name,
                plant_location_code:this.state.plant_location_code,
                plant_location_address:this.state.plant_location_address,
                latitude:this.state.latitude,
                longitude:this.state.longitude,
               transporter_code:localStorage.getItem("transportercode")
           }
           redirectURL.post("/dashboard/updateoemlocation", params,{
               headers:{
                   'Content-Type': 'application/json'
               }
           }).
           then(
               (response)=>{
                   //console.log("update response is : ",response.data);
                   //console.log("Update Response ",response.data.result)
                   if(response.data.status == "success")
                   {
                       this.setState({
                           show: true,
                           basicTitle:'Successfully update data',
                           basicType:"success",
                           sliderUpdate:"",
                           overly:"show-n"
                       });
                       this.onLoadData();
                   }
                   
                   else{
                       this.setState({
                           show: true,
                           basicTitle:'Something went wrong',
                           basicType:"danger",
                       });
                   }
                   
               }
           )
           .catch(function(error){
               console.log(error);
           });
       }
       else{
           this.setState({
               show: true,
               basicTitle:'All * fields should not be empty',
               basicType:"danger",
           });
       }

    }

    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Add OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "Add OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        console.log("this.state.sourceopt ", this.state.sourceopt)
        if(this.state.sourceopt.value != "" && this.state.plant_location_name != ""
       && this.state.plant_location_code != "" && this.state.plant_location_address != ""
       && this.state.latitude != "" && this.state.longitude != "")
        {
           var params = {
                oem_code:this.state.sourceopt.value,
                oem_name:this.state.sourceopt.label,
                plant_location_name:this.state.plant_location_name,
                plant_location_code:this.state.plant_location_code,
                plant_location_address:this.state.plant_location_address,
                latitude:this.state.latitude,
                longitude:this.state.longitude,
                transporter_code:localStorage.getItem("transportercode")
            }
            console.log("params ", params)
            redirectURL.post("/dashboard/saveoemlocation", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).
            then(
                (response)=>{
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'Successfully added data',
                            basicType:"success",
                            sliderRso:"",
                            overly:"show-n",
                            loadshow:"show-n"
                        });
                        this.onLoadData();
                    }
                    
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                    
                }
            )
            .catch(function(error){
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }

    }
    
    /*Alert Popups*/
	closeAlert = async () => {
        this.setState({
            show: false
        });
    }
    /*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        await window.location.reload()
    }


    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
            overly:'show-m',
            plant_location_name:"",
            plant_location_code:"",
            plant_location_address:"",
            latitude:"",
            longitude:"",
            sourceopt:{"value":"","label":""}
		})
        
    }
    
    
	 updateRowHandler = async(cell) => {
       
        var rowid = cell._id;
       
        await this.setState({
			sliderUpdate:'slider-translate',
            overly:'show-m',
            plant_location_name:cell.plant_location_name,
            plant_location_code:cell.plant_location_code,
            plant_location_address:cell.plant_location_address,
            latitude:cell.latitude,
            longitude:cell.longitude,
            rowid:rowid,
            sourceopt:{"value":cell.oem_code,"label":cell.oem_name}
		})
        
    }


    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            bulkslide:"",
            sliderBulk:"",
            is_nbmap_calling:false
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "OEM Master Data";
			if (currentDepartmentTitle) {
				pageTitle = "OEM Master Data" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/dashboard/deleteoemlocation", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
     
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeOfficerType(officertype){
		this.setState(
			{ officertype },
			() => console.log(`Option selected:`, this.state.officertype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.oems.length > 0)
			{
				this.state.oems.map((item) =>{
					items.push({"value":item.oem_code,"label":item.oem_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }
    
    
    onClickShowBulk()
    {
        this.setState({
            overly:"show-m",
			sliderBulk:'slider-translate',
        })
    }
    

	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'oem_name',
					inputName: 'oem_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'plant_location_code',
					inputName: 'plant_location_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'plant_location_name',
					inputName: 'plant_location_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'plant_location_address',
					inputName: 'plant_location_address',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'latitude',
					inputName: 'latitude',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'longitude',
					inputName: 'longitude',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
	}
    onSubmitBulkUpload(event)
    {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        var params = {
            csvcontent:this.state.csvcontent,
            transporter_code:localStorage.getItem("transportercode")
        }
        // console.log("Params ", params)
        redirectURL.post("/dashboard/bulkuploadoemlocation", params)
        .then((response) => {
            if(response.data.status == "success")
				{
					this.setState({
						show1: true,
						basicTitle1:'Successfully added items',
						basicType1:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderBulk:"",
                        file:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
        })
    }

    
    onClickShowTPTTruckLocation(e){
		console.log("Data ", e.data)
		this.setState({
            overly:"show-m",
            bulkslide : "slider-translate-60p",
            latLngArray:[{
                lat : e.data.latitude,
                lng : e.data.longitude,
                name : e.data.plant_location_name,
                address: e.data.plant_location_address
            }],
            is_nbmap_calling:true
        })
    }

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
                {
                    headerName: "",
                    field: "_id",
                    width: 40,
                    filter: false,
                    cellRenderer:'ShowMapicon',
                    editable:false
                },
	  	        {
	  	          headerName: "OEM Name",
	  	          field: "oem_name",
	  	          width: 120,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
	  	          headerName: "Plant Location Name",
	  	          field: "plant_location_name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Plant Location Code",
	  	          field: "plant_location_code",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
                {
	  	          headerName: "Plant Location Address",
	  	          field: "plant_location_address",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
                {
	  	          headerName: "Latitude",
	  	          field: "latitude",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
                {
	  	          headerName: "Longitude",
	  	          field: "longitude",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
                    headerName: "Action",
                    field: "_id",
                    width: 100,
                    filter: false,
                    cellRenderer:'updateItem',
                    editable:false
		        },
		        // {
                //     headerName: "Action",
                //     field: "_id",
                //     width: 100,
                //     filter: false,
                //     cellRenderer:'deleteItem',
                //     editable:false
		        // }
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>OEM Plant Locations </span>
                                       <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button>
                                       <button type="button" onClick={this.onClickShowBulk.bind(this)} className="btn btn-danger float-right">Bulk Upload</button>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add Location Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select OEM *</label>
                                
                                <Select 
                                placeholder={"Select OEM"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectsources()} />  
                            </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Plant Location Code *</label>
								<input 
									type="text" 
									name="plant_location_code"
									value={this.state.plant_location_code}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Plant Location Name *</label>
								<input 
									type="text" 
									name="plant_location_name"
									value={this.state.plant_location_name}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Plant Location Address *</label>
								<input 
									type="text" 
									name="plant_location_address"
                                    className="form-control" 
                                    value = {this.state.plant_location_address}
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							          
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Latitude *</label>
								<input 
									type="text" 
									name="latitude"
                                    className="form-control" 
                                    value = {this.state.latitude}
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							            
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Longitude *</label>
								<input 
									type="text" 
									name="longitude"
                                    className="form-control" 
                                    value = {this.state.longitude}
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							                         
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>



                <div className={"slide-r "+(this.state.sliderUpdate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Update OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>
							
                        <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select OEM *</label>
                                
                                <Select 
                                placeholder={"Select OEM"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value = {this.state.sourceopt}
                                options={this.selectsources()} />  
                            </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Plant Location Code *</label>
								<input 
									type="text" 
									name="plant_location_code"
									value={this.state.plant_location_code}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Plant Location Name *</label>
								<input 
									type="text" 
									name="plant_location_name"
									value={this.state.plant_location_name}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Plant Location Address *</label>
								<input 
									type="text" 
									name="plant_location_address"
                                    className="form-control" 
                                    value = {this.state.plant_location_address}
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							          
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Latitude *</label>
								<input 
									type="text" 
									name="latitude"
                                    className="form-control" 
                                    value = {this.state.latitude}
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							            
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Longitude *</label>
								<input 
									type="text" 
									name="longitude"
                                    className="form-control" 
                                    value = {this.state.longitude}
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							                             
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>


                <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Bulk Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File *</label>
								<input 
									type="file" 
									name="uploadfile"
									className="form-control" 
									onChange={this.changeFileHandler.bind(this)} />  
                            </div>
							 
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
                            
						    <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_location.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
						</form>

						
                        </div>
					</div>
				</div>


                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    {console.log("is_calling_nbmap")}
                    <h3 className="subH">View Location</h3>
                    <div className="slide-r-body" style={{height:"100%" , padding:"10px"}}>
                        <Drawmap 
                            context={this} 
                            coords={this.state.latLngArray} 
                            stepcoords={1} 
                            mapType={3} 
                            locInfo={""} 
                            mapFor={"dealer"}  
                        />
                        {/* {this.state.is_nbmap_calling?
                        <NextBillionMaps 
                                oem_plant_location = {this.state.latLngArray}
                        />:""} */}
                    </div>
                </div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}