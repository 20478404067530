/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
// import "@grapecity/wijmo.styles/wijmo.css";
// import * as pdf from "@grapecity/wijmo.pdf";
// import * as wijmo from "@grapecity/wijmo";
import ExportPDF from "./exportpdf"
import Constant from "../common/googlemapkey";

import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
import TabContent from 'reactstrap/lib/TabContent';
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var infoTitleBox = require('../common/google-title-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var moment = require('moment');
var mapviewmarker;
var mapviewmarkers = [];
var circle;
var selectlat;
var selectlng
var mapviewallcords = [];
var vmap;
var rad = function (x) {
	return x * Math.PI / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;
export default class ManageNearByTransporterTrucks extends Component {
	state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform: 'show-m',
			loadshow: 'show-n',
			showrefresh: 'show-n',
			sliderTranslate: '',
			showdata: this.displayData,
			radius: '5000',
			rlatitude: '',
			rlongitude: '',
			latitude: '',
			longitude: '',
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			defaultradius: 0,
			coordinates: [],
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			contentString: "testing",
			mwidth: "col-xl-12 col-lg-12",
			alldepts: [],
			transportervalue: '',
			alltransporters: [],
			truckstatus: '0',
			kpicode: '0',
			deptcode: '',
			truckNoList_map: [],
			alltrucks: [],
			truckno: '',
			showTrucksList: "show-n",
			filteredData: [],
			triptype: { "value": 1, "label": "ALL" },
			plantLocations: [],
			totalTrucksCount: 0,
			onTripCounter: 0,
			usermanualmodal: false,
			transporterTrucks: [],
			pdfRowData: [],
			pdfColumns: [],
			active_tab: '1',
			rowDataCopy: [],
			addressColumn: 300,
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			tpttrucks_map: [],
			adaniCheck: 0,
			rowData:[],
			showgoogletext : 0,

	};
	
	/*Alert Popups*/
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	async componentDidMount() {
		console.log(this.props,"this.props----MapView-Componentdid")
		if(this.props.pageLoad == "5")
		{
			this.setState({
				ontrips : this.props.ontrips
			});
			await this.onLoadPageData();
			this.truckRenderMap()
		}
	}

	async componentWillReceiveProps(newprops) {
		console.log(newprops,"this.props----MapView-Componentwill")
		if(newprops.pageLoad == "5" && this.state.rowData.length == 0)
		{
			this.setState({
				ontrips : this.props.ontrips
			});
			await this.onLoadPageData();
		}
	}

	onLoadPageData = async () => {
		this.setState({
			loadshow:"show-m",
		})
		var tptCode = localStorage.getItem('transportercode');
		if (tptCode.includes("T293") === true) {
			var adaniCheck = 1;
		}
		else {
			var adaniCheck = 0;
		}
		this.setState({
			adaniCheck: adaniCheck
		});
		var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if (vehicle_group != "") {
			var tparms = {
				transporter_code: tptCode,
				region: vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
				.then((response) => {
					var records = response.data.records;
					// console.log("records ", records)
					this.setState({
						tpttrucks_map: records
					})
				})
		}
		//console.log(this.props.match.)
		var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
		if (is_enmovil_transporter == 1) {
				var oemcode = ""
				if(localStorage.getItem("is_hero")>=0)
				{
					oemcode = "HERO"
				}
				else
				{
					oemcode = "Maruti"
				}
				var consignData = {
					transporter_code: localStorage.getItem("transportercode"),
					oem_code : oemcode
				}
				redirectURL.post("/dashboard/tptmsiltrips", consignData).then((response1) => {
					try
				{
					// var consignments = response1.data.records;
					var consignments = this.state.ontrips
					var tripstatus = response1.data.tripstatus
					console.log(tripstatus,"tripstatus")
					console.log("response1 consignments ", consignments)
					var consignmentsarr = []
					var consignmentitems = []
					if (consignments.length > 0) {
						consignments.map((item) => {
							consignmentsarr.push(item.truck_no);
							consignmentitems.push(item);
						})
					}
					console.log(consignmentsarr, "consignmentsarr")
					this.setState({
						msiltrips: consignmentsarr,
						msiltripitems: consignmentitems
					})
				}
				catch(e)
				{
					
				}
					this.setState({
						loadshow: 'show-m',
						coordinates: [],
						rowData: []
					});
					var dept = "SNDG";
					// console.log("dept_code is ", JSON.parse(localStorage.getItem("dept_code")))
					if (localStorage.getItem("dept_code") != undefined) {
						var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
						if (depmntCodeJns != "") {
							dept = depmntCodeJns[0];
						}
						else {
							dept = "SNDG";
						}
	
					}
					let pageTitle = "Carrier Location on Map";
					var transporter_code = "";
					if (localStorage.getItem('transportercode') != undefined) {
						var tptCode = JSON.parse(localStorage.getItem("transportercode"));
						//var transporter_code = tptCode[0];
					}
					this.setState({
						deptcode: dept,
						transportervalue: tptCode,
						pageTitle: pageTitle
					});
					if (vehicle_group != "" && vehicle_group != undefined) {
						var vgroup = vehicle_group;
					}
					else {
						var vgroup = "";
					}
					var formdata = {
						radius: 3000,
						latitude: "20.73568784876339",
						longitude: "79.38999444931353",
						dept_code: dept,
						transporter_code: JSON.stringify(tptCode),
						status: this.state.truckstatus,
						trip_type: 1,
						group_code: vgroup
					}
					rlatitude = "20.73568784876339";
					rlongitude = "79.38999444931353";
					// console.log("formdata ", formdata)
					//http://autometrics.in:5000/getNearByTrucks
					redirectURL.post('/vicinity/getRadiusCoordinates', formdata, {
						headers: {
							'Content-Type': 'application/json',
							'Access-Control-Allow-Origin': '*',
							'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
							'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"
	
						}
					})
						.then(
							(response) => {
								//console.log(response)
								//console.log("response", JSON.parse(response.data.body));
								// console.log(response.data.body)
								var mrkers = JSON.parse(JSON.parse(response.data.body).trucks_data);
								console.log(mrkers,"markers313");
								//console.log(response.data.body,"resppp");
								var deviceparams = {
									transporter_code: localStorage.getItem("transportercode")
								}
								redirectURL.post("/dashboard/carrierDevices", deviceparams).then(async (response1) => {
									// console.log(response1.data.devices, "devicesssssssssss")
									var transporterTrucks = [];
									var transptrucklist = [];
									response1.data.devices.map(function (e) {
										if (tptCode.indexOf(e.transporter_code) > -1) {
											transporterTrucks.push(e)
											transptrucklist.push(e.truck_no);
										}
									})
	
									if (mrkers.length > 0) {
										// console.log(transporterTrucks, "transporterTrucks")
										var mapviewmarkers = [];
										if (transporterTrucks.length > 0) {
											// mrkers.map(function(t){
											// 	{
											// 		mapviewmarkers.push(t)
											// 	}
											// })
											mapviewmarkers = await mrkers.filter(f =>
												transporterTrucks.filter((d) => {
													if (d.truck_no == f.truck_no) {
														// console.log("API ", d)
														// if(d.registereddevices != undefined)
														// {
														// 	if(d.registereddevices.length > 0)
														// 	{
														f.device_address = d.address
														f.device_city = d.city
														f.device_state = d.state
														f.trip_status = f.status
														var elasptm = "";
														// if(consignmentsarr != undefined && consignmentsarr != "undefined")
														// {
														// 	console.log(consignmentsarr,"consignmentsarr--mapp")
														// 		if (consignmentsarr.length > 0) {
														// 			if (consignmentsarr.indexOf(f.truck_no) >= 0) {
														// 				f.trip_status = "On Trip"
														// 			}
														// 		}															   
														// }
														
														var elspa = '';
														if (d.last_moved_time != "" && d.last_moved_time != undefined) {
															var td1 = moment.parseZone(new Date()).format("YYYY-MM-DD")
															var tday1 = moment.parseZone(new Date(td1 + " 00:00:00")).format("YYYY-MM-DD HH:mm:ss")
															// console.log("tday1 ", tday1)
															var e1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss")
															// console.log("current date is: ", e1)
															var e2 = moment.parseZone(d.last_moved_time).format("YYYY-MM-DD HH:mm:ss")
															// console.log("last moved date is: ", e2)
															var ems = moment(e1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
															// console.log("Difference : ", ems)
	
															var ed = moment.duration(ems);
															// console.log("Duration : ", ed)
															var es = Math.floor(ed.asSeconds());
															// console.log("Seconds ",es, f.truck_no)
															var esmins = Math.floor(ed.asMinutes());
															// console.log("MInutes ",esmins, f.truck_no)
	
															var todayems = moment(tday1, "YYYY-MM-DD HH:mm:ss").diff(moment(e2, "YYYY-MM-DD HH:mm:ss"));
															// console.log("Today Difference : ", todayems)
	
															var todayed = moment.duration(todayems);
															var todaysec = Math.floor(todayed.asSeconds());
	
															// console.log("todayhrs ", todaysec)
															if (todaysec >= 0) {
																elspa = 0
															}
															else {
																elspa = todaysec
															}
															if (es > 0) {
																elasptm = es
															}
															else {
																elasptm = 0
															}
														}
														else {
															elasptm = ""
														}
														// console.log("elasptm ", elasptm)
														f.elaped_time_from_last_moved_seconds = elasptm
	
														// 	}
														// }
														// console.log(f, f.truck_no)
														return f;
													}
												})
											);
										}
										console.log(mapviewmarkers,"filteredmarkers412")
										console.log(localStorage.getItem('transportercode'),"plant_location")
										if(localStorage.getItem('transportercode').includes("T293") && localStorage.getItem("plant_location") !="" &&
										localStorage.getItem("plant_location") != undefined)
										{
											mapviewmarkers = mapviewmarkers.filter((item)=>{
												console.log(item,"item state")
												if(item.device_state == localStorage.getItem("plant_location"))
												{
													return item;
												}
											})
										}
										// console.log(mapviewmarkers,"markersfilter")
										var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
										var pdfRowData = [];
										var searchTrucks = [];
										mapviewmarkers.map(t => {
											searchTrucks.push(t.truck_no);
											if (t.speed > 0) {
												var trvalstat = "Travelling"
											}
											else {
												var rrr = secondsToDhms(t.elaped_time_from_last_moved_seconds)
												var trvalstat = "Idle: " + rrr
											}
											return (pdfRowData.push({
												name: t.truck_no,
												device_address: t.device_address,
												device_city: t.device_city,
												device_state: t.device_state,
												speed: t.speed,
												travelling_status: trvalstat,
											})
											)
										})
										console.log("pdfRowData",pdfRowData)
										var pdfColumns = [
											{ header: name, binding: 'name' },
											{ header: 'Address', binding: 'device_address' },
											{ header: 'City', binding: 'device_city' },
											{ header: 'State', binding: 'device_state' },
											{ header: 'Speed', binding: 'speed' },
											{ header: 'Travelling Status', binding: 'travelling_status' },
										]
										// console.log(mapviewmarkers, "mapviewmarkers")
										this.setState({
											pdfRowData,
											pdfColumns,
											defaultradius: formdata.radius,
											coordinates: mapviewmarkers,
											totalTrucksCount: mapviewmarkers.length,
											mwidth: "col-xl-12 col-lg-12",
											rowData: mapviewmarkers,
											rowDataCopy: mapviewmarkers,
											radius: 5000,
											loadshow: 'show-n',
											showform: 'show-n',
											showrefresh: 'show-m',
											rlatitude: "21.144644112601775",
											rlongitude: "79.08860126768066",
											alltrucks: mapviewmarkers,
											//onTripCounter : JSON.parse(response.data.body).gps_trucks,
											onTripCounter: mapviewmarkers.length,
											transporterTrucks: transporterTrucks,
											//truckNoList_map :transptrucklist,
											truckNoList_map: searchTrucks
										});
										console.log(mapviewmarkers,"markersmsil")
										redirectURL.post("/weather/getPlants").then((rsp) => {
											this.setState({
												plantLocations: rsp.data,
											});
											//console.log(rsp.data);
											this.truckRenderMap();
	
										})
	
									}
									else {
										this.setState({
											show: true, basicType: 'warning', basicTitle: "No Data found.",
											loadshow: 'show-n',
											showform: 'show-m',
										});
	
									}
								})
	
							})
						.catch(function (error) {
							console.log(error);
						});
					var tptParams = {
						transporter_code: localStorage.getItem("transportercode")
					}
					// redirectURL.post("consignments/getTransportersTrucks",tptParams).then((response)=>{
					// 	var trucks = response.data;
					// 	try{
					// 		//console.log("trucks ", trucks)
					// 		var trucks = trucks.map(function(e){
					// 			if(e.truck_no != "")
					// 			{
					// 				return e.truck_no;
					// 			}
					// 		})
					// 		this.setState({
					// 				truckNoList_map :trucks
					// 		});
					// 	}catch(e){}
	
					// 	//console.log(trucks.length,"trucks");
					// });
	
	
					this.truckRenderMap();
				})
			
			this.logPageView(); // Log page view to GA


		}
		else {
			window.location.href = "/";
		}
	}

	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}
	createSelectTransportersItems() {
		let items = [];
		items.push({ value: 'ALL', label: "All Transporters" });
		this.state.alltransporters.map(item => {
			items.push({ value: item.transporter_code, label: item.transporter_name });
			//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>); 
		});
		return items;
	}
	/*
	createSelectItems() {
		let items = [];         
		//console.log(this.state.alldepts);
		items.push(<option key='all' value='all'>All Departments </option>);  
		this.state.alldepts.map((item, key) => {
			//console.log("Here "+item,key);
			items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
		return items;
	} 
	
	*/


	changeHandler = truckstatus => {

		this.setState(
			{ truckstatus },
			// () => console.log(`Option selected:`, this.state.truckstatus)
		);
	}

	changeKpiHandler = kpicode => {

		this.setState(
			{ kpicode },
			// () => console.log(`Option selected:`, this.state.kpicode)
		);
	}

	setTripType = trip => {
		this.setState({
			triptype: trip
		})
	}
	handleChange = transportervalue => {
		this.setState(
			{ transportervalue },
			// () => console.log(`Option selected:`, this.state.transportervalue)
		);
	}
	/*handleTruckStatusChange(e){
		this.setState({ truckstatus: e.target.value });
	}*/
	initalList = (event) => {
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.truckNoList_map;
		dataset = dataset.slice(0, 100);
		var tpttrucks_map = this.state.tpttrucks_map;
		var tptunqrecords = []
		if (tpttrucks_map.length > 0) {
			dataset.filter(f =>
				tpttrucks_map.filter((d) => {
					if (d.truck_no == f) {
						tptunqrecords.push(f)
					}
				})
			);
		}
		else {
			tptunqrecords = dataset
		}
		this.setState({
			filteredData: tptunqrecords,
			showTrucksList: "show-m",
		})
	}
	handlerForm = (event) => {
		console.log("eventsearchmap",event.target.value)
		try {
			$(".trucks-dropdown").removeClass("show-n");
			var dataset = this.state.truckNoList_map;

			var tpttrucks_map = this.state.tpttrucks_map;
			var tptunqrecords = []
			if (tpttrucks_map.length > 0) {
				dataset.filter(f =>
					tpttrucks_map.filter((d) => {
						if (d.truck_no == f) {
							tptunqrecords.push(f)
						}
					})
				);
			}
			else {
				tptunqrecords = dataset
			}

			var filteredResult = tptunqrecords.filter(function (e) {
				if (e != "" && e != null) {
					return e.toString().toLowerCase().includes($("#inputTruckSearch").val().toLocaleLowerCase());
				}

			});
			filteredResult = filteredResult.slice(0, 100);
			this.setState({
				filteredData: filteredResult,
				showTrucksList: "show-m",
			});
		} catch (e) { }
	}
	onClickTruck(event) {
		$("#inputTruckSearch").val($(this).text());
	}
	// handleFilter(e) {
	// 	// console.log("filter clicked");
	// 	e.preventDefault();
	// 	if (googleAnalytics.page.enableGA) {
	// 		let pageTitle = this.state.pageTitle;
	// 		let eventOptions = {
	// 			"category": pageTitle,
	// 			"action": this.state.eventFormAction,
	// 			"label": googleAnalytics.page.action.formSubmittedSearchByTransporter,
	// 		}
	// 		googleAnalytics.logEvent(eventOptions);
	// 	}
	// 	this.setState({
	// 		loadshow: 'show-m',
	// 	});
	// 	//console.log("Here ",this.props.match.url);

	// 	//console.log("Checking ",this.state.transportervalue);
	// 	var transcode = [];
	// 	var tstatus;
	// 	// console.log(this.state.transportervalue);



	// 	if (this.state.truckstatus == '0') {
	// 		tstatus = this.state.truckstatus;
	// 	}
	// 	else {
	// 		tstatus = this.state.truckstatus.value;
	// 	}
	// 	// var trip_type = this.state.triptype.value;

	// 	var trip_type = 1;
	// 	var transporter_code = "";
	// 	if (localStorage.getItem('transportercode') != undefined) {
	// 		var tptCode = JSON.parse(localStorage.getItem("transportercode"));
	// 		//var transporter_code = tptCode[0];
	// 	}
	// 	var formdata = {
	// 		radius: 3000,
	// 		latitude: "20.73568784876339",
	// 		longitude: "79.38999444931353",
	// 		dept_code: this.state.deptcode,
	// 		transporter_code: JSON.stringify(tptCode),
	// 		status: tstatus,
	// 		trip_type: trip_type
	// 	}
	// 	// console.log(formdata);
	// 	rlatitude = "20.73568784876339";
	// 	rlongitude = "79.38999444931353";
	// 	redirectURL.post('/vicinity/getRadiusCoordinates', formdata, {
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 			'Access-Control-Allow-Origin': '*',
	// 			'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
	// 			'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

	// 		}
	// 	})
	// 		.then(
	// 			async (response) => {
	// 				//console.log("response", JSON.parse(response.data.body));

	// 				var mrkers = JSON.parse(JSON.parse(response.data.body).trucks_data);
	// 				//console.log(JSON.parse(JSON.parse(response.data.body).trucks_data))

	// 				if (mrkers.length > 0) {
	// 					var mapviewmarkers = [];
	// 					var transporterTrucks = this.state.transporterTrucks;
	// 					if (transporterTrucks.length > 0) {
	// 						// mrkers.map(function(t){
	// 						// 	if(transporterTrucks.indexOf(t.truck_no) > -1 )
	// 						// 	{
	// 						// 		mapviewmarkers.push(t)
	// 						// 	}
	// 						// })

	// 						mapviewmarkers = await mrkers.filter(f =>
	// 							transporterTrucks.filter((d) => {
	// 								if (d.truck_no == f.truck_no) {
	// 									// console.log("API ", d)
	// 									if (d.registereddevices != undefined) {
	// 										if (d.registereddevices.length > 0) {
	// 											f.device_address = d.registereddevices[0].address
	// 											f.device_city = d.registereddevices[0].city
	// 											f.device_state = d.registereddevices[0].state
	// 										}
	// 									}
	// 									return f;
	// 								}
	// 							})
	// 						);

	// 					}
						
	// 					//console.log(mapviewmarkers,"markersfilter")
	// 					await this.setState({
	// 						defaultradius: formdata.radius,
	// 						coordinates: mapviewmarkers,
	// 						//onTripCounter : JSON.parse(response.data.body).gps_trucks,
	// 						onTripCounter: mapviewmarkers.length,
	// 						mwidth: "col-xl-12 col-lg-12",
	// 						rowData: mapviewmarkers,
	// 						rowDataCopy:mapviewmarkers,
	// 						radius: formdata.radius,
	// 						loadshow: 'show-n',
	// 						showform: 'show-n',
	// 						showrefresh: 'show-m',
	// 						rlatitude: formdata.latitude,
	// 						rlongitude: formdata.longitude,
	// 					});
	// 					console.log(mapviewmarkers, "markersmsil")
	// 					// console.log(rowDataCopy, "rowDataCopy1")
	// 					await redirectURL.post("/weather/getPlants").then((rsp) => {
	// 						this.setState({
	// 							plantLocations: rsp.data,
	// 						});
	// 						// console.log(rsp.data);
	// 						this.truckRenderMap();
	// 					})

	// 				}
	// 				else {
	// 					this.setState({
	// 						show: true, basicType: 'warning', basicTitle: "No Data found.",
	// 						loadshow: 'show-n',
	// 						showform: 'show-m',
	// 					});

	// 				}
	// 			})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});

	// 	this.truckRenderMap();
	// }
	truckRenderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMapTrucks");
		window.initMapTrucks = this.initMapTrucks
	}

	initMapTrucks = () => {
		mapviewallcords = [];
		console.log(this.state.coordinates,"this.state.coordinates")
		this.state.coordinates.map((mapviewmarker) => {
			mapviewallcords.push(mapviewmarker);
		});

		var contentdata = this.state.contentString;
		if (this.state.adaniCheck == 1) {
			var lt = 28.495765038354467;
			var ln = 77.0713875520654;
			var mpZoom = 15;
		}
		else {
			mpZoom = 5;
			if (this.state.rlatitude == '' && this.state.rlongitude == '') {

				lt = 21.144644112601775;

				ln = 79.08860126768066;
			}
			else {

				lt = this.state.rlatitude;

				ln = this.state.rlongitude;
			}
		}
		try {
			vmap = new window.google.maps.Map(document.getElementById('map_canvas_tpt_nearby'), {
				zoom: mpZoom,
				center: new window.google.maps.LatLng(lt, ln),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				// radius: 500,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },            
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
		catch (e) {
			// map = new window.google.maps.Map(document.getElementById('map_canvas_tpt_nearby'), {
			// 	zoom: 5,
			// 	center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
			// 	mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			// 	/*mapTypeControl: true,
			// 	gestureHandling: 'greedy',
			// 	mapTypeControlOptions: {
			// 		style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
			// 		position: window.google.maps.ControlPosition.TOP_RIGHT
			// 	  },            
			// 	disableDefaultUI: true,
			// 	zoomControl: true*/
			// });
		}



		// mapviewmarker = new window.google.maps.Marker({
		// 	map: map,
		// 	draggable: true,
		// 	// animation: window.google.maps.Animation.DROP,
		// 	//position: {lat: 28.48, lng: 77.06}
		// 	position: new window.google.maps.LatLng(28.49, 77.06),
		// 	title: 'marut udyog'
		// });

		// circle = new window.google.maps.Circle({
		//     map: map,
		// 	radius: 500,
		// 	fillColor: '#99bcc7'
		// });

		// circle.bindTo('center', mapviewmarker, 'position');

		var markerLatlng1;

		markerLatlng1 = new window.google.maps.LatLng(28.49568960582423, 77.07074382127196);
		//console.log("markerLatlng1", markerLatlng1)
		var circle = new window.google.maps.Circle({
			strokeColor: '#71caab',
			strokeOpacity: 0.7,
			strokeWeight: 1,
			fillColor: '#71caab',
			fillOpacity: 0.25,
			map: vmap,
			center: markerLatlng1,
			radius: 1000
		});

		// mapviewmarker.addListener('click', function(){
		// 	mapviewmarker.setAnimation(window.google.maps.Animation.BOUNCE)			
		// });

		// window.google.maps.event.addListener(map, 'click', function(event) {

		// 	//document.getElementById("latitude").value = event.latLng.lat();
		// 	//document.getElementById("longitude").value = event.latLng.lng();
		// 	mapviewmarker.setPosition(event.latLng);

		// });	

		//console.log("Radius ", this.state.radius)
		// console.log(mapviewallcords);
		var entracLogin = localStorage.getItem('transportercode').includes('_ENTRAC')?true :false
		var mapviewmarkers = mapviewallcords.map((mapviewmarker) => {
			// if(mapviewmarker.truck_no = "HR46E1297")
			// {
				// console.log("marker123 ",mapviewmarker);
			// }

			if (this.state.adaniCheck == 1) {
				var image = require('../../assets/icons/shuttle_na_24.png');
			}
			else {
				var image = require('../../assets/icons/truck_na_24.png');
			}
			if (mapviewmarker.status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_inside_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_inside_24.png');
				}
			}
			else if (mapviewmarker.status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_going_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_going_24.png');
				}
			}
			else if (mapviewmarker.status == 3 || mapviewmarker.status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_green.png');
				}
				else {
					var image = require('../../assets/icons/truck_atdealer_24.png');
				}
			}
			else if (mapviewmarker.status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/truck_leftdealer_24.png');
				}
			}
			else if (mapviewmarker.status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_return_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_return_24.png');
				}
			}
			else if (mapviewmarker.status == 7) {
				var truckText = "Empty Truck Outside Plant";
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_empty_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_empty_24.png');
				}
			}
			else {
				var truckText = mapviewmarker.truck_no;
				var color = "#333333";
				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_na_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}
			}
			if (this.state.adaniCheck == 1) {
				if (mapviewmarker.speed > 20) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/shuttle_green.png');
				}
			}
			var contentarr = []
			var headerTitle = mapviewmarker.truck_no;


			// if (this.state.adaniCheck == 1) {
			// 	contentarr.push({ "key": "Shuttle No", "value": mapviewmarker.truck_no });
			// }
			// else {
			// 	contentarr.push({ "key": "Truck No", "value": mapviewmarker.truck_no });
			// }
			// contentarr.push({ "key": "Transporter Name", "value": mapviewmarker.transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":mapviewmarker.distance});
			//contentarr.push({"key":"GPS Provider", "value":mapviewmarker.actual_lspuser});
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(mapviewmarker.timestamp) });
			contentarr.push({ "key": "Address", "value": mapviewmarker.device_address });
			contentarr.push({ "key": "City", "value": mapviewmarker.device_city });
			contentarr.push({ "key": "State", "value": mapviewmarker.device_state });
			if (mapviewmarker.speed > 0) {
				var trvalstat = "Travelling"
			}
			else {
				var rrr = secondsToDhms(mapviewmarker.elaped_time_from_last_moved_seconds)
				var trvalstat = "Idle: " + rrr
			}
			contentarr.push({ "key": "Travelling Status", "value": trvalstat });
			contentarr.push({ "key": "Latititude", "value": mapviewmarker.latitude });
			contentarr.push({ "key": "Longitude", "value": mapviewmarker.longitude });
			// if(mapviewmarker.trip_status == "On Trip")
			// {
			// 	var tripstatus = "On Trip"
			// }
			// else if(mapviewmarker.trip_status == undefined || mapviewmarker.trip_status != "undefined")
			// {
			// 	var tripstatus = "Not On Trip"
			// }
			// else
			// {
			// 	var tripstatus = "Not On Trip"
			// }
			contentarr.push({ "key": "Trip Status", "value": mapviewmarker.trip_status });
			contentarr.push({ "key": "GPS Provider", "value": "Enmovil" });
			contentarr.push({ "key": "Speed (km/h)", "value": Math.ceil(mapviewmarker.speed) });
			
			var contentString = infoBox(image, headerTitle, contentarr, '')

			//console.log(contentString,"contentString");
			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + mapviewmarker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+mapviewmarker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + mapviewmarker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+mapviewmarker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +mapviewmarker.address+"</td></tr></table>";

			//var contentString = "Here "+mapviewmarker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});


			var infowindow1 = new window.google.maps.InfoWindow({
				content: infoTitleBox(mapviewmarker.shuttle_no)
			});
			// sample infowindow default view
			// if (this.state.adaniCheck ===1) {
			// 	window.google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
			// 		infowindow.open(map, mapviewmarker);
			// 	  });
			// }


			var markerLatlng1 = new window.google.maps.LatLng(parseFloat(mapviewmarker.latitude), parseFloat(mapviewmarker.longitude));
			if (entracLogin) {
				var image = require('../../assets/icons/car_black_24.png');

			} else {



				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_na_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}
				if (mapviewmarker.status == 1) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_inside_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_inside_24.png');
					}
				}
				else if (mapviewmarker.status == 2) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_going_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_going_24.png');
					}
				}
				else if (mapviewmarker.status == 3 || mapviewmarker.status == 4) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_green.png');
					}
					else {
						var image = require('../../assets/icons/truck_atdealer_24.png');
					}
				}
				else if (mapviewmarker.status == 5) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_red.png');
					}
					else {
						var image = require('../../assets/icons/truck_leftdealer_24.png');
					}
				}
				else if (mapviewmarker.status == 6) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_return_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_return_24.png');
					}
				}
				else if (mapviewmarker.status == 7) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_empty_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_empty_24.png');
					}
				}
				else {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_na_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_na_24.png');
					}
				}
			}
			/*
			if(mapviewmarker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(mapviewmarker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}
			*/
			if (this.state.adaniCheck == 1) {
				if (mapviewmarker.speed > 20) {
					var image = require('../../assets/icons/shuttle_red.png');
				}
				else {
					var image = require('../../assets/icons/shuttle_green.png');
				}
			}

			var mark = new window.google.maps.Marker({
				position: markerLatlng1,
				map: vmap,
				title: "Status: " + truckText + " #" + mapviewmarker.truck_no,
				icon: image
			});
			if (this.state.adaniCheck == 1) {
				mark.addListener('click', function () {
					infowindow.open(vmap, mark);
					infowindow1.close(vmap, mark);
				});
				new window.google.maps.event.addListenerOnce(vmap, 'tilesloaded', function () {
					infowindow1.open(vmap, mark);
				});
				new window.google.maps.event.addListener(infowindow, 'closeclick', function () {
					infowindow1.open(vmap, mark);
				});
			}
			else {
				mark.addListener('click', function () {
					infowindow.open(vmap, mark);
				});
			}
			return mark
			// mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		try {
			var markerCluster = new MarkerClusterer(vmap, mapviewmarkers,
				{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
		}
		catch (e) { }
		// var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		//map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if (entracLogin ==false){
			if (this.state.plantLocations.length > 0) {
				this.state.plantLocations.map(function (params) {
					var plant = {
						url: require("../../assets/icons/" + params.icon), // url
						scaledSize: new window.google.maps.Size(32, 32), // scaled size
						origin: new window.google.maps.Point(0, 0), // origin
						anchor: new window.google.maps.Point(0, 0) // anchor
					};
					var coords = JSON.parse(params.coordinates);
					var plantmarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(coords.lat, coords.lng),
						map: vmap,
						icon: plant,
						title: params.plant_name
					});
					// var cityCircle = new window.google.maps.Circle({
					// strokeColor: '#9fc9b9',
					// strokeOpacity: 0.3,
					// strokeWeight: 2,
					// fillColor: '#9fc9b9',
					// fillOpacity: 0.6,
					// map: map,
					// center:coords,
					// radius: 50 * 1000
					// });
				})
			}
		}
		

	}
	toggleBounce() {
		if (mapviewmarker.getAnimation() !== null) {
			mapviewmarker.setAnimation(null);
		} else {
			mapviewmarker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	}

	changeInputHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	changeRadiusHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
		circle.setRadius(value);
	}


	goBack = () => {
		window.location.reload();
	}
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d / 1000; // returns the distance in meter
	};
	onShowInfo = (e) => {
		// console.log(JSON.parse(e.target.id));
		this.setState({
			contentString: "Here Success"
		});
		var ourMarker = e.target.id;
		window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		});
		infowindow.open(vmap, mapviewmarker);

	}
	popmarker = (e) => {
		console.log("map", vmap)
		var entracLogin = localStorage.getItem('transportercode').includes('_ENTRAC')?true :false
		var truckNo = e.data.truck_no;
		var truck = [];
		var trucks = this.state.alltrucks;
		// console.log(truckNo, "input truck");
		var filteredValue = trucks.filter(function (e) {
			return e.truck_no == truckNo
		});
		if (filteredValue.length > 0) {
			console.log(filteredValue,"Matched")
			var lat = parseFloat(filteredValue[0].latitude);
			var lng = parseFloat(filteredValue[0].longitude);
			var data = filteredValue[0];			
			vmap.setCenter(new window.google.maps.LatLng(lat, lng));
			vmap.setZoom(22);
			var markerLatlng1 = new window.google.maps.LatLng(lat, lng);

			if (filteredValue[0].status == 1) {
				var truckText = "Inside Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 2) {
				var truckText = "Going to Dealer";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
				var truckText = "At Dealer Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 5) {
				var truckText = "Left Destination Location";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 6) {
				var truckText = "Return to Plant";
				var color = "#333333";
			}
			else if (filteredValue[0].status == 7) {
				var truckText = "Empty Truck Outside Plant";
				var color = "#333333";
			}
			else {
				var truckText = "N/A";
				var color = "#333333";
			}
			if (entracLogin) {
				var image = require('../../assets/icons/car_black_24.png');
			}
			else {


				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_na_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}
				if (filteredValue[0].status == 1) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_inside_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_inside_24.png');
					}
				}
				else if (filteredValue[0].status == 2) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_going_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_going_24.png');
					}
				}
				else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_green.png');
					}
					else {
						var image = require('../../assets/icons/truck_atdealer_24.png');
					}
				}
				else if (filteredValue[0].status == 5) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_red.png');
					}
					else {
						var image = require('../../assets/icons/truck_leftdealer_24.png');
					}
				}
				else if (filteredValue[0].status == 6) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_return_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_return_24.png');
					}
				}
				else if (filteredValue[0].status == 7) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_empty_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_empty_24.png');
					}
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}

				if (this.state.adaniCheck == 1) {
					if (filteredValue[0].speed > 20) {
						var image = require('../../assets/icons/shuttle_red.png');
					}
					else {
						var image = require('../../assets/icons/shuttle_green.png');
					}
				}
			}

			var vmarker = new window.google.maps.Marker({
				position: markerLatlng1,
				map: vmap,
				title: data.truck_no,
				icon: image
			});


			var contentarr = []
			var headerTitle = filteredValue[0].truck_no;
			// if (this.state.adaniCheck == 1) {
			// 	contentarr.push({ "key": "Shuttle No", "value": filteredValue[0].truck_no });
			// }
			// else {
			// 	contentarr.push({ "key": "Truck No", "value": filteredValue[0].truck_no });
			// }
			// contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
			//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
			//contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
			contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
			contentarr.push({ "key": "Address", "value": filteredValue[0].device_address });
			contentarr.push({ "key": "City", "value": filteredValue[0].device_city });
			contentarr.push({ "key": "State", "value": filteredValue[0].device_state });
			if (filteredValue[0].elaped_time_from_last_moved_seconds <= 180) {
				var trvalstat = "Travelling"
			}
			else {
				var rrr = secondsToDhms(filteredValue[0].elaped_time_from_last_moved_seconds)
				var trvalstat = "Idle: " + rrr
			}
			contentarr.push({ "key": "Travelling Status", "value": trvalstat });
			contentarr.push({ "key": "Latititude", "value": filteredValue[0].latitude });
			contentarr.push({ "key": "Longitude", "value": filteredValue[0].longitude });
			// if(filteredValue[0].trip_status == "On Trip")
			// {
			// 	var tripstatus = "On Trip"
			// }
			// else if(filteredValue[0].trip_status == undefined || filteredValue[0].trip_status != "undefined")
			// {
			// 	var tripstatus = "Not On Trip"
			// }
			// else
			// {
			// 	var tripstatus = "Not On Trip"
			// }
			contentarr.push({ "key": "Trip Status", "value": filteredValue[0].trip_status });
			contentarr.push({ "key": "GPS Provider", "value": "Enmovil" });
			contentarr.push({ "key": "Speed (KMPH)", "value": filteredValue[0].speed });
			
			// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
			
			

			var contentString = infoBox(image, headerTitle, contentarr, '')
			//console.log(contentarr,"contentarr")
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			vmarker.setAnimation(window.google.maps.Animation.DROP)
			vmarker.addListener('click', function () {
				infowindow.open(vmap, vmarker);
			});

			// infowindow.open({
			// 	anchor: mapviewmarker,
			// 	map,
			// 	shouldFocus: true,
			// });
			// infowindow.open(map,mapviewmarker);
			vmarker.setMap(vmap);
			console.log(lat, lng)
			this.setState({
				'lat' : lat,
				'lng' : lng,
				'showgoogletext' : 1
			})
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "No Data found.",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}
		// console.log(filteredValue);

	}
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth: '30%',
			sliderTranslate: "slider-translate",
			showDiv: 'show-m',
			csvmessage: '',
			csverrmessage: '',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
		});
	}

	onSelectTrucks() {
		var items = []
		this.state.alltrucks.map((item) => {
			items.push({ value: item, label: item.truck_no });
		})
		return items;
	}
	changeTruckHandler(event) {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	truckFormHandler(e) {
		e.preventDefault();
		var inputTruckSearch = $("#inputTruckSearch").val().toUpperCase();
		var dataset = this.state.truckNoList_map;
		var truckCheck = 0;
		if (dataset.length > 0) {
			for (var d = 0; d < dataset.length; d++) {
				if (dataset[d] == inputTruckSearch) {
					truckCheck = 1;
					break;
				}
			}
		}
		if (truckCheck == 1) {
			if (googleAnalytics.page.enableGA) {
				let pageTitle = this.state.pageTitle;
				let eventOptions = {
					"category": pageTitle,
					"action": this.state.eventFormAction,
					"label": googleAnalytics.page.action.formSubmittedSearchByTruckNo
				}
				googleAnalytics.logEvent(eventOptions);
			}
			var truck = []
			var trucks = this.state.alltrucks;
			// console.log($("#inputTruckSearch").val(), "input truck");
			var filteredValue = trucks.filter(function (e) {
				return e.truck_no == $("#inputTruckSearch").val()
			});
			// console.log(filteredValue, "filteredValue")
			if (filteredValue.length > 0) {
				// console.log("Matched")
				var lat = parseFloat(filteredValue[0].latitude);
				var lng = parseFloat(filteredValue[0].longitude);
				var data = filteredValue[0];
				vmap.setCenter(new window.google.maps.LatLng(lat, lng));
				vmap.setZoom(22);
				var markerLatlng1 = new window.google.maps.LatLng(lat, lng);

				if (filteredValue[0].status == 1) {
					var truckText = "Inside Plant";
					var color = "#333333";
				}
				else if (filteredValue[0].status == 2) {
					var truckText = "Going to Dealer";
					var color = "#333333";
				}
				else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
					var truckText = "At Dealer Location";
					var color = "#333333";
				}
				else if (filteredValue[0].status == 5) {
					var truckText = "Left Destination Location";
					var color = "#333333";
				}
				else if (filteredValue[0].status == 6) {
					var truckText = "Return to Plant";
					var color = "#333333";
				}
				else if (filteredValue[0].status == 7) {
					var truckText = "Empty Truck Outside Plant";
					var color = "#333333";
				}
				else {
					var truckText = "N/A";
					var color = "#333333";
				}

				if (this.state.adaniCheck == 1) {
					var image = require('../../assets/icons/shuttle_na_24.png');
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}
				if (filteredValue[0].status == 1) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_inside_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_inside_24.png');
					}
				}
				else if (filteredValue[0].status == 2) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_going_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_going_24.png');
					}
				}
				else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_green.png');
					}
					else {
						var image = require('../../assets/icons/truck_atdealer_24.png');
					}
				}
				else if (filteredValue[0].status == 5) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_red.png');
					}
					else {
						var image = require('../../assets/icons/truck_leftdealer_24.png');
					}
				}
				else if (filteredValue[0].status == 6) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_return_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_return_24.png');
					}
				}
				else if (filteredValue[0].status == 7) {
					if (this.state.adaniCheck == 1) {
						var image = require('../../assets/icons/shuttle_empty_24.png');
					}
					else {
						var image = require('../../assets/icons/truck_empty_24.png');
					}
				}
				else {
					var image = require('../../assets/icons/truck_na_24.png');
				}
				if (this.state.adaniCheck == 1) {
					if (filteredValue[0].speed > 20) {
						var image = require('../../assets/icons/shuttle_red.png');
					}
					else {
						var image = require('../../assets/icons/shuttle_green.png');
					}
				}
				var mapviewmarker = new window.google.maps.Marker({
					position: markerLatlng1,
					map: vmap,
					title: data.truck_no,
					icon: image
				});
				var contentarr = []
				var headerTitle = filteredValue[0].truck_no;
				// contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
				// contentarr.push({ "key": "Transporter Name", "value": filteredValue[0].transporter_name });
				contentarr.push({ "key": "Last Packet Time", "value": getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp) });
				contentarr.push({ "key": "Address", "value": filteredValue[0].device_address });
				contentarr.push({ "key": "City", "value": filteredValue[0].device_city });
				contentarr.push({ "key": "State", "value": filteredValue[0].device_state });
				if (filteredValue[0].elaped_time_from_last_moved_seconds <= 180) {
					var trvalstat = "Travelling"
				}
				else {
					var rrr = secondsToDhms(filteredValue[0].elaped_time_from_last_moved_seconds)
					var trvalstat = "Idle: " + rrr
				}
				contentarr.push({ "key": "Travelling Status", "value": trvalstat });
				contentarr.push({ "key": "Latititude", "value": filteredValue[0].latitude });
				contentarr.push({ "key": "Longitude", "value": filteredValue[0].longitude });
				// if(mapviewmarker.trip_status == "On Trip")
				// {
				// 	var tripstatus = "On Trip"
				// }
				// else if(mapviewmarker.trip_status == undefined || mapviewmarker.trip_status != "undefined")
				// {
				// 	var tripstatus = "Not On Trip"
				// }
				// else
				// {
				// 	var tripstatus = "Not On Trip"
				// }
				contentarr.push({ "key": "Trip Status", "value": filteredValue[0].trip_status });
				//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
				//contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
				contentarr.push({ "key": "GPS Provider", "value": "Enmovil" });
				contentarr.push({ "key": "Speed (KMPH)", "value": filteredValue[0].speed });
				// contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
				var contentString = infoBox(image, headerTitle, contentarr, '')





				// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
				// contentString = contentString + trucks[i].distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+trucks[i].truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
				// contentString = contentString + trucks[i].transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+trucks[i].lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
				// contentString = contentString  +trucks[i].address+"</td></tr></table>";


				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});

				mapviewmarker.setAnimation(window.google.maps.Animation.DROP)

				// mapviewmarker.addListener("click", () => {
				// 	infowindow.open({
				// 	  anchor: mapviewmarker,
				// 	  map,
				// 	  shouldFocus: false,
				// 	});
				//   });	


				mapviewmarker.addListener('click', function () {
					infowindow.open(vmap, mapviewmarker);
					// console.log("markerTruckNo : ", mapviewmarker.title )
				});


				// infowindow.open(map,mapviewmarker);

				mapviewmarker.setMap(vmap);
			}
			else {
				this.setState({
					show: true, basicType: 'warning',
					basicTitle: "No Data found.",
					loadshow: 'show-n',
					showform: 'show-m',
				})
			}
			// console.log(filteredValue);
			// for (var i = 0; i < trucks.length; i++){
			// 	// look for the entry with a matching `code` value
			// 	if (trucks[i].truck_no == $("#inputTruckSearch").val()){
			// 	   // we found it
			// 	  // trucks[i].name is the matched result

			// 	}
			//   }
			/*
					var lat = parseFloat(e.value.latitude);
					var lng =parseFloat(e.value.longitude);
					var data =e.value;
					map.setCenter(new window.google.maps.LatLng(lat,lng));
					map.setZoom(22);
					var markerLatlng1 = new window.google.maps.LatLng(lat, lng);
					
					if(e.value.status == 1)
					{
						var image =  require('../../assets/icons/truck_inside_24.png');
					}
					else if(e.value.status == 2)
					{
						var image =  require('../../assets/icons/truck_going_24.png');
					}
					else if(e.value.status == 3 || e.value.status == 4) 
					{
						var image =  require('../../assets/icons/truck_atdealer_24.png');
					}
					else if(e.value.status == 5)
					{
						var image =  require('../../assets/icons/truck_leftdealer_24.png');
					}
					else if(e.value.status == 6)
					{
						var image =  require('../../assets/icons/truck_return_24.png');
					}
					else if(e.value.status == 7)
					{
						var image =  require('../../assets/icons/truck_empty_24.png');
					}
					else{
						var image =  require('../../assets/icons/truck_na_24.png');
					}
					
					var mapviewmarker = new window.google.maps.Marker({
						position: markerLatlng1,
						map: map,
						title:data.truck_no,
						icon:image
					});
					var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Address:"+data.address;
					var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+data.status;
					contentString = contentString +"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>"+data.distance+" (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no;
					contentString = contentString + "</td></tr><tr><th>Name: </th><td style='text-align: right'>"+data.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name;
					contentString = contentString + "</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
					
					
					var infowindow = new window.google.maps.InfoWindow({
						content: contentString
					});
					
					mapviewmarker.setAnimation(window.google.maps.Animation.DROP)
					mapviewmarker.addListener('click', function() {
						infowindow.open(map, mapviewmarker);
					});
					
					mapviewmarker.setMap(map);
			*/
		}
		else {
			this.setState({
				show: true, basicType: 'warning',
				basicTitle: "Enter Valid Truck Number",
				loadshow: 'show-n',
				showform: 'show-m',
			})
		}
	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};

	toggle = (tab) => {
		if (tab == 1) {
			console.log(this.state.rowDataCopy, "rowDataCopy")
			this.gridApi.setRowData(this.state.rowDataCopy);
			this.setState({ coordinates: this.state.rowDataCopy })
			this.truckRenderMap();

		}
		else if (tab == 2) {
			console.log(this.state.rowDataCopy, "rowDataCopy")
			var filteredData = this.state.rowDataCopy.filter(e => e.trip_status == "On Trip")
			console.log(filteredData, "filteredData")
			// console.log(this.state.rowDataCopy, "rowDataCopy")
			this.gridApi.setRowData(filteredData)
			this.setState({ coordinates: filteredData })
			this.truckRenderMap();

		}
		else if (tab == 3) {
			var filteredData = this.state.rowDataCopy.filter(e => e.trip_status != "On Trip")
			console.log(filteredData, "filteredData")
			this.gridApi.setRowData(filteredData)
			this.setState({ coordinates: filteredData })
			this.truckRenderMap();


		}
		else if (tab == 4) {
			var filteredData = this.state.rowDataCopy.filter((e) => {
				// if(e.elaped_time_from_last_moved_seconds >=180 || e.elaped_time_from_last_moved_seconds == "")
				// {

				// 	return e;
				// }
				// if (JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0) {
				// 	// console.log("params.data.last_known_speed ", params.data.last_known_speed)
					if (e.speed <= 0) {
						return "Idle";
						// return e;
					}
				// }
				// else {
				// 	if (e.elaped_time_from_last_moved_seconds >= 180 || e.elaped_time_from_last_moved_seconds == "") {

				// 		return e;
				// 	}
				// }
			})
			// console.log(filteredData, "filteredData")
			this.gridApi.setRowData(filteredData)
			this.setState({ coordinates: filteredData })
			this.truckRenderMap();

		}
		else if (tab == 5) {
			// console.log(this.state.rowDataCopy)
			var filteredData = this.state.rowDataCopy.filter((e) => {
				// if (e.elaped_time_from_last_moved_seconds <= 180) {
				// 	// console.log(e.elaped_time_from_last_moved_seconds, e.last_known_speed)
				// }
				// if (e.elaped_time_from_last_moved_seconds <= 180 && e.elaped_time_from_last_moved_seconds != "") {

				// 	return e;
				// }
				// if (JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0) {
					// console.log("params.data.last_known_speed ", params.data.last_known_speed)
					if (e.speed > 0) {
						return "Travelling";
					}
				// }
			})
			// console.log(filteredData, "filteredData")
			this.gridApi.setRowData(filteredData)
			this.setState({ coordinates: filteredData })
			this.truckRenderMap();

		}
		this.setState({
			active_tab: tab
		})
	}

	render() {
		const modalStyles = {
			width: '1300px !important',
		}

		const { usermanualmodal } = this.state;
		const { truckstatus } = this.state;
		const truckstatusoptions = [
			{ value: '0', label: 'All Status' },
			{ value: '1', label: 'Inside Plant' },
			{ value: '2', label: 'Going to Destination' },
			{ value: '3', label: 'At Destination Location' },
			{ value: '5', label: 'Left Destination Location' },
			/*{ value: '6', label: 'Return to Plant' },*/
			{ value: '7', label: 'Empty Truck Outside Plant' },
		];
		const kpioptions = [
			{ value: '0', label: 'All Status' },
			{ value: '1', label: 'Overspeed' },
			{ value: '2', label: 'Transit Time Delay' },
			{ value: '3', label: 'Night Driving' },
			{ value: '4', label: 'Enroute Stoppages' }
		];
		const dStyles = {
			width: '100%',
			marginLeft: '0px',
			paddingLeft: '0px',
			height: '500px'
		}
		const btstye = {
			marginBottom: "10px"
		}
		var listData = []
		try {
			this.state.filteredData.map(function (e) {
				listData.push(<li className="dropdown-truck-no-map">{e}</li>)
			})
		} catch (e) { console.log(e) }
		var showonlysnd = true;
		var showonlyprt = true;
		var hidecol = true;
		var hideDistance = true;
		var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if (adaniCheck == 1) {
			tkName = "Shuttle No";
		}
		var columnDefs = [
			{
				headerName: "",
				field: "map",
				width: 60,
				cellRenderer: function (params) {
					return "<i class='icofont icofont-map-pins f25'></i>"
				},
				editable: false
			},
			{
				headerName: tkName,
				field: "truck_no",
				width: 120,
				filter: true
			},
			// {
			// 	headerName: "Transporter Name",
			// 	field: "transporter_name",
			// 	width: 130,
			// 	filter: true
			// },
			// {
			// 	headerName: "Plant Code",
			// 	field: "plant_code",
			// 	width: 100,
			// 	filter: true
			// },
			{
				headerName: "Segment",
				field: "segment",
				width: 110,
				filter: true,
				hide: showonlysnd,
				valueGetter: function (params) {
					if (params.data.segment == "P") {
						return "Primary"
					}
					else {
						return "Secondary"
					}
				}
			},
			// {
			// 	headerName: "Distance From Co-ordinates (KMS)",
			// 	field: "distance_from_coordinates",
			// 	width: 160,
			// 	filter: true,
			// 	valueGetter: function(params){
			// 		return Math.round(params.data.distance_from_coordinates)
			// 	}
			// },
			// {
			// 	headerName: "Actual LSP User",
			// 	field: "actual_lspuser",
			// 	width: 130,
			// 	filter: true
			// },

			// {
			// 	headerName: "Area",
			// 	field: "area",
			// 	width: 130,
			// 	filter: true
			// },
			// {
			// 	headerName: "State",
			// 	field: "state",
			// 	width: 130,
			// 	filter: true
			// },

			{
				headerName: "Address",
				field: "device_address",
				width: 200,
				filter: true,
			},
			{
				headerName: "City",
				field: "area",
				width: 130,
				filter: true
			},

			{
				headerName: "State",
				field: "state",
				width: 130,
				filter: true
			},

			{
				headerName: "Speed",
				field: "speed",
				width: 130,
				filter: true,
				resizable: true,
				valueGetter: function (params) {
					if (params.data.speed != undefined && params.data.speed != "") 
					{

						return params.data.speed;
					}
					else
					{
						return 0;
					}
				}
			},

			{
				headerName: "Travelling Status",
				field: "elaped_time_from_last_moved_seconds",
				width: 190,
				// colId:"elaped_time_from_last_moved_seconds",
				valueGetter:function(params){
					try{
						
						// console.log("params.data.travel_sttaus ", params.data.travel_sttaus)
						// if(params.data.travel_sttaus != "" && params.data.travel_sttaus != undefined)
						// {
						// 	return "Inactive";
						// }
						// else
						// {
						// 	// console.log("params.data.elaped_time_from_last_moved_seconds ", params.data.elaped_time_from_last_moved_seconds, params.data.truck_no)
							
						// 	if(parseInt(params.data.elaped_time_from_last_moved_seconds) <= 180)
						// 	{
						// 		// console.log("S2")
						// 		return "Travelling";
						// 	}
						// 	else{
						// 		// console.log("S3")
						// 		// var mre = secondsToDhms(params.data.elaped_time_from_last_moved_seconds)
						// 		if(JSON.parse(localStorage.getItem("transportercode")).indexOf("T297") >= 0)
						// 		{
									// console.log("params.data.last_known_speed ", params.data.last_known_speed)
									if(params.data.speed > 0)
									{
										return "Travelling";
									}
									else
									{
										var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
										return "Idle ("+mre+")";
									}
						// 		}
						// 		else
						// 		{
						// 			var mre = secondsToHM(params.data.elaped_time_from_last_moved_seconds);
						// 			return "Idle ("+mre+")";
						// 		}
								
						// 	}
						// }
						
					}
					catch(e){
						return "";
					}
				}
			  },
			  

		];

		return (

			<div className="">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">

							<div className="card-header">
								<h5>
									{/* <i className="icofont icofont-truck cus-i"></i> <span> Trucks Locations ( {hText} ) </span> */}
									<i className="icofont icofont-truck cus-i"></i> <span> {this.state.pageTitle} </span>
									{(this.state.pdfRowData.length > 0) ?
										<ExportPDF rowData={this.state.pdfRowData} pagetitle = "Carrier Location on Map" pdfColumns={this.state.pdfColumns}
											addressColumn={this.state.addressColumn} />
										: ""}
								</h5>
							</div>
							<div className="card-body row">
								<div className="col-xl-12 col-lg-12 col-sm-12 row ">
									{/* <form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.handleFilter.bind(this)}>
										<div className="col-xl-8 col-lg-8 col-sm-8" style={{paddingRight:'0px',marginBottom:"0.2em"}}>
											<label>Search by Trip Type:</label>
											<Select placeholder="Select Trip Type"
											isMulti={false}
											value={this.state.triptype}
											onChange={this.setTripType.bind(this)} 
											options={(this.state.deptcode == "LOG-SC")?[{"value":1,"label":"ALL"}]:
											[{"value":1,"label":"ALL"},{"value":2,"label":"On Trip"},{"value":5,"label":"Waiting at Dealer"},{"value":3,"label":"Empty / Non-MSIL trip"}]
											} 
											required/>
										</div>
										<div className="col-xl-2 col-lg-2 col-sm-2" style={{padding:"0"}}>
											<button name="go" id="go"style={{height:"38px",margin:"10px 15px"}} className="btn btn-primary" type="submit">Go</button>
										</div>
									</form> */}
									<div className="col-xl-4 col-lg-4">
										<label><i className="icofont icofont-vehicle-delivery-van cus-i"></i> Total Carriers : <span className="counter txt-info f20">
												<CountUp end={(this.state.onTripCounter != '') ? this.state.onTripCounter : 0} />
											</span></label>
										{/* <h6 className="txt-info f20" style={{ paddingTop: "0.2em", marginLeft: "-2em" }}>
										</h6> */}
									</div>
									<div className={"trucks-dropdown " + this.state.showTrucksList} style={{ top: "72px", left: "30.6em", width: "20.3em" }}>
										<ul>
											{listData}
										</ul>
									</div>
									<form className=" theme-form col-xl-6 col-lg-6 row mb-20p" onSubmit={this.truckFormHandler.bind(this)}>
										<div className="col-xl-8 col-lg-8 col-sm-8" style={{ paddingRight: '0px' }}>
											<label>Search by {(this.state.adaniCheck == 1) ? "Shuttle" : "Truck"} No:</label>
											<input type="text" name="truck_no" id="inputTruckSearch" placeholder={(this.state.adaniCheck == 1) ? "Shuttle" : "Truck No"} autoComplete="off" className="form-control" onFocus={this.initalList.bind(this)} onChange={this.handlerForm.bind(this)} />
										</div>
										<div className="col-xl-4 col-lg-4 col-sm-4" style={{ padding: "0" }}>
											<button name="go" id="go" style={{ height: "38px", margin: "27px 15px 10px 15px" }} className="btn btn-primary" type="submit">Go</button>
										</div>
									</form>
								</div>
								<div className="col-xl-12 col-lg-12 col-sm-12 row ">
									<div className="col-xl-6 col-lg-6">
										<div id="myGrid" style={{ height: "540px", width: "100%" }} className="ag-theme-balham">
											<AgGridReact
												modules={this.state.modules}
												columnDefs={columnDefs}
												defaultColDef={this.state.defaultColDef}
												rowData={this.state.rowData}
												enableCharts={false}
												enableRangeSelection={false}
												autoGroupColumnDef={this.state.autoGroupColumnDef}
												onGridReady={this.onGridReady}
												onGridState={this.onGridState}
												statusBar={this.state.statusBar}
												sideBar={this.state.sideBar}
												paginationPageSize={this.state.paginationPageSize}
												pagination={false}
												floatingFilter={false}
												onCellClicked={this.popmarker}
											/>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6">

										
										<Nav tabs className={'tabs-color'}>
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab === '1' })}
													onClick={() => { this.toggle('1'); }} >
													All
												</NavLink>
											</NavItem>
											{(localStorage.getItem("transportercode").includes("_ENTRAC"))?"":
												<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab === '2' })}
													onClick={() => { this.toggle('2'); }} >
													On Trip
												</NavLink>
											</NavItem>
											}
											{(localStorage.getItem("transportercode").includes("_ENTRAC"))?"":
											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab === '3' })}
													onClick={() => { this.toggle('3'); }} >
													Off Trip
												</NavLink>
											</NavItem>
											}

											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab === '4' })}
													onClick={() => { this.toggle('4'); }} >
													Idle
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink className={classnames({ active: this.state.active_tab === '5' })}
													onClick={() => { this.toggle('5'); }} >
													Running
												</NavLink>
											</NavItem>
										</Nav>
										<div id="map_canvas_tpt_nearby" className={this.state.mwidth} style={dStyles}></div>
										{this.state.showgoogletext === 1 ? 
										<div>
										<a href={`https://maps.google.com/?q=${this.state.lat},${this.state.lng}`} target="_blank" rel="noopener noreferrer" marginLeft={"15px"} style={{color: '#000000'}}> Go To Google Map</a>
										</div> : 
										''}
										


									</div>
								</div>
							</div>
							<div className="card-header">
								<h6>
									<i className="fa fa-info-circle" style={{ color: "cornflowerblue" }}></i> <span> Note : Only {(this.state.adaniCheck == 1) ? "shuttles" : "trucks"} with GPS data in the last 24 hours are considered </span>

								</h6>
							</div>

						</div>
						<div className={"dataLoadpage " + (this.state.loadshow)}>
						</div>
						<div className={"dataLoadpageimg " + (this.state.loadshow)}>
							<div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
						</div>
					</div>

				</div>


				<div className={"slide-r " + (this.state.sliderTranslate)} >


					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className={"dataLoad " + (this.state.loadshow)}>
							<img src={require("../../assets/icons/loader.gif")} alt="img" />
						</div>
						<div className="col-xl-12 col-lg-12">
							{this.displayData}
						</div>
					</div>
				</div>

				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<h5 className="model-title">{(this.state.adaniCheck == 1) ? "Shuttle" : "Truck"} Location (Map)</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This utility provides pictorial representation of entire fleet (departmental) present at various locations throughout India.
							</div>
							<div className="col-xl-12 col-lg-12">
								Through this utility option user can find vehicle details either for individual {(this.state.adaniCheck == 1) ? "shuttle" : "truck"} or for selected transporter fleet for in transit and Empty/non MSIL trips
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>

			</div>

		);
	}
}


function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	// console.log(len);
	var htmlStr = "";
	var coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}
function secondsToHM(seconds) {
	seconds = Number(seconds);
	// var d = Math.floor(seconds / (3600*24));
	// var h = Math.floor(seconds % (3600*24) / 3600);
	// var m = Math.floor(seconds % 3600 / 60);
	// var s = Math.floor(seconds % 60);
	
	// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	// return dDisplay + hDisplay + mDisplay;
	
	var hh = Math.floor((seconds / 3600));
	var hrr = Math.floor(seconds - (hh*3600));
	var mm = Math.floor(hrr/60)
	if(mm < 10)
	{
		var mins = "0"+mm;
	}
	else
	{
		var mins = mm;
	}

	if(hh < 10)
	{
		var hrs = "0"+hh;
	}
	else
	{
		var hrs = hh;
	}
	return hrs+":"+mins;
}
function ConvertSeconds(totalSeconds) {
	// var days = Math.floor(num/86400);
	// var hours = Math.floor(num/3600)
	// var minutes = Math.floor(num/60);
	var minutes = Math.round((totalSeconds % 3600) / 60);
	var hours = Math.round((totalSeconds % 86400) / 3600);
	var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
	// let d = (new Date(t0)) - (new Date(t1));
	// let weekdays     = Math.floor(d/1000/60/60/24/7);
	// let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
	// let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
	// let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
	return days + " Day(s) " + hours + " Hrs " + minutes + " mins"
}
function ConvertHHMMSeconds(n) {
	// console.log(totalSeconds,"totalSeconds")
	// var seconds = Math.round((totalSeconds % 3600));
	// var minutes = Math.round((totalSeconds % 3600) / 60);
	// var hours = Math.round((totalSeconds % 86400) / 3600);
	// var days = Math.round((totalSeconds % (86400 * 30)) / 86400);

	var days = Math.round(parseInt(n / (24 * 3600)));

	n = n % (24 * 3600);
	var hours = Math.round(parseInt(n / 3600));

	n %= 3600;
	var minutes = Math.round(n / 60);

	n %= 60;
	var seconds = Math.round(n);

	return days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
}
function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}
$(document).ready(function () {
	$(document).on("click", ".dropdown-truck-no-map", function () {
		console.log("near by truck")
		var valsmap = $(this).text();
		$("#inputTruckSearch").val(valsmap);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTruckSearch");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})