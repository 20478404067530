import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

var redirectURL = require('../redirectURL');

var criticaldata=[]
export default class ChallansColumnChart extends Component {

    constructor(props) {
		super(props);
		this.state = {
		
		}

	}
	componentDidMount = async () =>{
		
		

	}
	componentWillReceiveProps (newprops){
       
	}
	
    render(){
    	//console.log("this.props.code) ", this.props.code)
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		  chart: {
					  type: 'column',
					  height:this.props.height
	      		  },
                  plotOptions: {
					pointWidth:"40px",
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        colorByPoint : true
					},
					series: {
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							format: '{point.y}'
						},
						cursor: 'pointer',
						point: {
							
						}
					}
				},
				
                title: {
					text: this.props.title,
					style:{
						fontSize: '16px'
					} 
                },
                subtitle: {
                    text: this.props.subtitle
				},
				
				xAxis: {
					categories: this.props.categories,
					title: {
						text: null
					},
					labels: {
					 enabled:true,
                    }

				},
				yAxis: {
					min: 0,
					title: {
						text: this.props.yaxistitle
					}
				},
				legend: {
					enabled: false
				},
				tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name} : </span><b>{point.title}</b><br/>'
                },
				series: this.props.series,
				colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
                  credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
					},
	      		};
        
        return ( 
			<div>
               <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptions} />
				
			</div>
			);
		}
}