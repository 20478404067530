
import React from 'react';
import {Badge, Row, Col } from "reactstrap";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import {
    getHyphenDDMMMYYYYHHMM,
} from '../common/utils';
const EbillWayInfo = (props) => {
    var modules = AllModules;
    var defaultColDef = {
        sortable: true,
        filter: true,
        editable: false,
    };
    
    var rowSelection= 'multiple';
    var rowModelhighchartoptionsType= 'enterprise';
    var rowGroupPanelShow= 'always';
    var statusBar= {
        statusPanels: [
            {
                statusPanel: "agTotalAndFilteredRowCountComponent",
                align: "left"
            },
            {
                statusPanel: "agTotalRowCountComponent",
                align: "center"
            },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
            { statusPanel: "agAggregationComponent" }
        ]
    };
    var paginationPageSize= 50;
    var sideBar= {
        toolPanels: [
            {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel"
            },
            {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel"
            }
        ]
    };
    var animateRows= true;
    var frameworkComponents= {
        
    };
    var ebillInfo = props.ebillInfo;
    var columnwithDefsForvehicle = [
        {
            headerName: "Vehicle No",
            field: "vehicle_number",
            colId: "vehicle_number",
            width: 110,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Updated On",
            field: "vehicle_number_update_date",
            colId: "vehicle_number_update_date",
            width: 160,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Transportation Mode",
            field: "transportation_mode",
            colId: "transportation_mode",
            width: 140,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Document Number",
            field: "transporter_document_number",
            colId: "transporter_document_number",
            width: 140,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Document Date",
            field: "transporter_document_date",
            colId: "transporter_document_date",
            width: 140,
            hide: false,
            cellRenderer: '', resizable: true
        },
    ];
    var VehiclListDetails = [];
    if(ebillInfo.VehiclListDetails !=undefined)
    {
        VehiclListDetails = ebillInfo.VehiclListDetails;
    }
    var columnwithDefsForitems = [
        {
            headerName: "Item No",
            field: "item_number",
            colId: "item_number",
            width: 100,
            hide: false,
            cellRenderer: '', resizable: true
        },
        // {
        //     headerName: "Product Id",
        //     field: "product_id",
        //     colId: "product_id",
        //     width: 80,
        //     hide: false,
        //     cellRenderer: '', resizable: true
        // },
        {
            headerName: "Name",
            field: "product_name",
            colId: "product_name",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Description",
            field: "product_description",
            colId: "product_description",
            width: 140,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "HSN Code",
            field: "hsn_code",
            colId: "hsn_code",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Quantity",
            field: "quantity",
            colId: "quantity",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Unit",
            field: "unit_of_product",
            colId: "unit_of_product",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "SGST",
            field: "sgst_rate",
            colId: "sgst_rate",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "CGST",
            field: "cgst_rate",
            colId: "cgst_rate",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "IGST",
            field: "igst_rate",
            colId: "igst_rate",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "CESS",
            field: "cess_rate",
            colId: "cess_rate",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "CESS Non Advol",
            field: "cessNonAdvol",
            colId: "cessNonAdvol",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
        {
            headerName: "Taxable Amount",
            field: "taxable_amount",
            colId: "taxable_amount",
            width: 120,
            hide: false,
            cellRenderer: '', resizable: true
        },
    ];
    var itemList = [];
    if(ebillInfo.itemList !=undefined)
    {
        itemList = ebillInfo.itemList;
    }
    return (
        <div className="row col-sm-12">
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Eway Bill No</label> : {ebillInfo.eway_bill_number}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Eway Bill Date</label> : {ebillInfo.eway_bill_date !=undefined ? getHyphenDDMMMYYYYHHMM(ebillInfo.eway_bill_date) : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Eway Bill Valid Date</label> : {ebillInfo.eway_bill_valid_date !=undefined ? getHyphenDDMMMYYYYHHMM(ebillInfo.eway_bill_valid_date) : ""}
            </div>
            {/* <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Status</label> : {ebillInfo.eway_bill_status !=undefined ? ebillInfo.eway_bill_status : ""}
            </div> */}
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Document date</label> : {ebillInfo.document_date !=undefined ? ebillInfo.document_date : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Document Type</label> : {ebillInfo.document_type !=undefined ? ebillInfo.document_type : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Document Number</label> : {ebillInfo.document_number !=undefined ? ebillInfo.document_number : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Supply Type</label> : {ebillInfo.supply_type !=undefined ? ebillInfo.supply_type : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Sub Supply Type</label> : {ebillInfo.sub_supply_type !=undefined ? ebillInfo.sub_supply_type : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Transaction Type</label> : {ebillInfo.transaction_type !=undefined ? ebillInfo.transaction_type : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Gstin Of Consignor</label> : {ebillInfo.gstin_of_consignor !=undefined ? ebillInfo.gstin_of_consignor : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Gstin Of Consignee</label> : {ebillInfo.gstin_of_consignee !=undefined ? ebillInfo.gstin_of_consignee : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>HSN Code</label> : {ebillInfo.hsn_code !=undefined ? ebillInfo.hsn_code : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>HSN Description</label> : {ebillInfo.hsn_description !=undefined ? ebillInfo.hsn_description : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignor Name</label> : {ebillInfo.legal_name_of_consignor !=undefined ? ebillInfo.legal_name_of_consignor : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignee Name</label> : {ebillInfo.legal_name_of_consignee !=undefined ? ebillInfo.legal_name_of_consignee : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignor State</label> : {ebillInfo.actual_from_state_name !=undefined ? ebillInfo.actual_from_state_name : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignee State</label> : {ebillInfo.actual_to_state_name !=undefined ? ebillInfo.actual_to_state_name : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignor Address1</label> : {ebillInfo.address1_of_consignor !=undefined ? ebillInfo.address1_of_consignor : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignee Address1</label> : {ebillInfo.address1_of_consignee !=undefined ? ebillInfo.address1_of_consignee : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignor Address2</label> : {ebillInfo.address2_of_consignor !=undefined ? ebillInfo.address2_of_consignor : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignee Address2</label> : {ebillInfo.address2_of_consignee !=undefined ? ebillInfo.address2_of_consignee : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignor Pincode</label> : {ebillInfo.pincode_of_consignor !=undefined ? ebillInfo.pincode_of_consignor : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignee Pincode</label> : {ebillInfo.pincode_of_consignee !=undefined ? ebillInfo.pincode_of_consignee : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignor Place</label> : {ebillInfo.place_of_consignor !=undefined ? ebillInfo.place_of_consignor : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Consignee Place</label> : {ebillInfo.place_of_consignee !=undefined ? ebillInfo.place_of_consignee : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Total Invoice Value</label> : {ebillInfo.total_invoice_value !=undefined ? ebillInfo.total_invoice_value : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Taxable Amount</label> : {ebillInfo.taxable_amount !=undefined ? ebillInfo.taxable_amount : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>SGST</label> : {ebillInfo.sgst_amount !=undefined ? ebillInfo.sgst_amount : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>CGST</label> : {ebillInfo.cgst_amount !=undefined ? ebillInfo.cgst_amount : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>IGST</label> : {ebillInfo.igst_amount !=undefined ? ebillInfo.igst_amount : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>CESS</label> : {ebillInfo.cess_amount !=undefined ? ebillInfo.cess_amount : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>CESS Non Advol</label> : {ebillInfo.cess_nonadvol_value !=undefined ? ebillInfo.cess_nonadvol_value : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Other Value</label> : {ebillInfo.other_value !=undefined ? ebillInfo.other_value : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Transporter Id</label> : {ebillInfo.transporter_id !=undefined ? ebillInfo.transporter_id : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Transporter Name</label> : {ebillInfo.transporter_name !=undefined ? ebillInfo.transporter_name : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Vehicle Type</label> : {ebillInfo.vehicle_type !=undefined ? ebillInfo.vehicle_type : ""}
            </div>
            <div className="col-sm-6" style={{marginBottom:"2%"}}>
                <label style={{fontWeight:"bold"}}>Transportation Distance</label> : {ebillInfo.transportation_distance !=undefined ? ebillInfo.transportation_distance : ""}
            </div>
            <div className='row col-sm-12'>
                <h6 style={{marginTop:"2%",fontWeight:"bold"}}>Item Details</h6>
                <div id="myGrid" style={{ width: "100%", height: "150px" }} className={"ag-theme-balham"}>
                    <AgGridReact
                        modules={modules}
                        columnDefs={columnwithDefsForitems}
                        defaultColDef={defaultColDef}
                        rowData={itemList}
                        enableCharts={false}
                        frameworkComponents={frameworkComponents}
                        // statusBar={statusBar}
                        // sideBar={sideBar}
                        paginationPageSize={paginationPageSize}
                        pagination={false}
                        // gridOptions={{
                        //     context: { componentParent: this }
                        // }}
                        // enableRangeSelection={true}
                    />
                </div>
            </div>
            <div className='row col-sm-12'>
                <h6 style={{marginTop:"2%",fontWeight:"bold"}}>Vehicle Details</h6>
                <div id="myGrid" style={{ width: "100%", height: "150px" }} className={"ag-theme-balham"}>
                    <AgGridReact
                        modules={modules}
                        columnDefs={columnwithDefsForvehicle}
                        defaultColDef={defaultColDef}
                        rowData={VehiclListDetails}
                        enableCharts={false}
                        frameworkComponents={frameworkComponents}
                        // statusBar={statusBar}
                        // sideBar={sideBar}
                        paginationPageSize={paginationPageSize}
                        pagination={false}
                        // gridOptions={{
                        //     context: { componentParent: this }
                        // }}
                        // enableRangeSelection={true}
                    />
                </div>
            </div>
        </div>
    )
};

export default EbillWayInfo;
