/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Consignmentforceclose from '../manage/consignmentforceclose';
//import Grid from '../layouts/gridnosidebarComponent';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault,
	getDDMMYYYYHHMM,
	getHyphenDDMMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ExportPDF from "./exportpdf"
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import EditActions from '../manage/EditActionsComponent';
import TptMSILTripsCounters from './tptmsiltripscounters';
import reorder from 'material-ui/svg-icons/action/reorder';
import ForceCloseSideBar from "../layouts/forceclosuresidebarcomponent";
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;
var containerslist = [];
export default class TPTHistoricTrips extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode: "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderCommentTranslate: "",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			sliderSegmentTranslate: "",
			mapinfo: '',
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			googelRoutes: '',
			plants: [],
			consgmntdata: [],
			transitdata: [],
			gpsntavadata: [],
			overspeed: [],
			nightdriving: [],
			loadingdelay: [],
			stoppages: [],
			critical: [],
			probaccdnt: [],
			counters_data: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				editActions: EditActions,
				consignmentforceclose: Consignmentforceclose
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: { "value": 'all', "label": 'All' },
			tripvalue: '',
			consigner: [{ "value": 'all', "label": 'All' }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Historic Trips',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			segementConsignmentCode: "",
			counter_screen: "",
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			defaultsdate: '',
			defaultedate: '',
			movementtype: { "value": "all", "label": "All" },
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			showumncr: 0,
			showummove: 0,
			usermanualncrmodal: false,
			usermanualmovemodal: false,
			slideuploadeway: "",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			transporterTrucks: [],
			tpttrucks: [],
			adaniCheck: 0,
			addressColumn: 300,
			pdfRowData: [],
			pdfColumns: [],
			msilCounters: {
				"transit_delay": [],
				"nightdriving_exception": [],
				"no_gps_data": [],
				"overspeeding_exception": [],
				"loading_delay_exception": [],
				"enroute_exception": [],
				"is_critical": [],
				"probable_accident": []
			},
			oems: [],
			oem: { "value": "", "label": "OEM" },
			dealers: [],
			plantlocations: [],
			dealer: { "value": "", label: "Dealer" },
			city: { "value": "", label: "City" },
			transittype: { "value": "Road", "label": "Road" },
			transit_distance: "",
			transit_time: "",
			truck_no: "",
			trip_no: "",
			vin_no: "",
			invoice_date: "",
			gate_in_time: "",
			gate_out_time: "",
			selected_eway_bill_date: "",
			quantity: "",
			fleet_amount: "",
			distance_in_km: "",
			expected_eta: "",
			drivers: [],
			driver: { "value": "", "label": "Driver" },
			sliderRso: "",
			tcode: "",
			cities: [],
			filteredDataTrip: [],
			alltrucks: [],
			showTrucksListTrip: "show-n",
			localtransporter: [],
			selectedRowNode: "",
			sliderForceCloseTranslate: "",
			selectedInfo: "",
			searchFor: "",
			selectedTruck: "",
			truck_no_options:[],
			trip_no_options:[],
			selectedOption:"",
			optns:[]
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowEditAction = this.onShowEditAction.bind(this);
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}

	componentDidMount() {
		console.log(this.props,"this.props----MapView-Componentdid")
		if(this.props.pageLoad == "9")
		{
			this.onLoadPageData();
		}
		var params = {
			transporter_code: localStorage.getItem("transportercode")
		}
		redirectURL.post('/dashboard/gettrucknoandtripno',params)
		.then((response) => {

			var truck_numbers = response.data.trucknumbers;
			var truck_number_data = []
			truck_numbers.map((e) =>{
				console.log(e,"truck data")
				truck_number_data.push({"label":e,"value":e})
			})
			var trip_numbers = response.data.tripnumbers;
			var trip_number_data = []
			trip_numbers.map((e) =>{
				console.log(e,"trip data")
				trip_number_data.push({"label":e,"value":e})
			})
			  console.log("truck_numbers ", truck_number_data)
			  console.log("trip_numbers ", trip_number_data)

			this.setState({
				truck_no_options: truck_number_data,
				trip_no_options:trip_number_data

			});
		})
		console.log(this.state.truck_no_options,"truck_no_options1")
		console.log(this.state.trip_no_options,"trip_no_options1")
	}

	componentDidMount() {
		// console.log(newprops,"this.props----MapView-Componentwill")
		// if(newprops.pageLoad == "9" && this.state.rowData.length == 0)
		// {
			this.onLoadPageData();
		// }
	}

	onLoadPageData = async () => {
			loadDateTimeScript();
			// var edate = moment.parseZone().format('DD-MM-YYYY');
			// var sdate = moment.parseZone().subtract(1, 'days').format('DD-MM-YYYY')
			// $("#invoiceStartDate").val(sdate);
			// $("#invoiceEndDate").val(edate);
			var parameters = {
				transporter_code: localStorage.getItem("transportercode")
			}
			redirectURL.post('/dashboard/tptdrivers', parameters)
				.then((response) => {
					var records = response.data.records;
					//   console.log("records ", records)
					this.setState({
						drivers: records
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			redirectURL.post('/dashboard/oemmasterdata')
				.then((response) => {
					var records = response.data.records;
					//   console.log("records ", records)
					this.setState({
						oems: records
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			var tptCode = localStorage.getItem('transportercode');
			if (tptCode.includes("T293") == true) {
				var adaniCheck = 1;
			}
			else {
				var adaniCheck = 0;
			}
			this.setState({
				adaniCheck: adaniCheck
			});
			var vehicle_group = localStorage.getItem("vehicle_group");
			var tptrucks = []
			if (vehicle_group != "") {
				var tparms = {
					transporter_code: tptCode,
					region: vehicle_group
				}
				redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
					.then((response) => {
						var records = response.data.records;
						// console.log("records ", records)
						this.setState({
							tpttrucks: records
						})
					})
			}

			var s_date = moment.parseZone().subtract(1, 'month').format('DD-MM-YYYY');
			var e_date =  moment.parseZone().format('DD-MM-YYYY')
			$("#invoiceStartDate").val(s_date)
			$("#invoiceEndDate").val(e_date)

			var edate = moment.parseZone().format('YYYY-MM-DD');
			var sdate = moment.parseZone().subtract(1, 'month').format('YYYY-MM-DD');
			
			var edatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
			var sdatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
			this.logPageView(); // Log page view to GA
			let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
			this.setState({ eventAction: eventAction });
			//console.log("Props ", this.props.match)
			this.setState({
				loadshow: 'show-m',
				startDate: sdate,
				endDate: edate,
				defaultsdate: sdate,
				defaultedate: edate,
				startDatetime: sdatetime,
				endDatetime: edatetime
			});
			var urlpath;
			var dpt;
			var isCounterDisplay = 1;
			var screenpage = '';
			var title = '';

			var consignData = {}
			var tptcode = localStorage.getItem("transportercode");
			if (localStorage.getItem("transportercode") != undefined) {
				var transporter = JSON.parse(localStorage.getItem("transportercode"))
			}
			else {
				var transporter = "";
			}
			if (Array.isArray(transporter)) {
				var transporter_code = transporter[0];
				var transporterss = JSON.stringify(transporter);
			}
			else {
				var transporter_code = transporter[0]
				var transporterss = JSON.stringify([transporter]);
			}
			this.setState({
				tcode: transporter_code,
				localtransporter: transporterss
			})

			urlpath = '/consignments/gethistorictripsdata';
			dpt = 'SNDG';
			title = 'Active Consignments';
			var consignData = {
				tpt_code: tptcode,
				transporter_code: transporterss,
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner
			}
			this.setState({
				startDate: "",
				endDate: "",
				counter_screen: "active"
			});


			redirectURL.post("/consignments/plants", { dept_code: dpt })
				.then((resp) => {
					this.setState({
						plants: resp.data
					});
				})


			redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
				.then(async (resp) => {
					//console.log("resp ", resp)
					await this.setState({
						usergridstate: resp.data
					});
					await this.restoreGridStates();
				})

			this.onLoadGetConsignmentsList(urlpath, consignData);
			this.getUserGridState();
	};
	getUserGridState() {
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				// console.log("resp ", resp)
				this.setState({
					usergridstate: resp.data
				});
				this.restoreGridStates();
			})
	}


	onLoadGetConsignmentsList(urlpath, parameters) {
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		console.log(parameters , "paramenter_1729");
		console.log("urlpath_1729", urlpath)
		redirectURL.post(urlpath, parameters)
			.then(async (response) => {
				var records = response.data.consignments;
				console.log(records, "Supereme");
				var transporterTrucks = [];
				var deviceparams = {
					transporter_code: localStorage.getItem("transportercode")
				}
				await redirectURL.post("/dashboard/devices", deviceparams).then((response1) => {
					var tptsCode = localStorage.getItem("transportercode");
					response1.data.devices.map(function (e) {

						if (tptsCode.indexOf(e.transporter_code) > -1) {
							transporterTrucks.push(e)
						}
					})

				});
				console.log("transporterTrucks ", transporterTrucks)
				records = await records.sort(GetSortDescOrder("_id"));
				var msilCounters = {};
				
				var uqTrckconsignments = [];
				if (records.length > 0) {
					await records.filter(f =>
						transporterTrucks.filter((d) => {
							if (d.truck_no == f.truck_no) {
								// console.log("API ", d)
								if (d.registereddevices != undefined) {
									if (d.registereddevices.length > 0) {
										f.device_address = d.registereddevices[0].address
										f.device_city = d.registereddevices[0].city
										f.device_state = d.registereddevices[0].state
										f.last_active_on = d.registereddevices[0].last_active_on
									}
								}
								uqTrckconsignments.push(f);
							}
						})
					);

				}
				console.log(uqTrckconsignments, "uqTrckconsignments")
				var tpttrucks = this.state.tpttrucks;
				var tptunqrecords = []
				if (tpttrucks.length > 0) {
					await uqTrckconsignments.filter(f =>
						tpttrucks.filter((d) => {
							if (d.truck_no == f.truck_no) {
								tptunqrecords.push(f)
							}
						})
					);
				}
				else {
					tptunqrecords = uqTrckconsignments
				}

				var countersdata = []
				var recordslist = tptunqrecords;
				console.log("historicTripsrecordslist ", recordslist)
				if (recordslist.length > 0) {
					recordslist.map((item) => {
						console.log(item.status, "status")
						if (item.status == 2 || item.status == "2" || item.status == "In Transit") {

						}
						else {
							countersdata.push(item);
						}
					})
				}
				console.log("cntrdata ", countersdata)
				var transitDelayData = countersdata.filter(e => (e.transit_delay == "1"))
				msilCounters['transit_delay'] = transitDelayData;
				var noGpsData = countersdata.filter(e => e.no_gps_data == "1")
				msilCounters['no_gps_data'] = noGpsData;
				var overSpeeding = countersdata.filter(e => e.overspeeding_exception == "1")
				msilCounters['overspeeding_exception'] = overSpeeding;
				var nightDriving = countersdata.filter(e => e.nimghtdriving_exception == "1")
				msilCounters['nightdriving_exception'] = nightDriving;
				var loadingDelay = countersdata.filter(e => e.loading_delay_exception == "1")
				msilCounters['loading_delay_exception'] = loadingDelay;
				var stoppages = countersdata.filter(e => e.enroute_exception == "1")
				msilCounters['enroute_exception'] = stoppages;
				var isCritical = countersdata.filter(e => e.is_critical == "1")
				msilCounters['is_critical'] = isCritical;
				var probableAccident = countersdata.filter(e => e.probable_accident == "1")
				msilCounters['probable_accident'] = probableAccident;


				
				// console.log(tptunqrecords, "tptunqrecords")
				var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
				var pdfRowData = [];
				tptunqrecords.map(function (t) {
					var invoiceTime = "NA";
					if (t.invoice_time === "") {
						invoiceTime = "NA"
					} else {
						invoiceTime = getHyphenDDMMMYYYYHHMM(t.invoice_time)
					}
					pdfRowData.push({
						name: t.truck_no,
						consignment_code: (t.consignment_code),
						status: t.status,
						expected_trip_end: getHyphenDDMMMYYYYHHMM(t.expected_trip_end),
						revised_trip_end: getHyphenDDMMMYYYYHHMM(t.revised_trip_end),
						device_address: t.device_address, field: "invoice_time",
						device_city: t.device_city,
						device_state: t.device_state,
						last_active_on: getHyphenDDMMMYYYYHHMM(t.last_active_on),
						invoice_time: invoiceTime,
						//transporter_name:t.transporter_name,
						consigner_code: t.consigner_code,
						consignee_code: t.consignee_code,
						consignee_name: t.consignee_name,
						consignee_city: t.consignee_city,
						distance_from_dealer_location: t.distance_from_dealer_location,
						aprox_dist_travelled_from_start: t.aprox_dist_travelled_from_start,
						gate_in_time: getHyphenDDMMMYYYYHHMM(t.gate_in_time),
						gps_gate_in_time: getHyphenDDMMMYYYYHHMM(t.inside_fence),
						gate_out_time: getHyphenDDMMMYYYYHHMM(t.gate_out_time),
						gps_gate_out_time: getHyphenDDMMMYYYYHHMM(t.outside_fence),
						is_transshipment: t.is_transshipment,
						quantity: t.quantity,
						fleet_amount: t.fleet_amount,
						expected_eta: t.expected_eta,
						distance_travelled_in_24_hrs_kms: t.distance_travelled_in_24_hrs_kms,
						old_truck_no: t.old_truck_no,
						transshipment_date: t.transshipment_date,
						tpt_confirm_delivery: t.tpt_confirm_delivery,
						tpt_delivery_date: t.tpt_delivery_date,
						tpt_delivery_reason: t.tpt_delivery_reason,
						transit_time: t.transit_time,
						distance_in_km: t.distance_in_km,
						cluster: t.cluster,
						spoc_contact_name: t.spoc_contact_name,
						spoc_contact_number: t.spoc_contact_number,
						vehicle_mode: t.vehicle_mode,
						dealer_name : t.dealer_name
					});
				});

				var pdfColumns = [
					{ header: name, binding: 'name' },
					{ header: 'Consignment Code', binding: 'consignment_code' },
					{ header: 'Status', binding: 'status' },
					{ header: 'Original ETA', binding: 'expected_trip_end' },
					{ header: 'Revised ETA', binding: 'revised_trip_end' },
					{ header: 'Transit Delay', binding: 'totaldays' },
					{header : 'Geofence' , binding : 'dealer_name'},
					{ header: 'Address', binding: 'device_address' },
					{ header: 'Last City/Town', binding: 'device_city' },
					{ header: 'Last State', binding: 'device_state' },
					{ header: 'Last Packet Time', binding: 'last_active_on' },
					{ header: 'Invoice Date', binding: 'invoice_time' },
					//{ header: 'Transporter Name', binding: 'transporter_name'},
					{ header: 'Consigner Code', binding: 'consigner_code' },
					{ header: 'Consignee Code', binding: 'consignee_code' },
					{ header: 'Consignee Name', binding: 'consignee_name' },
					{ header: 'Consignee City', binding: 'consignee_city' },
					{ header: 'Dist. From Destination (km)', binding: 'distance_from_dealer_location' },
					{ header: 'Total Trip Distance (Km)', binding: 'aprox_dist_travelled_from_start' },
					{ header: 'Gate in Time', binding: 'gate_in_time' },
					{ header: 'Gps Gate in Time', binding: 'inside_fence' },
					{ header: 'Gate out Time', binding: 'gate_out_time' },
					{ header: 'Gps Gate Out Time', binding: 'outside_fence' },
					{ header: "Transshipment", binding: "is_transshipment" },
					{ header: "Quantity", binding: "quantity" },
					{ header: "Freight Amount", binding: "fleet_amount" },
					{ header: "Expected ETA", binding: "expected_eta" },
					{ header: "Distance Traveled last 24Hr(KM)", binding: "distance_travelled_in_24_hrs_kms" },
					{ header: "Old Truck No", binding: "old_truck_no" },
					{ header: "Transshipment Date", binding: "transshipment_date" },
					{ header: "TPT Delivered", binding: "tpt_confirm_delivery" },
					{ header: "TPT Delivered On", binding: "tpt_delivery_date" },
					{ header: "TPT Delivered Reason", binding: "tpt_delivery_reason" },
					{ header: "Transit Time(Days)", binding: "transit_time" },
					{ header: "Expected Distance (KM)", binding: "distance_in_km" },
					{ header: "Cluster", binding: "cluster" },
					{ header: "SPOC Contact Name", binding: "spoc_contact_name" },
					{ header: "SPOC Contact Number", binding: "spoc_contact_number" },
					{ header: "Vehicle Mode", binding: "vehicle_mode" },


				]
				// console.log("records tptunqrecords ",tptunqrecords)
				var tptcode = localStorage.getItem("transportercode");
				var params = { dept_code: 'SNDG', transporter_codes: tptcode }
				// redirectURL.post('/consignments/getMSILTripsCountersData',params).then((response2)=>{
				// 	// console.log(response2.data,"conterdata");
				// 	var msilCounters = {};
				// 	var recordslist = response2.data.data;
				// 	// console.log("recordslist ", recordslist)
				// 	var countersdata = []
				// 	if(recordslist.length > 0)
				// 	{
				// 		recordslist.map((item) => {
				// 			if(item.status == 2 || item.status == "2" || item.status == "In Transit")
				// 			{
				// 				countersdata.push(item);
				// 			}
				// 		})
				// 	}
				// 	// console.log("data ", countersdata)
				// 	var transitDelayData = countersdata.filter(e => (e.transit_delay == "1"))
				// 	msilCounters['transit_delay'] = transitDelayData;
				// 	var noGpsData = countersdata.filter(e => e.no_gps_data == "1")
				// 	msilCounters['no_gps_data'] = noGpsData;
				// 	var overSpeeding = countersdata.filter(e => e.overspeeding_exception == "1")
				// 	msilCounters['overspeeding_exception'] = overSpeeding;
				// 	var nightDriving = countersdata.filter(e => e.nimghtdriving_exception == "1")
				// 	msilCounters['nightdriving_exception'] = nightDriving;
				// 	var loadingDelay = countersdata.filter(e => e.loading_delay_exception == "1")
				// 	msilCounters['loading_delay_exception'] = loadingDelay;
				// 	var stoppages = countersdata.filter(e => e.enroute_exception == "1")
				// 	msilCounters['enroute_exception'] = stoppages;
				// 	var isCritical = countersdata.filter(e => e.is_critical == "1")
				// 	msilCounters['is_critical'] = isCritical;
				// 	var probableAccident = countersdata.filter(e =>e.probable_accident == "1")
				// 	msilCounters['probable_accident'] = probableAccident;
				// 	this.setState({counters_data: msilCounters,
				// 	rowData:countersdata,
				// 	allRowData:countersdata,
				// 	originalData:countersdata
				// 	})

				// })
				// .catch(function(error){
				// 	console.log(error,"countererror");
				// })
				await this.setState({
					pdfRowData,
					pdfColumns,
					rowData: countersdata,
					allRowData: countersdata,
					originalData: countersdata,
					counters_data: msilCounters,
					loadshow: 'show-n',
					overly: 'show-n'
				});


			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow: 'show-m'
		});
		var reqData = {
			consignment_code: e.consignment_code
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("records", records);

				this.setState({
					uploadDivWidth: '30%',
					sliderTranslate: "slider-translate-50p",
					showDiv: 'show-m',
					timelinedata: records,
					loadshow: 'show-n',
					overly: 'show-m'
				});
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}

	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}



	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		if (this.state.deptcode == 'LOG-PRT') {
			console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception", "loading_delay_exception", "unloading_delay_exception", "trip_type"], false);

			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'LOG-TNP') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], true);
		}

		else { }



	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}


	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			slideuploadeway: '',
			sliderRouteTranslate: '',
			sliderSegmentTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderCommentTranslate: "",
			sliderRso: "",
			selectedRowNode: ""
		});

	}

	showGoogleRouteAlert = () => {
		this.setState({
			show: true,
			basicTitle: "No google routes found",
			basicType: "danger"
		});
	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	onClickCounterShowDataTEST(params) {
		// console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
	}


	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			//   () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			// () => console.log(`Movement Option selected:`, this.state.movementtype)
		);

	}
	selectConsignerOptionsItems() {
		let items = [];
		//console.log(this.state.trucks);
		var consigners = [];
		if (this.state.deptcode == 'SNDG') {
			var keys = ["B", "F", "G", "I", "M", "N"];

			// An array of values 
			var values = ["TVP Bangalore", "Gurgaon", "Gujarat", "Siliguri", "Manesar", "TVP Nagpur"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}


		if (this.state.deptcode == 'LOG-PRT') {
			var keys = ["MSILDM", "MSILG", "MSILM", "RPDC-BAN", "RPDC-GUJ", "RPDC-NAG", "RPDC-SIL"];

			// An array of values 
			var values = ["MSIL Domestic", "Gurgaon", "Manesar", "RPDC Bangalore", "RPDC Gujarat", "RPDC Nagpur", "RPDC Siliguri"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}


		if (this.state.deptcode == 'LOG-TNP') {
			var keys = ["MSIL-G", "MSIL-M", "MSILMC", "MSILME", "SMG"];

			// An array of values 
			var values = ["Gurgaon", "Manesar", "Casting", "Power Train", "SMG Gujarat"];

			// Object created 
			var obj = {};

			// Using loop to insert key 
			// value in Object 
			for (var i = 0; i < keys.length; i++) {
				consigners.push({ "code": keys[i], "value": values[i] });
			}
		}

		//console.log("consigners ", consigners)
		items.push({ value: 'all', label: 'All' });
		consigners.forEach(item => {
			items.push({ value: item.code, label: item.value });
		});

		return items;
	}

	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		items.push({ value: true, label: "Local Movement" });
		items.push({ value: false, label: "Non Local Movement" });
		return items;
	}


	getTruckConsignments = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		var selectedFilter = $("#selectedFilter").text();
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		if (selectedFilter == "Select..." || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true
			})
		}
		else {
			// console.log(selectedFilter,inputTruckConsignment);
			var urlpath = ""
			if (this.props.match.path == "/sndconsignments" ||
				this.props.match.path == "/prtconsignments" ||
				this.props.match.path == "/tnpconsignments") {
				urlpath = "/consignments/filterByConsignment";
			}
			if (this.props.match.path == "/snddeliveredconsignments" ||
				this.props.match.path == "/prtdeliveredconsignments" ||
				this.props.match.path == "/tnpdeliveredconsignments") {
				urlpath = "/consignments/filterDeliveredByConsignment";
			}
			if (this.props.match.path == "/ncrconsignments") {
				urlpath = "/consignments/filterNCRByConsignment"
			}
			if (this.props.match.path == "/sndallconsignments" ||
				this.props.match.path == "/prtallconsignments" ||
				this.props.match.path == "/tnpallconsignments") {
				urlpath = "/consignments/filterAllByConsignment";
			}
			if (this.props.match.path == "/insidemsilvicinty") {
				urlpath = "/consignments/filterInsideMSILByConsignment";
			}
			redirectURL.post(urlpath,
				{
					"selectedFilter": selectedFilter,
					"inputTruckConsignment": inputTruckConsignment,
					"dept_code": this.state.deptcode
				})
				.then((response) => {
					// console.log("")
					this.setState({
						rowData: response.data.consignments,
						allRowData: response.data.consignments,
						countersjson: response.data.counters,
						loadshow: "show-n",
						overly: "show-n"
					})
				}).catch = ((e) => {
					console.log(e);
				})
		}

	}
	onClickTripButton = async () => {

		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		var selectconsigners = []
		if (this.state.consigner == null) {
			selectconsigners = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsigners = this.state.consigner
		}

		var selecttrip = []
		if (this.state.triptype == null) {
			selecttrip = { "value": 'all', "label": 'All' }
		}
		else {
			selecttrip = this.state.triptype
		}

		var requestparams = {
			consigner: selectconsigners,
			dept_code: this.state.deptcode,
			startDate: sDate,
			endDate: eDate,
			triptype: selecttrip
		}

		var urlpath = '';
		if (this.props.match.path == "/tnpconsignments") {
			urlpath = '/consignments/filterTNPActiveConsignments';
		}

		else if (this.props.match.path == "/tnpallconsignments") {
			urlpath = '/consignments/alltnpconsignments';
		}

		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			urlpath = '/consignments/deliveredtnpconsignments';
		}

		this.onLoadGetConsignmentsList(urlpath, requestparams)
	}


	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			startDate: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		this.setState({
			endDate: edate
		});
	}
	onRowSelection(event) {
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		// console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
		//var containerlist=[];
		// console.log("rowselect", event.data)
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})
		//  console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }

	}
	onClickShowMapView = async () => {
		//this.gridApi.deselectAll();
		if (this.state.containerslist.length > 0) {
			var reqparms = {
				truck_no: this.state.containerslist,
				dept_code: this.state.deptcode
			}
		}
		else {
			var reqparms = {
				truck_no: [],
				dept_code: this.state.deptcode
			}
		}

		await redirectURL.post("/consignments/mapview", reqparms)
			.then((response) => {
				//console.log("Res ",response.data.message)
				var records = response.data.message;

				var truck = JSON.parse(records.truck_info);
				if (truck.length > 0) {
					if (truck.length > 1) {
						var consinees = this.state.plants;
					}
					else {
						var consinees = records.consignee_coordinates;
					}
				}
				else {
					var consinees = [];
				}
				//  console.log("truckinfo,",truck)
				//console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
				this.setState({
					open: true,
					maprowData: truck,
					rownode: truck,
					maptruckno: records.truck_no,
					consigneecoords: consinees,
					consigner_coordinates: records.consigner_coordinates
				});


				//containerslist=[];
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};
	changeSegmentHandler = (e) => {
		//  alert(e.consignment_code);
		this.setState({
			sliderSegmentTranslate: "slider-translate-60p",
			segementConsignmentCode: e.consignment_code,
			overly: "show-m"
		})
	}

	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	};

	onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ openforceclose: false });
	};



	handlerReachedDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			reached_dealer_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerLeftDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			left_dealer_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerInvoiceTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			invoice_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	onClickForceClose = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			forceclosedata: params.data,
			forceCloseRowNode: params,
			sliderForceCloseTranslate: "slider-translate-40p",
			overly: 'show-m',
			reasonforceclose: ""
		})
	}
	handleInput(val) {
		this.setState({ reasonforceclose: val[1], selectedReason: val[0] });
		// console.log(val)
	}
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	formForceCloseHandler(event) {
		event.preventDefault()

		//  if(this.state.deptcode == 'SNDG')
		//  {
		// 	 var invoice_time = moment.parseZone(this.state.invoice_time).format("YYYY-MM-DD")+" "+this.state.invoicehh+":"+this.state.invoicemm+":"+this.state.invoicess;
		//  }
		//  else{
		// 	var invoice_time=""
		//  }
		var consignemnt = this.state.forceCloseRowNode.data;
		var consignment_code = consignemnt.trip_no;
		var consignee_code = consignemnt.consignee_code;

		var truck_no = consignemnt.truck_no;
		var dept_code = this.state.deptcode;
		var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var userid = localStorage.getItem('userid');
		//  var reasonforceclose = this.state.reasonforceclose;
		// var others_Reason = this.state.selectedReason;
		//  var reached_dealer_time =  moment.parseZone(new Date(this.state.reached_dealer_time)).format("YYYY-MM-DD")+" "+this.state.reachedhh+":"+this.state.reachedmm+":"+this.state.reachedss;
		//  var left_dealer = moment.parseZone(new Date(this.state.left_dealer_time)).format("YYYY-MM-DD")+" "+this.state.lefthh+":"+this.state.leftmm+":"+this.state.leftss;

		var tpt_deliver_date = document.getElementById("tpt_deliver_date").value;
		if (tpt_deliver_date != '') {
			var rtime = tpt_deliver_date.split(" ");
			var splitrtime = rtime[0].split("-");
			if (splitrtime[0].length == 4) {
				var reachdealertime = splitrtime[0] + "-" + splitrtime[1] + "-" + splitrtime[2] + " " + rtime[1];

			}
			else {
				var reachdealertime = splitrtime[2] + "-" + splitrtime[1] + "-" + splitrtime[0] + " " + rtime[1];

			}
			//console.log("reached_dealer_time ", reachdealertime)
		}
		else {
			var reachdealertime = "";
		}
		// 	var left_dealer = document.getElementById("left_dealer_time").value;  
		// 	if(left_dealer != '')
		// 	{
		// 		var ltime = left_dealer.split(" ");
		// 		var splitltime = ltime[0].split("-");
		// 		var leftdealertime = splitltime[2]+"-"+splitltime[1]+"-"+splitltime[0]+" "+ltime[1]+":00";
		// 		//console.log("leftdealertime ", leftdealertime)
		// 	}   
		// 	else
		// 	{
		// 		var leftdealertime=""
		// 	}    

		if (this.state.deptcode == "SNDG") {
			//console.log(consignemnt.invoice_time);
			var invoice_time = getHyphenYYYYMMDDHHMMSS(consignemnt.invoice_time);
			var reqdata = {
				dept_code: dept_code,
				consignment_code: consignment_code,
				//consignee_code:consignee_code,
				truck_no: truck_no,
				//force_closure_time:force_closure_time,
				userid: userid,
				tpt_deliver_date: reachdealertime,
				// reasonforceclose:reasonforceclose,
				// others_Reason : others_Reason,
				screen: ""
				// reached_dealer_time:reachdealertime,
				//invoice_time:invoice_time,
				// left_dealer:leftdealertime
			}
		}
		else {
			var reqdata = {
				dept_code: dept_code,
				consignment_code: consignment_code,
				//consignee_code:"",
				truck_no: truck_no,
				//force_closure_time:force_closure_time,
				userid: userid,
				screen: "",
				// reasonforceclose:reasonforceclose,
				// others_Reason : others_Reason,
				tpt_deliver_date: reachdealertime,
				//invoice_time:"",
				// left_dealer:leftdealertime
			}
		}
		//  var flag = 0;
		//  if(others_Reason == "" && reasonforceclose == "Others"){
		// 	 flag = 1;
		//  }
		//  if(flag == 0 && reasonforceclose == "Others")
		//  {
		//   //    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
		// 	 if(others_Reason.replace(/\s/g,"").length == 0)
		// 	 {
		// 	  flag = 1;
		// 	 }
		//  }
		// console.log("reqdata ",reqdata)
		if (reachdealertime != "" && reachdealertime != "__-__-____ __:__") {
			redirectURL.post("/consignments/tripclose", reqdata)
				.then((response) => {
					//console.log("Resposne updateConsignmentForceClose ", response)
					// console.log(response);
					if (response.data.message == 'Success') {
						// document.getElementById("reached_dealer_time").value='';
						// document.getElementById("left_dealer_time").value=''; 
						this.setState({
							show: true,
							basicTitle: "Success",
							basicType: "success",
							loadshow: 'show-n',
							overly: 'show-n',
							left_dealer_time: '',
							reached_dealer_time: '',
							reachedhh: '',
							reachedmm: '',
							reachedss: '',
							lefthh: '',
							leftmm: '',
							leftss: '',
							reasonforceclose: "",
							openforceclose: false,
							sliderForceCloseTranslate: '',
							sliderTranslatesidebar: "",
						});
						document.getElementById("tpt_deliver_date").value = "";
						window.location.reload();
					}
					else {
						this.setState({
							show: true,
							basicTitle: "Error",
							basicType: "danger",
							loadshow: 'show-n',
							overly: 'show-n',
							left_dealer_time: '',
							sliderTranslatesidebar: "",
							reached_dealer_time: '',
							reachedhh: '',
							reachedmm: '',
							reachedss: '',
							lefthh: '',
							leftmm: '',
							leftss: '',
							reasonforceclose: '',
							openforceclose: false,
							sliderForceCloseTranslate: ''
						});
					}

				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		else {
			this.setState({
				show: true,
				basicTitle: 'Reason for closue should not be empty',
				basicType: "danger",
			});
		}
		event.target.reset();
	}

	// onClickSaveGridState(){
	// 	window.colState = this.gridColumnApi.getColumnState();
	// 	window.groupState = this.gridColumnApi.getColumnGroupState();
	// 	window.sortState = this.gridApi.getSortModel();
	// 	window.filterState = this.gridApi.getFilterModel();
	// 	console.log("Columns ", window.colState)
	// 	console.log("Group ", window.groupState)
	// 	console.log("Sort ", window.sortState)
	// 	console.log("Filter ", window.filterState)
	// 	let screenpage = '';

	// 	if(this.props.match.path == "/sndconsignments")
	// 	{
	// 		screenpage='snd active consignments';
	// 	}
	// 	else if(this.props.match.path == "/prtconsignments")
	// 	{
	// 		screenpage='prt active consignments';
	// 	}
	// 	else if(this.props.match.path == "/tnpconsignments")
	// 	{
	// 		screenpage='tnp active consignments';
	// 	}
	// 	else if(this.props.match.path == "/sndallconsignments")
	// 	{
	// 		screenpage='snd all consignments';

	// 	}
	// 	else if(this.props.match.path == "/prtallconsignments")
	// 	{
	// 		screenpage='prt all consignments';
	// 	}
	// 	else if(this.props.match.path == "/tnpallconsignments")
	// 	{
	// 		screenpage='tnp all consignments';
	// 	}
	// 	else if(this.props.match.path == "/snddeliveredconsignments")
	// 	{
	// 		screenpage='snd delivered consignments';
	// 	}
	// 	else if(this.props.match.path == "/prtdeliveredconsignments")
	// 	{
	// 		screenpage='prt delivered consignments';
	// 	}
	// 	else if(this.props.match.path == "/tnpdeliveredconsignments")
	// 	{
	// 		screenpage='tnp delivered consignments';
	// 	}
	// 	else if(this.props.match.path == "/railconsignments")
	// 	{
	// 		screenpage='railconsignments';
	// 	}
	// 	else if(this.props.match.path == "/ncrconsignments")
	// 	{
	// 		screenpage='ncr consignments';
	// 	}
	// 	else if(this.props.match.path == "/moveconsignments")
	// 	{
	// 		screenpage='move consignments';
	// 	}

	// 	let reqparams = {
	// 		gridcolumns:window.colState,
	// 		gridgroup:window.groupState,
	// 		gridcolsort:window.sortState,
	// 		gridcolfilter:window.filterState,
	// 		userId:localStorage.getItem("userid"),
	// 		screenurl:window.location.pathname,
	// 		screentitle:screenpage
	// 	}
	// 	//console.log("reqparams ", reqparams)
	// 	redirectURL.post("/consignments/saveGridStates", reqparams)
	// 	.then((response) => {
	// 		//console.log("State response ",response.data)
	// 		this.setState({
	// 			show:true,
	// 			basicTitle:"Successfully saved grid layout",
	// 			basicType:"success",
	// 			screenurl:window.location.pathname,
	// 			screentitle:screenpage
	// 		})
	// 	})
	// 	.catch(function(e){
	// 		console.log("Error ", e)
	// 	})
	// }

	// restoreGridStates()
	// {

	// 	if(this.state.usergridstate.length > 0)
	// 	{
	// 		var windowstates = this.state.usergridstate;
	// 		this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
	// 		this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
	// 		this.gridApi.setSortModel(windowstates[0].gridcolsort);
	// 		this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

	// 	}
	// }

	// resetState = () => {
	// 	this.gridColumnApi.resetColumnState();
	// 	this.gridColumnApi.resetColumnGroupState();
	// 	this.gridApi.setSortModel(null);
	// 	this.gridApi.setFilterModel(null);
	// 	//console.log('column state reset');
	// 	var reqparams = {
	// 		userId:localStorage.getItem("userid"),
	// 		screenurl:window.location.pathname,
	// 		screentitle:this.state.screenpage
	// 	}

	// 	redirectURL.post("/consignments/removeUserGridState", reqparams)
	// 	.then((response) => {
	// 		this.setState({
	// 			show:true,
	// 			basicTitle:"Successfully reset default grid layout",
	// 			basicType:"success"
	// 		})
	// 	})

	// };



	onCloseUserManualModal = () => {
		this.setState({ usermanualncrmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualncrmodal: true });
	};

	onCloseUserManualMoveModal = () => {
		this.setState({ usermanualmovemodal: false });
	};

	onClickShowUserMoveManaul = () => {
		this.setState({ usermanualmovemodal: true });
	};

	onShowUploadEWay() {
		this.setState({
			slideuploadeway: "slider-translate",
			overly: 'show-m'
		})
	}


	resetUpload = () => {
		this.setState({
			overly: 'show-n',
			file: ''
		});
	}

	onClickCounterShowData(val) {
		// console.log("Val ", val)
		if (val == 0) {
			this.gridApi.setRowData(this.state.allRowData)
		}
		else {
			// console.log(this.state.counters_data, "msil")
			this.gridApi.setRowData(this.state.counters_data[val])
		}
	}

	onShowEditAction = (row) => {

		var params = {
			transporter_code: localStorage.getItem("transportercode")
		}
		this.setState({
			sliderRso: 'slider-translate',
			overly: 'show-m',
			plant: { "value": row.plant_location_code, "label": row.plant_location_name },
			dealer: { "value": row.dealer_code, "label": row.dealer_name },
			oem: { "value": row.oem_name, "label": row.oem_name },
			driver: { "value": row.driver_name, "label": row.driver_name },
			truck_no: row.truck_no,
			trip_no: row.trip_no,
			invoice_date: getHyphenDDMMMYYYYHHMM(row.invoice_date),
			gate_in_time: getHyphenDDMMMYYYYHHMM(row.gate_in_time),
			gate_out_time: getHyphenDDMMMYYYYHHMM(row.gate_out_time),
			quantity: row.quantity,
			eway_bill_date: getHyphenDDMMMYYYYHHMM(row.eway_bill_date),
			fleet_amount: row.fleet_amount,
			distance_in_km: row.distance_in_km,
			expected_eta: row.expected_eta,
			city: { "value": row.city, "label": row.city },
			selectedRowNode: row

		})
		$("#inputTrucktrip").val(row.truck_no);
	}
	formHandler = async (event) => {
		event.preventDefault();
		var selectedInfo = this.state.selectedInfo
		var selectedOption = this.state.selectedOption
		console.log(this.state.selectedOption,"this.state.selectedOption")
		var trucks = this.state.alltrucks;
		var fromDate = $("#invoiceStartDate").val();
		var toDate = $("#invoiceEndDate").val();
		console.log(fromDate,"fromDate")
		console.log(toDate,"toDate")
		// console.log(this, "trucks")
		// var inputData = $("#userinputTruck").val();
		
		// console.log(inputData, "input truck");
		// var filteredValue = this.state.allRowData.filter(function(e){
		// 	return e.truck_no == $("#inputTruck").val()
		// });
		if (localStorage.getItem("transportercode") != undefined) {
			var transporter = JSON.parse(localStorage.getItem("transportercode"))
		}
		else {
			var transporter = "";
		}
		if (Array.isArray(transporter)) {
			var transporter_code = transporter[0]
		}
		else {
			var transporter_code = transporter[0]
		}
		if(this.state.selectedOption != "" && this.state.selectedOption != undefined)
		{
			var selOptn = [];
			selectedOption.map((e) => {
				selOptn.push(e.value)
			})
		}
		
		var params = {
			searchFor: this.state.selectedInfo.value,
			selectedOption: selOptn,
			transporter_code: transporter_code,
			fromDate:fromDate,
			toDate:toDate
		}
		
		// var fromDate = $("#invoiceStartDate").val();
		// var toDate = $("#invoiceEndDate").val();
		// if(fromDate !='' && toDate !=''){
		// 	params.fromDate = fromDate;
		// 	params.toDate = toDate;
		// }
		console.log(params,"params")
		this.setState({
			loadshow:'show-m',
			overly:'show-m'
		})
		redirectURL.post("/consignments/getTPTMSILTripsFilter", params).then((response) => {
			var historicData = response.data.consignments
			console.log(historicData,"historicData")
			let countersdata  = historicData;
			let msilCounters  = []

			var transitDelayData = countersdata.filter(e => (e.transit_delay == "1"))
			msilCounters['transit_delay'] = transitDelayData;
			var noGpsData = countersdata.filter(e => e.no_gps_data == "1")
			msilCounters['no_gps_data'] = noGpsData;
			var overSpeeding = countersdata.filter(e => e.overspeeding_exception == "1")
			msilCounters['overspeeding_exception'] = overSpeeding;
			var nightDriving = countersdata.filter(e => e.nimghtdriving_exception == "1")
			msilCounters['nightdriving_exception'] = nightDriving;
			var loadingDelay = countersdata.filter(e => e.loading_delay_exception == "1")
			msilCounters['loading_delay_exception'] = loadingDelay;
			var stoppages = countersdata.filter(e => e.enroute_exception == "1")
			msilCounters['enroute_exception'] = stoppages;
			var isCritical = countersdata.filter(e => e.is_critical == "1")
			msilCounters['is_critical'] = isCritical;
			var probableAccident = countersdata.filter(e => e.probable_accident == "1")
			msilCounters['probable_accident'] = probableAccident;

			var name = this.state.adaniCheck === 1 ? 'Shuttle No' : 'Truck No'
				var pdfRowData = [];
				historicData.map(function (t) {
					var invoiceTime = "NA";
					if (t.invoice_time === "") {
						invoiceTime = "NA"
					} else {
						invoiceTime = getHyphenDDMMMYYYYHHMM(t.invoice_time)
					}
					pdfRowData.push({
						name: t.truck_no,
						consignment_code: t.trip_no,
						status: t.status,
						expected_trip_end: getHyphenDDMMMYYYYHHMM(t.expected_trip_end),
						revised_trip_end: getHyphenDDMMMYYYYHHMM(t.revised_trip_end),
						transit_delay:t.transit_delay,
						device_address: t.address,
						device_city: t.city,
						device_state: t.state,
						last_active_on: getHyphenDDMMMYYYYHHMM(t.last_active_on),
						invoice_time: t.invoice_date,
						//transporter_name:t.transporter_name,
						consigner_code: t.plant_location_code,
						consigner_name: t.plant_location_name,
						consignee_code: t.dealer_code,
						consignee_name: t.dealer_name,
						consignee_city: t.city,
						distance_from_dealer_location: t.distance_from_dealer_location,
						aprox_dist_travelled_from_start: t.aprox_dist_travelled_from_start,
						gate_in_time: getHyphenDDMMMYYYYHHMM(t.gate_in_time),
						gps_gate_in_time: getHyphenDDMMMYYYYHHMM(t.inside_fence),
						gate_out_time: getHyphenDDMMMYYYYHHMM(t.gate_out_time),
						gps_gate_out_time: getHyphenDDMMMYYYYHHMM(t.outside_fence),
						is_transshipment: t.is_transshipment,
						quantity: t.quantity,
						fleet_amount: t.fleet_amount,
						expected_eta: t.expected_eta,
					});
				});

				var pdfColumns = [
					{ header: name, binding: 'name' },
					{ header: 'Consignment Code', binding: 'consignment_code' },
					{ header: 'Status', binding: 'status' },
					{ header: 'Original ETA', binding: 'expected_trip_end' },
					{ header: 'Revised ETA', binding: 'revised_trip_end' },
					{ header: 'Transit Delay', binding: 'transit_delay' },
					{ header: 'Address', binding: 'device_address' },
					{ header: 'Last City/Town', binding: 'device_city' },
					{ header: 'Last State', binding: 'device_state' },
					{ header: 'Last Packet Time', binding: 'last_active_on' },
					{ header: 'Invoice Date', binding: 'invoice_time' },
					//{ header: 'Transporter Name', binding: 'transporter_name'},
					{ header: 'Consigner Code', binding: 'consigner_code' },
					{ header: 'Consigner Name', binding: 'consigner_name' },
					{ header: 'Consignee Code', binding: 'consignee_code' },
					{ header: 'Consignee Name', binding: 'consignee_name' },
					{ header: 'Consignee City', binding: 'consignee_city' },
					{ header: 'Dist. From Destination (km)', binding: 'distance_from_dealer_location' },
					{ header: 'Total Trip Distance (Km)', binding: 'aprox_dist_travelled_from_start' },
					{ header: 'Gate in Time', binding: 'gate_in_time' },
					{ header: 'Gps Gate in Time', binding: 'gps_gate_in_time' },
					{ header: 'Gate out Time', binding: 'gate_out_time' },
					{ header: 'Gps Gate Out Time', binding: 'gps_gate_out_time' },
					{ header: "Transshipment", binding: "is_transshipment" },
					{ header: "Quantity", binding: "quantity" },
					{ header: "Freight Amount", binding: "fleet_amount" },
					{ header: "Expected ETA", binding: "expected_eta" },
					// { header: "Distance Traveled last 24Hr(KM)", binding: "distance_travelled_in_24_hrs_kms" },
					// { header: "Old Truck No", binding: "old_truck_no" },
					// { header: "Transshipment Date", binding: "transshipment_date" },
					// { header: "TPT Delivered", binding: "tpt_confirm_delivery" },
					// { header: "TPT Delivered On", binding: "tpt_delivery_date" },
					// { header: "TPT Delivered Reason", binding: "tpt_delivery_reason" },
					// { header: "Transit Time(Days)", binding: "transit_time" },
					// { header: "Expected Distance (KM)", binding: "distance_in_km" },
					// { header: "Cluster", binding: "cluster" },
					// { header: "SPOC Contact Name", binding: "spoc_contact_name" },
					// { header: "SPOC Contact Number", binding: "spoc_contact_number" },
					// { header: "Vehicle Mode", binding: "vehicle_mode" },


				]

			this.setState({
				pdfColumns,
				pdfRowData,
				rowData: historicData,
				allRowData : historicData,
				counters_data : msilCounters,
				loadshow:'show-n',
				overly:'show-n'
			})
		})
	}
	onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		// console.log("IN onShowRouteDiv, /Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no
		});

		// If gateouttime is current time - It shows "No Route data available"
		if (e.invoice_date) {
			// console.log("came into invoice time", e.invoice_time)
			//console.log("Step1", e.invoice_time)
			var gateouttime = moment.parseZone(e.invoice_date).format('YYYY-MM-DD HH:mm:ss');
		}
		else {
			//console.log("Step2")
			if (e.gate_in_time) {
				// console.log("came into gate in time", e.gate_in_time)
				//console.log("Step3")
				var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
			}
			else {
				//console.log("Step4")
				if (e.gate_out_time) {
					//console.log("Step5")
					// console.log("came into gate out time", e.gate_out_time)
					var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}

		// if (e.gate_out_time) {
		// 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		// }

		var edate = '';
		var end_date;
		if (e.status <= 2) {
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			// console.log("status less than 2");
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			edate = getHyphenDDMMYYYYHHMMSS(end_date);
		}
		else if (e.status == 3 || e.status == 4) {
			if (e.recent_dealer_reported) {
				end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.recent_dealer_reported);
			}
			// console.log("status 3 and 4");
		}
		else if (e.status == 5) {
			if (e.left_dealer_marked) {
				end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
			}
			// console.log("status 5");
		}
		else if (e.status == 6) {
			if (e.force_closure_time) {
				end_date = e.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.force_closure_time);
			}
			// console.log("status 6");
		}
		else {
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')

			edate = getHyphenDDMMYYYYHHMMSS(end_date);
			// console.log("in else condition");
		}

		//console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0) {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time + 1) * 24 * 60 * 60 * 1000));
		}
		else {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
		}

		var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

		if (!end_date) {
			// When end date is unavailable then current date is considered as end date.
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			//console.log("Defining end_date ", end_date);
		}
		var reqData = {
			truck_no: e.truck_no,
			consignment_code: e.trip_no,
			consignee_code: e.dealer_code,
			gate_out_time: gateouttime,
			seconddate: end_date,
			screen: "historic"
		}
		// console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				console.log("Props data ", records)

				this.setState({
					loadshow: 'show-m'
				});
				try {
					if (records.status == "failure") {
						console.log("records", records.coords);
						// if (records.status != undefined) {
							this.setState({
								show: true,
								basicTitle: 'No Route data available',
								basicType: "danger",
								loadshow: 'show-n'
							});
						// }

					}
					else {
						if (records != '') {
							var sdate = e.gate_out_time;
							if (e.invoice_date) {
								// console.log("came into invoice time", e.invoice_time)
								//console.log("Step1", e.invoice_time)
								var sdate = e.invoice_date;
							}
							else {
								//console.log("Step2")
								if (e.gate_in_time) {
									// console.log("came into gate in time", e.gate_in_time)
									//console.log("Step3")
									var sdate = e.gate_in_time;
								}
								else {
									//console.log("Step4")
									if (e.gate_out_time) {
										//console.log("Step5")
										// console.log("came into gate out time", e.gate_out_time)
										var sdate = e.gate_out_time;
									}
								}
							}


							var edate = '';
							if (e.status <= 4) {
								//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
								//var edatesecond = new Date(ms);
								end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
								edate = getHyphenDDMMYYYYHHMMSS(end_date);
							}
							else if (e.status == 5) {
								if (e.left_dealer_marked) {
									edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
								}
								else {
									end_date = moment.parseZone().format('DD-MM-YYYY HH:mm:ss')
									// edate = getHyphenDDMMYYYYHHMMSS(end_date);
								}
							}
							//console.log("End Date ", edate)
							this.setState({
								sliderRouteTranslate: "slider-translate-60p",
								showDiv: 'show-m',
								mapinfo: records,
								dealer: e.dealer_code,
								consignment_code: "Consignment : " + e.trip_no,
								maptruckno: e.truck_no,
								routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
								loadshow: 'show-n',
								sidebarSubHeader: "Consignment Information",
								overly: 'show-m',
								rownode: e,
								leg_no: 0,
								tolls: records.tolls
							});
							this.onShowGoogleRoute(e.trip_no, e.dealer_code, 0, e.truck_no)
							this.renderMap();

						}
					}
				}
				catch (e) {
					// this.setState({
					// 	show: true,
					// 	basicTitle:'No Route data available',
					// 	basicType:"danger",
					// 	loadshow:'show-n'
					// });
				}

			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}
	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = 'MSIL Trips';



		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try {
			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
		}
		catch (e) { }
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};


	onClickNewAddRow(event) {
		event.preventDefault();

		var invoice_date = $("#invoice_date").val();
		var gate_in_time = $("#gate_in_time").val();
		var gate_out_time = $("#gate_out_time").val();
		var eway_bill_date = $("#eway_bill_date").val();
		var quantity = $("#quantity").val();
		var fleet_amount = $("#fleet_amount").val();
		var distance_in_km = $("#distance_in_km").val();
		var expected_eta = $("#expected_eta").val();
		var truck_no = $("#inputTrucktrip").val();
		if (localStorage.getItem("is_hero") == 1 && localStorage.getItem("is_hero") != undefined) {
			var accessby = "HERO"
		}
		else {
			var accessby = "Maruti Suzuki"
		}
		if (this.state.oem.value != "" && this.state.plant.value != "" && this.state.city.value != ""
			&& invoice_date != "" && gate_out_time != ""
			&& invoice_date != "__-__-____ __:__" && gate_out_time != "__-__-____ __:__") {
			var params = {
				oem_code: this.state.oem.value,
				oem_name: this.state.oem.label,
				plant_location_code: this.state.plant.value,
				plant_location_name: this.state.plant.label,
				dealer_code: this.state.dealer.value,
				dealer_name: this.state.dealer.label,
				city: this.state.city.value,
				truck_no: truck_no,
				trip_no: this.state.trip_no,
				vin_no: this.state.vin_no,
				driver_name: this.state.driver.value,
				invoice_date: invoice_date,
				gate_in_time: gate_in_time,
				gate_out_time: gate_out_time,
				eway_bill_date: eway_bill_date,
				quantity: quantity,
				fleet_amount: fleet_amount,
				distance_in_km: distance_in_km,
				expected_eta: expected_eta,
				accessby: accessby,
				transporter_code: localStorage.getItem("transportercode")
			}
			if (this.state.selectedRowNode != "") {
				params.row_id = this.state.selectedRowNode._id
			}
			// console.log("params ", params)
			redirectURL.post("/dashboard/savetpttrips", params, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).
				then(
					(response) => {
						//console.log("update response is : ",response.data);
						//console.log("Update Response ",response.data.result)
						if (response.data.status == "success") {
							this.setState({
								show: true,
								basicTitle: 'Successfully added data',
								basicType: "success",
								sliderRso: "",
								selectedRowNode: "",
								overly: "show-n",
								loadshow: "show-n"
							});

							var consignData = {}
							var tptcode = localStorage.getItem("transportercode");
							if (localStorage.getItem("transportercode") != undefined) {
								var transporter = JSON.parse(localStorage.getItem("transportercode"))
							}
							else {
								var transporter = "";
							}
							if (Array.isArray(transporter)) {
								var transporter_code = transporter[0]
							}
							else {
								var transporter_code = transporter[0]
							}


							var urlpath = '/consignments/tptmsiltrips';
							if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
								var sDate = this.state.defaultsdate;
							}
							else {
								var sDate = this.state.startDate;
							}
							if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
								var eDate = this.state.defaultedate;
							}
							else {
								var eDate = this.state.endDate;
							}
							var consignData = {
								tpt_code: tptcode,
								transporter_code: this.state.localtransporter,
								startDate: sDate,
								endDate: eDate,
								consigner: this.state.consigner
							}

							this.onLoadGetConsignmentsList(urlpath, consignData);
						}
						else if (response.data.status == "gateout") {
							this.setState({
								show: true,
								basicTitle: 'Gate out time should be greater than gate in time and invoice date.',
								basicType: "danger",
							});
						}
						else {
							this.setState({
								show: true,
								basicTitle: 'Something went wrong',
								basicType: "danger",
							});
						}

					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}
		else {
			this.setState({
				show: true,
				basicTitle: 'All * fields should not be empty',
				basicType: "danger",
			});
		}

	}
	onAddRow() {
		var params = {
			transporter_code: localStorage.getItem("transportercode")
		}
		// redirectURL.post("/dashboard/tpttrucks", params)
		// 	.then((response) => {
		// 		var records = response.data.records;
		// 		var trucks = []
		// 		if (records.length > 0) {
		// 			records.map((item) => {
		// 				trucks.push(item.truck_no);
		// 			})
		// 		}
		// 		this.setState({
		// 			alltrucks: trucks
		// 		})
		// 	})
		redirectURL.post("/dashboard/tripsdealers")
			.then((response) => {
				// console.log("respone oem ", response.data)
				var dealers = response.data.records;
				// console.log("dealers ", dealers)
				let newArray = [];
				// Declare an empty object
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements
				for (let i in dealers) {

					// Extract the title
					totobjTitle = dealers[i]['city'];

					// Use the title as the index
					totuniqueObject[totobjTitle] = dealers[i];
				}

				// Loop to push unique object into array
				for (var a in totuniqueObject) {
					newArray.push(totuniqueObject[a]);
				}
				this.setState({
					cities: newArray
				})
			})
		// var newItem = 
		// {
		//     "truck_no":"",
		//     "truck_type":"",
		//     "transporter_code":"",
		//     "dept_code":""
		// };
		// console.log("newItem",newItem);
		// var res = this.gridApi.updateRowData({ add: [newItem] });
		this.setState({
			sliderRso: 'slider-translate',
			selectedRowNode: "",
			overly: 'show-m',
			plant: { "value": "", "label": "Plant Location" },
			dealer: { "value": "", "label": "Dealer" },
			oem: { "value": "", "label": "OEM" },
			driver: { "value": "", "label": "Driver" },
			truck_no: "",
			trip_no: "",
			vin_no: "",
			invoice_date: "",
			gate_in_time: "",
			gate_out_time: ""
		})
		$("#invoice_date").val("__-__-____ __:__");
		$("#gate_in_time").val("__-__-____ __:__");
		$("#gate_out_time").val("__-__-____ __:__");
		$("#invoice_date").val("");
		$("#gate_in_time").val("");
		$("#gate_out_time").val("");

	}


	onChangeSourceItem(plant) {
		this.setState(
			{ plant },
			// () => console.log(`Option selected:`, this.state.plant)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeDealers(dealer) {
		this.setState(
			{ dealer },
			// () => console.log(`Option selected:`, this.state.dealer)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangeCity(city) {
		this.setState(
			{ city },
			// () => console.log(`Option selected:`, this.state.city)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeDriver(driver) {
		this.setState(
			{ driver },
			// () => console.log(`Option selected:`, this.state.driver)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangeOEM(oem) {
		this.setState(
			{ oem },
			() => {
				var transporter_code = localStorage.getItem("transportercode")
				var prms = {
					transporter_code: transporter_code,
					oem_code: this.state.oem.value
				}
				redirectURL.post("/dashboard/oemplantdealersdata", prms)
					.then((response) => {
						// console.log("respone oem ", response.data)
						var dealers = response.data.dealers;
						var plants = response.data.plants;
						this.setState({
							dealers: dealers,
							plantlocations: plants
						})
					})
			}
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	selectsources() {
		let items = [];
		try {
			if (this.state.plantlocations.length > 0) {
				this.state.plantlocations.map((item) => {
					items.push({ "value": item.plant_location_code, "label": item.plant_location_name })
				})
			}
		}
		catch (e) {

		}
		return items;
	}
	selectdealers() {
		let items = [];
		try {
			if (this.state.dealers.length > 0) {
				this.state.dealers.map((item) => {
					items.push({ "value": item.dealer_code, "label": item.dealer_name })
				})
			}
		}
		catch (e) {

		}
		return items;

	}
	selectcity() {
		let items = [];
		var citieslist = [];
		if (this.state.cities.length > 0) {
			citieslist = [...new Set(this.state.cities.map(obj => obj.city))];
		}
		try {
			if (citieslist.length > 0) {
				citieslist.map((item) => {
					if (item != '') {

						items.push({ "value": item, "label": item })

					}
				})
			}
		}
		catch (e) {

		}
		return items;
	}
	selectoem() {
		let items = [];
		try {
			if (this.state.oems.length > 0) {
				this.state.oems.map((item) => {
					items.push({ "value": item.oem_code, "label": item.oem_name })
				})
			}
		}
		catch (e) {

		}
		return items;
	}

	selectdrivers() {
		let items = [];
		try {
			if (this.state.drivers.length > 0) {
				this.state.drivers.map((item) => {
					items.push({ "value": item.driver_name, "label": item.driver_name })
				})
			}
		}
		catch (e) {

		}
		return items;
	}

	onChangeTransitType(transittype) {
		this.setState(
			{ transittype },
			// () => console.log(`Option selected:`, this.state.transittype)
		);


		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	initalListTrip = (event) => {
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.alltrucks;
		var tptunqrecords = dataset

		tptunqrecords = tptunqrecords.slice(0, 100);
		this.setState({
			filteredData: tptunqrecords,
			showTrucksList: "show-m",
		})
	}
	handlerFormTrip = (event) => {
		//console.log("Select", event.target.value);
		// let name = event.target.name;
		// let value = event.target.value;
		// this.setState({[name]:value});
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.alltrucks
		var tptunqrecords = dataset

		var filteredResult = tptunqrecords.filter(function (e) {
			if (e != "" && e != null) {
				return e.toString().toLowerCase().includes($("#inputTrucktrip").val().toLocaleLowerCase());
			}

		});
		// console.log("filteredResult ", filteredResult)
		filteredResult = filteredResult.slice(0, 100);
		this.setState({
			filteredDataTrip: filteredResult,
			showTrucksListTrip: "show-m",
		})
	}
	changeSearchInfo = (e) => {
		console.log(e.value,"e.value")
		if(e.value == "Truck No")
		{
			var optns = this.state.truck_no_options
		}
		else
		{
			var optns = this.state.trip_no_options
		}
		this.setState({ selectedInfo: e,optns:optns }) 
		
	}
	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		const { open } = this.state;
		const { openforceclose } = this.state;
		const { tcode } = this.state;
		var maptransporter = '';
		try {
			if (this.state.rownode.transporter) {
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter = this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch (e) {
			console.log(e)
		}
		var adaniCheck = this.state.adaniCheck;
		var tkName = "Truck No";
		if (adaniCheck == 1) {
			tkName = "Shuttle No";
		}

		var listDatatrip = []
		this.state.filteredDataTrip.map(function (e) {
			listDatatrip.push(<li className="tptdropdown-truck-no">{e}</li>)
		})
		const columnwithDefs =
			[
				{
					headerName: "",
					field: "_id",
					colId: "_id",
					width: 50,
					// pinned: 'left',
					cellRenderer: 'consignmentActions',
					filter: false, resizable: true

				},
				{
					headerName: "",
					field: "_id",
					colId: "_id",
					width: 50,
					// pinned: 'left',
					cellRenderer: 'editActions',
					filter: false, resizable: true

				},
				{
					headerName: tkName,
					field: "truck_no",
					width: 140,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},

				{
					headerName: "Consignment Code",
					field: "trip_no",
					//colId : "consignment_code",
					width: 120,
					// pinned: 'left',
					editable: false,
					filter: "agSetColumnFilter", resizable: true,
					//cellRenderer : "agGroupCellRenderer"
				},
				{
					headerName: "Status",
					field: "status",
					width: 120,
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter: function (params) {
						//console.log("GPS ", params.data.gps_data_available);
						if (params.data.status == 1) {
							return "Inside Plant"
						}
						else if (params.data.status == 2) {
							return "In Transit"
						}
						else if (params.data.status == 3) {
							return "Near Destination"
						}
						else if (params.data.status == 4) {
							return "Reached Destination"
						}

						else if (params.data.status == 5) {
							return "Left Destination"
						}
						else if (params.data.status == 6) {
							return "Force Closed"
						}
						else {
							return ""
						}

					}
				},
				{
					headerName: "Original ETA",
					field: "expected_trip_end",
					id: "expected_trip_end",
					width: 160,
					//pinned: 'left',              
					resizable: true,
					valueGetter: function (params) {
						if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null) {
							return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						}
						else {
							return "";
						}


					},
					comparator: dateComparator,

				},
				{
					headerName: "Revised ETA",
					field: "revised_trip_end",
					width: 160,
					//pinned: 'left',              
					resizable: true,
					// cellClass : "dateFormat",
					valueGetter: function (params) {
						//console.log(" ETA ", params.data)
						if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						}
						else {
							return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
						}

					},
					comparator: dateComparator,

					cellClass: function (params) {
						let hourDifference = 0;
						if (params.data.revised_trip_end && params.data.expected_trip_end) {
							let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
							let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
							hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
							//console.log("diff= ", params.data.consignment_code, hourDifference);
						} else {
							//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
						}

						if (hourDifference >= 1) {
							return 'bgColorDangerMedium'
						}

						if (hourDifference <= -1) {
							return 'bgColorSuccessMedium'
						}
						else {
							return ''
						}
					}
				},
				{
					headerName: "Transit Delay(Days)",
					field: "totaldays",
					width: 200,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						var expected = params.data.expected_trip_end;
						if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
							var revised = params.data.expected_trip_end;
						}
						else {
							var revised = params.data.revised_trip_end;
						}
						var s = moment.parseZone(expected).format("x");
						var ed = moment.parseZone(revised).format("x");
						var diff = (ed - s)
						var days = secondsToDays(diff)
						var totaldays = Math.round(days / 1000);
						if (totaldays > 0) {
							return totaldays;
						}
						else {
							return 0;
						}
					}
				},

				{
					headerName: "Current Geofence",
					field: "dealer_name",
					width: 160,
					filter: true,
					resizable: true
					
				},
				{
					headerName: "Address",
					field: "drop_location",
					width: 200,
					valueGetter : function(params){
						if(!["" , null , undefined , "undefined".includes(params.data.drop_location)]){
							return params.data.drop_location
						}else{
							return params.data.dealer_name
						}
					}
					// colId:"device_address",
				},
				

				// {
				//     headerName:"Last City/Town",
				//     field:"area",
				//     width:150,
				//     filter:"agSetColumnFilter",
				//     cellRenderer:'',resizable: true
				// },    
				//     {
				//     headerName:"Last State",
				//     field:"state",
				//     width:120,
				//     // hide:showOnlyCol,
				//     filter:"agSetColumnFilter",
				//     cellRenderer:'',resizable: true,

				// },             

				// {
				// 	headerName: "Last Packet Time",
				// 	field: "last_active_on",
				// 	width: 130,
				// 	valueGetter: function (params) {
				// 		return getHyphenDDMMMYYYYHHMM(params.data.last_active_on)
				// 	},

				// 	//filter: "agDateColumnFilter",
				// 	// cellRenderer:'',resizable: true	          
				// },
				// {
				// headerName: "GPS Provider",
				// field: "actual_lspuser",
				// width: 200,
				// editable : true,
				// filter: "agSetColumnFilter"	
				// },    
				{
					headerName: "Invoice Date",
					field: "invoice_date",
					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.invoice_date != '' && params.data.invoice_date != undefined) {
							return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
						}
						else {
							return "NA";
						}
					},
				},
				{
					headerName: "Consigner Code",
					field: "plant_location_code",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Consigner Name",
					field: "plant_location_name",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Consignee Code",
					field: "dealer_code",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Consignee Name",
					field: "dealer_name",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Consignee City",
					field: "city",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},

				{
					headerName: "Dist. From Destination (km)",
					field: "distance_from_dealer_location",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						//console.log("params ", params.data);
						if (typeof params.data.distance_from_dealer_location == 'undefined') {

						}
						else {
							return Math.round(params.data.distance_from_dealer_location);
						}

					},
				},

				{
					headerName: "Total Trip Distance (Km)",
					field: "aprox_dist_travelled_from_start",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: function (params) {
						//console.log("params ", params.data);
						if (typeof params.data.aprox_dist_travelled_from_start == 'undefined') {

						}
						else {
							return Math.round(params.data.aprox_dist_travelled_from_start);
						}

					},
				},

				{
					headerName: "Total Challan Amount (Rs)",
					field: "challan_amount_rs",
					width: 150,
					resizable: true,

				},
				{
					headerName: "Total Fastag Amount (Rs)",
					field: "fasttag_amount_rs",
					width: 150,
					resizable: true,

				},
				{
					headerName: "Gate in Time",
					field: "gate_in_time",
					width: 180,
					//filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.gate_in_time != "2000-01-01T00:00:00.000Z" && params.data.gate_in_time != "1970-01-01T00:00:00.000Z" && params.data.gate_in_time != "") {
							return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
						}
						else {
							return "";
						}

					},
					resizable: true
				},
				// {
				// 	headerName: "GPS Gate in Time",
				// 	field: "inside_fence",
				// 	width: 180,
				// 	//filter: "agDateColumnFilter",
				// 	//cellRenderer:'dateFormater',
				// 	comparator: dateComparator,

				// 	valueGetter: function (params) {
				// 		return getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
				// 	},
				// 	resizable: true
				// },

				{
					headerName: "Gate out Time",
					field: "gate_out_time",
					width: 180,
					//filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,

					valueGetter: function (params) {
						return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
					},
					resizable: true
				},
				// {
				// 	headerName: "GPS Gate out Time",
				// 	field: "outside_fence",
				// 	width: 180,
				// 	//filter: "agDateColumnFilter",
				// 	//cellRenderer:'dateFormater',
				// 	// sortable:true,
				// 	comparator: dateComparator,

				// 	valueGetter: function (params) {
				// 		return getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
				// 	},
				// 	resizable: true
				// },
				{
					headerName: "Transshipment",
					field: "is_transshipment",
					width: 110,
					filter: "agSetColumnFilter",
					valueGetter: function (params) {
						if (params.data.is_transshipment == 1) {
							return "Yes";
						}
						else {
							return "No";
						}
					},
					resizable: true,

				},
				{
					headerName: "Quantity",
					field: "quantity",
					width: 110,
					filter: "agSetColumnFilter",
					resizable: true,

				},
				// {
				// 	headerName: "Freight Amount",
				// 	field: "fleet_amount",
				// 	width: 110,
				// 	filter: "agSetColumnFilter",
				// 	resizable: true,

				// },
				{
					headerName: "Expected Distance (KM)",
					field: "distance_in_km",
					width: 110,
					filter: "agSetColumnFilter",
					resizable: true,

				},
				// {
				// 	headerName: "Expected ETA",
				// 	field: "expected_eta",
				// 	width: 110,
				// 	filter: "agSetColumnFilter",
				// 	resizable: true,

				// },
				{
					headerName: "Reached Dealer",
					field: "recent_dealer_reported",
					width: 180,
					//filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,

					valueGetter: function (params) {
						try {
							if (params.data.recent_dealer_reported != "" && params.data.recent_dealer_reported != undefined) {
								return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
							}
							else {
								return "";
							}
						} catch (e) {

						}

					},
					resizable: true
				},

				{
					headerName: "Left Dealer",
					field: "left_dealer_marked",
					width: 180,
					//filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,

					valueGetter: function (params) {
						try {
							if (params.data.left_dealer_marked != "" && params.data.left_dealer_marked != undefined) {
								return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
							}
							else {
								return "";
							}
						} catch (e) {

						}

					},
					resizable: true
				},

				{
					headerName: "Trip Close",
					field: "",
					width: 80,
					cellRendererSelector: function (params) {
						var rendComponent = {
							component: "consignmentforceclose"
						};
						return rendComponent;
					},
					resizable: true,


				},
			];
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}

		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}

				<div className="row">
					{(this.state.msilCounters != undefined) ?
						<TptMSILTripsCounters
							context={this}
							total_rows={this.state.allRowData}
							counters={this.state.counters_data}
							onClickCounterShowData={this.onClickCounterShowData}
						/>
						: ""}
					<form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
						<div className="row">
							<div className="col-xl-2 col-lg-2">
								<div className="form-group">
									<label>Search For: </label>
									<Select
										value={this.state.selectedInfo}
										// placeholder="All"
										closeMenuOnSelect={true}
										isMulti={false}
										// onChange={this.getRailConsginments.bind(this)}
										className={"border-radius-0"}
										style={{ borderRadius: "0px" }}
										options={[{ "label": "Truck No", value: "Truck No" }, { "label": "Trip No", value: "Trip No" }]}
										onChange={this.changeSearchInfo.bind(this)}
									/>
								</div>
							</div>
							{console.log(this.state.truck_no_options,"truck_no_options drop down")}
							<div className="col-xl-2 col-lg-2">
								<div className="form-group">
									<label>Select Option</label>
									<Select
									value= {this.state.selectedOption}
									// placeholder="All"
									closeMenuOnSelect={true}
									isMulti={true}
									// onChange={this.getRailConsginments.bind(this)}
									className={"border-radius-0"}
									style={{borderRadius:"0px"}}
									options={this.state.optns}
									onChange={(e)=>{this.setState({selectedOption:e})}}
									/>
								</div>
							</div>
							<div class="col-xl-2 col-lg-2 row form-group">
								<label>From Date</label>
								<input type="text" name="date" id="invoiceStartDate" autoComplete='off' className="datetimepicker_date form-control" />
							</div>
							<div class="col-xl-2 col-lg-2 row form-group">
								<label>To Date</label>
								<input type="text" name="date" id="invoiceEndDate" autoComplete='off' className="datetimepicker_date form-control" />
							</div>
							<div className="form-group col-xl-2 col-lg-2">
								<button type="submit" style={{ marginTop: "30px" }} className={"btn btn-success "}>Submit</button>
							</div>
						</div>
					</form>
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>


									<span className="layoutbtns pull-right">
										<button className="float-right custom-btn white btn-danger"
											style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
									{(this.state.rowData.length > 0) ?
										<ExportPDF rowData={this.state.pdfRowData} pagetitle="Historic Trips" pdfColumns={this.state.pdfColumns}
											addressColumn={this.state.addressColumn} />
										: ""}
								</h5>
							</div>
							<div className="card-body pt-10px" >



								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={false}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}

										enableCellChangeFlash={false}
										//suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										// suppressCellSelection= {true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
									/>

									{/* 
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

								</div>
							</div>
						</div>
					</div>

				</div>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap context={this} rownode={this.state.rownode} mapFor={"consignment"} tolls={this.state.tolls} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} adaniCheck={this.state.adaniCheck} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">{(this.state.adaniCheck == 1) ? "Shuttle" : "Truck"} No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '') ?
												<div className="col route-block">
													<label className="sidebar-label">Transporter</label>
													<div>{(!isEmpty(maptransporter)) ? maptransporter : ""}</div>
												</div>

												: ""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.mapinfo.route_details.start_time != '') ? getHyphenDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.mapinfo.route_details.end_time != '') ? getHyphenDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
											</div>
											{/* <div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance / 1000)} Kms</div>
											</div> */}

											{(this.state.leg_no == 0 || this.state.leg_no == null) ? ""
												:
												<div className="col route-block">
													<label className="sidebar-label">Google Distance</label>
													<div>{(this.state.rownode.actual_google_leg_distance) ? parseInt(this.state.rownode.actual_google_leg_distance) : "0"} Kms</div>
												</div>
											}

											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}


				<div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Add /Edit New Trip
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select OEM *</label>
									<Select
										placeholder={"Select OEM"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeOEM.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										value={this.state.oem}
										options={this.selectoem()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Plant Location *</label>
									<Select
										placeholder={"Select Plant Location"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeSourceItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										value={this.state.plant}
										options={this.selectsources()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Dealer </label>
									<Select
										placeholder={"Select Dealer"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeDealers.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										value={this.state.dealer}
										options={this.selectdealers()} />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select city * </label>
									<Select
										placeholder={"Select City"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeCity.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										value={this.state.city}
										options={this.selectcity()} />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<div className={"trucks-dropdown " + this.state.showTrucksListTrip} style={{ top: "65px" }}>
										<ul>
											{listDatatrip}
										</ul>
									</div>
									<label className="col-xl-12 col-lg-12">Truck No *</label>
									{/*<input 
									type="text" 
									name="truck_no"
									value={this.state.truck_no}
									className="form-control" 
                                    // onChange={this.changeNumberAccept.bind(this)}
                                    onChange = {this.changeHandler.bind(this)}
                                    // onKeyPress={onlyNumberKey(this.value)}
                                    /> 
                                    */}
									<input
										type="text"
										name="truck_no"
										id="inputTrucktrip"
										placeholder={(this.state.adaniCheck == 1) ? "Shuttle" : "Truck" + " No"}
										autoComplete="off"
										style={{ marginLeft: "15px" }}
										className="form-control"
										onFocus={this.initalListTrip.bind(this)}
										onChange={this.handlerFormTrip.bind(this)} />
								</div>

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Trip No *</label>
									<input
										type="text"
										name="trip_no"
										value={this.state.trip_no}
										className="form-control"
										onChange={this.changeHandler.bind(this)} />


								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Vin No *</label>
								<input 
									type="text" 
									name="vin_no"
									value={this.state.vin_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Driver *</label>
									<Select
										placeholder={"Select Driver"}
										closeMenuOnSelect={true}
										// isMulti={true}
										onChange={this.onChangeDriver.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										options={this.selectdrivers()} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Quantity</label>
									<input
										type="text"
										name="quantity"
										id="quantity"
										value={this.state.quantity}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Freight Amount</label>
									<input
										type="text"
										name="fleet_amount"
										id="fleet_amount"
										value={this.state.fleet_amount}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Expected ETA</label>
									<input
										type="text"
										name="expected_eta"
										id="expected_eta"
										value={this.state.expected_eta}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Expected Distance (KM)</label>
									<input
										type="text"
										name="distance_in_km"
										id="distance_in_km"
										value={this.state.distance_in_km}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Invoice Date *</label>
									<input
										type="text"
										name="invoice_date"
										id="invoice_date"
										value={this.state.invoice_date}
										className="invoice_date datetimepicker_mask form-control"
										autoComplete={"off"}
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Gate In Time</label>
									<input
										type="text"
										name="gate_in_time"
										id="gate_in_time"
										value={this.state.gate_in_time}
										autoComplete={"off"}
										className="gate_in_time datetimepicker_mask form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Gate Out Time *</label>
									<input
										type="text"
										name="gate_out_time"
										id="gate_out_time"
										value={this.state.gate_out_time}
										autoComplete={"off"}
										className="gate_out_time datetimepicker_mask form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Eway Bill date</label>
									<input
										type="text"
										name="eway_bill_date"
										id="eway_bill_date"
										value={this.state.selected_eway_bill_date}
										autoComplete={"off"}
										className="gate_out_time datetimepicker_mask form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>


						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderForceCloseTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Trip Closure
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>

								{/* 								
								<ForceCloseSideBar context={this} handleInput = {this.handleInput} deptcode={this.state.deptcode}/> */}

								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Delivery Date:</label>
									<input
										type="text"
										name="tpt_deliver_date"
										id="tpt_deliver_date"
										autoComplete={"off"}
										className="tpt_deliver_date datetimepicker_mask form-control" />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

$(document).ready(function () {
	$(document).on("click", ".tptdropdown-truck-no", function () {
		var vals = $(this).text();
		$("#inputTrucktrip").val(vals);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTrucktrip");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}

function onSetCritical(params, status) {
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus', {
		params: params,
		is_critical: status,
		token: localStorage.getItem("token"),
		userId: localStorage.getItem("userid")
	})
		.then((response) => {

			//var records = JSON.parse(JSON.stringify(response)).data;
			console.log(response.data);
		});

}

function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function loadDateTimeScript() {
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format: 'd-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		// format: 'd-m-Y H:i',
		// timepicker: true\
		format:'d-m-Y',
        timepicker: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

// $(document).on("change", "#toDate", function (e) {
// 	e.preventDefault();
// 	var bookingDate = $("#fromDate").val();
// 	var cargoDate = $("#toDate").val();
// 	// var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
// 	// var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
// 	var startDate = new Date(bookingDate);
// 	var endDate = new Date(cargoDate);
// 	var startTime = startDate.getTime();
// 	var endTime = endDate.getTime();
// 	// console.log(startTime,endTime);
// 	if (startTime < endTime) {
// 		$("#toDate").val("");
// 		alert("To Date should be greater than or equal to From Date");
// 	}
// })

// $(document).on("change", "#fromDate", function (e) {
// 	e.preventDefault();
// 	var bookingDate = $("#fromDate").val();
// 	var cargoDate = $("#toDate").val();
// 	// var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
// 	// var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
// 	var startDate = new Date(bookingDate);
// 	var endDate = new Date(cargoDate);
// 	var startTime = startDate.getTime();
// 	var endTime = endDate.getTime();
// 	// console.log(startTime,endTime);
// 	if (startTime > endTime) {
// 		$("#fromDate").val("");
// 		alert("From Date should be less than equal to To Date");
// 	}
// })