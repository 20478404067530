/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import Breadcrumb from '../common/breadcrumb.component';
import {Redirect} from 'react-router-dom';
import Constant from "../common/googlemapkey";
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
const fenchtypeoptions = [
	  { value: 'Plant', label: 'Plant' },
	  { value: 'TVP', label: 'TVP' },
	  { value: 'Stockyard', label: 'Stockyard' },
	  { value: 'Railyard', label: 'Railyard' },
	  { value: 'Port', label: 'Port' },
	  { value: 'Vendor', label: 'Vendor' },
	  { value: 'MSP', label: 'MSP' },
	  { value: 'Dealer', label: 'Dealer' },
	];

const railyardoptions = [
	{ value: 'Loading', label: 'Loading' },
	{ value: 'UnLoading', label: 'UnLoading' },
]


export default class AddTptGeoFence extends Component {

    constructor(props) {
        super(props);

        this.state = {
			pageTitle: "Add TPT Geo Fence",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			currentDepartmentCode: null,
			currentDepartmentTitle: null,
        	routemap:'',
        	departments:[],
        	coords:[],
        	geo_name:'',
        	geo_type:'',
        	fence_type:'',
        	dept_code:[],
        	notify:'',
			// emailid:'',
			erroMessage:'',
			redirectpage:'',
			railyard_type:'',
			rail_delivery_point : 0.0,
			rail_loading_point : 0.0,
    	      
        };
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}    	
    	this.showMap();
    }
   
    showMap =() =>{
    	this.renderMap();
    }
    renderMap = () => {
    	
           loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
           window.initMap = this.initMap
	}
	
   
    initMap = () => {
    	var coordsvalues;
    	var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 12,
            center: new window.google.maps.LatLng(17.3580,78.4869),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true
          });
  		
  		//added by nanda for places dropdown...
  	    var input = document.getElementById('search');
          var searchBox = new window.google.maps.places.SearchBox(input);
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

          // Bias the SearchBox results towards current map's viewport.
          map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());
          });
  		
  		var markers = [];
  		console.log("searchBox",searchBox);
  		 searchBox.addListener('places_changed', function() {
            var places = searchBox.getPlaces();

            if (places.length == 0) {
              return;
            }

            // Clear out the old markers.
            markers.forEach(function(marker) {
              marker.setMap(null);
            });
            markers = [];
  // For each place, get the icon, name and location.
            var bounds = new window.google.maps.LatLngBounds();
            places.forEach(function(place) {
              if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
              }
              var icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25)
              };

              // Create a marker for each place.
              markers.push(new window.google.maps.Marker({
                map: map,
                icon: icon,
                title: place.name,
                position: place.geometry.location
              }));

              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
          });		
  		//End of adding by nanda
  		
  		var polyOptions = {
            strokeWeight: 0,
            fillOpacity: 0.70,
            editable: true
          };
          // Creates a drawing manager attached to the map that allows the user to draw
          // markers, lines, and shapes.
          drawingManager = new window.google.maps.drawing.DrawingManager({
            drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
              drawingModes: [
                window.google.maps.drawing.OverlayType.POLYGON
              ]
            },
            markerOptions: {
              draggable: true
            },
            polylineOptions: {
              editable: true
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: map
          });

          window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function(e) {
              if (e.type != window.google.maps.drawing.OverlayType.MARKER) {
              // Switch back to non-drawing mode after drawing a shape.
              drawingManager.setDrawingMode(null);
              // To hide:
              drawingManager.setOptions({
                drawingControl: false
              });

              // Add an event listener that selects the newly-drawn shape when the user
              // mouses down on it.
              var newShape = e.overlay;
              newShape.type = e.type;
              window.google.maps.event.addListener(newShape, 'click', function() {
                setSelection(newShape);
              });
              setSelection(newShape);
              //console.log("selectedShape",newShape);
  			  fencingLength = selectedShape.getPath().getLength();
  			  
  				var latlngObj=[];
	  			var htmlStr = "";
	  	        for (var i = 0; i < fencingLength; i++) {
	  				var each_lat_lng = selectedShape.getPath().getAt(i).toUrlValue(5).split(',');
	  				var in_latlng_array={"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
	  				latlngObj.push(in_latlng_array);
	  				coordinates.push(in_latlng_array);
	  			}
	  			
            }
              
          });
          
          
          // Clear the current selection when the drawing mode is changed, or when the
          // map is clicked.
  		window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', function(){
  			clearSelection()
  		});
  		window.google.maps.event.addListener(map, 'click', function(){
  			clearSelection()
  		});
        /*
         * window.google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', function(){
        		deleteSelectedShape()
		});
        */
        
  		
      //buildColorPalette();
  		map.addListener('zoom_changed', function() {
            mapZoom=map.getZoom();
          });
  		
  		/*window.google.maps.event.addDomListener(document.getElementById('saveGeo'), 'click', function(){
  			getSelectedShape()
  		});*/
  		
  		console.log("coordinates", coordinates);
  		
    }
    
    
    
    fenceTypeHandler = (event) => {
    	console.log(event.target)
    }


    changeHandler = geo_type => {

    	this.setState(
	      { geo_type },
	     //   () => console.log(`Option selected:`, this.state.geo_type)
	    );
	}
	changeRailyard = railyard_type => {

    	this.setState(
	      { railyard_type },
	     () => console.log(`railyard types:`, this.state.railyard_type.value)
	    );
		if(railyard_type.value == "Loading"){
			this.setState({
				"rail_delivery_point" : 0.0,
    			"rail_loading_point" : 1.0
			})
		}
		else
			{
				this.setState({
					"rail_delivery_point" : 1.0,
					"rail_loading_point" : 0.0
				})
			}
	}
    checkHandler = (event) => {
    	
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
    changeArrayHandler = dept_code => {
    	/*this.setState({
    		dept_code:[...this.state.dept_code,event.target.value]
    	},() => {
    		console.log(this.state.dept_code)
    	});
    	*/
    	
    	this.setState(
	      { dept_code },
	      () => console.log(`Option selected:`, this.state.dept_code)
	    );
    	
    	
    }
    
    checkNotifyHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

	// changeEmailHandler = (event) => {
    // 	let name = event.target.name;
	// 	let value = event.target.value;
    // 	this.setState({[name]:value});
    // }
    
    applyCoords =(e) =>{
    	console.log(e);
    }
    
	formHandler = (event) => {
			
		event.preventDefault();
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pageTitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventFormAction,
		// 		"label": googleAnalytics.page.action.formSubmitted,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }
		var formdata1 = new FormData(event.target);
		console.log(coordinates.length);
		
		if(coordinates.length > 3)
		{
			var transporter_code = "";
			if(localStorage.getItem('transportercode') !=undefined)
			{
				var tptCode = JSON.parse(localStorage.getItem("transportercode"));
				var transporter_code = tptCode[0];
			}
			var params = {
				"transporter_code" : transporter_code  
			}
			var formdata = {}
			if (this.state.geo_type.value == "Railyard") {
				formdata = {
					geo_name: this.state.geo_name,
					geo_type: this.state.geo_type,
					notify: this.state.notify,
					coordinates: coordinates,
					transporter_code:transporter_code,
					isactive: 1,
					rail_delivery_point: this.state.rail_delivery_point,
					rail_loading_point: this.state.rail_loading_point
				}
			}
			else {
				formdata = {
					geo_name: this.state.geo_name,
					geo_type: this.state.geo_type,
					notify: this.state.notify,
					// emailid: this.state.emailid,
					coordinates: coordinates,
					transporter_code: transporter_code
				}
			}
			console.log(formdata,"formdata");
			console.log("coordinates", coordinates);
			
			redirectURL.post("/geofence/addTptGeo", formdata,{
				headers:{
					'Content-Type': 'application/json'
				}
			}).then((response)=>{
					// console.log('response',response);
					if(response.data.message == 'Success')
					{
						this.setState({
							geo_name:'',
							dept_code:'',
							geo_type:'',
							fence_type:'',
							notify:'',
							// emailid:'',
							//redirectpage:'yes'
						});
						window.location.href='/tptlogin/geofences';
					}
					//window.location.href='/manage/geofences';
					
				}
			)
			.catch(function(error){
				console.log(error);
			});
		}
		else
		{
			this.setState({
				erroMessage:'Please Add at least 4 Coordinates'
			});
		}
		
	}
  
    render(){
		
    	const dStyles={
				width:'100%',
				height:'500px'
		}
        
        return (
            <div className="container-fluid">
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
				       			</h5>
				   			</div>
				   			<div className="row card-body">
				   			
								<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
									<div className="col-xl-12 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Geofence Name *:</label>
											<input type="text" name="geo_name" value={this.state.geo_name} onChange={this.changeInputHandler} className="form-control" placeholder="Name" required />
										</div>
									
										
									
										<div className="form-group">
											<label className="c-lbl">Type *:</label>
											
											<Select value={this.state.geo_type} onChange={this.changeHandler} style={{borderRadius:"0px"}} options={fenchtypeoptions} required />
											{this.state.geo_type.value == "Railyard"?
												<>
													<label className="c-lbl mt-4">Railyard Type *:</label>
													<Select placeholder={"Select Railyard Type"} onChange={this.changeRailyard} style={{borderRadius:"0px"}} options={railyardoptions} required />
												</>
												:false
											}
											{/*
											<select name="geo_type" onChange={this.changeHandler.bind(this)} className="form-control" >
												<option value="">Select Type</option>
												<option value="Plant">Plant</option>
												<option value="TVP">TVP</option>
												<option value="Stockyard">Stockyard</option>
												<option value="Railyard">Railyard</option>
												<option value="Port">Port</option>
												<option value="Vendor">Vendor</option>
												<option value="MSP">MSP</option>
											</select>
											*/}
											
										</div>
									
										<div className="form-group">
											<label className="c-lbl">Notify When *:</label>
											<div className="row col-xl-12 col-lg-12">
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio44" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Vehicle Comes in" />
													<label htmlFor="radio44" className="">Vehicle Comes in</label>
												</div>
												<div className="radio radio-info col col-xl-6 col-lg-6">
													<input id="radio55" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Vehicle Goes out" />
													<label htmlFor="radio55" className="">Vehicle Goes out</label>
												</div>
												<div className="radio radio-warning col col-xl-6 col-lg-6">
													<input id="radio66" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Both" />
													<label htmlFor="radio66" className="">Both</label>
												</div>
												<div className="radio radio-danger col col-xl-6 col-lg-6">
													<input id="radio77" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" value="Never" />
													<label htmlFor="radio77" className="">Never</label>
												</div>
											</div>
										</div>
										{/* <div className="form-group">
											<label className="c-lbl">Email ID:</label>
											<input type="text" name="emailid" value={this.state.emailid} onChange={this.changeEmailHandler.bind(this)} className="form-control" placeholder="Name" required />
										</div> */}
											
										<div className="form-group">
											<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save Geofence</button>
											<a href="/tptlogin/geofences" className="btn btn-info cs-btn">Cancel</a>
											
										</div>
									</div>
										
								</form>
				           		<div className="col-xl-8 col-lg-8">
								
									
									<div className={(this.state.erroMessage == '')?"show-n alert alert-danger":
									"show-m alert alert-danger"}>Add at least 4 coordinates of Geofence to save</div>
									
									  <input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />
				
				
				               		<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>
				
				 	               <div className="col-xl-12 col-lg-12">
				 					  <input type="hidden" name="coords" id="coords" value={this.state.coords} />
				 				   </div>
				               		
			               		</div>
			           		</div>
		           		</div>
	           		</div>
                </div>
            </div>
                
        );
    }
}
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
                   
function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}                              
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {
		
				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				
        });
}


 function clearSelection() {
	    if (selectedShape) {
	      selectedShape.setEditable(false);
	      selectedShape = null;
	    }
	  }

	 function setSelection(shape) {
	    clearSelection();
	    selectedShape = shape;
	    //console.log("selectedShape",selectedShape);
	    shape.setEditable(true);
	    
	    selectColor(shape.get('fillColor') || shape.get('strokeColor'));
	  }

  function deleteSelectedShape() {
		fencingLength=0;  
	    if (selectedShape) {
	      selectedShape.setMap(null);
	    }
	    // To show:
	     drawingManager.setOptions({
	       drawingControl: true
	     });
	  }

	  
	 function selectColor(color) {
	      selectedColor = color;
	      for (var i = 0; i < colors.length; ++i) {
	        var currColor = colors[i];
	        //console.log(colorButtons[currColor]);
	        //colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	      }

	      // Retrieves the current options from the drawing manager and replaces the
	      // stroke or fill color as appropriate.
	      var polylineOptions = drawingManager.get('polylineOptions');
	      polylineOptions.strokeColor = color;
	      drawingManager.set('polylineOptions', polylineOptions);

	      var rectangleOptions = drawingManager.get('rectangleOptions');
	      rectangleOptions.fillColor = color;
	      drawingManager.set('rectangleOptions', rectangleOptions);

	      var circleOptions = drawingManager.get('circleOptions');
	      circleOptions.fillColor = color;
	      drawingManager.set('circleOptions', circleOptions);

	      var polygonOptions = drawingManager.get('polygonOptions');
	      polygonOptions.fillColor = color;
	      drawingManager.set('polygonOptions', polygonOptions);
	    }

	function setSelectedShapeColor(color) {
	      if (selectedShape) {
	        if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
	          selectedShape.set('strokeColor', color);
	        } else {
	          selectedShape.set('fillColor', color);
	        }
	      }
	    }

	  function makeColorButton(color) {
	      var button = document.createElement('span');
	      button.className = 'color-button';
	      button.style.backgroundColor = color;
	      window.google.maps.event.addDomListener(button, 'click', function() {
	        selectColor(color);
	        setSelectedShapeColor(color);
	      });

	      return button;
	    }

	  function buildColorPalette() {
	       var colorPalette = document.getElementById('color-palette');
	       for (var i = 0; i < colors.length; ++i) {
	         var currColor = colors[i];
	         var colorButton = makeColorButton(currColor);
	         colorPalette.appendChild(colorButton);
	         colorButtons[currColor] = colorButton;
	       }
	       selectColor(colors[0]);
	     }  
	  
	 function getSelectedShape(coords){
		var lt=[]
		//console.log("coordsLatlngs ",coords);
		for (let value of Object.values(coords)) {
		  //console.log("Val is ",value); // John, then 30
		  lt.push(value)
		}
		//console.log("Combine ",lt);
		document.getElementById('coords').value=lt;
		//return lt;
	}


