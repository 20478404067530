
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');


const ShowUptimeLegs = (props) => {
    
    const handleApproveClick = (e) => {
        e.stopPropagation();
        //console.log("Props Routmap ", props);
        props.context.componentParent.onClickShowUpTimeLegs(props);
    };
   
    const handleShowReport = (e) => {
        e.stopPropagation();
        //console.log("Props Routmap ", props);
        props.context.componentParent.onClickDonwloadUpTimeLegs(props);
    };
    return (
        <div>
            {(props.data.uptime_days != "" && props.data.uptime_days != undefined)?
            <span>
                {props.data.uptime_days}&nbsp;
                <a 
                    href="javascript:;"
                    title="Show Legs" 
                    onClick={handleApproveClick}
                    className="f14" style={{color:"#333"}}>
                        <i className="icofont icofont-site-map f14"></i>
                </a>
                {/* &nbsp;
                <a 
                    href="javascript:;"
                    title="View Data" 
                    onClick={handleShowReport}
                    className="f14" style={{color:"#333"}}>
                        <i className="icofont icofont-download f14"></i>
                </a> */}
            </span>
    
            :""}
           
        </div>
    );
};

export default ShowUptimeLegs;
