/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from 'react-datetime';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';

import Map from '../common/drawmap';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// import MapComponent from './mapLocationComponent';
import Consignmentforceclose from '../manage/consignmentforceclose';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import Modal from 'react-responsive-modal';

var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

const cipher = (salt) => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }
    // eslint-disable-next-line no-unreachable
    catch (e) { }

}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class WareHouseTranshipmentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modules: AllModules,
            // truckData:this.props.truckInfo,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            oldTruckNo: "",
            truckNumList: [],
            loadshow: 'show-n',
            truckLocation: "",
            replaced_date_time: "",
            transhipment_done_by: "",
            route_details: "",
            rowData: [],
            rowSelection: 'multiple',
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 50,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            route_info: "",
            animateRows: true,
            consignment_details: "",
            dept_code: "",
            replacement_truck: "",
            pagetitle: "",
            showonlytransport: 'show-m',
            reason: '',
            usergridstate: [],
            screenurl: "",
            screentitle: "",
            usermanualmodal: false,
            loginplant: "",
            geoList: [],
            geoName: ''
        };

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        loadDateTimeScript();

        $('#replacement-submit').prop("disabled", true);
        var tptCode = localStorage.getItem('transportercode');
        var useremail = localStorage.getItem("email");
        var vehicle_group = localStorage.getItem("vehicle_group");
        var ishero = localStorage.getItem("is_hero");
        var tptrucks = []
        
        var params = {
            // transporter_code: JSON.parse(localStorage.getItem("transportercode")) //parsed 
            transporter_code:localStorage.getItem("transportercode") //stringified
        }
        redirectURL.post("/dashboard/warehouseactivetrucks", params)
            .then((response) => {
                var records = response.data;
                // console.log("recordss ", records)
                var trucks = records.truck_list
                // console.log(trucks,"truckNumList")
                var geolistitems=records.geofence_list

                this.setState({
                    truckNumList:trucks,
                    geoList:geolistitems
                })
                console.log(this.state.truckNumList,"truckNumList")
                this.getGridData(trucks)
            })
            
      
    }
    getGridData(trucks_list){
        try {
            // if(params != null){
            this.setState({
                rowData: [],
            })
            var reqParams = {
                truck_no: trucks_list
            }
            // console.log("Trucks", reqParams)
            redirectURL.post("/dashboard/warehousetrucks", reqParams)
                .then((response) => {
                    // console.log(response.data,"ware house data");
                    this.setState({
                        rowData: JSON.parse(response.data.result)
                    })
                }).catch(function (error) {
                    console.log(error);
                });
            $('#replacement-submit').prop("disabled", false);
        }
        catch (e) {
            console.log(e);
        }
    }

    getTruckConsignments(params) {
        // console.log("params",params)
        // console.log(params,"params.value");
        this.getGridData([params.value])
        
    }

    onGridReady = params => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };


    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);



    }
    selectTruckNum() {
        let arr = [];
        try {
            this.state.truckNumList.forEach(item => {
                //console.log("pod ",item)
                arr.push({ value: item, label: item });
            });
            return arr;
        }
        catch (e) { }
    }

    selectGeoName() {
        let arr = [];
        try {
            this.state.geoList.forEach(item => {
                //console.log("pod ",item)
                arr.push({ value: item, label: item });
            });
            //    console.log("pod ",arr)
            return arr;
        }
        catch (e) { }
    }

    changeGeoNameHandler = event => {
        console.log("event", event.value)
        this.setState({
            geoName: event.value
        })
    }

   
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    onRowSelection(event) {
        var containerslist = [];
        //console.log("rowselect", event)
        var rwCount = event.api.getSelectedNodes();
        // console.log("rwCount ", rwCount)
        //var containerlist=[];
        // console.log("rowselect", event.data)
        var rowCount = event.data
        //console.log("rowCount ", rowCount)

        rwCount.map((item) => {
            containerslist.push(item.data.truck_no)
        });

        this.setState({
            containerslist: containerslist
        })
        // console.log("checkedlist ", containerslist)

    }
    saveReplacedData = (event) => {
        event.preventDefault()
        var cnf = window.confirm("Are you sure you want to proceed?");
        if (cnf == true) {
            var selectedConsignments = this.gridApi.getSelectedNodes();

            if (googleAnalytics.page.enableGA) {
                let pageTitle = this.state.pagetitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": this.state.eventGridAction,
                    "label": "Save Replacement Data",
                }
                googleAnalytics.logEvent(eventOptions);
            }

            selectedConsignments = selectedConsignments.map(function (e) {
                return e.data
            })
            //console.log(selectedConsignments);
            // var rwCount = this.gridApi.();
            // console.log("rwCount ",rwCount)
            var old_truck_no = this.state.oldTruckNo;
            var truckData = []
            // if(rwCount.length > 0)
            // {
            this.gridApi.forEachNode((item) => {
                truckData.push(item.data);
            });
            // }
            var geo_name = this.state.geoName
            var replacement_truck = this.state.replacement_truck;
            replacement_truck = replacement_truck.replace(/\s/g, "");
            var replaced_date = document.getElementById("replaced_Date").value;
            //console.log("replaced_date ", replaced_date)
            if (this.state.reason.replace(/\s/g, "").length > 0) {
                if (replaced_date == "" || replaced_date == null || replaced_date == undefined || replaced_date == '__-__-____ __:__') {
                    this.setState({
                        show: true,
                        basicTitle: "Replacement Date is Not given",
                        basicType: "warning",
                    });
                }
                else {
                    if (truckData.length == 0 || selectedConsignments.length == 0) {
                        this.setState({
                            show: true,
                            basicTitle: "Please select atleast one checkbox item from grid.",
                            basicType: "warning",
                        });
                    }
                    else {
                        // console.log("replaced_date here ", selectedConsignments)
                        var rdt = replaced_date.split(" ");
                        var rd = rdt[0].split("-");
                        var rdate = rd[2] + "-" + rd[1] + "-" + rd[0] + " " + rdt[1];
                        var invoiceTime = truckData[0].invoice_time;
                        invoiceTime = moment.parseZone(invoiceTime).format('YYYY-MM-DD HH:mm:ss');
                        // console.log(invoiceTime,"invoiceTime")
                        rdate = moment.parseZone(new Date(rdate)).format("YYYY-MM-DD HH:mm:ss");
                        // console.log(rdate,"rdate");
                        // console.log("s1 ", rdate,invoiceTime)
                        var comparision = moment(rdate).isAfter(moment(invoiceTime));

                        if (this.state.showonlytransport == 'show-n') {
                            var usetrucks = 'y'
                        }
                        else {

                            usetrucks = 'n'
                        }
                        if (comparision == true) {
                            var reqParams = {
                                old_truck_no:old_truck_no,
                                truckData: truckData,
                                replacement_truck: replacement_truck,
                                replaced_date: rdate,
                                selectedConsignments: selectedConsignments,
                                dept_code: "SNDG",
                                usetrucks: usetrucks,
                                geo_name: geo_name,
                                transhipment_done_by: this.state.transhipment_done_by,
                                reason: this.state.reason,
                                user_email: localStorage.getItem("email"),
                                is_tvp: false
                            }
                            console.log("reqParams ", reqParams)
                            redirectURL.post("/dashboard/insertTruckWareHouseData", reqParams)
                                .then((response) => {
                                    // if(response.data.statusMessage == "success"){
                                    this.setState({
                                        show: true,
                                        basicTitle: "Replacement Success.",
                                        basicType: "success",
                                    });
                                    window.location.reload();
                                    // }
                                }).catch(function (error) {
                                    console.log(error);
                                });
                        } else {
                            this.setState({
                                show: true,
                                basicTitle: "Reason should not be empty",
                                basicType: "danger",
                            });
                        }
                    }
                }
            }
            else {
                this.setState({
                    show: true,
                    basicTitle: "Replacement Time should be greater than Invoice Time",
                    basicType: "danger",
                });
            }
        }

    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render() {
        const modalStyles = {
            width: '1300px !important',
        }

        const { usermanualmodal } = this.state;
        const columnwithDefs = [
            {
                headerName: "Invoice No",
                field: "invoice_no",
                pinned: 'left',
                width: 140,
                filter: true,
                resizable: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true
            },
            {
                headerName: "GR No",
                field: "consignment_code",
                // pinned: 'left',
                filter: true,
                resizable: true
            },
            {
                headerName: "Consignee Code",
                width: 140,
                field: "consignee_code",
                pinned: 'left',
                filter: true,
                resizable: true,
            },
            {
                headerName: "Consignment Status",
                width: 140,
                field: "status",
                pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.status == 2) {
                        return "In Transit";
                    }
                    else if (params.data.status == 3) {
                        return "Possible Reached Dealer";
                    }
                    else if (params.data.status == 4) {
                        return "Reached Dealer";
                    }
                    else if (params.data.status == 5) {
                        return "Left Dealer";
                    }
                    else {
                        return "N/A";
                    }
                }
            },
            {
                headerName: "Consignee Name",
                field: "consignee_name",
                pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.consignee_name == "") {
                        return "N/A";
                    }
                    else {
                        return params.data.consignee_name;
                    }
                }
            },
            {
                headerName: "Consignee City",
                field: "consignee_city",
                width: 140,
                pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.consignee_city == "") {
                        return "N/A";
                    }
                    else {
                        return params.data.consignee_city
                    }
                }
            },
            {
                headerName: "Gateout Time",
                field: "gate_out_time",
                width: 140,
                pinned: 'left',
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.gate_out_time != '' && params.data.gate_out_time != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    }
                    else {
                        return "NA";
                    }
                },

            },
            {
                headerName: "Invoice Time",
                field: "invoice_time",
                pinned: 'left',
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (params.data.invoice_time != '' && params.data.invoice_time != undefined) {
                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                    }
                    else {
                        return "NA";
                    }
                },
            },

        ]

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row col-xl-12 col-lg-12">

                    <h5>
                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Warehouse Transshipment
                            {/* {this.state.pagetitle}  */}
                        </span>

                    </h5>
                    <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                        <div className="form-group col-xl-3 col-lg-3">
                            <label>Select Truck No: </label>
                            <Select
                                placeholder={"Select Truck Number"}
                                closeMenuOnSelect={true}
                                onChange={this.getTruckConsignments.bind(this)}
                                className={"border-radius-0"}
                                style={{ borderRadius: "0px" }}
                                options={this.selectTruckNum()} />
                        </div>
                        <p style={{ fontFamily: "Roboto", color: "#c20000", fontSize: "15px", marginTop: "40px" }}>Note* : Ontrip TVP load trucks which are eligible for warehouse transshipments will only be shown.</p>
                    </form>
                </div>

                <div className="col-xl-12 col-lg-12">
                    {/* {this.state.rowData != null ? */}
                    <div id="myGrid" style={{ width: "82%", height: "360px" }} className="ag-theme-balham">
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnwithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={true}
                            enableRangeSelection={true}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            paginationPageSize={this.state.paginationPageSize}
                            pagination={true}
                            rowSelection={this.state.rowSelection}
                            stopEditingWhenGridLosesFocus={true}
                            gridOptions={{
                                context: { componentParent: this }
                            }}
                            onRowSelected={this.onRowSelection.bind(this)}
                            suppressRowClickSelection={true}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                        //floatingFilter={true}
                        // rowClassRules={this.state.rowClassRules}
                        // frameworkComponents={this.state.frameworkComponents}
                        // detailCellRendererParams={this.state.detailCellRendererParams}
                        //editType={this.state.editType}
                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                        //onCellClicked={this.onRowClicked.bind(this)}
                        />

                    </div>

                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0 mt-20p Transhipment-Edit-Row">
                        <form method="POST" className="row theme-form col-xl-12 col-lg-12" onSubmit={this.saveReplacedData}>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>Select Warehouse Name: </label>
                                <Select
                                    placeholder={"Select Warehouse"}
                                    closeMenuOnSelect={true}
                                    name="geoName"
                                    onChange={this.changeGeoNameHandler}
                                    className={"border-radius-0"}
                                    style={{ borderRadius: "0px" }}
                                    options={this.selectGeoName()} />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                <label className="">Replacement Truck</label>
                                <input type="text" class="form-control" name="replacement_truck" id="replacement_truck" onChange={this.changeHandler.bind(this)} autoComplete="off" Placeholder="Truck No." required />
                            </div>

                            <div className={"col-xl-2 col-lg-2 form-group "}>
                                <label className="replacement-time">Replacement Time</label>
                                {/* <Datetime value={this.state.replaced_date_time}
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true}
                                    className="transhipment-datetime"
                                    inputProps={{ placeholder: 'Replaced Date', name: 'replaced_Date_Time', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD"
                                    name="replaced_Date_Time"
                                    onChange={this.handlerReplacedDate.bind(this)}
                                        /> */}
                                <input type="text" autoComplete="off" class="form-control transhipment-datetime datetimepicker_mask" id="replaced_Date" required />
                            </div>
                            <div className={"col-xl-2 col-lg-2 form-group"}>
                                <label className="reason">Reason</label>
                                <input type="text" name="reason" value={this.state.reason} autoComplete="off" onChange={this.changeHandler.bind(this)} class="form-control" id="reason" />
                            </div>
                            <div className="col-xl-2 col-lg-2 form-group">
                                {/* <label className="replacement-time"><br /><br /></label> */}
                                <button type="submit" style={{ marginTop: "25px" }} className="btn btn-warning" id="replacement-submit">Submit</button>
                            </div>


                        </form>
                    </div>

                </div>

                <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                        <h5 className="model-title">Transhipment Screen</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                Transhipment Screen is used to change the primary carrier which has been physically changed by transporters to secondary vehicle because of various reasons like (but not limited to)
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        a. Accident
                                    </li>
                                    <li>
                                        b. Breakdowns
                                    </li>
                                    <li>
                                        c. Geographical Issues
                                    </li>
                                    <li>
                                        d. Administrative issues (caught by local police, transport authorities)
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <h6 className="f16">How to Use:</h6>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <ul>
                                    <li>
                                        a. Select the vehicle no. which you want to replace with new one.
                                    </li>
                                    <li>
                                        b. Check all the consignments from the list provided which are currently in active mode on vehicle and needs to be transhipped

                                    </li>
                                    <li>
                                        c. Check Gateout time, Invoice time, Consignment code, Consigner code &Consignee detail
                                    </li>
                                    <li>
                                        d. Fill the Replacement Truck no. , Replacement time with Proper Reason
                                    </li>
                                    <li>
                                        e. Click on the submit Button to complete the process of Transhipment in system
                                    </li>

                                </ul>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                <h6 className="f16">Consignment details:</h6>
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                All the data of consignment in Transhipment screen we can arrange it as per our requirement by using “columns” option. Some important column inside are – Consignment code, Invoice Time, Gateout Time & Consignee detail(Code, Name, City) Also we can filterout each of these column.
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
