import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import '../../../../node_modules/react-grid-layout/css/styles.css';
// import '../../../../node_modules/react-resizable/css/styles.css';

import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';
 
import axios from 'axios';
import Autosuggest from 'react-autosuggest';

import Breadcrumb from '../common/breadcrumb.component';
import GpsIntegrationCounters from './tptgpsintegrationscounters';

import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import redirectURL from '../redirectURL';
import ComponentIndex from '../common/appendComponents';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import trucklocaction from '../layouts/trucklocaction';
import TPTtrucklocaction from '../layouts/tpttrucklocaction';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 
import $ from "jquery";
import * as Datetime from 'react-datetime';
import ExportPDF from "./exportpdf"
import ChallansColumnChart from "./challansColumnChart";
import ChallansStateWiseChart from "./challansStateWiseChart";
import { string } from 'prop-types';
import Mapview from "./heatmapview";
import StoppageMapview from "./heatmapstoppage";

import WeatherMapComponent from "./tptweathermap";



var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
const ResponsiveReactGridLayout = WidthProvider(Responsive);
var map;
var viewMarkersArr=[];
var moment = require('moment'); 
var googleAnalytics = require("../common/googleanalytics");
var infoBox = require('../common/google-infowindow');


export default class  stockyardTransportersDashboard extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			totalTrucks: [],
			uniqueActiveConsignments: null,

			totalActiveConsignments: null,
			gpsNotTodayActiveConsignments: [],
			totalActiveConsignmentsCount: 0,
			gpsNotTodayActiveConsignmentsCount: 0,
			currentDepartmentTitle: null,
			showdata : this.displayData,
			truckInfo:'',
			consignments:'',
			sndshow:'show-n',
			departmentchrtshow:'show-m',
			currenttrucklocation:'',
			truckoperates:0,
			truckgpslifetime:0,
			lspInfo:0,
			deptServes:0,
			transrecordserves:0,
			gpssendtoday:0,
			nopetransporters:0,
			gpstoday:0,
        	alldepts:[],
        	//deptvalue:'all',
        	deptvalue:null,
			sliderTranslate:'',
        	truckscnt:0,
        	trucklocations:0,
        	notrans:0,
        	trucktoday:0,
        	trucknogps:0,
            data: "grouped",
            legendPosition: "top-left",
            interpolate: "linear",
            xLabel: "M",
            yLabel: "GGGGGGGGGG",
            hidePoints: false,  
            hideLines: false,
            yMin: null,
            yMax: null,
            xMax: null,
            xMin: null,
            loadsData:[], 
            transportschart:[],
            transaxis:[],
			transyaxis:[],
			trans_no_gps_axis:[],
			trans_no_gps_yaxis:[],
            deptaxis:[],
            deptyaxis:[],
            lspaxis:[],
            lspyaxis:[],
            lsp_no_gps_axis:[],
            lsp_no_gps_yaxis:[],
            gpsintervalaxis:[],
            gpsintervalyaxis:[],
            modules: AllModules,
  	      open: false,
  	      filterstatess:{},
  	      columnsdatas:{},
  	      rowgroupdcols:[],
  	      valuecolumns:[],
  	      pivotmodeis:false,
			usergridstate1: [],
  	      pivotcols:[],
			show: false,
			type:"",
			title:"",
  	      
  	      defaultColDef: {
  	        sortable: true,
			  filter: true,
			  resizable: true

			},
			frameworkComponents: {
			dateFormater:DateFormater,
			trucklocaction:trucklocaction,
			tpttrucklocaction:TPTtrucklocaction,
		},

  	      rowData: [],
			// pdfRowData: [],
            // pdfColumns: [],
  	      rowwithoutData: [],
			rowgpspacketData:null,
  	      rowModelhighchartoptionsType: 'enterprise',
  	      rowGroupPanelShow: 'always',
  	      animateRows: true,
  	      debug: true,
  	      showToolPanel: true,
  	      suppressAggFuncInHeader: true,
			statusBar: {
				statusPanels: [
				  {
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
				  },
				  {
					statusPanel: "agTotalRowCountComponent",
					align: "center"
				  },
				  { statusPanel: "agFilteredRowCountComponent" },
				  { statusPanel: "agSelectedRowCountComponent" },
				  { statusPanel: "agAggregationComponent" }
				]
			  },
			  sideBar: {
				toolPanels: [
				  {
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
				  },
				  {
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
				  }
				]
			  },
			  usergridstate:[],
			  screenurl:"",
			  screentitle:"",
			  pagetitle:"",
			  eventGridAction: "gridAction",  
			  defaulttab:"all",
			  loadshow:"show-n" ,
			  overly:"show-n",
			  ontripwithgps:0,
			  ontripwithgpsdata:[],
			  ontripgpsna:0,
			  trucksWithGpsLocation:[],
			  atdealertruckcnt:0,
			  atdealernogps:[],
			  alldealertrucks:[],
			  rowdataatdealer:[],
			  primaryontrips:[],
			  secondaryontrips:[],  
			  usermanualmodal: false,
			  mapData:[],
			  sliderRouteTranslate:'',
			  timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			addressColumn: 300,
            bayDashboardData:[],
            bayDashboardCategories:[],
            transportersData:[],
            transportersDataCategories:[],
            modelDashboardData:[],
            modelDashboardCategories:[],
            occupied_data:[],
            vacant_data:[],
            total_bays:[],
			stateData:[],
			stateDataCategories:[]
		};
	}
	componentDidMount = async () => {
       
			await this.formatBayInformationChartData();
			await this.formatTransportersChartData();
            // await this.formatModelDashboardChartData();
			// await this.formatStatesChartData();
		// // })



	}

    formatBayInformationChartData()
    {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
		var currentUrl = window.location.href;
		currentUrl = currentUrl.split("/");
		var lastItem = currentUrl.reverse()[0];
		console.log(lastItem,"this.props.match.params.")
		if(lastItem == 1)
		{
			var params = {
				stockyardemailid:"stockyarddummyuser1@entrac.in"
			}
		}
		else
		{
			var params = {
				stockyardemailid:localStorage.getItem("email")
			}
		}
		if(![undefined , "undefined" , "" , null].includes(localStorage.getItem("transportercode"))){
			params.transporter = localStorage.getItem("transportercode")
		}
        redirectURL.post("/consignments/parkingcelldata",params).then((response)=>{
			console.log(response.data,"parkingcelldata_260")
            var total_bays = response.data.data.length
            // console.log(total_bays,"bayInformation")
            var occupied_data = response.data.occupieddata;
            // console.log(occupied_data.length,"occupieddata")
            var vacant_data = total_bays-occupied_data.length;
            // console.log(vacant_data.length,"vacantdata")
            var categories = ["Total Bays","Occupied Bays","Vacant Bays"];

            var seriesList = [{"name":"Total Bays",y:total_bays,"title":total_bays},{"name":"Occupied Bays",y:occupied_data.length,"title":occupied_data.length},{"name":"Vacant Bays",y:vacant_data,"title":vacant_data}];
            // console.log(seriesList,"seriesList")
            this.setState({
                total_bays: total_bays,
                occupied_data:occupied_data,
                vacant_data:vacant_data,
                bayDashboardData: [{"name":"Bay Dashboard", data : seriesList}],
                bayDashboardCategories: categories,
                loadshow: "show-n",
                overly: "show-n"
            })
        })
        
        
    }

    formatTransportersChartData()
    {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
		var currentUrl = window.location.href;
		currentUrl = currentUrl.split("/");
		var lastItem = currentUrl.reverse()[0];
		console.log(lastItem,"this.props.match.params.")
		if(lastItem == 1)
		{
			var params = {
				stockyardemailid:"stockyarddummyuser1@entrac.in"
			}
		}
		else
		{
			var params = {
				stockyardemailid:localStorage.getItem("email")
			}
		}
		if(![undefined , "undefined" , "" , null].includes(localStorage.getItem("transportercode"))){
			params.transporter = localStorage.getItem("transportercode")
		}
        redirectURL.post("/consignments/bayinformationdata",params)
        .then((response)=>{
            var records = response.data.data;
            console.log("bayinformationdata_310", response.data)
            var transporter = groupBy(records, rdata => rdata.transporter_name);
            // console.log("transporter ", transporter)
            var seriesList = []
            var categories=[]
            // console.log("records ", records.length)
            // if(records.length > 0)
            // {
                transporter.forEach((monthData, monthKey) => {
                    // console.log("item ", item)
                    seriesList.push({name:monthKey,y: monthData.length,"title":monthData.length})
                    categories.push(monthKey);
                })
                // console.log("categories ", categories)
                // console.log("seriesList ", seriesList)
            // }
            this.setState({
                transportersData: {"name": "Transporter Dashboard", data: seriesList},
                transportersDataCategories: categories,
                loadshow: "show-n",
                overly: "show-n"
            })
            // console.log("transportersData ", this.state.transportersData)
            // console.log("transportersDataCategories ", this.state.transportersDataCategories)
            
            
        })
        
        
    }

    // formatModelDashboardChartData()
    // {
    //     this.setState({
    //         loadshow: "show-m",
    //         overly: "show-m"
    //     })
        
           
    //     redirectURL.post("/consignments/bayinformationdata")
    //     .then((response)=>{
    //         var records = response.data.data;
    //         // console.log("records ", records)
    //         var carname = groupBy(records, rdata => rdata.car_name);
    //         // console.log("carname ", carname)
    //         var seriesList = []
    //         var categories=[]
    //         // console.log("records ", records.length)
    //         // if(records.length > 0)
    //         // {
    //             carname.forEach((monthData, monthKey) => {
    //                 // console.log("item ", item)
    //                 seriesList.push({name:monthKey,y: monthData.length,"title":monthData.length})
    //                 categories.push(monthKey);
    //             })
    //             // console.log("categories ", categories)
    //             // console.log("seriesList ", seriesList)
    //         // }
    //         this.setState({
    //             modelDashboardData: {"name": "Model Dashboard", data: seriesList},
    //             modelDashboardCategories: categories,
    //             loadshow: "show-n",
    //             overly: "show-n"
    //         })
    //         // console.log("modelDashboardData ", this.state.modelDashboardData)
    //         // console.log("modelDashboardCategories ", this.state.modelDashboardCategories)
            
            
    //     }) 
    // }
	// formatStatesChartData()
    // {
    //     this.setState({
    //         loadshow: "show-m",
    //         overly: "show-m"
    //     })
    //     redirectURL.post("/consignments/bayinformationdata")
    //     .then((response)=>{
    //         var records = response.data.data;
    //         console.log("records ", records)
    //         var statedata = groupBy(records, rdata => rdata.oem_plant);
    //         console.log("transporter ", statedata)
    //         var seriesList = []
    //         var categories=[]
    //         // console.log("records ", records.length)
    //         // if(records.length > 0)
    //         // {
    //             statedata.forEach((monthData, monthKey) => {
    //                 // console.log("item ", item)
    //                 seriesList.push({name:monthKey,y: monthData.length,"title":monthData.length})
    //                 categories.push(monthKey);
    //             })
    //             // console.log("categories ", categories)
    //             // console.log("seriesList ", seriesList)
    //         // }
    //         this.setState({
    //             stateData: {"name": "OEM Plant Dashboard", data: seriesList},
    //             stateDataCategories: categories,
    //             loadshow: "show-n",
    //             overly: "show-n"
    //         })
    //         // console.log("transportersData ", this.state.transportersData)
    //         // console.log("transportersDataCategories ", this.state.transportersDataCategories)
            
            
    //     })
        
        
    // }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + "/trucksgpsintegrationdata";
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + "/trucksgpsintegrationdata";
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + "/trucksgpsintegrationdata";
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }


	formHandler = async (event) => {
        
    }

    render(){
		
    	rendJsonData(this.state.loadsData);
		
        //console.log("Default",localStorage.getItem('token'));
        const dStyles={
				width:'100%',
				height:'100%'
		}
		const highchartoptions = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.transaxis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"With GPS",
	      		      data: this.state.transyaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const transporterNoGPSChart = {
	      		  chart: { 
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
					  categories: this.state.trans_no_gps_axis
				   },
				   plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: false
						},
						colorByPoint:true

					},
					},
	      		  series: [
	      		    {
					  name:"Without GPS",
	      		      data: this.state.trans_no_gps_yaxis
					  }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };

        const lsphighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lspaxis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lspyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  
		const lspNoGPShighchartoptions = {
	      		  chart: {
	      		    type: 'column'
	      		  },
	      		  plotOptions: {
		      	      column: {
		      	          colorByPoint: true
		      	      },
		      	      series: {
		      	    	maxPointWidth: 20
		              }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.lsp_no_gps_axis
				   },
	      		  series: [
	      		    {
	      		      data: this.state.lsp_no_gps_yaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: false
	                        }
	                    }
	                }]
	      		  }
				  };
				  				  
        const highchartoptions1 = {
	      		  chart: {
	      		    type: 'pie'
	      		  },
	      		  plotOptions: {
	      			pie: {
	    		        allowPointSelect: true,
	    		        cursor: 'pointer',
	    		        showInLegend: true,
	    		        dataLabels: {
	    		          enabled: true,
	    		          format: '{point.x:,.0f}'			    		          
	    		        }
	    		      }
	      		  },
	      		  title:{
	      			  text:''
	      		  },
	      		xAxis: {
	      	        categories: this.state.deptaxis
	      	     },
	      	     legend: {
		    		  enabled: true,
		    		  labelFormatter: function() {

							//console.log("test",this.series)

			    		    var legendIndex = this.index;
			    		    var legendName = this.series.chart.axes[0].categories[legendIndex];

			    		    return legendName;
			    		  }
		    		},
	      		  series: [
	      		    {
	      		      data: this.state.deptyaxis
	      		     //data: [64,21,33,42,52]
	      		    }
	      		  ],
	      		colors: ['#f15c80', '#92A8CD','#A47D7C', '#B5CA92','#2b908f', '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce'],
	                credits:false,
	      		  responsive: {
	                rules: [{
	                    condition: {
	                        maxWidth: '100%'
	                    },
	                    chartOptions: {
	                        chart: {
	                            height: '100%'
	                        },
	                        subtitle: {
	                            text: null
	                        },
	                        navigator: {
	                            enabled: true
	                        }
	                    }
	                }]
	      		  }
	      		};
        			  
			const highchartgpsinterval = {
				chart: {
					type: 'column'
				},
				title: {
					text: ' '
				},
				xAxis: {
					categories: this.state.gpsintervalaxis
				},
				yAxis: {
					min: 0,
					
					stackLabels: {
						enabled: true,
						style: {
							fontWeight: 'bold',
							color: ( // theme
								Highcharts.defaultOptions.title.style &&
								Highcharts.defaultOptions.title.style.color
							) || 'gray'
						}
					}
				},
				legend: {
					align: 'right',
					x: -30,
					verticalAlign: 'top',
					y: 25,
					floating: true,
					backgroundColor:
						Highcharts.defaultOptions.legend.backgroundColor || 'white',
					borderColor: '#CCC',
					borderWidth: 1,
					shadow: false
				},
				tooltip: {
					headerFormat: '<b>{point.x}</b><br/>',
					pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
				},
				plotOptions: {
					column: {
						stacking: 'normal',
						dataLabels: {
							enabled: true
						}
					}
				},
				series: this.state.gpsintervalyaxis
			};
            // var userroles = localStorage.getItem("roles");
			// var israil = (userroles.indexOf("tpt_rail") > 0)?1:0

		const {usermanualmodal} = this.state;
		const modalStyles  = {
		width:'1300px !important',
		}


        return (
            <div className="col-sm-12 white-bg">
            <div className="col-sm-12 row">
                    <div className='col-xl-6 col-lg-6 col-md-6'>
                        
                        <ChallansColumnChart 
                        title={"Bay Dashboard"}
                        subtitle={""}
                        context={this}
                        series={this.state.bayDashboardData}
                        categories={this.state.bayDashboardCategories}
                        yaxistitle={"No of Bays"}
                        />
                        <br></br>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6'>
                        
                        <ChallansStateWiseChart 
                        title={"Transporter Dashboard"}
                        subtitle={""}
                        context={this}
                        series={this.state.transportersData}
                        categories={this.state.transportersDataCategories}
						yaxistitle={"No of Logistics"}
						height={""}
                        />
                    </div>
                    {/* <div className='col-xl-12 col-lg-6 col-md-6'>
                        
                        <ChallansColumnChart 
                        title={"Model Dashboard"}
                        subtitle={""}
                        context={this}
                        series={this.state.modelDashboardData}
                        categories={this.state.modelDashboardCategories}
                        yaxistitle={"No of Cars"}
                        />
                    </div> */}
					{/* <div className='col-xl-6 col-lg-6 col-md-6'>
                        
                        <ChallansColumnChart 
                        title={"OEM Plant Dashboard"}
                        subtitle={""}
                        context={this}
                        series={this.state.stateData}
                        categories={this.state.stateDataCategories}
                        yaxistitle={"No of OEM's"}
                        />
                    </div> */}
                </div>
                                    
            </div>
        );
    }
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}       

	function rendJsonData(data)
	{
		//console.log(data);
		var order_cnt=[];
		order_cnt=data.map( (seris) => 
			seris.key
		)
		//console.log(order_cnt)
	}

	function dateComparator(date1, date2) {
		// console.log("dateComparator1");
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		if (date1Number === null && date2Number === null) {
		  return 0;
		}
		if (date1Number === null) {
		  return -1;
		}
		if (date2Number === null) {
		  return 1;
		}
		return date1Number - date2Number;
	  }
	function monthToComparableNumber(date) {
		if (date === undefined || date === null || date.length !== 19) {
			return null;
		}
		var yearNumber = date.substring(6, 10);
		var monthNumber = date.substring(3, 5);
		var dayNumber = date.substring(0, 2);
		var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
		return result;
	}
	function loadScript(url){
		var index  =window.document.getElementsByTagName("script")[0]
		var script = window.document.createElement("script")
		script.src=url
		script.async=true
		script.defer = true
		index.parentNode.insertBefore(script,index)
	}
	function initOMS(){
		// Initializing OMS
		oms = new oms.OverlappingMarkerSpiderfier(map, {
		  //markersWontMove: true,
		  //keepSpiderfied: true,
		  //nearbyDistance: 10,
		  //circleFootSeparation: 60,
		  //legWeight: 1.5
		  markersWontMove: true,
		markersWontHide: true,
		keepSpiderfied: true,
		basicFormatEvents: true
		});
	  }
	  function timeConvert(n) {
		  var num = n;
		  var hours = (num / (3600));
		  var rhours = parseInt(hours);
		  var minutes = (num-(rhours*3600)) / (60);
		  var rminutes = Math.round(minutes);
		  return rhours + " hour(s) and " + rminutes + " minute(s).";
	  }   
	
	function ConvertMinutes(num){
		var d = Math.floor(num/1440); // 60*24
		var h = Math.floor((num-(d*1440))/60);
		var m = Math.round(num%60);
		return d+" Day(s) "+h+" Hrs "+m+" mins"
	}
	
	function ConvertSeconds(totalSeconds)
	{
		// var days = Math.floor(num/86400);
		// var hours = Math.floor(num/3600)
		// var minutes = Math.floor(num/60);
		var minutes = Math.round((totalSeconds % 3600) / 60);
		var hours = Math.round((totalSeconds % 86400) / 3600);
		var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
		// let d = (new Date(t0)) - (new Date(t1));
		// let weekdays     = Math.floor(d/1000/60/60/24/7);
		// let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
		// let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
		// let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
		return days+" Day(s) "+hours+" Hrs "+minutes+" mins"
	}
	function ConvertHHMMSeconds(n)
	{
		var days =Math.round(parseInt( n / (24 * 3600)));
	
		n = n % (24 * 3600);
		var hours = Math.round(parseInt(n / 3600));
	
		n %= 3600;
		var minutes = Math.round(n / 60);
	
		n %= 60;
		var seconds = Math.round(n);
	
		return days+" Day(s) "+hours+" Hrs "+minutes+" mins "+ seconds+" secs"
	}
	function secondsToDhms(seconds) {
		seconds = Number(seconds);
		var d = Math.floor(seconds / (3600*24));
		var h = Math.floor(seconds % (3600*24) / 3600);
		var m = Math.floor(seconds % 3600 / 60);
		var s = Math.floor(seconds % 60);
		
		var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
		var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
		var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
		var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
		return dDisplay + hDisplay + mDisplay;
	}
	function secondsToHM(seconds) {
		seconds = Number(seconds);
		// var d = Math.floor(seconds / (3600*24));
		// var h = Math.floor(seconds % (3600*24) / 3600);
		// var m = Math.floor(seconds % 3600 / 60);
		// var s = Math.floor(seconds % 60);
		
		// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
		// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
		// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
		// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
		// return dDisplay + hDisplay + mDisplay;
		
		var hh = Math.floor((seconds / 3600));
		var hrr = Math.floor(seconds - (hh*3600));
		var mm = Math.floor(hrr/60)
		if(mm < 10)
		{
			var mins = "0"+mm;
		}
		else
		{
			var mins = mm;
		}

		if(hh < 10)
		{
			var hrs = "0"+hh;
		}
		else
		{
			var hrs = hh;
		}
		return hrs+":"+mins;
	}
	function convertMeters(metersValue)
	{
		return (metersValue/1000).toFixed(2);
	}


    function GetSortDescOrder(prop) {    
        return function(a, b) {    
            if (a[prop] < b[prop]) {    
                return 1;    
            } else if (a[prop] > b[prop]) {    
                return -1;    
            }    
            return 0;    
        }    
    } 
    function groupBy(list, keyGetter) {

        const map = new Map();
    
        list.forEach((item) => {
    
             const key = keyGetter(item);
    
             const collection = map.get(key);
    
             if (!collection) {
    
                 map.set(key, [item]);
    
             } else {
    
                 collection.push(item);
    
             }
    
        });
    
        return map;
    
    }