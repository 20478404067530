import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import Select from 'react-select';
import DeleteItem from './deleteitem';
// import EditroleAction from './editroleAction';
import Select from 'react-select';
import $ from 'jquery';
import MapsLocalBar from 'material-ui/svg-icons/maps/local-bar';
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class HollandTrips extends Component{
    constructor(props){
        super(props);
        this.state={
            show : false,
            rowSelection:'multiple',
            rowData: [],
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            basicTitle: "",
            deptCode: "",
            items: [],
            source: [],
            destination: [],
            deptCodeValue: [],
            operationsvalue : [],
            fetchItemsOptions: [],
            fetchSourceOptions: [],
            fetchDestinationOptions: [],
            operationValue : [],
            overly:"show-n",
            loadshow:"show-n",
            bulkslide:"",
            frameworkComponents: {
				// updateItem:UpdateItem,
                deleteItem:DeleteItem,
               // EditroleAction:EditroleAction,
				//statusChange:StatusChange
			},
            fetchDeptCodeOptions: [],
            paginationPageSize:50,
            defaultColDef: {
              sortable: true,
              filter: true,
              resizable: true,
              pivot : true,
              enableValue: true,
              enableRowGroup: true,
            },
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
            basicType:"default",
            operationsData: [],
            filteredoperations :[],
            rowId : "",
            sliderTitleName:"Add Item",
            operation : "",
            truck_no : {value:"",label:"Select Truck"},
            consignment_code : '',
            trucks:[]

        }

    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount(){
        this.logPageView();
        this.getHollandTrItems(); 
        var tparms = {
            transporter_code:localStorage.getItem('transportercode')
        }
        redirectURL.post("/dashboard/tpttrucks",tparms)
        .then((response) => {
            var recordsarr = response.data.records;
            console.log("records", recordsarr);
            var trucks=[]
            if(recordsarr.length > 0)
            {
                recordsarr.map((item) => {
                    trucks.push({value:item.truck_no,label:item.truck_no})
                })
            }
            this.setState({
                trucks:trucks
            })
        })
    }

    getHollandTrItems = () => {
        var data = []
        var tptCode = localStorage.getItem('transportercode');
        let tcode = JSON.parse(tptCode)
        let query = {
            transporter_code : tcode
        }
        redirectURL.post("/dashboard/getHollandTripsData",query).then((response)=>{
            data = response.data
            console.log("data",data)
            // redirectURL.post('/dashboard/gettrucksforholland',query).then((response)=>{
            //     let trucksData = response.data
            //     console.log("trucksData",trucksData)                
            // })
            this.setState({
                rowData: data,
            })
        })     
    }
    
    // changeHandler = event => {
    //     // console.log("event", event)    
    //     this.setState({items: event});
	// }

     // changeDestinationHandler = event => {
    //     this.setState({destination: event});
	// }

    formHandler = (event) => {
        // const {items, deptCodeValue,operationsvalue,rowId} = this.state
        event.preventDefault();
        // var letterNumber = /^[0-9a-zA-Z]+$/
        // var userName = localStorage.getItem("username")
        if(this.state.truck_no.value != "")
        {
            var truck = this.state.truck_no.value;
        }
        else
        {
            var truck = "";
        }
        // var truck_no =this.state.truck_no.value;
        var consignment_code = $("#consignmentNo").val();
        var tptCode = localStorage.getItem('transportercode');
        let tcode = JSON.parse(tptCode)
       
        var reqparam = {
            'truck_no' : truck,
            'consignment_code' : consignment_code,
            'transporter_code' : tcode       
        }
        console.log("reqparams", reqparam)
        if (truck !== undefined && truck !== "" && consignment_code !== undefined && consignment_code !== ''){
           // if(truck_no && consignment_code){
                redirectURL.post("/dashboard/addhollandtrucksdata",reqparam).then((response)=>{
                    if (response.data.status === "Data Already Exists"){
                        this.setState({
                            basicType:"warning",
                            basicTitle : "Data Already Exists",
                            show: true
                        })
                    // this.getHollandTrItems();
                    }else if (response.data.status === "success"){
                        $("#truckNo").val("")
                        $("#consignmentNo").val("");
                        this.setState({
                            basicType:"success",
                            basicTitle : "Successfully Added",
                            show : true,
                            deptCode: "",
                            items: "",
                            source: "",
                            destination: "",
                            overly:"show-n",
                            loadshow:"show-n",
                            bulkslide : "",
                        });
                        this.getHollandTrItems();
                    }else{
                        this.setState({
                            basicType:"danger",
                            basicTitle : "Failed to Add",
                            show : true,
                        })
                    }
                })
            // }else{
            //     this.setState({
            //         basicType:"warning",
            //         basicTitle : "Please enter values",
            //         show: true
            //     })               
            // }        
        }else{
            this.setState({
                basicType:"warning",
                basicTitle : "fields should not be empty",
                show : true,                
            });
        }
    }

    onCellRowClicked =e =>{
        if(e.colDef.field === "update"){
            // var rowId = e.data._id;
            console.log("e", e)
        }
    }
    // onShowEditMenu(params){
    //     var query = {
    //         "_id" : params.data._id
    //     }
    //     console.log(this.state.operationsData, "operationsData")
    //     redirectURL.post("/consignments/gettptuseritems",query).then((response)=>{
    //         // console.log("responseId", response.data[0])
    //         $("#add_destination").val(response.data[0].destination)
    //         $("#add_source").val(response.data[0].source);

    //         this.setState({
    //             items: {"value":response.data[0].items,"label":response.data[0].items},
    //             deptCodeValue: {"value":response.data[0].dept_code,"label":response.data[0].dept_code} ,
    //             rowId:params.data._id,
    //             sliderTitleName: "Edit User Item",
    //             bulkslide:'slider-translate',
    //             overly:'show-m',
    //         })
    //         // console.log("stateitems", this.state.items, typeof(this.state.items))
    //     }).catch((e)=>{
    //         console.log(e);
    //     })
    // }

    deleteRowHandler = (params) =>{
        let rowData = this.state.rowData
        const rowFiltered = rowData.filter(m => m._id !== params.data._id);
        this.setState({ rowData :  rowFiltered});
        // console.log("rowf", rowFiltered)
        let par = {
            _id: params.data._id
        }
        console.log("delparams",params, params._id)
        let cnf = window.confirm("Are you sure you want to Delete?")
            if(cnf === true){
                redirectURL.post("/dashboard/deleteHollandTruckItem",par).then((response) =>{
                    let data = response.data
                    // console.log("dataresponse", data)
                    if (data.status === "success"){
                        this.setState({
                            basicType:"success",
                            basicTitle : "Successfully Deleted",
                            show : true,                    
                            // overly:"show-n",
                            // loadshow:"show-n", 
                        })
                    }else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
        
                })
            }

    }
    changeScreen = () =>{
        this.setState({
            // sliderTitleName: "Add User Item",
            overly:"show-m",
            loadshow:"show-m",
            bulkslide : "slider-translate-30p",            
        })
	}

    previousscreen = () =>{
        this.setState({
            overly:"show-n",
            loadshow:"show-n",
            bulkslide : "",             
        })
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
	}

    onClickHideAll(){
        $("#truckNo").val("");
        $("#consignmentNo").val("");
        this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide:"",
        items: [],
        source: [],
        destination: [],
        deptCodeValue: [],    
        });        
    }

    onGridReady = params => {
		this.gridApi = params.api;		
	    console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;		   
	};
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
    }
    onChangeTruck = (truck_no) =>{
        this.setState({truck_no})
    }

    render(){

        const columnwithDefs= [
            
            // {
            //     pinned:"left",
            //     headerName : "Edit",
            //     field:"_id",
            //     width:100,
            //     resizable : true,
            //     editable : false,
            //     cellRendererSelector:function(params){       
            //         var rendComponent = {
            //             component: 'EditroleAction'
            //         };
            //         return rendComponent;
            //     },
            // },
            {
                headerName: "Action",
                field: "_id",
                width: 100,
                filter: false,
                cellRenderer:'deleteItem',
                editable:false
            },
            {
                headerName: "View",
                field: "_id",                
                filter: true,
                width:100,
                hide: true,               
            },
              {
                headerName: "Truck No",
                field: "truck_no",                
                filter: true,
                editable: true,
                width:250,
                resizable: true,               
            },
            {
                headerName: "Consignment Code",
                field: "consignment_code",                
                filter: true,
                editable: true,
                width:250,
                resizable: true,               
            },
            
           
        ]
        return(
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                    >
	             </SweetAlert>
                 {this.state.alert}
                 <div className="row">
                 <div className=" col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header space-between">
                             <h5 className="float-left">
                                <i className="icon-map cus-i"></i><span> Holland Trips</span>
                             </h5>
                            <div>
                             <button className="btn btn-success pull-right cs-btn" id="addUserItem" onClick={this.changeScreen.bind(this)} style={{color:"white"}}> <i className="icon-plus"></i>Add Holland Trip</button>
                                <button className="btn btn-warning" id="previous-screen" onClick={this.previousscreen.bind(this)} style={{display:"none",padding: "9px",float: "right",width: "87px"}}><i className="fa fa-arrow-left"></i> Back</button>
                            </div>                        
                        </div>
                        <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                            <h3 className="subH">Add Holland Trip </h3>
                            <div className="slide-r-body" style={{position:"relative"}}>             
                                <form className=" theme-form col-xl-8 col-lg-8" onSubmit={this.formHandler}>
	                    			<div className="col-xl-12 col-lg-12">
                                        <div className="form-group">
					               			<label className="c-lbl">Truck No:</label>		               			
                                               {/* <input type="text" name="truckNo" id="truckNo" className="form-control"
                                               //value={this.state.truck_no}   //onChange={this.changeHandler}
                                               required/> */}
                                               <Select 
                                                    placeholder={"Truck"}
                                                    closeMenuOnSelect={true}
                                                    onChange={this.onChangeTruck.bind(this)}
                                                    // className={"col-xl-12 col-lg-12 border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    value={this.state.truck_no}
                                                    className="basic-multi-select"                                            
                                                    options={this.state.trucks} />
					               	   </div>                                       
                           

                                          <div className="form-group">
					               			<label className="c-lbl">Consignment No:</label>		               			
                                               <input type="text" name="consignmentNo" id="consignmentNo" className="form-control"
                                               // value={this.state.consignment_code}   //onChange={this.changeHandler}
                                               required/>
					               	   </div>                       
										<div className="form-group">
					               			<button type="submit" className="btn btn-success cs-btn" id="saveevent">Save</button>
					               		</div>
									</div>									
				            	</form>                      
                            </div>
                        </div>
                        <div id="tptUserItemGrid" className="card-body col-sm-11">                        
									<div id="myGrid" style={{ height: "580px",width: "100%",}}  className="ag-theme-balham">
										<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										detailCellRendererParams={this.state.detailCellRendererParams}
										stopEditingWhenGridLosesFocus={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										gridOptions={{
                                            context: { componentParent: this }
										}}
										masterDetail={true}
										onCellClicked={this.onCellRowClicked}			
										suppressRowClickSelection={true}
                                        components={this.state.components}
										rowSelection={this.state.rowSelection}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                        //onCellClicked={this.onRowClicked.bind(this)}
										//getRowHeight={this.state.getRowHeight}
										// onCellEditingStopped={this.onCellUpdateData.bind(this)}
										// onRowSelected={this.onRowSelection.bind(this)}
										// suppressCellSelection= {true}
										// overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}										
										/>
									</div>								
                        </div>
                       
                     </div>
                 </div>
                 </div>
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
           
        )
    }

}