import React, { Component, Fragment } from "react";
import {    Row,    Col,} from "reactstrap";
//  import {    X,    CheckSquare,Download,Users    } from "react-feather";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';
// import $ from 'jquery';
import { Alert } from "reactstrap";
// import { FoldedContentConsumer } from "../../utility/context/toggleContentContext";
// import CSVFileValidator from 'csv-file-validator';

// import PerfectScrollbar from "react-perfect-scrollbar";

var redirectURL = require('../redirectURL');

export default class OemApiTab extends Component {
    constructor(props){
        super(props);
        this.state={
            modules:AllModules,
            pageTitle:"OEM Api Configurations",
            defaultColDef: {
                width: 80,
                sortable: true,
                filter:true,
                resizable:true,
                wrapText: true,
                autoHeight: true,
            },
            rowData: [],
      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
            rowHeight: 90,  
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            detailCellRendererParams:{},
            successMsg:"",
            customizer: false,
            transporters: [],
            columnDefs:[],
           
        }
        this.onLoadPageData = this.onLoadPageData.bind(this);
        this.createColumns = this.createColumns.bind(this);
        this.createrows = this.createrows.bind(this);
        // this.toggleCustomizer = this.toggleCustomizer.bind(this);
    }

    componentDidMount(){
		this.onLoadPageData()
    }

    onLoadPageData()
    {
        // var orgId=localStorage.getItem('org_id');
        var params = {
            // org_id : JSON.parse(orgId),
        }
        redirectURL.post("/dashboard/apioemlist",params)
        .then((response) => {
            var records = response.data.records;
            console.log("Oemrecords",records)
            var transportercode = JSON.parse(localStorage.getItem("transportercode"));
            
            var recordsarr=[]
            if(records.length > 0)
            {
                records.map((item) => {
                    if(item.transporters != undefined)
                    {
                        if(item.transporters.indexOf(transportercode[0]) > 0)
                        {
                            item["f_"+transportercode[0]] = "Yes";
                        }
                        else
                        {
                            item["f_"+transportercode[0]] = "No";
                        }
                    }
                    else{
                        item["f_"+transportercode[0]] = "No";
                    }
                    
                    recordsarr.push(item)
                })
            }
            // console.log(recordsarr)
            this.setState({
				rowData:recordsarr
            })
        })

        // redirectURL.post("/dashboard/getTransporters",params)
        // .then((response) => {
        //     var records = response.data.records;
        //     console.log("transporter srecords",records)
        //     this.setState({
        //         transporters:records,
        //         // loadshow:'show-n',
        //         // overly:'show-n',
        //     })
        // })
       

        setTimeout(async () => {

            await this.createColumns();
           // await this.createrows();
        },1000)        
      
    }

    createColumns(){
        var transportercode = JSON.parse(localStorage.getItem("transportercode")); 
        var columnDefs = [              
            {
                headerName: 'Oem Code',
                field: 'oem_code',
                width:150,
                filter: true,
                hide: true,
            },
            {
                headerName: 'OEM Name',
                field: 'oem_name',
                width:280,
                filter: true,
            },  
            {
                headerName:transportercode[0],
                field: "f_"+transportercode[0],
                width:280,
                filter: true,
            }, 
           
        ];
       
        this.setState({
            columnDefs:columnDefs
        })
    }

    createrows(){
        let oemName = this.state.rowData;
        var tCode = this.state.transporters;
        // console.log(oemName,"oemName")
        var rows = []
        if (oemName.length > 0){
            oemName.map((item) => { 
          var rw= {
                    oem_code: item.oem_code,
                    oem_name:item.oem_name,
                    industry_type:item.industry_type
                }
                if(tCode.length > 0)
                {                  
                    // var trnarray = []
                    tCode.map((tc, key) => {
                        var oemTransporters = item.transporters;                                          
                        try{
                            // var foemdata = []
                            if(oemTransporters.length > 0)
                            {   
                                if (oemTransporters.includes(tc.transporter_code)){
                                    rw[tc.transporter_code] = "Yes";
                                }else{
                                    rw[tc.transporter_code] = "";
                                }                              
                            }
                            else{
                                rw[tc.transporter_code] = "";
                            }
                        }
                        catch(e)
                        {
                            rw[tc.transporter_code] = "";
                        }
                    })
                }
                rows.push(rw)
            })
        }
        
        // console.log('row', rows)
        this.setState({
            rowData:rows
        })
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onChangeCellEditData = (params) => {
        // console.log("params:", params)
    }   
 

   render() {
    const {columnDefs} = this.state     
      return (
        <div className="container-fluid">
            <div className="row">
            		<div className="col-xl-12 col-lg-12">                        
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Oem API Configurations</span>
                                 
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus= {true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //rowHeight={this.state.rowHeight}
                                        enableRangeSelection= {true}
                                        onCellEditingStopped = {this.onChangeCellEditData.bind(this)}                             
                                     />                                  
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>

        </div>

        //  <Fragment>
        //     {/* Minimal statistics charts */}
        //     <Row>
        //         <Col sm="12" md="12" xl="12">
        //             <div class="well">
        //                 {this.state.successMsg &&
        //                     <Alert color="dark">{this.state.successMsg}</Alert>
        //                 }
        //                 <Row>
        //                     <Col sm="8" md="8" xl="8">
        //                         <div className="page-title-head">
        //                             {/* <i className="menu-icon page-title-icon">
        //                                 <Users size={24} color={"#51416e"} />
        //                             </i> */}
        //                             <span className="menu-item-text page-title">Oem API Configuration</span>                                    
        //                         </div>
        //                     </Col>            
        //                 </Row>
        //                 <div id="myGrid" style={{height: '500px',width: '100%'}} 
        //                     className="ag-theme-balham">
        //                     <AgGridReact
        //                         modules={this.state.modules}
        //                         columnDefs={columnDefs}
        //                         defaultColDef={this.state.defaultColDef}
        //                         rowData={this.state.rowData}
        //                         enableCharts={false}
        //                         // autoGroupColumnDef={this.state.autoGroupColumnDef}
        //                         onGridReady={this.onGridReady}
        //                         onGridState={this.onGridState}
        //                         frameworkComponents={this.state.frameworkComponents}
        //                         statusBar={this.state.statusBar}
        //                         sideBar={this.state.sideBar}
        //                         stopEditingWhenGridLosesFocus= {true}
        //                         paginationPageSize={this.state.paginationPageSize}
        //                         pagination={true}
        //                         gridOptions={{
        //                             context: { componentParent: this }
        //                         }}
        //                         //rowHeight={this.state.rowHeight}
        //                         enableRangeSelection= {true}
        //                         onCellEditingStopped = {this.onChangeCellEditData.bind(this)}                             
        //                         //getRowHeight={this.getRowHeight.bind(this)}
        //                     />
        //                 </div>
        //             </div>
        //         </Col>
        //     </Row>
           
        //  </Fragment>
      );
   }
}

