/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";


import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import RouteAnimate from '../tptlogin/routeAnimate';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Consignmentforceclose from '../manage/consignmentforceclose';
//import Grid from '../layouts/gridnosidebarComponent';
import DateFormater from '../layouts/dateComponent';
import DrawMap from '../common/drawmap';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYYHHMM,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault,
	getDDMMYYYYHHMM,
	getHyphenDDMMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ExportPDF from "./exportpdf"
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import EditActions from '../manage/EditActionsComponent';
import TptMSILTripsCounters from './tptmsiltripscounters';
import reorder from 'material-ui/svg-icons/action/reorder';
import ForceCloseSideBar from "../layouts/forceclosuresidebarcomponent";
import CSVFileValidator from 'csv-file-validator';
$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;
var containerslist = [];
export default class TPTTrips extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode: "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderCommentTranslate: "",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			sliderSegmentTranslate: "",
			mapinfo: '',
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			googelRoutes: '',
			plants: [],
			consgmntdata: [],
			transitdata: [],
			gpsntavadata: [],
			overspeed: [],
			nightdriving: [],
			loadingdelay: [],
			stoppages: [],
			critical: [],
			probaccdnt: [],
			counters_data: [],
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: true,
			},
			rowData: [],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				editActions: EditActions,
				consignmentforceclose: Consignmentforceclose
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: { "value": 'all', "label": 'All' },
			tripvalue: '',
			consigner: [{ "value": 'all', "label": 'All' }],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Tickets',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			segementConsignmentCode: "",
			counter_screen: "",
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			defaultsdate: '',
			defaultedate: '',
			movementtype: { "value": "all", "label": "All" },
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			showumncr: 0,
			showummove: 0,
			usermanualncrmodal: false,
			usermanualmovemodal: false,
			slideuploadeway: "",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			transporterTrucks: [],
			tpttrucks: [],
			adaniCheck: 0,
			addressColumn: 300,
			pdfRowData: [],
			pdfColumns: [],
			msilCounters: {
				"transit_delay": [],
				"nightdriving_exception": [],
				"no_gps_data": [],
				"overspeeding_exception": [],
				"loading_delay_exception": [],
				"enroute_exception": [],
				"is_critical": [],
				"probable_accident": []
			},
			oems: [],
			oem: { "value": "", "label": "OEM" },
			dealers: [],
			plantlocations: [],
			dealer: { "value": "", label: "Dealer" },
			city: { "value": "", label: "City" },
			transittype: { "value": "Road", "label": "Road" },
			transit_distance: "",
			transit_time: "",
			truck_no: "",
			trip_no: "",
			vin_no: "",
			invoice_date: "",
			gate_in_time: "",
			gate_out_time: "",
			selected_eway_bill_date: "",
			quantity: "",
			fleet_amount: "",
			distance_in_km: "",
			expected_eta: "",
			drivers: [],
			driver: { "value": "", "label": "Driver" },
			sliderRso: "",
            sliderUpdate:"",
			tcode: "",
			cities: [],
			filteredDataTrip: [],
			alltrucks: [],
			showTrucksListTrip: "show-n",
			localtransporter: [],
			selectedRowNode: "",
			sliderForceCloseTranslate: "",
			csvcontent: [],
			't329Check': 0,
			sliderBulkForceClose: '',
			csvcontentBulk: '',
			file1: '',
            selectedService:[],
            servicelocationvalues:[],
            servicelocation:[],
            tickets_no:[],
            expected_date_of_service:[],
            transporters_name:[],
            ticket_remarks:[],
            service_remarks:[],
            service_location_list:[],
            transporter_list:[],
            selectedStatus:[],
            update_remarks:[],
            expected_closure_date:[],
            mobile_number:[]
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		// this.onShowEditAction = this.onShowEditAction.bind(this);
		// this.onLoadData = this.onLoadData.bind(this);
	}

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount(){
        loadDateTimeScript()
		this.onLoadPageData();
		
	}
	getUserGridState() {
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				// console.log("resp ", resp)
				this.setState({
					usergridstate: resp.data
				});
				this.restoreGridStates();
			})
	}


	onLoadPageData = () => {
		// this.setState({
		// 	loadshow: 'show-m',
		// 	overly: 'show-m'
		// })
        redirectURL.post("/dashboard/servicelocations",params).then(async (response1) => {
			var servicelocationlist = response1.data.servicelocationlist;
			console.log(servicelocationlist, "servicelocationlist");
            var service_location_list = []
            servicelocationlist.map((e) =>{
                console.log(e,"dataaaaaa")
                service_location_list.push({ "value": e.service_location_name, "label": e.service_location_name })
            })
            console.log(service_location_list,"service_location_list")
			this.setState({
				service_location_list: service_location_list,
				loadshow: 'show-n',
				overly: 'show-n'
			});

			console.log(this.state.rowData, "pdfRowData")
		})

        redirectURL.post("/dashboard/getTransporters").then(async (response2) => {
			var transporterlist = response2.data.records;
			// console.log(transporterlist, "transporterlist");
            var transportercodevalue = localStorage.getItem("transportercode")
            console.log(transportercodevalue,"transportercodevalue")
            var transporter_list = []
            transporterlist.map((e) =>{
                console.log(e.transporter_code,"e.transporter_code")
                if(transportercodevalue == e.transporter_code)
                {
                    console.log(transportercodevalue == e.transporter_code,"transportercodevalue == e.transporter_code")
                    transporter_list.push(e.transporter_name)
                }
            })
            console.log(transporter_list,"transporters list")
			this.setState({
				transporter_list: transporter_list,
				loadshow: 'show-n',
				overly: 'show-n'
			});
		})

        var params = {
            transporter_code : localStorage.getItem("transportercode")
        }
		redirectURL.post("/dashboard/getticketsdata",params).then(async (response) => {
			var records = response.data.ticketdata;
			console.log(records, "ticketdata");
			this.setState({
				rowData: records,
				// allRowData: countersdata,
				// originalData: countersdata,
				// counters_data: msilCounters,
				loadshow: 'show-n',
				overly: 'show-n'
			});

			console.log(this.state.rowData, "pdfRowData")
		})
	}


	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}

	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}



	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		if (this.state.deptcode == 'LOG-PRT') {
			console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception", "loading_delay_exception", "unloading_delay_exception", "trip_type"], false);

			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'LOG-TNP') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], true);
		}

		else { }



	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}


	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			slideuploadeway: '',
			sliderRouteTranslate: '',
			sliderSegmentTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderCommentTranslate: "",
			sliderRso: "",
            sliderUpdate:"",
			selectedRowNode: "",
			sliderBulk: "",
			sliderBulkForceClose: ''
		});

	}


	closeAlert = () => {
		this.setState({
			show: false
		});
	}

    onRowSelection(event) {
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		// console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
		//var containerlist=[];
		// console.log("rowselect", event.data)
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})

	}

	toggle = () => {
        const currentState = this.state.mapToggle;
        this.setState({ mapToggle: !currentState });
    }

	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	// onShowEditAction = (row) => {
    //     console.log(row,"edit row data")
	// 	this.setState({
	// 		sliderRso: 'slider-translate',
	// 		overly: 'show-m',
    //         tickets_no: row.ticket_no,
	// 		truck_no: row.truck_no,
	// 		transporters_name: row.transporters_name,
	// 		expected_date_of_service: getHyphenDDMMYYYYHHMM(row.expected_date_of_service),
    //         selectedService:row.reason_for_service,
    //         servicelocation:row.service_location,
	// 		ticket_remarks: row.ticket_remarks,
    //         service_remarks:row.service_remarks,
    //         selectedStatus:row.status,
	// 		selectedRowNode: row

	// 	})
	// 	$("#inputTruckticket").val(row.truck_no);

	// }
	onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		// console.log("IN onShowRouteDiv, /Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no
		});

		// If gateouttime is current time - It shows "No Route data available"
		if (e.invoice_date) {
			// console.log("came into invoice time", e.invoice_time)
			//console.log("Step1", e.invoice_time)
			var gateouttime = moment.parseZone(e.invoice_date).format('YYYY-MM-DD HH:mm:ss');
		}
		else {
			//console.log("Step2")
			if (e.gate_in_time) {
				// console.log("came into gate in time", e.gate_in_time)
				//console.log("Step3")
				var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
			}
			else {
				//console.log("Step4")
				if (e.gate_out_time) {
					//console.log("Step5")
					// console.log("came into gate out time", e.gate_out_time)
					var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}

		// if (e.gate_out_time) {
		// 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		// }

		var edate = '';
		var end_date;
		if (e.status <= 2) {
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			// console.log("status less than 2");
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			edate = getHyphenDDMMYYYYHHMMSS(end_date);
		}
		else if (e.status == 3 || e.status == 4) {
			if (e.recent_dealer_reported) {
				end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.recent_dealer_reported);
			}
			// console.log("status 3 and 4");
		}
		else if (e.status == 5) {
			if (e.left_dealer_marked) {
				end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
			}
			// console.log("status 5");
		}
		else if (e.status == 6) {
			if (e.force_closure_time) {
				end_date = e.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.force_closure_time);
			}
			// console.log("status 6");
		}
		else {
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')

			edate = getHyphenDDMMYYYYHHMMSS(end_date);
			// console.log("in else condition");
		}

		//console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0) {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time + 1) * 24 * 60 * 60 * 1000));
		}
		else {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
		}

		var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

		if (!end_date) {
			// When end date is unavailable then current date is considered as end date.
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			//console.log("Defining end_date ", end_date);
		}
		var reqData = {
			truck_no: e.truck_no,
			consignment_code: e.trip_no,
			consignee_code: e.dealer_code,
			gate_out_time: gateouttime,
			seconddate: end_date,
			screen: "consignment"
		}
		// console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				// console.log("Props data ", records)

				this.setState({
					loadshow: 'show-m'
				});
				try {
					if (records.status == "failure" || records.coords.length == 0) {
						console.log("records", records.coords);
						if (records.status != undefined) {
							this.setState({
								show: true,
								basicTitle: 'No Route data available',
								basicType: "danger",
								loadshow: 'show-n'
							});
						}

					}
					else {
						if (records != '') {
							var sdate = e.gate_out_time;
							if (e.invoice_date) {
								// console.log("came into invoice time", e.invoice_time)
								//console.log("Step1", e.invoice_time)
								var sdate = e.invoice_date;
							}
							else {
								//console.log("Step2")
								if (e.gate_in_time) {
									// console.log("came into gate in time", e.gate_in_time)
									//console.log("Step3")
									var sdate = e.gate_in_time;
								}
								else {
									//console.log("Step4")
									if (e.gate_out_time) {
										//console.log("Step5")
										// console.log("came into gate out time", e.gate_out_time)
										var sdate = e.gate_out_time;
									}
								}
							}


							var edate = '';
							if (e.status <= 4) {
								//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
								//var edatesecond = new Date(ms);
								end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
								edate = getHyphenDDMMYYYYHHMMSS(end_date);
							}
							else if (e.status == 5) {
								if (e.left_dealer_marked) {
									edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
								}
								else {
									end_date = moment.parseZone().format('DD-MM-YYYY HH:mm:ss')
									// edate = getHyphenDDMMYYYYHHMMSS(end_date);
								}
							}
							//console.log("End Date ", edate)
							this.setState({
								sliderRouteTranslate: "slider-translate-60p",
								showDiv: 'show-m',
								mapinfo: records,
								dealer: e.dealer_code,
								consignment_code: "Consignment : " + e.trip_no,
								maptruckno: e.truck_no,
								routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
								loadshow: 'show-n',
								sidebarSubHeader: "Consignment Information",
								overly: 'show-m',
								rownode: e,
								leg_no: 0,
								tolls: records.tolls
							});
							this.onShowGoogleRoute(e.trip_no, e.dealer_code, 0, e.truck_no)
							this.renderMap();

						}
					}
				}
				catch (e) {
					// this.setState({
					// 	show: true,
					// 	basicTitle:'No Route data available',
					// 	basicType:"danger",
					// 	loadshow:'show-n'
					// });
				}

			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}
	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = 'MSIL Trips';



		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		///console.log("this.state.usergridstate ", this.state.usergridstate)
		try {
			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
		}
		catch (e) { }
	}


	resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};


	onClickNewAddRow(event) {
		event.preventDefault();

		// var tickets_no = $("#tickets_no").val();
		var expected_date_of_service = $("#expected_date_of_service").val();
        var service_remarks = ""
        if($("#service_remarks").val() != "" && $("#service_remarks").val() != undefined)
        {
            service_remarks = $("#service_remarks").val()
        }
		var selectedservice = this.state.selectedService.value
		var servicelocation = this.state.servicelocation.value
        // var selectedstatus  = this.state.selectedStatus.value
		var ticket_remarks = $("#ticket_remarks").val();
        var mobile_number = $("#mobile_number").val();
		var truck_no = $("#inputTruckticket").val();
        
        if($("#expected_date_of_service").val() != "" &&$("#expected_date_of_service").val() != undefined || $("#inputTruckticket").val() != "" && $("#inputTruckticket").val() != undefined || this.state.servicelocation.value != "" && this.state.servicelocation.value != undefined || this.state.selectedService.value != "" && this.state.selectedService.value != undefined || $("#mobile_number").val() != "" && $("#mobile_number").val() != undefined)
        {
            var params = {
                // tickets_no:tickets_no,
                expected_date_of_service:expected_date_of_service,
                transporters_name:localStorage.getItem("firstname"),
                selectedservice:selectedservice,
                service_remarks:service_remarks,
                servicelocation:servicelocation,
                user_email:localStorage.getItem("email"),
                ticket_remarks:ticket_remarks,
                mobile_number:mobile_number,
                // selectedstatus:selectedstatus,
                truck_no:truck_no,
				transporter_code: localStorage.getItem("transportercode")
			}
			if (this.state.selectedRowNode != "") {
				params.row_id = this.state.selectedRowNode._id
			}
			console.log("params ", params)
			redirectURL.post("/dashboard/savetickets", params, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).
				then(
					(response) => {
						//console.log("update response is : ",response.data);
						//console.log("Update Response ",response.data.result)
						if (response.data.status == "success") {
							this.setState({
								show: true,
								basicTitle: 'Successfully added data',
								basicType: "success",
								sliderRso: "",
								selectedRowNode: "",
                                tickets_no:"",
                                expected_date_of_service:"",
                                transporters_name:"",
                                selectedService:"",
                                servicelocation:"",
                                selectedStatus:"",
                                ticket_remarks:"",
                                truck_no:"",
                                service_remarks:"",
								overly: "show-n",
								loadshow: "show-n"
							});
							this.onLoadPageData();
                        }
						else {
							this.setState({
								show: true,
								basicTitle: 'Something went wrong',
								basicType: "danger",
							});
						}

					}
				)
        }
        else
        {
            this.setState({
                show: true,
                basicTitle: 'Fields should not be empty',
                basicType: "danger",
            });
        }
			

	}
    onClickUpdateTicket(event) {
        event.preventDefault();
        var clickedRowData = this.state.clickedRowData;
        console.log(clickedRowData,"clickedRowData")
		var expected_closure_date = $("#expected_closure_date").val();
        var selectedstatus  = this.state.selectedStatus.value
		var update_remarks = $("#update_remarks").val();
        if($("#expected_closure_date").val() != "" && $("#expected_closure_date").val() != undefined || this.state.selectedStatus.value != "" && this.state.selectedStatus.value != undefined)
        {
            var params = {
                ticket_no:clickedRowData.ticket_no,
                expected_closure_date:expected_closure_date,
                transporters_name:localStorage.getItem("firstname"),
                user_email:localStorage.getItem("email"),
                update_remarks:update_remarks,
                selectedstatus:selectedstatus,
				transporter_code: localStorage.getItem("transportercode")
			}
			console.log("params Update", params)
			redirectURL.post("/dashboard/updatetickets", params, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).
				then(
					(response) => {
						if (response.data.status == "success") {
							this.setState({
								show: true,
								basicTitle: 'Successfully Updated data',
								basicType: "success",
                                sliderUpdate:"",
                                expected_closure_date:"",
                                selectedStatus:"",
                                update_remarks:"",
								overly: "show-n",
								loadshow: "show-n"
							});
							this.onLoadPageData();
                        }
						else {
							this.setState({
								show: true,
								basicTitle: 'Something went wrong',
								basicType: "danger",
							});
						}

					}
				)
        }
		else{
            this.setState({
                show: true,
                basicTitle: 'Fields should not be empty',
                basicType: "danger",
            });
        }

	}
	onAddRow() {
		var tptCode = localStorage.getItem('transportercode');
		var vehicle_group = localStorage.getItem("vehicle_group");
		var tptrucks = []
		if (vehicle_group != "") {
			var tparms = {
				transporter_code: tptCode,
				region: vehicle_group
			}
			redirectURL.post("/dashboard/tpregiontrucksdata", tparms)
				.then((response) => {
					var records = response.data.records;
					// console.log("records ", records)
					tptrucks = records;
					this.setState({
						tpttrucks: records
					})
				})
		}
		var params = {
			transporter_code: localStorage.getItem("transportercode")
		}
		redirectURL.post("/dashboard/tpttrucks", params)
			.then((response) => {
				var records = response.data.records;
				var tpttrucks = tptrucks;
				console.log("tpttrucks ", tpttrucks)
				var trucksarr = []
				if (tpttrucks.length > 0) {
					records.filter(f =>
						tpttrucks.filter((d) => {
							if (d.truck_no == f.truck_no) {
								trucksarr.push(f)
							}
						})
					);
				}
				else {
					trucksarr = records
				}
				console.log(trucksarr, "trucksarr")
				var trucks = []
				if (trucksarr.length > 0) {
					trucksarr.map((item) => {
						trucks.push(item.truck_no);
					})
				}
				console.log(trucks, "trucks")
				this.setState({
					alltrucks: trucks
				})
			})
		redirectURL.post("/dashboard/tripsdealers")
			.then((response) => {
				// console.log("respone oem ", response.data)
				var dealers = response.data.records;
				// console.log("dealers ", dealers)
				let newArray = [];
				// Declare an empty object
				let totuniqueObject = {};
				var totobjTitle;
				// Loop for the array elements
				for (let i in dealers) {

					// Extract the title
					totobjTitle = dealers[i]['city'];

					// Use the title as the index
					totuniqueObject[totobjTitle] = dealers[i];
				}

				// Loop to push unique object into array
				for (var a in totuniqueObject) {
					newArray.push(totuniqueObject[a]);
				}
				this.setState({
					cities: newArray
				})
			})
		// var newItem = 
		// {
		//     "truck_no":"",
		//     "truck_type":"",
		//     "transporter_code":"",
		//     "dept_code":""
		// };
		// console.log("newItem",newItem);
		// var res = this.gridApi.updateRowData({ add: [newItem] });
		this.setState({
			sliderRso: 'slider-translate',
			selectedRowNode: "",
			overly: 'show-m',
			tickets_no:"",
            expected_date_of_service:"",
            transporters_name:"",
            selectedService:"",
            selectedStatus:"",
            servicelocation:"",
            ticket_remarks:"",
            mobile_number:"",
            truck_no:"",
            service_remarks:"",
		})

	}



    onCellClicked(e)
	{
        if(e.colDef.field == "update_ticket")
        {
                this.setState({
                    sliderUpdate:"slider-translate",
                    sliderTitle:"Update Ticket",
                    clickedRowData:e.data
                });
        }
	}
	initalListTrip = (event) => {
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.alltrucks;
		var tptunqrecords = dataset

		tptunqrecords = tptunqrecords.slice(0, 100);
		this.setState({
			filteredData: tptunqrecords,
			showTrucksList: "show-m",
		})
		console.log(tptunqrecords, "tptunqrecords")
	}
	handlerFormTrip = (event) => {
		//console.log("Select", event.target.value);
		// let name = event.target.name;
		// let value = event.target.value;
		// this.setState({[name]:value});
		$(".trucks-dropdown").removeClass("show-n");
		var dataset = this.state.alltrucks
		var tptunqrecords = dataset

		var filteredResult = tptunqrecords.filter(function (e) {
			if (e != "" && e != null) {
				return e.toString().toLowerCase().includes($("#inputTruckticket").val().toLocaleLowerCase());
			}

		});
		// console.log("filteredResult ", filteredResult)
		filteredResult = filteredResult.slice(0, 100);
		this.setState({
			filteredDataTrip: filteredResult,
			showTrucksListTrip: "show-m",
		})
	}
	onClickShowBulk() {
		this.setState({
			overly: "show-m",
			sliderBulk: 'slider-translate',
		})
	}

	onClickBulkUpload = () => {
		this.setState({
			overly: 'show-m',
			sliderBulkForceClose: 'slider-translate-50p'
		})
	}
    
	render() {
        console.log(this.state.selectedService,"this.state.selectedService")
		const modalStyles = {
			width: '1300px !important',
		}
		const { open, openforceclose, tcode } = this.state;

		var maptransporter = '';
		try {
			if (this.state.rownode.transporter) {
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter = this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch (e) {
			console.log(e)
		}

		var listDatatrip = []
		this.state.filteredDataTrip.map(function (e) {
			listDatatrip.push(<li className="tptdropdown-truck-no">{e}</li>)
		})
		// console.log("tcode",tcode)
		var tStatusShowOrHide = true
		if (tcode == "T329") {
			tStatusShowOrHide = false
		}
		var columnwithDefs =
			[
				// {
				// 	headerName: "",
				// 	field: "_id",
				// 	colId: "_id",
				// 	width: 50,
				// 	// pinned: 'left',
				// 	cellRenderer: 'editActions',
				// 	filter: false, resizable: true

				// },
                {
					headerName: "Ticket No",
					field: "ticket_no",
					width: 140,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 120,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},

				{
					headerName: "Transporter Name",
					field: "transporters_name",
					//colId : "consignment_code",
					width: 150,
					// pinned: 'left',
					editable: false,
					filter: "agSetColumnFilter", resizable: true,
					//cellRenderer : "agGroupCellRenderer"
				},
				{
					headerName: "Expected Date of Service",
					field: "expected_date_of_service",
					width: 160,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true,
                    valueGetter:function(params){
                        return getHyphenDDMMMYYYYHHMM(params.data.expected_date_of_service)}
				},
                {
					headerName: "Service Location",
					field: "service_location",
					width: 140,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
                {
					headerName: "Ticket Status",
					field: "ticket_status",
					width: 120,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true,
                    valueGetter: function (params) {
                        if (params.data.ticket_status == 1) {
                            return "Open"
                        }
                        else if (params.data.ticket_status == 2) {
                            return "Work In Progress"
                        }
                        else if (params.data.ticket_status == 3) {
                            return "Hold"
                        }
                        else {
                            return "Closed"
                        }
                    }
				},
                {
					headerName: "Mobile Number",
					field: "mobile_number",
					width: 140,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
                {
					headerName: "Reason for Service",
					field: "reason_for_service",
					width: 140,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
                {
					headerName: "Other Reason",
					field: "service_remarks",
					width: 170,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
                {
					headerName: "Remarks",
					field: "ticket_remarks",
					width: 140,
					// pinned : "left",
					filter: "agSetColumnFilter",
					resizable: true
				},
                // {
                //     headerName: "Update Ticket",
                //     field: "update_ticket",
                //     width: 140,
                //     filter: true,
                //     resizable: true,
                //     cellRenderer : function(params){
                //         return '<button class="btn  btn-success" >Update Ticket</button>'
                //     }
                // }
			];
            if(localStorage.getItem("user_type") != "TRANSPORTER")
            {
                columnwithDefs.push(
                    {
                    headerName: "Update Ticket",
                    field: "update_ticket",
                    width: 140,
                    filter: true,
                    resizable: true,
                    cellRenderer : function(params){
                        return '<button class="btn  btn-success" >Update Ticket</button>'
                    }
                })
            }
		// console.log("columnwithDefs", columnwithDefs)
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}

		return (
			<div className="">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}

				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
                                    {(localStorage.getItem("user_type") == "TRANSPORTER")?
										<button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Create Ticket</button>
                                        :""}

									{/* <button type="button" onClick={this.onClickShowBulk.bind(this)} className="btn btn-danger float-right">Bulk Upload</button> */}
									{/* <span className="layoutbtns pull-right">
										<button className="float-right custom-btn white btn-danger"
											style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.onClickSaveGridState.bind(this)}>
											<i className="icofont icofont-save"></i> Save Grid Layout
										</button>
										<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.resetState.bind(this)}>
											<i className="icofont icofont-refresh"></i> Reset Default Layout
										</button>
									</span>
									{(this.state.pdfRowData.length > 0) ?
										<ExportPDF rowData={this.state.pdfRowData} pagetitle="Trips" pdfColumns={this.state.pdfColumns}
											addressColumn={this.state.addressColumn} />
										: ""} */}
								</h5>
							</div>
							<div className="card-body pt-10px" >



								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={false}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}

										enableCellChangeFlash={false}
										//suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										// suppressCellSelection= {true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
                                        onCellClicked={this.onCellClicked.bind(this)}
									/>

								</div>
							</div>
						</div>
					</div>

				</div>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>

				<div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Create Ticket
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<div className={"trucks-dropdown " + this.state.showTrucksListTrip} style={{ top: "65px" }}>
										<ul>
											{listDatatrip}
										</ul>
								    </div>
									<label className="col-xl-12 col-lg-12">Truck No *</label>
									<input
										type="text"
										name="truck_no"
										id="inputTruckticket"
										placeholder={"Truck No"}
										autoComplete="off"
										// style={{ marginLeft: "15px" }}
										className="form-control"
										onFocus={this.initalListTrip.bind(this)}
										onChange={this.handlerFormTrip.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Service Location *</label>
									<Select
										closeMenuOnSelect={true}
										// isMulti={true}
                                        value= {this.state.servicelocation}
										onChange={(e)=>{this.setState({servicelocation:e})}}
										className={"col-xl-12 col-lg-12"}
										// style={{ borderRadius: "0px" }}
										options={this.state.service_location_list} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Reason for Service *</label>
									<Select
										// placeholder={"Select Service"}
										closeMenuOnSelect={true}
										// isMulti={true}
                                        value= {this.state.selectedService}
										onChange={(e)=>{this.setState({selectedService:e})}}
										className={"col-xl-12 col-lg-12"}
										// style={{ borderRadius: "0px" }}
										options={[{"label":"Device Inactive",value:"Device Inactive"},
										{"label":"New GPS installation",value:"New GPS installation"},
										{"label":"Remove GPS",value:"Remove GPS"},
                                        {"label":"Others",value:"Others"}]} />
								</div>
                                <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Mobile No *</label>
									<input
										type="text"
										name="mobile_number"
										id="mobile_number"
										value={this.state.mobile_number}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
                                {(this.state.selectedService.value == "Others")? 
                                <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12"></label>
									<input
										type="text"
										name="service_remarks"
										id="service_remarks"
										value={this.state.service_remarks}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>:""}
                               
                                <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Remarks</label>
									<input
										type="text"
										name="ticket_remarks"
										id="ticket_remarks"
										value={this.state.ticket_remarks}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
                                {/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Ticket Status</label>
									<Select
										// placeholder={"Select Service"}
										closeMenuOnSelect={true}
										// isMulti={true}
                                        value= {this.state.selectedStatus}
										onChange={(e)=>{this.setState({selectedStatus:e})}}
										className={"col-xl-12 col-lg-12"}
										// style={{ borderRadius: "0px" }}
										options={[{"label":"Open",value:"Open"},
                                        {"label":"Resolved",value:"Resolved"}]} />
								</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">EXPECTED DATE OF SERVICE *</label>
									<input
										type="text"
										name="expected_date_of_service"
										id="expected_date_of_service"
										value={this.state.expected_date_of_service}
										className="expected_date_of_service datetimepicker_mask form-control"
										autoComplete={"off"}
										onChange={this.changeHandler.bind(this)} />
								</div>
                                
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>


						</div>
					</div>
				</div>
                <div className={"slide-r " + (this.state.sliderUpdate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Update Ticket
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateTicket.bind(this)}>
                                <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Ticket Status *</label>
									<Select
										// placeholder={"Select Service"}
										closeMenuOnSelect={true}
										// isMulti={true}
                                        value= {this.state.selectedStatus}
										onChange={(e)=>{this.setState({selectedStatus:e})}}
										className={"col-xl-12 col-lg-12"}
										// style={{ borderRadius: "0px" }}
										options={[{"label":"Open",value:"Open"},
                                        {"label":"Resolved",value:"Resolved"}]} />
								</div>
                                <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Update Remarks</label>
									<input
										type="text"
										name="update_remarks"
										id="update_remarks"
										value={this.state.update_remarks}
										autoComplete="off"
										className="form-control"
										onChange={this.changeHandler.bind(this)} />
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Expected Closure Date *</label>
									<input
										type="text"
										name="expected_closure_date"
										id="expected_closure_date"
										value={this.state.expected_closure_date}
										className="expected_closure_date datetimepicker_mask form-control"
										autoComplete={"off"}
										onChange={this.changeHandler.bind(this)} />
								</div>
                                 
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>


						</div>
					</div>
				</div>
			</div>

		);
	}
}

$(document).ready(function () {
	$(document).on("click", ".tptdropdown-truck-no", function () {
		var vals = $(this).text();
		$("#inputTruckticket").val(vals);
		$(".trucks-dropdown").removeClass("show-m");
		$(".trucks-dropdown").addClass("show-n");
	});

	$("body").on("click", function (e) {
		var container = $("#inputTruckticket");
		if (!container.is(e.target)) {
			try {
				if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
					$(".trucks-dropdown").removeClass("show-m");
					$(".trucks-dropdown").addClass("show-n");
				}
			} catch (e) { }

		}


	})
})

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}

function onSetCritical(params, status) {
	//console.log("onSetCritical ", params);
	//console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus', {
		params: params,
		is_critical: status,
		token: localStorage.getItem("token"),
		userId: localStorage.getItem("userid")
	})
		.then((response) => {

			//var records = JSON.parse(JSON.stringify(response)).data;
			console.log(response.data);
		});

}

function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false,
		onShow: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToHM(seconds) {
	seconds = Number(seconds);
	// var d = Math.floor(seconds / (3600*24));
	// var h = Math.floor(seconds % (3600*24) / 3600);
	// var m = Math.floor(seconds % 3600 / 60);
	// var s = Math.floor(seconds % 60);

	// var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	// var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	// var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	// var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	// return dDisplay + hDisplay + mDisplay;

	var hh = Math.floor((seconds / 3600));
	var hrr = Math.floor(seconds - (hh * 3600));
	var mm = Math.floor(hrr / 60)
	if (mm < 10) {
		var mins = "0" + mm;
	}
	else {
		var mins = mm;
	}

	if (hh < 10) {
		var hrs = "0" + hh;
	}
	else {
		var hrs = hh;
	}
	return hrs + ":" + mins;
}