import React, { Component } from 'react';
import CountUp from 'react-countup';


export default class TptMSILTripsCounters extends Component {
    constructor(props){
    	super(props);
    	this.state={
            
    	}
    
    }
    componentWillReceiveProps(newprops){
        var totalRows = newprops.total_rows;
    }
    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        return (
            <div className="row col-xl-12">

            <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                <div className="card">
                    <div className="card-body" id="dashboardCounterCardBody">
                        <div className="crm-numbers pb-0">
                            <div className="row">
                               
                               <div className="col cirlce-d " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,0)}>
                                   <span className="f13">
                                       <i className="icofont icofont-ui-browser f24 greenfont"></i>
                                       <br /> Consignments
                                   </span>
                                   <h4 className="txt-info f50">
                                       <span className="counter">
                                           <CountUp end={this.props.total_rows.length}/>
                                       </span>
                                   </h4>
                               </div> 
                               {localStorage.getItem('transportercode').includes('_ENTRAC') ? "":
                                    <div className="col " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"transit_delay")}>
                                    <span className="f13">
                                        <i className="icofont icofont-clock-time f22 redfont"></i>
                                        <br /> Transit Delays
                                    </span>
                                    <h4 className="txt-primary f50">
                                        <span className="counter">
                                            <CountUp end= {(this.props.counters.transit_delay != undefined)?this.props.counters.transit_delay.length:0}/>
                                        </span>
                                    </h4>
                                </div>
                               }

                               <div className="col " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                   <span className="f13">
                                       <i className="icofont icofont-social-google-map f24 txt-danger"></i>
                                       <br /> GPS Not Available
                                   </span>
                                   <h4 className="txt-pink-medium f50">
                                       <span className="counter">
                                           <CountUp end={(this.props.counters.no_gps_data !=undefined)?this.props.counters.no_gps_data.length :0}/>
                                       </span>
                                   </h4>
                               </div>

                                <div className="col " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"overspeeding_exception")}>
                                    <span className="f13">
                                        <i className="fa fa-tachometer f22 txt-success"></i>
                                        <br /> Overspeeding
                                    </span>
                                    <h4 className="txt-success-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.props.counters.overspeeding_exception !=undefined)?this.props.counters.overspeeding_exception.length:0}/>
                                        </span>
                                    </h4>
                                   
                                </div>

                                <div className="col " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"nightdriving_exception")}>
                                    <span className="f13">
                                        <i className="icofont icofont-full-night f24 txt-primary"></i>
                                        <br /> Night Driving
                                    </span>
                                    <h4 className="txt-success-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.props.counters.nightdriving_exception != undefined)?this.props.counters.nightdriving_exception.length:0}/>
                                        </span>
                                    </h4>
                                   
                                </div>

                                {localStorage.getItem('transportercode').includes('_ENTRAC') ? "":
                                <div className="col " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"loading_delay_exception")}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-loaded f24 darkorange"></i>
                                        <br /> Loading Delay
                                    </span>
                                    <h4 className="txt-success-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.props.counters.loading_delay_exception!=undefined)?this.props.counters.loading_delay_exception.length:0}/>
                                        </span>
                                    </h4>
                                   
                                </div>
                                }

                                <div className="col " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"enroute_exception")}>
                                    <span className="f13">
                                        <i className="icofont icofont-pause f22 maroon"></i>
                                        <br /> Stoppages
                                    </span>
                                    <h4 className="txt-success-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.props.counters.enroute_exception !=undefined)?this.props.counters.enroute_exception.length:0}/>
                                        </span>
                                    </h4>
                                </div>

                                {/* <div className="col " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"is_critical")}>
                                    <span className="f13">
                                        <i className="icofont icofont-minus-circle f22 maroon"></i>
                                        <br /> Critical
                                    </span>
                                    <h4 className="txt-success-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.props.counters.is_critical !=undefined)?this.props.counters.is_critical.length:0}/>
                                        </span>
                                    </h4>
                                </div>

                                <div className="col " style={{cursor:"pointer"}} onClick={this.onClickCounter.bind(this,"probable_accident")}>
                                    <span className="f13">
                                        <i className="icofont icofont-minus-circle f22 maroon"></i>
                                        <br /> Probable Accident
                                    </span>
                                    <h4 className="txt-success-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.props.counters.probable_accident != undefined)?this.props.counters.probable_accident.length:0}/>
                                        </span>
                                    </h4>
                                   
                                </div> */}

                            </div>

                            
                        </div>
                       
                    </div>
                </div>
            </div>
           
            </div>
        );
    }
}






