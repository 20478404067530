
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const ShowTPTRoute = (props) => {
    const handleOverspeedClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickShowMapRoute(props);
    };

    return (
        <div>
          	<span onClick={handleOverspeedClick} className={"f12 "} title="View Route" >
                  <i className="icofont icofont-map-pins f20"></i>
              </span>
          
        </div>
    );
};

export default ShowTPTRoute;
