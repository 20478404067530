/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import $ from 'jquery';
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');

export default class BillingDownload extends Component {

    state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },

            frameworkComponents: {
                updateItem: UpdateItem,
                deleteItem: DeleteItem
                //statusChange:StatusChange
            },

            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk: "",
            file: "",
            amount_payable: [],
            pinnedBottomRowData: [],
            csvcontent: [],
            billing_month: "",
            remarks_history_cols: [
                {
                    headerName: "Truck No",
                    field: "truck_no",
                    width: 150,
                    editable: false,
                    filter: "agSetColumnFilter"
                },

                {
                    headerName: "Remark",
                    field: "individual_remarks",
                    width: 180,
                    editable: false,
                    filter: "agSetColumnFilter"
                },
                {
                    headerName: "Updated Time",
                    field: "remarktime",
                    width: 150,
                    editable: false,
                    filter: "agSetColumnFilter",
                    valueGetter: function (params) {
                        try {
                            if (params.data.remarktime != '' && params.data.remarktime != undefined) {
                                return getHyphenYYYYMMDDHHMMSS(params.data.remarktime)
                            }
                            else {
                                return "";
                            }
                        }
                        catch (e) {
                            return "";
                        }
                    }
                },

            ],
            remarks_data: [],
    };

    getCurrentDepartmentCode(pathToMatch = null) {
        let department = null;
        let departmentName = null;
        switch (pathToMatch) {
            case "/tptoemmaster":
                department = 'SNDG';
                departmentName = " ";
                break;

            default:
                console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
        }
        this.setState({
            currentDepartmentTitle: departmentName
        });
        return department;
    }
    componentDidMount() {
        var billingId = window.atob(this.props.match.params.billingId);
        console.log(billingId, "billingId")
        if (googleAnalytics.page.enableGA) {
            this.logPageView(); // Log page view to GA
            let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            this.setState({ eventAction: eventAction, billingId: billingId });

        }
        if (localStorage.getItem("email") == "billing@enmovil.in") this.setState({ is_admin: 1 })

        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            billingId
        })



        // get department code to filter records by department
        let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
        this.setState({
            departmentCode: currentDepartmentCode
        });
        /*Consignments List*/
        this.onLoadData(billingId);


    };
    onLoadData = (billingId) => {
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        var transporter_code = localStorage.getItem('transportercode')
        console.log(transporter_code, "transporter_code");

        let bmonth = {
            billingId,
        }
        if (transporter_code.length !== 0 && transporter_code !== undefined && transporter_code !== "") {
            bmonth.transporter_code = transporter_code;
        }

        redirectURL.post('/dashboard/billingDownload', bmonth).then((response) => {
            var records = response.data;
            // console.log("records ", records)

            let billing_year_month = records[0].billing_year_month
            var amount_payable = 0;
            records.map((e) => {
                amount_payable += e.amount_payable;
            })
            console.log(amount_payable, "amount")

            var pinnedBottomRowData = {}
            pinnedBottomRowData['view_remarks'] = "";

            pinnedBottomRowData['billing_status_transporter'] = "Total";
            pinnedBottomRowData['uptime_(%)'] = "Total";
            // pinnedBottomRowData['total_break_time_mins'] = "Total";
            if (amount_payable.toString().includes(".") == true) {
                pinnedBottomRowData['amount_payable'] = amount_payable.toFixed(2);
            }
            else {
                pinnedBottomRowData['amount_payable'] = amount_payable;
            }
            this.setState({
                rowData: records,
                amount_payable: [pinnedBottomRowData],
                overly: "show-n",
                loadshow: "show-n",
                billing_year_month
            });
        })
            .catch(function (error) {
                console.log(error);
            });





        // pinnedBottomRowData['total_travel_time_mins'] = "total";

    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }


    onGridReady1 = params => {
        this.gridApi1 = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi1 = params.columnApi;


    };


    onGridState1 = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState1 = this.gridColumnApi1.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState1 = this.gridColumnApi1.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns1 = this.gridColumnApi1.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState1 = this.gridApi1.getFilterModel();
        this.gridApi1.setFilterModel(window.filterState1);


    }

    onClickUpdateRowHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "Update OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "Update OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        if (this.state.oem_name != "" && this.state.oem_code != ""
            && this.state.industry_type != "") {
            var params = {
                rowID: this.state.rowid,
                oem_code: this.state.oem_code,
                oem_name: this.state.oem_name,
                industry_type: this.state.industry_type,
                transporter_code: localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/updateoemitem", params, { headers: {'Content-Type': 'application/json'}
            }).then((response) => {
                        //console.log("update response is : ",response.data);
                        //console.log("Update Response ",response.data.result)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicTitle: 'Successfully update data',
                                basicType: "success",
                                sliderUpdate: "",
                                overly: "show-n"
                            });
                            this.onLoadData();
                        }

                        else {
                            this.setState({
                                show: true,
                                basicTitle: 'Something went wrong',
                                basicType: "danger",
                            });
                        }

                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'All * fields should not be empty',
                basicType: "danger",
            });
        }

    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1);

        this.setState({
            billing_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    onRowClicked = (cell) => {

        if (cell.colDef.field == "view_remarks") {

            var transporter_code = localStorage.getItem('transportercode')
            var admin_email = localStorage.getItem('email')
            this.setState({
                overly: "show-m",
            })
            redirectURL.post("/dashboard/getbillingdownloadremarks", { truck_no: cell.data.truck_no, transporter_code: transporter_code, admin_email: admin_email, })
                .then((response) => {
                    var records = response.data;
                    //   records = records.sort(GetSortDescOrder("billing_id"));
                    console.log("records ", records)
                    this.setState({

                        sliderUpdate: 'slider-translate-40p',
                        remarks_data: records
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }


    onClickNewAddRow(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "Add OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "Add OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        if (this.state.oem_name != "" && this.state.oem_code != ""
            && this.state.industry_type != "") {
            var params = {
                oem_code: this.state.oem_code,
                oem_name: this.state.oem_name,
                industry_type: this.state.industry_type,
                transporter_code: localStorage.getItem("transportercode")
            }
            var fdata = {
                rownode: params,
                transporter_code: localStorage.getItem("transportercode")
            }
            redirectURL.post("/dashboard/saveoemitem", params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).
                then(
                    (response) => {
                        //console.log("update response is : ",response.data);
                        //console.log("Update Response ",response.data.result)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicTitle: 'Successfully added data',
                                basicType: "success",
                                sliderRso: "",
                                overly: "show-n",
                                loadshow: "show-n"
                            });
                            this.onLoadData();
                        }

                        else {
                            this.setState({
                                show: true,
                                basicTitle: 'Something went wrong',
                                basicType: "danger",
                            });
                        }

                    }
                )
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'All * fields should not be empty',
                basicType: "danger",
            });
        }

    }

    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false
        });
    }


    onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
            sliderRso: 'slider-translate',
            overly: 'show-m',
            oem_code: "",
            oem_name: "",
            industry_type: ""
        })

    }


    updateRowHandler = async (cell) => {

        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
            sliderUpdate: 'slider-translate',
            overly: 'show-m',
            oem_code: oem_code,
            oem_name: oem_name,
            industry_type: industry_type,
            rowid: rowid
        })

    }



    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRso: "",
            sliderUpdate: "",
            sliderBulk: ""
        });

    }


    onChangeTruckType(trucktype) {
        this.setState(
            { trucktype },
            () => console.log(`Option selected:`, this.state.trucktype)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }


    onChangeTransporter(transporter) {
        this.setState(
            { transporter },
            () => console.log(`Option selected:`, this.state.transporter)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    onChangeDepartment(deptcode) {
        this.setState(
            { deptcode },
            () => console.log(`Option selected:`, this.state.deptcode)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }

    deleteRowHandler = (cell) => {
        //  console.log("Here",cell);
        if (googleAnalytics.page.enableGA) {
            let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
            let pageTitle = "OEM Master Data";
            if (currentDepartmentTitle) {
                pageTitle = "OEM Master Data" + currentDepartmentTitle;
            }

            let eventOptions = {
                "category": pageTitle,
                "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
                "label": googleAnalytics.page.action.rowUpdated,
            }
            googleAnalytics.logEvent(eventOptions);
        }

        var fdata = {
            rownode: cell
        }
        redirectURL.post("/dashboard/deleteoemitem", fdata, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).
            then(
                (response) => {
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if (response.data.status == "success") {
                        this.setState({
                            show: true,
                            basicTitle: 'Successfully delete item',
                            basicType: "success",
                            loadshow: "show-n",
                            overly: "show-n",
                            sliderRso: ""
                        });
                        this.onLoadData();
                    }

                    else {
                        this.setState({
                            show: true,
                            basicTitle: 'Something went wrong',
                            basicType: "danger",
                        });
                    }

                }
            )
            .catch(function (error) {
                console.log(error);
            });

    }

    onChangeSourceItem(sourceopt) {
        this.setState(
            { sourceopt },
            () => console.log(`Option selected:`, this.state.sourceopt)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    onChangeOfficerType(officertype) {
        this.setState(
            { officertype },
            () => console.log(`Option selected:`, this.state.officertype)
        );


        this.setState({
            errorMessage: '',
            successMessage: ''
        })
    }
    selectsources() {
        let items = [];
        try {
            if (this.state.sources.length > 0) {
                this.state.sources.map((item) => {
                    items.push({ "value": item.geofence_name, "label": item.geofence_name })
                })
            }
        }
        catch (e) {

        }
        return items;
    }

    onClickShowBulk() {
        this.setState({
            overly: "show-m",
            sliderBulk: 'slider-translate',
        })
    }


    // changeFileHandler = async (e) => {
    // 	//console.log(e.target.files[0])
    // 	var dt = '';
    // 	// const config = {
    // 	// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
    // 	// }

    // 	const config = {
    // 		headers: [
    // 			{ 
    // 				name: 'oem_name',
    // 				inputName: 'oem_name',
    // 				required: true,
    // 				requiredError: function (headerName, rowNumber, columnNumber) {
    // 					return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    // 				}
    //             },
    //             { 
    // 				name: 'oem_code',
    // 				inputName: 'oem_code',
    // 				required: true,
    // 				requiredError: function (headerName, rowNumber, columnNumber) {
    // 					return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    // 				}
    //             },
    //             { 
    // 				name: 'industry_type',
    // 				inputName: 'industry_type',
    // 				required: true,
    // 				requiredError: function (headerName, rowNumber, columnNumber) {
    // 					return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    // 				}
    // 			}

    // 		]
    // 	}
    // 	var data = CSVFileValidator(e.target.files[0], config)
    // 	.then((csvData) => {
    // 		this.setState({
    // 			csvcontent:csvData.data
    // 		});
    // 	})
    // 	.catch(err => {})

    // 	var out = new Promise(function(reject, resolve){
    // 		var reader =  new FileReader();
    // 		reader.onload = async function(e) {
    // 			var contents = await e.target.result;
    // 			console.log("contents ", contents )

    // 			resolve(contents);
    // 		};
    // 		var tt =  reader.readAsText(e.target.files[0]);
    // 		console.log("tt ",tt)
    // 	});
    // 	//console.log("Out ", out);

    // 	this.setState({
    // 		file:e.target.files[0]
    // 	});

    // }
    onClickRejectBill = () => {
        this.setState({
            overly: "show-m",
            sliderBulk: 'slider-translate',
        })

        // window.location.href="/tptlogin/billingsummary"
    }
    onClickAcceptBill = () => {
        var transporter_code = localStorage.getItem('transportercode')
        console.log(transporter_code, "transporter_code");
        var acceptBilling = 1;
        var billingId = this.state.billingId;
        console.log(acceptBilling, "acceptBilling")
        var params = {
            billingId: billingId,
            billing_status_transporter: 1,
            transporter_code: transporter_code,
        }
        redirectURL.post('/dashboard/billacceptedstatus', params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {

        })
        params.billing_year_month = this.state.billing_year_month
        redirectURL.post('/consignments/sendBillingApprovedMailToMsilAndEnmovilFromTpt', params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {

        })

        window.location.href = "/tptlogin/billingsummary"
    }

    onBtnExportDataAsCsv() {
        var params = {
            columnKeys: ['billing_id_1', 'billing_year_month_1', 'truck_no_1', 'transporter_code_1', 'billing_status_transporter_1', 'transporter_name_1', 'date_of_installation_1', 'voice_box_installed_on_1', 'freewheeling_installed_on_1', 'billing_start_date_1', 'billing_end_date_1', 'total_billing_days_1', 'total_uptime_days_1', 'uptime_days_added_manually_1', 'comments_if_uptime_is_added_manually_1', 'uptime_(%)_1', 'amount_payable_1', 'category_1']
        };
        this.gridApi.exportDataAsCsv(params);
    }
    onSubmitRejectBill(e) {
        e.preventDefault();
        var transporter_code = localStorage.getItem('transportercode')
        console.log(transporter_code, "transporter_code");
        var admin_mail = localStorage.getItem('email');
        var rejectedBill = 2
        console.log(rejectedBill, "rejectedBill")
        var data = {
            complete_remarks: $("#rejectremark").val(),
            billing_id: this.state.billingId,
            admin_email: admin_mail,
            billing_status_transporter: 2,
            transporter_code: transporter_code,
        }
        console.log(data, "data");
        redirectURL.post("/dashboard/rejectionremarksaving", data, {

        })
        window.location.href = "/tptlogin/billingsummary"
    }
    render() {
        const modalStyles = {
            width: '1300px !important',
        }
        var transporters = []
        var trans = this.state.transporters;
        if (trans.length > 0) {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
        const { usermanualmodal } = this.state;
        var hideChilds = true;
        if (this.state.departmentCode == "LOG-PRT") {
            hideChilds = false
        }
        const columnwithDefs = [
            {
                // View Remarks
                headerName: "",
                field: "view_remarks",
                width: 40,
                filter: false,
                editable: false,
                // cellRenderer:'updateItem',
                cellRenderer: function (params) {
                    if (params.data.view_remarks != "") {

                        let basestring = params.data.truck_no;
                        let encryptedstring = window.btoa(basestring);
                        var htmloption = '<i class="fa fa-comment f15"></i>';
                        //var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="fa fa-comment f15"></i>View</a>';
                        return htmloption
                    }
                    else {
                        return ""
                    }
                }
            },
            {
                headerName: "Billing Id",
                field: "billing_id",
                width: 120,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false
            },
            {
                headerName: "Billing Month",
                field: "billing_year_month",
                width: 120,
                filter: false,
                // cellRenderer:'updateItem',
                editable: false
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
                editable: false,
                filter: "agSetColumnFilter"
            },

            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Transporter Approval Status",
                field: "billing_status_transporter",
                width: 200,
                editable: false,
                filter: "agSetColumnFilter",
                valueGetter: function (params) {
                    if (params.data.billing_status_transporter == 1) {
                        return "Approved"
                    }
                    else if (params.data.billing_status_transporter == 2) {
                        return "Disapproved"
                    }
                    else if (params.data.billing_status_transporter == "Total") {
                        return "";
                    }
                    else {
                        return "Pending..."
                    }
                }
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter"
            },

            {
                headerName: "Date Of Installation",
                field: "date_of_installation",
                width: 150,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                valueGetter: function (params) {
                    return getHyphenYYYYMMDDHHMMSS(params.data.date_of_installation)
                },
            },
            {
                headerName: "Voice Box Installed On",
                field: "voice_box_installed_on",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                valueGetter: function (params) {
                    return getHyphenYYYYMMDDHHMMSS(params.data.voice_box_installed_on)
                },
            },
            {
                headerName: "Freewheeling Installed On",
                field: "freewheeling_installed_on",
                width: 190,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                valueGetter: function (params) {
                    return getHyphenYYYYMMDDHHMMSS(params.data.freewheeling_installed_on)
                },
            },
            {
                headerName: "Billing Start Date",
                field: "billing_start_date",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                valueGetter: function (params) {
                    return getHyphenYYYYMMDDHHMMSS(params.data.billing_start_date)
                },
            },
            {
                headerName: "Billing End Date",
                field: "billing_end_date",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                valueGetter: function (params) {
                    return getHyphenYYYYMMDDHHMMSS(params.data.billing_end_date)
                },
            },


            {
                headerName: "Total Billing Days",
                field: "total_billing_days",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false
            },
            {
                headerName: "Total UpTime Days",
                field: "total_uptime_days",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false
            },
            {
                headerName: "UpTime Days Added Manually",
                field: "uptime_days_added_manually",
                width: 220,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false
            },
            {
                headerName: "Comments If UpTime Is Added Manually",
                field: "comments_if_uptime_is_added_manually",
                width: 280,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false
            },
            {
                headerName: "UpTime (%)",
                field: "uptime_(%)",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                cellStyle: function (params) {
                    if (params.data['uptime_(%)'] == 'Total') {
                        //mark police cells as red
                        return { "font-weight": 'bolder' };
                    } else {
                        return null;
                    }
                },
            },

            {
                headerName: "Amount Payable",
                field: "amount_payable",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                cellStyle: function (params) {
                    if (params.data['uptime_(%)'] == 'Total') {
                        //mark police cells as red
                        return { "font-weight": 'bolder' };
                    } else {
                        return null;
                    }
                },

            },
            {
                headerName: "Category",
                field: "category",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false
            },
            {
                headerName: "Remarks",
                field: "individual_remarks",
                width: 250,
                filter: true,
                // cellRenderer:'updateItem',
                editable: true,
                valueSetter: (params) => {
                    var admin_email = localStorage.getItem('email')
                    var transporter_code = localStorage.getItem('transportercode')
                    console.log(params)
                    if (params.oldValue != params.newValue) {
                        var data = {
                            individual_remarks: params.newValue,
                            truck_no: params.data.truck_no,
                            transporter_code: transporter_code,
                            admin_email: admin_email,
                        }
                        console.log(data, "data");
                        params.data.individual_remarks = params.newValue;

                        redirectURL.post("/dashboard/saveremarksfrombillingdownload", data, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then((response) => {
                        })
                    }
                }
            },
        ];
        if (this.state.is_admin == 1) {
            columnwithDefs.splice(6, 0, {
                headerName: "Approval Time - TPT",
                field: "bill_accept_time_transporter",
                width: 180,
                filter: true,
                // cellRenderer:'updateItem',
                editable: false,
                valueGetter: function (params) {
                    try {
                        if (params.data.bill_accept_time_transporter != '' && params.data.bill_accept_time_transporter != undefined) {
                            return getHyphenYYYYMMDDHHMMSS(params.data.bill_accept_time_transporter)
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) {
                        return "";
                    }
                }
            })
        }
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Billing Summary </span>
                                    {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button> */}
                                    <button type="button" onClick={() => this.onBtnExportDataAsCsv()} className="btn btn-info float-right">Download Bill</button>
                                    {/* <button type="button" onClick={() => this.onClickRejectBill()} className="btn btn-warning float-right">Disapprove Bill</button> */}
                                    <button type="button" onClick={() => this.onClickAcceptBill()} className="btn btn-success float-right">Approve Bill</button>

                                </h5>
                            </div>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                            <label>Select Month</label>
                                <Datetime
                                    value={this.state.billing_month} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ 
                                        placeholder: '', 
                                        name: 'billing_month', 
                                        autoComplete:'off' 
                                    }}
                                    dateFormat="YYYY-DD" 
                                    name="billing_month" 
                                    onChange={this.handlerStartDateTime.bind(this)} 

                                    />
                            </div>				   			 */}
                            <div className="card-body">
                                <div id="myGrid" style={{ height: "450px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked}
                                        pinnedBottomRowData={this.state.amount_payable}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r " + (this.state.sliderTranslate)} >

                    <div className="slide-r-title">
                        <h4>
                            Sidebars
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="col-xl-12 col-lg-12">
                            <div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

                        </div>
                    </div>
                </div>


                {/* <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Code *</label>
								<input 
									type="text" 
									name="oem_code"
									value={this.state.oem_code}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">OEM Name *</label>
								<input 
									type="text" 
									name="oem_name"
									value={this.state.oem_name}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							     
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Industry Type *</label>
								<input 
									type="text" 
									name="industry_type"
									value={this.state.industry_type}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							                       
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>
 */}


                <div className={"slide-r " + (this.state.sliderUpdate)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Trucks Remarks List
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler}>

                                <div id="myGrid" style={{ height: "450px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady1}
                                        onGridState={this.onGridState1}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked}

                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            </form>


                        </div>
                    </div>
                </div>


                <div className={"slide-r " + (this.state.sliderBulk)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Bill Rejection Comments
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitRejectBill.bind(this)}>

                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Reason for the Rejection *</label>
                                    <textarea
                                        type="file"
                                        rows="10"
                                        name="uploadfile"
                                        id="rejectremark"
                                        className="form-control" required />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>

                                {/* <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div> */}
                            </form>


                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


            </div>

        );
    }
}
function getParams() {
    return {
        allColumns: true,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: true,
        skipGroups: true,
        skipHeader: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,

    };
}