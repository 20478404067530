/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import EditActions from '../manage/EditActionsComponent';
import ExportStockyardPdf from './exportstockyardpdf';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM,getHyphenDDMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
//import { getConsignmentsNoGpsLifeTimeCounter } from '../../../../tpt_node/models/consignmentModel';

import CountUp from 'react-countup';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
// ModuleRegistry.registerModules([
//     ClientSideRowModelModule,
//     CsvExportModule,
//     ExcelExportModule,
//     MenuModule,
//   ]);
export default class StockyardTransportersInfo extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Parking Information",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
            frameworkComponents: {
				editActions: EditActions,
                ExportStockyardPdf: ExportStockyardPdf
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            defaultExcelExportParams: {
                allColumns: true,
            },
			plant_code:'',
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
            markersList:[],
            btnClicked:[],
            sliderUpdate:"",
            CellRendererFunc:[],
			detailCellRendererParams:{},
            transporter_name_options:[],
            selectedtransporter:{"label": "All", value: "All"},
            transporter_code:[],
            apl_logistics_data:[],
            ivc_logistics_data:[],
            nyk_adani_data:[],
            oriental_carriers_data:[],
            joshi_konoike_data:[],
            total_data:[],
            sliderRso:"",
            vin_no:"",
            car_model:"",
            transporter_manual_name:"",
            bay_no:"",
            gate_in_remarks:"",
            gate_out_remarks:"",
            time_spent_in_parking:"",
            gate_in_date:"",
            bay_allocation_date:"",
            gate_out_date:"",
            bay_deallocation_date:"",
            rowid:"",
            selectedRowNode: "",
            gurgaon_data:[],
            manesar_data:[],
            gujarat_data:[]


		};
		// this.popmarker=this.popmarker.bind(this);
        // this.onShowEditAction = this.onShowEditAction.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        this.setState({
            loadshow:'show-m'
        })
        loadDateTimeScript()
		redirectURL.post("/consignments/bayinformationdata").then((response)=>{
            var transporterInfo = response.data.data;
            console.log(transporterInfo,"bayInformation") 
            var gurgaon = transporterInfo.filter(e => e.oem_plant == "Gurgaon")
            var manesar = transporterInfo.filter(e => e.oem_plant == "Manesar")
            var gujarat = transporterInfo.filter(e => e.oem_plant == "Gujarat")
            // var nyk_adani_data = transporterInfo.filter(e => e.transporter_code == "T324")
            // var oriental_carriers_data = transporterInfo.filter(e => e.transporter_code == "T025")
            // var joshi_konoike_data = transporterInfo.filter(e => e.transporter_code == "T314")
            // console.log(apl_logistics_data,"apl_logistics_data")
            // console.log(ivc_logistics_data,"ivc_logistics_data")
            // console.log(nyk_adani_data,"nyk_adani_data")
            // console.log(oriental_carriers_data,"oriental_carriers_data")
            // console.log(joshi_konoike_data,"joshi_konoike_data")
            this.setState({
            loadshow:'show-n',
            rowData: transporterInfo,
            total_data:transporterInfo,
            gurgaon_data:gurgaon,
            manesar_data:manesar,
            gujarat_data:gujarat
            })
        })

    }
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	  }

formHandler = (event) =>{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}

        // var transporter_code = this.state.selectedtransporter;

        // var params ={
        //     transporter_code:this.state.selectedtransporter.value
        // }
        // redirectURL.post("/consignments/bayinformationfilterdata",params).then((response)=>{
        //     var transporterInfo = response.data;
        //     // console.log(transporterInfo,"transporterInfo")
        //     this.setState({
        //     rowData: transporterInfo,
        //     })
        // })
        
    }
	onClickHideAll(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",
            sliderRso: "",
            sliderUpdate:"",
            selectedRowNode: "",

		})
	}
    onClickTotalInfo = () => {
        var totaldata = this.state.total_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: totaldata
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickGurgaonDataInfo = () => {
        var gurgaondatainfo = this.state.gurgaon_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: gurgaondatainfo
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickManesarDataInfo = () => {
        var manesardatainfo = this.state.manesar_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: manesardatainfo
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickGujaratDataInfo = () => {
        var gujaratdatainfo = this.state.gujarat_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: gujaratdatainfo
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
   onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    onAddRow() {
		
		this.setState({
			sliderRso: 'slider-translate',
			selectedRowNode: "",
			overly: 'show-m',
		})
	

	}
    onBtExport = () => {
        // this.gridApi.exportDataAsExcel({skipColumnHeaders : false, skipColumnGroupHeaders : false})
        let params = getParams();
        this.gridApi.exportDataAsExcel(params);
    };

    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Parking Information';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			// console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		// console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}
    onGridState = () =>{
		//console.log(this.gridApi);
		
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
			
		
		
	}
    resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		
		var columnDefs= [
            // {
            //     headerName: "",
            //     field: "",
            //     colId: "",
            //     width: 50,
            //     // pinned: 'left',
            //     cellRendererSelector: function(params){
            //         var rendComponent = {
            //             component : 'editActions'
            //         }
            //         return rendComponent;
            //     },
            //     filter: false, resizable: true

            // },
            {
                headerName: "Truck No",
                  field: "gate_in_truck_no",
                  width: 200,
  
              },
			{
                headerName: "Vin Number",
                  field: "vin_number",
                  width: 200,
  
              },
              {
                headerName: "OEM Plant",
                field: "oem_plant",
                width: 200,

              },
              {
                headerName: "Dealer Name",
                field: "dealer_name",
                width: 200,

              },
              {
                headerName: "MSIL Invoice No",
                field: "invoice_no",
                width: 200,

              },
              {
                headerName: "Trip No",
                field: "trip_no",
                width: 200,

              },
              {
                headerName: "Load No",
                field: "load_no",
                width: 200,

              },
              {
                headerName: "MSIL Rake Id",
                field: "rake_no",
                width: 200,

              },
              {
                  headerName: "Car Name",
                  field: "car_name",
                  width: 200,
    
              },
              
               {
                  headerName: "Transporter",
                  field: "transporter_name",
                  width: 200,
  
              },
              {
                  headerName: "Gate In Time",
                  field: "gate_in_time",
                  width: 150,
                  valueGetter:function(params){
                      return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)}
              },
              {
                  headerName: "Bay In Damage Remarks",
                  field: "bay_in_damages",
                  width: 250,
  
              },
              {
                headerName: "Bay In Image",
                field: "",
                width: 250,

              },
              {
                  headerName: "Bay No",
                  field: "parking_cell",
                  width: 100,
    
              },
              {
                  headerName: "Bay Allocation Date",
                  field: "bay_in_time",
                  width: 150,
                  valueGetter:function(params){
                      return getHyphenDDMMMYYYYHHMM(params.data.bay_in_time)}
              },
              {
                  headerName: "Bay De-allocation Date",
                  field: "bay_out_time",
                  width: 150,
                  valueGetter:function(params){
                      return getHyphenDDMMMYYYYHHMM(params.data.bay_out_time)}
              },
              
              {
                  headerName: "Gate Out Time",
                  field: "gate_out_time",
                  width: 150,
                  valueGetter:function(params){
                      return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)}
              },
              {
                  headerName: "Bay Out Time Remarks",
                  field: "bay_out_damages",
                  width: 250,
  
              },
              {
                headerName: "Bay Out Image",
                field: "",
                width: 250,

              },
              {
                  headerName: "Time Spent in Parking(HH:MM)",
                  field: "parking_duration_secs",
                  width: 150,
                  valueGetter:function(params){
                    if(params.data.parking_duration_secs > 0)
                    {
                        var mre = secondsToHM(params.data.parking_duration_secs);
                        return mre;
                    }
                },
              },
              {
                headerName: "Allocation Type",
                field: "manual_entry",
                width: 150,
                valueGetter:function(params){
                    if(params.data.manual_entry == 1)
                    {
                        return "Manual"
                    }
                    else
                    {
                        return "Scanned"
                    }
                    }
            },
              {
                  headerName: "Receipt",
                  field: "receipt",
                  width: 150,
                //   cellRenderer: (params)=>{
                //       return "<i class='fa fa-print'></i>"
                //   }
                  cellRendererSelector: function(params){
                      var rendComponent = {
                          component : 'ExportStockyardPdf'
                      }
                      return rendComponent;
                  },
              },
              
		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}

        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
						<div className="card" >
							<div className="card-body" id="dashboardCounterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">
                                        <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickTotalInfo.bind(this)}>
                                                <span className="f15 txt-primary"><br /> Total </span>
                                                <h4 className="txt-primary f40"><span className="counter">
                                                <CountUp end= {(this.state.total_data.length)?this.state.total_data.length:0}/>
                                                </span></h4>
                                        </div>
                                        <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickGurgaonDataInfo.bind(this)}>
                                                <span className="f15 txt-secondary"><br /> Gurgaon </span>
                                                <h4 className="txt-secondary f40"><span className="counter">
                                                <CountUp end= {(this.state.gurgaon_data.length)?this.state.gurgaon_data.length:0}/>
                                                </span></h4>
                                            </div>
                                            <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickManesarDataInfo.bind(this)}>
                                                <span className="f15 txt-info"><br /> Manesar  </span>
                                                <h4 className="txt-info f40"><span className="counter">
                                                    <CountUp end= {(this.state.manesar_data.length)?this.state.manesar_data.length:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickGujaratDataInfo.bind(this)}>
                                                <span className="f15 txt-danger"><br /> Gujarat </span>
                                                <h4 className="txt-danger f40"><span className="counter">
                                                    <CountUp end= {(this.state.gujarat_data.length)?this.state.gujarat_data.length:0}/>
                                                    </span></h4>
                                            </div>
									</div>
								</div>

							</div>
						</div>
					</div>
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>
                                       {/* <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Manual Allotment</button> */}
                                       <button className="float-right export-btn white btn-danger" onClick={() => this.onBtExport()}style={{marginRight:"10px",padding:"6px"}}>
                                        Export to Excel</button>
                                        <span className="layoutbtns pull-right">
                                            <button className="float-right custom-btn white btn-danger"
                                            style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                                <i className="icofont icofont-save"></i> Save Grid Layout
                                            </button>
                                            <button className="float-right custom-btn white btn-info" style={{marginRight:"10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.resetState.bind(this)}>
                                                <i className="icofont icofont-refresh"></i> Reset Default Layout
                                            </button>
                                        </span>
								</h5>
				   			</div>
				   			<div className="card-body row">
							   <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
								   <div className={"col-xl-12 col-lg-12 "+this.state.showStateTrucksMsg}>
								   	{/* <p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p> */}
								   </div>

	                    			<div className="row">
                                        {/* <div className="col-xl-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Select Transporter : </label>
                                                <Select
                                                    value= {this.state.selectedtransporter}
                                                    // placeholder="All"
                                                    closeMenuOnSelect={true}
                                                    isMulti={false}
                                                    // onChange={this.getRailConsginments.bind(this)}
                                                    className={"border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={[{"label":"All",value:"All"},{"label":"APL LOGISTICS",value:"T275"},
                                                    {"label":"IVC LOGISTICS LIMITED",value:"T305"},{"label":"NYK ADANI",value:"T324"},
                                                    {"label":"ORIENTAL CARRIERS PVT.LTD",value:"T025"},{"label":"JOSHI KONOIKE TPT. & INFRA. PVT LTD",value:"T314"}]}
                                                    onChange={(e)=>{this.setState({selectedtransporter:e})}}
                                                />
                                            </div>
                                        </div>

										<div className="form-group col-xl-2 col-lg-2">
											<button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
										</div> */}
									</div>


								</form>
								<div className="row col-xl-12 col-lg-12 col-sm-12">

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

                                    <div className="col-xl-12 col-lg-12 ">

                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                masterDetail = {true}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                defaultExcelExportParams={this.state.defaultExcelExportParams}
                                                statusBar={this.state.statusBar}
                                                frameworkComponents={this.state.frameworkComponents}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                // enableRangeSelection={false}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                floatingFilter={false}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>

								</div>

                            </div>
				   		</div>
				   	</div>
				 </div>


				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
               
                <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						{/* <h4>
							Manual Allotment
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4> */}
					</div>
					{/* <div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
								
								
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Vin No</label>
                                        <input 
                                        type="text" 
                                        name="vin_no"
                                        id="vin_no"
                                        autoComplete="off"
                                        value={this.state.vin_no}
                                        className="form-control" 
                                        onChange={this.changeHandler.bind(this)} 
                                        />   
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Car Model</label>
                                        <input 
                                            type="text"
                                            name="car_model"
                                            id="car_model"
                                            autoComplete="off"
                                            value={this.state.car_model}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Transporter Name</label>
                                        <input 
                                            type="text"
                                            name="transporter_manual_name"
                                            id="transporter_manual_name"
                                            autoComplete="off"
                                            value={this.state.transporter_manual_name}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Bay No</label>
                                        <input 
                                            type="text" 
                                            name="bay_no"
                                            id="bay_no"
                                            autoComplete="off"
                                            value={this.state.bay_no}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Gate In Remarks</label>
                                            <input 
                                            type="text" 
                                            name="gate_in_remarks"
                                            id="gate_in_remarks"
                                            autoComplete="off"
                                            value={this.state.gate_in_remarks}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Gate Out Remarks</label>
                                            <input 
                                            type="text" 
                                            name="gate_out_remarks"
                                            id="gate_out_remarks"
                                            autoComplete="off"
                                            value={this.state.gate_out_remarks}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Time Spent In Parking</label>
                                            <input 
                                            type="text" 
                                            name="time_spent_in_parking"
                                            id="time_spent_in_parking"
                                            autoComplete="off"
                                            value={this.state.time_spent_in_parking}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Gate In Date</label>
                                        <input 
                                            type="text" 
                                            name="gate_in_date"
                                            id="gate_in_date"
                                            autoComplete="off"
                                            //value={this.state.challan_date}
                                            className="gate_in_date datetimepicker_mask form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Bay Allocation Date</label>
                                        <input 
                                            type="text" 
                                            name="bay_allocation_date"
                                            id="bay_allocation_date"
                                            autoComplete="off"
                                            //value={this.state.challan_date}
                                            className="bay_allocation_date datetimepicker_mask form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Gate Out Date</label>
                                        <input 
                                            type="text" bay_allocation_date
                                            name="gate_out_date"
                                            id="gate_out_date"
                                            autoComplete="off"
                                            //value={this.state.challan_date}
                                            className="gate_out_date datetimepicker_mask form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Bay De-allocation Date</label>
                                        <input 
                                            type="text" 
                                            name="bay_deallocation_date"
                                            id="bay_deallocation_date"
                                            autoComplete="off"
                                            //value={this.state.challan_date}
                                            className="bay_deallocation_date datetimepicker_mask form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
								

								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>


						</div>
					</div> */}
				</div>


            </div>
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{

	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);

}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }
  function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}
function loadDateTimeScript(){
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		onShow:false
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
		onShow:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function getParams() {

    return {
        allColumns:false,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: false,
        skipGroups: true,
        skipHeader: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
    };

  }