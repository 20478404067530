/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "@grapecity/wijmo.styles/wijmo.css";
import * as pdf from "@grapecity/wijmo.pdf";
import * as wijmo from "@grapecity/wijmo";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
var moment = require('moment');

export default class ExportStockyardPdf extends Component {

    constructor(props) {
        super(props);
        // this.colWidth = 130;
        this.rowHeight = 20;
        this.state =
        {
            rowData: [],
            pdfColumns: [],
            adaniCheck: 0,
            addressColumn: "",
            colWidth: null,
            pdfInfo:{
                signature:"",
                name:"",
                phone_number:""
            }

        };
    }

    componentDidMount() {
        console.log(this.props,"cnsole")
        var pdfRowData = this.props.data;
        var pdfInfo = {
            signature:pdfRowData.signature,
            name:pdfRowData.name,
            phone_number:pdfRowData.phone_number
        };
        var rowData = [];
        rowData.push({
            "label" : "VIN Number",
            "value" : pdfRowData.vin_number
        });
        // rowData.push({
        //     "label" : "OEM Plant",
        //     "value" : pdfRowData.oem_plant
        // });
        // rowData.push({
        //     "label" : "Dealer Name",
        //     "value" : pdfRowData.dealer_name
        // });
        // rowData.push({
        //     "label" : "MSIL Invoice No",
        //     "value" : pdfRowData.invoice_no
        // });
        // rowData.push({
        //     "label" : "Trip No",
        //     "value" : pdfRowData.trip_no
        // });
        // rowData.push({
        //     "label" : "Load No",
        //     "value" : pdfRowData.load_no
        // });
        rowData.push({
            "label" : "MSIL Rake Id",
            "value" : pdfRowData.rake_no
        });
        // rowData.push({
        //     "label" : "Car Model",
        //     "value" : pdfRowData.car_name
        // });
        rowData.push({
            "label" : "Transporter",
            "value" : pdfRowData.transporter_name
        });
        var gate_in_time = "";
        if(pdfRowData.gate_in_time !=undefined && pdfRowData.gate_in_time !="")
        {
            gate_in_time = getHyphenYYYYMMDDHHMMSS(pdfRowData.gate_in_time)
        }        
        rowData.push({
            "label" : "Gate In Date & Time",
            "value" : gate_in_time
        });
        var gate_in_damage = "";
        if(pdfRowData.bay_in_damages !=undefined && pdfRowData.bay_in_damages !="")
        {
            gate_in_damage = pdfRowData.bay_in_damages
        }        
        rowData.push({
            "label" : "Gate In Remarks",
            "value" : gate_in_damage
        });
        rowData.push({
            "label" : "Bay No",
            "value" : pdfRowData.parking_cell
        });
        var bay_out_time = "";
        if(pdfRowData.bay_out_time !=undefined && pdfRowData.bay_out_time !="")
        {
            bay_out_time = getHyphenYYYYMMDDHHMMSS(pdfRowData.bay_out_time)
        }        
        rowData.push({
            "label" : "Bay De-allocation Date & Time",
            "value" : bay_out_time
        });
        var gate_out_time = "";
        if(pdfRowData.gate_out_time !=undefined && pdfRowData.gate_out_time !="")
        {
            gate_out_time = getHyphenYYYYMMDDHHMMSS(pdfRowData.gate_out_time)
        }        
        rowData.push({
            "label" : "Gate Out Date & Time",
            "value" : gate_out_time
        });
        var gate_out_damage = "";
        if(pdfRowData.bay_out_damages !=undefined && pdfRowData.bay_out_damages !="")
        {
            gate_out_damage = pdfRowData.bay_out_damages
        }        
        rowData.push({
            "label" : "Gate Out Remarks",
            "value" : gate_out_damage
        });
        var parking_duration_secs = "";
        if(pdfRowData.gate_in_time != "" && pdfRowData.gate_in_time != undefined && pdfRowData.gate_out_time != "" && pdfRowData.gate_out_time != undefined)
        {
            var firstinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(pdfRowData.gate_in_time))).utcOffset("+05:30")._d
            // console.log(firstinstance,"firstinstance")
            var recentinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(pdfRowData.gate_out_time))).utcOffset("+05:30")._d
            // console.log(recentinstance,"firstinstance")
            var parking_duration_secs = (recentinstance - firstinstance)
            // console.log(parking_duration_secs,"data data1")
            parking_duration_secs = secondsToHM(parking_duration_secs/1000);
        }
        else{
            parking_duration_secs = ""
        }
        // console.log(parking_duration_secs,"data data")
        rowData.push({
            "label" : "Time Spent in Parking",
            "value" : parking_duration_secs
        });
        var pdfColumns = [             
            { header: 'Label', binding: 'label'},
            { header: 'Value', binding: 'value'}						
        ]
        var addressColumnWidth = this.props.addressColumn;
        console.log("pdfrowData", rowData)
        if (addressColumnWidth == "undefined" || addressColumnWidth == "" || addressColumnWidth == null) {
            this.setState({
                colWidth: 130
            })
        } else {
            this.setState({
                colWidth: addressColumnWidth
            })
        }
        this.setState({
            rowData: rowData,
            signature:pdfRowData.vin_number+".png",
            pdfColumns: pdfColumns,
            pdfInfo: pdfInfo
        })
        console.log("rowDataTest: => ", rowData)
    }

    componentWillReceiveProps(newprops) {
        console.log("receivedProps",newprops)
        var pdfRowData = newprops.data;
        var pdfInfo = {
            signature:pdfRowData.signature,
            name:pdfRowData.name,
            phone_number:pdfRowData.phone_number
        };
        var rowData = [];
        rowData.push({
            "label" : "VIN Number",
            "value" : pdfRowData.vin_number
        });
        // rowData.push({
        //     "label" : "OEM Plant",
        //     "value" : pdfRowData.oem_plant
        // });
        // rowData.push({
        //     "label" : "Dealer Name",
        //     "value" : pdfRowData.dealer_name
        // });
        // rowData.push({
        //     "label" : "MSIL Invoice No",
        //     "value" : pdfRowData.invoice_no
        // });
        // rowData.push({
        //     "label" : "Trip No",
        //     "value" : pdfRowData.trip_no
        // });
        // rowData.push({
        //     "label" : "Load No",
        //     "value" : pdfRowData.load_no
        // });
        rowData.push({
            "label" : "MSIL Rake Id",
            "value" : pdfRowData.rake_no
        });
        // rowData.push({
        //     "label" : "Car Model",
        //     "value" : pdfRowData.car_name
        // });
        rowData.push({
            "label" : "Transporter",
            "value" : pdfRowData.transporter_name
        });
        var gate_in_time = "";
        if(pdfRowData.gate_in_time !=undefined && pdfRowData.gate_in_time !="")
        {
            gate_in_time = getHyphenYYYYMMDDHHMMSS(pdfRowData.gate_in_time)
        }        
        rowData.push({
            "label" : "Gate In Date & Time",
            "value" : gate_in_time
        });
        var gate_in_damage = "";
        if(pdfRowData.bay_in_damages !=undefined && pdfRowData.bay_in_damages !="")
        {
            gate_in_damage = pdfRowData.bay_in_damages
        }        
        rowData.push({
            "label" : "Gate In Remarks",
            "value" : gate_in_damage
        });
        rowData.push({
            "label" : "Bay No",
            "value" : pdfRowData.parking_cell
        });
        var bay_out_time = "";
        if(pdfRowData.bay_out_time !=undefined && pdfRowData.bay_out_time !="")
        {
            bay_out_time = getHyphenYYYYMMDDHHMMSS(pdfRowData.bay_out_time)
        }        
        rowData.push({
            "label" : "Bay De-allocation Date & Time",
            "value" : bay_out_time
        });
        var gate_out_time = "";
        if(pdfRowData.gate_out_time !=undefined && pdfRowData.gate_out_time !="")
        {
            gate_out_time = getHyphenYYYYMMDDHHMMSS(pdfRowData.gate_out_time)
        }        
        rowData.push({
            "label" : "Gate Out Date & Time",
            "value" : gate_out_time
        });
        var gate_out_damage = "";
        if(pdfRowData.bay_out_damages !=undefined && pdfRowData.bay_out_damages !="")
        {
            gate_out_damage = pdfRowData.bay_out_damages
        }        
        rowData.push({
            "label" : "Gate Out Remarks",
            "value" : gate_out_damage
        });
        var parking_duration_secs = "";
        if(pdfRowData.gate_in_time != "" && pdfRowData.gate_in_time != undefined && pdfRowData.gate_out_time != "" && pdfRowData.gate_out_time != undefined)
        {
            var firstinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(pdfRowData.gate_in_time))).utcOffset("+05:30")._d
            // console.log(firstinstance,"firstinstance")
            var recentinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(pdfRowData.gate_out_time))).utcOffset("+05:30")._d
            // console.log(recentinstance,"firstinstance")
            var parking_duration_secs = (recentinstance - firstinstance)
            // console.log(parking_duration_secs,"data data1")
            parking_duration_secs = secondsToHM(parking_duration_secs/1000);
        }
        else{
            parking_duration_secs = ""
        }
        // console.log(parking_duration_secs,"data data")        
        rowData.push({
            "label" : "Time Spent in Parking",
            "value" : parking_duration_secs
        });
        console.log("pdfrowData", rowData)
        this.setState({
            colWidth: 130
        })
        var pdfColumns = [             
            { header: 'Label', binding: 'label'},
            { header: 'Value', binding: 'value'}						
        ]
        this.setState({
            rowData: rowData,
            signature:pdfRowData.signature,
            pdfColumns: pdfColumns,
            pdfInfo: pdfInfo
        })
        console.log("rowDataTest: => ", rowData)
    }

    exportPDF() {
        let doc = new pdf.PdfDocument({
            header: {
                declarative: {
                    text: this.props.pagetitle,
                    font: new pdf.PdfFont('times', 30),
                    brush: '#bfc1c2'
                }
            },
            footer: { declarative: { text: '\t&[Page] of &[Pages]' } },
            lineGap: 2,
            pageSettings: {
                margins: {
                    left: 30,
                    right: 30,
                    top: 30,
                    bottom: 30
                },
                size: "A4"
            },
            pageAdded: function (s, e) {
                let image = require('../../assets/icons/poweredbyenmovil.png');
                console.log(localStorage.getItem("transportercode"), localStorage.getItem("transportercode").indexOf("T025") >= 0)
                if(localStorage.getItem("transportercode").indexOf("T305") >= 0){
                    console.log("T305")
                    var enmovilImage = require('../../assets/icons/ivclogo.png');
                }
                if(localStorage.getItem("transportercode").indexOf("T025") >= 0){
                    console.log("T025")
                    var enmovilImage = require('../../assets/icons/oriental_Carrier_image.png');
                }                //
                // s.drawImage(image, null, null, {
                //     width: 150,
                //     height: 30,
                //     stretchProportionally: true,
                //     align: pdf.PdfImageHorizontalAlign.Center
                // });
                s.drawImage(enmovilImage, 150, 0, {
                    width: 250,
                    height: 90,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Right
                });
            },
            ended: (sender, args) => pdf.saveBlob(args.blob, 'Document.pdf')
        });
        let image = require('../../assets/icons/poweredbyenmovil.png');
        doc.footer.drawImage(image, null, null, {
            width: 150,
            height: 50,
            stretchProportionally: true,
            align: pdf.PdfImageHorizontalAlign.Left
        });

        // doc.footer.drawText("Receipt generated on enmovil.io by Enmovil Solutions", null, null, {
        //     align: pdf.PdfTextHorizontalAlign.Right
        // });

        doc.drawText("STOCKYARD RECEIPT", 0, 80, {
            height: 0,
            width: 300,
            align: pdf.PdfTextHorizontalAlign.Left,
            font: new pdf.PdfFont("times", 14, "normal", "bold")
        });
        var consignees = this.state.rowData;
        // console.log("cCheck=:",this.state.rowData)
        // console.log(consignees,"consignees")

        if (consignees.length > 75) {
            var totalPages = (consignees.length / 75).toString();
            var lastRemainder = parseInt(totalPages.split(".")[1])
            totalPages = parseInt(totalPages.split(".")[0])
            //console.log(totalPages,"totalPages")
            //console.log(lastRemainder,"lastRemainder")
            var endPage = 75
            var startPage = 0
            while (consignees.length !== 0) {
                let sen = consignees.splice(0, 75)
                this.$_drawEmployee(doc, sen);
            }
            
        }
        else {
            this.$_drawEmployee(doc, consignees);
        }
        doc.end();
    }
    //
    $_drawEmployee(doc, consignees) {
        //let tot = employee.expenses.totals;
        let expenses = consignees;
        // console.log(expenses, "expenses")

        if (expenses.length > 0) {
            let columns = this.state.pdfColumns, bold = new pdf.PdfFont('times', 30, 'normal', 'bold'),
                colWidth = this.state.colWidth, rowHeight = this.rowHeight;

            let y = doc.y;

            doc.moveDown(2);

            // * draw table *
            doc.saveState();

            y = 0;
            let scale = doc.width / (columns.length * colWidth), docY = doc.y;

            if (scale > 1) {
                scale = 1;
            }

            doc.scale(scale, scale, new wijmo.Point(0, docY));
            doc.translate(0, docY);

            // header
            // this.$_renderRow(doc, y, columns, column => column.header, null, bold, '#fad9cd');

            y += rowHeight;

            // body

            expenses.map((item, index) => {
                //this.$_checkLineAvailable(doc);  
                this.$_renderRow(doc, y, columns, column => item[column.binding], column => column.format);
                y += rowHeight;
            });
            // try{
            //     let stamp = require('../../assets/icons/ivc_stamp.png');
            //     doc.drawImage(stamp, 0, 350, {
            //         width: 150,
            //         height: 100,
            //         stretchProportionally: true,
            //         align: pdf.PdfImageHorizontalAlign.Left
            //     });
            // }
            // catch(e){

            // }
            doc.drawText("Signature of the Carrier : ", 0, 400, {
                align: pdf.PdfTextHorizontalAlign.Left
            });
            doc.drawText("Name of the Carrier : "+this.state.pdfInfo.name, 0, 430, {
                align: pdf.PdfTextHorizontalAlign.Left
            });
            doc.drawText("Mobile No : "+this.state.pdfInfo.phone_number, 0, 460, {
                align: pdf.PdfTextHorizontalAlign.Left
            });
           
            console.log(localStorage.getItem("transportercode"), localStorage.getItem("transportercode").indexOf("T025") >= 0)
            if(localStorage.getItem("transportercode").indexOf("T305") >= 0){
                console.log("T305")
                var stamp = require('../../assets/icons/ivc_stamp.png');
            }
            if(localStorage.getItem("transportercode").indexOf("T025") >= 0){
                console.log("T025")
                var stamp = require('../../assets/icons/oriental_carrier_logo.png');
            }
                doc.drawImage(stamp, 370, 360, {
                    width: 150,
                    height: 100,
                    stretchProportionally: true,
                    align: pdf.PdfImageHorizontalAlign.Right
                });
                try{
                    let sign = require('../../assets/icons/signature_stockyard.png');
                    doc.drawImage(sign, 420, 360, {
                        width: 150,
                        height: 100,
                        stretchProportionally: true,
                        align: pdf.PdfImageHorizontalAlign.Right
                    });
                }
                catch(e){
    
                }
                doc.drawText("Signature Of Stockyard Manager", 0, 460, {
                    align: pdf.PdfTextHorizontalAlign.Right
                });    
            let image = require('../../assets/icons/poweredbyenmovil.png');
            // doc.drawImage(image, null, 1000, {
            //     width: 150,
            //     height: 30,
            //     stretchProportionally: true,
            //     align: pdf.PdfImageHorizontalAlign.Center
            // });
            // doc.addPage(doc.currentPageSettings);
            doc.footer.drawImage(image, null, null, {
                width: 150,
                height: 50,
                stretchProportionally: true,
                align: pdf.PdfImageHorizontalAlign.Left
            });
            // doc.footer.drawText("Report generated on enmovil.io by Enmovil Solutions", null, null, {
            //     align: pdf.PdfTextHorizontalAlign.Right
            // });

        }
    }
    //
    $_checkLineAvailable(doc) {
        if (doc.height - doc.y < doc.lineHeight() + doc.lineGap) {
            doc.addPage();
        }
    }
    //
    $_renderRow(doc, y, values, valueGetter, formatGetter, font, brush) {
        let colWidth = this.state.colWidth, rowHeight = this.rowHeight;
        //
        values.forEach((v, idx) => {
            let x = idx * colWidth;
            //
            doc.paths
                .rect(x, y, colWidth, rowHeight)
                //.fill(brush || '#eaeaea')
                .stroke();
            //
            let value = valueGetter != null ? valueGetter(v) : v || '';
            let format = formatGetter != null ? formatGetter(v) : '';
            //
            if (value !== 'Total') {
                value = wijmo.changeType(value, wijmo.DataType.String, format);
            }
            //
            doc.drawText(value, x + 1, y + 5, {
                font: font,
                height: rowHeight,
                width: colWidth
            });
        });
        doc.moveDown(5);
    }

    render() {
        return (
            // <button className="btn btn-danger" style={{ float: "right", marginRight: "10px" }} onClick={this.exportPDF.bind(this)}>Export To PDF</button>
            <i class='fa fa-print' onClick={this.exportPDF.bind(this)}></i>
        )
    }
}
function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}