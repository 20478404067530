/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import CommentActions from "../layouts/commentsComponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import Consignmentforceclose from '../manage/consignmentforceclose';
// import SOBchangelogs from "./sobchangelog";
// import SobCompleteData from "./sobcompletedata";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import { getHyphenDDMMMYYYYHHMM } from '../common/utils';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");




export default class ShareOfBusiness extends Component {
    state = {
        modules: AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: false,
        },
        eventAction: null,
        show: false,
        frameworkComponents: {
        },
        modified_by: "",
        basicTitle: '',
        reasonforceclose: "",
        uploadFile: "",
        basicType: "default",
        forceCloseRowNode: "",
        forceclosedata: "",
        csvcontent: "",
        sliderForceCloseTranslate: "",
        rowData: [],
        staterowData: [],
        bulkslide: "",
        showDiv: "",
        uploadDivWidth: "",
        mapinfo: "",
        dealer: "",
        sliderCommentTranslate: "",
        commentsRowData: "",
        consignment_code: "",
        sliderRouteTranslate: "",
        maptruckno: "",
        routeTruck: "",
        rownode: "",
        googelRoutes: "",
        file: "",
        rowSelection: 'multiple',
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        paginationPageSize: 200,
        geofencelist: [],
        geoFenceData: "",
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        animateRows: true,
        dept_code: "",
        pagetitle: "",
        markDeliverRowNode: "",
        markDeliverData: "",
        sliderMarkDeliverTranslate: "",
        csvData: 0,
        overly: "show-n",
        loadshow: "show-n",
        holidayEvents: [],
        showCalendar: "show-m",
        showGridData: "show-m",
        columnwithDefs: [],
        countersData: [],
        rowClassRules: {
            "fontBold": function (params) {
                if (params.data.transporter == "Total - Plan" || params.data.transporter == "Total - Actual") {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        plan_month: "2021-07",
        select_month: "",
        showType: "show-n",
        transportersList: [],
        sliderType: 1,
        colorArray: [],
        logHistory: [],
        last_updated_on: "",
        completeData: [],
        sourceDestination: [],
        transporter_code: "",
        items: [],
        dataChanged: false
        //components : {datepicker:getDatePicker()}
    }


    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    async componentDidMount() {
        this.logPageView();
        loadDateTimeScript();
        redirectURL.post("/consignments/getTNPTransportersList").then((response) => {
            var transportersList = response.data;
            this.setState({
                transportersList: transportersList
            });
        });
        // var currentMonth = moment.parseZone().subtract(1, 'months').format("YYYY-MM");
        var currentMonth = moment.parseZone().format("YYYY-MM");
        //console.log(currentMonth,"currentMonth")
        await this.setState({
            plan_month: currentMonth
        })
        redirectURL.post("/consignments/getSOBSources").then((response) => {
            var sourceDestination = response.data.sourceDestination;
            this.setState({
                sourceDestination: sourceDestination
            });
        });
        redirectURL.post("/consignments/getOperationAssignDropDown").then((response) => {
            var items = response.data.smg;
            this.setState({
                items: items
            });
        });
        await this.onLoadPageData();
    }
    async onLoadPageData() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var plan_month = this.state.plan_month
        var tptcode = JSON.parse(localStorage.getItem("transportercode"))
        var params = {
            plan_month: this.state.plan_month,
            transporter_code: tptcode[0]
        }
        // console.log(params, "params")
        redirectURL.post("/consignments/getSOBLogHistory", params).then((response) => {
            var logHistory = response.data;
            var last_updated_on = "";
            if (logHistory.length > 0) {
                if (logHistory[0].updated_on != undefined && logHistory[0].updated_on != "") {
                    last_updated_on = getHyphenDDMMMYYYYHHMM(logHistory[0].updated_on);
                }
            }
            this.setState({
                logHistory: logHistory,
                last_updated_on: last_updated_on
            });
        });
        redirectURL.post("/consignments/getShareOfBusiness", params).then(async (response) => {
            // console.log(response.data,"SOB");
            var responseData = response.data.monthly_sob;
            if (response.data.status !== 'Failure') {
                if (responseData != undefined && responseData != '') {
                    var rowData = eval(responseData);
                    // console.log(rowData,"rowData");
                    var countersData = [];
                    var columns = []
                    if (rowData.length > 0) {
                        // ROW TOTAL
                        var planTotal = [];
                        await rowData.map(async function (g) {
                            if (g.plan_type == 'plan') g.plan_type = 'Plan'
                            if (g.plan_type == 'actual') g.plan_type = 'Actual'
                            var rowDataKeys = Object.keys(g);
                            if (g.plan_type == "Plan") {
                                var rowTotal = 0;
                                var rowGapTotal = 0;
                                await rowDataKeys.map(async function (rdk) {
                                    if (rdk != "oem_copy" && rdk != "oem_code" && rdk != "oem_name" && rdk != "plant_location_code" && rdk != "plant_location_name" && rdk !=
                                        "plan_type" && rdk != "plan_total" && rdk != "plan_date" && rdk != "_id" && rdk != "plant_location_name_new") {
                                        rowGapTotal = parseInt(rowGapTotal) + parseInt(g[rdk]);
                                    }
                                })
                                planTotal.push({
                                    "oem_code": g.oem_code,
                                    "plant_location_code": g.plant_location_code,
                                    "plan_total": rowGapTotal,
                                });
                            }
                        })

                        // OEM ROW SPAN
                        var locationGroup = groupBy(rowData, rdata => rdata.oem_name);
                        // console.log(locationGroup,"locationGroup")
                        var lgArray = [];
                        await locationGroup.forEach((lg, lgkey) => {
                            lgArray.push({
                                oemKey: lgkey,
                                oemLength: lg.length
                            });
                        })
                        var keys = Object.keys(rowData[0]);
                        var wrapText = false;
                        var autoHeight = false;
                        await keys.forEach(async (z, k) => {
                            if (z != "_id" && z != "plant_location_name_new") {
                                var keyVal = z;
                                var hwid = 100;
                                var pinnedLeft = "";
                                var hideField = false;
                                var isEditable = false;
                                var showFilter = false;
                                var hideFilter = true;
                                if (z == "oem_name") {
                                    keyVal = "OEM Name";
                                    hwid = 200;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                    hideField = true;
                                }
                                if (z == "oem_copy") {
                                    keyVal = "OEM Name";
                                    hwid = 300;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                    showFilter = true;
                                    hideFilter = false;
                                }
                                if (z == "oem_code") {
                                    keyVal = "OEM Code";
                                    hwid = 120;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                }
                                if (z == "plant_location_code") {
                                    keyVal = "Location Code";
                                    hwid = 120;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                    hideField = false;
                                    //wrapText = true;
                                    //autoHeight = true;
                                }
                                if (z == "plant_location_name") {
                                    keyVal = "Location Name";
                                    hwid = 120;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                }
                                if (z == "plan_type") {
                                    keyVal = "Plan Type";
                                    hwid = 120;
                                    pinnedLeft = "left";
                                    isEditable = false;
                                }
                                if (z == "plan_total") {
                                    keyVal = "Total";
                                    hwid = 100;
                                    pinnedLeft = "right";
                                    isEditable = false;
                                }
                                if (z != "oem_copy" && z != "oem_code" && z != "oem_name" && z != "plant_location_code" && z != "plant_location_name" && z !=
                                    "plan_type" && z != "plan_total" && z != "plan_date" && z != "_id" && z != "plant_location_name_new") {
                                    hwid = 100;
                                    isEditable = true;
                                    // console.log("z316",z)
                                }
                                // console.log("z337",z, isEditable)
                                
                                if (z == "oem_copy") {
                                    columns.push({
                                        headerName: keyVal,
                                        field: z,
                                        width: hwid,
                                        pinned: pinnedLeft,
                                        editable: function (params) {
                                            if (params.data.plant_location_code.includes("Total") == true || params.data.plan_type.includes('Actual') == true) {
                                                return false;
                                            }
                                            else {
                                                return isEditable
                                            }
                                        },
                                        resizable: true,
                                        hide: hideField,
                                        filter: true,
                                        sortable: false,
                                        cellClass: function (params) {
                                            if (z == "oem_copy") {
                                                if (params.data[z] != "") {
                                                    return ["cellstylegridG", "pstBg"]
                                                }
                                            }
                                        },
                                        cellStyle: function (params) {
                                            // if(z == "transporter")
                                            // {
                                            if (params.data.plant_location_code == "total" || params.data.plant_location_code == "Total") {
                                                return { fontWeight: "bold", borderRight: '1px solid #ccc' }
                                            }
                                            // }
                                            if (z == "oem_copy") {
                                                if (params.data[z] != "") {
                                                    return { borderBottom: '1px solid #ccc' };
                                                }
                                            }
                                            if (z != "oem_copy") {
                                                if (isEditable == true) {
                                                    return { borderRight: '1px solid #ccc' }
                                                }
                                                else {
                                                    return { backgroundColor: "#eee", borderRight: '1px solid #ccc' }
                                                }
                                            }
                                        },
                                        rowSpan: (params) => {
                                            if (z == "oem_copy") {
                                                if (params.data[z] != "") {
                                                    if (lgArray.length > 0) {
                                                        var filteredData = lgArray.filter(function (obj) {
                                                            return (obj.oemKey === params.data[z]);
                                                        });
                                                        return filteredData[0].oemLength
                                                    }
                                                }
                                            }
                                        },
                                        // valueSetter : params =>{
                                        //     console.log("params406",params)
                                        // },
                                        cellEditor: NumericCellEditor,
                                        wrapText: wrapText,
                                        autoHeight: autoHeight,
                                    })
                                }
                                else
                                {
                                    columns.push({
                                        headerName: keyVal,
                                        field: z,
                                        width: hwid,
                                        pinned: pinnedLeft,
                                        editable: function (params) {
                                            if (params.data.plant_location_code.includes("Total") == true || params.data.plan_type.includes('Actual') == true) {
                                                return false;
                                            }
                                            else {
                                                return isEditable
                                            }
                                        },
                                        resizable: true,
                                        hide: hideField,
                                        filter: false,
                                        suppressMenu: true,
                                        sortable: false,
                                        cellClass: function (params) {
                                            if (z == "oem_copy") {
                                                if (params.data[z] != "") {
                                                    return ["cellstylegridG", "pstBg"]
                                                }
                                            }
                                        },
                                        cellStyle: function (params) {
                                            // if(z == "transporter")
                                            // {
                                            if (params.data.plant_location_code == "total" || params.data.plant_location_code == "Total") {
                                                return { fontWeight: "bold", borderRight: '1px solid #ccc' }
                                            }
                                            // }
                                            if (z == "oem_copy") {
                                                if (params.data[z] != "") {
                                                    return { borderBottom: '1px solid #ccc' };
                                                }
                                            }
                                            if (z != "oem_copy") {
                                                if (isEditable == true) {
                                                    return { borderRight: '1px solid #ccc' }
                                                }
                                                else {
                                                    return { backgroundColor: "#eee", borderRight: '1px solid #ccc' }
                                                }
                                            }
                                        },
                                        rowSpan: (params) => {
                                            if (z == "oem_copy") {
                                                if (params.data[z] != "") {
                                                    if (lgArray.length > 0) {
                                                        var filteredData = lgArray.filter(function (obj) {
                                                            return (obj.oemKey === params.data[z]);
                                                        });
                                                        return filteredData[0].oemLength
                                                    }
                                                }
                                            }
                                        },
                                        // valueSetter : params =>{
                                        //     console.log("params406",params)
                                        // },
                                        cellEditor: NumericCellEditor,
                                        wrapText: wrapText,
                                        autoHeight: autoHeight,
                                    })
                                }
                            }
                        })
                    }
                }
                //console.log("columns tg", columns)
                // console.log("countersData - 678", countersData)
                // console.log("columns - 678", columns)
                await this.setState({
                    columnwithDefs: columns,
                    rowData: rowData,
                    staterowData: rowData,
                    countersData: countersData,
                    loadshow: "show-n",
                    overly: "show-n",
                })
            } else {
                this.setState({
                    columnwithDefs: [],
                    staterowData: [],
                    countersData: [],
                    rowDate: [],
                    loadshow: "show-n",
                    overly: "show-n",
                    basicTitle: "No Data Found.",
                    basicType: "info",
                    show: true
                })
            }
        }).catch((e) => {
            console.log(e);
        })
        const firstdate = moment(plan_month).startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const lastdate = moment(plan_month).endOf('month').format("YYYY-MM-DD HH:mm:ss");

        var reqparams = {
            month: plan_month,
            startDate: firstdate,
            endDate: lastdate
        }
        // console.log("reqparams", reqparams)
        var completeData = [];
        // redirectURL.post("/consignments/getSobCompleteData",reqparams)
        // .then((response) => {
        //     console.log("response.data", response.data)

        //     response.data.map((item)=> {
        //         completeData.push({
        //             truck_no: item.truck_no,
        //             consignment_code : item.consignment_code,
        //             gate_out_time : getHyphenDDMMMYYYYHHMM(item.gate_out_time),
        //             consigner_code: item.consigner_code,
        //             consignee_code: item.consignee_code,
        //             transporter_code:item.transporter_code,
        //             transporter_name: item.transporter_name,
        //             items:item.items,
        //             tnp_user_dept_code: item.tnp_user_dept_code
        //         })
        //     });        
        //     this.setState({
        //         completeData: completeData,
        //     })
        // })
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }



    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onCellClicked = async (e) => {
        // console.log(e,"clickedData")
        var updateCheck = 0;
        var clickedValue = parseInt(e.value);
        var clickedField = e.colDef.field;
        var clickedOemCode = e.data.oem_code;
        var clickedLocation = e.data.plant_location_code;
        var clickedPlantLocationNameNew = e.data.plant_location_name_new;
        // var clickedItemType = e.data.item_type;
        // var clickedPlanDate = e.data.plan_date;
        var clickedTotal = e.data.total;
        var includeCheck = "";
        // stateRowData.map(function(s){
        //     if(s.location == clickedLocation && s.item_type == clickedItemType && s.transporter == clickedTransporter)
        //     {
        //         console.log(s.location,clickedLocation,"location")
        //         console.log(s.item_type,clickedItemType,"item_type")
        //         console.log(s.transporter,clickedTransporter,"transporter")
        //         console.log(clickedField,"clickedField")
        //         console.log(s[clickedField],clickedValue)
        //         if(s[clickedField] != clickedValue)
        //         {
        //             updateCheck = 1;
        //         }

        //     }
        // })
        // console.log(clickedValue,"clickedValue")
        if (clickedValue >= 0 && clickedValue != '' && clickedValue != undefined && clickedValue !== '0') {
            // console.log("if 528")

            updateCheck = 1;
        }
        else {
            // console.log("else 530")
            var rowData = this.state.rowData;
            // console.log(clickedLocation,clickedOemCode,"update total")
            rowData.map(async function (rd) {

                // console.log(rd.location,rd.transporter,rd.item_type ,"Bhanu Teja--3")
                if (rd.plan_type == "Plan") {
                    if (rd.oem_code == clickedOemCode && rd.plant_location_code == clickedLocation) {
                        //console.log(clickedField,clickedLocation,clickedTransporter,clickedItemType,"Bhanu Teja")
                        var rowDataKeys = Object.keys(rd);
                        //console.log(rowDataKeys)
                        await rowDataKeys.map(async function (rdk) {
                            //console.log(rdk,clickedField)
                            if (rdk == clickedField) {
                                rd[rdk] = 0;
                            }
                        })
                    }
                }
            })
            clickedValue = 0;
            updateCheck = 1;
        }
        // console.log(updateCheck,"updateCheck")
        if (updateCheck == 1) {
            var rowData = this.state.rowData;
            // console.log(clickedField, clickedLocation, clickedOemCode, "update total")
            var colTotal = 0;
            await rowData.map(async rd => {
                var rowDataKeys = Object.keys(rd);
                //console.log(rowDataKeys.length,"rowDataKeys");
                var rowTotal = 0;
                if (rd.oem_code == clickedOemCode) {
                    if (rd.plan_type == 'Plan') {
                        if (rd.plant_location_code !== 'Total') {
                            colTotal = parseInt(colTotal) + parseInt(rd[clickedField]);
                            // console.log('colTotal589',colTotal)
                        }
                        else {
                            // console.log('colTotal592',colTotal)
                            rd[clickedField] = colTotal;
                        }
                    }
                }
                rowDataKeys.map(rdk => {
                    if (rdk != "oem_copy" && rdk != "oem_code" && rdk != "oem_name" && rdk != "plant_location_code" && rdk != "plant_location_name" && rdk !=
                        "plan_type" && rdk != "plan_total" && rdk != "plan_date" && rdk != "_id" && rdk != "plant_location_name_new") {
                        //console.log(rdk,"Bhanu Teja")
                        //console.log(parseInt(rowTotal),parseInt(rd[rdk]),"Bhanu Teja")
                        rowTotal = parseInt(rowTotal) + parseInt(rd[rdk]);
                    }
                });
                rd.plan_total = rowTotal;
                rowTotal = 0;
            })
            var colTotal = 0;

            await this.setState({
                rowData: rowData,
                dataChanged: true
            })
            await this.gridApi.refreshCells();
        }
        else {
            // await this.setState({
            //     rowData : rowData
            // })
            // await this.gridApi.refreshCells();
        }
    }

    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",

        });

    }

    addNewTransporter = (e) => {
        e.preventDefault();
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        //console.log(params);
        var location = $("#add_location").val();
        var item_type = "";
        if (location == "MSIL-M") {
            item_type = $("#add_location").val();
        }
        else if (location == "GGN") {
            item_type = "All";
        }
        else if (location == "MPT") {
            item_type = "ALL";
        }
        var lsp = $("#add_lsp").val();
        var select_month = this.state.select_month;
        var sliderType = this.state.sliderType;
        if (location != '' && location != undefined && item_type != '' && item_type != undefined && lsp != '' && lsp != undefined && select_month != "" && select_month != undefined && sliderType != "" && sliderType != undefined) {
            var params = {
                month: select_month,
                location: location,
                item_type: item_type,
                lsp: lsp,
                sliderType: sliderType
            }
            redirectURL.post("/consignments/updateSOBTransporter", params).then((response) => {
                // console.log(response,'response626');
                if (response.data.status == "success") {
                    this.setState({
                        basicTitle: "Success",
                        basicType: "success",
                        show: true,
                        loadshow: 'show-n',
                        overly: 'show-n',
                        bulkslide: "",
                    })
                    this.onLoadPageData();
                }
                else {
                    this.setState({
                        basicTitle: "Failed.",
                        basicType: "warning",
                        show: true
                    })
                }
            })
        }
        else {
            this.setState({
                basicTitle: "Fields Should not be Empty.",
                basicType: "warning",
                show: true
            })
        }
    }
    showGrid() {
        this.setState({
            showCalendar: "show-n",
            showGridData: "show-m"
        })
    }
    showBulkUpload() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate",
            sliderType: 1,
        })
    }
    deleteTransporter() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate",
            sliderType: 2,
        })
    }
    showLogData() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate-60p",
            sliderType: 3,
        })
    }
    showCompleteData() {
        this.setState({
            overly: "show-m",
            bulkslide: "slider-translate-60p",
            sliderType: 4,
        })
    }
    resetUpload = () => {
        this.setState({
            bulkslide: '',
            overly: 'show-n',
            file: ''
        });
        document.getElementById("upform").reset();
    }
    // formAssignHandler = (event) => {
    //     event.preventDefault();
    //     if (googleAnalytics.page.enableGA) {
    //         let pageTitle = this.state.pageTitle;
    //         let eventOptions = {
    //             "category": pageTitle,
    //             "action": this.state.eventFormAction,
    //             "label": googleAnalytics.page.action.formSubmittedUpdateNow,
    //         }
    //         googleAnalytics.logEvent(eventOptions);
    //     }
    //     this.setState({
    //         loadshow:'show-m'   
    //     });
    //     var itemsToUpdate = [];
    //     this.gridApi.forEachNode(function(rowNode, index) {
    //         var data = rowNode.data;

    //         itemsToUpdate.push(data);
    //     });
    //     console.log(itemsToUpdate,"itemsToUpdate");
    //     var tptcode = JSON.parse(localStorage.getItem("transportercode"))
    //     var params = {
    //         rowData : itemsToUpdate,
    //         sendMail : 1,
    //         email : localStorage.getItem("email"),
    //         username : localStorage.getItem("username"),
    //         transporter_code:tptcode[0],
    //         plan_month : this.state.plan_month+"-01",
    //     }
    //     console.log(params,"params")
    //     redirectURL.post("/consignments/updateShareOfBusiness",params)
    //     .then((response)=>{
    //         if(response.data.message == "success")
    //         {
    //             console.log(response.data.response,"response.data.response")
    //             this.setState({
    //                 show:true,
    //                 basicTitle:"Data Updated Successfully",
    //                 basicType:"success"
    //             });
    //             this.onLoadPageData();
    //         }
    //         else
    //         {
    //             this.setState({
    //                 show:true,
    //                 basicTitle:"Failed to update the data",
    //                 basicType:"info"
    //             })
    //         }
    //         //window.location.reload();
    //     })
    // }


    formAssignHandlerWithOutMail = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedUpdateNow,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: 'show-m'
        });
        var itemsToUpdate = [];
        this.gridApi.forEachNode(function (rowNode, index) {
            var data = rowNode.data;
            itemsToUpdate.push(data);
        });
        // console.log(itemsToUpdate, "itemsToUpdate");
        var tptcode = JSON.parse(localStorage.getItem("transportercode"))
        var params = {
            rowData: itemsToUpdate,
            sendMail: 1,
            email: localStorage.getItem("email"),
            username: localStorage.getItem("username"),
            transporter_code: tptcode[0],
            plan_month: this.state.plan_month + "-01",
        }

        // console.log(params, "params")
        if (this.state.dataChanged == true) {
            redirectURL.post("/consignments/updateShareOfBusiness", params)
                .then((response) => {
                    if (response.data.message == "success") {
                        // console.log(response.data.response, "response.data.response")
                        this.setState({
                            show: true,
                            basicTitle: "Data Updated Successfully",
                            basicType: "success"
                        });
                        this.onLoadPageData();
                    }
                    else {
                        this.setState({
                            show: true,
                            basicTitle: "Failed to update the data",
                            basicType: "info"
                        })
                    }
                    //window.location.reload();
                })
        } else {
            this.setState({
                show: true,
                basicTitle: "Failed to update the data",
                basicType: "info",
                loadshow: 'show-n',
                overly: 'show-n'
            })
        }
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var plan_month = d.getFullYear() + "-" + (d.getMonth() + 1);

        this.setState({
            plan_month: plan_month
        });
    }

    handlerStartDateTimeForNewTransporter = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var select_month = d.getFullYear() + "-" + (d.getMonth() + 1);

        this.setState({
            select_month: select_month
        });
    }

    filterDataByMonth = async () => {
        await this.setState({
            plan_month: this.state.plan_month,
            dataChanged : false,
        });
        await this.onLoadPageData();
    }

    changeLocationHandler = () => {
        var location = $("#add_location").val();
        if (location == "MSIL-M") {
            $("#add_item_type").attr("required", true);
            this.setState({
                showType: "show-m"
            })
        }
        else {
            $("#add_item_type").removeAttr("required", true);
            this.setState({
                showType: "show-n"
            })
        }
    }
    render() {

        var columnwithDefs = [
            {
                headerName: "MSP Name",
                field: "msp_name",
                width: 180,
                filter: true,
                resizable: true,
                editable: true,
            },
            {
                headerName: "Lattitude",
                field: "latitude",
                width: 130,
                filter: true,
                resizable: true,
                editable: true,
                valueGetter: function (params) {
                    if (params.data.latitude != undefined && params.data.latitude != '') {
                        return params.data.latitude;
                    }
                    else {
                        return "";
                    }
                }
            },
            {
                headerName: "Longitude",
                field: "longitude",
                width: 130,
                filter: true,
                resizable: true,
                editable: true,
                valueGetter: function (params) {
                    if (params.data.longitude != undefined && params.data.longitude != '') {
                        return params.data.longitude;
                    }
                    else {
                        return "";
                    }
                }
            },
        ]
        var gridOptions = {
            defaultColDef: {
                sortable: true,
                resizable: true,
                filter:true
            },
            groupHeaderHeight: 75,
            headerHeight: 40,
            floatingFiltersHeight: 50,
            pivotGroupHeaderHeight: 50,
            pivotHeaderHeight: 100,
            suppressRowTransform: true
        };
        var itClass = "row col-xl-12 col-lg-12 col-sm-12"
        var aggridStyle = "3000px";
        if(JSON.parse(localStorage.getItem("transportercode")).indexOf("T329") >= 0)
        {
            aggridStyle = "500px";
        }        
        return (
            <div class="container-fluid" style={{ padding: "0" }}>
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="col-xl-12 col-lg-12">
                    <div className="f16" style={{ marginBottom: "10px" }}>
                        <ul className="d-tabs">
                            {/* <li className={"lttabs activet"}>
                        <a href="#0" style={{color:"#fff"}} type="button" className="btn">SMG SOB</a>
                    </li>

                    <li className={"lttabs"}>
                        <a href="/shareofbusinessinterplant" style={{color:"#000"}} type="button" className="btn">Inter Plant SOB</a>
                    </li> */}
                        </ul>
                    </div>
                </div>



                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-header">
                            <div className="row col-xl-12 col-lg-12">
                                <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                    <div className="col-xl-2 col-lg-2 form-group">
                                        <label>Filter By Month</label>
                                        {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                        <Datetime
                                            value={this.state.plan_month}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{
                                                placeholder: 'Select Month',
                                                name: 'plan_month',
                                                autoComplete: 'off',
                                                readOnly: "readOnly"
                                            }}
                                            dateFormat="YYYY-DD"
                                            name="plan_month"
                                            onChange={this.handlerStartDateTime.bind(this)}
                                        />
                                    </div>
                                    <div className="form-group col-xl-2 col-lg-2">
                                        <label>&nbsp;</label><br />
                                        <button type="button" className="btn btn-success" style={{ padding: "5px 10px", marginRight: "2%" }} onClick={this.filterDataByMonth.bind(this)}>Submit</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card-body n-p-0">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    {(this.state.countersData.map((c) =>
                                        <div className="col cirlce-d">
                                            <span className="f15" style={{ color: "#db3031", fontWeight: "bold" }}>{c.location}</span>
                                            <div className="row col-xl-12 col-lg-12 col-sm-12">
                                                {(c.itemData.map((d) =>
                                                ((c.itemData.length > 1) ?
                                                    <div className="col-xl-6 col-lg-6 col-sm-6" style={{ marginTop: "5%", padding: "0" }}>
                                                        <span className="f13">{d.itemName}</span>
                                                        <div className="row col-xl-12 col-lg-12 col-sm-12" style={{ padding: "0" }}>
                                                            <div className="col-xl-6 col-lg-6 col-sm-6" style={{ padding: "0", marginTop: "5%" }}>
                                                                <span className="f11">PLAN</span>
                                                                <h5 className={"f25 mt-20p " + c.cntClass}>
                                                                    <span className="counter">
                                                                        <CountUp end={(d.locationTotal) ? d.locationTotal : 0} />
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-sm-6" style={{ padding: "0", marginTop: "5%" }}>
                                                                <span className="f11">ACTUAL</span>
                                                                <h5 className={"f25 mt-20p " + c.cntClass}>
                                                                    <span className="counter">
                                                                        <CountUp end={(d.locActualTotal) ? d.locActualTotal : 0} />
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-xl-12 col-lg-12 col-sm-12" style={{ marginTop: "5%" }}>
                                                        <span className="f13">{d.itemName}</span>
                                                        <div className="row col-xl-12 col-lg-12 col-sm-12" style={{ marginTop: "5%" }}>
                                                            <div className="col-xl-6 col-lg-6 col-sm-6">
                                                                <span className="f13">PLAN</span>
                                                                <h5 className={"f25 mt-20p " + c.cntClass}>
                                                                    <span className="counter">
                                                                        <CountUp end={(d.locationTotal) ? d.locationTotal : 0} />
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6 col-sm-6">
                                                                <span className="f13">ACTUAL</span>
                                                                <h5 className={"f25 mt-20p " + c.cntClass}>
                                                                    <span className="counter">
                                                                        <CountUp end={(d.locActualTotal) ? d.locActualTotal : 0} />
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <span> SOB  PLAN</span>
                                {/* <button type="button" style={{float:"right",marginRight:"10px"}} onClick={this.deleteTransporter.bind(this)} className="btn btn-danger float-right">Delete Transporter</button> */}

                                {/* <button type="button" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)} className="btn btn-info float-right">Add New Transporter</button> */}

                                {/* <button type="button" style={{float:"right",marginRight:"10px"}} onClick={this.showLogData.bind(this)} className="btn btn-primary float-right">Changes Log</button> */}
                                {/* <button type="button" style={{float:"right",marginRight:"10px"}} onClick={this.showCompleteData.bind(this)} className="btn btn-warning float-right">Complete Data</button> */}
                            </h5>
                        </div>
                        <div className="card-body col-xl-12 col-lg-12">
                            {(this.state.last_updated_on) != '' ?
                                <div className="col-xl-12 col-lg-12" style={{ height: "35px" }}>
                                    <p style={{ float: "right" }}>Last Updated On : {this.state.last_updated_on}</p>
                                </div>
                                : ""}
                            <div className="col-xl-12 col-lg-12" style={{ padding: "0px" }}>
                                <div id="myGrid" style={{ width: "100%", height: aggridStyle }} className={"ag-theme-balham  " + this.state.showGridData}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        frameworkComponents={this.state.frameworkComponents}
                                        // statusBar={this.state.statusBar}
                                        //sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={false}
                                        rowClassRules={this.state.rowClassRules}
                                        gridOptions={gridOptions}
                                        // components={this.state.components}
                                        enableRangeSelection={true}
                                        onCellEditingStopped={this.onCellClicked}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                                </div>
                            </div>
                            <button style={{ marginTop: "2%" }} type="button" onClick={this.formAssignHandlerWithOutMail.bind(this)} className="btn btn-primary float-right">Update</button>
                            {/* <button style={{marginTop:"2%",marginRight:"2%"}} type="button" onClick={this.formAssignHandler.bind(this)} className="btn btn-danger float-right">Update &#38; Send Mail</button> */}
                        </div>
                    </div>

                </div>


                {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
                <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden" }}>
                    {/* {(this.state.sliderType == 1) ?
            <h3 className="subH">Add Transporter</h3> : ""} */}
                    {/* {(this.state.sliderType == 2) ?
            <h3 className="subH">Delete Transporter</h3> :""} */}
                    {/* {(this.state.sliderType == 3) ?
            <h3 className="subH">Change Log</h3> :""} */}
                    {/* {(this.state.sliderType == 4) ?
            <h3 className="subH">Complete Data</h3> :""} */}
                    <div className="slide-r-body" style={{ position: "relative" }}>

                        {/* <div className="container-fluid">
                    {(this.state.sliderType == 1 || this.state.sliderType == 2) ?
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.addNewTransporter}>
                        <div className="form-group mt-20p">
                            <label className="">Select Month</label> 
                            <Datetime 
                                value={this.state.select_month} 
                                disableCloseOnClickOutside={false} 
                                closeOnSelect={true} 
                                inputProps={{ 
                                    placeholder: 'Select Month', 
                                    name: 'select_month', 
                                    autoComplete:'off' 
                                }}
                                dateFormat="YYYY-DD" 
                                name="select_month" 
                                onChange={this.handlerStartDateTimeForNewTransporter.bind(this)} 
                            />
                        </div>

                        <div className="form-group mt-20p">
                            <label className="">Location</label> 
                            <select name="add_location" id="add_location" className="form-control"  onChange={this.changeLocationHandler.bind(this)}  required>
                                <option value="">Select Location</option>
                                {(this.state.sourceDestination.map((sd) =>
                                    <option value={sd}>{sd}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"form-group mt-20p "+this.state.showType}>
                            <label className="">Item Type</label> 
                            <select name="add_item_type" id="add_item_type" className="form-control">
                                <option value="">Select Item Type</option>
                                {(this.state.items.map((it) =>
                                    <option value={it.operation}>{it.operation}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mt-20p">
                            <label className="">LSP</label> 
                            <select name="add_lsp" id="add_lsp" className="form-control" required>
                                <option value="">Select LSP</option>
                                {(this.state.transportersList.map((t) =>
                                    <option value={t.alias}>{t.alias}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            {(this.state.sliderType == 1) ?
                            <button type="submit" className="btn btn-success">Submit</button> : 
                            <button type="submit" className="btn btn-success">Delete</button>
                            }
                            <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                        </div>
                    </form> : ( this.state.sliderType == 3) ?
                        <SOBchangelogs logHistory={this.state.logHistory}></SOBchangelogs> : <SobCompleteData completeData={this.state.completeData}>  </SobCompleteData>
                    }
                </div> */}
                    </div>
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}

function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'Y-m',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}