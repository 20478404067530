import React, {Component} from 'react';
import _ from "lodash";
import { AutoComplete }   from 'material-ui';
import getMuiTheme        from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider   from 'material-ui/styles/MuiThemeProvider';
import {Redirect} from 'react-router-dom';
import Modal from 'react-responsive-modal';
/*
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
*/
import axios from 'axios';
import SearchTruckActiveConsignments from "../../tptlogin/tptsearchtruckconsignments";
import ModelTruckData from "../../tptlogin/truckmapdatamodelComponent";

import $ from "jquery";
var redirectURL = require('../../redirectURL');

const googleAutoSuggestURL = '//suggestqueries.google.com/complete/search?client=youtube&ds=yt&q=';

class Search extends Component {

    constructor(props) {
        super(props);
        this.onUpdateInput = this.onUpdateInput.bind(this);
        this.state = {
            dataSource : [],
            inputValue : '',
            open: false,
            searchtruck:"",
            truckNoListSearc:[],
            filteredDataSearc:[],
            showTrucksListsrch : "show-n",
            activesearchtruckconsignments:[],
            tpttrucks:[],
            adaniCheck: 0,
            ontripofftriptruckstatus:[]
        }
        this.onCloseModal = this.onCloseModal.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }

    componentDidMount(){
        var tptCode = localStorage.getItem('transportercode');
		var vehicle_group = localStorage.getItem("vehicle_group");
        var is_hero = localStorage.getItem("is_hero");
        if(is_hero != undefined)
        {
            var tptrucks = []
            
            var tparms = {
                transporter_code:tptCode
            }
            redirectURL.post("/dashboard/tpttrucks",tparms)
            .then((response) => {
                var records = response.data.records;
                console.log("records ser ", records)
                this.setState({
                    tpttrucks:records
                })
            })
            
        }
        else{
            var tptrucks = []
            if(vehicle_group != "")
            {
                var tparms = {
                    transporter_code:tptCode,
                    region:vehicle_group
                }
                redirectURL.post("/dashboard/tpregiontrucksdata",tparms)
                .then((response) => {
                   var records = response.data.records;
                    // console.log("records ", records)
                    this.setState({
                        tpttrucks:records
                    })
                })
            }
        }
		

        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        if(is_enmovil_transporter == 1)
        {
            // this.logPageView(); // Log page view to GA
            // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            // this.setState({eventAction: eventAction});
            var pagetitle='GPS Integration'

			if(tptCode.includes("T293") == true)
			{
				var adaniCheck = 1;
			}
			else
			{
				var adaniCheck = 0;
			}
			this.setState({
				adaniCheck : adaniCheck
			});
        }

        // logPageView = () => {
        //     try {
        //         if (googleAnalytics.page.enableGA) {
        //             googleAnalytics.logPageView();
        //             let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        //             let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
        //             let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
        //             this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        //         } else {
        //             console.log("GA disabled...");
        //         }
        //     } catch(error) {
        //         console.error("Error occured while logging to GA, error = ", error);
        //     }
        // }
        
        var depmntCode = "";
        if(localStorage.getItem("dept_code") !=undefined)
        {            
            var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
            var depmntCode = depmntCodeJns[0];
        }
		let currentDepartmentCode = depmntCode;
		
		if(currentDepartmentCode == 'SNDG')
		{
			var defaulttab = this.state.defaulttab
		}
		else{
			var defaulttab = ""
		}
		
		var israil = 0;
        var userroles = localStorage.getItem("roles");
        if (userroles.indexOf("tpt_rail") > 0)
		{
			israil=1;
			var defaulttab = this.state.defaulttab
        }
        var depmntCode = "";
        if(localStorage.getItem("dept_code") !=undefined)
        {            
            var depmntCodeJns = JSON.parse(localStorage.getItem("dept_code"));
            var depmntCode = depmntCodeJns[0];
		}
		var tptCode = localStorage.getItem('transportercode');
		let layoutdata={
			token:localStorage.getItem('token'),
			israil:israil,
            defaulttab:defaulttab,
            deptcode:currentDepartmentCode,
            is_enmovil_transporter:localStorage.getItem('is_enmovil_transporter'),
            transporter_code:localStorage.getItem('transportercode'),
        }
        
        
        
        // redirectURL.post('/overview/truckWithGPSData', layoutdata)
		//   .then(async (response) => {
			 
		//    	var recs = JSON.parse(JSON.stringify(response)).data;
		//    	if(recs.length > 0)
		//    	{
        //         var transporterTrucks = [];
        //         var deviceparams = {
        //             transporter_code:localStorage.getItem("transportercode")
        //         }
        //         await redirectURL.post("/dashboard/devices", deviceparams)
        //         .then(async (response1)=>{					
                   
        //             var transptrucklist = [];
        //             // console.log("response1.data.devices ", response1.data.devices)
        //             response1.data.devices.map(function(e){
                        
        //                 transporterTrucks.push(e);
        //             })
        //             this.setState({
        //                 // truckNoListSearc:transptrucklist
        //             })
        //         })  


		// 		// var transporterTrucks = this.state.transporterTrucks;
		// 		if(transporterTrucks.length > 0)
		// 		{
		// 			var records = [];
		// 			// recs.map(function(t){
		// 			// 	if(transporterTrucks.indexOf(t.truck_no) > -1 )
		// 			// 	{
		// 			// 		records.push(t)
		// 			// 	}
		// 			// })
		// 			var records =await recs.filter(f =>
		// 				transporterTrucks.filter((d )=> {
		// 					if(d.truck_no == f.truck_no){
		// 						// console.log("API ", d)
		// 						if(d.registereddevices != undefined)
		// 						{
		// 							if(d.registereddevices.length > 0)
		// 							{
		// 								f.device_address = d.registereddevices[0].address
		// 								f.device_city = d.registereddevices[0].city
		// 								f.device_state = d.registereddevices[0].state
		// 							}
		// 						}
		// 						f.timestamp = d.last_active_on;
		// 						f.modified_date = d.modified_date;
		// 						return f;
		// 					}
		// 				})
		// 			);
		// 			//console.log("In indexcomponent, /overview/truckWithGPSData, records = ", records);
        //             var transptrucklist = []
        //             if(records.length > 0)
        //             {
        //                 records.map((item) => {
        //                     transptrucklist.push(item.truck_no);
        //                 })
        //             }
        //             await this.setState({
		// 				truckNoListSearc:transptrucklist
		// 			});
		// 		}
		//    	}	   
		    
		//   })
		//   .catch(function (error) {
		//     console.log(error);
        //   });
          

    }
    onUpdateInput(inputValue) {
        const self = this;
        this.setState({
          inputValue: inputValue
        }, function() {
          self.performSearch();
        });
      }
    
      performSearch() {
        const
          self = this,
          //url  = googleAutoSuggestURL + this.state.inputValue;
          url  = "http://localhost:3001/search/keyword?q=" + this.state.inputValue;
    
        if(this.state.inputValue !== '') {
            redirectURL(url)
            .then((response, error) => {
                console.log("Resjults ",response.data)
                let searchResults, retrievedSearchTerms;
        
                if(error) return error;

                
                searchResults = response.data[1];
        
                retrievedSearchTerms = searchResults.map(function(result) {
                  return result[0]+" ("+result[1]+")";
                });
        
                self.setState({
                  dataSource: retrievedSearchTerms
                });
            });
        }
      }
      callback(params){
          console.log("params ", params)
      }
      onSelectOption(params){
        var keyword = document.querySelector("#keyword").value
        if(keyword == '')
        {
            
        }
        else
        {
            console.log("Keyword ", keyword)
            var keys = keyword.split("(");
            var boardtype = keys[1].split(")");
            var dashboardType = boardtype[0];
            console.log(dashboardType) 
            var num = keys[0].split(" ");
            var number = num[0];
            if(dashboardType.toLowerCase() == 'consignment')
            {
                window.location.href="/consignment/"+number
            }
        }
      }
      changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
      onCloseModal = () => {
        this.setState({ open: false , activesearchtruckconsignments: []});
        $("#hinputTruck").val("")
      };
 
      onSubmitSearchTruck = async(event) =>{
          event.preventDefault();
        //   console.log("Submit success",localStorage.getItem("is_hero"))
        var is_hero = localStorage.getItem("is_hero");
        if(is_hero != undefined && is_hero != 'undefined') 
        {
            var ishero = 1;
        }
        else{
            var ishero = 0;
        }
          var searchtruck = $("#hinputTruck").val();
          var prms = {
              truck_no:[searchtruck],
              transporter_code:localStorage.getItem("transportercode"),
              is_hero:ishero
          }
          await this.setState({
              open:true,
              searchtruck:searchtruck,
              activesearchtruckconsignments:[]
          })
          
        //   console.log("here ",prms)
         await redirectURL.post("/consignments/searchtruckConsignments", prms)
          .then( async (response) => {
            var recordsarr = response.data.consignments
            var tripstatus = response.data.tripstatus
            // console.log("Search recordsarr ", recordsarr)
            // console.log("searchtruck ", searchtruck)
            var records = []
            if(recordsarr.length > 0)
            {
                recordsarr.map((item) => {
                    if(item.truck_no == searchtruck)
                    {
                        records.push(item)
                    }
                })
            }
            // var transporterTrucks = [];
            // var deviceparams = {
			// 	transporter_code:localStorage.getItem("transportercode")
			// }
			// await redirectURL.post("/dashboard/devices", deviceparams).then((response1)=>{
            //     var tptsCode = localStorage.getItem("transportercode");
            //     response1.data.devices.map(function(e){
                    
            //         if(tptsCode.indexOf(e.transporter_code) > -1 )
            //         {
            //             transporterTrucks.push(e)
            //         }
            //     })
               
            // });
            // console.log("transporterTrucks ", transporterTrucks)
			records = await records.sort(GetSearchSortDescOrder("_id"));
            
            // var uqTrckconsignments = [];
            // if(records.length > 0)
            // {
            //     uqTrckconsignments =await records.filter(f =>
            //         transporterTrucks.filter((d )=> {
            //             if(d.truck_no == f.truck_no){
            //                 // console.log("API ", d)
            //                 if(d.registereddevices != undefined)
            //                 {
            //                     if(d.registereddevices.length > 0)
            //                     {
            //                         f.device_address = d.registereddevices[0].address
            //                         f.device_city = d.registereddevices[0].city
            //                         f.device_state = d.registereddevices[0].state
            //                     }
            //                 }
            //                 return f;
            //             }
            //         })
            //     );

            // }
           
            
			// console.log("records ",records)
			await this.setState({				
                activesearchtruckconsignments:records,
                ontripofftriptruckstatus:tripstatus
			});
          })
      }

    initalListsearc =(event)=>{
        $(".trucks-dropdown-srch").removeClass("show-n");
        var is_hero = localStorage.getItem("is_hero");
        if(is_hero != undefined)
        {
            var tpttrucksarr = [];
            var tpttrucks = this.state.tpttrucks;
            if(tpttrucks.length > 0)
            {
                tpttrucks.map((item) => {
                    tpttrucksarr.push(item.truck_no);
                })
            }
            var dataset = tpttrucksarr;
            dataset = dataset.slice(0,100);
            var tptunqrecords = dataset
        }
        else{
            var dataset = this.state.truckNoListSearc;
            dataset = dataset.slice(0,100);

            var tpttrucks = this.state.tpttrucks;
            var tptunqrecords = []
            if(tpttrucks.length > 0)
            {
                dataset.filter(f =>
                    tpttrucks.filter((d )=> {
                        if(d.truck_no == f){									
                            tptunqrecords.push(f)
                        }
                    })
                );
            }
            else
            {
                tptunqrecords = dataset
            }
        }
        this.setState({
            filteredDataSearc : tptunqrecords,
            showTrucksListsrch : "show-m",
        })
    }
    handlerFormSearc = (event) => {
		console.log("eventsearch",event.target.value)
		try
		{            
            var tptunqrecords = []
            $(".trucks-dropdown-srch").removeClass("show-n");
            var is_hero = localStorage.getItem("is_hero");
            if(is_hero != undefined)
            {
                var tpttrucksarr = [];
                var tpttrucks = this.state.tpttrucks;
                if(tpttrucks.length > 0)
                {
                    tpttrucks.map((item) => {
                        tpttrucksarr.push(item.truck_no);
                    })
                }
                tptunqrecords = tpttrucksarr
                
            }
            else{
                var dataset = this.state.truckNoListSearc;
                var tpttrucks = this.state.tpttrucks;
                if(tpttrucks.length > 0)
                {
                    dataset.filter(f =>
                        tpttrucks.filter((d )=> {
                            if(d.truck_no == f){									
                                tptunqrecords.push(f)
                            }
                        })
                    );
                }
                else
                {
                    tptunqrecords = dataset
                }
            }
			var filteredResult = tptunqrecords.filter(function(e){
				if(e != "" && e != null)
				{
					return e.toString().toLowerCase().includes($("#hinputTruck").val().toLocaleLowerCase());
				}
				
			});
			filteredResult = filteredResult.slice(0,100);
			this.setState({
				filteredDataSearc : filteredResult,
				showTrucksListsrch : "show-m",
			});
		}catch(e){}
    }
    onClickTruck(event)
    {
         $("#hinputTruck").val($(this).text());
    }
    render() {
        const modalStyles  = {
            width:'1200px !important',
            maxWidth:'1200px !important',
        }
        const { open } = this.state;

        const SearchIcon = require('../../../assets/images/dashboard/search.png');

        var searchStyle = {
            backgroundImage: "url("+SearchIcon+")",
            backgroundPosition:"left",
            paddingLeft:"20px",
            fontSize:"13px",
            backgroundRepeat:"no-repeat",
            transform: 'translateY(0) scaleY(1)',
            opacity: 1,
            visibility: 'visible',
            transition: 'all linear .3s'
        }
        /*if(this.state.searchbar)
        {
            searchStyle = {
                backgroundImage: "url("+SearchIcon+")",
                transform: 'translateY(0) scaleY(1)',
                opacity: 1,
                visibility: 'visible',
                transition: 'all linear .3s'
            }
        }else{
            searchStyle = {
                backgroundImage: "url("+SearchIcon+")"
            }
        }
        */
       var listDatasearch = []
        try{
            this.state.filteredDataSearc.map(function(e){
                listDatasearch.push(<li className="dropdown-truck-no" style={{textAlign:"left",width:"100%"}}>{e}</li>)
                })
		}catch(e){console.log(e)}

        var is_enmovil_transporter = localStorage.getItem("is_enmovil_transporter");
        
        return (
            <div>
                {(is_enmovil_transporter == 1)?
            <form className="form-inline search-form" method="POST" onSubmit={this.onSubmitSearchTruck.bind(this)}>
                <div className="form-group" style={{position:"relative"}}>
                    <div className={"trucks-dropdown-srch "+this.state.showTrucksListsrch} style={{width:"16em",left:"0px"}}>
                        <ul>
                            {listDatasearch}
                        </ul>
                    </div>
                    {/* <input
                        type="search"
                        id="hinputTruck"
                        className="float-left form-control-plaintext"
                        placeholder="Enter Shuttle No"
                        autoComplete="off" 
                        onFocus={this.initalList.bind(this)} 
                        onChange={this.handlerForm.bind(this)}
                        name="searchtruck"
                        value={this.state.searchtruck}
                    /> */}
                    {(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)?"":
                    <input 
                    type="text"
                     name="search_truck_no"
                      id="hinputTruck"  
                    //   placeholder="Enter Shuttle No"
                      placeholder = {(this.state.adaniCheck == 1) ? "Enter Shuttle No"  : "Enter Truck No"}
                       autoComplete="off"
                        className="float-left form-control-plaintext"
                         onFocus={this.initalListsearc.bind(this)} 
                         onChange={this.handlerFormSearc.bind(this)} />}
							{(localStorage.getItem("transportercode").indexOf("JWS01") >= 0)?"":		
                    <button type="submit"
                        className="float-left mobile-search"
                        //style ={ { backgroundImage: "url("+SearchIcon+")" } }
                        // onClick={() => this.handleSearchClick()}
                    >
                    <img src={SearchIcon} />
                    </button>}
                     {/* <MuiThemeProvider>
                        <AutoComplete
                            dataSource    = {this.state.dataSource}
                            onUpdateInput = {this.onUpdateInput}
                            //style ={ searchStyle }
                            id="keyword"
                            
                            />
                    </MuiThemeProvider>
                    <button type="button"
                        className="float-left mobile-search"
                        //style ={ { backgroundImage: "url("+SearchIcon+")" } }
                        onClick={this.onSelectOption}
                    >
                    <img src={SearchIcon} />
                     </button> */}
                    
                </div>
                
            </form>
                :
                <form className="form-inline search-form" method="POST" >
                    <div className="form-group">
                        <input 
                        type="text"
                        name="keyworkd"
                        id="keyworkd"  
                        placeholder="Search"
                        autoComplete="off"
                            className="float-left form-control-plaintext"
                            // onFocus={this.initalList.bind(this)} 
                            // onChange={this.handlerForm.bind(this)} 
                        />
                                        
                        <button type="submit"
                            className="float-left mobile-search"
                            //style ={ { backgroundImage: "url("+SearchIcon+")" } }
                            // onClick={() => this.handleSearchClick()}
                        >
                        <img src={SearchIcon} />
                        </button>
                    </div>
                </form>
                }
                <Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
	                <div className={"row"} style={{ height: '100vh',padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
	                	
                        <div className="col-xl-7 col-lg-7">
                            <ModelTruckData
                                truck_no={this.state.searchtruck}
                                rowdata={this.state.activesearchtruckconsignments}
                                trip_status={this.state.ontripofftriptruckstatus}
                            />
                        </div>
                        <div className="col-xl-5 col-lg-5">
                           
                            <SearchTruckActiveConsignments
                                rowdata={this.state.activesearchtruckconsignments}
                                // trip_status={this.state.ontripofftriptruckstatus}
                            />
                        
                        </div>
	                </div>
	           </Modal>		
            </div>
        );
    }
}

$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        // alert("search comp!!")
        var vals = $(this).text();
        //  $("#hinputTruck").val(vals);
         $("input[name='search_truck_no']").val(vals);
         $(".trucks-dropdown-srch").removeClass("show-m");
         $(".trucks-dropdown-srch").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#hinputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown-srch").attr("class").includes("show-m") || $(".trucks-dropdown-srch").attr("class") == "trucks-dropdown-srch")
                {
                    $(".trucks-dropdown-srch").removeClass("show-m");
                    $(".trucks-dropdown-srch").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})
function GetSearchSortDescOrder(prop) {    
	return function(a, b) {    
		if (a[prop] < b[prop]) {    
			return 1;    
		} else if (a[prop] > b[prop]) {    
			return -1;    
		}    
		return 0;    
	}    
} 



export default Search