/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import DateFormater from '../layouts/dateComponent';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import datetimepicker from 'jquery-datetimepicker';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import CSVFileValidator from 'csv-file-validator';
import SweetAlert from 'react-bootstrap-sweetalert';
import EditActions from '../manage/EditActionsComponent';
import ExportStockyardPdf from './exportstockyardpdf';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM,getHyphenDDMMYYYYHHMM,getHyphenYYYYMMDDHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import $ from "jquery";
//import { getConsignmentsNoGpsLifeTimeCounter } from '../../../../tpt_node/models/consignmentModel';

import CountUp from 'react-countup';

$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
// ModuleRegistry.registerModules([
//     ClientSideRowModelModule,
//     CsvExportModule,
//     ExcelExportModule,
//     MenuModule,
//   ]);
export default class StockyardTransportersInfo extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Parking Information",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
            frameworkComponents: {
				editActions: EditActions,
                ExportStockyardPdf: ExportStockyardPdf
			},
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            defaultExcelExportParams: {
                allColumns: true,
            },
			plant_code:'',
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
            markersList:[],
            btnClicked:[],
            sliderUpdate:"",
            CellRendererFunc:[],
			detailCellRendererParams:{},
            transporter_name_options:[],
            selectedtransporter:{"label": "All", value: "All"},
            transporter_code:[],
            apl_logistics_data:[],
            ivc_logistics_data:[],
            nyk_adani_data:[],
            oriental_carriers_data:[],
            joshi_konoike_data:[],
            total_data:[],
            sliderRso:"",
            vin_no:"",
            car_model:"",
            transporter_manual_name:"",
            bay_no:"",
            gate_in_remarks:"",
            gate_out_remarks:"",
            time_spent_in_parking:"",
            gate_in_date:"",
            bay_allocation_date:"",
            gate_out_date:"",
            bay_deallocation_date:"",
            rowid:"",
            selectedRowNode: "",
            srcImage: "",
            bulkslide: "",
            sliderType:"",
            gate_in_images_view:[],
            sliderTitle:"",
            gate_out_images_view:[],
            usergridstate: [],
            msil_rake_id:""
		};
		// this.popmarker=this.popmarker.bind(this);
        this.onShowEditAction = this.onShowEditAction.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        this.setState({
            loadshow:'show-m',
            overly:'show-m'
        })
        loadDateTimeScript()
        var params = {
			stockyardemailid:localStorage.getItem("email")
		}
        if(![undefined , "undefined" , "" , null].includes(localStorage.getItem("transportercode"))){
			params.transporter = localStorage.getItem("transportercode")
		}
		redirectURL.post("/consignments/bayinformationdata",params).then((response)=>{
            var transporterInfo = response.data.data;
            // $('#ItemPreview').attr('src', "data:application/octet-stream;base64,"+transporterInfo[0].gate_in_images[0]);
            var apl_logistics_data = transporterInfo.filter(e => e.transporter_code == "T275")
            var ivc_logistics_data = transporterInfo.filter(e => e.transporter_code == "T305")
            var nyk_adani_data = transporterInfo.filter(e => e.transporter_code == "T324")
            var oriental_carriers_data = transporterInfo.filter(e => e.transporter_code == "T025")
            var joshi_konoike_data = transporterInfo.filter(e => e.transporter_code == "T314")
            // console.log(apl_logistics_data,"apl_logistics_data")
            // console.log(ivc_logistics_data,"ivc_logistics_data")
            // console.log(nyk_adani_data,"nyk_adani_data")
            // console.log(oriental_carriers_data,"oriental_carriers_data")
            // console.log(joshi_konoike_data,"joshi_konoike_data")
            this.setState({
            rowData: transporterInfo,
            total_data:transporterInfo,
            apl_logistics_data:apl_logistics_data,
            ivc_logistics_data:ivc_logistics_data,
            nyk_adani_data:nyk_adani_data,
            oriental_carriers_data:oriental_carriers_data,
            joshi_konoike_data:joshi_konoike_data,
            loadshow:'show-n',
            overly:'show-n'
            })
            redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
            .then( async (resp) => {
                await this.setState({
                    usergridstate:resp.data
                });
                await this.restoreGridStates();
            })
        })

    }
    	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	  }

formHandler = (event) =>{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}
        loadDateTimeScript()
        this.setState({
            sliderRso:"slider-translate"
        })

        
    }
	onClickHideAll(){
		$("#email_ids").val("");
        var uploadfilebulk = $("#uploadfile").val(null)
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",
            sliderRso: "",
            sliderUpdate:"",
            selectedRowNode: "",
            uploadfilebulk:uploadfilebulk

		})
	}
    onClickTotalInfo = () => {
        var totaldata = this.state.total_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: totaldata
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickAplLogisticsInfo = () => {
        var apllogisticsdata = this.state.apl_logistics_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: apllogisticsdata
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickIVCLogisticsInfo = () => {
        var ivclogisticsinfo = this.state.ivc_logistics_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: ivclogisticsinfo
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickNYKAdaniInfo = () => {
        var NYKAdaniInfo = this.state.nyk_adani_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: NYKAdaniInfo
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickOreintalCarriersInfo = () => {
        var orientalcarriersinfo = this.state.oriental_carriers_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: orientalcarriersinfo
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickJoshiKonoikeInfo = () => {
        var joshikonoikeinfo = this.state.joshi_konoike_data;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: joshikonoikeinfo
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
   onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    onAddRow() {
		
		this.setState({
			sliderRso: 'slider-translate',
			selectedRowNode: "",
			overly: 'show-m',
            sliderType:1,
            sliderTitle:"Bulk Upload Manual Data",
            vin_no:"",
            car_model:"",
            transporter_manual_name:"",
            msil_rake_id:"",
            bay_no:"",
            gate_in_remarks:"",
            gate_out_remarks:"",
            time_spent_in_parking:"",
            gate_in_date:"",
            bay_allocation_date:"",
            gate_out_date:"",
            bay_deallocation_date:"",
		})
	

	}
    onBtExport = () => {
        // this.gridApi.exportDataAsExcel({skipColumnHeaders : false, skipColumnGroupHeaders : false})
        let params = getParams();
        this.gridApi.exportDataAsExcel(params);
    };
    onShowEditAction = (row) => {
        // console.log(row,"row data")
        // console.log(row.vin_number,"row.vin_no")
		// console.log(moment.parseZone(new Date(getHyphenDDMMMYYYYHHMM(row.gate_in_time))).utcOffset("+05:30")._d,getHyphenDDMMYYYYHHMM(row.gate_in_time));
        // console.log()
		this.setState({
			sliderRso: 'slider-translate',
			overly: 'show-m',
			vin_no:row.vin_number,
            // car_model:row.car_name,
            transporter_manual_name:row.transporter_name,
            msil_rake_id:row.rake_no,
            bay_no:row.parking_cell,
            gate_in_remarks:row.bay_in_damages,
            gate_out_remarks:row.bay_out_damages,
            time_spent_in_parking:row.parking_duration_secs,
			gate_in_date: getHyphenDDMMYYYYHHMM(row.gate_in_time),
			bay_allocation_date:getHyphenDDMMYYYYHHMM(row.bay_in_time),
			gate_out_date: getHyphenDDMMYYYYHHMM(row.gate_out_time),
			bay_deallocation_date: getHyphenDDMMYYYYHHMM(row.bay_out_time),
			selectedRowNode: row,
            sliderType:4
		})

        loadDateTimeScript()

	}

    onClickNewAddRow(event)
	{
        event.preventDefault();
		
        var vin_no = $("#vin_no").val();
        var car_model= $("#car_model").val();
        var transporter_manual_name = $("#transporter_manual_name").val();
		var bay_no = $("#bay_no").val();
		var gate_in_remarks = $("#gate_in_remarks").val();
        var gate_out_remarks = $("#gate_out_remarks").val();
        var time_spent_in_parking = $("#time_spent_in_parking").val();
        var gate_in_date = $("#gate_in_date").val();
        var bay_allocation_date = $("#bay_allocation_date").val();
        var gate_out_date = $("#gate_out_date").val();
        var bay_deallocation_date = $("#bay_deallocation_date").val();
        
        // console.log(bay_deallocation_date,"bay_deallocation_date")
        // console.log(gate_out_date,"gate_out_date")
       if(vin_no.length ==17)
        {
            var check = 1;
            if(gate_out_date != "")
            {
                if(bay_deallocation_date != "")
                {
                    check = 1;
                }
                else
                {
                    check = 0
                }
            }
            // console.log(check,"check")
            if(check == 1)
            {
                var td = gate_in_date.split(" ");
                var s = td[0].split("-")
                if (s[0].length == 4) {
                    var tdate = s[0] + "-" + s[1] + "-" + s[2] + " " + td[1]
                }
                else {
                    var tdate = s[2] + "-" + s[1] + "-" + s[0] + " " + td[1]
                }
                var dateToGateIn = tdate+":00";
                console.log(dateToGateIn,"dateToGateIn")
                dateToGateIn = moment.parseZone(new Date(dateToGateIn)).utcOffset("+00:00")._d;
                var td1 = bay_allocation_date.split(" ");
                var s1 = td1[0].split("-")
                if (s1[0].length == 4) {
                    var tdate1 = s1[0] + "-" + s1[1] + "-" + s1[2] + " " + td1[1]
                }
                else {
                    var tdate1 = s1[2] + "-" + s1[1] + "-" + s1[0] + " " + td1[1]
                }
                var dateToChk = tdate1+":00";
                console.log(dateToChk,"dateToChk")
                dateToChk = moment.parseZone(new Date(dateToChk)).utcOffset("+00:00")._d;
                var comparision = moment(dateToChk).isAfter(moment(dateToGateIn));
                console.log(dateToGateIn,dateToChk)
                console.log(comparision,"comparision")
                if(comparision == true)
                {
                    var params = {
                        vin_no:vin_no,
                        car_model:car_model,
                        transporter_manual_name:transporter_manual_name,
                        bay_no:bay_no,
                        gate_in_remarks:gate_in_remarks,
                        gate_out_remarks:gate_out_remarks,
                        time_spent_in_parking:time_spent_in_parking,
                        gate_in_date:gate_in_date,
                        bay_allocation_date:bay_allocation_date,
                        gate_out_date:gate_out_date,
                        bay_deallocation_date:bay_deallocation_date,
                        transporter:localStorage.getItem("transportercode"),
                        stockyardemailid:localStorage.getItem("email")
                    }
                    if (this.state.selectedRowNode != "") {
                        params.row_id = this.state.selectedRowNode._id
                    }
                    console.log(params,"params")
                    redirectURL.post("/dashboard/savestockyardmanualdata", params,{
                        headers:{
                            'Content-Type': 'application/json'
                        }
                    }).
                    then(
                        (response)=>{
                            //console.log("update response is : ",response.data);
                            //console.log("Update Response ",response.data.result)
                            if(response.data.status == "success")
                            {
                                this.setState({
                                    show: true,
                                    basicTitle:'Successfully added data',
                                    basicType:"success",
                                    sliderRso:"",
                                    selectedRowNode: "",
                                    vin_no:"",
                                    car_model:"",
                                    transporter_manual_name:"",
                                    bay_no:"",
                                    gate_in_remarks:"",
                                    gate_out_remarks:"",
                                    time_spent_in_parking:"",
                                    gate_in_date:"",
                                    bay_allocation_date:"",
                                    gate_out_date:"",
                                    bay_deallocation_date:"",
                                }); 
                                this.componentDidMount()
                            }
                            else{
                                this.setState({
                                    show: true,
                                    basicTitle:'Something went wrong',
                                    basicType:"danger",
                                });
                            }
                            
                        }
                    )
                    .catch(function(error){
                        console.log(error);
                    });
                }
                else
                {
                    this.setState({
                        show: true,
                        basicTitle:'Bay Allocation date should be greater than GateIn Date',
                        basicType:"danger",
                    });
                }
            }
            else
            {
                this.setState({
                    show: true,
                    basicTitle:'Gate Out should not be done without bay-deallocation',
                    basicType:"danger",
                });
            }
        }
        else{
            this.setState({
                show: true,
                basicTitle:'Vin Number should have 17 characters',
                basicType:"danger",
            });
        }

    }
    onClickSaveGridState(){
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		
		let screenpage = 'Parking Information';

		

		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			// console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{
		// console.log("this.state.usergridstate ", this.state.usergridstate)
		try{
			if(this.state.usergridstate.length > 0)
			{
				var windowstates = this.state.usergridstate;
				// console.log("windowstates[0].gridcolumns ",windowstates[0].gridcolumns)
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
			
			}
		}
		catch(e){}
	}
    onGridState = () =>{
		//console.log(this.gridApi);
		
		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		* User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();
				
		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);
			
		
		
	}
    resetState = () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};
    onCellClicked(e)
	{
        if(e.colDef.field == "gateinview")
        {
            var gate_in_images = e.data.bay_in_images;
            if(gate_in_images !=undefined)
            {  
                this.setState({
                    gate_in_images_view:gate_in_images,
                    sliderRso:"slider-translate-50p",
                    sliderType:2,
                    sliderTitle:"Damage Pictures"
                });
            }
        }
        if(e.colDef.field == "gateoutview")
        {
            var gate_out_images = e.data.bay_out_images;
            if(gate_out_images !=undefined)
            {  
                this.setState({
                    gate_out_images_view:gate_out_images,
                    sliderRso:"slider-translate-50p",
                    sliderType:3,
                    sliderTitle:"Damage Pictures"
                });
            }
        }
	}
    onSubmitBulkUpload = (event) => {
		event.preventDefault()

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        // console.log(this.state.csvcontent,"bulk uplosd dtaa")
        if(this.state.csvcontent != undefined && this.state.csvcontent != "")
        {
            if (this.state.csvcontent.length > 0) {
                var vinnumberdata = true
                var vinnumberdatalength = true
                var vinnumberlength = []
                var duplicatebaynumber = []
                this.state.csvcontent.map((e) => {
                    // console.log(e,"edata")
                    // console.log(e.vin_number,"vin number")
                    if(e.vin_number == "" || e.vin_number == undefined)
                    {
                        vinnumberdata = false
                    }
                    if(e.vin_number != "" || e.vin_number != undefined)
                    {
                        vinnumberlength.push(e.vin_number)
                    }
                    if(e.parking_cell != "" && e.parking_cell != undefined)
                    {
                        duplicatebaynumber.push(e.parking_cell)
                    }
                })
                vinnumberlength.map((dd) => {
                    if(dd.length != 17)
                    {
                        vinnumberdatalength = false
                    }
                })
                if(vinnumberdata == true)
                {
                    if(vinnumberdatalength == true)
                    {
                        // console.log(vinnumberlength,"vinnumberlength")
                        // if(duplicatebaynumber !="" && duplicatebaynumber != undefined)
                        // {
                        var bayuniqueCheck = checkIfArrayIsUnique(duplicatebaynumber)
                        // }
                        // console.log(duplicatebaynumber,"duplicatebaynumber")
                        var uniqueCheck = checkIfArrayIsUnique(vinnumberlength)
                        // console.log(uniqueCheck,"uniquecheck")
                        // console.log(bayuniqueCheck,"bayuniqueCheck")
                        if(uniqueCheck == true && bayuniqueCheck == true)
                        {
                            var params = {
                                csvcontent: this.state.csvcontent,
                                stockyardemailid:localStorage.getItem("email"),
                                // accessby: accessby,
                                transporter : localStorage.getItem("transportercode"),
                            }
                            // console.log("content ", this.state.csvcontent)
                            // console.log("Params ", params)
                            redirectURL.post("/dashboard/bulkuploadstockyarddata", params)
                                .then((response) => {
                                    // console.log(response,"response")
                                    if (response.data.vinnumbernotexists == "Records successfully inserted") {
                                        var uploadfilebulk = $("#uploadfile").val(null)
                                        this.setState({
                                            show: true,
                                            basicTitle: 'Successfully added items',
                                            basicType: "success",
                                            loadshow: "show-n",
                                            overly: "show-n",
                                            sliderBulk: "",
                                            file: "",
                                            uploadfilebulk:uploadfilebulk
                                        });
                                        this.componentDidMount();
                                    }
                                    else if(response.data.dupvinnumber == "Vin Number already exists"){
                                        this.setState({
                                            show: true,
                                            basicTitle: '"Vin Number already exists"',
                                            basicType: "danger",
                                        });
                                    }
                                    else {
                                        this.setState({
                                            show: true,
                                            basicTitle: 'Something went wrong',
                                            basicType: "danger",
                                        });
                                    }
                                })
                            }                            
                            else{
                                this.setState({
                                    show: true,
                                    basicTitle: 'Vin Number or Bay Number should not be duplicate',
                                    basicType: "danger",
                                });
                            }
                    }
                    else{
                        this.setState({
                            show: true,
                            basicTitle: 'Make sure all the vin numbers in the file should have 17 characters',
                            basicType: "danger",
                        });
                    }
                }
                else{
                    this.setState({
                        show: true,
                        basicTitle: 'Vin Number should not be empty',
                        basicType: "danger",
                    });
                }
                
            }
        }
        else{
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }
	}

    changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
                {
					name: 'vin_number',
					inputName: 'vin_number',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'rake_no',
					inputName: 'rake_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'transporter_name',
					inputName: 'transporter_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'gate_in_time',
					inputName: 'gate_in_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'bay_in_damage',
					inputName: 'bay_in_damage',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'parking_cell',
					inputName: 'parking_cell',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'bay_in_time',
					inputName: 'bay_in_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'bay_out_time',
					inputName: 'bay_out_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'bay_out_damage',
					inputName: 'bay_out_damage',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'gate_out_time',
					inputName: 'gate_out_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// {
				// 	name: 'truck_no',
				// 	inputName: 'truck_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'oem_plant',
				// 	inputName: 'oem_plant',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'dealer_name',
				// 	inputName: 'dealer_name',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'invoice_no',
				// 	inputName: 'invoice_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'trip_no',
				// 	inputName: 'trip_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'load_no',
				// 	inputName: 'load_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
                // {
				// 	name: 'car_name',
				// 	inputName: 'car_name',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData2) => {
				this.setState({
					csvcontent: csvData2.data
				});
				console.log(csvData2.data, "csvData");
			})
			.catch(err => { })
        try
        {
            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // console.log("contents ", contents)
    
                    resolve(contents);
                };
                if(e.target.files[0] != "" && e.target.files[0] != undefined)
                {
                    var tt = reader.readAsText(e.target.files[0]);
                }
            });
            //console.log("Out ", out);
    
            this.setState({
                file: e.target.files[0]
            });
            if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        catch{

        }
		
	}

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		
		var columnDefs= [
            {
                headerName: "",
                field: "",
                colId: "",
                width: 50,
                // pinned: 'left',
                cellRendererSelector: function(params){
                    var rendComponent = {
                        component : 'editActions'
                    }
                    return rendComponent;
                },
                filter: false, resizable: true

            },
            // {
            //     headerName: "Truck No",
            //       field: "gate_in_truck_no",
            //       width: 200,
  
            //   },
			{
                headerName: "Vin Number",
                  field: "vin_number",
                  width: 200,
  
              },
            //   {
            //     headerName: "OEM Plant",
            //     field: "oem_plant",
            //     width: 200,

            //   },
            //   {
            //     headerName: "Dealer Name",
            //     field: "dealer_name",
            //     width: 200,

            //   },
            //   {
            //     headerName: "MSIL Invoice No",
            //     field: "invoice_no",
            //     width: 200,

            //   },
            //   {
            //     headerName: "Trip No",
            //     field: "trip_no",
            //     width: 200,

            //   },
            //   {
            //     headerName: "Load No",
            //     field: "load_no",
            //     width: 200,

            //   },
              {
                headerName: "MSIL Rake Id",
                field: "rake_no",
                width: 200,

              },
            //   {
            //       headerName: "Car Name",
            //       field: "car_name",
            //       width: 200,
    
            //   },
              
               {
                  headerName: "Transporter Name",
                  field: "transporter_name",
                  width: 200,
  
              },
              {
                  headerName: "Gate In Date&Time",
                  field: "gate_in_time",
                  width: 150,
                  valueGetter:function(params){
                      return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)}
              },
              {
                  headerName: "Bay In Damage Remarks",
                  field: "bay_in_damages",
                  width: 250,
                  valueGetter:function(params){
                    if(params.data.bay_in_damages!="" && params.data.bay_in_damages!=undefined)
                    {
                        return params.data.bay_in_damages
                    }
                    else {
                        return params.data.gate_in_damage
                    }
                }
              },
              {
                headerName: "Bay In Damage Images",
                field: "gateinview",
                width: 250,
                cellRenderer : function(params){
                    return '<button class="btn white btn-info" style="padding:0 6px 6px;color:#ccc" >View</button>'
                }
              },
              {
                  headerName: "Bay No",
                  field: "parking_cell",
                  width: 100,
    
              },
              {
                  headerName: "Bay Allocation Date",
                  field: "bay_in_time",
                  width: 150,
                  valueGetter:function(params){
                      return getHyphenDDMMMYYYYHHMM(params.data.bay_in_time)}
              },
              {
                  headerName: "Bay De-allocation Date",
                  field: "bay_out_time",
                  width: 150,
                  valueGetter:function(params){
                      return getHyphenDDMMMYYYYHHMM(params.data.bay_out_time)}
              },
            //   {
            //     headerName: "Unloading Time(HH:MM)",
            //     field: "unloading_time",
            //     width: 200,
  
            //   },
              {
                  headerName: "Gate Out Date&Time",
                  field: "gate_out_time",
                  width: 150,
                  valueGetter:function(params){
                      return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time)}
              },
              {
                  headerName: "Bay Out Damage Remarks",
                  field: "bay_out_damages",
                  width: 250,
                  valueGetter:function(params){
                    if(params.data.bay_out_damages!="" && params.data.bay_out_damages!=undefined)
                    {
                        return params.data.bay_out_damages
                    }
                    else {
                        return params.data.gate_out_damage
                    }
                }
              },
              {
                headerName: "Bay Out Damage Images",
                field: "gateoutview",
                width: 250,
                cellRenderer : function(params){
                    return '<button class="btn white btn-info" style="padding:0 6px 6px;color:#ccc" >View</button>'
                }

              },
            //   {
            //     headerName: "Loading Time(HH:MM)",
            //     field: "loading_time",
            //     width: 200,
  
            //   },
            //   {
            //       headerName: "Time Spent in Parking(HH:MM)",
            //       field: "parking_duration_secs",
            //       width: 150,
            //       valueGetter:function(params){
            //         try{
            //             //console.log("params.data.night_driving_duration_secs ", params.data.night_driving_duration_secs)
            //             if(params.data.gate_in_time != "" && params.data.gate_in_time != undefined && params.data.gate_out_time != "" && params.data.gate_out_time != undefined)
            //             {
            //                 var firstinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(params.data.gate_in_time))).utcOffset("+05:30")._d
            //                 // console.log(firstinstance,"firstinstance")
            //                 var recentinstance = moment.parseZone(new Date(getHyphenYYYYMMDDHHMMSS(params.data.gate_out_time))).utcOffset("+05:30")._d
            //                 // console.log(recentinstance,"firstinstance")
            //                 var data = (recentinstance - firstinstance)
            //                 // console.log(data,"data data")
            //                 return secondsToHM(data/1000);
            //             }
            //             else{
            //                 return "";
            //             }
            //         }
            //         catch(e){
            //             return "";
            //         }
            //     }
            //   },
              {
                headerName: "Allocation Type",
                field: "manual_entry",
                width: 150,
                valueGetter:function(params){
                    if(params.data.manual_entry == 1)
                    {
                        return "Manual"
                    }
                    else
                    {
                        return "Scanned"
                    }
                    }
            },
            //   {
            //       headerName: "Receipt",
            //       field: "receipt",
            //       width: 150,
            //       cellRendererSelector: function(params){
            //           var rendComponent = {
            //               component : 'ExportStockyardPdf'
            //           }
            //           return rendComponent;
            //       },
            //   },
              
		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}

        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
						<div className="card" >
							<div className="card-body" id="dashboardCounterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">
                                        <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickTotalInfo.bind(this)}>
                                                <span className="f15 txt-primary"><br /> Total </span>
                                                <h4 className="txt-primary f40"><span className="counter">
                                                <CountUp end= {(this.state.total_data.length)?this.state.total_data.length:0}/>
                                                </span></h4>
                                        </div>
                                        <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickAplLogisticsInfo.bind(this)}>
                                                <span className="f15 txt-secondary"><br /> APL LOGISTICS </span>
                                                <h4 className="txt-secondary f40"><span className="counter">
                                                <CountUp end= {(this.state.apl_logistics_data.length)?this.state.apl_logistics_data.length:0}/>
                                                </span></h4>
                                            </div>
                                            <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickIVCLogisticsInfo.bind(this)}>
                                                <span className="f15 txt-info"><br /> IVC LOGISTICS LIMITED  </span>
                                                <h4 className="txt-info f40"><span className="counter">
                                                    <CountUp end= {(this.state.ivc_logistics_data.length)?this.state.ivc_logistics_data.length:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickNYKAdaniInfo.bind(this)}>
                                                <span className="f15 txt-danger"><br /> NYK ADANI </span>
                                                <h4 className="txt-danger f40"><span className="counter">
                                                    <CountUp end= {(this.state.nyk_adani_data.length)?this.state.nyk_adani_data.length:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickOreintalCarriersInfo.bind(this)}>
                                                <span className="f15 txt-warning"><br /> ORIENTAL CARRIERS PVT.LTD  </span>
                                                <h4 className="txt-warning f40"><span className="counter">
                                                    <CountUp end= {(this.state.oriental_carriers_data.length)?this.state.oriental_carriers_data.length:0}/>
                                                    </span></h4>
                                            </div>
                                            <div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickJoshiKonoikeInfo.bind(this)}>
                                                <span className="f15 txt-success"><br /> JOSHI KONOIKE TPT. & INFRA. PVT LTD</span>
                                                <h4 className="txt-success f40"><span className="counter">
                                                    <CountUp end= {(this.state.joshi_konoike_data.length)?this.state.joshi_konoike_data.length:0}/>
                                                    </span></h4>
                                            </div>
									</div>
								</div>

							</div>
						</div>
					</div>
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>
                                       <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Bulk Upload Manual Data</button>
                                       <button className="float-right export-btn white btn-danger" onClick={() => this.onBtExport()}style={{marginRight:"10px",padding:"6px"}}>
                                        Export to Excel</button>
                                        <span className="layoutbtns pull-right">
                                            <button className="float-right custom-btn white btn-danger"
                                            style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.onClickSaveGridState.bind(this)}>
                                                <i className="icofont icofont-save"></i> Save Grid Layout
                                            </button>
                                            <button className="float-right custom-btn white btn-info" style={{marginRight:"10px",padding: "9px 4px",fontSize: "14px"}} onClick={this.resetState.bind(this)}>
                                                <i className="icofont icofont-refresh"></i> Reset Default Layout
                                            </button>
                                        </span>
								</h5>
				   			</div>
				   			<div className="card-body row">
							   <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
								   <div className={"col-xl-12 col-lg-12 "+this.state.showStateTrucksMsg}>
								   	{/* <p style={{color:"#ff0000"}}>* Note: This is an E-Mail report and will be sent to the E-Mails Provided</p> */}
								   </div>

	                    			<div className="row">
                                        {/* <div className="col-xl-3 col-lg-3">
                                            <div className="form-group">
                                                <label>Select Transporter : </label>
                                                <Select
                                                    value= {this.state.selectedtransporter}
                                                    // placeholder="All"
                                                    closeMenuOnSelect={true}
                                                    isMulti={false}
                                                    // onChange={this.getRailConsginments.bind(this)}
                                                    className={"border-radius-0"}
                                                    style={{borderRadius:"0px"}}
                                                    options={[{"label":"All",value:"All"},{"label":"APL LOGISTICS",value:"T275"},
                                                    {"label":"IVC LOGISTICS LIMITED",value:"T305"},{"label":"NYK ADANI",value:"T324"},
                                                    {"label":"ORIENTAL CARRIERS PVT.LTD",value:"T025"},{"label":"JOSHI KONOIKE TPT. & INFRA. PVT LTD",value:"T314"}]}
                                                    onChange={(e)=>{this.setState({selectedtransporter:e})}}
                                                />
                                            </div>
                                        </div>

										<div className="form-group col-xl-2 col-lg-2">
											<button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
										</div> */}
									</div>


								</form>
								<div className="row col-xl-12 col-lg-12 col-sm-12">

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
                                    {/* <img id="ItemPreview" src={this.state.srcImage} /> */}
                                    <div className="col-xl-12 col-lg-12 ">

                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                masterDetail = {true}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                defaultExcelExportParams={this.state.defaultExcelExportParams}
                                                statusBar={this.state.statusBar}
                                                frameworkComponents={this.state.frameworkComponents}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                                // enableRangeSelection={false}
                                                onCellClicked={this.onCellClicked.bind(this)}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                floatingFilter={false}
                                                // onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>

								</div>

                            </div>
				   		</div>
				   	</div>
				 </div>


				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
               
                <div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							{this.state.sliderTitle}
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            {(this.state.sliderType == 1)?
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File *</label>
                                <input
                                    type="file"
                                    name="uploadfile"
                                    id="uploadfile"
                                    className="form-control"
                                    onChange={this.changeFileHandler.bind(this)} />
                            </div>

                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group" style={{color:"red",fontWeight:"bold"}}>
                                Date format should be in dd/mm/yyyy hh:mm:ss
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/sample_bulk_stockyard_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                        </form>
                        :""}
                        {(this.state.sliderType == 4)?
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
								
								
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Vin No</label>
                                        <input 
                                        type="text" 
                                        name="vin_no"
                                        id="vin_no"
                                        autoComplete="off"
                                        disabled="disabled"
                                        value={this.state.vin_no}
                                        className="form-control" 
                                        onChange={this.changeHandler.bind(this)} 
                                        />   
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">MSIL Rake ID</label>
                                        <input 
                                            type="text"
                                            name="msil_rake_id"
                                            id="msil_rake_id"
                                            autoComplete="off"
                                            disabled="disabled"
                                            value={this.state.msil_rake_id}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Transporter Name</label>
                                        <input 
                                            type="text"
                                            name="transporter_manual_name"
                                            id="transporter_manual_name"
                                            autoComplete="off"
                                            disabled="disabled"
                                            value={this.state.transporter_manual_name}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Bay No</label>
                                        <input 
                                            type="text" 
                                            name="bay_no"
                                            id="bay_no"
                                            autoComplete="off"
                                            value={this.state.bay_no}
                                            disabled="disabled"
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Bay In Damage Remarks</label>
                                            <input 
                                            type="text" 
                                            name="gate_in_remarks"
                                            id="gate_in_remarks"
                                            autoComplete="off"
                                            value={this.state.gate_in_remarks}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Bay Out Damage Remarks</label>
                                            <input 
                                            type="text" 
                                            name="gate_out_remarks"
                                            id="gate_out_remarks"
                                            autoComplete="off"
                                            value={this.state.gate_out_remarks}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    {/* <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Time Spent In Parking</label>
                                            <input 
                                            type="text" 
                                            name="time_spent_in_parking"
                                            id="time_spent_in_parking"
                                            autoComplete="off"
                                            value={this.state.time_spent_in_parking}
                                            className="form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div> */}
                                    
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Gate In Date</label>
                                        <input 
                                            type="text" 
                                            name="gate_in_date"
                                            id="gate_in_date"
                                            autoComplete="off"
                                            value={this.state.gate_in_date}
                                            className="gate_in_date datetimepicker_mask form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Bay Allocation Date</label>
                                        <input 
                                            type="text" 
                                            name="bay_allocation_date"
                                            id="bay_allocation_date"
                                            autoComplete="off"
                                            value={this.state.bay_allocation_date}
                                            className="bay_allocation_date datetimepicker_mask form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Bay De-allocation Date</label>
                                        <input 
                                            type="text" 
                                            name="bay_deallocation_date"
                                            id="bay_deallocation_date"
                                            autoComplete="off"
                                            value={this.state.bay_deallocation_date}
                                            className="bay_deallocation_date datetimepicker_mask form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Gate Out Date</label>
                                        <input 
                                            type="text" 
                                            name="gate_out_date"
                                            id="gate_out_date"
                                            autoComplete="off"
                                            value={this.state.gate_out_date}
                                            className="gate_out_date datetimepicker_mask form-control" 
                                            onChange={this.changeHandler.bind(this)} 
                                        />    
                                    </div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
                            :""}
                            {(this.state.sliderType == 2)?
                            <div className="row col-sm-12">
                                {(this.state.gate_in_images_view.map(function(g,index){
                                     return <div className="col-sm-4" style={{marginBottom:"2%"}}>
                                        {(index == 0)?
                                            <img src={"data:application/octet-stream;base64,"+g.replace(/ /g,"+")}/>
                                        :
                                            <img src={"data:application/octet-stream;base64,"+g.replace(/ /g,"+").replace("+","")}/>
                                        }
                                    </div>
                                }))}
                            </div>
                            :""}
                            {(this.state.sliderType == 3)?
                            <div className="row col-sm-12">
                                {(this.state.gate_out_images_view.map(function(g,index){
                                     return <div className="col-sm-4" style={{marginBottom:"2%"}}>
                                        {(index == 0)?
                                            <img src={"data:application/octet-stream;base64,"+g.replace(/ /g,"+")}/>
                                        :
                                            <img src={"data:application/octet-stream;base64,"+g.replace(/ /g,"+").replace("+","")}/>
                                        }
                                    </div>
                                }))}
                            </div>
                            :""}
						</div>
					</div>
				</div>


            </div>
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{

	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);

}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }
  function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}
function loadDateTimeScript(){
    console.log("datetime")
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
		onShow:false
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
		onShow:false
	});
    $(document).on(datetimepicker, ".datetimepicker_date", {
        mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false,
		onShow:false
    })

    
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function getParams() {

    return {
        allColumns:false,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: false,
        skipGroups: true,
        skipHeader: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
    };

}

function checkIfArrayIsUnique(myArray) {
    return myArray.length === new Set(myArray).size;
}