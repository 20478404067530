import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import Select from 'react-select';
import UpdateItem from './updaterow';
import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';

import axios from 'axios';

import Modal from 'react-responsive-modal';

import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import { getDDMMYYYY, getDDMMMYYYY,getHyphenDDMMMYYYY,
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';
$.datetimepicker.setLocale('en');  
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class TPTOEMConsignments extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				updateItem:UpdateItem,
                deleteItem:DeleteItem
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            plant:{"value":"","label":"Plant Location"},
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            dealers:[],
            plantlocations:[],
            dealer:{"value":"",label:"Dealer"},
            city:{"value":"",label:"City"},
            transittype:{"value":"Road", "label":"Road"},
            transit_distance:"",
            transit_time:"",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            oems:[],
            oem:{"value":"","label":"OEM"},
            truck_no:"",
            trip_no:"",
            vin_no:"",
            invoice_date:"",
            gate_in_time:"",
            gate_out_time:"",
            selected_eway_bill_date:"",
            drivers:[],
            driver:{"value":"","label":"Driver"}

		};
        this.updateRowHandler = this.updateRowHandler.bind(this);
        this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
	
				department='SNDG';
				departmentName = " ";
				
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
        loadDateTimeScript()
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        
        this.setState({
            loadshow:"show-n",
            overly:"show-n"
        })

       
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/		
       this.onLoadData();
       var parameters = {
            transporter_code:localStorage.getItem("transportercode")
        }
        redirectURL.post('/dashboard/tptdrivers', parameters)    
            .then((response) => {
            var records = response.data.records;
            //   console.log("records ", records)
            this.setState({
                drivers:records
            });
            })
            .catch(function (error) {
            console.log(error);
        });
        
        // redirectURL.post('/dashboard/oemlocations', parameters)    
        //     .then((response) => {
        //     var records = response.data.records;
        //     //   console.log("records ", records)
        //     this.setState({
        //         plantlocations:records
        //     });
        //     })
        //     .catch(function (error) {
        //     console.log(error);
        // });
        
        redirectURL.post('/dashboard/oemmasterdata')    
            .then((response) => {
            var records = response.data.records;
            //   console.log("records ", records)
            this.setState({
                oems:records
            });
            })
            .catch(function (error) {
            console.log(error);
        });
    };
    onLoadData(){
        var parameters = {
            transporter_code:localStorage.getItem("transportercode")
        }
        redirectURL.post('/dashboard/oemconsignments',parameters)    
        .then((response) => {
          var records = response.data.records;
        //   console.log("records ", records)
          this.setState({
              rowData:records
          });
        })
        .catch(function (error) {
          console.log(error);
      });
     
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    onClickUpdateRowHandler(event)
	{
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
           let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
           let pageTitle = "Update OEM Consignmentss";
           if (currentDepartmentTitle) {
               pageTitle = "Update OEM Consignmentss" +  currentDepartmentTitle;
           }
           
           let eventOptions = {
               "category": pageTitle,
               "action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
               "label": googleAnalytics.page.action.rowUpdated,
           }
           googleAnalytics.logEvent(eventOptions);
       }
      
       var invoice_date = $("#uinvoice_date").val();
        var gate_in_time = $("#ugate_in_time").val();
        var gate_out_time = $("#ugate_out_time").val();
        var eway_bill_date = $("#eway_bill_date").val();
       if(this.state.oem.value != "" && this.state.plant.value != "" && this.state.dealer.value != ""
       && invoice_date != "" && gate_in_time != "" && gate_out_time != "" 
       && invoice_date != "__-__-____ __:__" && gate_in_time != "__-__-____ __:__" && gate_out_time != "__-__-____ __:__" )
       {
           var params = {
                rowID:this.state.rowid,
                oem_code:this.state.oem.value,
                oem_name:this.state.oem.label,
                plant_location_code:this.state.plant.value,
                plant_location_name:this.state.plant.label,
                dealer_code:this.state.dealer.value,
                dealer_name:this.state.dealer.label,
                city_name:this.state.city.value,
                truck_no:this.state.truck_no,
                trip_no:this.state.trip_no,
                vin_no:this.state.vin_no,
                driver_name:this.state.driver.value,
                invoice_date:invoice_date,
                gate_in_time:gate_in_time,
                gate_out_time:gate_out_time,
                eway_bill_date:eway_bill_date,
                transporter_code:localStorage.getItem("transportercode")
           }
           redirectURL.post("/dashboard/updateoemconsignment", params,{
               headers:{
                   'Content-Type': 'application/json'
               }
           }).
           then(
               (response)=>{
                   //console.log("update response is : ",response.data);
                   //console.log("Update Response ",response.data.result)
                   if(response.data.status == "success")
                   {
                       this.setState({
                           show: true,
                           basicTitle:'Successfully update data',
                           basicType:"success",
                           sliderUpdate:"",
                           overly:"show-n"
                       });
                       this.onLoadData();
                   }
                   else if(response.data.status == "gateout"){
                        this.setState({
                            show: true,
                            basicTitle:'Gate out time should be greater than gate in time and invoice date.',
                            basicType:"danger",
                        });
                    }
                   else{
                       this.setState({
                           show: true,
                           basicTitle:'Something went wrong',
                           basicType:"danger",
                       });
                   }
                   
               }
           )
           .catch(function(error){
               console.log(error);
           });
       }
       else{
           this.setState({
               show: true,
               basicTitle:'All * fields should not be empty',
               basicType:"danger",
           });
       }

    }

    
    onClickNewAddRow(event)
	{
        event.preventDefault();
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "Add OEM Consignments";
			if (currentDepartmentTitle) {
				pageTitle = "Add OEM Consignments" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
        }
        
        var invoice_date = $("#invoice_date").val();
        var gate_in_time = $("#gate_in_time").val();
        var gate_out_time = $("#gate_out_time").val();
        var eway_bill_date = $("#eway_bill_date").val();
       if(this.state.oem.value != "" && this.state.plant.value != "" && this.state.city.value != ""
       && invoice_date != "" && gate_in_time != "" && gate_out_time != "" 
       && invoice_date != "__-__-____ __:__" && gate_in_time != "__-__-____ __:__" && gate_out_time != "__-__-____ __:__" )
       {
           var params = {
                oem_code:this.state.oem.value,
                oem_name:this.state.oem.label,
                plant_location_code:this.state.plant.value,
                plant_location_name:this.state.plant.label,
                dealer_code:this.state.dealer.value,
                dealer_name:this.state.dealer.label,
                city:this.state.city.value,
                truck_no:this.state.truck_no,
                trip_no:this.state.trip_no,
                vin_no:this.state.vin_no,
                driver_name:this.state.driver.value,
                invoice_date:invoice_date,
                gate_in_time:gate_in_time,
                gate_out_time:gate_out_time,
                eway_bill_date:eway_bill_date,
                transporter_code:localStorage.getItem("transportercode")
            }
            console.log("params ", params)
            redirectURL.post("/dashboard/saveoemconsignment", params,{
                headers:{
                    'Content-Type': 'application/json'
                }
            }).
            then(
                (response)=>{
                    //console.log("update response is : ",response.data);
                    //console.log("Update Response ",response.data.result)
                    if(response.data.status == "success")
                    {
                        this.setState({
                            show: true,
                            basicTitle:'Successfully added data',
                            basicType:"success",
                            sliderRso:"",
                            overly:"show-n",
                            loadshow:"show-n"
                        });
                        this.onLoadData();
                    }
                    else if(response.data.status == "gateout"){
                        this.setState({
                            show: true,
                            basicTitle:'Gate out time should be greater than gate in time and invoice date.',
                            basicType:"danger",
                        });
                    }
                    else{
                        this.setState({
                            show: true,
                            basicTitle:'Something went wrong',
                            basicType:"danger",
                        });
                    }
                    
                }
            )
            .catch(function(error){
                console.log(error);
            });
        }
        else{
            this.setState({
                show: true,
                basicTitle:'All * fields should not be empty',
                basicType:"danger",
            });
        }

    }
    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    /*Alert Popups*/
	closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        await window.location.reload();
    }
    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
            overly:'show-m',
            plant:{"value":"","label":"Plant Location"},
            dealer:{"value":"","label":"Dealer"},
            oem:{"value":"","label":"OEM"},
            driver:{"value":"","label":"Driver"},
            truck_no:"",
            trip_no:"",
            vin_no:"",
            invoice_date:"",
            gate_in_time:"",
            gate_out_time:""
		})
        $("#invoice_date").val("__-__-____ __:__");
        $("#gate_in_time").val("__-__-____ __:__");
        $("#gate_out_time").val("__-__-____ __:__");
        $("#invoice_date").val("");
        $("#gate_in_time").val("");
        $("#gate_out_time").val("");
        
    }
    
    
	 updateRowHandler = async(cell) => {
       
        var rowid = cell._id;
        // console.log("cell ", cell)
        var inv = moment.parseZone(cell.invoice_date).format("DD-MM-YYYY HH:mm:ss");
        var gtim = moment.parseZone(cell.gate_in_time).format("DD-MM-YYYY HH:mm:ss");
        var gtom = moment.parseZone(cell.gate_out_time).format("DD-MM-YYYY HH:mm:ss"); 
        // console.log("inv ", inv)
        $("#uinvoice_date").val(inv);
        $("#ugate_in_time").val(gtim);
        $("#ugate_out_time").val(gtom);

        var transporter_code = localStorage.getItem("transportercode")
        var prms = {
            transporter_code:transporter_code,
            oem_code:cell.oem_code
        }
        redirectURL.post("/dashboard/oemplantdealersdata",prms)
        .then((response) => {
            console.log("respone oem ", response.data)
            var dealers = response.data.dealers;
            var plants = response.data.plants;
            this.setState({
                dealers:dealers,
                plantlocations:plants
            })
        })

        await this.setState({
			sliderUpdate:'slider-translate',
            overly:'show-m',   
            oem:{"value":cell.oem_code,"label":cell.oem_name},        
            plant:{"value":cell.plant_location_code,"label":cell.plant_location_name},
            dealer:{"value":cell.dealer_code,"label":cell.dealer_name},
            driver:{"value":cell.driver_name,"label":cell.driver_name},
            truck_no:cell.truck_no,
            trip_no:cell.trip_no,
            vin_no:cell.vin_no,
            invoice_date:inv,
            gate_in_time:gtim,
            gate_out_time:gtom,
            rowid:rowid
		})
        
    }


    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
   
    changeNumberAccept = (event) => {
        var pattern = /^[0-9\b]+$/;
        // /^[0-9\b]+$/
        let name = event.target.name;
        let value = event.target.value;
        // console.log("herer ", event.target.value)
        if(pattern.test(value))
        {
            this.setState({[name]:value});
        }
    }
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    deleteRowHandler(cell)
	{
		//  console.log("Here",cell);
		 if (googleAnalytics.page.enableGA) {
			let currentDepartmentTitle = this.getCurrentDepartmentCode(window.location.pathname);
			let pageTitle = "OEM Consignments";
			if (currentDepartmentTitle) {
				pageTitle = "OEM Consignments" +  currentDepartmentTitle;
			}
			
			let eventOptions = {
				"category": pageTitle,
				"action": googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname,
				"label": googleAnalytics.page.action.rowUpdated,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var fdata = {
	    		rownode:cell
	    }
	    redirectURL.post("/dashboard/deleteoemtransittime", fdata,{
			headers:{
				'Content-Type': 'application/json'
			}
		}).
		then(
			(response)=>{
				//console.log("update response is : ",response.data);
				//console.log("Update Response ",response.data.result)
				if(response.data.status == "success")
				{
					this.setState({
						show: true,
						basicTitle:'Successfully delete item',
						basicType:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderRso:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
				
			}
		)
		.catch(function(error){
			console.log(error);
		});

    }
     
    onChangeSourceItem(plant){
		this.setState(
			{ plant },
			() => console.log(`Option selected:`, this.state.plant)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeDealers(dealer){
		this.setState(
			{ dealer },
			() => console.log(`Option selected:`, this.state.dealer)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    onChangeCity(city){
		this.setState(
			{ city },
			() => console.log(`Option selected:`, this.state.city)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDriver(driver){
		this.setState(
			{ driver },
			() => console.log(`Option selected:`, this.state.driver)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    onChangeOEM(oem){
		this.setState(
			{ oem },
			() => {
                var transporter_code = localStorage.getItem("transportercode")
                var prms = {
                    transporter_code:transporter_code,
                    oem_code:this.state.oem.value
                }
                redirectURL.post("/dashboard/oemplantdealersdata",prms)
                .then((response) => {
                    console.log("respone oem ", response.data)
                    var dealers = response.data.dealers;
                    var plants = response.data.plants;
                    this.setState({
                        dealers:dealers,
                        plantlocations:plants
                    })
                })
            }
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.plantlocations.length > 0)
			{
				this.state.plantlocations.map((item) =>{
					items.push({"value":item.plant_location_code,"label":item.plant_location_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}
    selectdealers(){
		let items = [];    
		try{
			if(this.state.dealers.length > 0)
			{
				this.state.dealers.map((item) =>{
					items.push({"value":item.dealer_code,"label":item.dealer_name})
				})
			}
		}
		catch(e){

		}		
		return items;
        
    }
    selectcity(){
		let items = []; 
        var citieslist = [];
        if(this.state.dealers.length > 0){
           citieslist =  [...new Set( this.state.dealers.map(obj => obj.city)) ];
        }
		try{
			if(citieslist.length > 0)
			{
			    citieslist.map((item) =>{
                    if(item != '')
                    {

					items.push({"value":item,"label":item})

                    }
				})
			}
		}
		catch(e){

		}	
		return items;
    }
    selectoem(){
		let items = [];    
		try{
			if(this.state.oems.length > 0)
			{
				this.state.oems.map((item) =>{
					items.push({"value":item.oem_code,"label":item.oem_name})
				})
			}
		}
		catch(e){

		}		
		return items;
	}

    selectdrivers(){
		let items = [];    
		try{
			if(this.state.drivers.length > 0)
			{
				this.state.drivers.map((item) =>{
					items.push({"value":item.driver_name,"label":item.driver_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }

    onChangeTransitType(transittype){
		this.setState(
			{ transittype },
			() => console.log(`Option selected:`, this.state.transittype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onClickShowBulk()
    {
        this.setState({
            overly:"show-m",
			sliderBulk:'slider-translate',
        })
    }
    

	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'plant_location_name',
					inputName: 'plant_location_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'dealer_name',
					inputName: 'dealer_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'transit_type',
					inputName: 'transit_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'transit_distance',
					inputName: 'transit_distance',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'transit_time',
					inputName: 'transit_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
	}
    onSubmitBulkUpload(event)
    {
        event.preventDefault()

        if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
        }
       
        var params = {
            csvcontent:this.state.csvcontent,
            transporter_code:localStorage.getItem("transportercode")
        }
        // console.log("Params ", params)
        redirectURL.post("/dashboard/bulkuploadoemtransittimes", params)
        .then((response) => {
            if(response.data.status == "success")
				{
					this.setState({
						show1: true,
						basicTitle1:'Successfully added items',
						basicType1:"success",
                        loadshow:"show-n",
                        overly:"show-n",
                        sliderBulk:"",
                        file:""
                    });
                    this.onLoadData();
				}
				
				else{
					this.setState({
						show: true,
						basicTitle:'Something went wrong',
						basicType:"danger",
					});
				}
        })
    }

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
	  	        {
	  	          headerName: "OEM Name",
	  	          field: "oem_name",
	  	          width: 120,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Plant Location Name",
	  	          field: "plant_location_name",
	  	          width: 120,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
	  	          headerName: "Dealer Name",
	  	          field: "dealer_name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },

                {
	  	          headerName: "City",
	  	          field: "city",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },

                {
	  	          headerName: "Truck No",
	  	          field: "truck_no",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
                {
	  	          headerName: "Trip No",
	  	          field: "trip_no",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
                // {
	  	        //   headerName: "Vin No",
	  	        //   field: "vin_no",
	  	        //   width: 150,
                //   editable:false,
	    	    //   filter: "agSetColumnFilter"
                // },
                
                
                {
	  	          headerName: "Invoice Date",
	  	          field: "invoice_date",
	  	          width: 160,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                      try{
                          if(params.data.invoice_date != "" && params.data.invoice_date != undefined)
                          {
                              return getHyphenDDMMMYYYYHHMM(params.data.invoice_date);
                          }
                          else{
                              return "";
                          }
                      }
                      catch(e){
                          return "";
                      }
                  }
                },
                
                {
	  	          headerName: "Gate In Time",
	  	          field: "gate_in_time",
	  	          width: 160,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                      try{
                          if(params.data.gate_in_time != "" && params.data.gate_in_time != undefined)
                          {
                              return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                          }
                          else{
                              return "";
                          }
                      }
                      catch(e){
                          return "";
                      }
                  }
                },
                
                {
	  	          headerName: "Gate Out Time",
	  	          field: "gate_out_time",
	  	          width: 160,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                      try{
                          if(params.data.gate_out_time != "" && params.data.gate_out_time != undefined)
                          {
                              return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                          }
                          else{
                              return "";
                          }
                      }
                      catch(e){
                          return "";
                      }
                  }
                },


                {
	  	          headerName: "Driver Name",
	  	          field: "driver_name",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
                {
	  	          headerName: "Eway Bill Date",
	  	          field: "eway_bill_date",
	  	          width: 160,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                      try{
                          if(params.data.eway_bill_date != "" && params.data.eway_bill_date != undefined)
                          {
                              return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                          }
                          else{
                              return "";
                          }
                      }
                      catch(e){
                          return "";
                      }
                  }
                },

		        {
                    headerName: "Action",
                    field: "_id",
                    width: 100,
                    filter: false,
                    cellRenderer:'updateItem',
                    editable:false
		        },
		        // {
                //     headerName: "Action",
                //     field: "_id",
                //     width: 100,
                //     filter: false,
                //     cellRenderer:'deleteItem',
                //     editable:false
		        // }
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
            <SweetAlert
	            show={this.state.show1}
	            type={this.state.basicType1}
	            title={this.state.basicTitle1}
	            onConfirm={this.closeAlert1}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>OEM Consignments </span>
                                       <button type="button" onClick={this.onAddRow.bind(this)} className="btn btn-warning float-right">Add New Row</button>
                                       {/* <button type="button" onClick={this.onClickShowBulk.bind(this)} className="btn btn-danger float-right">Bulk Upload</button> */}
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
				
                
                <div className={"slide-r "+(this.state.sliderRso)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Add OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickNewAddRow.bind(this)}>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select OEM *</label>
                                
                                <Select 
                                placeholder={"Select OEM"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeOEM.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.oem}
                                options={this.selectoem()} />  
                            </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Plant Location *</label>
                                
                                <Select 
                                placeholder={"Select Plant Location"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.plant}
                                options={this.selectsources()} />  
                            </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Dealer </label>
                                
                                <Select 
                                placeholder={"Select Dealer"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeDealers.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.dealer}
                                options={this.selectdealers()} />  
                            </div>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select city * </label>
                                
                                <Select 
                                placeholder={"Select City"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeCity.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.city}
                                options={this.selectcity()} />  
                            </div>
                            
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Truck No *</label>
								<input 
									type="text" 
									name="truck_no"
									value={this.state.truck_no}
									className="form-control" 
                                    // onChange={this.changeNumberAccept.bind(this)}
                                    onChange = {this.changeHandler.bind(this)}
                                    // onKeyPress={onlyNumberKey(this.value)}
                                    />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Trip No *</label>
								<input 
									type="text" 
									name="trip_no"
									value={this.state.trip_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Vin No *</label>
								<input 
									type="text" 
									name="vin_no"
									value={this.state.vin_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div> */}
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Driver *</label>
                                
                                <Select 
                                placeholder={"Select Driver"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeDriver.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                options={this.selectdrivers()} />  
                            </div> 
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Invoice Date *</label>
								<input 
									type="text" 
									name="invoice_date"
									id="invoice_date"
									value={this.state.invoice_date}
									className="invoice_date datetimepicker_mask form-control" 
                                    autoComplete={"off"}
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Gate In Time *</label>
								<input 
									type="text" 
									name="gate_in_time"
									id="gate_in_time"
									value={this.state.gate_in_time}
                                    autoComplete={"off"}
									className="gate_in_time datetimepicker_mask form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Gate Out Time *</label>
								<input 
									type="text" 
									name="gate_out_time"
									id="gate_out_time"
									value={this.state.gate_out_time}
                                    autoComplete={"off"}
									className="gate_out_time datetimepicker_mask form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Eway Bill date</label>
								<input 
									type="text" 
									name="eway_bill_date"
									id="eway_bill_date"
									value={this.state.selected_eway_bill_date}
                                    autoComplete={"off"}
									className="gate_out_time datetimepicker_mask form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>



                <div className={"slide-r "+(this.state.sliderUpdate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Update OEM Item
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickUpdateRowHandler.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select OEM *</label>
                                
                                <Select 
                                placeholder={"Select OEM"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeOEM.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.oem}
                                options={this.selectoem()} />  
                            </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Plant Location *</label>
                                
                                <Select 
                                placeholder={"Select Plant Location"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeSourceItem.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value = {this.state.plant}
                                options={this.selectsources()} />  
                            </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Dealer </label>
                                
                                <Select 
                                placeholder={"Select Dealer"}
                                closeMenuOnSelect={true}
                                // isMulti={true}
                                onChange={this.onChangeDealers.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value = {this.state.dealer}
                                options={this.selectdealers()} />  
                            </div>
                            
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Truck No *</label>
								<input 
									type="text" 
									name="truck_no"
									value={this.state.truck_no}
									className="form-control" 
                                    // onChange={this.changeNumberAccept.bind(this)}
                                    onChange = {this.changeHandler.bind(this)}
                                    // onKeyPress={onlyNumberKey(this.value)}
                                    />  
                            </div>
							     
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Trip No *</label>
								<input 
									type="text" 
									name="trip_no"
									value={this.state.trip_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Vin No *</label>
								<input 
									type="text" 
									name="vin_no"
									value={this.state.vin_no}
									className="form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div> */} 
                            
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Select Driver *</label>
                                
                                <Select 
                                placeholder={"Select Driver"}
                                closeMenuOnSelect={true}
                                 // isMulti={true}
                                onChange={this.onChangeDriver.bind(this)} 
                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                style={{borderRadius:"0px"}}
                                value={this.state.driver}
                                options={this.selectdrivers()} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Invoice Date *</label>
								<input 
									type="text" 
									name="invoice_date"
									id="uinvoice_date"
									value={this.state.invoice_date}
                                    autoComplete={"off"}
									className="invoice_date datetimepicker_mask form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Gate In Time *</label>
								<input 
									type="text" 
									name="gate_in_time"
									id="ugate_in_time"
									value={this.state.gate_in_time}
                                    autoComplete={"off"}
									className="gate_in_time datetimepicker_mask form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Gate Out Time *</label>
								<input 
									type="text" 
									name="gate_out_time"
									id="ugate_out_time"
									value={this.state.gate_out_time}
                                    autoComplete={"off"}
									className="gate_out_time datetimepicker_mask form-control" 
									onChange={this.changeHandler.bind(this)} />  
                            </div>
							
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>

						
                        </div>
					</div>
				</div>


                <div className={"slide-r "+(this.state.sliderBulk)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Bulk Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>
							
                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File *</label>
								<input 
									type="file" 
									name="uploadfile"
									className="form-control" 
									onChange={this.changeFileHandler.bind(this)} />  
                            </div>
							 
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
                            
						    <div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_oem_transittimes.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
						</form>

						
                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}

function onlyNumberKey(e) {
    const pattern = /^[0-9]$/;

    return pattern.test(e)
}



window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.gate_in_time').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.gate_out_time').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$(".invoice_date").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.gate_in_time').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.gate_out_time').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$(".invoice_date").datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});

    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
