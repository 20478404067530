/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import MarkerClusterer from "@google/markerclusterer";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import $ from "jquery";
//import { getConsignmentsNoGpsLifeTimeCounter } from '../../../../tpt_node/models/consignmentModel';

import CountUp from 'react-countup';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
var consigners=[];
export default class StockyardBayInformation extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Bay Information",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },

			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
            defaultExcelExportParams: {
                allColumns: true,
            },
			plant_code:'',
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:"1",
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
			showStateTrucksMsg : "show-n",
			states_list : [],
			selectedState : [],
			showStates : "show-n",
			showRadius : "show-m",
			usermanualmodal:false,
			tpttrucks:[],
			adaniCheck:0,
            fuel_station_options:[],
            fuel_station_data:[],
            fuelstagingtrucks:[],
            selectFuelStationName:"All",
            markersList:[],
            fasttagtrucks:[],
            fasttagtnx:[],
            VehicleNumber:[],
            btnClicked:[],
            CellRendererFunc:[],
			detailCellRendererParams:{},
			totalwallet:0,
			totaltagsassign:0,
			totalfleet:0,
            totalbays:0,
            occupiedbays:0,
            vacantbays:0,
            occupieddata:[],
            vacantdata:[],
            occupied_data:[],
            vacant_data:[],
            bayInfo:[],
            total_bay_count:"",
            selectedRowNode: "",

		};
		// this.popmarker=this.popmarker.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        var params = {
			stockyardemailid:localStorage.getItem("email")
		}
            if(![undefined , "undefined" , "" , null].includes(localStorage.getItem("transportercode"))){
                params.transporter = localStorage.getItem("transportercode")
            }
            redirectURL.post("/consignments/parkingcelldata",params).then((response)=>{
                // console.log(response,"response stokayaaas")
				var bayInfo = response.data.data;
				// console.log(bayInfo,"bayInformation")
                var occupied_data = response.data.occupieddata;
                // console.log(occupied_data,"occupieddata")
                var vacant_data = response.data.vacantdata;
                // console.log(vacant_data,"vacantdata")
                var totalbays = bayInfo.length
                // console.log(totalbays,"totalbays stokayy")
                var occupiedbays = 0;
                var vacantbays = totalbays-occupied_data.length;
                // if(bayInfo.length > 0)
                // {
                //     totalbays = bayInfo.length
                // }
                if(occupied_data.length > 0)
                {
                    occupiedbays = occupied_data.length
                }
                // if(vacant_data.length > 0)
                // {
                //     vacantbays = total_bays-occupied_data.length
                // }
                this.setState({
                rowData: bayInfo,
                totalbays:totalbays,
                occupiedbays:occupiedbays,
                vacantbays:vacantbays,
                occupied_data:occupied_data,
                vacant_data:vacant_data,
                bayInfo:bayInfo,
                })
            })

    }
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}

	  }
    onClickBayTotalInfo = () => {
        var totaldata = this.state.bayInfo;
        // console.log(totaldata,"totaldata")
        this.setState({
        rowData: totaldata
        })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onClickBayOccupiedInfo = () => {
            var occupieddata = this.state.occupied_data;
            // console.log(occupieddata,"occupieddata")
            this.setState({
            rowData: occupieddata
            })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    
    onClickBayVacantInfo = () => {
            var vacantdata = this.state.vacant_data;
            // console.log(vacantdata,"vacantdata")
            this.setState({
            rowData: vacantdata
            })
        // console.log(this.state.rowData,"this.state.rowData")
    }
    onBtExport = () => {
        // this.gridApi.exportDataAsExcel({skipColumnHeaders : false, skipColumnGroupHeaders : false})
        let params = getParams();
        this.gridApi.exportDataAsExcel(params);
        };
    formHandler = (event) =>{
		event.preventDefault();

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted
			}
			googleAnalytics.logEvent(eventOptions);
		}
        var total_bay_count = $("#total_bay_count").val();
        console.log(total_bay_count,"total_bay_count")
        if(total_bay_count!="" && total_bay_count!=undefined)
        {
            if(total_bay_count >= 500 && total_bay_count <= 3000)
            {
                var params = {
                    total_bay_count: total_bay_count,
                    transporter_code: localStorage.getItem("transportercode"),
                    stockyardemailid:localStorage.getItem("email"),
                }
                // if (this.state.selectedRowNode != "") {
                //     params.row_id = this.state.selectedRowNode._id
                // }
                console.log("params ", params)
                redirectURL.post("/dashboard/savestockyardtotalbaycount", params, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).
                    then(
                        (response) => {
                            console.log("update response is : ",response.data);
                            //console.log("Update Response ",response.data.result)
                            if (response.data.status == "success") {
                                this.setState({
                                    show: true,
                                    basicTitle: 'Successfully added data',
                                    basicType: "success",
                                    total_bay_count:"",
                                    overly: "show-n",
                                    loadshow: "show-n"
                                });
                                this.componentDidMount();
                            }
                            else {
                                this.setState({
                                    show: true,
                                    basicTitle: response.data.message,
                                    basicType: "danger",
                                });
                            }
    
                        }
                    )
            }
            else
            {
                this.setState({
                    show:true,
                    basicTitle:'Bay Count should be min 500 and max 3000',
                    basicType:"danger",
                    loadshow:'show-n',
                })
            }
        }
        else
		{
			this.setState({
				show:true,
				basicTitle:'Please enter bay count',
				basicType:"danger",
				loadshow:'show-n',
			})
		}
    }
    changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	onClickHideAll(){
		$("#email_ids").val("");
		this.setState({
			overly : "show-n",
			sliderStateEmailsTranslate : "",
            selectedRowNode: "",

		})
	}
    
   onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

	};


	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};
    render(){
		
		var columnDefs= [
            // {
            //     headerName: "Car Name",
            //     field: "car_name",
            //     width: 200,
  
            // },
            {
                headerName: "Bay No",
                field: "parking_cell",
                width: 150,
  
            },
		 	{
                headerName: "Transporter",
                field: "transporter_code",
                width: 250,

		    },
            // {
            //     headerName: "Gate In Time",
            //     field: "gate_in_time",
            //     width: 150,
            //     valueGetter:function(params){
            //         return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time)}
            // },
            // {
            //     headerName: "Gate In Damage Remarks",
            //     field: "gate_in_damage",
            //     width: 250,

		    // },
            
            {
                headerName: "Status",
                field: "is_active",
                width: 150,
                valueGetter:function(params){
                if(params.data.is_active == 1)
                {
                    return "Occupied"
                }
                else if(params.data.is_active == 0)
                {
                    return "Vacant"
                }
            }
  
            },
            
			{
                headerName: "Vin Number",
                  field: "vin_number",
                  width: 200,
  
            },
            // {
            //     headerName: "Bay Allocation Date",
            //     field: "bay_in_time",
            //     width: 150,
            //     valueGetter:function(params){
            //         return getHyphenDDMMMYYYYHHMM(params.data.bay_in_time)}
            // },
			// {
            //     headerName: "Bay De-allocation Date",
            //     field: "bay_out_time",
            //     width: 150,
            //     valueGetter:function(params){
            //         return getHyphenDDMMMYYYYHHMM(params.data.bay_out_time)}
            // },
            
            // {
            //     headerName: "Gate Out Time",
            //     field: "gate_out_time",
            //     width: 150,
            //     valueGetter:function(params){
            //         return getHyphenDDMMMYYYYHHMM(params.data.bay_out_time)}
            // },
            // {
            //     headerName: "Gate Out Time Remarks",
            //     field: "gate_out_damage",
            //     width: 250,

		    // },
            // {
            //     headerName: "Time Spent in Parking(HH:MM)",
            //     field: "parking_duration_secs",
            //     width: 150,
            //     valueGetter:function(params){
            //       if(params.data.parking_duration_secs > 0)
            //       {
            //           var mre = secondsToHM(params.data.parking_duration_secs);
            //           return mre;
            //       }
            //   },
            // },
            // {
            //     headerName: "Receipt",
            //     field: "receipt",
            //     width: 150,
            //     cellRenderer: (params)=>{
            //         var link = params.data.receipt;
            //         return "<a href="+link+" target='_blank'><i class='fa fa-print'></i></a>"
            //     }
            // }
            
		  ];

    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}

        return (

    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
					<div className="col-xl-12 col-lg-12 col-sm-12 beffect">
						<div className="card">
							<div className="card-body" id="dashboardCounterCardBody">
								<div className="crm-numbers pb-0">
									<div className="row">
                                    
										<div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickBayTotalInfo.bind(this)}>
											<span className="f15 txt-primary"><br /> Total Bays  </span>
											<h4 className="txt-primary f40"><span className="counter">
												<CountUp end= {(this.state.totalbays)?this.state.totalbays:0}/>
                                                {/* <CountUp end= "20"/> */}
												</span></h4>
										</div>
										<div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickBayOccupiedInfo.bind(this)}>
											<span className="f15 txt-success"><br /> Occupied Bays  </span>
											<h4 className=" txt-success f40"><span className="counter">
												<CountUp end= {(this.state.occupiedbays)?this.state.occupiedbays:0}/>
												</span></h4>
										</div>
										<div className="col cpointer" style = {{textAlign:"center"}} onClick = {this.onClickBayVacantInfo.bind(this)}>
											<span className="f15 txt-danger"><br />  Vacant Bays </span>
											<h4 className="txt-danger f40"><span className="counter">
												<CountUp end= {(this.state.vacantbays)?this.state.vacantbays:0}/>
												</span></h4>
										</div> 
									</div>
								</div>

							</div>
						</div>
					</div>
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">

			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>{this.state.pageTitle} </span>
                                       <button className="float-right export-btn white btn-danger" onClick={() => this.onBtExport()} style={{padding:"6px"}}>
                                        Export to Excel</button>
								</h5>
				   			</div>
				   			<div className="card-body row">
							   <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
	                    			<div className="row">
                                    <div className="col-xl-2 col-lg-2 row form-group">
                                        <label className="col-xl-12 col-lg-12">Enter Bay Count</label>
                                        <input
                                            type="text"
                                            name="total_bay_count"
                                            id="total_bay_count"
                                            value={this.state.total_bay_count}
                                            autoComplete="off"
                                            className="form-control"
                                            onChange={this.changeHandler.bind(this)} />
								    </div>
										<div className="form-group col-xl-2 col-lg-2">
											<button type="submit" style={{marginTop:"30px"}} className={"btn btn-success "}>Submit</button>
										</div>
									</div>


								</form>
								<div className="row col-xl-12 col-lg-12 col-sm-12">

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>

                                    <div className="col-xl-12 col-lg-12 ">

                                        <div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={columnDefs}
                                                masterDetail = {true}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={false}
                                                enableRangeSelection={false}
                                                autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                defaultExcelExportParams={this.state.defaultExcelExportParams}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                // enableRangeSelection={false}
                                                detailCellRendererParams={this.state.detailCellRendererParams}
                                                floatingFilter={false}
                                                onCellClicked={this.popmarker}
                                            />
                                        </div>
                                    </div>

								</div>

                            </div>
				   		</div>
				   	</div>
				 </div>


				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				{/* <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span> */}
            </div>
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{

	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);

}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }
  function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}
function getParams() {

    return {
        allColumns:false,
        columnGroups: true,
        columnKeys: true,
        // customHeader: true && makeCustomContent(),
        // customFooter: true && makeCustomContent(),
        skipFooters: false,
        skipGroups: true,
        skipHeader: false,
        skipPinnedTop: true,
        skipPinnedBottom: true,
    };

  }