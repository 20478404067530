/* eslint-disable array-callback-return */
/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import {
    getDDMMYYYY, getDDMMMYYYY, getHyphenDDMMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault
} from '../common/utils';

import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import CSVFileValidator from 'csv-file-validator'
import ShowTPTGpsMap from "./showtptgpsmap";
import ShowTPTGpsBreaks from "./showtptgpsbreaks";
import RoutemapAction from "./gpsroutemaplegs";
import ExportPDF from "./exportpdf"
$.datetimepicker.setLocale('en');
var redirectURL = require('../redirectURL');
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");


export default class DealerDataComponent extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            loadshow: 'show-n',
            overly: "show-n",
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            show1: false,
            basicTitle1: '',
            basicType1: "default",
            dept_code: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            rowData: [],
            pdfRowData: [],
            pdfColumns: [],
            frameworkComponents: {
                showtptgpsmap: ShowTPTGpsMap,
                showtptgpsbreaks: ShowTPTGpsBreaks
            },
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },
            rowClassRules: {
                "boxbgblue": "data.to_be_approved == 1"
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize: 200,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle: "GPS EOD Analysis",
            sliderForAddorEdit: '',
            sliderForBulkUpload:"",
            startDate: "",
            endDate: "",
            defaultStartDate: "",
            defaultEndDate: "",
            routeinfo: "",
            breaktruck: "",
            uniquetrucks: [],
            showTrucksList: "show-n",
            filteredData: [],
            tottalDistance: [],
            pinnedBottomRowData: [],
            pageType: 1,
            showDpk: { display: "none" },
            showMpk: { display: "none" },
            pageTitle: "Dealer Data",
            tpttrucks: [],
            transporterTrucks: [],
            adaniCheck: 0,
            lattitude: '',
            longitude: '',
            dealer_code: '',
            dealer_address: '',
            city: '',
            data_for_edit: '',
            for_edit: false,
            csvcontent:'',
            file:'',
            uploadFile:''
        };


    }

    componentDidMount() {
        this.onLoadData();
    }

    onLoadData() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m",
        })
        var parameters = {}
        var tpt_code = localStorage.getItem("transportercode")
        if (![undefined, null, "undefined", ""].includes(tpt_code)) {
            parameters.transporter_code = JSON.parse(tpt_code)
        }
        redirectURL.post("/dashboard/getdealerData", parameters)
            .then(async (response) => {
                var recs = response.data;
                this.setState({
                    rowData: recs,
                    loadshow: "show-n",
                    overly: "show-n",
                })
            })


    }
    /*Alert Popups*/
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload()
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;


    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onRowClicked = async (params) => {
        console.log(params.data)
        if (params.colDef.field == "update") {
            this.setState({
                data_for_edit: params.data,
                for_edit: true,
                lattitude: params.data.lat,
                longitude: params.data.lng,
                dealer_code: params.data.dealer_code,
                dealer_address: params.data.dealer_address,
                city: params.data.city,
                sliderForAddorEdit: 'slider-translate',
                overly: 'show-m',
            })
        }
    }

    onClickGetSliderForAddorEdit = () => {
        this.setState({
            sliderForAddorEdit: 'slider-translate',
            overly: 'show-m',
        })
    }

    onClickGetSliderForBulkUpload = () => {
        this.setState({
            sliderForBulkUpload: 'slider-translate',
            overly: 'show-m',
        })
    }

    onSubmitUpdateDealerData = (event) => {
        event.preventDefault()
        var dealer_code = this.state.dealer_code
        var lat = this.state.lattitude
        var lng = this.state.longitude
        var dealer_address = this.state.dealer_address
        var city = this.state.city


        if (this.state.for_edit == true) {
            var params = {
                dealer_code: this.state.data_for_edit.dealer_code,
                lat: parseFloat(lat),
                lng: parseFloat(lng),
                dealer_address: dealer_address,
                city: this.state.data_for_edit.city,
                consignee_coordinates: JSON.stringify({ lat: parseFloat(lat), lng: parseFloat(lng) }),
                transporter_code: JSON.parse(localStorage.getItem("transportercode")),
                updated_by: localStorage.getItem("email")
            }
            redirectURL.post("/dashboard/editDealerData", params).then(response => {
                if (response.data.status == 'success') {
                    this.setState({
                        show: true,
                        basicType: 'success',
                        basicTitle: response.data.message,
                        lattitude: '',
                        longitude: '',
                        dealer_code: '',
                        dealer_address: '',
                        city: '',
                        sliderForAddorEdit: '',
                        for_edit: false,
                    })
                    this.componentDidMount()
                } else {
                    this.setState({
                        show: true,
                        basicType: 'danger',
                        basicTitle: response.data.message
                    })
                }
            })
        } else {
            var params = {
                dealer_code: dealer_code,
                lat: parseFloat(lat),
                lng: parseFloat(lng),
                dealer_address: dealer_address,
                city: city,
                consignee_coordinates: JSON.stringify({ lat: parseFloat(lat), lng: parseFloat(lng) }),
                transporter_code: JSON.parse(localStorage.getItem("transportercode")),
                updated_by: localStorage.getItem("email")
            }
            redirectURL.post("/dashboard/addDealerData", params).then(response => {
                if (response.data.status == 'success') {
                    this.setState({
                        show: true,
                        basicType: 'success',
                        basicTitle: response.data.message,
                        lattitude: '',
                        longitude: '',
                        dealer_code: '',
                        dealer_address: '',
                        city: '',
                        sliderForAddorEdit: ''
                    })
                    this.componentDidMount()
                } else {
                    this.setState({
                        show: true,
                        basicType: 'danger',
                        basicTitle: response.data.message
                    })
                }
            })
        }

    }

    changeFileHandler = async (e) => {
        var config = {
            headers: [

                {
                    name: 'Dealer Code',
                    inputName: 'dealer_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Lattitude',
                    inputName: 'lat',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Longitude',
                    inputName: 'lng',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Dealer Address',
                    inputName: 'dealer_address',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'City',
                    inputName: 'city',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                }
            ]
        }

        var data = CSVFileValidator(e.target.files[0], config)
            .then(csvData => {
                console.log(csvData.data)
                this.setState({
                    csvcontent: csvData.data
                })
            })
            .catch(err => { })

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });

        this.setState({
            file: e.target.files[0]
        });

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }


    }

    onSubmitBulkUpload = async (event) => {
        event.preventDefault()
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m',
        })
        var csvData = this.state.csvcontent
        console.log(csvData.length, "lenght1729")
        if (csvData != undefined && csvData != '') {
            if (csvData.length > 0) {
                var params = {
                    csvcontent: csvData,
                    updated_by: localStorage.getItem('email'),
                    transporter_code:JSON.parse(localStorage.getItem("transportercode"))
                }
                console.log(params)
                redirectURL.post('/dashboard/bulkUploadForDealerData', params).then(response => {
                    if (response.data.status == 'success') {
                        this.setState({
                            show: true,
                            basicTitle: response.data.message,
                            basicType: "success",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForBulkUpload: "",
                            uploadFile:''
                        })
                    } else {
                        this.setState({
                            show: true,
                            basicTitle: 'Data not Updated',
                            basicType: "danger",
                            loadshow: 'show-n',
                            overly: 'show-n',
                            sliderForBulkUpload: "",
                            uploadFile:''
                        })
                    }
                })
            }
        }
        else {
            this.setState({
                show: true,
                basicTitle: 'Choose file to continue',
                basicType: "danger",
            });
        }

    }



    onClickHideAll() {
        this.setState({
            overly: 'show-n',
            sliderForAddorEdit: '',
            sliderForBulkUpload:"",
            for_edit: false,
            lattitude: '',
            longitude: '',
            dealer_code: '',
            dealer_address: '',
            city: '',
        });
    }

    render() {
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        var pageType = this.state.pageType;
        if (pageType == 1) {
            var hidemonthly = false;
        }
        else {
            var hidemonthly = true;
        }
        var adaniCheck = this.state.adaniCheck;
        var tkName = "Truck No";
        if (adaniCheck == 1) {
            tkName = "Shuttle No";
        }
        var columnwithDefs = [

            {
                headerName: 'Dealer Code',
                field: "dealer_code",
                width: 120,
            },

            {
                headerName: "Lattitude",
                field: "lat",
                width: 180,
            },
            {
                headerName: "Longitude",
                field: "lng",
                width: 180,
            },
            {
                headerName: "Dealer Adrress",
                field: "dealer_address",
                width: 180,
            },
            {
                headerName: "City",
                field: "city",
                width: 180,
            },
            {
                headerName: "",
                field: "update",
                width: "110",
                editable: false,
                cellRenderer: function (params) {
                    return "<button class='btn btn-info' style='padding-top:0px'>Edit</button>"
                }
            }
        ]
        return (

            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="row">

                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-truck-alt cus-i"></i> <span>{this.state.pageTitle}  </span>


                                    {/* <span className="layoutbtns pull-right">
                                        <button className="float-right custom-btn white btn-danger"
                                            style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.onClickSaveGridState.bind(this)}>
                                            <i className="icofont icofont-save"></i> Save Grid Layout
                                        </button>
                                        <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} onClick={this.resetState.bind(this)}>
                                            <i className="icofont icofont-refresh"></i> Reset Default Layout
                                        </button>
                                    </span>

                                    {(this.state.pdfRowData.length > 0) ?
                                        <ExportPDF rowData={this.state.pdfRowData} pagetitle ="Distance Traveled Monthly Report" pdfColumns={this.state.pdfColumns} />
                                        : ""} */}
                                    <button className="float-right btn white btn-dark" onClick={this.onClickGetSliderForAddorEdit} style={{ width: '100px', marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} >
                                        Add Item
                                    </button>
                                    <button className="float-right btn white btn-arms" onClick={this.onClickGetSliderForBulkUpload} style={{ width: '100px', marginRight: "10px", padding: "9px 4px", fontSize: "14px" }} >
                                        Bulk Upload
                                    </button>
                                </h5>
                            </div>
                            <div className="card-body row">
                                {/* <div className="row col-xl-12 col-lg-12">
                                    <div style={{ top: "65px" }} className={"trucks-dropdown " + this.state.showTrucksList}>
                                        <ul>
                                            {listData}
                                        </ul>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>{(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}</label>
                                        <input
                                            type="text"
                                            name="truck_no"
                                            id="inputTruck"
                                            placeholder={(this.state.adaniCheck == 1) ? "Shuttle No" : "Truck No"}
                                            autoComplete="off"
                                            className="form-control"
                                            onFocus={this.initalList.bind(this)}
                                            onChange={this.handlerForm.bind(this)} />
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>From Date</label>

                                        <input
                                            type="text"
                                            id="gpsstartdate"
                                            name="gpsstartdate"
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group">
                                        <label>To Date</label>

                                        <input
                                            type="text"
                                            id="gpsenddate"
                                            name="gpsenddate"
                                            className="form-control"
                                        />
                                    </div>


                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button type="button" className="btn btn-success" style={{ marginTop: "29px" }} onClick={this.onClickFilterData.bind(this)}>Submit</button>
                                    </div>

                                </div> */}
                                <div className={"row col-xl-12 col-lg-12 "}>

                                    <div id="myGrid" style={{ height: "480px", width: "100%" }} className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            enableCharts={true}
                                            enableRangeSelection={true}
                                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            statusBar={this.state.statusBar}
                                            sideBar={this.state.sideBar}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            // rowClassRules={this.state.rowClassRules}
                                            frameworkComponents={this.state.frameworkComponents}
                                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                            detailCellRendererParams={this.state.detailCellRendererParams}
                                            masterDetail={true}
                                            pinnedBottomRowData={this.state.tottalDistance}
                                            onCellClicked={this.onRowClicked.bind(this)}
                                            gridOptions={{
                                                context: { componentParent: this },
                                                getRowStyle: function (params) {
                                                    if (params.node.rowPinned) {
                                                        return { 'font-weight': 'bold', 'font-size': '16px' };
                                                    }
                                                },
                                            }}
                                        />

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


                {this.state.sliderForAddorEdit != '' ?
                    <div className={"slide-r " + (this.state.sliderForAddorEdit)} style={{ overflow: "auto" }}>
                        <div className="slide-r-title">
                            <h4>
                                View Route
                                <span className="float-right closebtn" style={{ marginRight: "25px" }}
                                    onClick={this.onClickHideAll.bind(this)} >X</span>

                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>

                            <div className="container-fluid">

                                <form method="post" onSubmit={this.onSubmitUpdateDealerData} className="row col-xl-12 col-lg-12">
                                    {this.state.for_edit == true ? "" :
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Dealer Code:</label>
                                            <input className='form-control' id='dealer_code' value={this.state.dealer_code} onChange={(e) => {
                                                this.setState({
                                                    dealer_code: e.target.value
                                                })
                                            }} required />
                                        </div>}
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Lattitude:</label>
                                        <input className='form-control' id='lat' value={this.state.lattitude} onChange={(e) => {
                                            var value = e.target.value
                                            console.log(value)
                                            var regex = /^[0-9.]+$/
                                            if (regex.test(value) || value == '') {
                                                this.setState({
                                                    lattitude: value
                                                })
                                            }
                                        }} required />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Longitude:</label>
                                        <input className='form-control' id='lng' value={this.state.longitude} onChange={(e) => {
                                            var value = e.target.value
                                            var regex = /^[0-9.]+$/
                                            console.log(value)
                                            if (regex.test(value) || value == '') {
                                                this.setState({
                                                    longitude: value
                                                })
                                            }
                                        }} required />
                                    </div>
                                    <div className="col-xl-12 col-lg-12 form-group">
                                        <label>Dealer Address:</label>
                                        <input className='form-control' id='dealer_address' value={this.state.dealer_address} onChange={(e) => {
                                            this.setState({
                                                dealer_address: e.target.value
                                            })
                                        }} required />
                                    </div>
                                    {this.state.for_edit == true ? "" :
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label>City:</label>
                                            <input className='form-control' id='city' value={this.state.city} onChange={(e) => {
                                                this.setState({
                                                    city: e.target.value
                                                })
                                            }} required />
                                        </div>}

                                    <div className="form-group col-xl-3 col-lg-3">
                                        <button type="submit" className="btn btn-success" style={{ borderRadius: "5px" }}>Submit</button>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
                    : ""}

                <div className={"slide-r " + (this.state.sliderForBulkUpload)} style={{ overflow: "auto" }}>
                    <div className="slide-r-title">
                        <h4>
                            Bulk Upload Dealer Data
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <form method="POST" id='upform' className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload} >
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={this.changeFileHandler}
                                        value={this.state.uploadFile} />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                                {/* <div className="col-xl-12 col-lg-12 form-group">
								Date format should be in yyyy:mm:dd hh:mm:ss
							</div> */}
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/sample_data_dealer_data_bulk_upload.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}
window.onload = function () {
    // $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
    // });
    $('#gpsstartdate').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('#gpsenddate').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });

    $(".styles_modal__gNwvD").css({ width: "450px" })
};
document.addEventListener('turbolinks:before-render', () => {
    loadDateTimeScript()
});

function loadDateTimeScript() {

    $("#report_date").datepicker({
        changeMonth: true,
        changeYear: true,
        showButtonPanel: false,
        dateFormat: "mm-yy",
        onClose: function (dateText, inst) {

            //Get the selected month value
            var month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();

            //Get the selected year value
            var year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();

            //set month value to the textbox
            $(this).datepicker('setDate', new Date(year, month, 1));
        },
        beforeShow: function (input, inst) {
            $('#ui-datepicker-div').addClass("report_date");
        }
    });

    $('#gpsstartdate').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    $('#gpsenddate').datetimepicker({
        mask: '39-19-9999 29:59',
        format: 'd-m-Y H:i'
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}


function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}

function secondsToString(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

function minutesToDHM(n) {
    var num = n;
    var d = Math.floor(num / (60 * 24));
    var h = Math.floor((num % (60 * 24)) / 60);
    var m = Math.floor((num % (60 * 24)) % 60);

    var dDisplay = d >= 0 ? d + (d === 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h === 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m === 1 ? " Mins " : " Mins ") : "";
    return dDisplay + hDisplay + mDisplay
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    // console.log(date.length);
    // console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
function arrayMin(arr) {
    return arr.reduce(function (p, v) {
        return (p < v ? p : v);
    });
}

function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}
$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})
