/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline } from 'google-maps-react';

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils';

import Modal from 'react-responsive-modal';

import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Constant from "../common/googlemapkey";

var infoBox = require('../common/google-infowindow');
var moment = require('moment');

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;
class AndriodMapView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mapinfo: '',
			defTransitCoords: '',
			activeMarker: {},
			selectedPlace: {},
			dealerCode: '',
			timelinesmarkers: [],
			viewtimelinemarkers: true,
			viewgoogleroutes: true,
			googleroutepath: [],
			loadshow: 'show-n',
			open: false,
			modules: AllModules,
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			rowData: null,

			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				"highlitRow": "data.transit_delay == 1",
				"green": "data.status >= 3"

			}
		};

	}
	componentDidMount() {

		this.renderMap()
	}
	componentWillReceiveProps() {
		this.renderMap()
	}
	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		//console.log("Deptcode " ,this.state.deptcode)
		if (this.state.deptcode == 'LOG-PRT') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}


	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

	initMap = () => {

		//console.log("Yes Here")
		//console.log("mapFor ",this.props.mapFor)
		//console.log("mapinfo ",this.props.mapinfo)
		var currentwindow;
		var dealer = '';
		this.setState({
			dealerCode: this.props.dealer
		});
		var arr = [];
		//console.log("dealerCode ",this.state.dealerCode)
		if (this.props.mapFor == 'geofence' || this.props.mapFor == 'truckgps') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
		}
		if (this.props.mapFor == 'consignment') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var arr = jsondata.coords;
			dealer = jsondata.consignee_coordinates;
		}
		if (this.props.mapFor == 'importsconsignment') {
			var jsondata = this.props.mapinfo;
			var locations = jsondata.points;
			var arr = jsondata.route;
		}
		//console.log("Here",arr);
		if (arr.length > 0 && arr.length != undefined) {
			var lt = arr[0].lat;
			var ln = arr[0].lng;
		}
		else {
			if (this.props.defTransitCoords == '') {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			else {
				var lt = JSON.parse(this.props.defTransitCoords).lat;
				var ln = JSON.parse(this.props.defTransitCoords).lng;
			}

		}
		//console.log("Lt ",lt);
		//console.log("Ln ",ln);
		var routeinfo;
		if (this.props.mapFor == 'consignment') {
			var routeinfo = jsondata.route_details;

		}
		var mapOptions = {
			zoom: 13,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['hybrid', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};

		var map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});
		if (arr.length > 0) {

			var allpoints = [];

			/*if(arr.length < 15)
			{
				var ratio = (arr.length/1);
			}
			else if(arr.length > 15 && arr.length < 35)
			{
				var ratio = (arr.length/2);
			}
			else if(arr.length > 35 && arr.length <50)
			{
				var ratio = 10;
			}
			else if(arr.length > 35 && arr.length <50)
			{
				var ratio = 20;
			}
			else if(arr.length > 200 && arr.length <400)
			{
				var ratio = 60;
			}*/
			if (arr.length < 500) {
				var ratio = 1;
			}
			else {
				var ratio = 30;
			}
			var arr1 = [];
			//console.log("Total ",ratio);
			if (this.state.timelinesmarkers.length > 0) {
				arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
					return (index % ratio == 0);
				});


				//var arr1 = this.state.timelinesmarkers;
			}

			//console.log("Packet ",arr1);
			//Consignments Maps
			if (this.props.mapFor == 'consignment') {

				for (let i = 0; i < arr.length; i++) {
					if (arr[i].speed > 0) {
						var path = line.getPath().getArray();

						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);

						//console.log("Check ",pointertime[arr[i].stime]);

						//Change line color based on map type
						window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
							if (map.getMapTypeId() == 'hybrid' || map.getMapTypeId() == 'satellite') {
								var line = new window.google.maps.Polyline(
									{
										map: map,
										strokeColor: '#FF0202',
										strokeOpacity: 1.0,
										strokeWeight: 2.5,
										icons: [{
											icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor: '#ff8c52',
												fillColor: '#ff8c52',
												fillOpacity: 1,
												strokeWeight: 2
											},
											repeat: '100px',
											path: []
										}]
									});
							}
							else {
								var line = new window.google.maps.Polyline(
									{
										map: map,
										strokeColor: '#FF0202',
										strokeOpacity: 1.0,
										strokeWeight: 2.5,
										icons: [{
											icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor: '#ff8c52',
												fillColor: '#ff8c52',
												fillOpacity: 1,
												strokeWeight: 2
											},
											repeat: '100px',
											path: []
										}]
									});

							}

							for (var ia = 0; ia < arr.length; ia++) {
								if (arr[ia].speed > 0) {
									var path = line.getPath().getArray();
									let latLng = new window.google.maps.LatLng(arr[ia].lat, arr[ia].lng);
									path.push(latLng);
									line.setPath(path);
								}

								//map.setCenter(latLng);
							}

							var x = map.getZoom();
							var c = map.getCenter();
							window.google.maps.event.trigger(map, 'resize');
							map.setZoom(x);
							map.setCenter(c);
						});

						bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
					}
				}

				var infowindow = new window.google.maps.InfoWindow();
				var marker, l;

				// for (l = 0; l < locations.length; l++) {
				// marker = new window.google.maps.Marker({
				// 	position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
				// 	icon:require('../../assets/icons/cf.png'),
				// 	map: map,
				// });

				// window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
				// 		return function() {
				// 		//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
				// 		var contentarr = []
				// 		var header = "Break - "+(l+1)
				// 		contentarr.push({"key":"Start time", "value":getDDMMYYYYHHMMSS(locations[l].break_start)})
				// 		contentarr.push({"key":"End time", "value":getDDMMYYYYHHMMSS(locations[l].break_end)+" ("+ secondsToString(locations[l].break_time_seconds)+")"})
				// 		var contentString = infoBox(marker.icon, header, contentarr)
				// 		infowindow.setContent(contentString);
				// 		currentwindow = infowindow;
				// 		infowindow.open(map, marker);
				// 		//console.log(marker.position.toJSON());
				// 		//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
				// 		}
				// 	})(marker, l));
				// }

				/*DEALER*/
				try {

					if ((dealer.lat != '' || dealer.lat != 0) && (dealer.lng != '' || dealer.lng != 0)) {
						console.log(dealer.lat + " ==   " + dealer.lng);
						marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(dealer.lat, dealer.lng),
							icon: require('../../assets/icons/user-map.png'),
							map: map,
						});


						var markerLatlng1;
						markerLatlng1 = new window.google.maps.LatLng(dealer.lat, dealer.lng);
						//console.log("markerLatlng", markerLatlng1)
						var circle = new window.google.maps.Circle({
							strokeColor: '#71caab',
							strokeOpacity: 0.7,
							strokeWeight: 1,
							fillColor: '#71caab',
							fillOpacity: 0.25,
							map: map,
							center: markerLatlng1,
							radius: 25000
						});
						//circle.setRadius(value);
						/*
						window.google.maps.event.addListener(marker, 'click', (function(marker) {
							return function() {
								//	console.log(clusters[0])
								var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Dealer"
							contentarr.push({"key":"Code ", "value":this.state.dealerCode})
							var contentString = infoBox(marker.icon, header, contentarr,'')
													
							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker));
						*/
					}
				} catch (e) { }



				/*START ICON*/
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
					icon: require('../../assets/icons/track_start.png'),
					map: map,
				});
				window.google.maps.event.addListener(marker, 'click', (function (marker) {
					return function () {
						//	console.log(clusters[0])
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "Starting Point"
						contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
						var contentString = infoBox(marker.icon, header, contentarr, '')


						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
					}
				})(marker));
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
					icon: require('../../assets/icons/truck-end.png'),
					map: map,
				});
				window.google.maps.event.addListener(marker, 'click', (function (marker) {
					return function () {
						//	console.log(clusters[0])
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
						var header = "End Point"
						contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
						var contentString = infoBox(marker.icon, header, contentarr)

						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
					}
				})(marker));

				if (arr1.length > 0) {

					//console.log("allpoints ", allpoints)

					for (var a = 0; a < arr1.length; a++) {
						var marker = new window.google.maps.Marker({
							position: new window.google.maps.LatLng(arr1[a].lat, arr1[a].lng),
							icon: {
								path: window.google.maps.SymbolPath.CIRCLE,
								strokeColor: '#157254',
								fillColor: '#157254',
								fillOpacity: 1,
								strokeWeight: 3,
								scale: 2,
							},
							map: map,
							content: arr1[a]
						});

						//console.log("Arr ",arr1[a])
						window.google.maps.event.addListener(marker, 'click', (function (marker) {
							return function () {
								//console.log("Arr ",marker)
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Current Status"
								contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
								contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })

								var contentString = infoBox("", header, contentarr, '')


								infowindow.setContent(contentString);
								currentwindow = infowindow;
								infowindow.open(map, marker);
								//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
							}
						})(marker));

						/*
						window.google.maps.event.addListener(line, 'click', function(e) {
							infowindow.setPosition(e.latLng);
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Current Status"
							contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(e.stime)})
							contentarr.push({"key":"Speed ", "value":e.speed+" KMPH"})
							
							var contentString = infoBox("", header, contentarr,'')
							infowindow.setContent(contentString);
							infowindow.open(map);
						});
						window.google.maps.event.addListener(line, 'mouseout', function() {
							infowindow.close();
						});
						*/
					}
				}

				/*Google Routes Starts*/
				var groutepath = this.state.googleroutepath;
				if (groutepath.length > 0) {
					var lineg = new window.google.maps.Polyline(
						{
							map: map,
							strokeColor: '#FF0000',
							strokeOpacity: 1.0,
							strokeWeight: 2.5,
							icons: [{
								icon: {
									path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
									strokeColor: '#FF0000',
									fillColor: '#FF0000',
									fillOpacity: 1,
									strokeWeight: 2
								},
								repeat: '100px',
								path: []
							}]
						});
					console.log("groutepath ", groutepath)
					for (let g = 0; g < groutepath.length; g++) {
						var pathg = lineg.getPath().getArray();

						let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
						pathg.push(latLng);
						lineg.setPath(pathg);
						//console.log("path ", pathg)
						//Change line color based on map type
						window.google.maps.event.addListener(map, 'maptypeid_changed', function () {

							var x = map.getZoom();
							var c = map.getCenter();
							window.google.maps.event.trigger(map, 'resize');
							map.setZoom(x);
							map.setCenter(c);

							var pathg = lineg.getPath().getArray();
							let latLng = new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng);
							pathg.push(latLng);
							lineg.setPath(pathg);
						});

						bounds.extend(new window.google.maps.LatLng(groutepath[g].lat, groutepath[g].lng));
					}
				}


			}


			//Imports Consingments Maps
			if (this.props.mapFor == 'importsconsignment') {

				for (let i = 0; i < arr.length; i++) {
					if (arr[i].speed > 0) {
						var path = line.getPath().getArray();

						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);




						//Change line color based on map type
						window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
							if (map.getMapTypeId() == 'hybrid' || map.getMapTypeId() == 'satellite') {
								var line = new window.google.maps.Polyline(
									{
										map: map,
										strokeColor: '#FFFFFF',
										strokeOpacity: 1.0,
										strokeWeight: 2.5,
										icons: [{
											icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor: '#ff8c52',
												fillColor: '#ff8c52',
												fillOpacity: 1,
												strokeWeight: 2
											},
											repeat: '100px',
											path: []
										}]
									});
							}
							else {
								var line = new window.google.maps.Polyline(
									{
										map: map,
										strokeColor: '#157254',
										strokeOpacity: 1.0,
										strokeWeight: 2.5,
										icons: [{
											icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor: '#ff8c52',
												fillColor: '#ff8c52',
												fillOpacity: 1,
												strokeWeight: 2
											},
											repeat: '100px',
											path: []
										}]
									});

							}

							/*for (i = 0; i < arr.length; i++) {
								  var path=line.getPath().getArray();
								  let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
								  path.push(latLng);
									  line.setPath(path);
										  //map.setCenter(latLng);
								}
							  */

							var x = map.getZoom();
							var c = map.getCenter();
							window.google.maps.event.trigger(map, 'resize');
							map.setZoom(x);
							map.setCenter(c);

							var path = line.getPath().getArray();
							let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
							path.push(latLng);
							line.setPath(path);
						});

						bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
					}
				}

				var infowindow = new window.google.maps.InfoWindow();
				var marker, l;
				//console.log("locations.length ", locations.length)
				var lastorigin = '';
				if (locations.length > 0) {
					for (l = 0; l < locations.length; l++) {
						if (arr[0].lat != locations[l].coordinates.lat && arr[0].lng != locations[l].coordinates.lmg) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
								icon: require('../../assets/icons/boat.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {

									var contentarr = []
									var headerTitle = locations[l].name + ", " + locations[l].country;
									//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
									var contentStringData = '';
									if (locations[l].events.length > 0) {
										contentStringData = contentStringData + "<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
										contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
										for (var e = 0; e < locations[l].events.length; e++) {
											var typemode;
											if (locations[l].events[e].type == 1) {
												typemode = 'Rail/Truck';
											}
											if (locations[l].events[e].type == 2) {
												typemode = 'Air';
											}
											if (locations[l].events[e].type == 3) {
												typemode = 'Sea';
											}
											if (locations[l].events[e].type == 4) {
												typemode = 'Rail/Truck';
											}
											var d = locations[l].events[e].event_datetime_ist;
											var dat = d.split(" ");

											contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>" + dat[0];
											contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";


										}
										contentStringData = contentStringData + "</tbody></table>";

									}
									contentarr.push(contentStringData);
									//lastorigin=contentString;
									//console.log("1", headerTitle)
									var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')


									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker, l));
						}
						else {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(locations[l].coordinates.lat, locations[l].coordinates.lng),
								icon: require('../../assets/icons/start_port.png'),
								map: map,
							});

							window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
								return function () {
									//var contentString = "<p class='maptitle'>"+"<B> "+locations[l].name+", "+locations[l].country+"</B></p>";
									var contentarr = []
									var headerTitle = locations[l].name + ", " + locations[l].country;
									var contentStringData = '';
									if (locations[l].events.length > 0) {
										contentStringData = contentStringData + "<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
										contentStringData = contentStringData + "<thead><tr><th>Date</th><th>Event</th><th>Transport Mode</th></tr></thead><tbody>";
										for (var e = 0; e < locations[l].events.length; e++) {
											var typemode;
											if (locations[l].events[e].type == 1) {
												typemode = 'Rail/Truck';
											}
											if (locations[l].events[e].type == 2) {
												typemode = 'Air';
											}
											if (locations[l].events[e].type == 3) {
												typemode = 'Sea';
											}
											if (locations[l].events[e].type == 4) {
												typemode = 'Rail/Truck';
											}
											var d = locations[l].events[e].event_datetime_ist;
											var dat = d.split(" ");

											contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>" + dat[0];
											contentStringData = contentStringData + "</span></td><td>" + locations[l].events[e].description + "</span></td><td style='text-align: right'>" + typemode + "</span></td></tr>";

										}
										contentStringData = contentStringData + "</tbody></table>";

									}
									contentarr.push(contentStringData);
									//console.log("2", headerTitle)
									var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log(marker.position.toJSON());
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker, l));
						}
					}
				}
				if (jsondata.hasOwnProperty('last_known_location') == true && jsondata.last_known_location != null) {
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(jsondata.last_known_location.lat, jsondata.last_known_location.lng),
						icon: require('../../assets/icons/crane.png'),
						map: map,

					});


					window.google.maps.event.addListener(marker, 'click', (function (marker) {
						return function () {
							//console.log("jsondata", jsondata);
							var contentarr = []
							var headerTitle = "Last known location";
							var contentStringData = '';
							var typemode;
							if (jsondata.last_event.type == 1) {
								typemode = 'Rail/Truck';
							}
							if (jsondata.last_event.type == 2) {
								typemode = 'Air';
							}
							if (jsondata.last_event.type == 3) {
								typemode = 'Sea';
							}
							if (jsondata.last_event.type == 4) {
								typemode = 'Rail/Truck';
							}
							var d = jsondata.last_event.event_datetime_ist;
							var dat = d.split(" ");

							var contentStringData = contentStringData + "<table class='mt-8p table table-border' style='background:#f5f5f5;border:1px solid #dee2e6'>";
							contentStringData = contentStringData + "<thead><tr><th>Last Updated Date</th><th>Transport Mode</th></tr></thead><tbody>";

							contentStringData = contentStringData + "<tr><td class='custb'><span class='tbmap'><i class='icofont icofont-disc'></i> <span class='tdmapsp'>" + jsondata.last_known_time;
							contentStringData = contentStringData + "</span></td><td style='text-align: right'>" + typemode + "</td></tr>";
							contentStringData = contentStringData + "</tbody></table>";

							contentarr.push(contentStringData);
							var contentString = infoBox(marker.icon, headerTitle, contentarr, 'table')


							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
						}
					})(marker));
				}
			}

			/*GEOFENCE MAP*/
			if (this.props.mapFor == 'geofence' || this.props.mapFor == 'truckgps') {

				var routeinfo = jsondata.route_details;

				for (let i = 0; i < arr.length; i++) {
					if (arr[i].speed > 0) {
						var path = line.getPath().getArray();

						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);

						//Change line color based on map type
						window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
							if (map.getMapTypeId() == 'hybrid' || map.getMapTypeId() == 'satellite') {
								var line = new window.google.maps.Polyline(
									{
										map: map,
										strokeColor: '#FFFFFF',
										strokeOpacity: 1.0,
										strokeWeight: 2.5,
										icons: [{
											icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor: '#ff8c52',
												fillColor: '#ff8c52',
												fillOpacity: 1,
												strokeWeight: 2
											},
											repeat: '100px',
											path: []
										}]
									});
							}
							else {
								var line = new window.google.maps.Polyline(
									{
										map: map,
										strokeColor: '#157254',
										strokeOpacity: 1.0,
										strokeWeight: 2.5,
										icons: [{
											icon: {
												path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
												strokeColor: '#ff8c52',
												fillColor: '#ff8c52',
												fillOpacity: 1,
												strokeWeight: 2
											},
											repeat: '100px',
											path: []
										}]
									});

							}

							for (i = 0; i < arr.length; i++) {
								var path = line.getPath().getArray();
								let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
								path.push(latLng);
								line.setPath(path);
								//map.setCenter(latLng);
							}

							var x = map.getZoom();
							var c = map.getCenter();
							window.google.maps.event.trigger(map, 'resize');
							map.setZoom(x);
							map.setCenter(c);
						});
						bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
					}
				}

				var infowindow = new window.google.maps.InfoWindow();
				var marker, l;

				for (l = 0; l < locations.length; l++) {
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
						icon: require('../../assets/icons/cf.png'),
						map: map,
					});

					window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
						return function () {
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
							var header = "Break"
							contentarr.push({ "key": "Break time", "value": locations[l].break_start + " - " + locations[l].break_end + " (" + timeConvert(locations[l].break_time) + ")" })

							var contentString = infoBox(marker.icon, header, contentarr, '')

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							//console.log(marker.position.toJSON());
							console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
						}
					})(marker, l));
				}
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
					icon: require('../../assets/icons/track_start.png'),
					map: map,
				});
				window.google.maps.event.addListener(marker, 'click', (function (marker) {
					return function () {
						//	console.log(clusters[0])

						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "Starting Point"
						contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

						var contentString = infoBox(marker.icon, header, contentarr, '')

						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
					}
				})(marker));
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
					icon: require('../../assets/icons/truck-end.png'),
					map: map,
				});
				window.google.maps.event.addListener(marker, 'click', (function (marker) {
					return function () {
						//	console.log(clusters[0])

						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
						var contentarr = []
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
						var header = "End Point"
						contentarr.push({ "key": "End at", "value": routeinfo.end_time })

						var contentString = infoBox(marker.icon, header, contentarr, '')

						infowindow.setContent(contentString);
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
					}
				})(marker));

				if (this.props.mapFor == 'truckgps') {
					if (arr1.length > 0) {

						//console.log("allpoints ", allpoints)

						for (var a = 0; a < arr1.length; a++) {
							var marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(arr1[a].lat, arr1[a].lng),
								icon: {
									path: window.google.maps.SymbolPath.CIRCLE,
									strokeColor: '#157254',
									fillColor: '#157254',
									fillOpacity: 1,
									strokeWeight: 3,
									scale: 2,
								},
								map: map,
								content: arr1[a]
							});

							//console.log("Arr ",arr1[a])
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//console.log("Arr ",marker)
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
									var header = "Current Status"
									contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.stime) })
									contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })

									var contentString = infoBox("", header, contentarr, '')

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
								}
							})(marker));

							/*
							window.google.maps.event.addListener(line, 'click', function(e) {
								infowindow.setPosition(e.latLng);
								var contentarr = []
								//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
								var header = "Current Status"
								contentarr.push({"key":"Time ", "value":getDDMMYYYYHHMMSS(e.stime)})
								contentarr.push({"key":"Speed ", "value":e.speed+" KMPH"})
								
								var contentString = infoBox("", header, contentarr,'')
								infowindow.setContent(contentString);
								infowindow.open(map);
							});
							window.google.maps.event.addListener(line, 'mouseout', function() {
								infowindow.close();
							});
							*/

						}
					}
				}

			}
		}


		map.fitBounds(bounds)
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};

	onShowTimelines() {
		this.setState({
			viewtimelinemarkers: !this.state.viewtimelinemarkers
		})
		if (this.state.viewtimelinemarkers == true) {
			if (this.props.mapFor == 'consignment') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if (this.props.mapFor == 'importsconsignment') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.points;
				var arr = jsondata.route;
			}
			if (this.props.mapFor == 'truckgps') {
				var jsondata = this.props.mapinfo;
				var locations = jsondata.breaks;
				var arr = jsondata.coords;
			}
			if (arr.length > 0) {

				var allpoints = [];


				if (arr.length < 50) {
					var ratio = (arr.length / 5);
				}
				else if (arr.length > 50 && arr.length < 200) {
					var ratio = 20;
				}
				else if (arr.length > 200 && arr.length < 400) {
					var ratio = 60;
				}
				else {
					var ratio = 100;
				}

				//console.log("Total ",arr.length);
				var arr1 = arr.filter(function (value, index, arr) {
					//return (index % ratio == 0);
					return index;
				});
				this.setState({
					timelinesmarkers: arr1
				})
			}
		}
		else {
			this.setState({
				timelinesmarkers: []
			})
		}
		this.renderMap()

	}
	onViewTimelines(e) {
		this.setState({
			viewtimelinemarkers: !this.state.viewtimelinemarkers
		})
		//console.log("viewtimelinemarkers ", this.state.viewtimelinemarkers)
	}
	onShowGridPop(e) {
		if (this.props.mapFor == 'consignment') {
			this.setState({
				loadshow: 'show-m'
			})
			var jsondata = this.props.mapinfo;
			var locations = jsondata.breaks;
			var coordinates = jsondata.coords;
			//console.log("truckno ", this.props.truckno)
			//console.log("coordinates ", coordinates)
			var dataarr = []
			//console.log("coordinates ", coordinates)
			coordinates.map((item) => {
				dataarr.push({ "truck_no": this.props.truckno, "lat": item.lat, "lng": item.lng, "speed": item.speed, "stime": item.stime, "dist_from_prev_point": item.dist_from_prev_point, "received_on": item.received_on, "time_from_prev_point": item.time_from_prev_point })
			});
			this.setState({
				rowData: dataarr,
				open: true,
				loadshow: 'show-n'
			})
		}
	}


	onShowGoogleRoutes() {
		this.setState({
			viewgoogleroutes: !this.state.viewgoogleroutes
		})
		if (this.state.viewgoogleroutes == true) {
			if (this.props.mapFor == 'consignment') {
				try {
					var googleroutes = JSON.parse(this.props.googleroutes);
					//console.log("googleroutes ", googleroutes)
					if (googleroutes == 0) {
						this.props.context.showGoogleRouteAlert();
						this.setState({
							viewgoogleroutes: !this.state.viewgoogleroutes
						});
					}
					else {
						this.setState({
							googleroutepath: googleroutes
						})
					}
				}
				catch (e) {
					console.log(e)
				}

			}
		}
		else {
			this.setState({
				googleroutepath: [],
				viewgoogleroutes: !this.state.viewgoogleroutes
			})
		}

		this.renderMap()
	}

	render() {
		const modalStyles = {
			width: '500px !important',
		}
		const { open } = this.state;
		const columnwithDefs = [
			{
				headerName: "Truck no",
				field: "truck_no",
				width: 100,
				filter: false,
				resizable: true

			},
			{
				headerName: "Latitude",
				field: "lat",
				width: 120,
				filter: false,
				resizable: true,
				valueGetter: function (params) {
					return params.data.lat.toFixed(6);
				}

			},
			{
				headerName: "Longitude",
				field: "lng",
				width: 120,
				filter: false,
				resizable: true,
				valueGetter: function (params) {
					return params.data.lng.toFixed(6);
				}

			},
			{
				headerName: "Speed (Kms)",
				field: "speed",
				width: 80,
				filter: false,
				resizable: true

			},
			{
				headerName: "Packet Time",
				field: "stime",
				width: 140,
				filter: false,
				resizable: true,
				valueGetter: function (params) {
					//console.log(params);
					return getDDMMYYYYHHMMSS(params.data.stime);
				}
				// valueGetter:function(params){
				// 	console.log(params);
				// 	return params.data.stime;
				// }

			},
			{
				headerName: "Received On",
				field: "received_on",
				width: 140,
				filter: false,
				resizable: true,
				valueGetter: function (params) {
					//console.log(params);
					return getDDMMYYYYHHMMSS(params.data.received_on);
				}

			},
		]
		return (

			<div className="col-xl-12 col-md-12 n-p-0">

				<div id="map" className="" style={{ width: '100%', height: "100vh" }}></div>
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} >
					<div className={"row"} style={{ height: '100vh', padding: '5px 0px', width: '1185px', overflow: 'hidden', zIndex: '9999', }} >
						<div className={"col-sm-8 col-lg-8 col-md-8"} style={{ padding: '20px', background: '#fff' }}>
							{/*<div style={{position:'absolute',top:'5px',right:'10px',padding:'0px'}}>
	                			<a href={"#"} title="Close" style={{fontSize:'12px', color:'#333', textDecoration:'none'}} onClick={this.onCloseModal}>
	                				<img src={require("../../assets/icons/close.png")} style={{width:'8px',marginTop:'-2px'}} title="Close" />
	                				 &nbsp;Close
	                			</a>
	                		</div>
							*/}
							<div id="myGrid" style={{ height: "580px", width: "100%", marginTop: '10px' }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={columnwithDefs}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									floatingFilter={false}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
								/>

								<div className={"dataLoadpage " + (this.state.loadshow)}>
								</div>
								<div className={"dataLoadpageimg " + (this.state.loadshow)}>
									<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
								</div>

							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}

function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num - (rhours * 3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function intervals(startString, endString) {
	var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
	var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

	start.minutes(Math.ceil(start.minutes() / 15) * 15);

	var result = [];

	var current = moment(start);

	while (current <= end) {
		result.push(current.format('DD-MM-YYYY HH:mm'));
		current.add(15, 'minutes');
	}

	return result;
}


function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}


export default AndriodMapView;